import ACTIONS, { NOTIFICATION } from 'application/constants'
import { call, put, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { closeAllDialogs, openDialogByName, sendPayloadRequest, updateDialogDataByName } from 'application/redux/actions/dialogs'
import { setPicturePatternSaga } from './set_picture_saga'
import { croppingPicturePatternSaga } from './cropping_picture_saga'
import { showToast } from 'application/redux/actions/notifications'


function* putPictureSaga(action) {
    try {
        const imageDataUrl = yield call(setPicturePatternSaga, action)
        yield put(updateDialogDataByName(action.metaData.dialogName, {file: action.files, imageDataUrl }))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* setPictureSaga(action) {
    try {
        const imageDataUrl = yield call(setPicturePatternSaga, action)
        yield put(openDialogByName(action.metaData.dialogName, true, {file: action.files, imageDataUrl, userId: action.metaData.userId, accountId: action.metaData.accountId }, 'Set profile picture'))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* croppingAndSendImageSaga(action) {
    const { accountId, dialogName, file, successMessage, userId, errorMessage } = action.metaData
    try {
        const croppedImage = yield call(croppingPicturePatternSaga, action)
        // action = { ...action, croppedImage }
        yield put(sendPayloadRequest(dialogName, { accountId, file, croppedImage, userId }, { successMessage}))
    } catch (e) {
        yield handleError(e, action)
        yield put(showToast(errorMessage, NOTIFICATION.LEVEL.ERROR))
        yield put(closeAllDialogs())
    }
}

export function* editPictureWatcher() {
    yield takeEvery(ACTIONS.FILES_PUT_PICTURE, putPictureSaga)
    yield takeEvery(ACTIONS.FILES_SET_PICTURE, setPictureSaga)
    yield takeEvery(ACTIONS.FILES_CROPPING_IMAGE, croppingAndSendImageSaga)
}