export const onUpdateDataNext = (data = [], currentStep, nextStep) => {
    // update current data
    const currentData = data.find(d => d.step === currentStep)
    Object.keys(currentData).length !== 0 && Object.assign(currentData, { isDone: true, inProgress: false })

    // update next data
    const nextData = data.find(d => d.step === nextStep)
    Object.keys(nextData).length !== 0 && Object.assign(nextData, { isDone: false, inProgress: true })
    
    // delete old data
    let values = [currentStep, nextStep]
    const restData = data.filter(d => values.indexOf(d.step) === -1)

    const updatedData = [...restData, currentData, nextData].sort((a, b) => a.step - b.step)

    return updatedData

}
export const onUpdateDataPrev = (data = [], currentStep, prevStep) => {

    // update current data
    const currentData = data.find(d => d.step === currentStep)
    Object.keys(currentData).length !== 0 && Object.assign(currentData, { isDone: false, inProgress: false })
    
    // update next data
    const prevData = data.find(d => d.step === prevStep)
    Object.keys(prevData).length !== 0 && Object.assign(prevData, { isDone: false, inProgress: true })

    // delete old data
    let values = [currentStep, prevStep]
    const restData = data.filter(d => values.indexOf(d.step) === -1)

    const updatedData = [...restData, currentData, prevData].sort((a, b) => a.step - b.step)

    return updatedData

}

export const onAllDone = (data = []) => {
    const doneData = data.map(d => ({
        ...d,
        isDone: true,
        inProgress: false
    }))

    return doneData
}