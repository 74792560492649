import ACTIONS from 'application/constants'
import default_store from '../../../default_store'

const aktenActionNewMessageReducer = (state = default_store.pages.akten.action.newMessagesOnFolder, action) => {
    switch (action.type) {
    case ACTIONS.PAGES_AKTEN_REFRESH_NEW_MESSAGES_ON_FOLDER: return {
        ...state,
        request: false,
        succeed: false,
        failed: false,
    }
    case ACTIONS.PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_REQUEST: return {
        ...state,
        request: true,
    }
    case ACTIONS.PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_SUCCEED: return {
        ...state,
        request: false,
        succeed: true
    }
    case ACTIONS.PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_FAILED: return {
        ...state,
        request: false,
        succeed: false,
        failed: true
    }
    default:
        return state
    }
}

export default aktenActionNewMessageReducer