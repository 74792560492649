import React, { useState } from 'react'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import * as Fragments from '../fragments'
import { textHelpers } from 'application/common'
import { useTranslation } from 'react-i18next'


const WelcomeGreetings = (props) => {
    const { 
        accountPublicInfo,
        useMobile = false
    } = props
    const { welcomeText, primaryColor, welcomeHeadline } = accountPublicInfo
    const welcomeTextLength = welcomeText === '' ? 0 : welcomeText.length
    const [showAllWelcomeText, setShowAllWelcomeText] = useState(welcomeTextLength > 519)
    const truncatedWelcomeText = textHelpers.truncateEndText(welcomeText, 519)
    const {t} = useTranslation()
    const hasWelcomeHeadline = welcomeHeadline !== '' && welcomeHeadline !== 'Longstring_HomepageDefaultTitle'
    return (
        <Fragments.AsclarMainContainer>
            <FlexGrid>
                <FlexGrid directionColumn>
                    <FlexGridItem>
                        <FlexGrid directionColumn>
                            {
                                hasWelcomeHeadline && (
                                !useMobile ? 
                                        <>
                                            <Fragments.AsclarText isBold fontSize={'27px'}>{welcomeHeadline}</Fragments.AsclarText>
                                            <Fragments.AsclarSpacer padding={'15px'}/>
                                            <Fragments.AsclarText fontSize={'21px'}>{welcomeText}.</Fragments.AsclarText>
                                        </>
                                    :
                                    welcomeTextLength < 519  ?
                                        <>
                                            <Fragments.AsclarText isBold fontSize={'17px'}>{welcomeHeadline}</Fragments.AsclarText>
                                            <Fragments.AsclarSpacer padding={'10px'}/>
                                            <Fragments.AsclarText fontSize={'14px'}>{welcomeText}.</Fragments.AsclarText>
                                        </> 
                                    : showAllWelcomeText ? 
                                            <>
                                                <Fragments.AsclarText isBold fontSize={'17px'}>{welcomeHeadline}</Fragments.AsclarText>
                                                <Fragments.AsclarSpacer padding={'10px'}/>
                                                <Fragments.AsclarText fontSize={'14px'}>{truncatedWelcomeText}</Fragments.AsclarText>
                                                {' '}<Fragments.StyledMoreAndLessText primaryColor={primaryColor} onClick={() => setShowAllWelcomeText(!showAllWelcomeText)}>
                                                        {t('Show it more')}
                                                </Fragments.StyledMoreAndLessText>
                                            </>
                                                :  
                                            <>
                                                <Fragments.AsclarText isBold fontSize={'17px'}>{welcomeHeadline}</Fragments.AsclarText>
                                                <Fragments.AsclarSpacer padding={'10px'}/>
                                                <Fragments.AsclarText fontSize={'14px'}>{welcomeText}</Fragments.AsclarText>
                                                    {' '} <Fragments.StyledMoreAndLessText primaryColor={primaryColor} onClick={() => setShowAllWelcomeText(!showAllWelcomeText)}>
                                                        {t('Show it less')}
                                                </Fragments.StyledMoreAndLessText>
                                            </>
                                )
                            }
                        </FlexGrid>
                    </FlexGridItem>
                </FlexGrid>
            </FlexGrid>
        </Fragments.AsclarMainContainer>
    )

}

export default WelcomeGreetings