import { Spacer } from 'application/components/pages/internal/dashboard/fragments'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
    HeadlineWizard, ParWizard, StepText
} from '../../fragments'
import { useTheme } from 'styled-components'

const TitleWizard = props => {
    const { akteMeta } = props
    const {t} = useTranslation()
    const theme = useTheme()
    const {
        step = 1,
        headline = t('EsyMandantSalutation'),
        desc = t('EsyMandantSalutationText'),
        spacerHeadlineAndStep = '8px'
    } = props
    return <Fragment>
        <StepText>
            {t('step')} {step}/3
        </StepText>
        <Spacer distance={spacerHeadlineAndStep} />
        <HeadlineWizard style={theme.classes.HeadlineWizard}>
            {headline}
        </HeadlineWizard>
        <ParWizard style={theme.classes.ParWizard}>
            {desc}
        </ParWizard>
    </Fragment>
}

export default TitleWizard
