import { ACTIONS } from 'application/constants'
import default_store from 'application/redux/reducers/default_store'
import { regDataPasswordSet, regDataUserNameSet, registrationCompleted } from 'application/storage'
import { wizardData } from 'application/storage/wizard_data'
import { onAllDone, onUpdateDataNext, onUpdateDataPrev } from './function'

const wizard = (state = {
    ...default_store.wizard,
    registrationData: {
        ...default_store.wizard.registrationData,
        usernameSet: regDataUserNameSet.get() !== '',
        username: regDataUserNameSet.get(),
        passwordSet: regDataPasswordSet.get() !== '',
        registered: {
            ...default_store.wizard.registrationData.registered,
            completed: registrationCompleted.get() !== ''
        }
    }
}, action) => {
    switch (action.type) {
    case ACTIONS.WIZARD_POPULATE_DATA:
        return {
            ...state,
            data: action.payload,
            currentStep: action.currentStepName,
        }
    case ACTIONS.WIZARD_POPULATE_REGISTRATION_KEY:
        return {
            ...state,
            registrationData: {
                ...state.registrationData,
                regKey: action.regKey,
            }
        }
    case ACTIONS.WIZARD_UPDATED_DATA_SUCCEED:
        wizardData.store(action.payload)
        return {
            ...state,
            isLoaded: true,
        }
    case ACTIONS.WIZARD_UPDATE_DATA_NEXT:
        const updatedDataNext = onUpdateDataNext(state.data, action.currentStep, action.nextStep)
        wizardData.store(updatedDataNext)
        return {
            ...state,
            currentStep: action.currentStepName,
            data: updatedDataNext
        }
    case ACTIONS.WIZARD_UPDATE_DATA_PREV:
        const updatedDataPrev = onUpdateDataPrev(state.data, action.currentStep, action.prevStep)
        wizardData.store(updatedDataPrev)
        return {
            ...state,
            currentStep: action.currentStepName,
            data: updatedDataPrev
        }
    case ACTIONS.WIZARD_UPDATE_DATA_ALL_DONE:
        const updatedDataDone = onAllDone(state.data)
        regDataPasswordSet.store(true)
        return {
            ...state,
            currentStep: action.currentStepName,
            data: updatedDataDone
        }
    case ACTIONS.WIZARD_CHECK_USERNAME:
        return {
            ...state,
            registrationData: {
                ...state.registrationData,
                usernameRequest: true,
            }
        }
    case ACTIONS.WIZARD_POPULATE_USERNAME:
        regDataUserNameSet.store(action.username)
        return {
            ...state,
            registrationData: {
                ...state.registrationData,
                usernameRequest: false,
                usernameSet: true,
                username: action.username
            }
        }
    case ACTIONS.WIZARD_USERNAME_NOT_VALID:
        return {
            ...state,
            registrationData: {
                ...state.registrationData,
                usernameRequest: false,
                usernameSet: false,
                usernameNotValid: true,
                errorMessage: action.errorMessage,
            }
        }
    case ACTIONS.WIZARD_WRITE_ERROR_MESSAGE:
        return {
            ...state,
            registrationData: {
                ...state.registrationData,
                errorMessage: action.errorMessage,
            }
        }
        
    case ACTIONS.WIZARD_REGISTRATION_REQUEST: 
        registrationCompleted.store(false)
        return {
            ...state,
            registrationData: {
                ...state.registrationData,
                registered: {
                    ...state.registrationData.registered,
                    request: true,
                    completed: false,
                    failed: false,
                }
            }
        }
    case ACTIONS.WIZARD_REGISTRATION_COMPLETE:
        registrationCompleted.store(true)
        return {
            ...state,
            registrationData: {
                ...state.registrationData,
                registered: {
                    ...state.registrationData.registered,
                    request: false,
                    completed: true,
                    failed: false,
                }
            }
        }
    case ACTIONS.WIZARD_REGISTRATION_FAILED:
        registrationCompleted.store(true)
        return {
            ...state,
            registrationData: {
                ...state.registrationData,
                registered: {
                    ...state.registrationData.registered,
                    request: false,
                    completed: false,
                    failed: true,
                }
            }
        }
    default:
        return state
    }
}

export default wizard