import { useQuery } from 'application/components/hooks'
import React, { createContext } from 'react'

export const AuthContext = createContext()

export function AuthProvider({ children, auth, logoutClicked, onRedirectToLogin }) {
    const query = useQuery()
    let value = { auth, logoutClicked, onRedirectToLogin, query }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}