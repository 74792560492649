import { api } from 'application/api'
import { updateDataOnMessage } from 'application/redux/actions/messages'
import { getNewMessagesSucceed } from 'application/redux/actions/pages/akten'
import { call, put } from 'redux-saga/effects'
import { setDeliveredOnOwnMessage } from './message_raw_mapper'

export function* getNewMessagesAkteSaga(action) {
    // const resultMessageDetails = yield call(api.gateway.pages.getMessageDetails, action.accountId, action.akteIdHash, 0, 1, 'Ascending')

    const resultMessageDetails = yield call(api.gateway.pages.getMessageDetails, action.accountId, action.akteIdHash)
    const messages = setDeliveredOnOwnMessage(resultMessageDetails.messages.timeline)
    const files = resultMessageDetails.files
    const folders = resultMessageDetails.folders
    yield put(updateDataOnMessage(action.messageKey, { messages, files, folders }))
    yield put(getNewMessagesSucceed())
}