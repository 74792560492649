import {MessagesSidebar, ServiceProviderDetailSidebar} from 'application/components/building_blocks/sidebar'
import React from 'react'

const DashboardSidebarComponent = ({ accountDetailsVisible, selectedAccount, allAccounts }) => {
    return <React.Fragment>
        {
            accountDetailsVisible ? <ServiceProviderDetailSidebar selectedAccount={selectedAccount} /> :
                <MessagesSidebar />
        }
    </React.Fragment>
} 

export default DashboardSidebarComponent