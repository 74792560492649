const { default: ACTIONS } = require('application/constants')
const { default: default_store } = require('../../default_store')

const accountDetails = (state = default_store.common.accountDetails, action) => {
    switch (action.type) {
    case ACTIONS.COMMON_SET_SELECTED_ACCOUNTS_DETAILS:
        return {
            ...state,
            accountDetailsVisible: true,
            selectedAccount: action.payload,
        }
    case ACTIONS.COMMON_GET_ACCOUNT_PUBLIC_INFO:
        return {
            ...state,
            publicInfoLoaded: false,
            publicInfoLoading: true,
        }
    case ACTIONS.COMMON_SET_ACCOUNT_PUBLIC_INFO:
        return {
            ...state,
            publicInfoLoading: false,
            publicInfoLoaded: true,
            publicInfo: action.payload,
        }
    case ACTIONS.COMMON_REFRESH_ACCOUNT_PUBLIC_INFO:
        return {
            ...state,
            publicInfoLoading: false,
            publicInfoLoaded: false,
            publicInfoFailed: false,
            publicInfo: null,
        }
    case ACTIONS.COMMON_GET_ACCOUNT_PUBLIC_INFO_FAILED:
        return {
            ...state,
            publicInfoLoading: false,
            publicInfoLoaded: false,
            publicInfoFailed: true,
        }
    case ACTIONS.COMMON_UPDATE_SELECTED_ACCOUNTS_DETAILS:
        return {
            ...state,
            accountDetailsVisible: true,
            selectedAccount: {
                ...state.selectedAccount,
                ...action.payload
            },
        }
    case ACTIONS.COMMON_CLOSE_ACCOUNTS_DETAILS:
        return {
            ...state,
            accountDetailsVisible: false,
            selectedAccount: {},
        }
    case ACTIONS.COMMON_ACTIVATE_ACCOUNT_REQUEST:
        return {
            ...state,
            activate: {
                ...state.activate,
                accountId: action.accountId,
                request: true,
            }
        }
    case ACTIONS.COMMON_ACTIVATE_ACCOUNT_SUCCEED:
        return {
            ...state,
            activate: {
                ...state.activate,
                accountId: null,
                request: false,
                succeed: true,
            }
        }
    case ACTIONS.COMMON_ACTIVATE_ACCOUNT_FAILED:
        return {
            ...state,
            activate: {
                ...state.activate,
                accountId: null,
                request: false,
                failed: true,
            }
        }
    default:
        return state
    }
}

export default accountDetails