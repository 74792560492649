import { downloadFile, getFullFileById } from 'application/redux/actions/files'
import { showGallery } from 'application/redux/actions/gallery'
import { deletePayloadMessageFavorite, setPayloadMessageFavorite } from 'application/redux/actions/messages'
import { markFavoriteMessageRequest, unmarkFavoriteMessageRequest } from 'application/redux/actions/pages/akten'
import { downloadMessageRequest, readMessageRequest } from 'application/redux/actions/pages/akten/action'
import { connect } from 'react-redux'
import ChatBubbleComponent from './component'
import { accessTokenRegistered } from 'application/storage'

const mapStateToProps = (state, props) => {
    const metaMessage = state.messages.registered.find((m) => m.key === props.messageKey)
    return {
        filesToShow: metaMessage.files.filter((f) => f.messageId === props.messageId),
        // filesOnQueue: state.pages.akten.details.filesOnQueue,
        thumbnailsToShow: metaMessage.files.filter((f) => f.messageId === props.messageId),
        downloadMessageRequest: state.pages.akten.action.downloadMessage.request,
        downloadMessageId: state.pages.akten.action.downloadMessage.id,
        markFavoriteMessageRequest: state.pages.akten.action.markFavorite.request,
        unmarkFavoriteMessageRequest: state.pages.akten.action.unmarkFavorite.request,
        unmarkMessageFavoriteId: state.pages.akten.action.unmarkFavorite.id,
        markMessageFavoriteId: state.pages.akten.action.markFavorite.id,
        filesLoaded: state.pages.akten.action.newFiles.succeed,
        newMessagesLoaded: state.pages.akten.action.newMessages.succeed,
        readRequest: state.pages.akten.action.readMessage.request,
        readRequestId: state.pages.akten.action.readMessage.id,
    }
}

const mapDispatchToProps = (dispatch) => ({
    markMessageAsFavorite: (accountId, messageId, params, messageKey) => {
        dispatch(markFavoriteMessageRequest(accountId, messageId, params, messageKey))
    },
    unmarkMessageAsFavorite: (accountId, messageId, params, messageKey) => {
        dispatch(unmarkFavoriteMessageRequest(accountId, messageId, params, messageKey))
    },
    readMessage: (accountId, messageId, akteIdHash, messageKey, rootKey, folderId) => {
        dispatch(readMessageRequest(accountId, messageId, akteIdHash, messageKey, rootKey, folderId))
    },
    downloadMessage: (accountId, messageId, header) => {
        dispatch(downloadMessageRequest(accountId, messageId, header))
    },
    onOpenGallery: (selectedId, selectedName, selectedAccountId, selectedParentId, akteIdHash, thumbnailsToShow) =>{
        dispatch(getFullFileById(selectedId, selectedName, {
            accountId: selectedAccountId, 
            storage: accessTokenRegistered
        }))
        dispatch(showGallery(selectedId, selectedName, selectedAccountId, selectedParentId, akteIdHash, thumbnailsToShow))
    },
    downloadAttachment: (fileId, fileName, accountId) => dispatch(downloadFile(fileId, fileName, accountId)),
    setPayloadFavoriteMessages: (payload) => dispatch(setPayloadMessageFavorite(payload)),
    deletePayloadFavoriteMessages: (messageId) => dispatch(deletePayloadMessageFavorite(messageId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatBubbleComponent)
