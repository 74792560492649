import React from 'react'
import * as Fragments from '../fragments'
import { FlexGrid } from 'application/components/fragments/grid'

const ContactCompany = (props) => {
    const { useMobile = false, textColor, accountPublicInfo } = props
    const { contact: {street, city, postalCode, contact, website = window.location.href} } = accountPublicInfo
    const sizeText = useMobile ? '12px' : '14px'
    const alignText = useMobile ? 'left' : 'right'
    const companyWebsite = website === '' || website === null ? window.location.href : website

    return <FlexGrid directionColumn>
                <Fragments.AsclarText 
                    color={textColor} 
                    fontSize={sizeText}
                    margin={'5px 0'} 
                    textAlign={alignText}
                ><a href={companyWebsite}>{companyWebsite}</a></Fragments.AsclarText>
                {
                    contact && <Fragments.AsclarText 
                        color={textColor} 
                        fontSize={sizeText}
                        margin={'5px 0'} 
                        textAlign={alignText}
                    >{contact}</Fragments.AsclarText>
                }
                <Fragments.AsclarText 
                    color={textColor} 
                    fontSize={sizeText}
                    margin={'5px 0'} 
                    textAlign={alignText}
                >{street},&nbsp;&nbsp;{postalCode}&nbsp;&nbsp;{city}</Fragments.AsclarText>
            </FlexGrid>
}

export default ContactCompany
