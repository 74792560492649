import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormCheckUsername from '../../wizard/registration/username/sections/form_check_username'
import TitleWizard from '../../wizard/registration/_common/title_wizard'
import TopContentWizardReg from '../../wizard/registration/_common/top_content'
import * as WizardMobileStyled from './styles'
import ActionButton from 'application/components/controls/button/action_button/component'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { Spacer } from 'application/components/pages/internal/dashboard/fragments'
import { useTheme } from 'styled-components'


const UsernameWizardMobile = props => {
    const { wizardData, onUpdateWizardPrev, handleSubmit, onSubmitSuccess, register, validationMethods, errors, usernameNotValid, usernameSet, usernameRequest, errorMessage, akteMeta } = props
    const {t} = useTranslation()
    const theme = useTheme()

    return <WizardMobileStyled.Layout>
        <WizardMobileStyled.Container>
            <TopContentWizardReg useMobile withBackStep backStepClik={() => onUpdateWizardPrev(2, 1)} wizardData={wizardData} />
            <WizardMobileStyled.Container>
                <TitleWizard step={2} headline={t('EsyMandantRegistration')} desc={t('EsyMandantRegistrationSubTitle')} spacerHeadlineAndStep='30px' akteMeta={akteMeta} />
                <FlexDividerHorizontal />
                <FormCheckUsername
                    useMobile
                    handleSubmit={handleSubmit}
                    onSubmitSuccess={onSubmitSuccess}
                    register={register}
                    validationMethods={validationMethods}
                    errors={errors}
                    usernameNotValid={usernameNotValid}
                    usernameSet={usernameSet}
                    usernameRequest={usernameRequest}
                    errorMessage={errorMessage}
                />
            </WizardMobileStyled.Container>
        </WizardMobileStyled.Container>
        <WizardMobileStyled.Container>
            <FlexDividerHorizontal />
            <Spacer distance='20px' />
            {
                !usernameRequest ?
                    <ActionButton
                        backgroundColor={theme.color.primary}
                        buttonText={t('NextButtonText')}
                        onButtonClick={handleSubmit(onSubmitSuccess)}
                        strechted
                        borderradius='10px'
                    /> : <PreloaderSpin
                        primaryColor={theme.color.primary}
                    />
            }
        </WizardMobileStyled.Container>
    </WizardMobileStyled.Layout>
}

export default UsernameWizardMobile
