import { STORAGE } from 'application/constants'

export const consentStorage = {
    store: (dateAccept) => {
        localStorage.removeItem(STORAGE.CONSENT_USE_APP)
        localStorage.setItem(STORAGE.CONSENT_USE_APP, dateAccept)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.CONSENT_USE_APP)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.CONSENT_USE_APP)
    }
}