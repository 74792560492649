import IconLink from 'application/components/controls/icon/icon_link'
import React from 'react'
const { default: styled } = require('styled-components')

export const TitleRowAkteDetails = styled.span`
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-family: ${props => props.theme.fontFamily.secondary};
    margin-right: 8px;
    margin-top: 5px;
`

export const SubTitleRowAkteDetails = styled.span`
    color: ${props => props.theme.color.color40};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`

export const DateSidebarAkteDetails = styled.span`
    color: ${props => props.theme.color.color30};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.medium};
    font-style: ${props => props.theme.fontStyle.italic};
`

export const ArrowForward = ({ onClick, color, size }) => {
    return <IconLink iconKey='arrow_forward' onCLick={onClick} color={color} size={size} />
}