import { FlexGrid } from 'application/components/fragments/grid'
import LoginPinFormContentAesthetic from 'application/components/pages/external/mobile_assistant/login_pin/sections/content_aesthetic'
import LoginPinFormSection from 'application/components/pages/external/mobile_assistant/login_pin/sections/login_form'
import React from 'react'
import { Fragment } from 'react'

export const LoginPinFormTabletLandscape = (props) => {
    return <Fragment>
        <FlexGrid>
            <LoginPinFormSection {...props} />
            <LoginPinFormContentAesthetic largeImage={'60%'} heightImage='100%' />
        </FlexGrid>
    </Fragment>
}

export default LoginPinFormTabletLandscape
