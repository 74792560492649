import React from 'react'
import { FlexGrid } from 'application/components/fragments/grid'
import styled from 'styled-components'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { PluginAlternateView } from 'plugins'

const Wrapper = styled.div`
    padding: 16px;
`

const NotFoundPage = () => {

    return <PluginAlternateView id='notfound.bigfoot'>
        <React.Fragment>
            <FlexGrid justifyCenter direcctionColumn>
                <Wrapper>
                    <ImagePlaceHolder
                        withBorder={false}
                        large='450px'
                        source='/assets/images/notfound.png'
                    />  
                </Wrapper>
            </FlexGrid>
        </React.Fragment>
    </PluginAlternateView>

}

export default NotFoundPage