export const PERMISSIONS = {
    LOGIN_CREATE: 'login:create',
    LOGIN_READ: 'login:read',
    LOGIN_READ_ANY: 'login:read_any',
    LOGIN_UPDATE: 'login:update',
    LOGIN_UPDATE_ANY: 'login:update_any',
    LOGIN_DELETE: 'login:delete',
    LOGIN_DELETE_ANY: 'login:delete_any',
    ACCOUNT_CREATE: 'account:create',
    ACCOUNT_READ_ANY: 'account:read_any',
    ACCOUNT_UPDATE_ANY: 'account:update_any',
    ACCOUNT_LIST: 'account:list',
    ACCOUNT_LIST_ALL: 'account:list_all',
    ACCOUNT_ADD_USERS: 'account:add_users',
    ACCOUNT_ADD_USERS_ANY: 'account:add_users_any',
    USER_READ_ALL: 'user:read_all'
}