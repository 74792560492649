import config from './config'

const common = {
  color: {
    primary: '#2C4490',
    secondary: '#F5BFB8',
    preloaderColor: '#2C4490',
    footerColor: '#2C4490',
    backgroundColorPin: '#dce3fa',
    textColorPin: '#2C4490',
    success: '#F5BFB8',
    redDot: '#2c448c',
    dividerTitle: '#2c448c',
    dividerWrapper: '#dce3fa',
    policyTextColor: '#2c448c',
    filterPill: '#2C4490',
    requestPin: '#bc3640',
    checked: '#2C4490',
    counterColor: '#2C4490',
  },

  fontFamily: {
    primary: 'Verdana',
    secondary: 'Marcellus',
    assistantHeadline: 'Marcellus',
    assistant: 'Verdana',
  },
  loginRightImage: `${config.prod.assetPath}/image_login.png`,
  companyIcon: `${config.prod.assetPath}/myk-law.png`,
  companyName: 'Kanzlei MYK',
  title: 'Kanzlei MYK',
  favicon: '/assets/images/myk-law/favicon.png',
  navigationMessagesIconKey: 'home',
}

export default {
  ...common,
  classes: {
    loginTitle: {
      color: common.color.primary,
      fontFamily: common.fontFamily.secondary,
    },
    succeedContainer:{
      display:'flex',
      justifyContent: 'center',
      minWidth: '100vw'
    },
    loginSubtitle: {
      fontFamily: common.fontFamily.secondary
    },
    loginButton: {
      background: common.color.primary,
      fontFamily: common.fontFamily.primary,
      borderRadius: '6px',
      border: 'none',
    },
    lawBannerText: {
      fontFamily: common.fontFamily.secondary,
    },
    lawBannerAgreement: {
      color: common.color.primary,
      fontFamily: common.fontFamily.secondary
    },
    forgotButton: {
      color: 'black'
    },
    wizardHeadline:{
      fontFamily: common.fontFamily.primary
    },
    wizardPar:{
      fontFamily: common.fontFamily.secondary,
    }
  }
}