import { textHelpers } from 'application/common'
import GravatarWithInitial from 'application/components/controls/gravatar'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import React from 'react'
import { withTheme } from 'styled-components'
import CreatorProfilePictureLoader from './creator_profile_picture_loader'
import CreatorProfilePictureRegister from './creator_profile_picture_register'

const CreatorProfilePictureComponent = (props) => {
    const {
        profPicLoaded,
        hasProfPic,
        mainColor,
        theme,
        profilePicture,
        large,
        creatorFirstName,
        creatorLastName,
        aktenPayload,
        borderRadius = '100%',
    } = props
    const initials = aktenPayload.length > 0 && textHelpers.getInitials(creatorFirstName, creatorLastName)
    return (
        <CreatorProfilePictureRegister {...props}>
            <CreatorProfilePictureLoader {...props}>
                {!profPicLoaded || !hasProfPic ? (
                    <GravatarWithInitial
                        initial={initials}
                        borderRadius={borderRadius}
                        backgroundcolor={mainColor !== '' ? theme.color.gravatar || mainColor : theme.color.mainAkte}
                        large={large}
                    />
                ) : (
                    <React.Fragment>
                        <ImagePlaceHolder
                            radius={borderRadius}
                            isSizeFixed={true}
                            backgroundImageUrl={profilePicture}
                            widthOnFixed={large + 'px'}
                            heightOnFixed={large + 'px'}
                        />
                    </React.Fragment>
                )}
            </CreatorProfilePictureLoader>
        </CreatorProfilePictureRegister>
    )
}

export default withTheme(CreatorProfilePictureComponent)
