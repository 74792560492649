import { AccountPublicInfoInternalLoader } from 'application/common'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { accessTokenRegistered } from 'application/storage'
import React, { Fragment } from 'react'
import ActivationModeServiceProvider from './activation'
import ServiceProviderDetailSidebarDefault from './default'
import { useQuery } from 'application/components/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

const ServiceProviderDetailSidebarComponent = (props) => {
    const { selectedAccount, onDisplayStatement } = props
    let navigate = useNavigate()
    let {search} = useLocation()
    const { isActivated } = selectedAccount
    const userRegistered = accessTokenRegistered.get() !== ''
    const handleOpenAgbAccount = (statement, title) => {
        onDisplayStatement(statement, title)
    }

    const handleDisplayDatenshutz = (statement, title) => {
        onDisplayStatement(statement, title)
    }
    const paramsObj = Object.fromEntries(new URLSearchParams(search))
    const activateModeToFalse = () => {
        const newQueryParams = {
            ...paramsObj ,
            activateMode: '0'
        }
        let searchParams = new URLSearchParams(newQueryParams)
        navigate({
            pathname: ROUTES.DASHBOARD,
            search: searchParams.toString()
        })
    }
    const activateModeToTrue = () => {
        const newQueryParams = {
            ...paramsObj ,
            activateMode: '1'
        }
        let searchParams = new URLSearchParams(newQueryParams)
        navigate({
            pathname: ROUTES.DASHBOARD,
            search: searchParams.toString()
        })
    }

    const query = useQuery()
    const isActivateModeParams = query.getStringParam(ROUTE_PARAMETERS.DASHBOARD.ACTIVATE_MODE) === '' ? false : query.getStringParam(ROUTE_PARAMETERS.DASHBOARD.ACTIVATE_MODE) === '1'

    const funcLaw = {
        handleDisplayDatenshutz,
        handleOpenAgbAccount,
        activateModeToFalse,
        activateModeToTrue
    }
    return isMobileOnly ? <Fragment /> : <AccountPublicInfoInternalLoader {...props}>
        {
            isActivated ? <ServiceProviderDetailSidebarDefault isActivateModeParams={isActivateModeParams} {...funcLaw} {...props} /> : <ActivationModeServiceProvider isActivateModeParams={isActivateModeParams} userRegistered={userRegistered} {...funcLaw} {...props} />
        }
    </AccountPublicInfoInternalLoader> 
}

export default ServiceProviderDetailSidebarComponent