import { DIALOG } from 'application/constants'
import { refreshAccountPublicInfo } from 'application/redux/actions/common/account_details'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import AccountsCardEasyMode from './component'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
	refreshPublicInfoAccount: () => dispatch(refreshAccountPublicInfo()),
	openDialogToactivateAccount: (accountId, accountName, esyThingAgb, esyThingDatenschutz) => {dispatch(openDialogByName(DIALOG.NAME.ACTIVATE_MODE_MOBILE, true, {accountId, accountName, esyThingAgb, esyThingDatenschutz}, 'Activate provider', DIALOG.SCREEN.MOBILE))}
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountsCardEasyMode)