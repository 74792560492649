import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import * as Fragment from '../fragments'
import CountIndicator from 'application/components/controls/count_indicator'
// import IconLink from 'application/components/controls/icon/icon_link'
import { useTranslation } from 'react-i18next'

const CountSection = ({ countIndicator = [], withIcon = true, iconLink = [] }) => {
    const { t } = useTranslation()
    return <FlexGridItem grow={0.06} shrink={0}>
        <FlexGrid itemsCenter justifySpaceBetween>
            <Fragment.TotalCountWrapper>
                {
                    countIndicator.map((c, i) => {
                        return <CountIndicator 
                            key={i}
                            count={c.count}
                            label={t(c.label)}
                            clickable={c.clickable}
                            to={c.to}
                        />
                    })
                }
            </Fragment.TotalCountWrapper>
           
        </FlexGrid>
    </FlexGridItem>
}

export default CountSection