import { useTranslation } from 'react-i18next'
import * as Fragments from '../../../../../application/components/building_blocks/messages/message_details/content/fragments'

export function MobileViewAktenFavorites() {
    const {t} = useTranslation()
    return <div style={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
     }}>
        <Fragments.ParNoContent>{t('No favorites have been saved yet.')}</Fragments.ParNoContent>        
    </div>
}