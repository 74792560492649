import ACTIONS from 'application/constants'
import default_store from '../../../default_store'

const aktenDetailsReducer = (state = default_store.pages.akten.details, action) => {
    switch (action.type) {
    case ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS: return {
        ...state,
        isRequest: true,
    }
    case ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS_FAILED: return {
        ...state,
        isRequest: false,
        isLoaded: true,
        isFailed: true,
    }
    case ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS_ON_FOLDER: return {
        ...state,
        onFolderIsRequest: true,
    }
    case ACTIONS.PAGES_AKTEN_REFRESH_MESSAGE_ON_ROOT: return {
        ...state,
        isLoaded: false,
    }
    case ACTIONS.PAGES_AKTEN_REFRESH_MESSAGE_ON_FOLDER: return {
        ...state,
        onFolderIsLoaded: false,
        isLoaded: false,
    }
    case ACTIONS.PAGES_AKTEN_POPULATE_MESSAGES: return {
        ...state,
        isRequest: false,
        isLoaded: true,
        messages: action.payload,
        messageCount: action.count
    }
    case ACTIONS.PAGES_AKTEN_POPULATE_FAVORITE_MESSAGES: return {
        ...state,
        messageFavorite: action.payload
    }
    case ACTIONS.PAGES_AKTEN_POPULATE_MESSAGES_ON_FOLDER: return {
        ...state,
        onFolderIsRequest: false,
        onFolderIsLoaded: true,
        isloaded: true,
        messages: action.payload,
        messageCount: action.count
    }
    case ACTIONS.PAGES_AKTEN_REFRESH_MESSAGES_DETAILS: return {
        ...state,
        messages: [],
        messageCount: 0
    }
    
    case ACTIONS.PAGES_AKTEN_POPULATE_FILES_ON_QUEUE: return {
        ...state,
        filesOnQueue: action.files
    }
    case ACTIONS.PAGES_AKTEN_ADD_FILES_ON_QUEUE: return {
        ...state,
        filesOnQueue: state.filesOnQueue.concat(action.files)
    }
    case ACTIONS.PAGES_AKTEN_DELETE_FILE_ON_QUEUE_BY_ID:
        const updatedFiles = state.filesOnQueue.filter(f => f.attachment.fileId !== action.fileId)
        return {
            ...state,
            filesOnQueue: updatedFiles
        }
    case ACTIONS.PAGES_AKTEN_DELETE_FILE_TO_SEND_BY_ID:
        const updatedFilesToSend = state.filesToSend.filter(f => f.fileuploadId !== action.fileId)
        return {
            ...state,
            filesToSend: updatedFilesToSend
        }
    case ACTIONS.PAGES_AKTEN_POPULATE_FILES_TO_SEND: return {
        ...state,
        filesToSend: [
            ...state.filesToSend,
            action.files
        ]
    }
    case ACTIONS.PAGES_AKTEN_CLEAR_ALL_FILES_TO_SEND: return {
        ...state,
        filesToSend: []
    }
    case ACTIONS.PAGES_AKTEN_CLEAR_ALL_FILES_ON_QUEUE: return {
        ...state,
        filesOnQueue: []
    }
    case ACTIONS.PAGES_AKTEN_MESSAGE_READ_CONDITION: return {
        ...state,
        messages: state.messages.map(d => {
            return d.id === action.messageId ? {
                ...d,
                read: true
            } : d
        })
    }
    case ACTIONS.PAGES_AKTEN_PUT_MESSAGE_AS_FAVORITE: return {
        ...state,
        messages: state.messages.map(d => {
            return d.id === action.messageId ? {
                ...d,
                isFavorite: action.condition
            } : d
        })
    }
    default:
        return state
    }
}

export default aktenDetailsReducer