import { ERROR_TYPE_CODE } from 'application/constants/errors'
import { HandlePush, formLoginAttempt, formWithPin, getFormElementsFailed, getFormElementsRedirected, populateFormMetaData } from 'application/redux/actions/form'
import { put } from 'redux-saga/effects'

export function* getFormErrorSaga(action, e) {
    let reasonMessage = e.response.status === ERROR_TYPE_CODE.UNAUTHORIZED ? 'FalsePin' : e.data.reasonMessage
    reasonMessage = e.response.status === ERROR_TYPE_CODE.LOCKED ? 'Invalid PIN. Please request a new PIN' : reasonMessage
    const errorPayload = {
        ...e.data,
        reasonMessage
    }
    yield put(populateFormMetaData({ responseInfo: errorPayload }))
    if (e.response.status === ERROR_TYPE_CODE.UNAUTHORIZED || e.response.status === ERROR_TYPE_CODE.LOCKED) {
        yield put(getFormElementsRedirected())
        yield put(formWithPin(true))
    }

    if (e.response.status === 400) {
        yield put(HandlePush('/', true))
    }

    

    yield put(getFormElementsFailed())
    
    yield put(formLoginAttempt(action.loginAttempt + 1))
}