import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import { TextInputWithLabel } from '..'

const TextInputPassword = ({
    label,
    name,
    register,
    validate = {},
    error,
    hideValidationMessage = false,
    defaultValue = '',
    placeholder = '',
    onChange = () => { },
    showMarkLabel = true,
    pushBottom = false,
    width,
    disabled = false,
    errorMessageOverride,
    onInputBlur = () => { },
    onInputFocus = () => { },
}) => {
    const [show, setShow] = useState(false)
    const type = show ? 'text' : 'password'
    const iconKey = show ? 'visibility_off' : 'visibility'
    return <TextInputWithLabel
        hideValidationMessage={hideValidationMessage}
        error={error}
        label={label}
        name={name}
        register={register}
        validate={validate}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        showMarkLabel={showMarkLabel}
        pushBottom={pushBottom}
        width={width}
        disabled={disabled}
        inputType={type}
        withIcon={true}
        iconKey={iconKey}
        errorMessageOverride={errorMessageOverride}
        onIconClick={() => setShow(!show)}
        htmlId={nanoid()}
        onInputFocus={onInputFocus}
        onInputBlur={onInputBlur}
    />
}

export default TextInputPassword