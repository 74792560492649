import { closeSelectedAccountDetails } from 'application/redux/actions/common/account_details'
import { connect } from 'react-redux'
import DashboardSidebarEffects from './effects'

const mapStateToProps = state => ({
    allAkten: state.common.allAkten.payload,
    allAccounts: state.common.allAccounts.payload,
    accountDetailsVisible: state.common.accountDetails.accountDetailsVisible,
    selectedAccount: state.common.accountDetails.selectedAccount,
})

const mapDispatchToProps = dispatch => ({
    closeSelectedAccountDetails: () => closeSelectedAccountDetails()
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardSidebarEffects)