import { ROUTES } from 'application/constants'
import React from 'react'
import { Navigate } from 'react-router-dom'

const RedirectPasswordDone = (props) => {
    const { newPasswordSucceed, children } = props

    if (newPasswordSucceed) return <Navigate to={ROUTES.RESET_PASSWORD_DONE} />

    return <React.Fragment>
        {children}
    </React.Fragment>
}

export default RedirectPasswordDone