import React from 'react'
import { Fragment } from 'react'
import { isTablet, withOrientationChange } from 'react-device-detect'

const RedirectTablet = ({ TabletCompoentLandscape = null, TabletCompoentPortrait = null, ...props }) => {
    const { children, isLandscape, isPortrait } = props

    if (isTablet) {
        if (isLandscape && TabletCompoentLandscape !== null) {
            return <TabletCompoentLandscape {...props} />
        }
        if (isPortrait && TabletCompoentPortrait !== null) {
            return <TabletCompoentPortrait {...props} />
        }
    }

    return <Fragment>
        {children}
    </Fragment>
}

export default withOrientationChange(RedirectTablet)
