import { downloadFile, getDownloadUrl } from 'application/api/helpers'
import { API_VERSION_ESYMANDANT, fetchHandler, fetchRequestBuilder, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { handleApiFilestreamResponse } from 'application/api/response_handlers'
import { accessTokenUnregistered } from 'application/storage'

export const downloadAgbByAccountIdUnregistered = (accountId, accountName) => {

    const request = fetchRequestBuilder(API_VERSION_ESYMANDANT + '/e.consult.' + accountId + '/account/agb?resultType=stream', HTTP_METHOD.GET, {}, true, false, false, accessTokenUnregistered)

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiFilestreamResponse)
        .then(getDownloadUrl)
        .then(downloadUrl => {
            downloadFile(downloadUrl, accountName)
        })
}