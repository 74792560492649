export const en = {
  translation: {
    'Mandate Conditions': 'Mandate terms',
    'privacy' : 'Privacy policy',
    'agbText': 'Mandate terms',
    'agbTextFormula': 'Mandatsbedingungen',
    'LawBannerText1' : 'I agree with the',
    'LawBannerText2' : 'privacy policy',
    'LawBannerText3' : 'agreement.',

    'PasswordForgotSubHeadline': 'Please enter your username. You will receive an e-mail with a link to edit your password.',
    'ForgotUsernameSubHeadline': 'Please enter your e-mail address. We will send you an e-mail with your username.',
    'ForgotUsername': 'Forgotten your username?',
    'ForgotPassword': 'Forgotten your password?',
    'UsernamePlaceholderForgot': 'username',
    'email': 'e-mail address',
    'Profile picture, Password': 'Profile picture, password',
    'New Password': 'New password',
    'Good Morning!': 'Good morning.',
    'Good Afternoon!': 'Good afternoon.',
    'Good Evening!': 'Good evening.',
    'Account Settings': 'Account settings',
    'Search Akten': 'Search cases',
    'MessagesNavigationBar': 'Cases',
    'DashboardMobileNavigationBar': 'Cases',
    'Settings' : 'Settings',
    'PasswordResetLinkSuccess': 'Password reset link has been sent to your e-mail.',
    'UsernameResetLinkSuccess': 'Username has been sent to your e-mail.',
    'Akten': 'Files',
    
    'There Is No Task': 'Empty task',
    'EmptySharedMessageContent': 'Empty message',
    'NoCasesFound': 'Empty akte',

    'ValidUntil' : 'valid until',
    'ExpiredOn' : 'expired on',
    'AnsweredOn': 'answered on',

    'No folders have been created yet.': 'Empty folder',
    'No favorites have been saved yet.': 'Empty favorites',
    'No files': 'Empty files',
    'ErrorRedirectButtonText': 'Back to loginpage',

    'EsyMandantRegistration': 'Please create a username.',
    'EsyMandantRegistrationSubTitle':'',
    'EsyMandantPasswordConfirmation': 'Please create a secure password.',
    'EsyMandantSalutation': 'Welcome to Kanzlei MYK.',
    'EsyMandantSalutationText': '',
    'Herzlichen Glückwunsch!': 'Registration is complete.',
    'CongratulationText1':'',
    'FirstStep': 'Next', 

    'Forgot Username': 'Forgot username',
    'Forgot Password': 'Forgot password',

    'ResetPassword': 'Reset password',
    'NewPasswordPlaceholder' : 'New password',
    'NewPasswordConfirmationPlaceholder' : 'New password confirmation',
    
    'zum Dashboard': 'Online portal',

    'Enter a subject' : 'Please enter your subject.',
    'Add subject' : 'Subject',
    'Enter your message' : 'Please enter your message.',

    'CheckReceivedEmail': 'You received an e-mail from us.',

    'RequestPINAnother' : 'Request PIN again.',
  }
}