import React from 'react'
import FontIcon from 'application/components/controls/font_icon'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 40px 45px 25px 45px;
    position: relative;
    width: 100%;
`

const CloseButton = styled.button`
    position: absolute;
    right: 10px;
    top: 10px;
    width: 36px;
    height: 36px;
    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    border-radius: 50%;
    margin: 0;
`

const AutoReplyDialog = (props) => {
    const { dialog, abort } = props
        const { message } = dialog.payload
    return <Container>
        <p style={{ fontWeight:'normal', fontSize: '17px', textAlign:'justify' }}>{message}</p>
        <CloseButton onClick={() => abort()}>
            <FontIcon icon={'clear'} color='gray' isClickable={true}/>
        </CloseButton>
    </Container>


}

export default AutoReplyDialog