import { textHelpers } from 'application/common'
import FontIcon from 'application/components/controls/font_icon'
import { StyledDarkDot } from 'application/components/fragments/red_dot'
import { motion } from 'framer-motion'
import React, { useEffect, useRef }  from 'react'
import ReactTooltip from 'react-tooltip'
import styled, { withTheme } from 'styled-components'
import { ElementTree, NodeText } from './fragments'
import IconTree from './icon'
import WrapperTree from './wrapper'

const IconWrapper = styled(motion.div)`
    padding-right: 0px;
    display: flex;
    justify-content: flex-start !important;
        &:hover {
        background: ${props => props.descendantsLength > 0 ? props.theme.color.color20 : 'transparent'};
        border-radius: 3px;
    }
`

const OverrideStyledTootlipName = styled.span`
    font-weight: ${props => props.theme.fontWeight.medium} !important;
    font-size: ${props => props.theme.fontSize.small} !important;
    color: ${props => props.theme.color.color5} !important;
`

const StyledTriggerTooltip = styled.a`
    text-decoration: none;
`

const NodeElementTree = (props) => {
    const {
        data,
        isOpen,
        isRoot,
        toggle,
        onSelect,
        selected,
        level,
        iconSet,
        theme,
        noIcons,
        descendants,
        folderId,
        breadcrumbsData,
        totalUnreadMessage,
    } = props

    

    const dataIdRef = useRef(`${data.id}_${isOpen}`)
    const id = data.id
    const cond = isOpen
    const found = breadcrumbsData.filter(b => b.id === id).length > 0
    const currentFold = breadcrumbsData.length > 0 && breadcrumbsData[breadcrumbsData.length - 1].id === id
    useEffect(() => {
        dataIdRef !== null &&
            breadcrumbsData &&
            found &&
            !cond &&
            !currentFold &&
            dataIdRef.current.click()
    }, [breadcrumbsData, cond, currentFold, data.id, found, isOpen])
    
    if (data === null || data.length === 0) {
        return null
    }

    const folderSelected = selected && selected.id === folderId && selected.id === data.id
    const Icon = () => {
        if (totalUnreadMessage > 0) {
            return <FontIcon icon='mark_chat_unread' fontSize={theme.fontSize.headline3} color={theme.color.black} />
        } else {
            if (descendants.length !== 0) {
                return iconSet
            } else {
                return <FontIcon icon='folder_open' fontSize={theme.fontSize.headline2} color={folderSelected ? theme.color.salmon : theme.color.color40} />
            }
        }
    }
    const nodeName = textHelpers.truncateMiddleText(data.name, (15))
    return <ElementTree
        isOpen={isOpen}
        isRoot={isRoot}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onSelect(e, data)
        }}
        selected={folderSelected}
    >
        <WrapperTree level={level}>
            {
                !noIcons && descendants.length !== 0 ? <React.Fragment>
                    <IconWrapper descendantsLength={descendants.length} ref={dataIdRef} onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        toggle()
                        isOpen && found && onSelect(e, data)
                    }} animate={{ rotate: isOpen ? 90 : 0 }}>
                        <IconTree
                            icon={<FontIcon icon='chevron_right' color={theme.color.color70} fontSize={theme.fontSize.standard} />}
                            size={theme.fontSize.standard}
                            noPaddRight
                        />
                    </IconWrapper>
                </React.Fragment> : <IconWrapper descendantsLength={descendants.length}>
                    <StyledDarkDot />
                </IconWrapper>
            }
            <IconTree
                icon={Icon()}
                size={theme.fontSize.medium}
                selected={folderSelected}
                totalUnreadMessage={totalUnreadMessage}
            />
            
            <NodeText selected={folderSelected}>
                {
                    data.name.length > 15 && folderSelected ? <StyledTriggerTooltip data-tip data-for={`treeName${folderId}`}>
                        {nodeName}
                    </StyledTriggerTooltip> : <span>
                        {nodeName}
                    </span>
                }
                
            </NodeText>
            {
                data.name.length > 15 && folderSelected && <ReactTooltip place='top' effect='solid' id={`treeName${folderId}`}>
                    <OverrideStyledTootlipName>
                        {data.name}
                    </OverrideStyledTootlipName>
                </ReactTooltip>
            }
        </WrapperTree>
    </ElementTree>
}

NodeElementTree.defaultProps = {
    data: null,
    toggle: () => { },
    isOpen: false,
    isRoot: false,
    level: 0,
    selected: null,
    onSelect: () => { },
    iconSet: null,
    noIcons: false,
    descendants: [],
    folderId: ''
}

export default withTheme(NodeElementTree)