import React, {useState, useRef, useEffect} from 'react'
import { Document, Page, pdfjs  } from 'react-pdf'
import styled from 'styled-components'
import { FlexGrid } from 'application/components/fragments/grid'
import PreloaderSpin from 'application/components/controls/preloader/spin'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const StyledDocument = styled.div`
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    & .react-pdf__Document, & .react-pdf__Page{
        max-width: 100%;
        margin: auto;
    }

    & .react-pdf__Page__canvas{
        margin: 20px auto;
        border: 1px solid ${props => props.theme.color.color15};
    }
`

const PdfViewerComponent = ({ fileType, isSizeFixed, filePath, errorComponent = null, withCaption = false, fileName }) => {
    const [pageCount, setPageCount] = useState(1)
    const [pageWidth, setPageWidth] = useState(0)

    const documentRef = useRef(null)
    useEffect(() => {
        if (!documentRef.current || !documentRef.current.getBoundingClientRect().width){
            return
        }
        setPageWidth(documentRef.current.getBoundingClientRect().width - Math.round(documentRef.current.getBoundingClientRect().width / 10))
    }, [documentRef])
    return (
        <StyledDocument ref={documentRef} >
            <Document
                file={filePath}
                loading={<FlexGrid justifyCenter itemsCenter><PreloaderSpin /></FlexGrid>}
                onLoadSuccess={(pdf) => { setPageCount(pdf._pdfInfo.numPages) }}
                onLoadError={console.error}
            >
                                {
                    Array(pageCount).fill().map((index, i) => {
                        return <Page key={i} pageNumber={i + 1} width={pageWidth} />
                    })
                }
            </Document>
        </StyledDocument>
    )
}

export default PdfViewerComponent