import React, { useContext } from 'react'
import * as Fragment from '../auth/fragments'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { useTheme } from 'styled-components'

const LogoAuth = ({ accountId }) => {
    const { config } = usePluginsContext()
    const theme = useTheme()

    return <Fragment.LogoWrapper>
        <Fragment.StyledBigLogo>
            <Fragment.StyledImageBigLogo
                src={theme.companyIcon}
                alt={config.uniqueAccountKey}
            />
        </Fragment.StyledBigLogo>
    </Fragment.LogoWrapper>
}

export default LogoAuth
