import React from 'react'

export const Form = ({onSubmit, children}) => {

    const handleOnSubmit = e => {
        e.preventDefault()
        onSubmit(e)
    }

    return <form autoComplete='off' style={{ width: '100%' }} onSubmit={handleOnSubmit}>
        {children}
    </form>
}

export default Form