import styled from 'styled-components'

export const Layout = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: ${p => p.height ? p.height : '90%'};
	overflow: auto;
`

export const Container = styled.div`
`
export const ContainerImage = styled.div`
    text-align: center;
    position: relative;
    height: 20rem;
    width: 20rem;
    margin: 0 auto;
`

export const Image = styled.img`
    width: 90%;
    height: auto;
`

export const TextLink = styled.p`
    text-decoration: underline;
    color: ${p => p.theme.color.color100};
    font-weight: ${p => p.theme.fontWeight.semiBold};
    font-size: ${p => p.theme.fontSize.small};
    text-align: right;
`

export const ParLine = styled.p`
	color: ${p => p.theme.color.color70};
	text-align: center;
	width: 100%;
`