import React from 'react'
import PropTypes from 'prop-types'
import ServiceProviderCardDefaultEasyMode from './default'
import NotActive from './not_active'

const AccountsCardEasyMode = ({isActivated, categories, address, website, uniqueAccountKey, mainColor, accountId, accountName, refreshPublicInfoAccount, openDialogToactivateAccount, esyThingAgb, esyThingDatenschutz}) => {
	return isActivated ? <ServiceProviderCardDefaultEasyMode categories={categories} uniqueAccountKey={uniqueAccountKey} website={website} mainColor={mainColor} address={address} accountId={accountId} accountName={accountName} refreshPublicInfoAccount={refreshPublicInfoAccount} /> : <NotActive 
		openDialogToactivateAccount={openDialogToactivateAccount}
		accountId={accountId}
		accountName={accountName}
		address={address}
		categories={categories}
		mainColor={mainColor}
		uniqueAccountKey={uniqueAccountKey}
		website={website}
		esyThingDatenschutz={esyThingDatenschutz}
		esyThingAgb={esyThingAgb}
	/>
}

AccountsCardEasyMode.propTypes = {
    categories: PropTypes.array,
    address: PropTypes.string,
    website: PropTypes.string,
    uniqueAccountKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
    isActivated: PropTypes.bool,
    accountId: PropTypes.number,
    accountName: PropTypes.string,
	refreshPublicInfoAccount: PropTypes.func,
	esyThingAgb: PropTypes.string,
	esyThingDatenschutz: PropTypes.string
}

export default AccountsCardEasyMode
