import { getCreatorProfilePicture, registerCreatorProfilePicture } from 'application/redux/actions/common/all_akten'
import { connect } from 'react-redux'
import CreatorProfilePictureComponent from './component'

const mapStateToProps = (state, props) => {
    const aktenPayload = state.common.allAkten.payload
    const profilePictureRegistered = state.files.profilePictureCreator.filter((p) => p.creatorIdHash === props.creatorIdHash && p.isLoaded).length > 0
    return {
        aktenPayload,
        files: state.files,
        auth: state.auth,
        allAktenLoaded: state.common.allAkten.isLoaded,
        allAkten: state.common.allAkten.payload,
        profPicLoaded: profilePictureRegistered,
        hasProfPic: profilePictureRegistered && state.files.profilePictureCreator.find((p) => p.creatorIdHash === props.creatorIdHash).has,
        profilePicture: profilePictureRegistered && state.files.profilePictureCreator.find((p) => p.creatorIdHash === props.creatorIdHash).data,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getCreatorProfilePicture: (accountId, creatorIdHash, akteIdHash) => {
        dispatch(getCreatorProfilePicture(accountId, creatorIdHash, akteIdHash))
    },
    registerCreatorProfilePicture: (accountId, creatorIdHash, akteIdHash) => {
        dispatch(registerCreatorProfilePicture(accountId, creatorIdHash, akteIdHash))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatorProfilePictureComponent)
