import { hideGalleryOnQueue, sendFilesMessage, setActiveFileOnQueue } from 'application/redux/actions/gallery'
import { deleteFilesonQueueAktenById, addFilesOnQueue } from 'application/redux/actions/pages/akten'
import { connect } from 'react-redux'
import AttachmentGalleryOnQueue from './on_queue'

const mapStateToProps = (state, ownProps) => ({
    gallery: state.gallery.onQueue,
    sendRequest: state.gallery.onQueue.send.request,
    filesOnQueue: state.pages.akten.details.filesOnQueue,
    waitingScreen: state.waitingScreen,
})

const mapDispatchToProps = dispatch => ({
    onSelectAttachment: (fileId, fileName, selectedData, accountId) => {
        dispatch(setActiveFileOnQueue(fileId, fileName, selectedData, accountId))
    },
    sendFiles: (accountId, files) => dispatch(sendFilesMessage(accountId, files)),
    onCloseGallery: () => dispatch(hideGalleryOnQueue()),
    clearFilesOnQueue: (fileId, files, accountId) => {
        dispatch(deleteFilesonQueueAktenById(fileId))
        if (files.length === 0) {
            dispatch(hideGalleryOnQueue())
        } else {
            dispatch(setActiveFileOnQueue(files[0].attachment.fileId, files[0].attachment.fileName, files[0].attachment.thumbnail, accountId))
        }
    },
    filesAdded: (files) => dispatch(addFilesOnQueue(files))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentGalleryOnQueue)