import React from 'react'
import PropTypes from 'prop-types'
import { DialogMainContent } from 'application/components/fragments/dialog'
import * as Fragments from './fragments'
import { MainLogoCompany } from 'application/components/building_blocks/company_logo'
import LawBanner from 'application/components/building_blocks/law_banner'
import LoadEffect from 'application/common/load_effect'
import ActionButton from 'application/components/controls/button/action_button/component'
import { useTranslation } from 'react-i18next'
import { DIALOG } from 'application/constants'
import { LawBannerContainer } from 'application/components/building_blocks/law_banner/fragments'
import styled, { withTheme } from 'styled-components'
import { Trans } from 'react-i18next'

const TextActivationSmall = styled.small`
    color: ${p => p.theme.color.anthracite};
    font-weight: 800;
    display: flex;
    text-align: center;
`

const ActivateProviderMobileScreen = ({ dialog, activateRequest, activateAccount, agbAndDatenschutzAccepted, checkedAccountId, theme }) => {
	let payload = dialog.payload
	let { accountId, accountName, esyThingAgb, esyThingDatenschutz } = payload
	let {t} = useTranslation()
    const hasLaw = esyThingAgb !== null || esyThingDatenschutz !== null
	const checked = accountId === checkedAccountId && agbAndDatenschutzAccepted
	const handleActivateAccount = () => {
        const accepted = hasLaw ? agbAndDatenschutzAccepted : true
        activateAccount(accountId, { agbAndDatenschutzAccepted: accepted, closeDialog: true, dialogName: DIALOG.NAME.ACTIVATE_MODE_MOBILE})
	}
	let companyName = accountName
  return (
	  <DialogMainContent>
		  <Fragments.StyledContentActivateProviderMobileScreen>
			  <Fragments.HeadlineWrapperActivateProviderMobileScreen>
				  <Fragments.StyledLogo>
					  <MainLogoCompany accountId={accountId} accountName={accountName} />
				  </Fragments.StyledLogo>
				  <Fragments.HeadlineTitleActivateProviderMobileScreen>
					  {accountName}
				  </Fragments.HeadlineTitleActivateProviderMobileScreen>
			  </Fragments.HeadlineWrapperActivateProviderMobileScreen>
			  {
				  hasLaw ?
					  <LawBanner 
                    hasAgb={esyThingAgb !== null}
                    hasDatenschutz={esyThingDatenschutz !== null}
                    esyThingAgb={esyThingAgb}
                    esyThingDatenschutz={esyThingDatenschutz}
                    companyName={companyName}
                    accountId={accountId}
			  />
					  : <LawBannerContainer borderColor={theme.color.mainAkte} backgroundColor={theme.color.secondaryAkte}>
                    <TextActivationSmall>
                        <Trans i18nKey="activateAccountText">
                            To activate your profile in the account of {{ companyName }}, please click "Activate"!
                        </Trans>
                    </TextActivationSmall>
                </LawBannerContainer>
			  }
			   <LoadEffect loading={activateRequest}>
                <ActionButton
                    onButtonClick={handleActivateAccount}
                    buttonText={t('Activate')}
					  disabled={hasLaw ? !checked : false}
					  borderradius='50px'
					  backgroundColor='#000'
					  strechted
                />
            </LoadEffect>
		  </Fragments.StyledContentActivateProviderMobileScreen>
	  </DialogMainContent>
  )
}

ActivateProviderMobileScreen.propTypes = {
	dialog: PropTypes.object,
	activateRequest: PropTypes.bool,
	agbAndDatenschutzAccepted: PropTypes.bool,
	checkedAccountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default withTheme(ActivateProviderMobileScreen)