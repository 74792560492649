import { ROUTES } from 'application/constants'
import { hashUserKey } from 'application/storage'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

const WizardRegistrationRedirect = (props) => {
    const { currentStep } = props
    let params = useParams()

    const { wizardPage } = params
    if (wizardPage !== currentStep)
        return <Navigate to={ROUTES.WIZARD_REG_BASE + '/' + hashUserKey.get() + '/' + currentStep} />

    return <React.Fragment>{props.children}</React.Fragment>
}

export default WizardRegistrationRedirect
