import styled from 'styled-components'

export const NavigationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    justify-content: flex-start;
`

export const LogoutWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
`