import React, { useEffect } from 'react'

const CreatorProfilePictureLoader = (props) => {
    const {
        children,
        auth,
        accountId,
        creatorIdHash,
        getCreatorProfilePicture,
        allAktenLoaded,
        files,
        akteIdHash
    } = props
    const registered = files.profilePictureCreator.filter(e => e.creatorIdHash === creatorIdHash && e.akteIdHash === akteIdHash).length > 0
    const loaded = files.profilePictureCreator.filter(e => e.creatorIdHash === creatorIdHash && e.isLoaded).length > 0
    const shouldLoad = accountId !== undefined && accountId !== '' && accountId !== null && !loaded && registered
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    useEffect(() => {
        allAktenLoaded
            && shouldLoad
            && loggedIn
            && getCreatorProfilePicture(accountId, creatorIdHash, akteIdHash)
    }, [accountId, akteIdHash, allAktenLoaded, creatorIdHash, getCreatorProfilePicture, loggedIn, shouldLoad])

    return <div>
        {children}
    </div>
}

export default CreatorProfilePictureLoader