import { call, put, takeEvery } from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { logoutRequest, writeToken } from 'application/redux/actions/auth'

function* regenerateSaga(action) {
    try {
        const refreshResponse = yield call(api.external.auth.postRefresh, action)
        yield put(writeToken(refreshResponse.accessToken))
        yield put(action.previousAction)
    } catch (e) {
        yield put(logoutRequest())
        //ToDo: get correct error message to show on login screen
        //yield put(showLoginError(e.data.errorKey))
    }
}

export function* regenerateWatcher() {
    yield takeEvery(ACTIONS.AUTH_REGENERATE, regenerateSaga)
}