import React from 'react'
import FormEffects from './form_effects'
import FormLoader from './form_loader'
import RedirectGetFormFailed from './redirect_get_form_failed'
import RedirectGetFormWithPin from './redirect_get_form_with_pin'
import { useRouteLocationForm } from '../context/use_route_location_form_context'

const PreActionsMobileAssitant = (props) => {
    const { children } = props
    const metaData = {
        ...useRouteLocationForm()
    }
    return <FormLoader {...props} {...metaData}>
        <RedirectGetFormFailed {...props}>
            <RedirectGetFormWithPin {...props}>
                <FormEffects {...props}>
                    {children}
                </FormEffects>
            </RedirectGetFormWithPin>
        </RedirectGetFormFailed>
    </FormLoader>
}

export default PreActionsMobileAssitant
