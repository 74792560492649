import LoadEffect from 'application/common/load_effect'
import LawBanner from 'application/components/building_blocks/law_banner'
import { LawBannerContainer } from 'application/components/building_blocks/law_banner/fragments'
import ActionButton from 'application/components/controls/button/action_button/component'
import { FlexGrid, FlexGridItem, PushBottom40 } from 'application/components/fragments/grid'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import { WrapperServiceProviderDetail } from '../fragments'
import CategoriesServiceProviderDetail from '../sub_component/categories'
import ContactDetailServiceProvider from '../sub_component/contact_detail'
import HeaderServiceProviderDetail from '../sub_component/header'
import MenuServiceProvider from '../sub_component/menu'

const TextActivationSmall = styled.small`
    color: ${p => p.theme.color.anthracite};
    font-weight: 800;
    display: flex;
    text-align: center;
`

const ActivationModeServiceProvider = (props) => {
    const { closeServiceProvideDetailSidebar, activateModeToFalse, checkedAccountId, selectedAccount, activateAccount, agbAndDatenschutzAccepted, theme, activateRequest, handleOpenAgbAccount, handleDisplayDatenshutz, isActivateModeParams, activateModeToTrue } = props
    const { esyThingAgb, esyThingDatenschutz } = selectedAccount
    const hasLaw = esyThingAgb !== null || esyThingDatenschutz !== null
    const { t } = useTranslation()
    useEffect(() => {
        !isActivateModeParams && activateModeToTrue()
    }, [activateModeToFalse, activateModeToTrue, isActivateModeParams])
    const handleActivateAccount = () => {
        const accepted = hasLaw ? agbAndDatenschutzAccepted : true
        activateAccount(selectedAccount.id, { agbAndDatenschutzAccepted: accepted})
    }
    const checked = selectedAccount.id === checkedAccountId && agbAndDatenschutzAccepted
    const companyName = selectedAccount.name
    return <WrapperServiceProviderDetail id='activationAccountComponentSidebar' isOverflow={true}>
        <MenuServiceProvider closeServiceProvideDetailSidebar={closeServiceProvideDetailSidebar} openAgb={handleOpenAgbAccount} openDatenshutz={handleDisplayDatenshutz} {...props}/>
        <FlexGridItem justifyCenter>
            <FlexGrid directionColumn>
                <HeaderServiceProviderDetail {...props} />
                <CategoriesServiceProviderDetail {...props} />
                <ContactDetailServiceProvider selectedAccount={selectedAccount} />
            </FlexGrid>
        </FlexGridItem>
        <PushBottom24 />
        {
            hasLaw ?
                <LawBanner
                    acceptToBottom={true}
                    hasAgb={esyThingAgb !== null}
                    hasDatenschutz={esyThingDatenschutz !== null}
                    esyThingAgb={esyThingAgb}
                    esyThingDatenschutz={esyThingDatenschutz}
                    companyName={selectedAccount.name}
                    accountId={selectedAccount.id}
                    borderColor={theme.color.mainAkte}
                    backgroundColor={theme.color.secondaryAkte}
                /> : <LawBannerContainer borderColor={theme.color.mainAkte} backgroundColor={theme.color.secondaryAkte}>
                    <TextActivationSmall>
                        <Trans i18nKey="activateAccountText">
                            To activate your profile in the account of {{ companyName }}, please click "Activate"!
                        </Trans>
                    </TextActivationSmall>
                </LawBannerContainer> 
        }
        <PushBottom40 />
        <FlexGridItem justifyCenter>
            <LoadEffect loading={activateRequest}>
                <ActionButton
                    onButtonClick={handleActivateAccount}
                    buttonText={t('Activate')}
                    disabled={hasLaw ? !checked : false}
                />
                <div id='activationAccountComponentSidebarButton' />
            </LoadEffect>
        </FlexGridItem>
        <PushBottom24 />
    </WrapperServiceProviderDetail>
}

export default withTheme(ActivationModeServiceProvider)
