import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { isTablet } from 'react-device-detect'

const RefreshRootMessagesEffects = (props) => {
    const { selectedAkte, folderId, refreshRegisteredMessage, messageKey, metaMessage, auth } = props
    const lastSync = metaMessage && dayjs(metaMessage.lastSync)
    const lastMessageDate = selectedAkte && dayjs(selectedAkte.lastMessageDate)
    const newMessageFoundedInAkte = metaMessage && selectedAkte && lastMessageDate.diff(lastSync) > 0
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    const shouldRefresh = selectedAkte !== null && newMessageFoundedInAkte && !isTablet && loggedIn
    useEffect(() => {
        shouldRefresh &&
        folderId === '' &&
            refreshRegisteredMessage(messageKey)
    }, [folderId,
        messageKey, refreshRegisteredMessage, shouldRefresh])
    
    return <React.Fragment>
        {props.children}
    </React.Fragment>
}

export default RefreshRootMessagesEffects