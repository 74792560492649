import { combineReducers } from 'redux'
import allAccounts from './all_accounts'
import allAkten from './all_akten'
import accountDetails from './account_details'
import searchTerm from './search_term'
import agbDatenschutz from './agb_datenschutz'
import accountPublicInfoInternalAssets from './account_public_info_internal_assets'

export default combineReducers({
    allAccounts,
    allAkten,
    accountDetails,
    searchTerm,
    agbDatenschutz,
    accountPublicInfoInternalAssets,
})