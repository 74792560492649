import * as React from 'react'
import { WrapperTreeStyled } from './fragments'


const WrapperTree = (props) => {
    const { level, children } = props
    return <WrapperTreeStyled level={level}>{children}</WrapperTreeStyled>
}

WrapperTree.defaultProps = {
    level: 0
}

export default WrapperTree