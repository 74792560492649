import { connect } from 'react-redux'

import NotesPasswordRegistrationDialog from './component'

const mapStateToProps = (state, props) => ({
    dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name),
})

const mapDispatchToProps = dispatch => ({})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotesPasswordRegistrationDialog)