import { connect } from 'react-redux'
import Dialogs from './component'
import { closeDialogByName } from 'application/redux/actions/dialogs'
// import { closeAttachmentDetailsDialog, clearDocumentsOnQueue } from 'application/redux/actions/attachments'
// import { clearCaseHistory, clearTaskHistory } from 'application/redux/actions/history'
// import { commentsClearStore } from 'application/redux/actions/comments'

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        dialogs: state.dialogs,
        galleryVisible: state.gallery.onQueue.isVisible || state.gallery.isVisible,
        waitingScreen: state.waitingScreen,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCloseDialog: name =>{ 
            dispatch(closeDialogByName(name))
            // dispatch(closeAttachmentDetailsDialog())
            // dispatch(clearCaseHistory())
            // dispatch(clearTaskHistory())
            // dispatch(clearDocumentsOnQueue())
            // dispatch(commentsClearStore())
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dialogs)