import React, { useContext } from 'react'
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect'
import * as Fragments from '../fragments'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid } from 'application/components/fragments/grid'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PluginAlternateView } from 'plugins'
import { useParams } from 'react-router-dom'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const Small = styled.small`
    font-size: ${p => p.theme.fontSize.small};
    font-style: ${p => p.theme.fontStyle.italic};
    margin-right: 8px;
    color: ${p => p.textColor};
    font-weight: ${p => p.theme.fontWeight.bold};
`


const FooterExternalForm = props => {
    const { accountPublicInfo, theme, customFormula, page } = props
    const { primaryColor, contact, esyThingAgb, esyThingDatenschutz, } = accountPublicInfo
    const hasAgb = esyThingAgb !== undefined && esyThingAgb !== null
	const hasDatenschutz = esyThingDatenschutz !== undefined && esyThingDatenschutz !== null
	const hasFullStatement = hasAgb && hasDatenschutz
	const hasContact = contact !== null
    let mainColor = primaryColor !== null ? primaryColor !== theme.color.primary ? primaryColor : theme.color.color90 : theme.color.color90
    // const rgbMainColor = hexToRgb(mainColor === theme.color.primary ? theme.color.color90 : mainColor)
    const textColor = '#fff'
    const {t} = useTranslation()
    const {activePlugin, config } = usePluginsContext()

    const getbaseUrl = () => {
        if (window !== 'undefined') {

            const url = window.location.href

            const arr = url.split('/guidance')

            return arr[0]
        }
    }

    // return <Fragments.FormFooterContainer mainColor={mainColor === theme.color.primary ? theme.color.color90 : mainColor} isMobile={isMobile}>
    //     <FlexGrid basis='30%' grow={0} justifyCenter itemsCenter>
    //         <Small textColor={textColor}>
    //             Powered by
    //         </Small>
    //         <ImagePlaceHolder withBorder={false} large='30px' source='/assets/images/icon_esy_one.png' />
    //     </FlexGrid>
    //     <FlexGrid noWrap itemsFlexEnd grow={1} justifySpaceBetween widthTablet='70%' width={isMobileOnly ? '50%' : isTablet ? '70%' : '20%'}>
    //         <Fragments.LinkFooterForm>
    //             <Fragments.FooterWelcomeNavlink textColor={textColor} target='_blank' href={process.env.REACT_APP_PRIVACY_LINK}>
    //                 {t('privacy')}
    //             </Fragments.FooterWelcomeNavlink>
    //         </Fragments.LinkFooterForm>
    //         <Fragments.LinkFooterForm>
    //             <Fragments.FooterWelcomeNavlink textColor={textColor} target='_blank' href={process.env.REACT_APP_AGB_LINK}>
    //                 {t('conditions')}
    //             </Fragments.FooterWelcomeNavlink>
    //         </Fragments.LinkFooterForm>
    //         <Fragments.LinkFooterForm>
    //             <Fragments.FooterWelcomeNavlink textColor={textColor} target='_blank' href={process.env.REACT_APP_IMPRINT_LINK}>
    //                 {t('legal notice')}
    //             </Fragments.FooterWelcomeNavlink>
    //         </Fragments.LinkFooterForm>
    //     </FlexGrid>
    // </Fragments.FormFooterContainer>
    return (
        <div style={{
            width: '100%',
            backgroundColor: (activePlugin && theme.color.footerColor) ? 
                theme.color.footerColor : 
                mainColor === theme.color.primary ? theme.color.color90 : mainColor,
            padding: '20px 10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>

            <div
                style={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '40px',
                    padding: !isMobile ? '80px 0' : '20px 0'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        fontSize: '12px',
                        fontWeight: 'normal',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}
                >
                    <PluginAlternateView id='mobile.assistant.footer.policy' textColor={textColor}>
                        <a href={process.env.REACT_APP_PRIVACY_LINK} target='_blank' rel='noopener noreferrer'
                            style={{
                                color: textColor,
                            }}
                        >
                            {t('privacy')}
                        </a>
                        <a href={process.env.REACT_APP_AGB_LINK} target='_blank' rel='noopener noreferrer'
                            style={{
                                color: textColor,
                            }}
                        >
                            {t('conditions')}
                        </a>
                        <a href={process.env.REACT_APP_IMPRINT_LINK} target='_blank' rel='noopener noreferrer'
                            style={{
                                color: textColor,
                            }}
                        >
                            {t('legal notice')}
                        </a>
                    </PluginAlternateView>  
                </div>
                {activePlugin && !isMobile ? (
                    <a href={config.logoLink} target='_blank' rel='noopener noreferrer'>
                        <img
                            src={config.companyIcon}
                            alt='logo'
                            width={config.companyIconWidth}
                        />
                    </a>
                ) : (    
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            justifyContent: 'center',
                        }}
                    >
                        {hasContact && !activePlugin && (
                            <a
                                href={getbaseUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            style={{
                                color: textColor,
                                fontSize: !isMobile ? '14px' : '12px',
                                textAlign: 'end',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            {getbaseUrl()}
                        </a>
                        )}
                        {/* {hasFullStatement && (
                            <div
                                style={{
                                    color: textColor,
                                    fontSize: !isMobile ? '14px' : '12px',
                                    textAlign: 'end',
                                }}
                            >{esyThingAgb + ' ' + esyThingDatenschutz}</div>
                        )} */}
                        {hasContact && !activePlugin && (
                            <div
                            style={{
                                color: textColor,
                                fontSize: !isMobile ? '14px' : '12px',
                                textAlign: 'end',
                            }}
                        >
                            {`${contact.street}, ${contact.postalCode} ${contact.city}`}
                            </div>
                        )}
                    </div>
                )}

            </div>

            <PluginAlternateView id="mobile.assistant.footer.powered">
                {/* {!customFormula &&   */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'end',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{
                            color: textColor,
                            fontSize: '14px',
                            paddingRight: '15px',
                            lineHeight: '1.5',

                        }}>
                            Powered By
                        </div>
                        <ImagePlaceHolder radius={'8px'} withBorder={false} source={textColor === 'black' ? '/assets/images/icon_esy_one.png' : '/assets/images/icon_esy_one_white.png'} large={'40px'} />
                    </div>
                {/* } */}
            </PluginAlternateView>
        </div>
    )
}

export default FooterExternalForm
