import React from 'react'
import styled from 'styled-components'
import EN from '../../../../assets/images/en.png'
import DE from '../../../../assets/images/de.png'
import { useTranslation } from 'react-i18next'

const StyledImageWithLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    align-content: center;
`

const StyledImageWrapper = styled.div`
    height: 16px;  
`

const StyledLabelWrapper = styled.div`
    flex: 1 1 auto;
    padding-left: 6px;
    padding-right: 6px;
    color: ${props => props.theme.color.color70};
    font-size: ${props => props.theme.fontSize.medium}
`

export const getImageSrc = source => {
    switch (source) {
    case 'en':
        return EN
    case 'de':
        return DE
    default:
        return EN
    }
}

// const getImageSrc = (source) => {
//     if(isFileExist(`../../../../assets/images/${source}.png`)) {
//         return `../../../../assets/images/${source}.png`
//     }
//     if(isFileExist(`../../../../assets/images/${source}.jpg`)) {
//         return `../../../../assets/images/${source}.jpg`
//     }
//     return null
// }

export const getLabel = source => {
    switch (source) {
    case 'en':
        return 'English'
    case 'de':
        return 'Deutsch'
    default:
        return 'English'
    }
}

const ImageWithLabel = ({ source, withLabel }) => {
    const {t} = useTranslation()
    return <StyledImageWithLabel>
        <StyledImageWrapper>
            <img src={getImageSrc(source)} alt={source} />
        </StyledImageWrapper>
        {
            withLabel &&
            <StyledLabelWrapper>
                <span>
                    {t(getLabel(source))}
                </span>
            </StyledLabelWrapper>
        }
    </StyledImageWithLabel>
}

export default ImageWithLabel