import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledNavlink = styled(NavLink)`
    text-decoration: none;

    &:active {
        color: ${p => p.theme.color.primary};
    }
`

const StyledCount = styled.div`
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-bottom: 8px;
`

const StyledLabel = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.regular};
    color: ${props => props.theme.color.color30};
`

const CountIndicator = ({ count, label, clickable = false, to = '' }) => {
    return clickable ? <StyledNavlink to={to}>
        <FlexGrid directionColumn>
            <FlexGridItem>
                <StyledCount>
                    {count}
                </StyledCount>
            </FlexGridItem>
            <FlexGridItem>
                <StyledLabel>
                    {label}
                </StyledLabel>
            </FlexGridItem>
        </FlexGrid>
    </StyledNavlink> : <FlexGrid directionColumn>
        <FlexGridItem>
            <StyledCount>
                {count}
            </StyledCount>
        </FlexGridItem>
        <FlexGridItem>
            <StyledLabel>
                {label}
            </StyledLabel>
        </FlexGridItem>
    </FlexGrid>
}

export default CountIndicator