import React from 'react'
import OnFilter from './on_filter'
import LayoutFilesSidebar from './_layout'

const FilesSidebarComponent = (props) => {
    return <React.Fragment>
        <LayoutFilesSidebar {...props}>
            <OnFilter />
        </LayoutFilesSidebar>
    </React.Fragment>
}

export default FilesSidebarComponent