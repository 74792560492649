import PreloaderSpin from 'application/components/controls/preloader/spin'
import { FlexGrid } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import Preloader3D from 'application/components/controls/preloader/3d'
import { useParams } from 'react-router-dom'

const LoadEffect = (props) => {
    const { loading, children, allAccounts } = props
    const { accountIdentity } = useParams()
    if (loading) {
        let identity = accountIdentity
        if (allAccounts) identity = allAccounts[0]?.uniqueAccountKey
        return <FlexGrid justifyCenter itemsCenter>
            <Preloader3D/>
        </FlexGrid>
    }

    return <Fragment>
        {children}
    </Fragment>
}

export default LoadEffect