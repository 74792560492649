import React from 'react'
import { StyledIconLink } from './fragments'

const IconWithLink = (props) => {
    const { color, className, onClick, noHover, iconSize, isActive, width, height, backgroundcolor, borderradius, title, iconKey, to } = props
    return <StyledIconLink color={color}
        onClick={onClick}
        className={className}
        to={to}
        fontSize={iconSize}
        selected={isActive}
        width={width}
        height={height}
        backgroundcolor={backgroundcolor}
        borderradius={borderradius}
        noHover={noHover}
    >
        <i className="material-icons" title={title}>{iconKey ? iconKey : 'perm_identity'}</i>
    </StyledIconLink>
}

export default IconWithLink
