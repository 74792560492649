import React from 'react'
import PropTypes from 'prop-types'
import CommonContentServiceProviderEasyMode from './common_content'
import ActionButtonLinkWithIcon from 'application/components/controls/button/action_button_with_icon/component'
import { StyledCardAccountsEasyMode } from './default'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

const NotActive = ({ categories, address, website, uniqueAccountKey, mainColor, accountId, accountName, openDialogToactivateAccount, theme, esyThingAgb, esyThingDatenschutz }) => {
	const {t} = useTranslation()
	return <StyledCardAccountsEasyMode>
		<CommonContentServiceProviderEasyMode 
			mainColor={mainColor}
			accountId={accountId}
			accountName={accountName}
			address={address}
			categories={categories}
			uniqueAccountKey={uniqueAccountKey}
			website={website}
		/>
		<ActionButtonLinkWithIcon 
			buttonText={t('Activate')}
			iconKey='lock'
			strechted
			borderradius={'50px'}
			secondary
			onButtonClick={() => openDialogToactivateAccount(accountId, accountName, esyThingAgb, esyThingDatenschutz)}
			backgroundColor={mainColor}
			justifyContent={'center'}
		/>
	</StyledCardAccountsEasyMode>
}

NotActive.propTypes = {
	categories: PropTypes.array,
	address: PropTypes.string,
	website: PropTypes.string,
	uniqueAccountKey: PropTypes.string,
	mainColor: PropTypes.string,
	accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	accountName: PropTypes.string,
	esyThingDatenschutz: PropTypes.string,
	esyThingAgb: PropTypes.string
}

export default withTheme(NotActive)
