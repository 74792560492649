import { call, put, takeEvery } from 'redux-saga/effects'

import { ACTIONS, NOTIFICATION } from 'application/constants'
import { succeedLoaded } from 'application/redux/actions/dialogs'
import { handleError } from 'application/redux/saga/errors'
import { getDialogDataSagas, sendDialogDataSagas } from './api_mappers'
import { showToast } from 'application/redux/actions/notifications'
import { hideWaitingScreen } from 'application/redux/actions/waiting_screen'

function* fetchDialogDataSaga(action) {
    try {
        const sagas = yield call(getDialogDataSagas, action.dialogName)

        for(const saga of sagas){
            yield saga(action)
        }

        yield put(succeedLoaded(action.dialogName))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* sendDialogDataSaga(action) {
    try {
        const sagas = yield call(sendDialogDataSagas, action.dialogName)
        for (const saga of sagas) {
            yield saga(action)
        }
    } catch (e) {
        yield handleError(e, action)
        yield put(showToast(action.metaData.errorMessage, NOTIFICATION.LEVEL.ERROR))
        yield put(hideWaitingScreen())
    }
}

export function* fetchDataDialogWatcher() {
    yield takeEvery(ACTIONS.DIALOG_REQUEST_DIALOG_DATA, fetchDialogDataSaga)
}

export function* sendPayloadWatcher() {
    yield takeEvery(ACTIONS.DIALOG_SEND_DIALOG_DATA_BY_NAME, sendDialogDataSaga)
}