import styled from 'styled-components'

export const StyledRedDot = styled.div`
    width: 12px;
    height: 12px;
    background-color: ${props => props.theme.color.redDot};
    border-radius: 6px;
`

export const StyledRedDotWithNumber = styled.div`
    position: absolute;
    top: -5px;
    right: -10px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${p => p.theme.color.redDot};
    border-radius: 50%;
    color: white;
    width: 16px;
    height: 16px;
`

export const StyledDarkDot = styled.div`
   width: 6px;
    height: 6px;
    border-radius: 100%;
    margin-right: 4px;
    margin-left: 6px;
    background-color: ${props => props.theme.color.color40};
`