import { fetchHandler, HTTP_METHOD, limiter, API_VERSION_ESYMANDANT } from 'application/api/request_builders'
import { fetchRequestBuilder } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const setProfilePicture = (accountId, userId, UploadId) => {

    const request = fetchRequestBuilder(`${API_VERSION_ESYMANDANT}/e.consult.${accountId}/user/${userId}/profile/picture`, HTTP_METHOD.POST, { UploadId })

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}