import { objectHelpers } from 'application/common'
import ACTIONS from 'application/constants'
import default_store from '../../default_store'

const allAkten = (state = default_store.common.allAkten, action) => {
    switch (action.type) {
    case ACTIONS.COMMON_ALL_AKTEN_POPULATE_DATA:
        return {
            ...state,
            isLoaded: true,
            isLoading: false,
            payload: action.payload
        }
    case ACTIONS.COMMON_ALL_AKTEN_UNREGISTER_AKTE:
        return {
            ...state,
            payload: state.payload.filter(p => p.idHash !== action.idHash)
        }
    case ACTIONS.COMMON_GET_ALL_AKTEN_FAILED:
        return {
            ...state,
            isLoaded: true,
            isLoading: false,
            failed: true,
        }
    case ACTIONS.COMMON_ALL_AKTEN_IN_ONE_ACCOUNT_POPULATE_DATA:
        return {
            ...state,
            payload: objectHelpers.mergeDeep(state.payload, action.payload)
        }
    case ACTIONS.COMMON_ALL_AKTEN_UPDATE_DATA:
    case ACTIONS.COMMON_ALL_AKTEN_REQUEST_DATA:
        return {
            ...state,
            isLoading: true,
            lastSync: new Date()
        }
    case ACTIONS.COMMON_ALL_AKTEN_PUT_LAST_MESSAGE:
        return {
            ...state,
            payload: state.payload.map(d => {
                return d.idHash === action.akteIdHash ? {
                    ...d,
                    lastMessage: action.message
                } : d
            })
        }
    case ACTIONS.COMMON_RECOUNT_UNREAD_MESSAGE:
        return {
            ...state,
            payload: state.payload.map(d => {
                return d.idHash === action.akteIdHash ? {
                    ...d,
                    totalUnreadMessage: parseInt(d.totalUnreadMessage) !== 0 ? (parseInt(d.totalUnreadMessage) - 1) : 0
                } : d
            })
        }
    case ACTIONS.COMMON_UPDATE_SINGLE_AKTE:
        return {
            ...state,
            payload: state.payload.map(d => {
                return d.idHash === action.akteIdHash ? {
                    ...d,
                    ...action.payload,
                } : d
            })
        }
    case ACTIONS.COMMON_RECOUNT_UNREAD_MESSAGE_IN_FOLDER:
        return {
            ...state,
            payload: state.payload.map(d => {
                return d.idHash === action.akteIdHash ? {
                    ...d,
                    totalUnreadMessageInFolder: parseInt(d.totalUnreadMessageInFolder) !== 0 ? (parseInt(d.totalUnreadMessageInFolder) - 1) : 0
                } : d
            })
        }
    case ACTIONS.COMMON_REFRESH_ALL_AKTEN_DATA: return {
        ...state,
        lastSync: null,
        isLoaded: false,
        isLoading: false,
        failed: false,
        payload: []
    }
    default:
        return state
    }
}

export default allAkten