import ACTIONS from 'application/constants'
import DefaultStore from '../../default_store'
const accountPublicInfoInternalAssetsReducer = (state = DefaultStore.common.accountPublicInfoInternalAssets, action) => {
    switch (action.type) {
    case ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REGISTER_ACCOUNT:
        return state.filter(a => a.accountId === action.accountId).length > 0 ?
            [...state]: [
                ...state,
                {
                    accountId: action.accountId,
                    payload: null,
                    request: false,
                    failed: false,
                    succeed: false,
                }
            ]
    case ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_UNREGISTER_ACCOUNT:
        return state.filter(a => a.accountId !== action.accountId)
    case ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA:
        return state.map(a => {
            return a.accountId === action.accountId ? {
                ...a,
                request: true
            } : {...a}
        })
    case ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA_SUCCEED:
        return state.map(a => {
            return a.accountId === action.accountId ? {
                ...a,
                request: false,
                succeed: true
            } : {...a}
        })
    case ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA_FAILED:
        return state.map(a => {
            return a.accountId === action.accountId ? {
                ...a,
                request: false,
                succeed: false,
                failed: true,
            } : {...a}
        })
    case ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_POPULATE_DATA_BY_ACCOUNT_ID:
        return state.map(a => {
            return a.accountId === action.accountId ? {
                ...a,
                request: false,
                succeed: true,
                payload: action.payload,
            } : {...a}
        })
    default:
        return state
    }
}

export default accountPublicInfoInternalAssetsReducer