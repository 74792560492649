import { accessTokenRegistered, accessTokenUnregistered, storageUserData } from 'application/storage'
import default_store from '../default_store'

export const stateMapper = {
    ...default_store.auth,
    registered: {
        ...default_store.auth.registered,
        tokenSet: accessTokenRegistered.get() !== '',
        loggedIn: accessTokenRegistered.get() !== ''
    },
    unregistered: {
        ...default_store.auth.unregistered,
        tokenSet: accessTokenUnregistered.get() !== '',
        loggedIn: accessTokenUnregistered.get() !== ''
    },
    common: {
        ...default_store.common,
        user: storageUserData.get(),
    }
}


export const logoutStorageDelete = () => {
    localStorage.clear()
}