import { cropperHelpers } from 'application/common'
import { getOrientation } from 'get-orientation/browser'

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

export function setPicturePatternSaga(action) {
    const file = action.files.metaData
    let imageDataUrl = file

    // apply rotation if needed
    const orientation = getOrientation(file)
    const rotation = ORIENTATION_TO_ANGLE[orientation]
    if (rotation) {
        imageDataUrl = cropperHelpers.getRotatedImageAsObjectFile(imageDataUrl, rotation)
    }

    return imageDataUrl
}