import { ACTIONS } from 'application/constants'

export {
    loginRequest,
    loginRequestFailed,
    loginRequestSuccess,
    writeToken,
    refresPayloadFailed
} from './login'

export {
    loginRequestPin,
    loginRequestFailedPin,
    loginRequestSuccessPin,
    writeTokenUnregistered,
} from './login_pin'

export {
    sendPinFailed,
    sendPinRequest,
    sendPinSucceed
} from './send_pin'

export {
    forgotPasswordFailed,
    forgotPasswordRequest,
    forgotPasswordSucceed,
    forgotUsernameFailed,
    forgotUsernameRequest,
    forgotUsernameSucceed,
    sendNewPassword,
    sendNewPasswordFailed,
    sendNewPasswordSucceed,
    writeMessageErrorForgotCredential
} from './forgot_credentials'

// COMMON
export const updateUsername = (username) => ({
    type: ACTIONS.AUTH_UPDATE_USERNAME,
    username
})

export const updatePassword = (password) => ({
    type: ACTIONS.AUTH_UPDATE_PASSWORD,
    password
})

export const setReturnUrl = (returnUrl) => ({
    type: ACTIONS.AUTH_SET_RETURN_URL,
    returnUrl
})

export const showLoginError = (errorMessage) => ({
    type: ACTIONS.AUTH_SHOW_LOGIN_ERROR,
    errorMessage
})

export const regenerate = (previousAction) => ({
    type: ACTIONS.AUTH_REGENERATE,
    previousAction
})

export const populateTokenData = (tokenData) => ({
    type: ACTIONS.AUTH_POPULATE_TOKEN_DATA,
    tokenData
})

export const populateUserData = (userData) => ({
    type: ACTIONS.AUTH_POPULATE_USER_DATA,
    userData
})

export const getUserData = () => ({
    type: ACTIONS.AUTH_GET_USER_DATA_REQUEST
})

export const getProfilePicture = (accountId, userId) => ({
    type: ACTIONS.AUTH_GET_USER_PROFILE_PICTURE,
    accountId,
    userId
})

export const populateProfilePicture = (data) => ({
    type: ACTIONS.AUTH_POPULATE_USER_PROFILE_PICTURE,
    data
})

export const getProfilePictureFailed = () => ({
    type: ACTIONS.AUTH_GET_USER_PROFILE_PICTURE_FAILED,
})

export const refreshProfilePicture = () => ({
    type: ACTIONS.AUTH_REFRESH_USER_PROFILE_PICTURE,
})

export const logoutRequest = (clicked = false) => ({
    type: ACTIONS.AUTH_LOGOUT_REQUEST,
    clicked
})

export const setPushToDashboard = (condition) => ({
    type: ACTIONS.AUTH_SET_PUSH_TO_DASHBOARD,
    condition
})
