import { getMessageFavorite } from 'application/redux/actions/messages'
import { connect } from 'react-redux'
import FavoriteSidebarComponent from './component'

const mapStateToProps = (state, props) => {
    let favorites = state.messages.favorites.payload.filter((f) => f.messageKey === props.messageKey)
    return {
        favoriteLoaded: state.messages.favorites.succeed,
        favoriteLoading: state.messages.favorites.request,
        favoriteFailed: state.messages.favorites.failed,
        favorites,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getMessageFavorite: (messageKey, accountId, akteIdHash, folderId) => dispatch(getMessageFavorite(messageKey, accountId, akteIdHash, folderId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteSidebarComponent)
