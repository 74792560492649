import ACTIONS from 'application/constants'

export const getWizardDataRequest = (wizardType, accountId, userId, token = '') => ({
    type: ACTIONS.WIZARD_GET_DATA_REQUEST,
    wizardType,
    accountId,
    userId,
    token,
})

export const populateDataWizard = (payload, currentStepName) => ({
    type: ACTIONS.WIZARD_POPULATE_DATA,
    payload,
    currentStepName
})

export const populateRegKey = (regKey) => ({
    type: ACTIONS.WIZARD_POPULATE_REGISTRATION_KEY,
    regKey
})

export const updatedDataWizardSucceed = (payload) => ({
    type: ACTIONS.WIZARD_UPDATED_DATA_SUCCEED,
    payload
})

export const updateWizardNext = (currentStep, nextStep, currentStepName) => ({
    type: ACTIONS.WIZARD_UPDATE_DATA_NEXT,
    currentStep,
    nextStep,
    currentStepName
})

export const updateWizardPrev = (currentStep, prevStep, currentStepName) => ({
    type: ACTIONS.WIZARD_UPDATE_DATA_PREV,
    currentStep,
    prevStep,
    currentStepName
})

export const updateWizardPushBack = (currentStep, backStep, currentStepName) => ({
    type: ACTIONS.WIZARD_UPDATE_DATA_PUSH_BACK,
    currentStep,
    backStep,
    currentStepName
})

export const updateWizardDataAllDone = (currentStepName) => ({
    type: ACTIONS.WIZARD_UPDATE_DATA_ALL_DONE,
    currentStepName
})

export const getCheckUsername = (username, userId) => ({
    type: ACTIONS.WIZARD_CHECK_USERNAME,
    username,
    userId
})

export const populateUsername = (username) => ({
    type: ACTIONS.WIZARD_POPULATE_USERNAME,
    username
})

export const populatePassword = (password, passwordConfirmation) => ({
    type: ACTIONS.WIZARD_POPULATE_PASSWORD,
    password,
    passwordConfirmation,
})

export const registrationRequest = (username, password, passwordConfirmation, accountId, Key, userId, agbsAndDatenschutzAccepted = true, token = '') => ({
    type: ACTIONS.WIZARD_REGISTRATION_REQUEST,
    username,
    password,
    passwordConfirmation,
    accountId,
    Key,
    userId,
    agbsAndDatenschutzAccepted,
    token,
})

export const registrationComplete = () => ({
    type: ACTIONS.WIZARD_REGISTRATION_COMPLETE,
})

export const registrationFailed = () => ({
    type: ACTIONS.WIZARD_REGISTRATION_FAILED,
})

export const usernameNotValid = () => ({
    type: ACTIONS.WIZARD_USERNAME_NOT_VALID,
})

export const wizardWriteErrorMessage = (errorMessage) => ({
    type: ACTIONS.WIZARD_WRITE_ERROR_MESSAGE,
    errorMessage
})