import ConsentBanner from 'application/components/building_blocks/consent_banner'
import React from 'react'

const LayoutWelcome = ({children}) => {
    return (
        <div>
            {children}
            <ConsentBanner />
        </div>
    )
}

export default LayoutWelcome
