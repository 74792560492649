import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { registerAccountPublicInfoInternalAssets } from 'application/redux/actions/common/account_public_info_internal_assets'
import { companyHasNoMainLogo, companyHasNoSecondaryLogo, getCommonAllAccountsFailed, populateCommonAllAccountsData, populateMainLogo, populateSecondaryLogo } from 'application/redux/actions/common/all_accounts'
import { handleError } from 'application/redux/saga/errors'
import { call, put, takeEvery } from 'redux-saga/effects'
import { accountsArrayMapper } from './function'

function* getCommonAllAccountsDataSaga(action) {
    try {
        const response = yield call(api.gateway.common.getAllAccounts)
        const accounts = response.accounts
        yield put(populateCommonAllAccountsData(accountsArrayMapper(accounts)))
        for (let a of accounts) {
            yield put(registerAccountPublicInfoInternalAssets(a.id))
        }
    } catch (e) {
        yield handleError(e, action)
        yield put(getCommonAllAccountsFailed())
    }
}

function* getMainLogoSaga(action) {
    try {
        const response = yield call(api.gateway.common.getMainLogoByAccountId, action.accountId)
        const blobUrl = window.URL.createObjectURL(response)
        yield put(populateMainLogo(action.accountId, blobUrl))
    } catch (e) {
        if (e.response.status === 404) {
            yield put(companyHasNoMainLogo(action.accountId))
        } else {
            yield handleError(e, action)
        }
    }
}

function* getSecondaryLogoSaga(action) {
    try {
        const response = yield call(api.gateway.common.getSecondaryLogoByAccountId, action.accountId)
        const blobUrl = window.URL.createObjectURL(response)
        yield put(populateSecondaryLogo(action.accountId, blobUrl))
    } catch (e) {
        if (e.response.status === 404) {
            yield put(companyHasNoSecondaryLogo(action.accountId))
        } else {
            yield handleError(e, action)
        }
    }
}

export function* allAccountsWatcher() {
    yield takeEvery(ACTIONS.COMMON_ALL_ACCOUNTS_REQUEST_DATA, getCommonAllAccountsDataSaga)
    yield takeEvery(ACTIONS.COMMON_GET_MAIN_LOGO_COMPANY_REQUEST, getMainLogoSaga)
    yield takeEvery(ACTIONS.COMMON_GET_SECONDARY_LOGO_COMPANY_REQUEST, getSecondaryLogoSaga)
}