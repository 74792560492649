import { api } from 'application/api'
import { WIZARD_STEP } from 'application/constants'
import { populateDataWizard, populateRegKey, updatedDataWizardSucceed } from 'application/redux/actions/wizard'
import { call, put } from 'redux-saga/effects'

export function* fetchWizardDataRegisterSaga(action) {
    const response = yield call(api.local.getWizards, action.wizardType)
    yield put(populateDataWizard(response, WIZARD_STEP.REGISTRATION.WELCOME))
    yield put(updatedDataWizardSucceed(response))
    const regKeyResponse = yield call(api.gateway.auth.getRegistrationKey, action.accountId, action.userId, action.token)
    yield put(populateRegKey(regKeyResponse.asyncTaskKey))
}