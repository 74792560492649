import { WIZARD_STEP } from 'application/constants'
import { updateWizardDataAllDone, updateWizardNext } from 'application/redux/actions/wizard'
import { getPasswordReg, getUsernameReg, getUsernameNotValid } from 'application/redux/selectors'
import { put, select } from 'redux-saga/effects'

export function* updateWizardDataRegisterSaga() {
    const usernameSet = yield select(getUsernameReg)
    const usernameNotValid = yield select(getUsernameNotValid)
    const passwordSet = yield select(getPasswordReg)
    if (usernameSet && !usernameNotValid) {
        yield put(updateWizardNext(1, 2, WIZARD_STEP.REGISTRATION.USERNAME))
        yield put(updateWizardNext(2, 3, WIZARD_STEP.REGISTRATION.PASSWORD))
    }
    if (passwordSet) {
        yield put(updateWizardDataAllDone(WIZARD_STEP.REGISTRATION.DONE))
    }
}