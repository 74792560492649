import { FlexGridItem } from 'application/components/fragments/grid'
import React, { useState } from 'react'
import { MainLogoCompany } from '../company_logo'
import { StyledProviderCard } from './fragments'
import styled, { withTheme } from 'styled-components'
import CategoriesServiceProviderCard from './categories'
import { useTranslation } from 'react-i18next'
import FontIcon from 'application/components/controls/font_icon'

const StyledCompanyName = styled.p`
  font-size: ${props => props.theme.fontSize.headline3};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
  color: ${props => props.color};
`

const ServiceProviderCardNotActive = ({
    accountDetail,
    isActive = false,
    onCardClick = () => { },
    theme,
}) => {
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(false)
    // const accountName = textHelpers.truncateMiddleText(accountDetail.name, 45)
    return <StyledProviderCard expanded={expanded} onMouseDown={() => setExpanded(!expanded)} isActive={isActive} onClick={onCardClick}>
        <FlexGridItem justifyFlexEnd>
            <FontIcon title={t('Need to be activated')} fontSize='20px' icon='lock' color={theme.color.salmonDark} />
        </FlexGridItem>
        <FlexGridItem justifyCenter>
            <MainLogoCompany
                activated={false}
                accountId={accountDetail.id}
                accountName={accountDetail.name}
            />
        </FlexGridItem>
        <FlexGridItem itemsFlexEnd justifyCenter>
            <StyledCompanyName color={accountDetail.mainColor}>
                {accountDetail.name}
            </StyledCompanyName>
        </FlexGridItem>
        <CategoriesServiceProviderCard accountDetail={accountDetail} />
    </StyledProviderCard>
}

export default withTheme(ServiceProviderCardNotActive)