import React, { useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import Preloader3D from 'application/components/controls/preloader/3d'
import { FlexGrid } from 'application/components/fragments/grid'
// import { API_VERSION_ESYMANDANT, fetchHandler, HTTP_METHOD, limiter } from 'application/api/request_builders'
// import { fetchRequestBuilder } from 'application/api/request_builders'
// import { handleApiResponse } from 'application/api/response_handlers'
import danger from './images/danger.png'
import checkmark from './images/checkmark.png'
// import { EcDynamicForm } from 'application/dynamicform'
import { EcDynamicForm } from '@ec/dynamic-form-react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import AnsweredElem from '../answeredElem'
import { CheckFile } from './checkfile'
import { isSpecialAccount } from 'application/common/special_accounts'
import AssistantNoticeSection from '../../../../pages/external/mobile_assistant/sections/asistant_notice_section'
import { accessTokenUnregistered } from 'application/storage'
import { accessTokenForm } from 'application/storage/access_token'
import C from 'application/components/building_blocks/toaster'
import { useTheme } from 'styled-components'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { PluginAlternateView } from 'plugins'
import * as Fragments from '../fragments'
import { isMobile } from 'react-device-detect'

const Aufgaben = (props) => {
  const {
    selectedAkte,
    accountId,
    parentId,
    akteIdHash,
    metaInfo,
    onOpenGallery,
    openAccountStatement,
    onSendFormRequest,
    selectedFilter,
    accountPublicInfo,
    loginAdHoc,
    waitingScreen,
    sendSucceed,
    aufgaben,
    aufgabenIsLoading,
    setRefreshAufgaben
  } = props
  const [loadingform, setLoadingForm] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [workflowId, setWorkflowId] = useState(null)
  const [formData, setFormData] = useState()
  const [hasSubmit, setHasSubmit] = useState(false)
  const [gutachterOptionsMetadata, setGutachterOptionsMetadata] = useState(undefined)
  const [gutachterOptions, setGutachterOptions] = useState([])
  const [gutachterIsLoading, setGutachterIsLoading] = useState(false)
  const [postalCode, setPostalCode] = useState('')
  // const now = moment().format()
  const now = moment().utcOffset('+01:00').format()
  const [answer, setAnswer] = useState()
  const [userinfo, setUserInfo] = useState()
  const [showAssistantImage, setShowAssistantImage] = useState(false)
  const [resultText, setResultText] = useState(null)
  const { t } = useTranslation()
  const isAnswered = false
  const { getAsset, activePlugin } = usePluginsContext()
  const theme = useTheme()

  const baseUrl = process.env.REACT_APP_API_URL_BASE
  const token = localStorage.getItem('storage_access_token_registered')

  useEffect(() => {
    if (!workflowId) return
    loginAdHoc(workflowId)
  }, [workflowId])



  const getAccountPublicInfo = async () => {
    await axios
      .get(`${baseUrl}account/${accountId}/publicinfo`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        setUserInfo(res?.data)
      })
      .catch((err) => {})
  }

  const getForm = async () => {
    await axios
      .get(`${baseUrl}e.consult.${accountId}/assistants/process?workflowKey=${workflowId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        setFormData(res?.data)
        if (res?.data?.attachments && res?.data?.attachments.length > 0) {
          setShowAssistantImage(true)
        }

        setTimeout(() => {
          setLoadingForm(false)
        }, 2000)
      })
      .catch((err) => {
        setTimeout(() => {
          setLoadingForm(false)
        }, 2000)
      })
  }

  const getAnswer = async () => {
    await axios
      .get(`${baseUrl}e.consult.${accountId}/assistants/processes/${akteIdHash}/workflow/${workflowId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        setAnswer(res?.data)
        setLoadingForm(false)
      })
      .catch((err) => {
        setLoadingForm(false)
      })
  }

  useEffect(() => {
    getAccountPublicInfo()
  }, [selectedAkte, hasSubmit])

  useEffect(() => {
    if (workflowId !== null) {
      setLoadingForm(true)
      getForm()
      getAnswer()
    }
  }, [workflowId])

  useEffect(() => {
    if (sendSucceed) {
      localStorage.removeItem(workflowId)
      setHasSubmit(sendSucceed)
      setRefreshAufgaben(true)
      setLoadingForm(false)
    }
  }, [sendSucceed])

  const handleSendForm = (payload) => {
    onSendFormRequest(workflowId, payload)
    setLoadingForm(true)
  }

  const handleSubmit = async (payload) => {
    console.log(payload)
    setLoadingForm(true)
    await CheckFile(formData.formElement, payload, accountId).then(
      async (v) => {
        if (v.payload) {
          await axios
            .post(
              `${baseUrl}assistants/process?workflowKey=${workflowId}`,
              { formInput: v.payload },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                  'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
                  'X-API-Key': process.env.REACT_APP_API_KEY,
                },
              }
            )
            .then((res) => {
              setResultText(res.data?.resultText)
              setLoadingForm(false)
              setHasSubmit(true)
            })
            .catch((err) => {
              setLoadingForm(false)
            })
        }
      }
    )
  }

  const handleClose = () => {
    setOpenForm(false)
    setHasSubmit(false)
    setLoadingForm(false)
  }

  const handleOpenForm = (id) => {
    setLoadingForm(true)
    if (id !== answer?.workflowId) {
      setFormData(undefined)
      setAnswer(undefined)
    }
    if (id === workflowId) {
      getForm()
      getAnswer()
    }

    setOpenForm(true)
    setWorkflowId(id)
  }

  const x = aufgaben
  x.sort((a, b) => {
    return a.hasAnswer === b.hasAnswer ? 0 : a.hasAnswer ? 1 : -1
  })

  const y = x
  y.sort((a, b) => {
    return a.isClosed === b.isClosed ? 0 : a.isClosed ? 1 : -1
  })

  const filteredOpen = aufgaben?.filter((item) => item.hasAnswer === false && item.isClosed === false)
  const filteredAnswered = aufgaben?.filter((item) => item.hasAnswer === true && item.isClosed === false)
  const filteredClosed = aufgaben?.filter((item) => item.isClosed === true)

  const gutachterGetData = useCallback(async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenForm.get()}`,
        'Content-Type': 'application/json',
        'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
      mode: 'cors',
    }

    const url = `${baseUrl}e.consult.${accountId}/participants/${postalCode}`
    setGutachterIsLoading(true)
    await axios
      .get(url, config)
      .then((res) => {
        const data = res.data
        setGutachterOptionsMetadata(data.participants)
      })
      .catch((err) => {
        setGutachterOptionsMetadata(undefined)
        setGutachterOptions([])
        console.log(err)
      })
      .finally(() => {
        setGutachterIsLoading(false)
      })
  }, [baseUrl, postalCode])

  useEffect(() => {
    if (!gutachterOptionsMetadata) return
    setGutachterOptions(
      gutachterOptionsMetadata.map((m) => {
        return { value: m.id, label: m.name }
      })
    )
  }, [gutachterOptionsMetadata])


  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      {y?.length > 0 && selectedFilter === 0 && !aufgabenIsLoading ? (
        <div
          style={{
            margin: '8px 40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          {y.map((item, index) => (
            <button
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0px 8px',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
              }}
              disabled={item.validUntil < now}
              onClick={() => handleOpenForm(item?.workflowId)}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px',
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  backgroundColor: '#F2F2F2',
                }}
              >
                <img
                  style={{
                    width: '24px',
                    height: '24px',
                    objectFit: 'cover',
                  }}
                  src={item.hasAnswer && item.validUntil > now ? checkmark : danger}
                  alt='status'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginLeft: '16px',
                  gap: '4px',
                }}
              >
                <div>{item.assistantName}</div>
                <div
                  style={{
                    fontSize: '12px',
                    color: item.validUntil < now ? '#000000' : '#FFFFFF',
                    backgroundColor: item.hasAnswer && item.validUntil > now ? '#42855B' : item.hasAnswer === false && item.validUntil > now ? '#FF4A4A' : '#e0e0e0',
                    padding: '4px',
                    borderRadius: '3px',
                  }}
                >
                  {item.hasAnswer && item.validUntil > now && `${t('AnsweredOn')} ${moment(item.answerCreationDate, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                  {item.hasAnswer === false && item.validUntil > now && `${t('ValidUntil')} ${moment(item.validUntil, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                  {now > item.validUntil && `${t('ExpiredOn')} ${moment(item.validUntil, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                </div>
              </div>
            </button>
          ))}
        </div>
      ) : y?.length === 0 && selectedFilter === 0 && !aufgabenIsLoading ? (
        <PluginAlternateView id='aufgabe.nodata'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                fontSize: '18px',
                marginBottom: activePlugin && '12px',
              }}
            >
              {t('There Is No Task')}
            </div>
            <img
              style={{
                width: '400px',
                height: '400px',
                objectFit: 'contain',
              }}
              src={getAsset('nodata.png')}
              alt='empty'
            />
          </div>
        </PluginAlternateView>
      ) : null}

      {filteredOpen?.length > 0 && selectedFilter === 1 && !aufgabenIsLoading ? (
        <div
          style={{
            margin: '8px 40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          {filteredOpen.map((item, index) => (
            <button
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0px 8px',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
              }}
              disabled={item.validUntil < now}
              onClick={() => handleOpenForm(item?.workflowId)}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px',
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  backgroundColor: '#F2F2F2',
                }}
              >
                <img
                  style={{
                    width: '24px',
                    height: '24px',
                    objectFit: 'cover',
                  }}
                  src={item.hasAnswer && item.validUntil > now ? checkmark : danger}
                  alt='status'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginLeft: '16px',
                  gap: '4px',
                }}
              >
                <div>{item.assistantName}</div>
                <div
                  style={{
                    fontSize: '12px',
                    color: item.validUntil < now ? '#000000' : '#FFFFFF',
                    backgroundColor: item.hasAnswer && item.validUntil > now ? '#42855B' : item.hasAnswer === false && item.validUntil > now ? '#FF4A4A' : '#e0e0e0',
                    padding: '4px',
                    borderRadius: '3px',
                  }}
                >
                  {item.hasAnswer && item.validUntil > now && `${t('AnsweredOn')} ${moment(item.answerCreationDate, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                  {item.hasAnswer === false && item.validUntil > now && `${t('ValidUntil')} ${moment(item.validUntil, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                  {now > item.validUntil && `${t('ExpiredOn')} ${moment(item.validUntil, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                </div>
              </div>
            </button>
          ))}
        </div>
      ) : filteredOpen?.length === 0 && selectedFilter === 1 && !aufgabenIsLoading ? (
        <PluginAlternateView id='aufgabe.nodata'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '400px',
                height: '400px',
                objectFit: 'contain',
              }}
              src={getAsset('nodata.png')}
              alt='empty'
            />
          </div>
        </PluginAlternateView>
      ) : null}

      {filteredAnswered?.length > 0 && selectedFilter === 2 && !aufgabenIsLoading ? (
        <div
          style={{
            margin: '8px 40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          {filteredAnswered.map((item, index) => (
            <button
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0px 8px',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
              }}
              disabled={item.validUntil < now}
              onClick={() => handleOpenForm(item?.workflowId)}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px',
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  backgroundColor: '#F2F2F2',
                }}
              >
                <img
                  style={{
                    width: '24px',
                    height: '24px',
                    objectFit: 'cover',
                  }}
                  src={item.hasAnswer && item.validUntil > now ? checkmark : danger}
                  alt='status'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginLeft: '16px',
                  gap: '4px',
                }}
              >
                <div>{item.assistantName}</div>
                <div
                  style={{
                    fontSize: '12px',
                    color: item.validUntil < now ? '#000000' : '#FFFFFF',
                    backgroundColor: item.hasAnswer && item.validUntil > now ? '#42855B' : item.hasAnswer === false && item.validUntil > now ? '#FF4A4A' : '#e0e0e0',
                    padding: '4px',
                    borderRadius: '3px',
                  }}
                >
                  {item.hasAnswer && item.validUntil > now && `${t('AnsweredOn')} ${moment(item.answerCreationDate, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                  {item.hasAnswer === false && item.validUntil > now && `${t('ValidUntil')} ${moment(item.validUntil, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                  {now > item.validUntil && `${t('ExpiredOn')} ${moment(item.validUntil, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                </div>
              </div>
            </button>
          ))}
        </div>
      ) : filteredAnswered?.length === 0 && selectedFilter === 2 && !aufgabenIsLoading ? (
        <PluginAlternateView id='aufgabe.nodata'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '400px',
                height: '400px',
                objectFit: 'contain',
              }}
              src={getAsset('nodata.png')}
              alt='empty'
            />
          </div>
        </PluginAlternateView>
      ) : null}

      {filteredClosed?.length > 0 && selectedFilter === 3 && !aufgabenIsLoading ? (
        <div
          style={{
            margin: '8px 40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          {filteredClosed.map((item, index) => (
            <button
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0px 8px',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
              }}
              disabled={item.validUntil < now}
              onClick={() => handleOpenForm(item?.workflowId)}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px',
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  backgroundColor: '#F2F2F2',
                }}
              >
                <img
                  style={{
                    width: '24px',
                    height: '24px',
                    objectFit: 'cover',
                  }}
                  src={item.hasAnswer && item.validUntil > now ? checkmark : danger}
                  alt='status'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginLeft: '16px',
                  gap: '4px',
                }}
              >
                <div>{item.assistantName}</div>
                <div
                  style={{
                    fontSize: '12px',
                    color: item.validUntil < now ? '#000000' : '#FFFFFF',
                    backgroundColor: item.hasAnswer && item.validUntil > now ? '#42855B' : item.hasAnswer === false && item.validUntil > now ? '#FF4A4A' : '#e0e0e0',
                    padding: '4px',
                    borderRadius: '3px',
                  }}
                >
                  {item.hasAnswer && item.validUntil > now && `${t('AnsweredOn')} ${moment(item.answerCreationDate, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                  {item.hasAnswer === false && item.validUntil > now && `${t('ValidUntil')} ${moment(item.validUntil, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                  {now > item.validUntil && `${t('ExpiredOn')} ${moment(item.validUntil, 'YYYY-MM-DD, hh:mm:ss').format('DD.MM.YYYY, hh:mm')}`}
                </div>
              </div>
            </button>
          ))}
        </div>
      ) : filteredClosed?.length === 0 && selectedFilter === 3 && !aufgabenIsLoading ? (
        <PluginAlternateView id='aufgabe.nodata'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '400px',
                height: '400px',
                objectFit: 'contain',
              }}
              src={getAsset('nodata.png')}
              alt='empty'
            />
          </div>
        </PluginAlternateView>
      ) : null}

      {aufgabenIsLoading === true && (
        <FlexGrid justifyCenter itemsCenter>
          {accountPublicInfo && <Preloader3D />}
        </FlexGrid>
      )}

      {openForm && (
        <>
          <div
            style={{
              inset: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              position: 'fixed',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1,
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              margin: 'auto',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              zIndex: 2,
              width: isMobile ? '95%' : '80%',
              height: '90%',
              backgroundColor: '#FFFFFF',
              borderRadius: '12px',
              overflow: 'auto',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '25px 0px 25px 0px',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '12px',
                  display: 'flex',
                  width: '90%',
                  justifyContent: 'end',
                }}
              >
                <button
                  style={{
                    position: 'fixed',
                    border: 'none',
                    backgroundColor: 'grey',
                    color: 'white',
                    padding: '12px 16px',
                    borderRadius: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={handleClose}
                >
                  X
                </button>
              </div>

              {loadingform && (
                <div
                  style={{
                    position: 'fixed',
                    width: '80%',
                    bottom: '50%',
                    zIndex: 2,
                  }}
                >
                  <FlexGrid justifyCenter itemsCenter>
                    {accountPublicInfo && <Preloader3D />}
                  </FlexGrid>
                </div>
              )}
              {hasSubmit && !loadingform && (
                <div
                  style={{
                    marginTop: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: '20px',
                    }}
                  >
                    {/* <img 
                                      src={userinfo?.primaryLogoUrl}
                                      style={{
                                          width: '25px',
                                          borderRadius: '45px'
                                      }}
                                  
                                  /> */}
                    <div
                      style={{
                        paddingLeft: '10px',
                      }}
                    >
                      {userinfo.accountName}
                    </div>
                  </div>
                  <img
                    src={userinfo?.primaryLogoUrl}
                    alt='confirmation'
                    style={{
                      width: '300px',
                      height: '300px',
                    }}
                  />
                  <div
                    style={{
                      paddingTop: '20px',
                      textAlign: 'center',
                    }}
                  >
                    {resultText ? resultText : t('You Have Successfully Submitted Your Answer')}
                  </div>
                  <button
                    style={{
                      marginTop: '32px',
                      padding: '16px 32px',
                      borderRadius: '4px',
                      border: 'none',
                      backgroundColor: '#000000',
                      color: '#FFFFFF',
                      cursor: 'pointer',
                      fontSize: '16px',
                    }}
                    onClick={handleClose}
                  >
                    Back
                  </button>
                </div>
              )}
              {!hasSubmit && (
                <>
                  {formData !== undefined && loadingform === false ? (
                    <>
                      {answer?.answers.length > 0 && answer?.hasAnswer === true ? (
                        <AnsweredElem userinfo={userinfo} token={token} parentId={parentId} akteIdHash={akteIdHash} accountId={accountId} baseUrl={baseUrl} answerFormElements={answer?.answers[0]?.answerFormElements} onOpenGallery={onOpenGallery} />
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            flex: '90%',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '20px',
                              width: '100%',
                            }}
                          >
                            {userinfo?.secondaryLogoUrl && (
                              <img
                                style={{
                                  marginTop: '32px',
                                  width: '70%',
                                  height: '70%',
                                  objectFit: 'cover',
                                  borderRadius: '15px',
                                }}
                                src={userinfo?.secondaryLogoUrl}
                                alt='secondaryLogoUrl'
                              />
                            )}
                            {formData.assistantNotice !== null && (
                              <p
                                style={{
                                  fontSize: '16px',
                                  marginTop: '-5px',
                                  lineHeight: '24px',
                                  fontWeight: '300',
                                  textAlign: 'justify',
                                }}
                              >
                                {formData.assistantNotice}
                              </p>
                            )}

                            {/* Show Image File */}
                            {showAssistantImage && <AssistantNoticeSection showAssistantImage={showAssistantImage} formMetaData={{ core: { accountId } }} formData={formData} {...props} />}

                            <EcDynamicForm
                              workflowKey={workflowId}
                              formData={formData}
                              hasAgb={formData?.showAgb}
                              hasDatenschutz={formData?.showDatenschutz}
                              openAgb={() => openAccountStatement(metaInfo.esyThingAgb, t('conditions') + ' - ' + metaInfo.accountName)}
                              openDatenschutz={() => openAccountStatement(metaInfo.esyThingDatenschutz, t('privacy') + ' - ' + metaInfo.accountName)}
                              formThemeOverride={{
                                formContainerBackground: '#ffffff',
                                formElementBackground: '#ffffff',
                                formElementBorderRadius: '0px',
                                buttonBackground: theme.color.primary,
                                buttonBackgroundHover: theme.color.primary,
                                policyTextColor: theme.color.primary,
                              }}
                              localizedTextsOverride={{
                                formElementSimpleChoiceNoOption: t('no'),
                                formElementSimpleChoiceYesOption: t('yes'),
                                confirmTextExternalLinkPopUpStart: t('ConfirmTextExternalLinkModalStart'),
                                confirmTextExternalLinkPopUpEnd: t('ConfirmTextExternalLinkModalEnd'),
                                rsInsuranceNumberLabel: t('Bitte geben Sie Ihre Versicherungsnummer ein.'),
                                rsHaveInsuranceLabel: t('Besitzen Sie eine Rechtsschutzversicherung?'),
                              }}
                              gutachterGetData={gutachterGetData}
                              gutachterOptions={gutachterOptions}
                              gutachterIsLoading={gutachterIsLoading}
                              setPostalCodeGutachter={setPostalCode}
                              onSubmit={(data) => handleSendForm(data)}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        position: 'fixed',
                        width: '80%',
                        bottom: '50%',
                        zIndex: 2,
                      }}
                    >
                      <FlexGrid justifyCenter itemsCenter>
                        {accountPublicInfo && <Preloader3D color={isSpecialAccount(accountPublicInfo?.uniqueAccountKey) ? accountPublicInfo?.mainColor : null} />}
                      </FlexGrid>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {
            waitingScreen.active && (
              <Fragments.WaitingScreen dialogIndex={10} />
            )
          }
        </>
      )}
      {/* {openForm && answer?.answers.length > 0 && answer?.hasAnswer === true && (
            <>
                <div
                    style={{
                        inset: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        position: 'fixed',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}
                    onClick={handleClose}
                >
                </div>
                <div
                    style={{
                        position: 'absolute',
                        margin: 'auto',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        zIndex: 2,
                        width: '80%',
                        height: '90%',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '12px',
                        overflow: 'auto',
                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '25px',
                            position: 'relative',
                        }}
                    >
                        <div style={{
                                position: 'absolute',
                                top: '8px',
                                right: '12px',
                                display: 'flex',
                                width: '90%',
                                justifyContent: 'end'
                            }}
                        >

                            <button
                                    style={{
                                        position: 'absolute',
                                        top: '12px',
                                        right: '12px',
                                        border: 'none',
                                        backgroundColor: 'grey',
                                        color: 'white',
                                        padding: '12px 16px',
                                        borderRadius: '100%',
                                        cursor: 'pointer',
                                    }}
                                        onClick={handleClose}
                            >X</button>
                        </div>
                        {formData === undefined && loadingform ? (

                                <div style={{
                                    position: 'fixed',
                                    width: '80%',
                                    bottom: '50%',
                                    zIndex: 2
                                }}>

                                        <FlexGrid justifyCenter itemsCenter>
                                            {accountPublicInfo && (
                                                <Preloader3D/>
                                            )}
                                        </FlexGrid>
                                </div>
                                ) : (

                                    <AnsweredElem userinfo={userinfo} answerFormElements={answer?.answers[0]?.answerFormElements} />
                                )
                            }
                    </div>
                </div>
            </>
        )}     */}
    </div>
  )
}

export default Aufgaben
