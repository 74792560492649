import { useQuery } from 'application/components/hooks'
import { ROUTE_PARAMETERS } from 'application/constants'
import React from 'react'
import BreadcrumbComponent from './component'
import BreadcrumItemView from './item_view'

const BreadcrumbWrapper = (props) => {
    const { payload, onBreadClick } = props
    const handleBreadClick = (id, selectedData) => {
        if (onBreadClick) {
            onBreadClick(id, selectedData)
        }
    }
    const query = useQuery()
    const folderId = query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID) !== '' ? query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID) : ''
    
    return <BreadcrumbComponent {...props}>
        {
            payload.map((p, i) => {
                return <BreadcrumItemView isselected={folderId === p.id} selectedData={p} key={i} id={p.id} name={p.name} onBreadClick={handleBreadClick} {...props}  />
            })
        }
    </BreadcrumbComponent>
}

export default BreadcrumbWrapper