import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'


export function NavigationDashboard() {
    const { t } = useTranslation()

    return <ImagePlaceHolder 
        withBorder={false}
        large='120px'
        source={t('WourageKooperationImagePath')}
        // source={'assets/images/myk-law/wourage_kooperation_en.png'}
        altText='wourage logo'
    />

}