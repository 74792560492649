import axios from 'axios'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'


const AufgabenLoader = (props) => {
    const { setAufgaben, setAufgabenIsLoading, selectedAkte, refreshAufgaben, setRefreshAufgaben, children } = props
    const { accountId, akteIdHash } = useParams()

    useEffect(() => {
        setRefreshAufgaben(true)
    },[akteIdHash])

    useEffect(() => {
        if(refreshAufgaben === false) return
        setAufgaben([])
        setAufgabenIsLoading(true)
        getAufgaben(accountId, akteIdHash, setAufgaben, setAufgabenIsLoading, setRefreshAufgaben)
        setRefreshAufgaben(false)
    }, [refreshAufgaben])

    return <>
        {children}
    </>

}

const getAufgaben = async (accountId, akteIdHash, setAufgaben, setAufgabenIsLoading, setRefreshAufgaben) => {
  await axios
      .get(`${process.env.REACT_APP_API_URL_BASE}e.consult.${accountId}/assistants/processes/${akteIdHash}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('storage_access_token_registered')}`,
          'Content-Type': 'application/json',
          'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        setAufgaben(res?.data)
        setAufgabenIsLoading(false)
      })
      .catch(() => {
      })
}

export default AufgabenLoader