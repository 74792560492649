import NotFoundPage from '../../not_found'
import WizardRegistrationFinal from './final'
import WizardRegistrationPassword from './password'
import WizardRegistrationUsername from './username'
import { WIZARD_STEP } from 'application/constants'
import WizardRegistrationWelcome from './welcome'

export const getWizardComponent = (wizardPage) => {
    switch (wizardPage) {
    case WIZARD_STEP.REGISTRATION.WELCOME:
        return WizardRegistrationWelcome
    case WIZARD_STEP.REGISTRATION.USERNAME:
        return WizardRegistrationUsername
    case WIZARD_STEP.REGISTRATION.PASSWORD:
        return WizardRegistrationPassword
    case WIZARD_STEP.REGISTRATION.DONE:
        return WizardRegistrationFinal
    default:
        return NotFoundPage
    }
}