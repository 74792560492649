import { call, put, takeEvery } from '@redux-saga/core/effects'
import { api } from 'application/api'
import ACTIONS, { DIALOG } from 'application/constants'
import { downloadAgbAccountFailed, getDatenshutzFailed, populateDatenshutz } from 'application/redux/actions/common/agb_datenschutz'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { handleError } from 'application/redux/saga/errors'

function* downloadAgbAccountSaga(action) {
    try {
        const apiCall = action.userRegistered ? api.gateway.common.downloadAgbByAccountId : api.gateway.common.downloadAgbByAccountIdUnregistered
        yield call(apiCall, action.accountId, action.accountName)
    } catch (e) {
        yield handleError(e, action)
        yield put(downloadAgbAccountFailed(action.accountId))
    }
}

function* getDatenshutzAccountSaga(action) {
    try {
        const apiCall = action.userRegistered ? api.gateway.common.downloadDatenshutzByAccountId : api.gateway.common.downloadDatenshutzByAccountIdUnregistered
        const response = yield call(apiCall, action.accountId)
        yield put(populateDatenshutz(action.accountId, response.datenschutz))
        yield put(updateDialogDataByName(DIALOG.NAME.DISPLAY_DATENSCHUTZ, { datenschutz: response.datenschutz }))
    } catch (e) {
        yield put(getDatenshutzFailed(action.accountId))
        yield handleError(e, action)
    }
}

export function* agbDatenschutzAccountWatcher() {
    yield takeEvery(ACTIONS.COMMON_ON_AGB_DOWNLOAD, downloadAgbAccountSaga)
    yield takeEvery(ACTIONS.COMMON_ON_DATENSHUTZ_REQUEST, getDatenshutzAccountSaga)
}