import React from 'react'
import { DisplayDefault } from './display'
import TabsOnFilesSidebar from './tabs'

const OnFilter = (props) => {
    const {
        onFilterFiles
    } = props
    return <div>
        {
            onFilterFiles ? <React.Fragment>
                <TabsOnFilesSidebar {...props} />
            </React.Fragment> : <DisplayDefault {...props} />
        }
    </div>
}
export default OnFilter