import React from 'react'
import { Fragment } from 'react'
import { isMobileOnly, withOrientationChange } from 'react-device-detect'

const RedirectMobile = ({ MobileComponent = null, ...props }) => {
    const { children } = props
    
    if (isMobileOnly && MobileComponent !== null) {
        return <MobileComponent {...props}/>
    }

    return <Fragment>
        {children}
    </Fragment>
}

export default withOrientationChange(RedirectMobile)
