import * as React from 'react'
import { IconTreeContainer } from './fragments'

const IconTree = (props) => {
    const { icon, spin, size, ...rest } = props

    return <IconTreeContainer size={size} {...rest}>
        {icon}
    </IconTreeContainer>
}

export default IconTree