import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import LanguageSwitch from '..'
import { I18N } from 'application/constants'

const LanguageSwitchHoc = ({position='flex-start', withLabel = true, stretched}) => {
    const {i18n} = useTranslation()
    const currentLanguage = i18next.language.substring(0, 2)
    return <LanguageSwitch 
        justify={position}
        stretched={stretched}
        languages={I18N.LANGUAGES}
        currentLanguage={currentLanguage}
        withLabel={withLabel}
        onSwitchLanguages={(lang)=>{i18n.changeLanguage(lang)}}
    /> 
}

export default LanguageSwitchHoc