import { getAkteMetaRequest } from 'application/redux/actions/akte_meta'
import { getWizardDataRequest, updateWizardDataAllDone } from 'application/redux/actions/wizard'
import { connect } from 'react-redux'
import WizardRegistrationComponent from './component'

const mapStateToProps = state => ({
    wizardData: state.wizard.data,
    wizardLoaded: state.wizard.isLoaded,
    usernameSet: state.wizard.registrationData.usernameSet,
    passwordSet: state.wizard.registrationData.passwordSet,
    currentStep: state.wizard.currentStep,
    akteMeta: state.akteMeta.payload,
    auth: state.auth,
    accessTokenUnregisteredStore: state.auth.unregistered.accessToken,
    akteMetaLoaded: state.akteMeta.isLoaded,
    akteMetaLoading: state.akteMeta.isLoading,
    akteMetaFailed: state.akteMeta.failed,
    pinSent: state.auth.pin.sent,
    pinRequest: state.auth.pin.request,
    userIdHash: state.auth.common.user.userId
})

const mapDispatchToProps = dispatch => ({
    getWizardDataRegistration: (section, accountId, userId, accessTokenUnregisteredStore) => {
        dispatch(getWizardDataRequest(section, accountId, userId, accessTokenUnregisteredStore))
    },
    getAkteMetaRequest: (hashUserKey) => dispatch(getAkteMetaRequest(hashUserKey)),
    wizardDone: (stepName) => dispatch(updateWizardDataAllDone(stepName))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardRegistrationComponent)