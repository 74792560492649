import { api } from 'application/api'
import ACTIONS, { NOTIFICATION } from 'application/constants'
import { showToast } from 'application/redux/actions/notifications'
import { sendFormSupportFailed, sendFormSupportSucceed, setNewPasswordFailed, setNewPasswordSucceed } from 'application/redux/actions/pages/settings'
import { call, put, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../errors'

function* sendFormSupportSaga(action) {
    try {
        yield call(api.gateway.pages.sendFormSupport, action.accountId, action.payload)
        yield put(sendFormSupportSucceed())
        yield put(showToast(action.successMessage))
    } catch (e) {
        yield handleError(e, action)
        yield put(sendFormSupportFailed())
        showToast(action.errorMessage, NOTIFICATION.LEVEL.ERROR)
    }
}

const callbackShowToastChangePass = (action, result, loginAttempts) => {
    switch (result) {
    case 0:
        return (showToast(action.handleResponseMessage.code1))
    case 1:
        return (showToast(action.handleResponseMessage.code3, NOTIFICATION.LEVEL.ERROR))
    case 2:
        return (showToast(action.handleResponseMessage.code2, NOTIFICATION.LEVEL.ERROR))
    default:
        return (showToast(action.handleResponseMessage.code1))
    }
}

function* sendNewPasswordSaga(action) {
    try {
        const res = yield call(api.gateway.user.putPasswordOnDashboard, action.userId, action.payload)
        yield put(setNewPasswordSucceed())
        yield put(callbackShowToastChangePass(action, res.result, res.loginAttempts))
    } catch (e) {
        yield handleError(e, action)
        yield put(setNewPasswordFailed())
        // yield put(showToast(e.data.reasonMessage, NOTIFICATION.LEVEL.ERROR))
    }
}

export function* settingsWatcher() {
    yield takeEvery(ACTIONS.PAGES_SETTINGS_ABOUT_FORM_REQUEST, sendFormSupportSaga)
    yield takeEvery(ACTIONS.PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_REQUEST, sendNewPasswordSaga)
}