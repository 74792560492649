export const AKTEN = {
    SIDEBAR: {
        KEY: {
            DETAILS: 'akte_details',
            SEARCH: 'search_messages',
            FILES: 'akte_files',
            FAVORITE: 'favorite_messages',
        },
    },
    REGISTERED_KEY: {
        AKTEN: 'rka',
        FOLDER: 'rkaf',
    },
    TYPE: {
        ROOT: 'akten_root',
        FOLDER: 'akten_folder',
    },
    DISPLAY_CONTENT_VIEW_MOBILE: {
        MESSAGES: 'Messages',
        FILES: 'Files',
        FOLDERS: 'Folders',
        FAVORITES: 'Favorites',
    },
    CONTAINER_ID: {
        MESSAGES_WRAPPER: 'MessagesWrapper-ec',
        MESSAGES_LIST: 'MessagesList-ec',
        MESSAGES_CHAT: 'Messages_chat-ec',
    },
}
