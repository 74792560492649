import ActionButton from 'application/components/controls/button/action_button/component'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { Filler, PushBottom20 } from 'application/components/fragments/grid'
import { WizardFooterRight } from 'application/components/pages/_layout/fragments/wizard'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

const ButtonSubmitPassword = ({ registeredRequest, handleSubmit, onSubmitSuccess, staticIdBottomPassWizard, accountId }) => {
    const {t} = useTranslation()
    const theme = useTheme()
    return <Fragment>
        <Filler />
        <FlexDividerHorizontal />
        <PushBottom20 />
        <WizardFooterRight>
            {
                registeredRequest ? <PreloaderSpin
                    primaryColor={theme.color.primary}
                /> :
                    <ActionButton
                        backgroundColor={theme.color.primary}
                        buttonText={t('SubmitButtonText')}
                        onButtonClick={handleSubmit(onSubmitSuccess)}
                    />
            }
            <div id={staticIdBottomPassWizard} />
        </WizardFooterRight>
    </Fragment>
}

export default ButtonSubmitPassword
