import { API_VERSION_ESYMANDANT, fetchHandler, fetchRequestBuilder, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { handleApiFilestreamResponse } from 'application/api/response_handlers'
import { accessTokenRegistered } from 'application/storage'

export const getThumbnailsByAccountId = (accountId, fileId, storage = accessTokenRegistered) => {

    const request = fetchRequestBuilder(API_VERSION_ESYMANDANT + '/e.consult.' + accountId + '/files/thumbnail/' + fileId + '?resultType=Stream', HTTP_METHOD.GET, {}, true, false, false, storage)

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiFilestreamResponse)
}