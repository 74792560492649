import ACTIONS from 'application/constants'

export const getAkteMetaRequest = (hashUserKey) => ({
    type: ACTIONS.AKTE_META_DATA_REQUEST,
    hashUserKey,
})

export const populateAkteMeta = (payload) => ({
    type: ACTIONS.AKTE_META_DATA_POPULATE,
    payload
})

export const getAkteMetaFailed = () => ({
    type: ACTIONS.AKTE_META_DATA_FAILED,
})