import { NOTIFICATION } from 'application/constants'
import { unregisterMessage, updateDataOnMessage } from 'application/redux/actions/messages'
import { showToast } from 'application/redux/actions/notifications'
import { put } from 'redux-saga/effects'
import { getUpdatedAktenPayloadSaga } from './get_updated_akten_payload_saga'
import { unregisterFoldersFromUnexistedMessage } from './unregister_folders_from_unexisted_message'

export function* isAkteExistSaga(action) {
    const updatedAkten = yield getUpdatedAktenPayloadSaga()
    const difference = action.curAkten.filter(x => !updatedAkten.some(y => y.idHash === x.idHash))

    for (const d of difference) {
        yield unregisterFoldersFromUnexistedMessage(d.idHash)
        yield put(updateDataOnMessage(d.idHash, { exist: false }))
        yield put(unregisterMessage(d.idHash))
        if (d.idHash === action.match.params.akteIdHash) {
            yield put(showToast(`${d.aktenzeichen} is no more shared`, NOTIFICATION.LEVEL.ERROR))
        }
    }
    yield
}