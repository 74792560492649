import React from 'react'
import RedirectMobile from './redirect_mobile'
import RedirectTablet from './redirect_tablet'

const RedirectDevice = ({ TabletCompoentLandscape = null, TabletCompoentPortrait = null, MobileComponent = null, ...props }) => {
    const { children } = props
    
    return <RedirectTablet TabletCompoentLandscape={TabletCompoentLandscape} TabletCompoentPortrait={TabletCompoentPortrait} {...props}>
        <RedirectMobile MobileComponent={MobileComponent} {...props}>
            {children}
        </RedirectMobile>
    </RedirectTablet>
}

export default RedirectDevice
