import styled from 'styled-components'

export const WrapperServiceProviderDetail = styled.div`
    padding: ${p => p.noPadd ? '0px' : '10px 20px'};
    width: 100%;
    height: 100%;
    overflow: ${props => !props.isOverflow ? 'hidden' : 'auto'};
    max-height: ${props => props.maxHeight === undefined ? '100%' : props.maxHeight};
`

export const StyledLoadMoreServiceProviderDetail = styled.div`
    font-size: ${props => props.theme.fontSize.headline3};
    color: ${props => props.theme.color.color90};
    text-align: center;
    cursor: pointer;
`

export const StyledAccountNameServiceProviderDetail = styled.h1`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.mainColor !== undefined ? props.mainColor : props.theme.color.mainAkte};
    word-break: break-word;
    text-align: center;
`