import { FlexDividerVertical } from 'application/components/fragments/flex_divider'
import { FlexGrid } from 'application/components/fragments/grid'
import { useQuery } from 'application/components/hooks'
import React from 'react'
import { useTheme, withTheme } from 'styled-components'
import InternalLayout from '../../_layout'
import { aktenExtraProps } from './helpers'
import MessageListSection from './sections/message_list_section'
import MessageDetailsSection from './sections/message_details_section'
import MessageInformationSection from './sections/message_information_section'
import AkteEffects from './akte_effects'
import { useParams } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import WebHeaderLogoEffect from 'application/common/web_header_logo'

const AktenComponent = (props) => {
    const { allAkten, sidebarVisible, allAccounts } = props
    const theme = useTheme()
    const query = useQuery()
    let params = useParams()
    const { favoriteId, parentId, messageKey, widthMessageList, widthMessageDetails, newTitle, folderId } = aktenExtraProps(query, params, theme, allAkten, sidebarVisible)
    const customProps = {
        widthMessageDetails,
        widthMessageList,
        favoriteId,
        parentId,
        folderId,
        messageKey,
        ...props,
        ...params,
    }
    const accountPublicInfo = allAccounts[0]
    return (
        <WebHeaderLogoEffect accountPublicInfo={accountPublicInfo}>
            <InternalLayout withDiv100={false} newMessageTitle={newTitle} paddingTop={false} paddingRight={false} paddingBottom={false} paddingLeft={false} inAkten={true}>
                <AkteEffects {...customProps}>
                    <FlexGrid>
                        {!isMobileOnly && <MessageListSection {...customProps} />}
                        {!isMobileOnly && <FlexDividerVertical hideMobile />}
                        <MessageDetailsSection {...customProps} />
                        {!isMobileOnly && <MessageInformationSection {...customProps} />}
                    </FlexGrid>
                </AkteEffects>
            </InternalLayout>
        </WebHeaderLogoEffect>
    )
}

export default withTheme(AktenComponent)
