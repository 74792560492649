import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Layout = styled.div`
    background-color: #f6f6f6;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    padding: 20px;
`

export const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`

export const HeadlineNotFound = styled.h1`
        text-align: ${p => p.textAlignHeadline};
        font-family: ${props => props.theme.fontFamily.secondary};
        font-size: ${p => p.isMobile ? p.isTablet ? '5vw' : '7.5vw' : '3vw'};
        font-weight: 900;
        margin: 0;
`

export const DescriptionNotFound = styled.p`
        font-size: ${p => p.isMobile ? p.isTablet ? '2vw' : '3.5vw' : '1.5vw'};
        font-weight: 400;
        color: ${props => props.theme.color.color30};
        margin: 0;
`