import ACTIONS from 'application/constants'
import default_store from '../../../default_store'

const aktenActionSendMessageReducer = (state = default_store.pages.akten.action.sendMessage, action) => {
    switch (action.type) {
    case ACTIONS.PAGES_AKTEN_SEND_MESSAGE_REQUEST: return {
        ...state,
        id: action.metaData.akteIdHash,
        // messageIdQueue: state.messageIdQueue.filter(m => m === action.idOnQueue).length > 0 ?
        //     state.messageIdQueue : [...state.messageIdQueue, action.idOnQueue],
        request: true,
    }
    case ACTIONS.PAGES_AKTEN_SEND_MESSAGE_SUCCEED: return {
        ...state,
        id: null,
        request: false,
        succeed: true,
    }
    case ACTIONS.PAGES_AKTEN_SEND_MESSAGE_FAILED: return {
        ...state,
        id: null,
        request: false,
        succeed: false,
        failed: true
    }
    default:
        return state
    }
}

export default aktenActionSendMessageReducer