import styled from 'styled-components'

export const StyledProviderCard = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 23px;
    height: auto;
    min-height: 342px;
    /* overflow: auto; */
    background: white;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    padding-top: 10px;
    margin-bottom: 24px;
    margin-right: 24px;
    cursor: pointer;
    border: ${p => p.isActive ? `3px solid ${p.theme.color.color90}` : 'unset'};
`

export const SyledRestCategoriesServiceProviderCard = styled.span`
    padding: 9px;
    background: #e3e3e3;
    border-radius: 10px;
    color: ${p => p.mainColor};
    font-size: ${p => p.theme.fontSize.medium};
    font-weight: ${p => p.theme.fontWeight.bold};
    display: flex;
    justify-content: center;
    align-items: center;
`