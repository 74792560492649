import React from 'react'
import { Fragment } from 'react'
import { isTablet } from 'react-device-detect'
import { FlexDividerVertical } from 'application/components/fragments/flex_divider'
import { FlexGridItem } from 'application/components/fragments/grid'
import AktenSidebar from '../sidebar'

const MessageInformationSection = (props) => {
    const { sidebarVisible, selectedAkte, messageKey, parentId, akteIdHash, messageFavorite, allAktenLoaded, accountId, folderId } = props
    return (
        <Fragment>
            {sidebarVisible && !isTablet && (
                <React.Fragment>
                    <FlexDividerVertical hideMobile />
                    <FlexGridItem hideMobile grow={0.9} shrink={0} width='20%'>
                        {selectedAkte !== null && (
                            <AktenSidebar
                                messageKey={messageKey}
                                parentId={parentId}
                                accountId={accountId}
                                folderId={folderId}
                                akteIdHash={akteIdHash}
                                favoriteMessages={messageFavorite}
                                allAktenLoaded={allAktenLoaded}
                                // metaInfo={metaInfo}
                                selectedAkte={selectedAkte}
                            />
                        )}
                    </FlexGridItem>
                </React.Fragment>
            )}
        </Fragment>
    )
}

export default MessageInformationSection
