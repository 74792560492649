import { ThumbnailContainer } from 'application/components/building_blocks/attachments/thumbnail'
import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import { useParams } from 'react-router-dom'
import { WrapperFilesSidebar } from '../fragments'

const DisplayPdf = (props) => {
    const {
        thumbnailsPdf,
        akteIdHash,
        parentId,
        onOpenGallery,
    } = props
    let params = useParams()
    return <React.Fragment>
        <WrapperFilesSidebar height='74vh' noPadd maxHeight='100%' isoverflow={true}>
            <FlexGrid>
                {
                    thumbnailsPdf.sort((a, b) => b.id - a.id).map((s, i) => {
                        return <ThumbnailContainer
                            key={i}
                            fileId={s.id}
                            fileName={s.fileName}
                            messageId={s.messageId}
                            akteIdHash={akteIdHash}
                            parentId={parentId}
                            onAttachmentClick={() => onOpenGallery(s.id, s.fileName, params.accountId, parentId, akteIdHash, thumbnailsPdf)}
                            showCaption={true}
                            marginRight={false}
                            isSizeFixed={true}
                            creationDate={s.creationDate}
                            accountId={params.accountId}
                        />
                    })
                }
            </FlexGrid>
        </WrapperFilesSidebar>
    </React.Fragment>
}

export {DisplayPdf}
export default DisplayPdf