import React, { useState } from 'react'
import { TextInputWithLabel } from '..'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import PasswordStrengthChecker from 'application/components/building_blocks/password_strength_checker'
import { useStrengthDescriptors } from 'application/components/hooks/use_strength_checker'
import { nanoid } from 'nanoid'

const StyledTriggerTooltip = styled.a`
    text-decoration: none;
`

const TextInputPasswordToolTipChecker = ({
    label,
    name,
    register,
    validate = {},
    error,
    hideValidationMessage = false,
    defaultValue = '',
    placeholder = '',
    onChange = () => { },
    showMarkLabel = true,
    pushBottom = false,
    width,
    disabled = false,
    password,
    positionTooltip = 'right',
    errorMessageOverride
}) => {
    const [show, setShow] = useState(false)
    const type = show ? 'text' : 'password'
    const iconKey = show ? 'visibility_off' : 'visibility'
    return <React.Fragment>
        <StyledTriggerTooltip
            data-tip data-for="checker">
            <TextInputWithLabel
                hideValidationMessage={hideValidationMessage}
                error={error}
                label={label}
                name={name}
                register={register}
                validate={validate}
                defaultValue={defaultValue}
                placeholder={placeholder}
                onChange={onChange}
                showMarkLabel={showMarkLabel}
                pushBottom={pushBottom}
                width={width}
                disabled={disabled}
                inputType={type}
                withIcon={true}
                iconKey={iconKey}
                errorMessageOverride={errorMessageOverride}
                onIconClick={() => setShow(!show)}
                htmlId={nanoid()}
            />
        </StyledTriggerTooltip>
        <ReactTooltip place={positionTooltip} effect='solid' id="checker">
            <PasswordStrengthChecker
                password={password}
                strengthDescriptors={useStrengthDescriptors(
                    ''
                )}
            />
        </ReactTooltip>
    </React.Fragment> 
}

export default TextInputPasswordToolTipChecker