
export const getNewestPhaseState = (phases) => {
  let latestState
  
  phases.stateGroups.forEach((state) => {
    if(state.states.length === 0)
      return latestState

    latestState = state
  })

  return latestState
}

export const getRedDotColor = (state) => {
  if(state.states.length === 0) return 'gray'
  if(state.name === 'Fall Abgeschlossen') return 'green'
  return state.states[state.states.length-1].isInProgress ? 'orange' : 'green'
}
