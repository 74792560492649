import React from 'react'
import { GeneralSettingForm } from '../../form'
import ContentSettingLayout from '../common/layout'
import { useTranslation } from 'react-i18next'

const GeneralSettingComponent = (props) => {
    const {t} = useTranslation()
    return <ContentSettingLayout
        headline= {t('General Settings')}
        subHeadline= {t('Language')}
        // description='et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum'
    >
        <GeneralSettingForm {...props} />
    </ContentSettingLayout>
}

export default GeneralSettingComponent