import React, { Fragment, useEffect } from 'react'

const AllAccountsLoader = (props) => {
    const { getAllAccounts, children, auth, allAccountsLoaded } = props
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    useEffect(() => {
        loggedIn && !allAccountsLoaded && getAllAccounts()
    }, [ getAllAccounts, allAccountsLoaded, loggedIn])

    return <Fragment>
        {children}
    </Fragment>
}

export default AllAccountsLoader