import React from 'react'
import { LayoutBase } from '..'
import * as Fragment from '../fragments/auth'

const MobileAssistantLayout = ({ children }) => {
    return <LayoutBase>
        <Fragment.LayoutWrapper>
            {children}
        </Fragment.LayoutWrapper>
    </LayoutBase>
}

export default MobileAssistantLayout