import styled from 'styled-components'

export const FlexDividerVertical = styled.div`
    flex: 0 0 0;
    border-left: 1px solid ${props => props.theme.color.color15};
    display: ${props => props.hideMobile ? 'none' : 'flex'};

    @media (min-width: ${props => props.theme.breakpoint.sm}) {
        display: flex;
    }
`

export const FlexDividerHorizontal = styled.div`
    flex: 0 0 0;
    border-top: 1px solid ${props => props.theme.color.color15};
`