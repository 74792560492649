import ACTIONS from 'application/constants'
import { consentStorage } from 'application/storage'
import default_store from '../default_store'

const consentUseApp = (state = {...default_store.consentUseApp, accept: consentStorage.get() !== ''}, action) => {
    switch (action.type) {
    case ACTIONS.CONSENT_SET_ACCEPT:
        consentStorage.store(action.dateAccept)
        return {
            ...state,
            accept: true
        }
    default:
        return state
    }
}

export default consentUseApp