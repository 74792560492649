import { usePluginsContext }  from 'application/components/hooks/use_plugins_context'
import { ROUTES }  from 'application/constants'
import { useEffect }  from 'react'
import { useNavigate }  from 'react-router-dom'

export const WhitelabelAccountsEffect = ({ children }) => {
    const { activePlugin } = usePluginsContext()
    const navigate = useNavigate()
    
    useEffect(() => {
        if(activePlugin) navigate(ROUTES.NOT_FOUND)
        console.log(activePlugin)
    }, [activePlugin])

    return <>{children}</>
}


