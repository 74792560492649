import PreloaderSpin from 'application/components/controls/preloader/spin'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as Fragment from '../../fragments'

const StyledNewPin = styled.button`
    text-decoration: underline;
    color: ${p => p.theme.color.requestPin};
    background: transparent;
    font-weight: ${p => p.theme.fontWeight.semiBold};
    border: none;
    cursor: pointer;
`

const RequestPinAgainIfUserDidntGetThePin = ({ akteMeta, pinRequest, handleRequestPin }) => {
    const { t } = useTranslation()
    return <React.Fragment>
        {
            !akteMeta.isSmsAvailable && pinRequest ?
                <PreloaderSpin /> :
                <Fragment.PrivacyExplanation>
                    {t('RequestPINQuestion')}
                    <StyledNewPin onClick={handleRequestPin}>
                        {t('RequestPINAnother')}
                    </StyledNewPin>
                </Fragment.PrivacyExplanation>
        }
    </React.Fragment>
}
            
export default RequestPinAgainIfUserDidntGetThePin
