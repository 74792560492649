import { combineReducers } from 'redux'

import authReducer from './auth'
import notificationsReducer from './notifications'
import dialogsReducer from './dialogs'
import waitingScreen from './waiting_screen'
import wizard from './wizard'
import pages from './pages'
import akteMeta from './akte_meta'
import common from './common'
import files from './files'
import gallery from './gallery'
import messages from './messages'
import consentUseApp from './consent_use_app'
import default_store from './default_store'
import form from './form'
import sidebars from './sidebars'
import ACTIONS from 'application/constants'

const reducers = combineReducers({
    auth: authReducer,
    notifications: notificationsReducer,
    dialogs: dialogsReducer,
    pages,
    waitingScreen,
    wizard,
    akteMeta,
    common,
    files,
    gallery,
    messages,
    consentUseApp,
    form,
    sidebars,
})

const appReducer = (state, action) => {
    if (action.type === ACTIONS.AUTH_LOGOUT_REQUEST) {
        state = {
            ...default_store, //reset the complete store on logout
            //except:
            // auth: state.auth, //is handled by auth reducer -> case ACTIONS.AUTH_LOGOUT_REQUEST:
            notifications: state.notifications, //notifications should survive logout
            consentUseApp: state.consentUseApp
        }
    }
    return reducers(state, action)
}

export default appReducer
