import { FORMS, ROUTES } from 'application/constants'
import React, { createContext } from 'react'
import { useParams } from 'react-router-dom'

export const RouteLocationContext = createContext()

const RouteLocationExternalFormProvider = ({ children }) => {
  let params = useParams()
  const formType =
    params.processGuid !== undefined ? FORMS.ASSISTENT : FORMS.EXTERNALREQUEST

  const callbackValues = (formType) => {
    switch (formType) {
      case FORMS.ASSISTENT:
        return {
          getLookUpFirst: false,
          valueParams: {
            key: params.processGuid,
            externalRequestKey: null,
          },
          returnUrlOnSucced:
            ROUTES.FILL_THE_FORM_BASE + params.processGuid + '/succeed',
        }
      case FORMS.EXTERNALREQUEST:
        return {
          getLookUpFirst: true,
          valueParams: {
            key: params.accountIdentity,
            externalRequestKey: params.externalRequestKey,
          },
          returnUrlOnSucced:
            ROUTES.PAGE_BASE +
            params.accountIdentity +
            '/guidance/' +
            params.externalRequestKey +
            '/confirmation-page',
        }
      default:
        return {
          valueParams: {
            key: params.processGuid,
            externalRequestKey: null,
          },
          returnUrlOnSucced:
            ROUTES.FILL_THE_FORM_BASE + params.processGuid + '/succeed',
        }
    }
  }
  const values = {
    formType,
    ...callbackValues(formType),
  }

  return (
    <RouteLocationContext.Provider value={{ ...values }}>
      {children}
    </RouteLocationContext.Provider>
  )
}

export default RouteLocationExternalFormProvider
