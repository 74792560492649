import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { NOTIFICATION } from 'application/constants'
import { logoutRequest } from 'application/redux/actions/auth'
import { registeredMessageFailed, unregisterMessage, updateDataOnMessage } from 'application/redux/actions/messages'
import { unregisterAkte } from 'application/redux/actions/common/all_akten'
import { unregisterFoldersFromUnexistedMessage } from '../sagas/messages/unregister_folders_from_unexisted_message'

export function* handleError(e, action = { type: 'DEFAULT' }, level = NOTIFICATION.LEVEL.ERROR) {
    let message = 'Unexpected error'
    if (e.data !== undefined && e.data !== null && e.data.reasonMessage !== undefined && e.data.reasonMessage !== '' && e.data.reasonMessage !== null) {
        message = e.data.reasonMessage
    }
    if (e.data !== undefined && e.data !== null && e.data.Reason !== undefined && e.data.Reason !== '' && e.data.Reason !== null) {
        message = e.data.Reason
    }
    if (e.response !== undefined && e.response !== null && e.response.status === 401) {
        yield put(logoutRequest())
        yield put(showToast(message, level))
    } else {
        if (!e.isAuthError && !e.isConflict && !e.isOverRateLimit) {
            yield put(showToast(message, level))
        } else {
            yield put(showToast(message, level))
        }
    }
}

export function* handleErrorFile(e, action, level = NOTIFICATION.LEVEL.ERROR) {
    let message = 'Unexpected error'

    if (e.data !== undefined && e.data !== null && e.data.reasonMessage !== undefined && e.data.reasonMessage !== '' && e.data.reasonMessage !== null) {
        message = e.data.reasonMessage
    }
    if (e.response !== undefined && e.response !== null && e.response.status === 401) {
        yield put(logoutRequest())
        yield put(showToast(message, level))
    }
}

export function* handleAkteForbidden(e, akteIdHash, aktenzeichen) {
    yield put(registeredMessageFailed(akteIdHash))
    yield put(updateDataOnMessage(akteIdHash, { exist: false }))
    yield put(unregisterMessage(akteIdHash))
    yield unregisterFoldersFromUnexistedMessage(akteIdHash)
    yield put(unregisterAkte(akteIdHash))
    yield handleMessageNoMoreExist(aktenzeichen)
    if (e !== null || e !== undefined || e !== '') {
        yield handleError(e)
    }
}

export function* handleAkteFolderForbidden(e, folderId, currentMessageName) {
    yield put(registeredMessageFailed(folderId))
    yield unregisterFoldersFromUnexistedMessage(folderId)
    yield put(updateDataOnMessage(folderId, { exist: false }))
    yield put(unregisterMessage(folderId))
    yield handleMessageNoMoreExist(currentMessageName)
    if (e !== null || e !== undefined || e !== '') {
        yield handleError(e)
    }
}

export function* handleAkteFolderNotExistOnNewLoad(e, akteIdHash, folderId) {
    yield put(registeredMessageFailed(folderId))
    yield put(updateDataOnMessage(folderId, { exist: false }))
    yield put(unregisterMessage(folderId))
    yield unregisterFoldersFromUnexistedMessage(folderId)

    yield handleError(e)
}

export function* handleMessageNoMoreExist(currentMessageName = '') {
    let message = currentMessageName !== '' ? `${currentMessageName} is no more shared` : 'Message is no more shared'
    yield put(showToast(message, NOTIFICATION.LEVEL.WARNING))
}
