import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

export function LogoTopBar() {
  const {getAsset} = usePluginsContext()
  
  return (
    <img
      src={getAsset('myk-law.png')}
      altText='logo myk-law'
      width={64}
      style={{ 
        scale : '1.2',
        marginTop: '24px'
       }}
    />
  )
}
