import { getProfilePicture, refreshProfilePicture } from 'application/redux/actions/auth'
import { hasProfilePicture } from 'application/redux/selectors'
import { put, select } from 'redux-saga/effects'

export function* refreshProfilePictureSaga(action) {
    const has = yield select(hasProfilePicture)
    if (has) {
        yield put(refreshProfilePicture())
    } else {
        yield put(getProfilePicture(action.payload.accountId, action.payload.userId))
    }
}