import { textHelpers, validationMethods } from 'application/common'
import { Form, TextInputPassword, TextInputWithLabel } from 'application/components/controls/form_elements'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { ErrorLabel } from 'application/components/fragments/error_information'
import { FlexGrid } from 'application/components/fragments/grid'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import { CONSENT, ROUTES } from 'application/constants'
import { nanoid } from 'nanoid'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import ActionButton from 'application/components/controls/button/action_button/component'
import LoginFormEffects from './effects'
import CountdownBanner from 'application/components/building_blocks/countdown_banner'


const StyledButtonInput = styled.div`
    input {
        box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: row;
    flex-shrink: 0;
    border-style: solid;
    border-width: 1px;
    position: relative;
    z-index: 0;
    min-height: 0px;
    min-width: 80px;
    border-color: ${props => props.theme.color.color100};
    border-radius: 0px;
    height: 60px;
    justify-content: center;
    padding: 19px 24px;
    transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s, color 0.2s ease 0s;
    cursor: pointer;
    background-color: ${props => props.theme.color.color100};
    max-width: 100%;
    color: ${props => props.theme.color.color0};
    font-weight: ${props => props.theme.fontWeight.medium};
    font-size: ${props => props.theme.fontSize.medium18};
    line-height: 20px;
    visibility: visible;
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricprecision;
    text-size-adjust: none;

    &::hover {
        background-color: ${props => props.theme.color.color90};
    }
    }
`


const LoginFormDialog = (props) => {
    const { onLoginFormSubmit, auth, setConsent, consentUseApp, theme, refreshForgetPassword, closeDialog, refresPayloadFailed } = props
    const { registered } = auth
    const { payloadFailed } = registered
    // const payload = dialog.payload
    const { t } = useTranslation()
    const defaultValues = {
        username: '',
        password: ''
    }

    const { register, formState: { errors }, handleSubmit, getValues } = useForm({ defaultValues })

    const validationInput = {
        notEmpty: validationMethods.notEmpty
    }

    const onSubmitSuccess = () => {
        onLoginFormSubmit(getValues())
        !consentUseApp.accept && setConsent(textHelpers._enText(CONSENT.ACCEPT_TEXT + new Date()))
    }
    const handleRefresForgetPassword = () => {
        refreshForgetPassword()
        closeDialog()
    }
    const handleForgetUsername = () => {
        closeDialog()
    }
    const onRefreshPayloadFailed = () => {
        refresPayloadFailed()
    }
    return <LoginFormEffects {...props}>
        <DialogMainContent>
            {
                auth.registered.loginRequest ? <PreloaderSpin /> :
                    <React.Fragment>
                        <Form onSubmit={handleSubmit(onSubmitSuccess)}>
                            <TextInputWithLabel
                                placeholder={t('username')}
                                name='username'
                                register={register}
                                validate={validationInput}
                                error={errors.username}
                                showMarkLabel={false}
                                errorMessageOverride='UsernameNotEmpty'
                                pushBottom={true}
                                htmlId={nanoid()}
                            />
                            <TextInputPassword
                                placeholder={t('password')}
                                name='password'
                                register={register}
                                validate={validationInput}
                                error={errors.password}
                                errorMessageOverride={t('PasswordNotEmpty')}
                                showMarkLabel={false}
                                pushBottom={true}
                            />
                            <StyledButtonInput>
                                <input type='submit' value={t('login')} />
                                {
                                    payloadFailed !== null && (payloadFailed.nextPossibleRetry !== undefined || payloadFailed.nextPossibleRetry !== null) && <CountdownBanner
                                        infoText={'🔒' + t('Account is locked. Login attempt exceeded') + '. ' + t('Try again in')}
                                        timeGiven={payloadFailed.nextPossibleRetry} onComplete={onRefreshPayloadFailed}
                                    />
                                }
                            </StyledButtonInput>
                        </Form>
                        <PushBottom24 />
                        <FlexGrid justifySpaceBetween>
                            <ActionButton
                                noPadding
                                noBackground
                                buttonText={t('Forgot Username')}
                                linkTo={ROUTES.FORGET_USERNAME}
                                color={theme.color.red}
                                fontSize={theme.fontSize.small}
                                onButtonClick={handleForgetUsername}
                            />
                            <ActionButton
                                noPadding
                                noBackground
                                buttonText={t('Forgot Password')}
                                linkTo={ROUTES.FORGET_PASSWORD}
                                color={theme.color.red}
                                fontSize={theme.fontSize.small}
                                onButtonClick={handleRefresForgetPassword}
                            />
                        </FlexGrid>
                        {
                            auth.registered.loginFailed && payloadFailed === null && <React.Fragment>
                                <PushBottom24 />
                                <ErrorLabel>
                                    {t(auth.common.errorMessage)}
                                </ErrorLabel>
                            </React.Fragment>
                        }
                    </React.Fragment>
            }
            <PushBottom24 />
            <PushBottom24 />
        </DialogMainContent>
    </LoginFormEffects>
}

export default withTheme(LoginFormDialog)
