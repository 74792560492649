import React from 'react'
import styled from 'styled-components'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form_elements/_common'
import FontIcon from 'application/components/controls/font_icon'

const StyledTextInputWithLabel = styled.div`

    display: flex;
    flex-direction: column;
    width: ${props => props.width !== undefined ? props.width : '100%'};
    margin-bottom: ${props => props.pushBottom ? '8px' : '0px'};
    font-size: ${props => props.theme.fontSize.headline3};
    color: ${props => props.theme.color.color100};
    font-weight: ${props => props.theme.fontWeight.headLine3};


    & label.label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.standard};
    }
`

const StyledInputWrapper = styled.div`
    height: 60px;
    padding: 15px;
    border-radius: 5px;
    align-items: center;
            font-size: ${props => props.theme.fontSize.headline3};
    display: flex;
    justify-content: space-between;
    background-color: ${props => props.theme.color.color0};
    border: 1px solid ${props => props.disabled ? props.theme.color.color15 : props.cssValidationError ? props.theme.color.red : props.isSuccess ? props.theme.color.success : 'rgb(150, 147, 145)'};
    & input[type=text]{
        box-sizing: border-box;
        border: unset;
        width: 80%;
		color: ${props => props.theme.color.color100};
    background-color: ${props => props.theme.color.color0};
    border-radius: 5px;
        @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
            height: 41px;
            font-size: ${props => props.theme.fontSize.medium18};
    }
    }

    & input[type=password]{
        box-sizing: border-box;
        border: unset;
        width: 80%;
		color: ${props => props.theme.color.color100};
    background-color: ${props => props.theme.color.color0};
    border-radius: 5px;
        @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
            height: 41px;
            font-size: ${props => props.theme.fontSize.medium18};
    }
    }

    & input:invalid{
        border: unset;
    }


    & input[type=text]::placeholder { 
        color: ${props => props.theme.color.color80};
        font-size: ${props => props.theme.fontSize.medium18};
        font-weight: ${props => props.theme.fontWeight.medium};
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props.theme.color.color80};
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props.theme.color.color80};
      }


    & input[type=text]:hover {
        border: unset;
    }

    & input[type=text]:active {
        border: unset;
    }

    & input[type=text]:disabled {
        background-color: ${props => props.theme.color.color5};
        color: ${props => props.theme.color.color80};
    }

    & input[type=text]:focus{
        outline: none;
            font-size: ${props => props.theme.fontSize.headline3};
    }

    & input[type=password]::placeholder { 
        color: ${props => props.theme.color.color80};
        font-size: ${props => props.theme.fontSize.medium18};
        font-weight: ${props => props.theme.fontWeight.medium};
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props.theme.color.color80};
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props.theme.color.color80};
      }

      & input:read-only {
        background-color: ${props => props.theme.color.color5};
      }


    & input[type=password]:hover {
        border: unset;
    }

    & input[type=password]:active {
        border: unset;
    }

    & input[type=password]:disabled {
        background-color: ${props => props.theme.color.color5};
        color: ${props => props.theme.color.color80};
    }

    & input[type=password]:focus{
        outline: none;
    }
`

const StyledIcon = styled.span`
    display: flex;
    align-items: flex-end;
`

export const TextInput = ({
    label,
    name,
    register,
    onKeyPress = () => { },
    onInputBlur = () => { },
    onInputFocus = () => { },
    validate = {},
    error,
    hideValidationMessage = false,
    defaultValue = '',
    inputType = 'text', 
    errorMessageOverride = '',
    placeholder = '',
    onChange = () => { },
    showMarkLabel = true,
    pushBottom = false,
    width,
    isSuccess,
    cssValidationError,
    disabled = false,
    withIcon = false,
    iconKey = 'visibility',
    iconSize = '28px',
    onIconClick,
    readOnly = false,
    htmlId
}) => {
    return <StyledTextInputWithLabel disabled={disabled} isSuccess={isSuccess} cssValidationError={cssValidationError} width={width} pushBottom={pushBottom}>
        <label className='label' htmlFor={htmlId}>{label}<RequiredFieldMarker showMarkLabel={showMarkLabel} validate={validate} /></label>
        <StyledInputWrapper>
            <input
                readOnly={readOnly}
                autoComplete='off'
                disabled={disabled}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                onKeyPress={onKeyPress}
                id={htmlId}
                type={inputType}
                onChange={onChange}
                placeholder={placeholder} 
                name={name}
                defaultValue={defaultValue}
                {...register(name, {
                    validate
                })}
            />
            {
                withIcon && <StyledIcon onClick={onIconClick}>
                    <FontIcon isClickable={true} icon={iconKey} fontSize={iconSize} />
                </StyledIcon>
            }
        </StyledInputWrapper>
        <ValidationErrorMessage hideValidationMessage={hideValidationMessage} error={error} errorMessageOverride={errorMessageOverride} />
    </StyledTextInputWithLabel>
}

export default TextInput