import ACTIONS from 'application/constants'

export const registerAccountPublicInfoInternalAssets = (accountId) => ({
    type: ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REGISTER_ACCOUNT,
    accountId
})

export const unregisterAccountPublicInfoInternalAssets = (accountId) => ({
    type: ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_UNREGISTER_ACCOUNT,
    accountId
})

export const requestDataAccountPublicInfoInternalAssets = (accountId) => ({
    type: ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA,
    accountId
})

export const requestDataSucceedAccountPublicInfoInternalAssets = (accountId) => ({
    type: ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA_SUCCEED,
    accountId
})

export const requestDataFailedAccountPublicInfoInternalAssets = (accountId) => ({
    type: ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA_FAILED,
    accountId
})

export const populateDataByAccountIdAccountPublicInfoInternalAssets = (accountId, payload) => ({
    type: ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_POPULATE_DATA_BY_ACCOUNT_ID,
    accountId,
    payload,
})

export const getDataFromStoreAccountPublicInfoInternalAssets = (accountId) => ({
    type: ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_GET_DATA_IN_STORE,
    accountId,
})