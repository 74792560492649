
import React from 'react'
import styled, { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import FontIcon from 'application/components/controls/font_icon'
import { withOrientationChange } from 'react-device-detect'

const InputMessageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    position: relative;
`

const OpenMessageDialogButton = styled.button`
    border: none;
    margin: 0;
    padding: 0;
    width: 48px;
    color: white;
    height: 48px;
    background-color: ${p => p.theme.color.primary};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 24px;
    bottom: 16px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
`

const InputMessageComponent = ({
    userFirstName,
    userLastName,
    accountId,
    akteIdHash,
    folderId,
    userId,
    messageKey,
    setDisplayContentMobile,
    openSendMessageDialog,
}) => {
    const fullName = userFirstName !== null ? userFirstName + ' ' + userLastName : userLastName
    const metaData = {
        accountId,
        akteIdHash,
        folderId,
    }
    const {t} = useTranslation()    

    return (
        <React.Fragment>
            <InputMessageWrapper>
                <OpenMessageDialogButton type='button'
                    onClick={() => {
                        openSendMessageDialog({
                            accountId: accountId,
                            userId: userId,
                            fullName: fullName,
                            messageKey: messageKey,
                            metaData : metaData,
                            setDisplayContentMobile: setDisplayContentMobile,
                        }, 
                        t('New Message')) 
                    }}
                >
                    <FontIcon icon={'send'} isClickable={true} color='white' fontSize='24px'/>
                </OpenMessageDialogButton>
            </InputMessageWrapper>
        </React.Fragment>
    )
}

export default withOrientationChange(withTheme(InputMessageComponent))
