import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import IconLink from 'application/components/controls/icon/icon_link'
import ContextMenu from 'application/components/controls/context_menu'
import ContextMenuItem from 'application/components/controls/context_menu_item'
import { useTranslation } from 'react-i18next'
import { ROUTES } from 'application/constants'
import { isTablet } from 'react-device-detect'

export const MenuServiceProvider = ({ closeServiceProvideDetailSidebar, selectedAccount, openAgb, openDatenshutz, backgroundColor }) => {
    const { esyThingAgb, esyThingDatenschutz, name } = selectedAccount
    var hasAgb = esyThingAgb !== null,
        hasDatenschutz = esyThingDatenschutz !== null
    const hasLaw = hasAgb || hasDatenschutz
    const {t} = useTranslation()
    return <FlexGrid>
        <FlexGridItem>
            {
                !isTablet &&
                <IconLink  width='40px' iconKey='chevron_left' borderradius='15px' to={ROUTES.DASHBOARD} onClick={closeServiceProvideDetailSidebar} />
            }
        </FlexGridItem>
        {
            hasLaw &&
            <FlexGridItem itemsCenter justifyFlexEnd>
                <ContextMenu backgroundColorIconTrigger='transparents'>
                    {
                        hasAgb && <ContextMenuItem onListElementClick={() => openAgb(esyThingAgb, t('conditions') + ' - ' + name)}>
                            <span>
                                {t('conditions')}
                            </span>
                        </ContextMenuItem>
                    }
                    {
                        hasDatenschutz && <ContextMenuItem onListElementClick={() => openDatenshutz(esyThingDatenschutz, t('conditions') + ' - ' + name)}>
                            <span>
                                {t('privacy')}
                            </span>
                        </ContextMenuItem>
                    }
                </ContextMenu>
            </FlexGridItem>
        }
    </FlexGrid> 
}
export default MenuServiceProvider