import ACTIONS from 'application/constants'

export const registerMessage = (messageKey, messageType) => ({
    type: ACTIONS.MESSAGES_REGISTER_MESSAGES,
    messageKey,
    messageType,
})

export const unregisterMessage = (key) => ({
    type: ACTIONS.MESSAGES_UNREGISTER_MESSAGES,
    key,
})

export const refreshRegisteredMessage = (key) => ({
    type: ACTIONS.MESSAGES_REFRESH_REGISTER_MESSAGES,
    key,
})

export const registeredMessageFullyLoaded = (key, metaData) => ({
    type: ACTIONS.MESSAGES_REGISTERED_MESSAGES_FULLY_LOADED,
    key,
    metaData,
})

export const registeredMessageFailed = (key) => ({
    type: ACTIONS.MESSAGES_REGISTERED_MESSAGES_FAILED,
    key,
})

export const putMessageAsFavorite = (key, messageId, condition) => ({
    type: ACTIONS.MESSAGES_PUT_MESSAGE_AS_FAVORITE,
    key,
    messageId,
    condition,
})

export const readMessage = (key, messageId) => ({
    type: ACTIONS.MESSAGES_READ_MESSAGE,
    key,
    messageId,
})

export const updateDataOnMessage = (key, payload) => ({
    type: ACTIONS.MESSAGES_UPDATE_MESSAGES,
    key,
    payload,
})

export const recountUnreadMessageInFolderByMessageKey = (key, folderId) => ({
    type: ACTIONS.MESSAGES_RECOUNT_UNREAD_MESSAGE_IN_FOLDER_BY_MESSAGE_KEY,
    key,
    folderId,
})

export const updateDataInFolderByMessageKey = (key, folderId, payload) => ({
    type: ACTIONS.MESSAGES_UPDATE_DATA_IN_FOLDER_BY_MESSAGE_KEY,
    key,
    folderId,
    payload,
})

export const putMessageOnQueue = (key, payload) => ({
    type: ACTIONS.PAGES_AKTEN_PUT_MESSAGE_ON_QUEUE,
    key,
    payload,
})

export const popMessageOnQueue = (key, idQueue) => ({
    type: ACTIONS.PAGES_AKTEN_POP_MESSAGE_ON_QUEUE,
    key,
    idQueue,
})

export const replaceMessageQueueById = (key, idQueue, payload) => ({
    type: ACTIONS.PAGES_AKTEN_REPLACE_MESSAGE_QUEUE_BY_ID,
    key,
    idQueue,
    payload,
})

// just need a match - bcs it has to be the actual one based on url link
export const updateDataSelectedMessageRoot = (params, akteIdHash, accountId, metaData) => ({
    type: ACTIONS.MESSAGES_AKTEN_UPDATE_SELECTED_MESSAGE_ROOT,
    params,
    akteIdHash,
    accountId,
    metaData,
})

// just need a params and actual folder id - bcs it has to be the actual one based on url link
export const updateDataSelectedMessageFolder = (params, folderId, akteIdHash, accountId, metaData) => ({
    type: ACTIONS.MESSAGES_AKTEN_UPDATE_SELECTED_MESSAGE_FOLDER,
    params,
    folderId,
    akteIdHash,
    accountId,
    metaData,
})

export const getMessageFavorite = (messageKey, accountId, akteIdHash, folderId) => ({
    type: ACTIONS.MESSAGES_AKTEN_GET_FAVORITE_MESSAGES,
    messageKey,
    accountId,
    akteIdHash,
    folderId,
})

export const populateMessageFavorite = (payload) => ({
    type: ACTIONS.MESSAGES_AKTEN_POPULATE_FAVORITE_MESSAGES,
    payload,
})

export const getMessageFavoriteFailed = () => ({
    type: ACTIONS.MESSAGES_AKTEN_GET_FAVORITE_MESSAGES_FAILED,
})

export const refreshMessageFavorite = () => ({
    type: ACTIONS.MESSAGES_AKTEN_REFRESH_FAVORITE_MESSAGES,
})

export const setPayloadMessageFavorite = (payload) => ({
    type: ACTIONS.MESSAGES_AKTEN_SET_PAYLOAD_FAVORITE_MESSAGES,
    payload,
})

export const deletePayloadMessageFavorite = (messageId) => ({
    type: ACTIONS.MESSAGES_AKTEN_DELETE_PAYLOAD_FAVORITE_MESSAGES,
    messageId,
})
