import { connect } from 'react-redux'

import SendMessageDialog from './component'
import { clearAllSendToFiles, deleteFilesToSendAktenById, populateFilesOnQueue, sendMessageAkte } from 'application/redux/actions/pages/akten'
import { showGalleryOnQueue } from 'application/redux/actions/gallery'
import { clearAllFIlesOnQueue } from 'application/redux/actions/pages/akten/details'
import { putMessageOnQueue } from 'application/redux/actions/messages'
import { DIALOG } from 'application/constants'
import { closeDialogByName } from 'application/redux/actions/dialogs'

const mapStateToProps = (state, props) => ({
    dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name),
    selectedAkte: state.pages.akten.common.selectedAkte,
    filesToSend: state.pages.akten.details.filesToSend,
    filesOnQueue: state.pages.akten.details.filesOnQueue,
})

const mapDispatchToProps = dispatch => ({
    openGalleryOnQueue: (files, accountId) => {
        dispatch(populateFilesOnQueue(files))
        dispatch(showGalleryOnQueue(null, null, null, accountId, null))
    },
    clearFileToSend: (id) => { dispatch(deleteFilesToSendAktenById(id))},
    createMessage: (payload, metaData, errorMessage, params, idOnQueue, messageOnQueue, messageKey) => {
        dispatch(clearAllSendToFiles())
        dispatch(clearAllFIlesOnQueue())
        dispatch(sendMessageAkte(payload, metaData, errorMessage, params, idOnQueue, messageKey))
        dispatch(putMessageOnQueue(messageKey, messageOnQueue))
        // dispatch(registerMessageQueue(messageKey, idOnQueue, payload, metaData))
    },
    closeSendMessageDialog: () => {
        dispatch(closeDialogByName(DIALOG.NAME.SEND_MESSAGE))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SendMessageDialog)