import { combineReducers } from 'redux'
import action from './action'
import common from './common'
import details from './details'
import sidebar from './sidebar'

export default combineReducers({
    action,
    common,
    details,
    sidebar,
})