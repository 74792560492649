import { getRoutePath } from 'application/common/route_helpers'
import { ROUTES } from 'application/constants'
import React, { Fragment, useEffect } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

const BaseRedirect = (props) => {
    let location = useLocation()
    let params = useParams()
    const ableToRedirect = [
        ROUTES.YOU_IN_MOBILE,
        ROUTES.FILL_THE_FORM,
        ROUTES.FILL_THE_FORM_SUCCEED,
        ROUTES.FILL_THE_FORM_EXTERNAL_REQUEST,
        ROUTES.FILL_THE_FORM_EXTERNAL_REQUEST_SUCCEED,
        ROUTES.PAGE_FILL_THE_FORM_EXTERNAL_REQUEST,
        ROUTES.PAGE_FILL_THE_FORM_EXTERNAL_REQUEST_SUCCEED,
        ROUTES.PROUDLY_PRESENTS_BASE,
        ROUTES.PAGE_BASE,
        ROUTES.LOGIN_SPECIFIC_ACCOUNT,
        ROUTES.PAGE_LOGIN_SPECIFIC_ACCOUNT,
        ROUTES.ENTER_PIN,
        ROUTES.ENTER_PIN_HASH,
        ROUTES.REQUEST_PIN,
        ROUTES.REQUEST_PIN_HASH,
        ROUTES.WIZARD_REG,
        ROUTES.LOGIN,
		ROUTES.BASE,
		ROUTES.LOGIN_HASH,
        ROUTES.DASHBOARD,
        ROUTES.SETTINGS_PRESELECTED,
		ROUTES.AKTEN_PRESELECTED,
		ROUTES.FORGET_PASSWORD,
		ROUTES.RESET_PASSWORD_HASH,
		ROUTES.RESET_PASSWORD_DONE,
		ROUTES.FORGET_USERNAME,
		ROUTES.FORGET_PASSWORD_HASH,
		ROUTES.FORGET_USERNAME_HASH,
    ]
    let matchUrlPattern = getRoutePath(location, params)
    let shouldLogout = props.useMobile
		&& (!(ableToRedirect.includes(matchUrlPattern)))
	
    useEffect(() => {
        (props.auth.registered.loggedIn || props.auth.unregistered.loggedIn) && shouldLogout && props.logoutRequest()
    }, [props, shouldLogout]) // force to log out if not by pass

    if (props.useMobile
        && (!(ableToRedirect.includes(matchUrlPattern))) // by pass the exception routes in mobile
    ) {
        if (ableToRedirect.includes(matchUrlPattern)) {
            return <Navigate to={location.pathname} />
        }
        return <Navigate to={ROUTES.YOU_IN_MOBILE} /> // redirect to download the app if not by pass the routes exception mobile
    } else {
        if (!props.useMobile && (matchUrlPattern === ROUTES.YOU_IN_MOBILE)) {
            return <Navigate to={ROUTES.BASE} /> // redirect to base if user not use mobile
        }
    }
    return <Fragment>
        {props.children}
    </Fragment>
}

export default BaseRedirect