import { DIALOG, WIZARD_STEP } from 'application/constants'
import { getAkteMetaRequest } from 'application/redux/actions/akte_meta'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { getWizardDataRequest, registrationRequest, updateWizardDataAllDone } from 'application/redux/actions/wizard'
import { connect } from 'react-redux'
import WizardRegistrationPasswordComponent from './component'

const mapStateToProps = state => ({
    wizardData: state.wizard.data,
    wizardLoaded: state.wizard.isLoaded,
    akteMeta: state.akteMeta.payload,
    auth: state.auth,
    akteMetaLoaded: state.akteMeta.isLoaded,
    registeredRequest: state.wizard.registrationData.registered.request,
    registeredompleted: state.wizard.registrationData.registered.completed,
    registeredFailed: state.wizard.registrationData.registered.failed,
    username: state.wizard.registrationData.username,
    regKey: state.wizard.registrationData.regKey,
    errorMessage: state.wizard.registrationData.errorMessage,
    agbsAndDatenschutzAccepted: state.common.agbDatenschutz.isAccepted,
    accessTokenUnregisteredStore: state.auth.unregistered.accessToken,
})

const mapDispatchToProps = dispatch => ({
    onWizardDone: () => dispatch(updateWizardDataAllDone(WIZARD_STEP.REGISTRATION.DONE)),
    getAkteMetaRequest: (hashUserKey) => dispatch(getAkteMetaRequest(hashUserKey)),
    onRegistrationRequest: (username, password, passwordConfirmation, accountId, key, userId, agbsAndDatenschutzAccepted, accessTokenUnregisteredStore) => {
        dispatch(registrationRequest(username, password, passwordConfirmation, accountId, key, userId, agbsAndDatenschutzAccepted, accessTokenUnregisteredStore))
    },
    onOpenPasswordCriteriaOpenDialog: () => dispatch(openDialogByName(DIALOG.NAME.NOTES_PASSWORD_REGISTRATION, true, {}, 'Password hint')),
    getWizardDataRegistration: (section, accountId, userId) => dispatch(getWizardDataRequest(section, accountId, userId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardRegistrationPasswordComponent)