import ActionButton from 'application/components/controls/button/action_button/component'
import { ROUTES } from 'application/constants'
import { useTranslation } from 'react-i18next'

export function RequestCredentialsMailSentImage({children}){
    const { t } = useTranslation()
    return <>
        {children}
        <div style={{ marginTop: '36px' }}>
            <ActionButton
                secondary={false}
                buttonText={t('ReturnLoginPage')}
                linkTo={ROUTES.LOGIN}
            />
        </div>
    </>
}