import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import AkteCard from 'application/components/building_blocks/akte_card'
import styled, { useTheme } from 'styled-components'
import PreloaderSpin from 'application/components/controls/preloader/spin'

const AktenSidebarPopupWrapper = styled.div`
    padding: 20px;
`

const AktenPopupSidebar = (props) => {
    const { selectedAccount, setSelectedAkte, allAktenLoaded, allAccountsLoaded } = props
    const selectedAkten = selectedAccount.akten
    const theme = useTheme()
    return <AktenSidebarPopupWrapper>
        {
            allAktenLoaded && allAccountsLoaded ? <FlexGrid directionColumn>
                {
                    Object.entries(selectedAccount).length !== 0 && selectedAkten.length > 0 && selectedAkten.sort((a, b) => a.creationDate < b.creationDate ? 1 : -1).map((a, i) => {
                        return <AkteCard
                            setSelectedAkte={() => setSelectedAkte(a)}
                            key={i}
                            akteIdHash={a.idHash}
                            accountId={a.accountId}
                            accountName={a.accountName}
                            creatorFirstName={a.creatorFirstName}
                            creatorLastName={a.creatorLastName}
                            headline={a.header}
                            totalUnreadMessage={a.totalUnreadMessage}
                            lastMessageDate={a.lastMessageDate}
                            lastMessage={a.lastMessage}
                            mainColor={a.mainColor}
                            secondaryColor={a.secondaryColor}
                            totalUnreadMessageInFolder={a.totalUnreadMessageInFolder}
                            creatorIdHash={a.creatorIdHash}
                        />
                    })
                }
            </FlexGrid> : <FlexGrid justifyCenter itemsCenter>
                <PreloaderSpin />
            </FlexGrid>
        }
      
    </AktenSidebarPopupWrapper> 
}

export default AktenPopupSidebar
