import { textHelpers } from 'application/common'
import DateComponent from 'application/components/controls/date'
import Time from 'application/components/controls/time'
import ActionButton from 'application/components/controls/button/action_button/component'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { FlexGrid, FlexGridItem, PushBottom10 } from 'application/components/fragments/grid'
import React from 'react'
import * as Fragment from './fragments'
import { useTranslation } from 'react-i18next'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const UnreadMessageComponent = (props) => {
    const { accountId, readMessage, akteIdHash, readRequest, readRequestId, messageKey, folderId, serviceAkte, chatId } = props
    const { creatorName, creationDate, isOwnMessage, id, message, header } = props.messageDetail

    const dateOnMessage = creationDate
    const rootKey =  akteIdHash
    const showedMessage = message.length > 50 ? textHelpers.truncateEndText(message, 50) : ''
    const { activePlugin } = usePluginsContext()
    const {t} = useTranslation()
    return <React.Fragment>
        <Fragment.ChatBubbleWrapper id={chatId} serviceAkte={serviceAkte} isOwnMessage={isOwnMessage}>
            <Fragment.ChatBubbleInner>
                <FlexGrid justifySpaceBetween>
                    <Fragment.ChatBubbleCreator>
                        {creatorName}
                    </Fragment.ChatBubbleCreator>
                </FlexGrid>
                <PushBottom10 />
                <Fragment.UnreadWrapper>
                    <div style={{
                        fontWeight: 'bolder',
                        fontSize: '14px',
                        color: '#757575',
                        paddingTop: '10px',
                        
                    }}>{header}</div>
                    <Fragment.UnreadMessageDescription>
                        {showedMessage}
                    </Fragment.UnreadMessageDescription>
                    
                    <PushBottom10 />
                    <FlexGrid>
                        <FlexGridItem>
                            {
                                readRequest && readRequestId === id ? <PreloaderSpin /> :
                                    <ActionButton
                                        secondary={activePlugin ? false : true}
                                        onButtonClick={() => { readMessage(accountId, id, akteIdHash, messageKey, rootKey, folderId) }}
                                        strechted={false}
                                        buttonText={t('ReadMessage')}
                                        fontSize='12px'
                                        height='35px'
                                    />
                            }
                        </FlexGridItem>
                        <FlexGridItem width='50%' />
                    </FlexGrid>
                </Fragment.UnreadWrapper>
                <PushBottom10 />
                <FlexGrid justifyFlexEnd>
                    <Fragment.ChatBubleDate>
                        <DateComponent date={dateOnMessage} todayString={t('Today')} /> - <Time date={dateOnMessage} timeHourSuffix='' />
                    </Fragment.ChatBubleDate>
                </FlexGrid>
            </Fragment.ChatBubbleInner>
        </Fragment.ChatBubbleWrapper>
    </React.Fragment>
}

export default UnreadMessageComponent