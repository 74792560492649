import { createGlobalStyle } from 'styled-components'
import 'application/style/normalize.css'

export const GlobalStyle = createGlobalStyle`
    html, body, #root {
        width: 100vw;
        height: ${props => !props.inAkten && (props.iosHeightAdjust ||  props.mobileAndroid) ? '80vh' : '100vh'};
        overflow: hidden;
        background: ${p => p.internal ? p.theme.color.backgroundPrimary : p.theme.color.body};
        color: ${p => p.theme.color.text};
 		min-height: 100vh;
  		/* mobile viewport bug fix */
  		min-height: -webkit-fill-available;
    }
    #root {
        display: flex;
    }
    div {
        box-sizing: border-box;
    }
    * {
        font-family: ${p => p.theme.fontFamily.primary};
    }
    ::-webkit-scrollbar {
    width: 8px;
    }
    ::-webkit-scrollbar-track {
    background: transparent #cecece ;
    }
    ::-webkit-scrollbar-thumb {
    background-color:  #cecece ;
    border-radius: 10px;
    border: 2px solid #cecece;
    }
`