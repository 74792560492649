import React from 'react'
import styled from 'styled-components'

const StyledDatenschutz = styled.p`
    font-size: ${p => p.theme.fontSize.standard};
    text-align: left;
    padding: 0px 30px 0px 10px;
    white-space: pre-line;
    white-space: pre-line;
    line-height: 16px;
    word-break: break-word;
`

const DisplayDatenshutz = (props) => {
    const { registeredDatenshutz, dialog, accountId, isLoaded = false } = props
    const payload = dialog.payload
    const datenschutz = isLoaded ? payload.datenschutz : registeredDatenshutz.length > 0 ?
        registeredDatenshutz.filter(t => t.accountId === accountId && t.isLoaded).length > 0 &&
            registeredDatenshutz.find(t => t.accountId === accountId && t.isLoaded).datenschutz
        : null
    return (
        <StyledDatenschutz>
            {datenschutz}
        </StyledDatenschutz>
    )
}

export default DisplayDatenshutz
