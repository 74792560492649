import { call, put, takeEvery } from 'redux-saga/effects'
import { ERROR_TYPE_CODE } from 'application/constants/errors'
import { ACTIONS, NOTIFICATION } from 'application/constants'
import { api } from 'application/api'
import {
    loginRequestFailedPin,
    loginRequestSuccessPin,
    populateUserData,
    showLoginError,
    writeTokenUnregistered,
} from 'application/redux/actions/auth'
import { accessTokenUnregistered } from 'application/storage'
import { userMapperImport } from './mappers'
import { showToast } from 'application/redux/actions/notifications'
import { handleError } from '../../errors'

function* loginPinSaga(action) {
    try {
        const loginResponse = yield call(api.gateway.auth.postLoginPin, action.key, action.password)
        const userPayload = userMapperImport(loginResponse)

        yield accessTokenUnregistered.store(loginResponse.accessToken)
        yield put(writeTokenUnregistered(loginResponse.accessToken))
        yield put(populateUserData(userPayload))
        yield put(loginRequestSuccessPin())
    } catch (e) {
        let reasonMessage = e.response.status === ERROR_TYPE_CODE.UNAUTHORIZED ? 'FalsePin' : e.data.reasonMessage
        yield put(loginRequestFailedPin())
        // yield put(showToast(action.errorMessage, NOTIFICATION.LEVEL.ERROR))
        yield put(showToast(reasonMessage, NOTIFICATION.LEVEL.ERROR))
        yield put(showLoginError(action.errorMessage))
    }
}

function* loginWithoutPinSaga(action) {
    try {
        const loginResponse = yield call(api.gateway.auth.postLoginPin, action.key)
        const userPayload = userMapperImport(loginResponse)

        yield accessTokenUnregistered.store(loginResponse.accessToken)
        yield put(writeTokenUnregistered(loginResponse.accessToken))
        yield put(populateUserData(userPayload))
        yield put(loginRequestSuccessPin())
    }
    catch (e) {
        yield put(loginRequestFailedPin())
        yield handleError(e)
        yield put(showLoginError(action.errorMessage))

    }
}

export function* loginPinWatcher() {
    yield takeEvery(ACTIONS.AUTH_LOGIN_REQUEST_PIN, loginPinSaga)
    yield takeEvery(ACTIONS.AUTH_LOGIN_WITHOUT_PIN, loginWithoutPinSaga)
}