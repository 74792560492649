import { combineReducers } from 'redux'
import registered from './registered'
import unregistered from './unregistered'
import pin from './pin'
import forget from './forget'
import common from './common'

export default combineReducers({
    common,
    registered,
    unregistered,
    pin,
    forget,
})