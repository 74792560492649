import { NavLink } from 'react-router-dom'

const { default: styled } = require('styled-components')

export const WrapperFilesSidebar = styled.div`
    padding: ${props => props.noPadd ? '0px' : '20px'};
    width: 100%;
    overflow: ${props => !props.isoverflow ? 'hidden' : 'auto'};
    overflow-x: hidden;
    max-height: ${props => props.maxHeight === undefined ? '100%' : props.maxHeight};
    height: ${props => props.height};
`
export const WrapperFilesSidebarSidePadd = styled.div`
    padding: 0px 1px;
    width: 100%;
    overflow: ${props => !props.isoverflow ? 'hidden' : 'auto'};
    overflow-x: hidden;
    max-height: ${props => props.maxHeight === undefined ? '100%' : props.maxHeight};
    height: ${props => props.height};
`


export const ContainerTabs = styled.div`
    margin-top: 16px;
    height: 35px;
    max-height: 48px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    z-index: 1;
`

export const TabsWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-right: 24px;
`

export const TabsItem = styled.div`
    display: flex;
    padding: 0px 16px;
    height: 32px;
    cursor: pointer;
    color: ${props => props.isselected ? props.theme.color.primary : props.theme.color.color90};
    border-bottom: 2px solid ${props => props.isselected ? props.theme.color.primary : 'transparent'};
    align-items: center;

    &:hover {
        color: ${props => props.isselected ? props.theme.color.turqouise : props.theme.color.color100};
    }

    a {
        color: ${props => props.isselected ? props.theme.color.turqouise : props.theme.color.color90};
        text-decoration: none;

        &:hover {
            color: ${props => props.isselected ? props.theme.color.turqouise : props.theme.color.color100};
        }
    }
`

export const TabsItemLink = styled(NavLink)`
    display: flex;
    padding: 0px 16px;
    height: 32px;
    cursor: pointer;
    color: ${props => props.isselected ? props.theme.color.turqouise : props.theme.color.color90};
    border-bottom: 1px solid ${props => props.isselected ? props.theme.color.turqouise : 'transparent'};
    align-items: center;

    &:hover {
        color: ${props => props.isselected ? props.theme.color.turqouise : props.theme.color.color100};
    }
`


export const PlaceForRightSide = styled.div`
    display: flex;
    flex-flow: row nowrap;
`

export const WrapperForTheRightSide = styled.div`
    display: flex;
    height: ${props => props.smallFont ? '12px' : '32px'};
    align-items: center;
    flex-flow: row nowrap;
`