import ActionButton from 'application/components/controls/button/action_button/component'
import { ROUTES } from 'application/constants'
import React from 'react'
import { ForgotCredentialsLayout } from '../_common'
import { useTranslation } from 'react-i18next'
import { webViewerChecker } from 'application/common'
import { isMobileOnly } from 'react-device-detect'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const ResetPasswordDone = () => {
    const { isIosWebView, isAndroidWebView } = webViewerChecker()
    const {t} = useTranslation()
    let isRenderFromWebviewMobile = false
    const { activePlugin } = usePluginsContext()
    if (isIosWebView || isAndroidWebView || isMobileOnly) isRenderFromWebviewMobile = true
    return <React.Fragment>
        <ForgotCredentialsLayout
            needDesc={true}
            headline={t(isRenderFromWebviewMobile ? 'PasswordResetSuccessIsRenderFromWebviewMobile' : 'PasswordResetSuccess')}
        >
            <React.Fragment>
                {!isRenderFromWebviewMobile && (
                    <ActionButton
                        secondary={activePlugin ? false : true}
                        buttonText={t('ReturnLoginPage')}
                        linkTo={ROUTES.LOGIN}
                    />
                )}
            </React.Fragment>   
        </ForgotCredentialsLayout>
    </React.Fragment>
}

export default ResetPasswordDone