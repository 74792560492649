import ACTIONS from 'application/constants'

export const setSelectedAccountDetails = (payload) => ({
    type: ACTIONS.COMMON_SET_SELECTED_ACCOUNTS_DETAILS,
    payload
})

export const updateSelectedAccountDetails = (payload) => ({
    type: ACTIONS.COMMON_UPDATE_SELECTED_ACCOUNTS_DETAILS,
    payload
})

export const getAccountPublicInfo = (accountIdentify, getLookUpFirst = true) => ({
    type: ACTIONS.COMMON_GET_ACCOUNT_PUBLIC_INFO,
    accountIdentify,
    getLookUpFirst,
})

export const setAccountPublicInfo = (payload) => ({
    type: ACTIONS.COMMON_SET_ACCOUNT_PUBLIC_INFO,
    payload
})

export const refreshAccountPublicInfo = (payload) => ({
    type: ACTIONS.COMMON_REFRESH_ACCOUNT_PUBLIC_INFO,
})

export const getAccountPublicInfoFailed = () => ({
    type: ACTIONS.COMMON_GET_ACCOUNT_PUBLIC_INFO_FAILED,
})

export const closeSelectedAccountDetails = () => ({
    type: ACTIONS.COMMON_CLOSE_ACCOUNTS_DETAILS
})

export const activateAccountRequest = (accountId, payload) => ({
    type: ACTIONS.COMMON_ACTIVATE_ACCOUNT_REQUEST,
    accountId,
    payload,
})

export const activateAccountSucceed = (accountId) => ({
    type: ACTIONS.COMMON_ACTIVATE_ACCOUNT_SUCCEED,
    accountId,
})

export const activateAccountFailed = (accountId) => ({
    type: ACTIONS.COMMON_ACTIVATE_ACCOUNT_FAILED,
    accountId,
})