import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'

import SendPinOptionDialog from './component'

const mapStateToProps = (state, props) => ({
    dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name),
})

const mapDispatchToProps = dispatch => {
    return {
        reqPin: (dialogName, hashAuthKey, methode, errorMessage, successMessage) => {
            dispatch(sendPayloadRequest(dialogName, { hashAuthKey, methode }, { errorMessage, successMessage }))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SendPinOptionDialog)