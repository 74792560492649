import { fetchHandler, fetchRequestBuilder, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'
import { accessTokenForm } from 'application/storage/access_token'

export const getFormElementsAssistant = () => {

    const request = fetchRequestBuilder('assistants/process', HTTP_METHOD.GET, {}, true, false, false, accessTokenForm)

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}