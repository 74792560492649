import React from 'react'
import { Fragment } from 'react'
import LoginPinMobileAssistant from '../login_pin'

const RedirectGetFormWithPin = (props) => {
    const { withPin, children, authenticated } = props
    if (withPin && !authenticated) {
        return <LoginPinMobileAssistant {...props} />
    }
    return <Fragment>
        {children}
    </Fragment>
}

export default RedirectGetFormWithPin
