import React from 'react'
import * as Fragments from 'application/components/pages/external/auth/fragments'
import styled from 'styled-components'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import {TitlePin} from 'application/components/pages/external/_common'
import EnterPinForm from '../../auth/enter_pin/sections/enter_pin_form'
import RequestPinAgainIfUserDidntGetThePin from '../../auth/enter_pin/sections/request_pin'
import { useTranslation } from 'react-i18next'
import { PluginAlternateView } from 'plugins'

const DivTop = styled.div`
    align-items: center;
`

const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
`
const Image = styled.img`
    width: 100%;
    height: auto;
`

const DivMiddle = styled.div`
    text-align: center;
    position: relative;
    height: 20rem;
    width: 20rem;
    margin: 0 auto;
`

const DivBottom = styled.div``

const EnterPinMobile = props => {
	const {t} = useTranslation()
    const accountId = props.akteMeta.accountId
    return <Fragments.LayoutInputMobile>
        <DivTop>
            <LanguageSwitchHoc withLabel={false} />
            <TitlePin title={t('Enter PIN')} subTitle={t('RequestPINSubTitle')} />
            <FormWrapper>
                <EnterPinForm {...props} />
            </FormWrapper>
            <RequestPinAgainIfUserDidntGetThePin {...props} />
        </DivTop>
        <DivMiddle>
            <PluginAlternateView>
                <Image src='/assets/images/give_your_code.png' />
            </PluginAlternateView>
        </DivMiddle>
        <DivBottom>
        </DivBottom>
    </Fragments.LayoutInputMobile>
}

export default EnterPinMobile
