import PreloaderSpin from 'application/components/controls/preloader/spin'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, { useContext, useEffect } from 'react'
import InternalLayout from '../../_layout'
import { getCountIndicator, getUnreadMessage } from '../_common/function'
import CountSection from '../_common/count_section'
import HeadlineSection from '../_common/headline_section'
import ProviderSection from '../_common/provider_section'
import * as Fragment from './fragments'
import { getDashboardDate } from './function'
import { useTranslation } from 'react-i18next'
import RedirectDashboard from './redirect_dashboard'
import CloseSelectedAccountEffect from './close_selected_account_effect'
import RedirectMobile from 'application/common/redirect_mobile'
import { MobileInternalContent } from '../mobile'
import WebHeaderLogoEffect from 'application/common/web_header_logo'
import { useTheme } from 'styled-components'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import { isMobileOnly } from 'react-device-detect'


const DashboardComponent = (props) => {
    const { t } = useTranslation()
    const {
        allAccountsLoaded,
        allAktenLoaded,
        allAkten,
        allAccounts,
        setSelectedAccountDetails,
        selectedAccount,
        accountDetails,
        handleOpenProviderPopupSidebar,
        auth,
    } = props
    const theme = useTheme()
    const unreadMessages = getUnreadMessage(allAkten)
	const newTitle = unreadMessages > 0 ? `(${unreadMessages}) new messages - ${theme.companyName}` : theme.companyName
    const accountPublicInfo = allAccounts[0]
    const accountId = auth?.common?.user?.accountId
    
    const { config, setUserAccountId } = usePluginsContext()
    const navigate = useNavigate()

    useEffect(() => {
        if(!accountId) return

        setUserAccountId(accountId)

        if(config.disableDashboard && !isMobileOnly){
            navigate(ROUTES[config.disableDashboard.redirectTo])
        }
    },[config, accountId])

    return (
        <WebHeaderLogoEffect accountPublicInfo={accountPublicInfo}>
            <RedirectDashboard {...props}>
                <CloseSelectedAccountEffect {...props}>
                    <InternalLayout withDiv100={false} newMessageTitle={newTitle}>
                        <RedirectMobile MobileComponent={MobileInternalContent.Dashboard} {...props}>
                            <FlexGrid height='100%' noWrap directionColumn>
                                <HeadlineSection headline={'Dashboard'} date={getDashboardDate()} />
                                {allAktenLoaded ? (
                                    <CountSection countIndicator={getCountIndicator(allAkten, allAccounts)} withIcon={false} />
                                ) : (
                                    <FlexGridItem grow={0}>
                                        {allAccountsLoaded ? (
                                            <PreloaderSpin
                                                primaryColor={
                                                    theme.color.primary
                                                }
                                            />
                                        ) : null}
                                    </FlexGridItem>
                                )}
                                <FlexGridItem grow={0.06}>
                                    <Fragment.DashboardTitle>{t('Service Provider')}</Fragment.DashboardTitle>
                                </FlexGridItem>
                                {allAktenLoaded ? (
                                    <ProviderSection
                                        allAkten={allAkten}
                                        allAccounts={allAccounts}
                                        setSelectedAccountDetails={setSelectedAccountDetails}
                                        selectedAccount={selectedAccount}
                                        accountDetails={accountDetails}
                                        handleOpenProviderPopupSidebar={handleOpenProviderPopupSidebar}
                                    />
                                ) : (
                                    <FlexGridItem grow={0}>
                                        {allAccountsLoaded ? (
                                            <PreloaderSpin
                                                primaryColor={
                                                    theme.color.primary
                                                }
                                            />
                                        ) : null}
                                    </FlexGridItem>
                                )}
                            </FlexGrid>
                        </RedirectMobile>
                    </InternalLayout>
                </CloseSelectedAccountEffect>
            </RedirectDashboard>
        </WebHeaderLogoEffect>
    )
}

export default DashboardComponent
