import IconLink from 'application/components/controls/icon/icon_link'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { withTheme } from 'styled-components'
import * as Fragment from './fragments'
import { ThumbnailContainer } from 'application/components/building_blocks/attachments/thumbnail'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const DisplayFilesAkteDetailsComponent = ({ thumbnails = [], onOpenGallery, onClick, theme, akteIdHash, parentId }) => {
    let params = useParams()
    const {t} = useTranslation()

    return <React.Fragment>
        <FlexGrid directionColumn>
            <FlexGridItem>
                <FlexGrid justifySpaceBetween itemsCenter>
                    <FlexGridItem itemsFlexEnd>
                        <Fragment.TitleRowAkteDetails>
                            {t('Files')}
                        </Fragment.TitleRowAkteDetails>
                        {
                            thumbnails.length > 3 &&
                            <Fragment.SubTitleRowAkteDetails onClick={onClick}>
                                total {thumbnails.length}
                            </Fragment.SubTitleRowAkteDetails>
                        }
                    </FlexGridItem>
                    {
                        thumbnails.length > 3 && <IconLink title={t('Display your files')} iconKey='arrow_forward' onClick={onClick} color={theme.color.primary} size={theme.fontSize.medium} />
                    }
                </FlexGrid>
            </FlexGridItem>
            <FlexGrid>
                {
                    thumbnails.sort((a, b) => b.creationDate - a.creationDate).slice(0, 3).map((s, i) => {
                        return <ThumbnailContainer
                            key={i}
                            fileId={s.id}
                            fileName={s.fileName}
                            akteIdHash={akteIdHash}
                            parentId={parentId}
                            onAttachmentClick={() => onOpenGallery(s.id, s.fileName, params.accountId, parentId, akteIdHash, thumbnails)}
                            marginRight={false}
                            isSizeFixed={true}
                            divHeight='60px'
                            divWidth='60px'
                            showCaption={true}
                            messageId={s.messageId}
                            accountId={params.accountId}
                        />
                    })
                }
            </FlexGrid>
        </FlexGrid>
    </React.Fragment>
}

const DisplayFilesAkteDetails = withTheme(DisplayFilesAkteDetailsComponent)
export default DisplayFilesAkteDetails