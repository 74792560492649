import React, { Fragment } from 'react'
import { Form, TextInputPassword } from 'application/components/controls/form_elements'
import { isTablet } from 'react-device-detect'
import { FlexGrid, FlexGridItem, PushBottom10 } from 'application/components/fragments/grid'
import { useTranslation } from 'react-i18next'
import { ErrorLabel } from 'application/components/fragments/error_information'

const FormPasswordRegistration = ({ handleSubmit, onSubmitSuccess, register, validation, errors, registeredRequest, handleChangeNewPass, newPass, handleFocusPass, handleBlurPass, password, registeredFailed, errorMessage, useMobile = false }) => {
    const {t} = useTranslation()
    return <Fragment>
        <Form onSubmit={handleSubmit(onSubmitSuccess)}>
            <FlexGrid directionColumn={isTablet}>
                <FlexGridItem width={useMobile ? '100%' : isTablet ? '80%' : '40%'} needSpaceRight='8px'>
                    <TextInputPassword
                        label={t('password')}
                        name='password'
                        register={register}
                        validate={validation}
                        error={errors.password}
                        disabled={registeredRequest}
                        onChange={handleChangeNewPass}
                        password={newPass}
                        onInputFocus={handleFocusPass}
                        onInputBlur={handleBlurPass}
                    />
                </FlexGridItem>
                <FlexGridItem width={useMobile ? '100%' : isTablet ? '80%' : '40%'}>
                    <TextInputPassword
                        label={t('Password confirmation')}
                        name='passwordConfirmation'
                        register={register}
                        validate={(value) => value === password.current || t('PasswordInputMatchingError')}
                        error={errors.passwordConfirmation}
                        disabled={registeredRequest}
                    />
                </FlexGridItem>
            </FlexGrid>
            <PushBottom10 />
            <input type='submit' value='passwordOnReg' style={{ display: 'none' }} />
        </Form>
        {
            registeredFailed && <ErrorLabel>
                {t(errorMessage)}
            </ErrorLabel>
        }
    </Fragment>
}

export default FormPasswordRegistration
