import { ROUTES } from 'application/constants'
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const PushToDashboardEffect = (props) => {
    const { auth, children } = props
    let navigate = useNavigate()
    const pushToDashboard = auth.common.pushToDashboard
    
    useEffect(() => {
        pushToDashboard && navigate(ROUTES.DASHBOARD)
    }, [navigate, pushToDashboard])

    return <Fragment>
        {children}
    </Fragment>
}

export default PushToDashboardEffect
