import React from 'react'
import styled, { withTheme } from 'styled-components'

const StyledHeadlineBar = styled.div`
    height: 48px;
    display: flex;
    flex-direction: row;
    z-index: 2;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	font-size: ${p => p.theme.fontSize.headline2};
	font-weight: ${p => p.theme.fontWeight.bold};
	overflow: auto;
	height: auto;
`


const HeadlineBar = ({headline, children})=> {
    return <StyledHeadlineBar>
        {headline}
        {children}
    </StyledHeadlineBar>
}

HeadlineBar.propTypes = {
   
}

export default withTheme(HeadlineBar)