import React, { Fragment, useEffect } from 'react'

const LoginFormEffects = (props) => {
    const { auth, handleLoggedIn, children } = props

    useEffect(() => {
        auth.registered.loggedIn && handleLoggedIn()
    }, [auth.registered.loggedIn, handleLoggedIn])
    
    return <Fragment>
        {children}
    </Fragment>
}

export default LoginFormEffects
