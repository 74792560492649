import { filterDefinitions } from './filter_definitions'

const mapRoleToFilter = (role) => {
    var roles = {
        'consultant': () => (filterDefinitions.consultant),
        'default': () => (filterDefinitions.basic)
    }
    return (roles[role] || roles['default'])()
}

export const getFilters = (action = { role: 'basic' }) => {
    return mapRoleToFilter(action.role)
}