import { FlexGrid, FlexGridItem, PushBottom10 } from 'application/components/fragments/grid'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import { NavLink } from 'react-router-dom'
import Tree from 'application/components/building_blocks/tree'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { StyledRedDot } from 'application/components/fragments/red_dot'
import { useTranslation } from 'react-i18next'
import TagCategory from '../../tag_category'
import { textHelpers } from 'application/common'
import { isTablet } from 'react-device-detect'
import DateComponent from '../../date'
import FontIcon from '../../font_icon'
import PropTypes from 'prop-types'


const MessagePreviewWrapper = styled(NavLink)`
    padding: 20px;
    text-decoration: none;
    border-bottom: 1px solid ${props => props.isselected ? props.theme.color.backgroundPrimary : props.theme.color.color15};
    max-width: 100%;
    background-color: ${props => props.isselected ? props.theme.color.backgroundPrimary : props.theme.color.color0};
`

const MessageAccountName = styled.span`
    max-width: 100%;
    font-size: ${props => props.theme.fontSize.medium};
    font-family: ${props => props.theme.fontFamily.secondary};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.color.color90};
    margin-bottom: 4px;
`

const MessageHeader = styled.span`
    max-width: 100%;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.color.color90};
    margin-bottom: 4px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
`

const MessageCreator = styled.span`
    max-width: 100%;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.regular};
    color: ${props => props.theme.color.color90};
    margin-bottom: 4px;
`

const MessagePreviewLastMessage = styled.span`
    max-width: 100%;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.regular};
    color: ${props => props.theme.color.color40};
    margin-bottom: 4px;
`

const MessageDate = styled.span`
display: flex;
flex-direction: column;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.color90};
`


const MessagePreviewComponent = ({
    isselected = false,
    lastMessageDate,
    lastMessage,
    folderCount,
    creatorFirstName,
    creatorLastName,
    accountName,
    header,
    folders,
    linkTo,
    akteIdHash,
    id,
    theme,
    foldersView,
    openMessageExtendedData,
    onSelectFolder,
    folderId,
    totalUnreadMessage,
    totalUnreadMessageInFolder,
    onClick = () => {},
}) => {
    const noClick = () => {}
    const fullName = creatorFirstName + ' ' + creatorLastName
    const { t } = useTranslation()
    const hideFolders = t('HideFolder')
    const newMessageInFolder = t('NewMessageInFolders')
    const exploreFolders = t('ExploreFolders')
    const latestMessage = lastMessage === null ? '' : lastMessage

    const textTag = () => {
        if (foldersView && isselected && totalUnreadMessageInFolder === 0) {
            return hideFolders
        }
        if (isselected && totalUnreadMessageInFolder > 0) {
            return `${totalUnreadMessageInFolder} ${newMessageInFolder}`
        }
        if (!foldersView && isselected && totalUnreadMessageInFolder === 0) {
            return exploreFolders
        }
    }
    const truncateAccountName = textHelpers.truncateEndText(accountName, isTablet ? 15 : 25)
    const truncateFullName = textHelpers.truncateEndText(fullName, isTablet ? 20 : 30)
    const truncateHeader = textHelpers.truncateEndText(header, 25)
    const truncateLatestMessage = textHelpers.truncateEndText(latestMessage, isTablet ? 20 : 30)
    return <React.Fragment>
        <MessagePreviewWrapper id={id} onClick={onClick} to={linkTo} isselected={isselected} >
            <FlexGrid>
                <FlexGridItem width='60%'>
                    <FlexGrid directionColumn>
                        <MessageAccountName>
                            {truncateAccountName}
                        </MessageAccountName>
                        <MessageCreator>
                            {truncateFullName}
                        </MessageCreator>
                        <MessageHeader>
                            {header}
                        </MessageHeader>
                        <MessagePreviewLastMessage>
                            {truncateLatestMessage}
                        </MessagePreviewLastMessage>
                        {
                            folderCount > 0 &&
                            <FlexGridItem>
                                <TagCategory
                                    padding='6px'
                                    borderradius='20px'
                                    backgroundcolor={totalUnreadMessageInFolder > 0 ? theme.color.salmonDark : theme.color.color70}
                                    text={textTag()}
                                    iconKey='folder_open'
                                    onTagClick={isselected ? openMessageExtendedData : noClick}
                                />
                            </FlexGridItem>
                        }
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem itemsStretch grow={0}>
                    <FlexGrid justifySpaceBetween directionColumn>
                        <MessageDate>
                            <DateComponent date={lastMessageDate} todayString={t('Today')} />
                            {/* <Time date={lastMessageDate} /> */}
                        </MessageDate>
                        <FlexGridItem itemsFlexEnd justifyFlexEnd>
                            {
                                totalUnreadMessage !== 0 && <React.Fragment>
                                    <StyledRedDot />
                                </React.Fragment>
                            }
                        </FlexGridItem>
                        <FlexGridItem />
                    </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
            {
                isselected && foldersView && folderCount > 0 && <React.Fragment>
                    <PushBottom10 />
                    <FlexDividerHorizontal />
                    <Tree initiallyToggle={totalUnreadMessageInFolder > 0} folderId={folderId} onSelect={onSelectFolder} iconSet={<FontIcon icon='folder_open' fontSize={theme.fontSize.headline2} color={theme.color.color40} />} rootId={akteIdHash} nodes={folders} />
                </React.Fragment>
            }
        </MessagePreviewWrapper>
    </React.Fragment>
}

MessagePreviewComponent.propTypes = {
    isselected: PropTypes.bool,
    lastMessageDate: PropTypes.string,
    lastMessage: PropTypes.string,
    folderCount: PropTypes.number,
    creatorFirstName: PropTypes.string,
    creatorLastName: PropTypes.string,
    accountName: PropTypes.string,
    header: PropTypes.string,
    folders: PropTypes.array,
    linkTo: PropTypes.string,
    akteIdHash: PropTypes.string,
    id: PropTypes.string || PropTypes.number,
    theme: PropTypes.object,
    foldersView: PropTypes.bool,
    openMessageExtendedData: PropTypes.func,
    onSelectFolder: PropTypes.func,
    folderId: PropTypes.string,
    totalUnreadMessage: PropTypes.number,
    totalUnreadMessageInFolder: PropTypes.number,
    onClick: PropTypes.func,
}

export default withTheme(MessagePreviewComponent)