import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import * as Fragments from '../fragments'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'

const ContentLoginAccountSection = (props) => {
    
    return (
        <FlexGrid directionColumn noWrap>
            <FlexGrid>
                <FlexGridItem>
                    <FlexGrid directionColumn>
                        <center>
                            <Fragments.AsclarSpacer padding={'20px'}/>
                            <FlexGridItem justifyCenter>
                                <ImagePlaceHolder linkHref={process.env.REACT_APP_INSTALL_APP_IOS} withBorder={false} source={'/assets/images/app_ios.png'} large={'120px'} />
                                <ImagePlaceHolder linkHref={process.env.REACT_APP_INSTALL_APP_ANDROID} withBorder={false} source={'/assets/images/app_android.png'} large={'120px'} />
                            </FlexGridItem>
                        </center>
                    </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
            <Fragments.AsclarSpacer padding={'20px'}/>
        </FlexGrid>
    )
}

export default ContentLoginAccountSection
