import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { isTablet } from 'react-device-detect'

const RefreshFolderMessagesEffects = (props) => {
    const { selectedFolder, folderId, refreshRegisteredMessage, messageKey, metaMessage, loggedIn } = props
    const lastSync = metaMessage && dayjs(metaMessage.lastSync)
    const lastMessageDate = selectedFolder && dayjs(selectedFolder.lastMessageDate)
    const newMessageFoundedInFolder = metaMessage && selectedFolder && lastMessageDate.diff(lastSync) > 0
    const shouldRefresh = selectedFolder && metaMessage && newMessageFoundedInFolder && !isTablet && loggedIn
    useEffect(() => {
        shouldRefresh && folderId !== '' && refreshRegisteredMessage(messageKey)
    }, [folderId, messageKey, refreshRegisteredMessage, shouldRefresh])

    return <React.Fragment>
        {props.children}
    </React.Fragment>
}

export default RefreshFolderMessagesEffects