import { ROUTES } from 'application/constants'
import React from 'react'
import { Navigate } from 'react-router-dom'

const FinalWizardRedirect = (props) => {
    const { auth } = props

    if (auth.unregistered.loggedIn &&
        auth.registered.loggedIn) return <Navigate to={ROUTES.DASHBOARD} />

    return <React.Fragment>
        {props.children}
    </React.Fragment>
}

export default FinalWizardRedirect