import styled from 'styled-components'

export const BackgroundFormAssistant = styled.div`
    background-color: ${p => p.backgroundColor};
    width: 100%;
    overflow: auto;
	height: 100vh;
`

export const WaitingScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.5);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 40px;
    align-items: center;
    z-index: ${props => '10' + props.dialogIndex.toString() + '2'};
    overflow: auto;
`

export const AbortConfirmHeader = styled.div`
    font-size: ${props => props.theme.fontSize.headline1};
    color: ${props => props.theme.color.primary};
    text-transform: uppercase;
    font-weight: ${props => props.theme.fontWeight.standardBold};
    margin-bottom: 24px;
`

export const StyledImageAccount = styled.img`
  height: auto;
  width: 50%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const AssistantNoticeBannerContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 20px;
    border: 2px dashed ${p => p.borderColor ? p.borderColor : p.theme.color.color15};
    background-color: ${p => p.backgroundColor ? p.backgroundColor : p.theme.color.color5};
    margin: 18px;
    @media (min-width: 1280px) {
    margin: 15px;
  }
    @media (min-width: 1536px) {
    margin: 18px;
  }
`

export const AssistantNoticeBannerText = styled.p`
    color: ${p => p.theme.color.color70};
    font-weight: ${p => p.theme.fontWeight.medium};
    font-size: ${p => p.theme.fontSize.standard};
    margin: 0px;
    text-align: ${p => p.textAlign};
	white-space: pre-line;
    word-break: break-word;
`

export const AssistantNoticeWrapper = styled.div`
    padding: 0px ${p => p.isMobile ? '10px' : '0px'};
    width: ${p => p.isMobile ? '100%' : '74%'};
    display: flex;
    justify-content: space-between;
`

export const FormFooterContainer = styled.div`
    width: 100%;
    flex: 1 1 20vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px;
    background: ${p => p.mainColor};
`

export const LinkFooterForm = styled.div`
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: center;
`

export const FooterWelcomeNavlink = styled.a`
    color: ${p => p.textColor};
    font-size: ${props => props.theme.fontSize.small};
    text-decoration: none;
    font-weight: ${p => p.theme.fontWeight.bold};
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
    color: ${p => p.textColor};
    }
    
`

export const StyledTextWarning = styled.p`
    font-size: ${p => p.theme.fontSize.small};
    font-weight: ${p => p.theme.fontWeight.regular};
    color: ${p => p.theme.color.color70};
    text-align: center;
    width: 100%;
    margin-top: 0px;
`

export const StyledLinkDatenschutz = styled.span`
    font-size: ${p => p.theme.fontSize.small};
    font-weight: ${p => p.theme.fontWeight.regular};
    color: ${p => p.theme.color.color70};
    text-decoration: underline;
    cursor: pointer;
`

export const StyledBackgroundHeader = styled.div`
    background: ${props => `url(${props.backgroundImageUrl})`};
    height: 200px;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 768.1px) {
        height: 250px;
        width: 90%;
    }
        @media (min-width: 1024px) {
        height: 250px;
        width: 90%;
    }
        @media (min-width: 1280px) {
        height: 275px;
    }
    @media (min-width: 1367px) {
        height: 275px;
        width: 92%;
    }
    @media (min-width: 1440px) {
        height: 275px;
        width: 76%;
    }
        @media (min-width: 1536px) {
        height: 275px;
    }
    `

// LOGIN STYLE

export const BackgroundLoginFormAssistant = styled.div`
    background-color: ${p => p.theme.color.backgroundPrimary};
    padding: ${p => p.isMobile ? '20px' : '40px'};
    padding-bottom: 10px;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    align-items: center;
`

export const StyledButtonInput = styled.div`
    input {
        box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: row;
    flex-shrink: 0;
    border-style: solid;
    border-width: 1px;
    position: relative;
    z-index: 0;
    min-height: 0px;
    min-width: 80px;
    border-color: ${props => props.theme.color.color100};
    border-radius: 0px;
    height: 60px;
    justify-content: center;
    padding: 19px 24px;
    transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s, color 0.2s ease 0s;
    cursor: pointer;
    background-color: ${props => props.theme.color.color100};
    max-width: 100%;
    color: ${props => props.theme.color.color0};
    font-weight: ${props => props.theme.fontWeight.medium};
    font-size: ${props => props.theme.fontSize.medium18};
    line-height: 20px;
    visibility: visible;
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricprecision;
    text-size-adjust: none;

    &::hover {
        background-color: ${props => props.theme.color.color90};
    }
    }
`

export const TitleLogin = styled.h1`
    max-width: 100%;
    color: ${props => props.theme.color.black};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -0.2px;
    text-align: left;
    text-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    padding-top: 3px;
    padding-bottom: 2px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-size-adjust: none;
`
export const PrivacyExplanation = styled.p`
    max-width: 100%;
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.light};
    line-height: 20px;
    text-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    word-break: break-word;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 4px;
    padding-top: 1px;
    text-size-adjust: none;
`

export const PrivacyLink = styled.a`
    border-radius: 2px;
    color: ${props => props.theme.color.color90};
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.light};
    outline: 0px;
    text-decoration: underline ${props => props.theme.color.color90};
    text-rendering: geometricprecision;
    transition: color 0.2s ease 0s;
    -webkit-font-smoothing: antialiased;
`

export const LoginFormContainer = styled.div`
    width: 100%;
    height: auto;
    padding: ${p => p.isMobile || p.isTablet ? '0px' : '0px 100px'};
    display: flex;
    flex-direction: column;
    justify-content: ${p => p.isMobile ? 'center' : 'flex-start'};
    align-items: ${p => p.isMobile ? 'center' : 'flex-start'};
`

export const FooterWelcomeCompanyName = styled.span`
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.small};
    text-align: left;
`