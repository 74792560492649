import { updateDataSelectedMessageRoot } from 'application/redux/actions/messages'
import { setSelectedAkte } from 'application/redux/actions/pages/akten'
import { connect } from 'react-redux'
import DashboardMobile from './component'
const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    setSelectedAkte: (payload, params, akteIdHash, accountId) => {
        dispatch(setSelectedAkte(payload))
        dispatch(updateDataSelectedMessageRoot(params, akteIdHash, accountId, payload))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMobile)
