import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import * as Fragments from './fragments'
import {
  Headline2,
  Headline3,
  Paragraph,
} from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import Accordion from 'application/components/building_blocks/accordion'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { StyledRedDot } from 'application/components/fragments/red_dot'
import { useTheme } from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import useOutsideAlerter from 'application/components/hooks/use_outside_alerter'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { PhasesLoader } from '../loader/phases_loader'
import {
  getNewestPhaseState,
} from './helpers'
import GravatarWithInitial from 'application/components/controls/gravatar'
import { textHelpers } from 'application/common'
import Preloader3D from 'application/components/controls/preloader/3d'

const Overview = (props) => {
  const { setMenus, selectedAkte, getMainLogo, mainLogo, aufgaben, aufgabenIsLoading } = props
  const [assignePopupActive, setAssignePopupActive] = useState(false)
  const [phases, setPhases] = useState([])
  const [phasesIsLoading, setPhasesIsLoading] = useState(false)
  const { t } = useTranslation()
  const { akteIdHash, accountId } = useParams()
  const assigneList = selectedAkte.assignees
  const assignePopupRef = useRef(null)
  const currentLogo = mainLogo.find((logo) => logo.accountId === accountId)
  const createdAt = moment(
    selectedAkte.creationDate,
    'YYYY-MM-DD, hh:mm:ss'
  ).format('DD.MM.YYYY, hh:mm')
  useOutsideAlerter(assignePopupRef, () => setAssignePopupActive(false))
  useEffect(() => {
    if (mainLogo.findIndex((logo) => logo.accountId === accountId) === -1)
      getMainLogo(accountId)
  }, [])

  const theme = useTheme()


  return (
      <>
        <PhasesLoader {...props} setPhases={setPhases} setPhasesIsLoading={setPhasesIsLoading}>
          {phases.length === 0 || phasesIsLoading === true || aufgabenIsLoading === true ? 
            <FlexGrid style={{ height: '50%' }} justifyCenter directionColumn itemsCenter>
              <Preloader3D/>
            </FlexGrid> : 
            <Fragments.OverviewWrapper>
              <Fragments.AccountInfoWrapper isMobile={isMobile}>
                <FlexGridItem>
                  <FlexGrid directionColumn style={{ gap: '10px' }}>
                    <Headline2>Account</Headline2>
                    <FlexGrid style={{ gap: '15px' }} itemsCenter>
                      <ImagePlaceHolder
                        source={
                          currentLogo && currentLogo.has === true
                            ? currentLogo.data
                            : '/assets/images/default/default_placeholder_company.png'
                        }
                        height={50}
                        large={'auto'}
                        withBorder={false}
                      />
                      <span style={{ fontSize: '14px' }}>
                        {selectedAkte.accountName}
                      </span>
                    </FlexGrid>
                  </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                  <FlexGrid directionColumn style={{ gap: '10px' }}>
                    <Headline2>{t('assignePerson')}</Headline2>
                    <div style={{ position: 'relative' }}>
                      <Fragments.AvatarAssigneListWrapper
                        onClick={() => setAssignePopupActive((prev) => !prev)}
                      >
                        {assigneList &&
                          assigneList.map((assigne, index) => {
                            if (index == 3)
                              return (
                                <Fragments.AssigneMore
                                  translateX={`-${index * 8}px`}
                                >
                                  {assigneList.length - 3}+
                                </Fragments.AssigneMore>
                              )
                            if (index > 3) return <></>
                            return assigne.profilePictureUrl ? (
                              <Fragments.AssigneAvatar
                                src={assigne.profilePictureUrl}
                                translateX={`-${index * 8}px`}
                              />
                            ) : (
                              <div
                                style={{ transform: `translateX(-${index * 8}px)` }}
                              >
                                <GravatarWithInitial
                                  initial={textHelpers.getInitials(
                                    assigne.firstName,
                                    assigne.lastName
                                  )}
                                  backgroundcolor={
                                    selectedAkte.mainColor !== ''
                                      ? theme.color.gravatar ||
                                        selectedAkte.mainColor
                                      : theme.color.mainAkte
                                  }
                                  large={48}
                                  borderWidth='2px'
                                />
                              </div>
                            )
                          })}
                      </Fragments.AvatarAssigneListWrapper>
                      {assignePopupActive === true && (
                        <Fragments.AssignePopup ref={assignePopupRef}>
                          {assigneList &&
                            assigneList.map((assigne) => (
                              <Fragments.AssignePopupItem>
                                {assigne.profilePictureUrl ? (
                                  <Fragments.AssigneAvatar
                                    src={assigne.profilePictureUrl}
                                  />
                                ) : (
                                  <GravatarWithInitial
                                    initial={textHelpers.getInitials(
                                      assigne.firstName,
                                      assigne.lastName
                                    )}
                                    backgroundcolor={
                                      selectedAkte.mainColor !== ''
                                        ? theme.color.gravatar ||
                                          selectedAkte.mainColor
                                        : theme.color.mainAkte
                                    }
                                    large={48}
                                    borderWidth='2px'
                                  />
                                )}

                                <Paragraph>
                                  {assigne.lastName}{' '}
                                  {assigne.firstName && `, ${assigne.firstName}`}
                                </Paragraph>
                              </Fragments.AssignePopupItem>
                            ))}
                        </Fragments.AssignePopup>
                      )}
                    </div>
                  </FlexGrid>
                </FlexGridItem>
              </Fragments.AccountInfoWrapper>

              {aufgaben &&
                aufgaben.findIndex(
                  (aufgabe) =>
                    aufgabe.isClosed === false && aufgabe.hasAnswer === false
                ) !== -1 && (
                  <Fragments.WarningAufgabeContainer>
                    <Headline3 style={{ fontWeight: 'bold' }}>
                      {t('Your assistance is needed')}
                    </Headline3>
                    <Paragraph style={{ color: 'white', textAlign: 'justify' }}>
                      {t('In order to follow up your case')}
                    </Paragraph>
                    <Fragments.WarningAufgabeButton onClick={() => setMenus('task')}>
                      {t('To your tasks')}
                    </Fragments.WarningAufgabeButton>
                  </Fragments.WarningAufgabeContainer>
                )}
              <Fragments.StatusWrapper>
                <Accordion
                  title={t('Case status')}
                  contentMarginBottom='0px'
                  withBorderBottom={false}
                  titleDescription={
                    phases && (
                      <Fragments.NewestStatus state={getNewestPhaseState(phases)} />
                    )
                  }
                  contentMarginTop='0px'
                >
                  <Fragments.AllStatusWrapper>
                    {phases && phases.stateGroups.map((status, index) => 
                      <Fragments.StatusItemWrapper>
                        <Fragments.TimelineWrapper>
                            {(index == 2 || index == 3) && status.states.length == 0 &&
                                <Fragments.Timeline style={{
                                  position: 'absolute',
                                  height: '20px',
                                  left: '50%',
                                  top: '-18.5px',
                                  transform: 'translateX(-50%)'
                                }}/>
                            }
                            {status.states.length == 0 &&  
                              <Fragments.DotWrapper>
                                  <StyledRedDot style={{ backgroundColor: 'gray' }}/> 
                              </Fragments.DotWrapper>
                            }
                            { status.states.length != 0 && 
                              (status.states[status.states.length-1].isInProgress === false || 
                              status.name === 'Fall Abgeschlossen') &&
                              <Fragments.DotWrapper>
                                <StyledRedDot style={{ backgroundColor: 'green' }}/>
                              </Fragments.DotWrapper>
                            }
                            {index == 2 && status.states.length == 0 &&
                              <Fragments.Timeline/>
                            }
                            {index != 3 && 
                              <Fragments.Timeline/>
                            }
                          {status.states.length != 0 && 
                          status.states[status.states.length-1].isInProgress === true &&
                          status.name != 'Fall Abgeschlossen' &&
                            <Fragments.DotWrapper>
                                <StyledRedDot style={{ backgroundColor: 'orange', marginBottom:'18px'}}/>
                            </Fragments.DotWrapper>
                          }
                        </Fragments.TimelineWrapper>
                        <Fragments.StatusInfoWrapper>
                          <Fragments.StatusNameText>{t(status.name)}</Fragments.StatusNameText>
                          {status.states.map(state => 
                            <Fragments.StatusDescriptionText>{t(state.statusText)}</Fragments.StatusDescriptionText>
                          )}
                        </Fragments.StatusInfoWrapper>
                      </Fragments.StatusItemWrapper>
                    )}
                  </Fragments.AllStatusWrapper>
                </Accordion>
              </Fragments.StatusWrapper>
              <Fragments.StatusWrapper>
                <Accordion
                  Accordion
                  title={'Akte Info'}
                  contentMarginBottom='0px'
                  minHeight='60px'
                  withBorderBottom={false}
                  contentMarginTop='5px'
                >
                  <FlexGrid directionColumn>
                    <FlexGrid>
                      <Headline3>{t('Created on')}</Headline3>
                      <Paragraph>{createdAt}</Paragraph>
                    </FlexGrid>

                    <FlexGrid style={{ gap: '8px' }}>
                      <Headline3>{t('NewsOverview')}</Headline3>
                      <FlexGrid>
                        <FlexGridItem style={{ flexDirection: 'column' }}>
                          <Paragraph>{t('in this akte')}</Paragraph>
                          <span style={{ fontSize: '14px' }}>
                            {selectedAkte && selectedAkte.totalUnreadMessage}
                          </span>
                        </FlexGridItem>
                        <FlexGridItem style={{ flexDirection: 'column' }}>
                          <Paragraph>{t('in folders')}</Paragraph>
                          <span style={{ fontSize: '14px' }}>
                            {selectedAkte &&
                              selectedAkte.totalUnreadMessageInFolder}
                          </span>
                        </FlexGridItem>
                      </FlexGrid>
                    </FlexGrid>
                  </FlexGrid>
                </Accordion>
              </Fragments.StatusWrapper>
            </Fragments.OverviewWrapper>
          } 
        </PhasesLoader>
      </>
  )
}

export default Overview
