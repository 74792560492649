import ACTIONS from 'application/constants'
import default_store from '../default_store'
import _ from 'lodash'
import { filterNewMessagesAndFiles, updateDataInFolderMap, updateDataUnreadMessageInFolderMap } from './function'

const messages = (state = default_store.messages, action) => {
    switch (action.type) {
        case ACTIONS.MESSAGES_REGISTER_MESSAGES:
            return {
                ...state,
                registered:
                    state.registered.filter((r) => r.key === action.messageKey).length > 0
                        ? state.registered
                        : [
                              ...state.registered,
                              {
                                  key: action.messageKey,
                                  messageType: action.messageType,
                                  isFullLoaded: false,
                                  isFailed: false,
                                  exist: true,
                                  files: [],
                                  folders: [],
                                  messages: [],
                                  totalMessages: 0,
                                  metaInfo: null,
                                  messageRaw: {},
                                  favoriteMessages: [],
                                  inputMessage: '',
                                  lastMessageDate: null,
                                  lastSync: null,
                                  offset: 0
                              },
                          ],
            }
        case ACTIONS.MESSAGES_UNREGISTER_MESSAGES:
            return {
                ...state,
                registered: state.registered.filter((r) => r.key !== action.key),
            }
        case ACTIONS.MESSAGES_REFRESH_REGISTER_MESSAGES:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key
                        ? {
                              ...d,
                              isFullLoaded: false,
                          }
                        : d
                }),
            }
        case ACTIONS.MESSAGES_REGISTERED_MESSAGES_FULLY_LOADED:
            const {filteredMessages , filteredFiles, messageLength} = filterNewMessagesAndFiles(state.registered, action.metaData)
            return {
                ...state,
                registered: state.registered.map((d) => {       
                    return d.key === action.key
                        ? {
                              ...d,
                              isFullLoaded: true,
                              isFailed: false,
                              ...action.metaData,
                              messages: filteredMessages,
                              files: filteredFiles,
                              offset: messageLength
                          }
                        : d
                }),
            }
        case ACTIONS.MESSAGES_REGISTERED_MESSAGES_FAILED:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key
                        ? {
                              ...d,
                              isFullLoaded: true,
                              isFailed: true,
                              exist: false,
                              ...action.metaData,
                          }
                        : d
                }),
            }
        case ACTIONS.MESSAGES_PUT_MESSAGE_AS_FAVORITE:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key && d.isFullLoaded
                        ? {
                              ...d,
                              messages: d.messages.map((m) => {
                                  return m.id === action.messageId
                                      ? {
                                            ...m,
                                            isFavorite: action.condition,
                                        }
                                      : m
                              }),
                          }
                        : d
                }),
            }
        case ACTIONS.MESSAGES_READ_MESSAGE:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key && d.isFullLoaded
                        ? {
                              ...d,
                              messages: d.messages.map((m) => {
                                  return m.id === action.messageId
                                      ? {
                                            ...m,
                                            read: true,
                                        }
                                      : m
                              }),
                          }
                        : d
                }),
            }
        case ACTIONS.MESSAGES_UPDATE_MESSAGES:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key && d.isFullLoaded
                        ? {
                              ...d,
                              ...action.payload,
                          }
                        : d
                }),
            }
        case ACTIONS.PAGES_AKTEN_PUT_MESSAGE_ON_QUEUE:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key && d.isFullLoaded
                        ? {
                              ...d,
                              messages: [...d.messages, action.payload],
                          }
                        : d
                }),
            }
        case ACTIONS.PAGES_AKTEN_POP_MESSAGE_ON_QUEUE:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key && d.isFullLoaded
                        ? {
                              ...d,
                              messages: d.messages.filter((m) => m.id !== action.idQueue),
                          }
                        : d
                }),
            }
        case ACTIONS.PAGES_AKTEN_REPLACE_MESSAGE_QUEUE_BY_ID:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key && d.isFullLoaded
                        ? {
                              ...d,
                              messages: d.messages.map((m) => {
                                  return m.id === action.idQueue ? action.payload : m
                              }),
                              totalMessages: d.totalMessages + 1,
                          }
                        : d
                }),
            }
        case ACTIONS.MESSAGES_RECOUNT_UNREAD_MESSAGE_IN_FOLDER_BY_MESSAGE_KEY:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key && d.isFullLoaded
                        ? {
                              ...d,
                              folders: updateDataUnreadMessageInFolderMap(d.folders, action.folderId),
                          }
                        : d
                }),
            }
        case ACTIONS.MESSAGES_UPDATE_DATA_IN_FOLDER_BY_MESSAGE_KEY:
            return {
                ...state,
                registered: state.registered.map((d) => {
                    return d.key === action.key && d.isFullLoaded
                        ? {
                              ...d,
                              folders: updateDataInFolderMap(d.folders, action.folderId, action.payload),
                          }
                        : d
                }),
            }
        case ACTIONS.MESSAGES_AKTEN_GET_FAVORITE_MESSAGES:
            return {
                ...state,
                favorites: {
                    ...state.favorites,
                    request: true,
                },
            }
        case ACTIONS.MESSAGES_AKTEN_POPULATE_FAVORITE_MESSAGES:
            return {
                ...state,
                favorites: {
                    ...state.favorites,
                    request: false,
                    succeed: true,
                    payload: action.payload.length !== 0 ? _.values(_.merge(_.keyBy(state.favorites.payload, 'id'), _.keyBy(action.payload, 'id'))) : state.favorites.payload, // DEEP MERGE FUNCTION WITH LODASH LIBRARY
                },
            }
        case ACTIONS.MESSAGES_AKTEN_GET_FAVORITE_MESSAGES_FAILED:
            return {
                ...state,
                favorites: {
                    ...state.favorites,
                    request: false,
                    failed: true,
                },
            }
        case ACTIONS.MESSAGES_AKTEN_REFRESH_FAVORITE_MESSAGES:
            return {
                ...state,
                favorites: {
                    request: false,
                    failed: false,
                    succeed: false,
                    payload: [...state.favorites.payload],
                },
            }
        case ACTIONS.MESSAGES_AKTEN_SET_PAYLOAD_FAVORITE_MESSAGES:
            return {
                ...state,
                favorites: {
                    ...state.favorites,
                    payload: [...state.favorites.payload, action.payload],
                },
            }
        case ACTIONS.MESSAGES_AKTEN_DELETE_PAYLOAD_FAVORITE_MESSAGES:
            return {
                ...state,
                favorites: {
                    ...state.favorites,
                    payload: state.favorites.payload.filter((p) => p.id !== action.messageId),
                },
            }
        default:
            return state
    }
}

export default messages
