import { ROUTES } from 'application/constants'
import { hashUserKey } from 'application/storage'
import React from 'react'
import { Navigate } from 'react-router-dom'

const StartRedirect = (props) => {
    const { akteMeta } = props

    if (akteMeta.userRegistered || akteMeta.userStatus !== 0) return <Navigate to={ROUTES.LOGIN + '/' + hashUserKey.get()} />

    return <React.Fragment>
        {props.children}
    </React.Fragment>
}

export default StartRedirect