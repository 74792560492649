import { AKTEN } from 'application/constants'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { scroller } from 'react-scroll'

const MessageListEffectsScroll = (props) => {
    let params = useParams()
    const { children } = props
    const { akteIdHash } = params

    useEffect(() => {
        scroller.scrollTo(akteIdHash, {
            smooth: true,
            containerId: AKTEN.CONTAINER_ID.MESSAGES_LIST,
            offset: 0,
            duration: 500,
            delay: 100,
        })
    }, [akteIdHash])

    return <React.Fragment>
        {children}
    </React.Fragment>
}

export default MessageListEffectsScroll