import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import IconLink from '../../icon/icon_link'

const DividerWrapper = styled.div`
    display: flex;
    min-height: 35px;
    height: auto;
    justify-content: ${props => props.onCloseClick !== undefined ? 'space-between' : 'center' };
    align-items: center;
    background-color: ${props => props.theme.color.dividerWrapper};
    padding: 10px;
    max-width: 100%;
`

const DividerTitle = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.medium};
    color: ${props => props.theme.color.dividerTitle};
    margin-right: 8px;
`

const DividerDescription = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.light};
    color: ${props => props.theme.color.dividerTitle};
`

const DividerText = ({ title, description = '', onCloseClick, theme }) => {
    const {t} = useTranslation()
    return <DividerWrapper onCloseClick={onCloseClick}>
        <div>
            <DividerTitle>
                {title}
            </DividerTitle>
            {
                description !== '' &&
                <DividerDescription>
                    {description}
                </DividerDescription>
            }
        </div>
        {
            onCloseClick !== undefined && <IconLink title={t('Close')} color={theme.color.salmonDark} iconSize={theme.fontSize.standard} width='24px' iconKey='clear' onClick={onCloseClick} />
        }
    </DividerWrapper>
}

export default withTheme(DividerText)