import React from 'react'
import { isMobileOnly, isTablet } from 'react-device-detect'
import ReactCodeInput from 'react-code-input'
import { useMediaQuery } from 'react-responsive'
import styled, { withTheme } from 'styled-components'

const StyledPinWrapper = styled.div`
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
	}
`

const FormInputPin = ({
    value,
    onUpdatePin = () => {},
    pinLength = 6,
    type = 'number',
    inputMode = 'numeric',
    theme,
    name = 'codePin',
    isValid,
    accountId,
}) => {
    const isSmallLaptop = useMediaQuery({ minWidth: 1366, maxWidth: 1440 })

    const inputStyleHW = isMobileOnly ? {
        height: '45px',
        width: '35px',
        padding: '10px 5px',
        margin: '4px 2px',
        fontSize: '18px',
    } : isTablet ? {
        height: '40px',
        width: '30px',
        padding: '10px 5px',
        margin: '4px 4px',
        fontSize: '18px',
    } : isSmallLaptop ? {
        height: '45px',
        width: '35px',
        padding: '10px 5px',
        margin: '4px 4px',
        fontSize: '20px',
    } : {
        height: '60px',
        width: '50px',
        padding: '10px 5px',
        margin: '4px 8px',
        fontSize: '24px',
    }
    const props = {
        className: 'reactCodeInput',
        inputStyle: {
            fontFamily: 'monospace',
            MozAppearance: 'textfield',
            paddingLeft: '7px',
            backgroundColor: theme.color.backgroundColorPin,
            border: '0px',
            color: theme.color.textColorPin,
            padding: inputStyleHW.padding,
            borderRadius: '5px',
            height: inputStyleHW.height,
            width: inputStyleHW.width,
            margin: inputStyleHW.margin,
            fontSize: theme.fontSize.headline1,
            fontWeight: theme.fontWeight.bold,
            textAlign: 'center',
        },
        inputStyleInvalid: {
            fontFamily: 'monospace',
            MozAppearance: 'textfield',
            paddingLeft: '7px',
            backgroundColor: theme.color.backgroundColorPin,
            border: '0px',
            color: theme.color.textColorPin,
            padding: inputStyleHW.padding,
            borderRadius: '5px',
            height: inputStyleHW.height,
            width: inputStyleHW.width,
            margin: inputStyleHW.margin,
            fontSize: theme.fontSize.headline1,
            fontWeight: theme.fontWeight.bold,
            textAlign: 'center',
        }
    }

    return <StyledPinWrapper>
        <ReactCodeInput inputMode={inputMode} type={type} onChange={onUpdatePin} isValid={isValid} value={value} name={name} fields={pinLength} {...props} />
    </StyledPinWrapper> 
}

export default withTheme(FormInputPin)
