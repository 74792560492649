import { ROUTES } from 'application/constants'
import React from 'react'
import { Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SidebarWrapper from '../building_blocks/sidebar_wrapper/component'
import { AktenPopupSidebar, ContactInfoPopupSidebar, ProviderPopupSidebar, NavigationLinkMobile } from './sections'

const PopupSidebars = (props) => {
    const { aktenSidebar, providerSidebar, closeAktenSidebar, contactInfoSidebar, closeProviderSidebar, closeContactInfoSidebar, navigationLinkMobile,closeNavigationLinkMobileSidebar } = props
    let navigate = useNavigate()
    let location = useLocation()
    const handleCloseProviderSidebar = () => {
        closeProviderSidebar()
        navigate(location.pathname)
    }
    const inDashboardIntern = location.pathname === ROUTES.DASHBOARD
    return <Fragment>
        {
            aktenSidebar.visible && inDashboardIntern && <SidebarWrapper
                showHeadline
                showRight
                onAbort={closeAktenSidebar}
                title='Akten'
            >
                <AktenPopupSidebar />
            </SidebarWrapper>
        }
        {
            providerSidebar.visible && <SidebarWrapper
                showHeadline
                showRight
                onAbort={handleCloseProviderSidebar}
                title='Service provider'
                {...providerSidebar}
            >
                <ProviderPopupSidebar />
            </SidebarWrapper>
        }
        {
            contactInfoSidebar.visible && <SidebarWrapper
                showHeadline
                showRight
                onAbort={closeContactInfoSidebar}
                title='Contact information'
                {...contactInfoSidebar}
            >
                <ContactInfoPopupSidebar {...contactInfoSidebar.payload} />
            </SidebarWrapper>
        }
        {
            navigationLinkMobile.visible && <SidebarWrapper
                sidebarWidth='65%'
                onAbort={closeNavigationLinkMobileSidebar}
                {...navigationLinkMobile}
            >
                <NavigationLinkMobile />
            </SidebarWrapper>
        }
    </Fragment>
}

export default PopupSidebars
