import styled from 'styled-components'


export const StyledHeadline = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: #4D4D4D;
    width: 72%;
    text-align: left;
`

export const StyledDisplayDescription = styled.h2`
    font-size: 16px;
    font-weight: 300;
    color: #4D4D4D;
    text-align: justify;
    word-break: break-word;
    width: 72%;
	white-space: pre-line;
    line-height: 24px;
`

export const StyledInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
`

export const StyledInput = styled.input`
    width: 100%;
    margin-top: -8px;
    border: none;
    border-bottom: 1px solid #E5E5E5;
    font-size: 14px;
    color: #7F8487;
    padding: 0;
    height: 38px;
`

export const StyledLabel = styled.h2`
    font-size: 16px;
    font-weight: bold;
`

export const StyledTextArea = styled.textarea`
    width: 96%;
    margin-top: -8px;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    font-size: 14px;
    color: #7F8487;
    padding: 0 10px;
    height: 64px;
`

export const StyledRatingWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
`

export const StyledImageContainer = styled.div`
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 193px;
    height: 193px;
    background-color: ${props => props.selected ? '#CEE5D0' : '#fff'};
`

export const StyledCheckboxWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 12px;
`