import { api } from 'application/api'
import ACTIONS from 'application/constants'
import {
  populateFullFile,
  populateFullFileFailed,
} from 'application/redux/actions/files'
import { fullFilesLoaded } from 'application/redux/selectors'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { handleErrorFile } from '../../errors'
import { accessTokenUnregistered } from 'application/storage'

function* fetchFullFileByIdSaga(action) {
  try {
    const isLoaded = yield select(fullFilesLoaded, action.id)
    const storage = action.metaData.storage ? action.metaData.storage : accessTokenUnregistered

    if (!isLoaded) {
      const result = yield call(
        api.gateway.files.getFullFiles,
        action.metaData.accountId,
        action.id,
        storage
      )
      const blobUrl = window.URL.createObjectURL(result)
      yield put(populateFullFile(action.id, action.fileName, blobUrl))
    }
  } catch (e) {
    yield handleErrorFile(e, action)
    yield put(populateFullFileFailed(action.id, action.fileName, null))
  }
}

export function* fetchFullFileByIdWatcher() {
  yield takeEvery(ACTIONS.FILES_GET_FULL_FILE_REQUEST, fetchFullFileByIdSaga)
}
