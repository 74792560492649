import ACTIONS from 'application/constants'
import { accessTokenForm } from 'application/storage/access_token'
import defaultStore from '../default_store'
import { convertingFilesToUploadIds } from './helpers'

const formReducer = (state = {
    ...defaultStore.form,
    authenticated: accessTokenForm.get() !== ''
}, action) => {
    switch (action.type) {
    case ACTIONS.FORM_GET_FORM_ELEMENTS_REQUEST:
        return {
            ...state,
            request: true,
        }
    case ACTIONS.FORM_REFRESH_FORM_ELEMENTS:
        return {
            ...state,
            succeed: false,
            request: false,
            failed: false,
            redirected: false,
            payload: null,
        }
    case ACTIONS.FORM_GET_FORM_ELEMENTS_SUCCEED:
        return {
            ...state,
            request: false,
            succeed: true,
            redirected: false,
        }
    case ACTIONS.FORM_GET_FORM_ELEMENTS_FAILED:
        return {
            ...state,
            request: false,
            failed: true,
            redirected: true,
        }
    case ACTIONS.FORM_GET_FORM_ELEMENTS_REDIRECTED:
        return {
            ...state,
            request: false,
            loaded: false,
            failed: true,
            redirected: true,
        }
    case ACTIONS.FORM_WITH_PIN:
        return {
            ...state,
            withPin: action.condition,
            authenticated: false
        }
    case ACTIONS.FORM_AUTHENTICATED:
        return {
            ...state,
            authenticated: true
        }
    case ACTIONS.FORM_LOGIN_ATTEMPT:
        return {
            ...state,
            loginAttempt: action.attempt
        }
    case ACTIONS.FORM_POPULATE_FORM_METADATA:
        return {
            ...state,
            metaData: {
                ...state.metaData,
                ...action.payload
            }
        }
    case ACTIONS.FORM_SET_THEME:
        return {
            ...state,
            theme: {
                ...state.theme,
                ...action.themeOverride
            }
        }
    case ACTIONS.FORM_POPULATE_FORM_ELEMENTS:
        return {
            ...state,
            payload: {
                ...state.payload,
                ...action.payload
            }
        }
    case ACTIONS.FORM_GET_FORM_ELEMENTS_REFRESH:
        return {
            ...state,
            metaData: null,
            withPin: false,
            authenticated: false,
            loginAttempt: 0,
            succeed: false,
            request: false,
            failed: false,
            redirected: false,
            payload: null,
            resultText: '',
        }
    case ACTIONS.FORM_SEND_REQUEST:
        return {
            ...state,
            send: {
                ...state.send,
                id: action.key,
                request: true,
                formData: action.formData,
            }
        }
    case ACTIONS.FORM_SEND_SUCCEED:
        return {
            ...state,
            send: {
                ...state.send,
                id: null,
                formData: null,
                files: null,
                request: false,
                succeed: true,
            }
        }
    case ACTIONS.FORM_SEND_FAILED:
        return {
            ...state,
            send: {
                ...state.send,
                id: null,
                formData: null,
                files: null,
                request: false,
                failed: true,
            }
        }
    case ACTIONS.FORM_SEND_REFRESH:
        return {
            ...state,
            send: {
                id: null,
                request: false,
                succeed: false,
                failed: false,
                formData: null,
                files: null,
            }
        }
    case ACTIONS.FORM_UPDATE_UPLOAD_IDS:
        return {
            ...state,
            send: {
                ...state.send,
                files: state.send.files.filter(f => f.attachment.fileId !== action.fileId).concat(action.uploadId)
            }
        }
    case ACTIONS.FORM_UPDATE_UPLOAD_IDS_INPUT_FORM:
        const newObj = convertingFilesToUploadIds(action.keyValue, state, action.fileId, action.uploadId)
        return {
            ...state,
            send: {
                ...state.send,
                formData: {
                    ...state.send.formData,
                    formInput: { ...state.send.formData.formInput, ...newObj }
                }
            }
        }
    case ACTIONS.FORM_UPDATE_RESULT_TEXT:
        return {
            ...state,
            resultText: action.resultText
        }
    case ACTIONS.FORM_SET_RETURN_URL:
        return {
            ...state,
            returnUrl: action.url
        }
    case ACTIONS.FORM_REFRESH_RETURN_URL:
        return {
            ...state,
            returnUrl: null
        }
    default:
        return state
    }
}

export default formReducer