import { AKTEN } from 'application/constants'
import React, { useEffect } from 'react'

const RootMessageLoader = (props) => {
    const { allAccountsLoaded, accountId, akteIdHash, match, children, registeredMessages, rootKey, getMessageDetails, allAktenLoaded, loggedIn, folderId } = props

    const rootMessageRegistered = registeredMessages.filter((r) => r.key === rootKey).length > 0
    const rootMessageLoaded = rootMessageRegistered && registeredMessages.find((r) => r.key === rootKey).isFullLoaded
    const rootMessageshouldLoad = !rootMessageLoaded
    const inFolder = folderId !== ''
    useEffect(() => {
        loggedIn &&
            inFolder &&
            rootMessageshouldLoad &&
            allAccountsLoaded &&
            allAktenLoaded &&
            rootKey !== '' &&
            getMessageDetails(accountId, akteIdHash, match, rootKey, AKTEN.TYPE.ROOT)
    }, [accountId, allAccountsLoaded, akteIdHash, getMessageDetails, match, rootKey, rootMessageshouldLoad, allAktenLoaded, loggedIn, inFolder])

    return <React.Fragment>{children}</React.Fragment>
}

export default RootMessageLoader
