import { FlexGrid } from 'application/components/fragments/grid'
import { StyledRedDot } from 'application/components/fragments/red_dot'
import { Headline3 } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getRedDotColor } from './helpers'

export const OverviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  overflow: auto;
  padding-bottom: 35px;
`

export const AccountInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  padding: 0 5%;
  gap: 10px;
`

export const AssigneAvatar = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  transform: translateX(${(p) => p.translateX});
`

export const AssigneMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: ${(p) => p.theme.color.primary};
  color: white;

  transform: translateX(${(p) => p.translateX});
`

export const AccountImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`

export const AvatarAssigneListWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  padding: 5px 0px;
  border-radius: 5px;
  transition: all;
  transition-duration: 300ms;

  &:hover {
    background: #e6e6e6;
  }
`

export const WarningAufgabeContainer = styled.div`
  background-color: ${(p) => p.theme.color.redLight};
  padding: 24px;
  border-radius: 15px;
  margin: 20px 5%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const WarningAufgabeButton = styled.button`
  background-color: white;
  padding: 15px;
  color: black;
  border: none;
  font-weight: bold;
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
  transition: all;
  transition-duration: 300ms;

  &:hover {
    background: #e6e6e6;
  }
`

export const StatusWrapper = styled.div`
  border: 1px solid #e6e6e6;
  padding: 5px 10px;
  padding-bottom: 0;
  border-radius: 15px;
  margin: 0px 5%;
  margin-bottom: 20px;
  background-color: white;
`

export const StatusContent = styled.div`
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items:${p => p.status === 'orange' ? 'end' : 'start'} ;
`

export const AssignePopup = styled.div`
  position: absolute;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  top: 100%;
  left: 0;
  min-width: 300px;
  border-radius: 15px;
  z-index: 3;

`

export const AssignePopupItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 5px;
`

export const AllStatusWrapper = styled.div`
  display: flex;
  flex-direction : column;
  margin-top: 30px;
`

export const StatusItemWrapper = styled.div`
  display : flex;
  flex-direction: row;
  gap: 20px;
  justify-content: start;
  align-items:stretch;
`

export const StatusInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:5px;
  padding-bottom:20px;
`

export const StatusDescriptionText = styled.span`
  font-size: 14px;
  color: ${p => p.theme.color.color80};
`

export const StatusNameText = styled.b`
  font-size: 16px;
  margin-bottom: 5px;
`

export const Timeline = styled.div`
  flex-grow: 1;
  width: 2px;
  background:#e6e6e6;
  margin: auto;
`

export const DotWrapper = styled.div`
  background-color: white;
  padding: 4px;
`

export const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const NewestStatus = (props) => {
  const { state } = props
  const {t} = useTranslation()
  const lastStatus = state.states[state.states.length-1]

  return (
    <>
      <StatusContent style={{ padding: '15px 25px' }} status={getRedDotColor(state)}>
        <StyledRedDot 
        style={{ 
          marginBottom: '2px', 
          background: getRedDotColor(state),
          marginTop: getRedDotColor(state) === 'green' && '10px',
        }}
      />
        <FlexGrid directionColumn>
          <Headline3>{t(state.name)}</Headline3>
            <StatusDescriptionText>
                {t(lastStatus.statusText)}
            </StatusDescriptionText>
        </FlexGrid>
      </StatusContent>
    </>
  )
}
