import React from 'react'
// import PropTypes from 'prop-types'

import { ThumbnailSliderOnQueue } from 'application/components/building_blocks/attachments/slider'
import useEscapeKey from 'application/components/hooks/use_escape_key'

import {
    StyledOverlayDimmer,
    StyledOverlayContent,
    StyledHeader,
    StyledSidebarHeaderCaptionOnQueue,
    StyledSidebarHeaderCloseButton,
    StyledSendButtonOnQueue,
    StyledHeaderRow,
    StyledFooterRow,
    StyledContentonQueue,
} from './fragments'
import AttachmentViewerComponent from '../attachment_viewer/component'
import { fileHelpers, LoadEffect, textHelpers } from 'application/common'
import GalleryOnQueueEffects from './on_queue_effects'
import { FlexGrid } from 'application/components/fragments/grid'
import IconLink from 'application/components/controls/icon/icon_link'
import { SimpleUploader } from 'application/components/controls/form_elements'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import {
    DialogAbortConfirmHeader,
    DialogWaitingScreen,
} from '../../dialog/fragments'
import { Paragraph } from 'application/components/fragments/typography'
import IconButtonWithLabel from 'application/components/controls/icon/icon_button_with_label/component'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { isMobileOnly } from 'react-device-detect'

const AttachmentGalleryOnQueue = ({
    gallery,
    onSelectAttachment,
    onCloseGallery,
    sendFiles,
    filesOnQueue = [],
    sendRequest,
    clearFilesOnQueue,
    filesAdded,
    theme,
    waitingScreen,
}) => {
    useEscapeKey(onCloseGallery)
    const handleSelectAttachment = (att) => {
        onSelectAttachment(
            att.fileId,
            att.fileName,
            att.thumbnail,
            gallery.accountId
        )
    }
    const handleSend = () => {
        sendFiles(gallery.accountId, filesOnQueue)
    }
    let truncateFileName = gallery?.selectedFileName !== null ? textHelpers.truncateMiddleText(
        gallery.selectedFileName,
        50
    ) : <PreloaderSpin />
    const { t } = useTranslation()

    
    return gallery.isVisible ? (
        <GalleryOnQueueEffects
            filesOnQueue={filesOnQueue}
            onSelectAttachment={onSelectAttachment}
            gallery={gallery}
        >
            <StyledOverlayDimmer isVisible={gallery.isVisible}>
                <StyledOverlayContent>
                    <StyledHeaderRow>
                        <StyledHeader>
                            <StyledSidebarHeaderCaptionOnQueue blurred={sendRequest}>
                                {truncateFileName}
                            </StyledSidebarHeaderCaptionOnQueue>
                            {sendRequest ? (
                                <React.Fragment />
                            ) : (
                                <React.Fragment>
                                    <StyledSidebarHeaderCloseButton>
                                        <SimpleUploader
                                            iconKey="add"
                                            label={isMobileOnly ? '' : t('AddDocuments')}
                                            onFileAdded={(file) => filesAdded(file)}
                                        />
                                    </StyledSidebarHeaderCloseButton>
                                    <StyledSidebarHeaderCloseButton>
                                        <IconButtonWithLabel
                                            padding={'0px'}
                                            className="closeBtn"
                                            iconKey="delete"
                                            onButtonClick={() =>
                                                clearFilesOnQueue(
                                                    gallery.selectedFileId,
                                                    filesOnQueue.filter(
                                                        (f) =>
                                                            f.attachment.fileId !== gallery.selectedFileId
                                                    ),
                                                    gallery.accountId
                                                )
                                            }
                                            width="10px"
                                            height="10px"
                                        />
                                    </StyledSidebarHeaderCloseButton>
                                    <StyledSidebarHeaderCloseButton>
                                        <IconButtonWithLabel
                                            padding={'0px'}
                                            className="closeBtn"
                                            iconKey="close"
                                            onButtonClick={onCloseGallery}
                                            width="10px"
                                            height="10px"
                                        />
                                    </StyledSidebarHeaderCloseButton>
                                </React.Fragment>
                            )}
                        </StyledHeader>
                    </StyledHeaderRow>
                    <LoadEffect loading={gallery.selectedFileId === null}>
                        <StyledContentonQueue blurred={sendRequest}>
                            <AttachmentViewerComponent
                                withDownload={false}
                                extension={fileHelpers.getFileExtension(
                                    gallery.selectedFileName
                                )}
                                isLoading={false}
                                fileId={parseInt(gallery.selectedFileId)}
                                fileName={gallery.selectedFileName}
                                attachment={{ data: gallery.selectedData }}
                                accountId={gallery.accountId}
                            />
                        </StyledContentonQueue>
                    </LoadEffect>
                    <FlexGrid shrink={0} basis="5%" justifyFlexEnd>
                        <StyledSendButtonOnQueue>
                            {sendRequest ? (
                                <React.Fragment />
                            ) : (
                                <LoadEffect loading={gallery.selectedFileId === null}>
                                    <IconLink
                                        title={t('Upload document')}
                                        onClick={handleSend}
                                        iconKey="send"
                                        color={theme.color.color0}
                                        backgroundcolor={theme.color.primary}
                                        isActive={true}
                                        borderradius='100%'
                                        noHover
                                    />
                                </LoadEffect>
                            )}
                        </StyledSendButtonOnQueue>
                    </FlexGrid>
                    {filesOnQueue.length > 1 && (
                        <StyledFooterRow>
                            <LoadEffect loading={gallery.selectedFileId === null}>
                                <ThumbnailSliderOnQueue
                                    thumbnailsForSlider={filesOnQueue}
                                    accountId={gallery.accountId}
                                    onThumbnailClick={handleSelectAttachment}
                                    selectedThumbnailId={parseInt(gallery.selectedFileId)}
                                />
                            </LoadEffect>
                        </StyledFooterRow>
                    )}
                </StyledOverlayContent>
            </StyledOverlayDimmer>
            {waitingScreen.active && waitingScreen.inDialog && (
                <DialogWaitingScreen dialogIndex={200}> 
                        <DialogAbortConfirmHeader>
                            <PreloaderSpin/>
                            {t(waitingScreen.message)}
                        </DialogAbortConfirmHeader>
                    {waitingScreen.subMessages.map((m, i) => {
                        return <Paragraph key={i}>{m}</Paragraph>
                    })}
                </DialogWaitingScreen>
            )}
        </GalleryOnQueueEffects>
    ) : (
        <React.Fragment></React.Fragment>
    )
}

export default withTheme(AttachmentGalleryOnQueue)
