import { API_VERSION_ESYMANDANT, fetchHandler, fetchRequestBuilder, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'
import { accessTokenUnregistered } from 'application/storage'

export const downloadDatenshutzByAccountIdUnregistered = (accountId) => {

    const request = fetchRequestBuilder(API_VERSION_ESYMANDANT + '/e.consult.' + accountId + '/account/datenschutz', HTTP_METHOD.GET, {}, true, false, false, accessTokenUnregistered)

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}