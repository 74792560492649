import styled from 'styled-components'

export const Headline1 = styled.h1`
  font-size: ${props => props.theme.fontSize.headline1};
  font-weight: ${props => props.theme.fontWeight.headline1};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  color: ${props => props.color ? props.color : props.theme.color.color100};
  margin: 0px;
`

export const Headline2 = styled.h2`
  font-size: ${props => props.theme.fontSize.headline2};
  font-weight: ${props => props.theme.fontWeight.headline2};
  color: ${props => props.color ? props.color : props.theme.color.color100};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  margin: 0px;
`

export const Headline3 = styled.h3`
  font-size: ${props => props.theme.fontSize.headline3};
  font-weight: ${props => props.theme.fontWeight.headline3};
  color: ${props => props.color ? props.color : props.theme.color.color100};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  margin: 0px;
`

export const Paragraph = styled.p`
  font-size: ${props => props.theme.fontSize.standard};
  font-weight: ${props => props.fontWeight ? props.fontWeight : props.theme.fontWeight.standard};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  color: ${props => props.color ? props.color : props.theme.color.color100};
  margin: 0 0 16px;
`

export const ParagraphTitle = styled.p`
  font-size: ${props => props.theme.fontSize.standard};
  font-weight: ${props => props.theme.fontWeight.standardBold};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  margin: 0 0 16px;
`