import { WIZARD_STEP } from 'application/constants'
import { getAkteMetaRequest } from 'application/redux/actions/akte_meta'
import { getCheckUsername, updateWizardNext, updateWizardPrev } from 'application/redux/actions/wizard'
import { connect } from 'react-redux'
import WizardRegistrationUsernameComponent from './component'

const mapStateToProps = state => ({
    wizardData: state.wizard.data,
    wizardLoaded: state.wizard.isLoaded,
    akteMeta: state.akteMeta.payload,
    auth: state.auth,
    akteMetaLoaded: state.akteMeta.isLoaded,
    usernameSet: state.wizard.registrationData.usernameSet,
    usernameNotValid: state.wizard.registrationData.usernameNotValid, 
    usernameRequest: state.wizard.registrationData.usernameRequest, 
    errorMessage: state.wizard.registrationData.errorMessage,
})

const mapDispatchToProps = dispatch => ({
    onUpdateWizardNext: (current, next) => dispatch(updateWizardNext(current, next, WIZARD_STEP.REGISTRATION.PASSWORD)),
    onUpdateWizardPrev: (current, prev) => dispatch(updateWizardPrev(current, prev, WIZARD_STEP.REGISTRATION.WELCOME)),
    onCheckUsername: (username, userId) => { dispatch(getCheckUsername(username, userId))},
    getAkteMetaRequest: (hashUserKey) => dispatch(getAkteMetaRequest(hashUserKey)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardRegistrationUsernameComponent)