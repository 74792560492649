import { fileHelpers } from 'application/common'
import React, { useEffect, useState } from 'react'
import { DisplayFilterTime, DisplayPdf } from './display'
import { ContainerTabs, TabsItem, TabsWrapper } from './fragments'
import { useTranslation } from 'react-i18next'

const TABS_KEY = {
    ph: 'photos',
    docs: 'docs'
}

const TabsOnFilesSidebar = (props) => {
    const { thumbnailsToShow } = props
    
    const [thumbnailsNoPdf, setThumbnailsNoPdf] = useState([])
    const [thumbnailsPdf, setThumbnailsPdf] = useState([])
    const [isselected, setIsSelected] = useState(TABS_KEY.ph)

    useEffect(() => {
        setThumbnailsPdf(thumbnailsToShow.filter(t =>
            fileHelpers.getFileExtension(t.fileName) !== 'gif' &&
            fileHelpers.getFileExtension(t.fileName) !== 'jpg' &&
            fileHelpers.getFileExtension(t.fileName) !== 'png' &&
            fileHelpers.getFileExtension(t.fileName) !== 'jpeg'
        ))
    }, [thumbnailsToShow])
    useEffect(() => {
        setThumbnailsNoPdf(thumbnailsToShow.filter(t =>
            fileHelpers.getFileExtension(t.fileName) === 'gif' ||
            fileHelpers.getFileExtension(t.fileName) === 'jpg' ||
            fileHelpers.getFileExtension(t.fileName) === 'png' ||
            fileHelpers.getFileExtension(t.fileName) === 'jpeg'
        ))
    }, [thumbnailsToShow])

    const handleFilterPhotos = () => {
        setIsSelected(TABS_KEY.ph)
    }

    const handleFilterDocs = () => {
        setIsSelected(TABS_KEY.docs)
    }

    const docsProps = { thumbnailsPdf, ...props}
    const photosProps = { thumbnailsNoPdf, ...props}

    const {t} = useTranslation()

    return <div>
        <ContainerTabs>
            <TabsWrapper>
                <TabsItem onClick={handleFilterPhotos} isselected={isselected === TABS_KEY.ph}>
                    {t('photo')}
                </TabsItem>
                {
                    thumbnailsPdf.length > 0 &&
                    <TabsItem onClick={handleFilterDocs} isselected={isselected === TABS_KEY.docs}>
                        {t('document')}
                    </TabsItem>
                }
            </TabsWrapper>
        </ContainerTabs>
        <React.Fragment>
            {
                isselected === TABS_KEY.ph && <React.Fragment>
                    <DisplayFilterTime {...photosProps} />
                </React.Fragment>
            }
            {
                isselected === TABS_KEY.docs && <React.Fragment>
                    <DisplayPdf {...docsProps} />
                </React.Fragment>
            }
        </React.Fragment>
    </div>
}

export default TabsOnFilesSidebar