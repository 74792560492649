import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { accessTokenAdhoc } from 'application/storage/access_token'
import { call, takeLatest } from 'redux-saga/effects'
import { handleError } from '../../errors'

export function* downloadAttachmentAdHocSaga(action) {
  try {
    yield call(
      api.gateway.files.downloadFilesById,
      action.accountId,
      action.id,
      action.fileName,
      accessTokenAdhoc,
    )
  } catch (e) {
    yield handleError(e, action)
  }
}

export function* downloadFileAdHocSagaWatcher() {
  yield takeLatest(
    ACTIONS.DOWNLOAD_FILE_BY_ID_ADHOC_REQUEST,
    downloadAttachmentAdHocSaga
  )
}
