import { getFullFileById } from 'application/redux/actions/files'
import { connect } from 'react-redux'
import AttachmentViewerComponent from './load_attachment_hoc'

const mapStateToProps = (state) => {
    return ({
        files: state.files
    })
}

const mapDispatchToProps = dispatch => ({
    onLoadAttachment: (fileId, fileName, metaData, accessToken) => {
        dispatch(getFullFileById(fileId, fileName, metaData, accessToken))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentViewerComponent)