const theme = {
  title: 'e.sy One | the new way of communication',
  favicon: 'favicon.ico',
  companyName: 'e.sy One',

  color: {
    body: '#ffffff',
    white: '#ffffff',
    text: '#4d4d4d',
    color0: '#ffffff',
    smokewhite: '#f5f5f5',
    lightRed: 'ffd3d6',
    redLight: '#F55D67',
    red: '#bc3640',
    black10: '#E6E6E6',
    black20: '#C6C6C6',
    green: '#28a35b',
    color3: '#fafafa',
    color5: '#f5f5f5',
    color10: '#ededed',
    color15: '#d9d9d9',
    color20: '#cccccc',
    color30: '#a6a6a6',
    color40: '#929292',
    color45: '#8c8c8c',
    color70: '#4d4d4d',
    color80: '#A0A0A0',
    color90: '#404040',
    color100: '#000000',
    iceBlue: '#c4e7ff',
    iceBlue50: '#f0f9ff',
    lightTurqouise: '#f4fcff',
    turqouise: '#009a92',
    lightPurple: '#ebe6ff',
    purple: '#5136b9',
    powderGray: '#616b78',
    lightPowderGray: '#9a9eab',
    notificationSuccess: '#55d17b',
    warning: '#ffa500',
    success: '#4bb543',
    salmonLight: '#FEF1F7',
    salmon: '#eb3b86',
    salmonDark: '#d389bb',
    counterColor: '#d389bb',
    checked: '#d389bb',
    primary: '#009a92',
    preloaderColor: '#429f98',
    backgroundPrimary: '#f3f6f8',
    secondaryAkte: '#e7f4f3',
    mainAkte: '#009a92',
    backgroundExternalLoginAccount: '#FEF8F4',
    aktenSourceColor: '#FFC013',
    folderColor: '#1696FB',
    backgroundColorPin: '#C2ECE9',
    textColorPin: '#429f98',
    redDot: '#d389bb',
    dividerTitle: '#eb3b86',
    dividerWrapper: '#FEF1F7',
    policyTextColor: '#bc3640',
    filterPill: '#009a92',
    requestPin: '#bc3640', 
  },
  fontFamily: {
    primary: 'Lato, sans-serif',
    secondary: 'Lato, sans-serif',
  },
  fontSize: {
    xxxLarge: '44px',
    xxLarge: '36px',
    xLarge: '30px',
    large: '26px',
    headline1: '24px',
    headline2: '22px',
    headline3: '20px',
    medium18: '18px',
    medium: '16px',
    standard: '14px',
    small: '12px',
    xSmall: '10px',
  },
  fontWeight: {
    heavy: '900',
    bold: '800',
    semiBold: '700',
    medium: '600',
    regular: '500',
    light: '400',
    thin: '300',
  },
  fontStyle: {
    default: 'normal',
    italic: 'italic',
  },
  breakpoint: {
    xSmall: 480,
    small: 640,
    medium: 800,
    large: 1024,
    vSmall: 800,
    //custom
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tabletS: 640,
    tablet: 768,
    laptop: 1024,
    laptopM: 1224,
    laptopL: 1440,
    laptopXL: 1500,
    desktop: 2560,
    // sm - md - lg - xl - 2xl
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1536px',
  },
  thumbnail: {
    width: '64',
    height: '70',
  },
  transition: {
    fast: '0.1s',
  },
  dialog: {
    size: {
      small: 370,
      medium: 740,
      medium50: 905,
      large: 1110,
    },
  },
  disabledOpacity: '0.5',
  loginRightImage: '/assets/images/image_login.jpg',
  companyIcon: '/assets/images/icon_esy_one.png',
  navigationMessagesIconKey: 'mail',

  classes: {
    succeedContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      overflow: 'scroll',
    },

    forgotButton: {
      color: '#bc3640',
    },
  },
}
const themeDark = {
    color: {
        body: '#4d4d4d',
        white: '#ffffff',
        text: '#ffffff',
        color0: '#101010',
        smokewhite: '#f5f5f5',
        lightRed: 'ffd3d6',
        red: '#bc3640',
        black10: '#E6E6E6',
        black20: '#C6C6C6',
        green: '#28a35b',
        color3: '#404040',
        color5: '#A0A0A0',
        color10: '#4d4d4d',
        color15: '#8c8c8c',
        color20: '#929292',
        color30: '#a6a6a6',
        color40: '#cccccc',
        color45: '#d9d9d9',
        color70: '#ededed',
        color80: '#f5f5f5',
        color90: '#fafafa',
        color100: '#ffffff',
        iceBlue: '#c4e7ff',
        iceBlue50: '#f0f9ff',
        lightTurqouise: '#f4fcff',
        turqouise: '#009a92',
        lightPurple: '#ebe6ff',
        purple: '#5136b9',
        powderGray: '#616b78',
        lightPowderGray: '#9a9eab',
        notificationSuccess: '#55d17b',
        warning: '#ffa500',
        success: '#4bb543',
        salmonLight: '#FEF1F7',
        salmon: '#eb3b86',
        salmonDark: '#d389bb',
        primary: '#009a92',
        backgroundPrimary: '#232323',
        secondaryAkte: '#e7f4f3',
        mainAkte: '#009a92',
    },
    fontSize: {
        xxxLarge: '48px',
        xxLarge: '40px',
        xLarge: '32px',
        large: '28px',
        headline1: '24px',
        headline2: '22px',
        headline3: '20px',
        medium18: '18px',
        medium: '16px',
        standard: '14px',
        small: '12px',
        xSmall: '10px',
    },
    fontWeight: {
        heavy: '900',
        bold: '800',
        semiBold: '700',
        medium: '600',
        regular: '500',
        light: '400',
        thin: '300',
    },
    fontStyle: {
        default: 'normal',
        italic: 'italic'
    },
    breakpoint: {
        xSmall: 480,
        small: 640,
        medium: 800,
        large: 1024,
        vSmall: 800,
        //custom
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        tabletL: 1024,
        laptop: 1024,
        laptopM: 1224,
        laptopL: 1440,
        laptopXL: 1500,
        desktop: 2560,
    },
    thumbnail: {
        width: '64',
        height: '70'
    },
    transition: {
        fast: '0.1s'
    },
    dialog: {
        size: {
            small: 370,
            medium: 740,
            medium50: 905,
            large: 1110
        },
        sizeFullWidth: '100%'
    },
    disabledOpacity: '0.5',
    classes: {

    }
}

export { themeDark }
export default theme
