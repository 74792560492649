import ExternalLoginAccountLayout from 'application/components/pages/_layout/external/external_login_account'
import React from 'react'
import AccountPublicInfoFailedRedirect from './pre_actions/account_public_info_failed_redirect'
import AccountPublicInfoLoader from './pre_actions/account_public_info_loader'
import AccountPublicInfoMobileRedirect from './pre_actions/account_public_info_mobile_redirect'
import * as Fragments from './fragments'
import { PushToDashboardEffect } from './effects'
import { Helmet } from 'react-helmet'
import { RefreshForm } from 'application/common'
import { withOrientationChange } from 'react-device-detect'
import DefaultContentProdulyPresents from './_default'
import { useLocation } from 'react-router-dom'
import { WhitelabelRedirect } from './effects/redirect'

const LoginAccountComponent = (props) => {
    const { openLoginDialog, accountPublicInfo, publicInfoLoaded, auth, handleExternalRequestClick, isLandscape } = props
    let { pathname, search } = useLocation()
    let url = pathname + search
    const assistants = publicInfoLoaded ? accountPublicInfo.assistants : []
    const assistantsAvailable = assistants && assistants.length > 0
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    const handleLogin = () => {
        const title = publicInfoLoaded && accountPublicInfo !== null && accountPublicInfo.accountShortName === null ? accountPublicInfo.accountName : accountPublicInfo.accountShortName
        !loggedIn && openLoginDialog(title)
    }
    const onExternalRequestClick = () => {
        handleExternalRequestClick(url)
    }

    const newProps = {
        handleLogin,
        onExternalRequestClick,
        loggedIn,
        ...props
    }

    return <ExternalLoginAccountLayout>
            <AccountPublicInfoLoader {...newProps}>
                <RefreshForm {...newProps}>
                    <AccountPublicInfoFailedRedirect {...newProps}>
                        <AccountPublicInfoMobileRedirect {...newProps}>
                            {
                                publicInfoLoaded &&
                                <PushToDashboardEffect {...newProps}>
                                    <Fragments.LoginAccountContainer>
                                        <Helmet>
                                            <title>{accountPublicInfo.accountName}</title>
                                            <meta name='description' content={accountPublicInfo.welcomeText} />

                                            <meta property="og:type" content="website" />
                                            <meta property='og:title' content={accountPublicInfo.accountName} />
                                            <meta property='og:description' content={accountPublicInfo.welcomeText} />
                                            <meta property='og:image' content={accountPublicInfo.primaryLogoUrl} />
                                            <meta property='og:url' content={url} />

                                            <meta name="twitter:card" content='summary_large_image' />
                                            <meta name="twitter:url" content={url} />
                                            <meta name="twitter:title" content={accountPublicInfo.accountName} />
                                            <meta name="twitter:description" content={accountPublicInfo.welcomeText} />
                                            <meta name="twitter:image" content={accountPublicInfo.primaryLogoUrl} />
                                        </Helmet>
                                        <DefaultContentProdulyPresents isLandscape={isLandscape} assistantsAvailable={assistantsAvailable} {...newProps} />
                                    </Fragments.LoginAccountContainer>
                                </PushToDashboardEffect>
                            }
                        </AccountPublicInfoMobileRedirect>
                    </AccountPublicInfoFailedRedirect>
                </RefreshForm>
            </AccountPublicInfoLoader>
    </ExternalLoginAccountLayout>
}

export default withOrientationChange(LoginAccountComponent)
