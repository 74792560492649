import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import Select, { components } from 'react-select'
import { Controller } from 'react-hook-form'

const StyledPureSelect = styled.div`
      width: ${props => props.cssWidth};
      flex: ${props => props.cssFlexBasis === 'auto' ? '1' : '0'} 0 ${props => props.cssFlexBasis};

     
`
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="material-icons">keyboard_arrow_down</i>
        </components.DropdownIndicator>
    )
}

const ClearIndicator = props => {
    return (
        <components.ClearIndicator {...props}>
            <i className="material-icons">close</i>
        </components.ClearIndicator>
    )
}

const getCustomComponent = (Option, ValueContainer) => {
    return Option === null ? {
        DropdownIndicator,
        ClearIndicator,
    } : ValueContainer !== null ? {
        DropdownIndicator,
        ClearIndicator,
        Option,
        ValueContainer
    } : {
        DropdownIndicator,
        ClearIndicator,
        Option
    }
}

const PureSelect = (props) => {
    const { cssWidth = 'auto', cssFlexBasis = 'auto', optionComponent, theme, valueComponent, name, register, control, validate, setValue, ...rest } = props
    const Option = optionComponent !== undefined ? optionComponent : null
    const ValueContainer = valueComponent !== undefined ? valueComponent : null
    const CustomComponent = getCustomComponent(Option, ValueContainer)

    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? '#404040' : '#d9d9d9',
            borderRadius: '5px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#9a9eab',
            }
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? theme.color.primary : '',
            '&:hover': {
                background: state.isSelected ? theme.color.primary : theme.color.color15
            }
        })
    }
    return <Controller
        name={name}
        control={control}
        rules={{ validate }}
        render={({ field: { onChange, value } }) => (
            <Fragment>
                <StyledPureSelect valueImage={valueComponent} cssWidth={cssWidth} cssFlexBasis={cssFlexBasis}>
                    <Select
                        styles={customStyles}
                        components={{ ...CustomComponent }}
                        onChange={(val) => {
                            setValue(name, val === null ? null : val.value)
                            onChange(val === null ? null : val.value)
                        }}
                        {...rest} 
                    />
                </StyledPureSelect>
            </Fragment>
        )}
    /> 
}

export { PureSelect }
export default withTheme(PureSelect)