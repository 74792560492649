import React from 'react'
import SeparatorBreadcrumb from './separator'

const BreadcrumbCollapser = (props) => {
    const {handleClick} = props
    return <React.Fragment>
        <span onClick={handleClick}> ... </span>
        <SeparatorBreadcrumb />
    </React.Fragment>
}

export default BreadcrumbCollapser