
export class Plugin {
  id = null
  name = null
  description = null
  version = null
  // not sure wether to name resources or localization
  resources = {} // language resources
  theme = {}
  config = {}
  components = {}

  getConfig(mode) {
    return this.config[mode]
  }

  getTheme() {
    return this.theme
  }

  getComponent(id) {
    return this.components[id]
  }

  getResources() {
    return this.resources
  }
}