import { getNewMessagesRequest, getNewMessageOnFolderRequest } from 'application/redux/actions/pages/akten'
import { readMessageRequest } from 'application/redux/actions/pages/akten/action'
import { connect } from 'react-redux'
import WrapperMessagesComponent from './component'

const mapStateToProps = (state) => ({
    newMessageRequest: state.pages.akten.action.newMessages.request,
    newMessageLoaded: state.pages.akten.action.newMessages.succeed,
    newMessageOnFolderRequest: state.pages.akten.action.newMessagesOnFolder.request,
    newMessageOnFolderLoaded: state.pages.akten.action.newMessagesOnFolder.succeed,
    metaInfo: state.pages.akten.common.metaInfo,
    filesLoaded: state.pages.akten.action.newFiles.succeed,
    allAccountsLoaded: state.common.allAccounts.isLoaded,
    allAktenLoaded: state.common.allAkten.isLoaded,
    sendNewMessage: state.pages.akten.action.sendMessage.request,
})

const mapDispatchToProps = (dispatch) => ({
    getNewMessage: (accountId, akteIdHash, params, messageKey) => dispatch(getNewMessagesRequest(accountId, akteIdHash, params, messageKey)),
    getNewMessageOnFolder: (accountId, akteIdHash, folderId, params, messageKey) => {
        dispatch(getNewMessageOnFolderRequest(accountId, akteIdHash, folderId, params, messageKey))
    },
    readMessage: (accountId, unreadMessages, akteIdHash) => {
        for (const m of unreadMessages) {
            dispatch(readMessageRequest(accountId, m.id, akteIdHash))
        }
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(WrapperMessagesComponent)
