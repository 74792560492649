import styled from 'styled-components'

export const ServiceAkteBannerContainer = styled.span`
    display: flex;
    width: 50%;
    flex: 1 1 50%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 20px;
    border: 2px dashed ${p => p.borderColor ? p.borderColor : p.theme.color.color15};
    background-color: ${p => p.backgroundColor ? p.backgroundColor : p.theme.color.color5};
    margin: 10px;
    font-size: ${p => p.theme.fontSize.standard};
    font-weight: ${p => p.theme.fontWeight.bold};
`

export const ServiceAkteBannerText = styled.p`
    color: ${p => p.theme.color.color70};
    font-weight: ${p => p.theme.fontWeight.medium};
    font-size: ${p => p.theme.fontSize.standard};
    margin: 0px;
    text-align: ${p => p.textAlign};
`
export const WaitingScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.5);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 40px;
    align-items: center;
    z-index: ${props => '10' + props.dialogIndex.toString() + '2'};
    overflow: auto;
`