import ACTIONS from 'application/constants'
import default_store from '../../../default_store'

const aktenSidebarReducer = (state = default_store.pages.akten.sidebar, action) => {
    switch (action.type) {
    case ACTIONS.PAGES_AKTEN_CLOSE_AKTE_SIDEBAR: return {
        ...state,
        key: null,
        visible: false,
    }
    case ACTIONS.PAGES_AKTEN_OPEN_AKTE_SIDEBAR: return {
        ...state,
        key: action.key,
        visible: true,
    }
    default:
        return state
    }
}

export default aktenSidebarReducer