import { AKTEN, ROUTES } from 'application/constants'
import React from 'react'
import { useEffect } from 'react'
import { Fragment } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const RedirectDeletedAkteFolder = (props) => {
    const { children, metaMessage, akteIdHash, accountId, setSelectedFolder, setBreadcumData } = props
    let navigate = useNavigate()
    const exist = metaMessage.exist !== undefined && metaMessage.exist
    const messageType = metaMessage && metaMessage.messageType
    const messageFailed = metaMessage && metaMessage.isFailed
    const inFolder = messageType === AKTEN.TYPE.FOLDER
    const linkForDeletedFolder = ROUTES.AKTEN + '/' + akteIdHash + '/accounts/' + accountId
    useEffect(() => {
        metaMessage && !exist && inFolder &&
            setSelectedFolder(null)
    }, [exist, inFolder, metaMessage, setSelectedFolder])
    useEffect(() => {
        metaMessage && !exist && inFolder &&
            setBreadcumData(false, [])
    }, [exist, inFolder, metaMessage, setBreadcumData])
    useEffect(() => {
        metaMessage && !exist && inFolder &&
            navigate(linkForDeletedFolder)
    }, [exist, inFolder, linkForDeletedFolder, metaMessage, navigate])

    if (messageFailed && inFolder) {
        return <Navigate to={linkForDeletedFolder} />
    }
    return <Fragment>
        {children}
    </Fragment>
}

export default RedirectDeletedAkteFolder
