import { connect } from 'react-redux'
import { loginRequest, refresPayloadFailed } from 'application/redux/actions/auth'
import LoginFormDialog from './component'
import { refreshForgotUsername, refreshForgotPassword } from 'application/redux/actions/auth/forgot_credentials'
import { setConsentAccept } from 'application/redux/actions/consent_use_app'
import { closeAllDialogs } from 'application/redux/actions/dialogs'

const mapDispatchToProps = dispatch => {
    return {
        onLoginFormSubmit: (payload) => {
            dispatch(loginRequest(payload.username, payload.password))
        },
        handleLoggedIn: () => {
            dispatch(closeAllDialogs())
        },
        setConsent: (dateAccept) => dispatch(setConsentAccept(dateAccept)),
        refreshForgotUsername: () => dispatch(refreshForgotUsername()),
        refreshForgetPassword: () => dispatch(refreshForgotPassword()),
        refresPayloadFailed: () => dispatch(refresPayloadFailed()),
        closeDialog: () => dispatch(closeAllDialogs())
    }
}

export default connect(
    state => state,
    mapDispatchToProps
)(LoginFormDialog)