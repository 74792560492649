import styled from 'styled-components'

export const StyledOverlayDimmer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 6000;
    background-color: rgba(0, 0, 0, 0.55);
    display: ${props => props.isVisible ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
`

export const StyledOverlayContent = styled.div`
    position: relative;
    width: 97%;
    height: 97%;
    z-index: 6001;
    background-color: ${props => props.theme.color.color0};
    display: flex;
    flex-flow: column nowrap;
	border-radius: 5px;
`

export const StyledHeader = styled.h2`
    margin: 0px;
    align-items: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.headline2};
    font-weight: ${props => props.theme.fontWeight.light};
`

export const StyledSidebarHeaderCaption = styled.div`
    flex: 1;
    overflow: hidden;
`

export const StyledSidebarHeaderCaptionOnQueue = styled.span`
    flex: 1;
    overflow: hidden;
    filter: blur(${props => props.blurred ? '10px' : '0'});
`

export const StyledSidebarHeaderCloseButton = styled.div`
    flex: 0 0 30px;
`

export const StyledSidebarHeaderDownloadButton = styled.div`
    flex: 0 0 30px;
`

export const StyledSendButtonOnQueue = styled.div`
    flex: 0 0 30px;
    padding: 20px;
`

export const StyledHeaderRow = styled.div`
    flex: 0 0 10%;
    max-width: 100%;
`

export const StyledFooterRow = styled.div`
    background: #eeeeee;
    flex: 0 0 5%;
    max-width: 100%;
`

export const StyledContent = styled.div`
    display: flex;
    flex: 2 0 40%;
    overflow: auto;
    width: 100%;
    text-align: center;
`

export const StyledContentonQueue = styled.div`
    display: flex;
    flex: 2 0 40%;
    overflow: auto;
    width: 100%;
    filter: blur(${props => props.blurred ? '10px' : '0'});
    text-align: center;
`