import React from 'react'
import styled from 'styled-components'

const StyledImagePlaceHolderWrapperFixed = styled.div`
    cursor: ${props => props.isClick ? 'pointer' : 'default'};
    margin-right: ${props => props.needSpaceRight ? '8px' : '0px'};
    display: ${props => props.toCenter || props.justifyContent ? 'flex' : 'block'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : props.toCenter ? 'center' : 'unset'};
    width: ${props => props.widthOnFixed !== undefined ? props.widthOnFixed : !props.toCenter ? '80px' : 'unset'};
    height: ${ props => props.heightOnFixed !== undefined ? props.heightOnFixed : !props.toCenter ? '70px' : 'unset'};
    background: ${props => props.isSizeFixed ? `url(${props.backgroundImageUrl})` : 'unset'};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid ${props => props.withBorder && props.isSizeFixed ? props.theme.color.color20 : 'transparent'};
    filter: ${props => props.isLast ? 'blur(2.3px) brightness(0.5) contrast(0.4) grayscale(0.27) saturate(1.6)' : 'unset'};
    border-radius: ${props => props.isCircle ? '100%' : props.radius ? props.radius : 'unset'};
`

const StyledImagePlaceHolderWrapper = styled.div`
    cursor: ${props => props.isClick ? 'pointer' : 'default'};
    margin-right: ${props => props.needSpaceRight ? '8px' : '0px'};
    display: ${props => props.toCenter || props.justifyContent ? 'flex' : 'block'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : props.toCenter ? 'center' : 'unset'};
`

const StyledImagePlaceHolderWrapperHrefFixed = styled.a`
    cursor: pointer;
    text-decoration: none;
    margin-right: ${props => props.needSpaceRight ? '8px' : '0px'};
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: ${props => props.widthOnFixed !== undefined ? props.widthOnFixed : '80px'};
    height:${props => props.heightOnFixed !== undefined ? props.heightOnFixed : '70px'};
    background: ${props => props.isSizeFixed ? `url(${props.backgroundImageUrl})` : 'unset'};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid ${props => props.withBorder && props.isSizeFixed ? props.theme.color.color20 : 'transparent'};
`

const StyledImagePlaceHolderWrapperHref = styled.a`
    cursor: pointer;
    text-decoration: none;
    margin-right: ${props => props.needSpaceRight ? '8px' : '0px'};
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`

const StyledImage = styled.img`
    width: ${props => props.large !== undefined ? props.large : '32px'};
    height: ${props => props.height !== undefined ? props.height : 'auto'};
    border-radius: ${props => props.isCircle ? '100%' : props.radius !== undefined ? props.radius : '0px'};
    border: 1px solid ${props => props.withBorder ? props.theme.color.color20 : 'transparent'};
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`

const ImagePlaceHolder = ({
    isCircle = false,
    large,
    source,
    isClick,
    onClick = () => { },
    withBorder = true,
    toCenter = false,
    altText = '',
    linkHref,
    needSpaceRight,
    isSizeFixed = false,
    backgroundImageUrl,
    heightOnFixed,
    height,
    widthOnFixed,
    radius,
    title = '',
    justifyContent
}) => {
    if (linkHref !== undefined) {
        return <React.Fragment>
            {
                isSizeFixed ?
                    <StyledImagePlaceHolderWrapperHrefFixed justifyContent={justifyContent} withBorder={withBorder} heightOnFixed={heightOnFixed} widthOnFixed={widthOnFixed} isSizeFixed={isSizeFixed} backgroundImageUrl={backgroundImageUrl} needSpaceRight={needSpaceRight} href={linkHref} target='_blank' isClick={isClick} onClick={onClick} /> :
                    <StyledImagePlaceHolderWrapperHref withBorder={withBorder} needSpaceRight={needSpaceRight} href={linkHref} target='_blank' isClick={isClick} onClick={onClick}>
                        <StyledImage radius={radius} withBorder={withBorder} large={large} src={source} title={title} alt={altText} isCircle={isCircle} />
                    </StyledImagePlaceHolderWrapperHref>
            }
        </React.Fragment>
    }
    return <React.Fragment>
        {
            isSizeFixed ? <React.Fragment>
                <StyledImagePlaceHolderWrapperFixed
                    radius={radius}
                    isCircle={isCircle}
                    withBorder={withBorder}
                    heightOnFixed={heightOnFixed}
                    widthOnFixed={widthOnFixed}
                    isSizeFixed={isSizeFixed}
                    backgroundImageUrl={backgroundImageUrl}
                    toCenter={toCenter}
                    needSpaceRight={needSpaceRight}
                    isClick={isClick}
                    justifyContent={justifyContent}
                    onClick={onClick}>
                    
                </StyledImagePlaceHolderWrapperFixed>
            </React.Fragment>
                :
                <StyledImagePlaceHolderWrapper justifyContent={justifyContent} withBorder={withBorder} toCenter={toCenter} needSpaceRight={needSpaceRight} isClick={isClick} onClick={onClick}>
                    <StyledImage height={height} radius={radius} withBorder={withBorder} large={large} src={source} title={title} alt={altText}isCircle={isCircle} />
                </StyledImagePlaceHolderWrapper>
        }
    </React.Fragment>
}

export default ImagePlaceHolder