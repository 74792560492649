export const DIALOG = {
    SIZE: {
        SMALL: 'small',
        MEDIUM: 'medium',
        MEDIUM50: 'medium50',
        LARGE: 'large',
    },
    NAME: {
        SEND_PIN_OPTION: 'SEND_PIN_OPTION',
        UPLOAD_PROFILE_PICTURE: 'UPLOAD_PROFILE_PICTURE',
        DISPLAY_DATENSCHUTZ: 'DISPLAY_DATENSCHUTZ',
        DISPLAY_ACCOUNT_STATEMENT: 'DISPLAY_ACCOUNT_STATEMENT',
        LOGIN_FORM: 'LOGIN_FORM',
		NOTES_PASSWORD_REGISTRATION: 'NOTES_PASSWORD_REGISTRATION',
		ACTIVATE_MODE_MOBILE: 'ACTIVATE_MODE_MOBILE',
        AUTO_REPLY_MESSAGE: 'AUTO_REPLY_MESSAGE',
        SEND_MESSAGE: 'SEND_MESSAGE',
    },
    SCREEN: {
        DESKTOP: 'DESKTOP',
        TABLET: 'TABLET',
        MOBILE: 'MOBILE',
    }
}