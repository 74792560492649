import ACTIONS from 'application/constants'

export const populateMetaInfoAkte = (payload) => ({
    type: ACTIONS.PAGES_AKTEN_POPULATE_META_INFO,
    payload,
})

export const getSingleMessageData = (accountId, akteIdHash) => ({
    type: ACTIONS.PAGES_AKTEN_GET_SINGLE_MESSAGE_DETAILS,
    accountId,
    akteIdHash
})

export const populateSingleAkte = (payload) => ({
    type: ACTIONS.PAGES_AKTEN_POPULATE_SINGLE_AKTE,
    payload
})


export const populateFolders = (payload) => ({
    type: ACTIONS.PAGES_AKTEN_POPULATE_FOLDERS,
    payload
})


export const setSelectedAkte = (payload) => ({
    type: ACTIONS.PAGES_AKTEN_SET_SELECTED_AKTEN,
    payload
})

export const getSingleAkte = (accountId, akteIdHash) => ({
    type: ACTIONS.PAGES_GET_SINGLE_AKTE,
    accountId,
    akteIdHash
})

export const getSingleAkteFailed = () => ({
    type: ACTIONS.PAGES_GET_SINGLE_AKTE_FAILED
})

export const setMessageRaw = (payload) => ({
    type: ACTIONS.PAGES_AKTEN_SET_MESSAGE_RAW,
    payload
})

export const setFoldersView = (condition) => ({
    type: ACTIONS.PAGES_AKTEN_SET_FOLDERS_VIEW,
    condition
})

export const setSelectedFolder = (payload) => ({
    type: ACTIONS.PAGES_AKTEN_SET_SELECTED_FOLDER,
    payload
})

export const setBreadscurmbsView = (condition, initiallyPayload) => ({
    type: ACTIONS.PAGES_AKTEN_SET_BREADCRUMBS_VIEW,
    condition,
    initiallyPayload
})

export const populateBreadscrumbsData = (payload) => ({
    type: ACTIONS.PAGES_AKTEN_POPULATE_BREADCRUMBS_DATA,
    payload
})

export const registerMessageQueue = (messageKey, messageQueueId, payload, metaData) => ({
    type: ACTIONS.PAGES_AKTEN_REGISTER_MESSAGE_QUEUE,
    messageKey,
    messageQueueId,
    payload,
    metaData
})

export const registeredMessageQueueSent = (messageQueueId) => ({
    type: ACTIONS.PAGES_AKTEN_REGISTERED_MESSAGE_QUEUE_SENT,
    messageQueueId
})

export const unregisterMessageQueue = (messageQueueId) => ({
    type: ACTIONS.PAGES_AKTEN_UNREGISTER_MESSAGE_QUEUE,
    messageQueueId
})