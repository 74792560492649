import { ServiceProviderDetailSidebar } from 'application/components/building_blocks/sidebar'
import React from 'react'

const ServiceProviderSidebarComponent = ({ accountDetailsVisible}) => {
    return <React.Fragment>
        {
            accountDetailsVisible && <ServiceProviderDetailSidebar />
        }
    </React.Fragment>
} 

export default ServiceProviderSidebarComponent