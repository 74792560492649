import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export const GalleryReducer = (state = defaultStore.gallery, action) => {
    switch (action.type) {
    case ACTIONS.GALLERY_SHOW_GALLERY:
        return {
            ...state,
            isVisible: true,
            parentId: action.parentId,
            akteIdHash: action.akteIdHash,
            accountId: action.accountId,
            selectedFileId: action.fileId,
            selectedFileName: action.fileName,
            thumbnails: action.thumbnails,
            inAufgabe: action.inAufgabe
        }
    case ACTIONS.GALLERY_SET_ACTIVE_FILE:
        return {
            ...state,
            accountId: action.accountId,
            selectedFileId: action.fileId,
            selectedFileName: action.fileName,
        }
    case ACTIONS.GALLERY_HIDE_GALLERY:
        return {
            ...state,
            isVisible: false,
            accountId: null,
            parentId: null,
            selectedFileId: null,
            selectedFileName: '',
            inAufgabe: false,
            thumbnails: []
        }
    case ACTIONS.GALLERY_SHOW_GALLERY_ON_QUEUE:
        return {
            ...state,
            onQueue: {
                ...state.onQueue,
                isVisible: true,
                parentId: action.parentId,
                accountId: action.accountId,
                selectedFileId: action.fileId,
                selectedFileName: action.fileName,
                selectedData: action.data,
                waitingToShow: false,
            }
        }
    case ACTIONS.PAGES_AKTEN_POPULATE_FILES_ON_QUEUE:
        return {
            ...state,
            onQueue: {
                ...state.onQueue,
                waitingToShow: true,
            }
        }
    case ACTIONS.GALLERY_HIDE_GALLERY_ON_QUEUE:
        return {
            ...state,
            onQueue: {
                ...state.onQueue,
                isVisible: false,
                accountId: null,
                parentId: null,
                selectedFileId: null,
                selectedFileName: '',
                selectedData: null
            }
        }
    case ACTIONS.GALLERY_SET_ACTIVE_FILE_ON_QUEUE:
        return {
            ...state,
            onQueue: {
                ...state.onQueue,
                accountId: action.accountId,
                selectedFileId: action.fileId,
                selectedFileName: action.fileName,
                selectedData: action.data,
                parentId: action.parentId,
                akteIdHash: action.akteIdHash,
            }
        }
    case ACTIONS.GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE:
        return {
            ...state,
            onQueue: {
                ...state.onQueue,
                send: {
                    ...state.onQueue.send,
                    request: true,
                }
            }
        }
    case ACTIONS.GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE_SUCCEED:
        return {
            ...state,
            onQueue: {
                ...state.onQueue,
                send: {
                    ...state.onQueue.send,
                    request: false,
                    succeed: true,
                }
            }
        }
    case ACTIONS.GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE_FAILED:
        return {
            ...state,
            onQueue: {
                ...state.onQueue,
                send: {
                    ...state.onQueue.send,
                    request: false,
                    succeed: false,
                    failed: true
                }
            }
        }
    default:
        return state
    }
}


export default GalleryReducer