import { WIZARD_STEP } from 'application/constants'
import { getAkteMetaRequest } from 'application/redux/actions/akte_meta'
import { updateWizardNext } from 'application/redux/actions/wizard'
import { connect } from 'react-redux'
import WizardRegistrationWelcomeComponent from './component'

const mapStateToProps = state => ({
    wizardData: state.wizard.data,
    wizardLoaded: state.wizard.isLoaded,
    akteMeta: state.akteMeta.payload,
    auth: state.auth,
    akteMetaLoaded: state.akteMeta.isLoaded,
    agbsAndDatenschutzAccepted: state.common.agbDatenschutz.isAccepted,
})

const mapDispatchToProps = dispatch => ({
    onUpdateWizardNext: (current, next) => dispatch(updateWizardNext(current, next, WIZARD_STEP.REGISTRATION.USERNAME)),
    getAkteMetaRequest: (hashUserKey) => dispatch(getAkteMetaRequest(hashUserKey)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardRegistrationWelcomeComponent)