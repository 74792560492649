import { connect } from 'react-redux'
import { loginRequest, refresPayloadFailed } from 'application/redux/actions/auth'
import Login from './component'
import { refreshForgotUsername, refreshForgotPassword } from 'application/redux/actions/auth/forgot_credentials'
import { setConsentAccept } from 'application/redux/actions/consent_use_app'
import { getAccountPublicInfo } from 'application/redux/actions/common/account_details'

const mapStateToProps = (state) => ({
    accountPublicInfo: state.common.accountDetails.publicInfo,
    publicInfoFailed: state.common.accountDetails.publicInfoFailed,
    ...state,
})

const mapDispatchToProps = dispatch => {
    return {
        getAccountPublicInfo: (accountIdentify) => dispatch(getAccountPublicInfo(accountIdentify)),
        onLoginFormSubmit: (payload) => {
            dispatch(loginRequest(payload.username, payload.password))
        },
        setConsent: (dateAccept) => dispatch(setConsentAccept(dateAccept)),
        refreshForgotUsername: () => dispatch(refreshForgotUsername()),
        refreshForgetPassword: () => dispatch(refreshForgotPassword()),
        refresPayloadFailed: () => dispatch(refresPayloadFailed())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)