import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: ${props => props.noOverflow ? 'hidden' : 'auto'};
    background-color: ${p => p.backgroundColor ? p.backgroundColor : p.theme.color.body};
	height: 100%;
`