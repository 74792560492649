export default {
  'dev': {
    accountId: 757508,
    uniqueAccountKey: 'myk-law',
    link: 'https://www.kanzlei-myk.de/',
    hostName: 'app.kanzlei-myk.de',
    assetPath: '/assets/images/myk-law/',
    disableDashboard: {
      redirectTo: 'AKTEN'
    }, 
    disableAccountPage:{
      redirectTo: 'https://www.kanzlei-myk.de/'
    },
    datenschutzLink: 'https://www.kanzlei-myk.de/datenschutz',
    agbLink: 'https://www.kanzlei-myk.de/mandatsbedingungen',
    impressumLink: 'https://www.kanzlei-myk.de/impressum',
    logoLink: 'https://www.kanzlei-myk.de/',
    companyIcon: '/assets/images/myk-law.png',
    companyIconWidth: 160,
    customHeaderTitles:{
      '/page/myk-law/guidance/kontakt': 'Kanzlei MYK - Kontaktformular',
      '/page/myk-law/guidance/fall-melden': 'Kanzlei MYK - Fall melden',
    },
  },

  'prod': {
    accountId: 757508,
    uniqueAccountKey: 'myk-law',
    link: 'https://www.kanzlei-myk.de/',
    hostName: 'app.kanzlei-myk.de',
    assetPath: '/assets/images/myk-law/',
    disableDashboard: {
      redirectTo: 'AKTEN'
    }, 
    disableAccountPage:{
      redirectTo: 'https://www.kanzlei-myk.de/'
    },
    datenschutzLink: 'https://www.kanzlei-myk.de/datenschutz',
    agbLink: 'https://www.kanzlei-myk.de/mandatsbedingungen',
    impressumLink: 'https://www.kanzlei-myk.de/impressum',
    logoLink: 'https://www.kanzlei-myk.de/',
    companyIcon: '/assets/images/myk-law.png',
    companyIconWidth: 160,
    customHeaderTitles:{
      '/page/myk-law/guidance/kontakt': 'Kanzlei MYK - Kontaktformular',
      '/page/myk-law/guidance/fall-melden': 'Kanzlei MYK - Fall melden',
    },


  }
}