import { ROUTES } from 'application/constants'

export const getUnreadMessage = (allAkten) => {
    var count = 0
    for (var i = 0; i < allAkten.length; i++) {
        count = count + allAkten[i].totalUnreadMessage
        count = count + allAkten[i].totalUnreadMessageInFolder
    }

    return count
}

export const getTotalAkten = (allAkten) => {
    return allAkten.length
}
export const getTotalAccounts = (allAccounts) => {
    return allAccounts.length
}

export const getCountIndicator = (allAkten, allAccounts) => {
    return [
        {
            label: 'Akten',
            count: getTotalAkten(allAkten),
            clickable: false,
            to: ''
        },
        {
            label: 'Service Provider',
            count: getTotalAccounts(allAccounts),
            clickable: false,
            to: ''
        },
        {
            label: 'Unread Messages',
            count: getUnreadMessage(allAkten),
            clickable: true,
            to: ROUTES.AKTEN
        },
    ]
}