export const ACTIONS = {
  //auth reducer
  AUTH_UPDATE_USERNAME: 'AUTH_UPDATE_USERNAME',
  AUTH_UPDATE_PASSWORD: 'AUTH_UPDATE_PASSWORD',
  AUTH_LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  AUTH_LOGIN_REQUEST_PIN: 'AUTH_LOGIN_REQUEST_PIN',
  AUTH_LOGIN_WITHOUT_PIN: 'AUTH_LOGIN_WITHOUT_PIN',
  AUTH_ADHOC_ACCESS : 'AUTH_ADHOC_ACCESS',
  AUTH_WRITE_ACCESS_TOKEN: 'AUTH_WRITE_ACCESS_TOKEN',
  AUTH_WRITE_ACCESS_TOKEN_UNREGISTERED: 'AUTH_WRITE_ACCESS_TOKEN_UNREGISTERED',
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILED: 'AUTH_LOGIN_FAILED',
  AUTH_LOGIN_SUCCESS_PIN: 'AUTH_LOGIN_SUCCESS_PIN',
  AUTH_LOGIN_FAILED_PIN: 'AUTH_LOGIN_FAILED_PIN',
  AUTH_LOGOUT_REQUEST: 'AUTH_LOGOUT_REQUEST',
  AUTH_SET_PUSH_TO_DASHBOARD: 'AUTH_SET_PUSH_TO_DASHBOARD',
  AUTH_SET_RETURN_URL: 'AUTH_SET_RETURN_URL',
  AUTH_SHOW_LOGIN_ERROR: 'AUTH_SHOW_LOGIN_ERROR',
  AUTH_REGENERATE: 'AUTH_REGENERATE',
  AUTH_USERDATA_REQUEST: 'AUTH_USERDATA_REQUEST',
  AUTH_POPULATE_TOKEN_DATA: 'AUTH_POPULATE_TOKEN_DATA',
  AUTH_GET_USER_DATA_REQUEST: 'AUTH_GET_USER_DATA_REQUEST',
  AUTH_GET_USER_PROFILE_PICTURE: 'AUTH_GET_USER_PROFILE_PICTURE',
  AUTH_GET_USER_PROFILE_PICTURE_FAILED: 'AUTH_GET_USER_PROFILE_PICTURE_FAILED',
  AUTH_REFRESH_USER_PROFILE_PICTURE: 'AUTH_REFRESH_USER_PROFILE_PICTURE',
  AUTH_POPULATE_USER_PROFILE_PICTURE: 'AUTH_POPULATE_USER_PROFILE_PICTURE',
  AUTH_POPULATE_USER_DATA: 'AUTH_POPULATE_USER_DATA',
  AUTH_SEND_PIN_REQUEST: 'AUTH_SEND_PIN_REQUEST',
  AUTH_SEND_PIN_SUCCEED: 'AUTH_SEND_PIN_SUCCEED',
  AUTH_SEND_PIN_FAILED: 'AUTH_SEND_PIN_FAILED',
  AUTH_RERESH_FORGET_USERNAME: 'AUTH_RERESH_FORGET_USERNAME',
  AUTH_RERESH_FORGET_PASSWORD: 'AUTH_RERESH_FORGET_PASSWORD',
  AUTH_RERESH_PAYLOAD_FAILED: 'AUTH_RERESH_PAYLOAD_FAILED',
  AUTH_FORGET_USERNAME_REQUEST: 'AUTH_FORGET_USERNAME_REQUEST',
  AUTH_FORGET_USERNAME_SUCCEED: 'AUTH_FORGET_USERNAME_SUCCEED',
  AUTH_FORGET_USERNAME_FAILED: 'AUTH_FORGET_USERNAME_FAILED',
  AUTH_FORGET_PASSWORD_REQUEST: 'AUTH_FORGET_PASSWORD_REQUEST',
  AUTH_FORGET_PASSWORD_SUCCEED: 'AUTH_FORGET_PASSWORD_SUCCEED',
  AUTH_FORGET_PASSWORD_FAILED: 'AUTH_FORGET_PASSWORD_FAILED',
  AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD:
    'AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD',
  AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD_SUCCEED:
    'AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD_SUCCEED',
  AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD_FAILED:
    'AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD_FAILED',
  AUTH_FORGET_CREDENTIAL_SHOW_ERROR: 'AUTH_FORGET_CREDENTIAL_SHOW_ERROR',
  AUTH_HANDLE_PUSH: 'AUTH_HANDLE_PUSH',

  //debounce
  DEBOUNCE_REQUEST: 'DEBOUNCE_REQUEST',

  //attchments
  FILES_GET_THUMBNAIL_REQUEST: 'FILES_GET_THUMBNAIL_REQUEST',
  FILES_REGISTER_THUMBNAIL: 'FILES_REGISTER_THUMBNAIL',
  FILES_GET_FULL_FILE_REQUEST: 'FILES_GET_FULL_FILE_REQUEST',
  FILES_REGISTER_FULL_FILE: 'FILES_REGISTER_FULL_FILE',
  FILES_POPULATE_THUMBNAIL: 'FILES_POPULATE_THUMBNAIL',
  FILES_POPULATE_THUMBNAIL_FAILED: 'FILES_POPULATE_THUMBNAIL_FAILED',
  FILES_POPULATE_FULL_FILE: 'FILES_POPULATE_FULL_FILE',
  FILES_POPULATE_FULL_FILE_FAILED: 'FILES_POPULATE_FULL_FILE_FAILED',
  FILES_GET_META_REQUEST: 'FILES_GET_META_REQUEST',
  FILES_POPULATE_META: 'FILES_POPULATE_META',
  FILES_GET_META_MESSAGE_REQUEST: 'FILES_GET_META_MESSAGE_REQUEST',
  FILES_DELETE_FILE: 'FILES_DELETE_FILE',
  FILES_PUT_PICTURE: 'FILES_PUT_PICTURE',
  FILES_SET_PICTURE: 'FILES_SET_PICTURE',
  FILES_CROPPING_IMAGE: 'FILES_CROPPING_IMAGE',
  FILES_POST_FILES_REQUEST: 'FILES_POST_FILES_REQUEST',
  FILES_POST_FILES_SUCCEED: 'FILES_POST_FILES_SUCCEED',
  FILES_POST_FILES_FAILED: 'FILES_POST_FILES_FAILED',
  DOWNLOAD_FILE_BY_ID_REQUEST: 'DOWNLOAD_FILE_BY_ID_REQUEST',
  DOWNLOAD_FILE_BY_ID_ADHOC_REQUEST: 'DOWNLOAD_FILE_BY_ID_ADHOC_REQUEST',
  DOWNLOAD_FILE_MESSAGE_BY_ID_REQUEST: 'DOWNLOAD_FILE_MESSAGE_BY_ID_REQUEST',
  DOCUMENTS_DOWNLOAD_SCHADEN_DOCUMENTS: 'DOCUMENTS_DOWNLOAD_SCHADEN_DOCUMENTS',
  DOCUMENTS_DOWNLOAD_TASK_DOCUMENTS: 'DOCUMENTS_DOWNLOAD_TASK_DOCUMENTS',

  //notification
  NOTIFICATIONS_SHOW_TOAST: 'NOTIFICATIONS_SHOW_TOAST',

  //dialogs
  DIALOG_OPEN_DIALOG_BY_NAME: 'DIALOG_OPEN_DIALOG_BY_NAME',
  DIALOG_CLOSE_DIALOG_BY_NAME: 'DIALOG_CLOSE_DIALOG_BY_NAME',
  DIALOG_CLOSE_ALL: 'DIALOG_CLOSE_ALL',
  DIALOG_POPULATE_DIALOG_CONTENT_BY_NAME:
    'DIALOG_POPULATE_DIALOG_CONTENT_BY_NAME',
  DIALOG_SUCCEED_LOADED: 'DIALOG_SUCCEED_LOADED',
  DIALOG_UPDATE_DIALOG_DATA_BY_NAME: 'DIALOG_UPDATE_DIALOG_DATA_BY_NAME',
  DIALOG_SEND_DIALOG_DATA_BY_NAME: 'DIALOG_SEND_DIALOG_DATA_BY_NAME',
  DIALOG_REQUEST_DIALOG_DATA: 'DIALOG_REQUEST_DIALOG_DATA',

  //akte
  AKTE_META_DATA_REQUEST: 'AKTE_META_DATA_REQUEST',
  AKTE_META_DATA_POPULATE: 'AKTE_META_DATA_POPULATE',
  AKTE_META_DATA_FAILED: 'AKTE_META_DATA_FAILED',

  //wizards
  WIZARD_GET_DATA_REQUEST: 'WIZARD_GET_DATA_REQUEST',
  WIZARD_POPULATE_DATA: 'WIZARD_POPULATE_DATA',
  WIZARD_POPULATE_REGISTRATION_KEY: 'WIZARD_POPULATE_REGISTRATION_KEY',
  WIZARD_UPDATED_DATA_SUCCEED: 'WIZARD_UPDATED_DATA_SUCCEED',
  WIZARD_UPDATE_DATA_NEXT: 'WIZARD_UPDATE_DATA_NEXT',
  WIZARD_UPDATE_DATA_PREV: 'WIZARD_UPDATE_DATA_PREV',
  WIZARD_UPDATE_DATA_PUSH_BACK: 'WIZARD_UPDATE_DATA_PUSH_BACK',
  WIZARD_UPDATE_DATA_ALL_DONE: 'WIZARD_UPDATE_DATA_ALL_DONE',
  WIZARD_CHECK_USERNAME: 'WIZARD_CHECK_USERNAME',
  WIZARD_POPULATE_USERNAME: 'WIZARD_POPULATE_USERNAME',
  WIZARD_USERNAME_NOT_VALID: 'WIZARD_USERNAME_NOT_VALID',
  WIZARD_POPULATE_PASSWORD: 'WIZARD_POPULATE_PASSWORD',
  WIZARD_REGISTRATION_REQUEST: 'WIZARD_REGISTRATION_REQUEST',
  WIZARD_REGISTRATION_COMPLETE: 'WIZARD_REGISTRATION_COMPLETE',
  WIZARD_WRITE_ERROR_MESSAGE: 'WIZARD_WRITE_ERROR_MESSAGE',
  WIZARD_REGISTRATION_FAILED: 'WIZARD_REGISTRATION_FAILED',

  //waiting screen
  WAITING_SCREEN_SHOW: 'WAITING_SCREEN_SHOW',
  WAITING_SCREEN_HIDE: 'WAITING_SCREEN_HIDE',
  WAITING_SCREEN_ADD_NEW_SUB_MESSAGE: 'WAITING_SCREEN_ADD_NEW_SUB_MESSAGE',
  WAITING_SCREEN_ADD_TO_LAST_SUB_MESSAGE:
    'WAITING_SCREEN_ADD_TO_LAST_SUB_MESSAGE',

  //common
  COMMON_ALL_ACCOUNTS_REQUEST_DATA: 'COMMON_ALL_ACCOUNTS_REQUEST_DATA',
  COMMON_ALL_ACCOUNTS_POPULATE_DATA: 'COMMON_ALL_ACCOUNTS_POPULATE_DATA',
  COMMON_GET_ALL_ACCOUNTS_FAILED: 'COMMON_GET_ALL_ACCOUNTS_FAILED',
  COMMON_ALL_ACCOUNTS_UPDATE_DATA_BY_ID:
    'COMMON_ALL_ACCOUNTS_UPDATE_DATA_BY_ID',
  COMMON_REFRESH_ALL_ACCOUNTS_DATA: 'COMMON_REFRESH_ALL_ACCOUNTS_DATA',
  COMMON_GET_MAIN_LOGO_COMPANY_REQUEST: 'COMMON_GET_MAIN_LOGO_COMPANY_REQUEST',
  COMMON_POPULATE_MAIN_LOGO_COMPANY: 'COMMON_POPULATE_MAIN_LOGO_COMPANY',
  COMMON_COMPANY_HAS_NO_MAIN_LOGO: 'COMMON_COMPANY_HAS_NO_MAIN_LOGO',
  COMMON_GET_SECONDARY_LOGO_COMPANY_REQUEST:
    'COMMON_GET_SECONDARY_LOGO_COMPANY_REQUEST',
  COMMON_POPULATE_SECONDARY_LOGO_COMPANY:
    'COMMON_POPULATE_SECONDARY_LOGO_COMPANY',
  COMMON_COMPANY_HAS_NO_SECONDARY_LOGO: 'COMMON_COMPANY_HAS_NO_SECONDARY_LOGO',
  COMMON_POPULATE_TOTAL_UNREAD_MESSAGE_EACH_ACCOUNT:
    'COMMON_POPULATE_TOTAL_UNREAD_MESSAGE_EACH_ACCOUNT',
  COMMON_ALL_AKTEN_REQUEST_DATA: 'COMMON_ALL_AKTEN_REQUEST_DATA',
  COMMON_ALL_AKTEN_IN_ONE_ACCOUNT_REQUEST_DATA:
    'COMMON_ALL_AKTEN_IN_ONE_ACCOUNT_REQUEST_DATA',
  COMMON_ALL_AKTEN_UPDATE_DATA: 'COMMON_ALL_AKTEN_UPDATE_DATA',
  COMMON_ALL_AKTEN_UPDATE_DATA_FAILED: 'COMMON_ALL_AKTEN_UPDATE_DATA_FAILED',
  COMMON_ALL_AKTEN_POPULATE_DATA: 'COMMON_ALL_AKTEN_POPULATE_DATA',
  COMMON_ALL_AKTEN_UNREGISTER_AKTE: 'COMMON_ALL_AKTEN_UNREGISTER_AKTE',
  COMMON_GET_ALL_AKTEN_FAILED: 'COMMON_GET_ALL_AKTEN_FAILED',
  COMMON_ALL_AKTEN_IN_ONE_ACCOUNT_POPULATE_DATA:
    'COMMON_ALL_AKTEN_IN_ONE_ACCOUNT_POPULATE_DATA',
  COMMON_UPDATE_SINGLE_AKTE: 'COMMON_UPDATE_SINGLE_AKTE',
  COMMON_ALL_AKTEN_PUT_LAST_MESSAGE: 'COMMON_ALL_AKTEN_PUT_LAST_MESSAGE',
  COMMON_REFRESH_ALL_AKTEN_DATA: 'COMMON_REFRESH_ALL_AKTEN_DATA',
  COMMON_RECOUNT_UNREAD_MESSAGE: 'COMMON_RECOUNT_UNREAD_MESSAGE',
  COMMON_RECOUNT_UNREAD_MESSAGE_IN_FOLDER:
    'COMMON_RECOUNT_UNREAD_MESSAGE_IN_FOLDER',
  COMMON_GET_CREATOR_PROFILE_PICTURE_REQUEST:
    'COMMON_GET_CREATOR_PROFILE_PICTURE_REQUEST',
  COMMON_REGISTER_CREATOR_PROFILE_PICTURE:
    'COMMON_REGISTER_CREATOR_PROFILE_PICTURE',
  COMMON_POPULATE_CREATOR_PROFILE_PICTURE:
    'COMMON_POPULATE_CREATOR_PROFILE_PICTURE',
  COMMON_CREATOR_PROFILE_PICTURE_HAS_NO_DATA:
    'COMMON_CREATOR_PROFILE_PICTURE_HAS_NO_DATA',
  COMMON_SET_SELECTED_ACCOUNTS_DETAILS: 'COMMON_SET_SELECTED_ACCOUNTS_DETAILS',
  COMMON_UPDATE_SELECTED_ACCOUNTS_DETAILS:
    'COMMON_UPDATE_SELECTED_ACCOUNTS_DETAILS',
  COMMON_GET_ACCOUNT_PUBLIC_INFO: 'COMMON_GET_ACCOUNT_PUBLIC_INFO',
  COMMON_SET_ACCOUNT_PUBLIC_INFO: 'COMMON_SET_ACCOUNT_PUBLIC_INFO',
  COMMON_REFRESH_ACCOUNT_PUBLIC_INFO: 'COMMON_REFRESH_ACCOUNT_PUBLIC_INFO',
  COMMON_GET_ACCOUNT_PUBLIC_INFO_FAILED:
    'COMMON_GET_ACCOUNT_PUBLIC_INFO_FAILED',
  COMMON_CLOSE_ACCOUNTS_DETAILS: 'COMMON_CLOSE_ACCOUNTS_DETAILS',
  COMMON_ACTIVATE_ACCOUNT_REQUEST: 'COMMON_ACTIVATE_ACCOUNT_REQUEST',
  COMMON_ACTIVATE_ACCOUNT_SUCCEED: 'COMMON_ACTIVATE_ACCOUNT_SUCCEED',
  COMMON_ACTIVATE_ACCOUNT_FAILED: 'COMMON_ACTIVATE_ACCOUNT_FAILED',
  COMMON_SET_SEARCH_TERM_AKTEN: 'COMMON_SET_SEARCH_TERM_AKTEN',
  COMMON_ON_AGB_DATENSCHUTZ_ACCEPT: 'COMMON_ON_AGB_DATENSCHUTZ_ACCEPT',
  COMMON_ON_DATENSHUTZ_REQUEST: 'COMMON_ON_DATENSHUTZ_REQUEST',
  COMMON_ON_DATENSHUTZ_FAILED: 'COMMON_ON_DATENSHUTZ_FAILED',
  COMMON_POPULATE_DATENSCHUTZ_ACCOUNT: 'COMMON_POPULATE_DATENSCHUTZ_ACCOUNT',
  COMMON_ON_AGB_DOWNLOAD: 'COMMON_ON_AGB_DOWNLOAD',
  COMMON_ON_AGB_DOWNLOAD_SUCCEED: 'COMMON_ON_AGB_DOWNLOAD_SUCCEED',
  COMMON_ON_AGB_DOWNLOAD_FAILED: 'COMMON_ON_AGB_DOWNLOAD_FAILED',
  //common account public info internal assets
  COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REGISTER_ACCOUNT:
    'COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REGISTER_ACCOUNT',
  COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_UNREGISTER_ACCOUNT:
    'COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_UNREGISTER_ACCOUNT',
  COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA:
    'COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA',
  COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA_SUCCEED:
    'COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA_SUCCEED',
  COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA_FAILED:
    'COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA_FAILED',
  COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_POPULATE_DATA_BY_ACCOUNT_ID:
    'COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_POPULATE_DATA_BY_ACCOUNT_ID',
  COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_GET_DATA_IN_STORE:
    'COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_GET_DATA_IN_STORE',

  //messages
  MESSAGES_REGISTER_MESSAGES: 'MESSAGES_REGISTER_MESSAGES',
  MESSAGES_REGISTER_ROOT_MESSAGES: 'MESSAGES_REGISTER_ROOT_MESSAGES',
  MESSAGES_REGISTER_FOLDERS_MESSAGE: 'MESSAGES_REGISTER_FOLDERS_MESSAGE',
  MESSAGES_UNREGISTER_MESSAGES: 'MESSAGES_UNREGISTER_MESSAGES',
  MESSAGES_REFRESH_REGISTER_MESSAGES: 'MESSAGES_REFRESH_REGISTER_MESSAGES',
  MESSAGES_REGISTERED_MESSAGES_FULLY_LOADED:
    'MESSAGES_REGISTERED_MESSAGES_FULLY_LOADED',
  MESSAGES_REGISTERED_MESSAGES_FAILED: 'MESSAGES_REGISTERED_MESSAGES_FAILED',
  MESSAGES_READ_MESSAGE: 'MESSAGES_READ_MESSAGE',
  MESSAGES_UPDATE_MESSAGES: 'MESSAGES_UPDATE_MESSAGES',
  MESSAGES_RECOUNT_UNREAD_MESSAGE_IN_FOLDER_BY_MESSAGE_KEY:
    'MESSAGES_RECOUNT_UNREAD_MESSAGE_IN_FOLDER_BY_MESSAGE_KEY',
  MESSAGES_UPDATE_DATA_IN_FOLDER_BY_MESSAGE_KEY:
    'MESSAGES_UPDATE_DATA_IN_FOLDER_BY_MESSAGE_KEY',
  MESSAGES_PUT_MESSAGE_AS_FAVORITE: 'MESSAGES_PUT_MESSAGE_AS_FAVORITE',
  MESSAGES_REGISTERED_ROOT_MESSAGES_FULLY_LOADED:
    'MESSAGES_REGISTERED_ROOT_MESSAGES_FULLY_LOADED',
  MESSAGES_REGISTERED_MESSAGES_FOLDERS_LOADED:
    'MESSAGES_REGISTERED_MESSAGES_FOLDERS_LOADED',
  MESSAGES_AKTEN_UPDATE_SELECTED_MESSAGE_ROOT:
    'MESSAGES_AKTEN_UPDATE_SELECTED_MESSAGE_ROOT',
  MESSAGES_AKTEN_UPDATE_SELECTED_MESSAGE_FOLDER:
    'MESSAGES_AKTEN_UPDATE_SELECTED_MESSAGE_FOLDER',
  MESSAGES_AKTEN_GET_FAVORITE_MESSAGES: 'MESSAGES_AKTEN_GET_FAVORITE_MESSAGES',
  MESSAGES_AKTEN_POPULATE_FAVORITE_MESSAGES:
    'MESSAGES_AKTEN_POPULATE_FAVORITE_MESSAGES',
  MESSAGES_AKTEN_GET_FAVORITE_MESSAGES_FAILED:
    'MESSAGES_AKTEN_GET_FAVORITE_MESSAGES_FAILED',
  MESSAGES_AKTEN_REFRESH_FAVORITE_MESSAGES:
    'MESSAGES_AKTEN_REFRESH_FAVORITE_MESSAGES',
  MESSAGES_AKTEN_SET_PAYLOAD_FAVORITE_MESSAGES:
    'MESSAGES_AKTEN_SET_PAYLOAD_FAVORITE_MESSAGES',
  MESSAGES_AKTEN_DELETE_PAYLOAD_FAVORITE_MESSAGES:
    'MESSAGES_AKTEN_DELETE_PAYLOAD_FAVORITE_MESSAGES',

  //pages

  //settings about
  PAGES_SETTINGS_ABOUT_FORM_REQUEST: 'PAGES_SETTINGS_ABOUT_FORM_REQUEST',
  PAGES_SETTINGS_ABOUT_FORM_SUCCEED: 'PAGES_SETTINGS_ABOUT_FORM_SUCCEED',
  PAGES_SETTINGS_ABOUT_FORM_FAILED: 'PAGES_SETTINGS_ABOUT_FORM_FAILED',

  //settings account
  PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_REQUEST:
    'PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_REQUEST',
  PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_SUCCEED:
    'PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_SUCCEED',
  PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_FAILED:
    'PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_FAILED',

  //akten
  PAGES_AKTEN_SET_SELECTED_AKTEN: 'PAGES_AKTEN_SET_SELECTED_AKTEN',
  PAGES_GET_SINGLE_AKTE: 'PAGES_GET_SINGLE_AKTE',
  PAGES_GET_SINGLE_AKTE_FAILED: 'PAGES_GET_SINGLE_AKTE_FAILED',
  PAGES_AKTEN_SET_MESSAGE_RAW: 'PAGES_AKTEN_SET_MESSAGE_RAW',
  PAGES_AKTEN_SET_FOLDERS_VIEW: 'PAGES_AKTEN_SET_FOLDERS_VIEW',
  PAGES_AKTEN_SET_SELECTED_FOLDER: 'PAGES_AKTEN_SET_SELECTED_FOLDER',
  PAGES_AKTEN_SET_BREADCRUMBS_VIEW: 'PAGES_AKTEN_SET_BREADCRUMBS_VIEW',
  PAGES_AKTEN_POPULATE_BREADCRUMBS_DATA:
    'PAGES_AKTEN_POPULATE_BREADCRUMBS_DATA',
  PAGES_AKTEN_REGISTER_MESSAGE_QUEUE: 'PAGES_AKTEN_REGISTER_MESSAGE_QUEUE',
  PAGES_AKTEN_REGISTERED_MESSAGE_QUEUE_SENT:
    'PAGES_AKTEN_REGISTERED_MESSAGE_QUEUE_SENT',
  PAGES_AKTEN_UNREGISTER_MESSAGE_QUEUE: 'PAGES_AKTEN_UNREGISTER_MESSAGE_QUEUE',
  PAGES_AKTEN_REFRESH_MESSAGE_ON_ROOT: 'PAGES_AKTEN_REFRESH_MESSAGE_ON_ROOT',
  PAGES_AKTEN_REFRESH_MESSAGE_ON_FOLDER:
    'PAGES_AKTEN_REFRESH_MESSAGE_ON_FOLDER',
  PAGES_AKTEN_GET_MESSAGE_DETAILS: 'PAGES_AKTEN_GET_MESSAGE_DETAILS',
  PAGES_AKTEN_GET_AUFGABEN: 'PAGES_AKTEN_GET_AUFGABEN',
  PAGES_AKTEN_GET_AUFGABEN_ON_FOLDER: 'PAGES_AKTEN_GET_AUFGABEN_ON_FOLDER',
  PAGES_AKTEN_GET_MESSAGE_DETAILS_FAILED:
    'PAGES_AKTEN_GET_MESSAGE_DETAILS_FAILED',
  PAGES_AKTEN_GET_SINGLE_MESSAGE_DETAILS:
    'PAGES_AKTEN_GET_SINGLE_MESSAGE_DETAILS',
  PAGES_AKTEN_GET_MESSAGE_DETAILS_ON_FOLDER:
    'PAGES_AKTEN_GET_MESSAGE_DETAILS_ON_FOLDER',
  PAGES_AKTEN_POPULATE_SINGLE_AKTE: 'PAGES_AKTEN_POPULATE_SINGLE_AKTE',
  PAGES_AKTEN_OPEN_AKTE_SIDEBAR: 'PAGES_AKTEN_OPEN_AKTE_SIDEBAR',
  PAGES_AKTEN_DOWNLOAD_AKTE_ZIP: 'PAGES_AKTEN_DOWNLOAD_AKTE_ZIP',
  PAGES_AKTEN_DOWNLOAD_AKTE_ZIP_SUCCEED:
    'PAGES_AKTEN_DOWNLOAD_AKTE_ZIP_SUCCEED',
  PAGES_AKTEN_DOWNLOAD_AKTE_ZIP_FAILED: 'PAGES_AKTEN_DOWNLOAD_AKTE_ZIP_FAILED',
  PAGES_AKTEN_POPULATE_FILES_ON_QUEUE: 'PAGES_AKTEN_POPULATE_FILES_ON_QUEUE',
  PAGES_AKTEN_POPULATE_FILES_TO_SEND: 'PAGES_AKTEN_POPULATE_FILES_TO_SEND',
  PAGES_AKTEN_CLOSE_AKTE_SIDEBAR: 'PAGES_AKTEN_CLOSE_AKTE_SIDEBAR',
  PAGES_AKTEN_POPULATE_FOLDERS: 'PAGES_AKTEN_POPULATE_FOLDERS',
  PAGES_AKTEN_POPULATE_META_INFO: 'PAGES_AKTEN_POPULATE_META_INFO',
  PAGES_AKTEN_POPULATE_FAVORITE_MESSAGES:
    'PAGES_AKTEN_POPULATE_FAVORITE_MESSAGES',
  PAGES_AKTEN_POPULATE_MESSAGES: 'PAGES_AKTEN_POPULATE_MESSAGES',
  PAGES_AKTEN_POPULATE_MESSAGES_ON_FOLDER:
    'PAGES_AKTEN_POPULATE_MESSAGES_ON_FOLDER',
  PAGES_AKTEN_REFRESH_MESSAGES_DETAILS: 'PAGES_AKTEN_REFRESH_MESSAGES_DETAILS',
  PAGES_AKTEN_DELETE_FILE_ON_QUEUE_BY_ID:
    'PAGES_AKTEN_DELETE_FILE_ON_QUEUE_BY_ID',
  PAGES_AKTEN_DELETE_FILE_TO_SEND_BY_ID:
    'PAGES_AKTEN_DELETE_FILE_TO_SEND_BY_ID',
  PAGES_AKTEN_SEND_MESSAGE_REQUEST: 'PAGES_AKTEN_SEND_MESSAGE_REQUEST',
  PAGES_AKTEN_PUT_MESSAGE_ON_QUEUE: 'PAGES_AKTEN_PUT_MESSAGE_ON_QUEUE',
  PAGES_AKTEN_POP_MESSAGE_ON_QUEUE: 'PAGES_AKTEN_POP_MESSAGE_ON_QUEUE',
  PAGES_AKTEN_REPLACE_MESSAGE_QUEUE_BY_ID:
    'PAGES_AKTEN_REPLACE_MESSAGE_QUEUE_BY_ID',
  PAGES_AKTEN_MESSAGE_DELIVERED: 'PAGES_AKTEN_MESSAGE_DELIVERED',
  PAGES_AKTEN_SEND_MESSAGE_SUCCEED: 'PAGES_AKTEN_SEND_MESSAGE_SUCCEED',
  PAGES_AKTEN_SEND_MESSAGE_FAILED: 'PAGES_AKTEN_SEND_MESSAGE_FAILED',
  PAGES_AKTEN_READ_MESSAGE_REQUEST: 'PAGES_AKTEN_READ_MESSAGE_REQUEST',
  PAGES_AKTEN_MESSAGE_READ_CONDITION: 'PAGES_AKTEN_MESSAGE_READ_CONDITION',
  PAGES_AKTEN_READ_MESSAGE_SUCCEED: 'PAGES_AKTEN_READ_MESSAGE_SUCCEED',
  PAGES_AKTEN_READ_MESSAGE_FAILED: 'PAGES_AKTEN_READ_MESSAGE_FAILED',
  PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_REQUEST:
    'PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_REQUEST',
  PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_SUCCEED:
    'PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_SUCCEED',
  PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_FAILED:
    'PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_FAILED',
  PAGES_AKTEN_MARK_FAVORITE_MESSAGE_REQUEST:
    'PAGES_AKTEN_MARK_FAVORITE_MESSAGE_REQUEST',
  PAGES_AKTEN_MARK_FAVORITE_MESSAGE_SUCCEED:
    'PAGES_AKTEN_MARK_FAVORITE_MESSAGE_SUCCEED',
  PAGES_AKTEN_MARK_FAVORITE_MESSAGE_FAILED:
    'PAGES_AKTEN_MARK_FAVORITE_MESSAGE_FAILED',
  PAGES_AKTEN_PUT_MESSAGE_AS_FAVORITE: 'PAGES_AKTEN_PUT_MESSAGE_AS_FAVORITE',
  PAGES_AKTEN_NEW_MESSAGE_REQUEST: 'PAGES_AKTEN_NEW_MESSAGE_REQUEST',
  PAGES_AKTEN_NEW_MESSAGE_SUCCEED: 'PAGES_AKTEN_NEW_MESSAGE_SUCCEED',
  PAGES_AKTEN_NEW_MESSAGE_FAILED: 'PAGES_AKTEN_NEW_MESSAGE_FAILED',
  PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_REQUEST:
    'PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_REQUEST',
  PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_SUCCEED:
    'PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_SUCCEED',
  PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_FAILED:
    'PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_FAILED',
  PAGES_AKTEN_NEW_FOLDERS_REQUEST: 'PAGES_AKTEN_NEW_FOLDERS_REQUEST',
  PAGES_AKTEN_REFRESH_NEW_MESSAGES: 'PAGES_AKTEN_REFRESH_NEW_MESSAGES',
  PAGES_AKTEN_REFRESH_NEW_MESSAGES_ON_FOLDER:
    'PAGES_AKTEN_REFRESH_NEW_MESSAGES_ON_FOLDER',
  PAGES_AKTEN_NEW_FILES_REQUEST: 'PAGES_AKTEN_NEW_FILES_REQUEST',
  PAGES_AKTEN_NEW_FILES_SUCCEED: 'PAGES_AKTEN_NEW_FILES_SUCCEED',
  PAGES_AKTEN_NEW_FILES_FAILED: 'PAGES_AKTEN_NEW_FILES_FAILED',
  PAGES_AKTEN_REFRESH_NEW_FILES: 'PAGES_AKTEN_REFRESH_NEW_FILES',
  PAGES_AKTEN_DOWNLOAD_MESSAGE_REQUEST: 'PAGES_AKTEN_DOWNLOAD_MESSAGE_REQUEST',
  PAGES_AKTEN_DOWNLOAD_MESSAGE_SUCCEED: 'PAGES_AKTEN_DOWNLOAD_MESSAGE_SUCCEED',
  PAGES_AKTEN_DOWNLOAD_MESSAGE_FAILED: 'PAGES_AKTEN_DOWNLOAD_MESSAGE_FAILED',
  PAGES_AKTEN_ADD_FILES_ON_QUEUE: 'PAGES_AKTEN_ADD_FILES_ON_QUEUE',
  PAGES_AKTEN_CLEAR_ALL_FILES_TO_SEND: 'PAGES_AKTEN_CLEAR_ALL_FILES_TO_SEND',
  PAGES_AKTEN_CLEAR_ALL_FILES_ON_QUEUE: 'PAGES_AKTEN_CLEAR_ALL_FILES_ON_QUEUE',

  //gallery
  GALLERY_SHOW_GALLERY: 'GALLERY_SHOW_GALLERY',
  GALLERY_HIDE_GALLERY: 'GALLERY_HIDE_GALLERY',
  GALLERY_SET_ACTIVE_FILE: 'GALLERY_SET_ACTIVE_FILE',
  GALLERY_SHOW_GALLERY_ON_QUEUE: 'GALLERY_SHOW_GALLERY_ON_QUEUE',
  GALLERY_HIDE_GALLERY_ON_QUEUE: 'GALLERY_HIDE_GALLERY_ON_QUEUE',
  GALLERY_SET_ACTIVE_FILE_ON_QUEUE: 'GALLERY_SET_ACTIVE_FILE_ON_QUEUE',
  GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE:
    'GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE',
  GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE_SUCCEED:
    'GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE_SUCCEED',
  GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE_FAILED:
    'GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE_FAILED',

  //consent
  CONSENT_SET_ACCEPT: 'CONSENT_SET_ACCEPT',

  //form mobil assistent
  FORM_GET_FORM_ELEMENTS_REQUEST: 'FORM_GET_FORM_ELEMENTS_REQUEST',
  FORM_GET_FORM_ELEMENTS_REFRESH: 'FORM_GET_FORM_ELEMENTS_REFRESH',
  FORM_GET_FORM_ELEMENTS_SUCCEED: 'FORM_GET_FORM_ELEMENTS_SUCCEED',
  FORM_GET_FORM_ELEMENTS_FAILED: 'FORM_GET_FORM_ELEMENTS_FAILED',
  FORM_GET_FORM_ELEMENTS_REDIRECTED: 'FORM_GET_FORM_ELEMENTS_REDIRECTED',
  FORM_GET_FORM_ELEMENTS_REQUEST_WITH_AUTH:
    'FORM_GET_FORM_ELEMENTS_REQUEST_WITH_AUTH',
  FORM_GET_FORM_ELEMENTS_SUCCEED_WITH_AUTH:
    'FORM_GET_FORM_ELEMENTS_SUCCEED_WITH_AUTH',
  FORM_GET_FORM_ELEMENTS_FAILED_WITH_AUTH:
    'FORM_GET_FORM_ELEMENTS_FAILED_WITH_AUTH',
  FORM_POPULATE_FORM_METADATA: 'FORM_POPULATE_FORM_METADATA',
  FORM_REFRESH_FORM_ELEMENTS: 'FORM_REFRESH_FORM_ELEMENTS',
  FORM_POPULATE_FORM_ELEMENTS: 'FORM_POPULATE_FORM_ELEMENTS',
  FORM_SET_THEME: 'FORM_SET_THEME',
  FORM_WITH_PIN: 'FORM_WITH_PIN',
  FORM_AUTHENTICATED: 'FORM_AUTHENTICATED',
  FORM_LOGIN_ATTEMPT: 'FORM_LOGIN_ATTEMPT',
  FORM_SEND_REQUEST: 'FORM_SEND_REQUEST',
  FORM_SEND_SUCCEED: 'FORM_SEND_SUCCEED',
  FORM_SEND_FAILED: 'FORM_SEND_FAILED',
  FORM_SEND_REFRESH: 'FORM_SEND_REFRESH',
  FORM_UPDATE_UPLOAD_IDS: 'FORM_UPDATE_UPLOAD_IDS',
  FORM_UPDATE_UPLOAD_IDS_INPUT_FORM: 'FORM_UPDATE_UPLOAD_IDS_INPUT_FORM',
  FORM_UPDATE_RESULT_TEXT: 'FORM_UPDATE_RESULT_TEXT',
  FORM_SET_RETURN_URL: 'FORM_SET_RETURN_URL',
  FORM_REFRESH_RETURN_URL: 'FORM_REFRESH_RETURN_URL',

  //sidebars
  SIDEBAR_OPEN_AKTEN: 'SIDEBAR_OPEN_AKTEN',
  SIDEBAR_CLOSE_AKTEN: 'SIDEBAR_CLOSE_AKTEN',
  SIDEBAR_OPEN_PROVIDER: 'SIDEBAR_OPEN_PROVIDER',
  SIDEBAR_OPEN_NAVIGATION_LINK_MOBILE: 'SIDEBAR_OPEN_NAVIGATION_LINK_MOBILE',
  SIDEBAR_CLOSE_PROVIDER: 'SIDEBAR_CLOSE_PROVIDER',
  SIDEBAR_CLOSE_NAVIGATION_LINK_MOBILE: 'SIDEBAR_CLOSE_NAVIGATION_LINK_MOBILE',
  SIDEBAR_SET_DIALOG_VISIBLE_PROVIDER: 'SIDEBAR_SET_DIALOG_VISIBLE_PROVIDER',
  SIDEBAR_SET_DIALOG_NAVIGATION_LINK_MOBILE:
    'SIDEBAR_SET_DIALOG_NAVIGATION_LINK_MOBILE',
  SIDEBAR_OPEN_CONTACT_INFO: 'SIDEBAR_OPEN_CONTACT_INFO',
  SIDEBAR_CLOSE_CONTACT_INFO: 'SIDEBAR_CLOSE_CONTACT_INFO',
  SIDEBAR_CLOSE_ALL_SIDEBARS: 'SIDEBAR_CLOSE_ALL_SIDEBARS',
}
