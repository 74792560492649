import React, { useEffect } from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import * as Fragments from './fragments'
import { useTranslation } from 'react-i18next'
import { SimpleUploader } from 'application/components/controls/form_elements'
import FontIcon from 'application/components/controls/font_icon'
import FileName from 'application/components/controls/file_name'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { createMessageMapper, createMessageOnqueueMapper } from './mappers'
import { scrollSession } from 'application/storage/scroll_session'
import { nanoid } from 'nanoid'
import { useParams } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import { AKTEN } from 'application/constants'
import { useForm } from 'react-hook-form'

const SendMessageDialog = (props) => {
    const { dialog, selectedAkte, filesToSend, filesOnQueue, openGalleryOnQueue, clearFileToSend, closeSendMessageDialog, createMessage } = props
    const messageProps = dialog.payload
    const { accountId, userId, fullName, messageKey, metaData, setDisplayContentMobile } = messageProps
    const { t } = useTranslation()
    const defaultValues = {
        message: '',
        subject: '', 
    }
    const { getValues, handleSubmit, register, formState: { errors } } = useForm(defaultValues)
    const idHashQueue = nanoid()
    const params = useParams()

    const handleCreateMessage = (data) => {
        scrollSession.store(scrollSession.get() + 150)
        
        createMessage(
            createMessageMapper(getValues(), filesToSend, fullName),
            metaData,
            'This message cannot be sent',
            params,
            idHashQueue,
            createMessageOnqueueMapper(idHashQueue, data.message, userId, fullName, filesOnQueue),
            messageKey
        )
        
        closeSendMessageDialog()
        isMobileOnly && setDisplayContentMobile({ view: AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.MESSAGES, payload: {} })
    }
    return <React.Fragment>
        <DialogMainContent style={{ padding: '0px 30px', margin:'0' }}>
            <Fragments.BodyWrapper onSubmit={handleSubmit(handleCreateMessage)}>
                <Fragments.InputWrapper>
                    <label htmlFor="subject" style={{fontWeight: 'bold'}}>{t('Add subject')} <Fragments.RequiredIndicator/></label>
                    <Fragments.SubjectInput
                        id='subject'
                        type='text'
                        {...register('subject',
                            {
                                required: 'Plase enter a subject'
                            }
                        )}
                        placeholder={t('Enter a subject')}
                    />
                    {errors.subject  &&
                        <Fragments.ErrorLabel>{errors.subject.message}</Fragments.ErrorLabel>
                    }
                </Fragments.InputWrapper>
                <Fragments.InputWrapper>
                    <label htmlFor="message" style={{fontWeight: 'bold'}}>{t('MessageLabel')} <Fragments.RequiredIndicator/></label>
                    <Fragments.MessageInput
                        id='message'
                        type='text'
                        {...register('message',
                            {
                                required: 'Plase enter a message'
                            }
                        )}
                        placeholder={t('Enter your message')}
                    />
                    {errors.message  &&
                        <Fragments.ErrorLabel>{errors.message.message}</Fragments.ErrorLabel>
                    }
                </Fragments.InputWrapper>
                {filesToSend.length > 0 && (
                    <Fragments.FilesContainer>
                        <FlexGridItem overflow='auto' height='40px'>
                            <FlexGrid>
                                <FlexGridItem width='100%' maxWidth='100%' justifyFlexStart>
                                    <FlexGrid>
                                        <Fragments.FilesWrapper overflow={true}>
                                            {filesToSend.map((f, i) => {
                                                return (
                                                    <React.Fragment>
                                                        <FileName fileName={f.name} clearFile={() => clearFileToSend(f.fileuploadId)} />
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Fragments.FilesWrapper>
                                    </FlexGrid>
                                </FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>
                    </Fragments.FilesContainer>
                )}
                <Fragments.FooterWrapper>
                    <b>
                        <SimpleUploader
                            title={t('Upload')}
                            iconKey='attach_file'
                            onFileAdded={(files) => openGalleryOnQueue(files, accountId)}
                            onFilesAdded={(files) => openGalleryOnQueue(files, accountId)}
                            label={t('Add Attachment')}
                            fontSize='20px'
                        />
                    </b>
                    
                    <Fragments.SendButton type='submit'>
                        <FontIcon icon='send' color='white' fontSize='16px' isClickable={true} />
                        {t('Send')}
                    </Fragments.SendButton>
                </Fragments.FooterWrapper>
            </Fragments.BodyWrapper>
        </DialogMainContent>
    </React.Fragment>

}

export default SendMessageDialog