import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import FontIcon from 'application/components/controls/font_icon'

import { fileHelpers } from 'application/common'
import * as Fragments from './fragments'

const IconButtonUploadComponent = ({
    iconKey = 'attach_file', 
    onFileAdded, 
    onFilesAdded,
    onFilesRejected, 
    title = '', 
    disabled = false, 
    width = '48px', 
    height = '48px', 
    name = '', 
    capture = false, 
    label = '', 
    fontSize = '24px',
    pp = false,
    theme, //from theme context
}) => {

    const getAcceptFN = pp ? fileHelpers.getAcceptPp : fileHelpers.getAccept
    const isAcceptFN = pp ? fileHelpers.isAcceptedPp : fileHelpers.isAccepted

    const accept = getAcceptFN()
    const handleChange = (e) => {
        const filesArray = Array.from(e.target.files)
        const rejectedFiles = filesArray.filter(f => (!isAcceptFN(fileHelpers.getFileExtension(f.name.toLowerCase()))))
        const acceptedFiles = filesArray.filter(f => (isAcceptFN(fileHelpers.getFileExtension(f.name.toLowerCase()))))
        rejectedFiles.length > 0 && onFilesRejected(rejectedFiles)
        acceptedFiles.length > 0 && fileHelpers.readFiles(acceptedFiles, onFileAdded, onFilesAdded)
    }
    return <Fragments.StyledContainer disabled={disabled} widthProp={width} heightProp={height}>
        <FontIcon fontSize={fontSize} icon={iconKey} isClickable={!disabled} title={title} color={disabled ? theme.color.color80 : theme.color.color90} />
        {
            capture 
                ? <Fragments.StyledInput onChange={(e)=>{handleChange(e)}} disabled={disabled} type='file' name={name} accept={accept} multiple capture/>
                : <Fragments.StyledInput onChange={(e)=>{handleChange(e)}} disabled={disabled} type='file' name={name} accept={accept} multiple/>
        }
        {
            label !== '' && <Fragments.StyledLabel className='buttonLabel'>{label}</Fragments.StyledLabel>
        }
    </Fragments.StyledContainer>
}

IconButtonUploadComponent.propTypes = {
    iconKey: PropTypes.string,
    onFileAdded: PropTypes.func.isRequired,
    onFilesRejected: PropTypes.func.isRequired,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string, 
    name: PropTypes.string,
    capture: PropTypes.bool,
    label: PropTypes.string
}

export default withTheme(IconButtonUploadComponent)