import { call, put, takeEvery } from 'redux-saga/effects'

import { ACTIONS, NOTIFICATION } from 'application/constants'
import { api } from 'application/api'
import {
    sendPinFailed,
    sendPinSucceed,
} from 'application/redux/actions/auth'
import { handleError } from '../../errors'
import { showToast } from 'application/redux/actions/notifications'

function* requestPinSaga(action) {
    try {
        yield call(api.gateway.auth.getPin, action.hashAuthKey, action.methode)
        yield put(sendPinSucceed())
        yield put(showToast(action.successMessage))
    } catch (e) {
        yield handleError(e, action)
        yield put(sendPinFailed())
        yield put(showToast(action.errorMessage, NOTIFICATION.LEVEL.ERROR))
    }
}

export function* reqPinWatcher() {
    yield takeEvery(ACTIONS.AUTH_SEND_PIN_REQUEST, requestPinSaga)
}