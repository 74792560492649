export const getDashboardDate = () => {
    let d = new Date()
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]
    let month = monthNames[d.getMonth()]
    let date = d.getDate()
    let year = d.getFullYear()
    return {
        month,
        date,
        year,
    }
}

export const filterAkteOnAccount = (selectedId, allAkten = []) => {
    const filteredAkte = allAkten.filter(a => parseInt(a.accountId) === selectedId)
    return filteredAkte
}

export const setSelectedAccountDetailsMapper = (accountDetails, filteredAkte = []) => {
    return {
        ...accountDetails,
        akten: filteredAkte
    }
}