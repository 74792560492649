import { STORAGE } from 'application/constants'

export const lockedLoginAttemptTime = {
    store: (time) => {
        localStorage.removeItem(STORAGE.LOCKED_LOGIN_ATTEMPT_TIME)
        localStorage.setItem(STORAGE.LOCKED_LOGIN_ATTEMPT_TIME, time)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.LOCKED_LOGIN_ATTEMPT_TIME)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.LOCKED_LOGIN_ATTEMPT_TIME)
    }
}