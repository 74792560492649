import { getFoldersFlatten } from 'application/components/building_blocks/tree/helpers'
import { updateDataOnMessage } from 'application/redux/actions/messages'
import { getFoldersInRegisteredMessage } from 'application/redux/selectors'
import { put, select } from 'redux-saga/effects'

export function* unregisterFoldersFromUnexistedMessage(key) {
    const foldersToUnregister = yield select(getFoldersInRegisteredMessage, key)
    if (foldersToUnregister) {
        const curFoldersFlatten = getFoldersFlatten(foldersToUnregister)
        for (const cf of curFoldersFlatten) {
            yield put(updateDataOnMessage(cf.id, { exist: false }))
        }
    }
}