import React from 'react'
import LawBanner from 'application/components/building_blocks/law_banner'

const BannerStatementAccount = ({accountHasLaw, esyThingAgb, esyThingDatenschutz, accountName, accountId}) => {
    return accountHasLaw ?
        <LawBanner
            userRegistered={false}
            hasAgb={esyThingAgb !== null}
            hasDatenschutz={esyThingDatenschutz !== null}
            companyName={accountName}
            accountId={accountId}
            esyThingAgb={esyThingAgb}
            esyThingDatenschutz={esyThingDatenschutz}
        /> : null
}

export default BannerStatementAccount
