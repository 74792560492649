import { isMobile } from 'react-device-detect'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Header = styled.div`
    display: flex;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 90%;
    align-items: center;
    padding: 5px 20px;
    justify-content: space-between;
    flex-wrap: wrap;
`

export const ImageLogo = styled.img`
    width: ${isMobile ? '50px' : '90px'};
`

export const LinkToBusiness = styled(NavLink)`
display: flex;
white-space: nowrap;
justify-content: flex-start;

    font-size: ${props => props.theme.fontSize.medium};
color: ${props => props.theme.color.turqouise};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-right: 16px;

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        font-size: ${props => props.theme.fontSize.standard};
    }
`

export const FE = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    max-width: 100%;
    line-height: 1.75;
    overflow-x: hidden;
`

export const IMWinnerLeftInfo = styled.div`
    padding: 0px 48px;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 0px 20px;
    }
    /* margin-left: 10%; */
`

export const InfoMainWelcomeWrapper = styled.div`
    display: flex;
    max-width: 90%;
    padding: 48px 25px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: auto;
    margin-top: auto;

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        max-width: 100%;
        padding: 48px 0px;
        flex-direction: column-reverse;
    }
`

export const IMWTitle = styled.h1`
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.heavy};
    margin-bottom: 24px;
    margin-bottom: 16px;
    line-height: 1.3;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        width: 100%;
    font-size: ${props => props.theme.fontSize.xxLarge};
    text-align: center
    }
`

export const ITWTitle = styled.h3`
    padding-top: 52px;
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.Bold};
    margin-bottom: 24px;
    color: ${props => props.theme.color.color100};
    text-align: center;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 0px 10px;
    }

`

export const Esy = styled.span`
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.light};
    color: ${props => props.theme.color.turqouise};

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        width: 100%;
    font-size: ${props => props.theme.fontSize.xxLarge};
    text-align: center
    }
`

export const IMWTSubitle = styled.h3`
    font-size: ${props => props.theme.fontSize.headLine2};
    font-weight: ${props => props.theme.fontWeight.medium};
    margin: 0px;

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        text-align: center;
    }
`

export const IMWpar = styled.h2`
    font-size: ${props => props.theme.fontSize.medium18};
    margin: 0px;

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
    text-align: center
    }
`
export const UListCard = styled.ul`
    list-style: none;
    padding: 24px;
    box-shadow: 0 0 7px #c9c9c9;
    border-radius: 20px;
    width: ${p => p.width ? p.width : '55em'};
    background-color: ${props => props.theme.color.color0};

    & li::before{
        content: '✓';
        margin-right: 14px;
        color: ${props => props.theme.color.turqouise};
    }

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        margin: 0px 24px;
    }
`

export const List = styled.li`
    font-size: ${props => props.theme.fontSize.medium};
        margin-bottom: 8px;
        list-style: none;
        width: ${p => p.width ? p.width : '50%'};
        display: ${p => p.displayFlex ? 'flex' : 'inline-block'};
        position: relative;
        @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        width: 100%;
    }
`
export const ListWrapper = styled.div`
    padding: 24px;
`

export const ImageTopMain = styled.img`
       width: 400px;
    height: 300px;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        width: 100%;
        height: auto;
        margin-bottom: 24px;
        }
`

export const ImageOnGray = styled.img`
       width: 80%;
 /* @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
         width: 340px;
    height: 270px;
    } */
 @media (max-width: ${props => props.theme.breakpoint.laptopL}px) {
         width: 340px;
    height: 420px;
    }
 @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        width: 240px;
    height: 290px;
    }
`

export const ImageLogoOnGray = styled.img`
       width: 100%;
       margin-right: auto;

       &#apple {
       }

       &#android {
       }

       &.androidTablet_ecxet {
           width: 45%;
       }

        @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
             &#apple {
           /* width: 50%;
           margin-left: 10px; */
       }
             &#android {
                /* width: 45%; */
       }
    }
`

export const BreakLine32 = styled.div`
    margin-bottom: 32px;
`

export const WrapperInfoInTurqouise = styled.div`
    background-color: ${props => props.theme.color.turqouise};
    max-width: 100%;
    padding: 48px 48px 48px 48px;
`

export const InnerWrapperInfoInTurqouise = styled.div`
    /* margin-left: 10%;
    margin-right: 10%; */
`

export const StepIIT = styled.h4`
    color: ${props => props.theme.color.color0};
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0px;
`

export const ParIIT = styled.h5`
    color: ${props => props.theme.color.color0};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0px;
`

export const InfoCardTitle = styled.h2`
    color: ${props => props.theme.color.color100};
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       text-align: center;
    }
`

export const InfoCardWrapper = styled.div`
        max-width: 100%;
        padding: 48px;

        @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       padding: 24px 4px;
    }
`

export const InfoGrayWelcomeWrapper = styled.div`
    /* background-color: ${props => props.theme.color.color3} */
`

export const InfoGrayWelcomeInnerWrapper = styled.div`
    padding: 0px 120px;
    /* border-top-left-radius: 300px;
    border-top-right-radius: 300px; */
    /* max-width: 1440px; */
    /* background: #161616; */
    margin-right: auto;
    margin-left: auto;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       padding: 48px 4px;
    }
`

export const InfoGraySwitchContent = styled.div`
    display: ${props => props.hide !== undefined && props.hide ? 'none' : 'block'};
    border-bottom: 1px solid ${props => props.theme.color.color15};

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       border-bottom: unset;
    }
`

export const InfoGrayTitle = styled.h2`
    color: ${props => props.theme.color.color100};
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0px;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       font-size: ${props => props.theme.fontSize.headline1};
    }
`

export const InfoGrayDesc = styled.p`
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.medium18};
    margin-top: 10px;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       font-size: ${props => props.theme.fontSize.medium};
    }
`

export const QnAWrapper = styled.div`
    padding: 70px 30px;
    width: 85em;
    max-width: ${p => p.maxWidth ? p.maxWidth : '100%'};
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       padding: 70px 0px;
       width: 100%;
    }
    
`

export const QnATitle = styled.h2`
    color: ${props => props.theme.color.color100};
    font-size: ${props => props.theme.fontSize.xLarge};
    text-align: left;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
    text-align: center;
    }
`

export const PartnerTitle = styled.h1`
    color: ${props => props.theme.color.color100};
    font-size: ${props => props.theme.fontSize.xLarge};
    text-align: center;
`

export const PartnerWrapper = styled.div`
        display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1080px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       flex-flow: column wrap;
    }
    
`

export const PartnerImg = styled.img`
        width: 200px;
    vertical-align: middle;
    display: inline-block;
     -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
     padding: 0px 48px 48px 48px;
`

export const FooterWelcomeWrapper = styled.footer`
    padding: 32px;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       background: ${props => props.theme.color.color100};
    }
`

export const FooterInstallAppCTA = styled.div`
@media (min-width: ${props => props.theme.breakpoint.mobileS}px) {
       display: flex;
       flex-flow: row wrap;
        justify-content: space-between;
    }

 @media (min-width: ${props => props.theme.breakpoint.tablet}px) {
        display: flex;
       flex-flow: row wrap;
        justify-content: flex-end;
    }
     @media (min-width: ${props => props.theme.breakpoint.laptop}px) {
        display: none;
    }
`
export const FooterAppLogoInstallLink = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
`

export const FooterAppLogoInstall = styled.img`
    width: 150px;
`

export const FooterWelcomeCompanyName = styled.span`
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.small};
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       color: ${props => props.theme.color.color0};
    }
`

export const FooterWelcomeNavlink = styled.a`
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.small};
    text-decoration: none;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       color: ${props => props.theme.color.color0};
    }
    
`