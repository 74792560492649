import React from 'react'
import { FlexGrid } from 'application/components/fragments/grid'
import { ContentFinder } from './helpers_default'

const DefaultIconWithLabel = ({ positionLabel, color, onClick, className, iconSize, isActive, fontSize, width, height, backgroundcolor, borderradius, title, iconKey, label, justifyContent, padding }) => {
    const Content = ContentFinder(positionLabel)
    return <FlexGrid grow={0} noWrap directionColumn itemsCenter>
        <Content 
            color={color}
            onClick={onClick}
            className={className}
            iconSize={iconSize}
            isActive={isActive}
            width={width}
            height={height}
            backgroundcolor={backgroundcolor}
            borderradius={borderradius}
            title={title}
            iconKey={iconKey}
            label={label}
            positionLabel={positionLabel}
            justifyContent={justifyContent}
            fontSize={fontSize}
            padding={padding}
        />
    </FlexGrid>
}

export default DefaultIconWithLabel
