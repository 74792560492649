import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, { useContext } from 'react'
import PasswordStrengthChecker from 'application/components/building_blocks/password_strength_checker'
import { useStrengthDescriptors } from 'application/components/hooks/use_strength_checker'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { isMobile } from 'react-device-detect'

const PasswordStrengthCheckerAnimation = ({ newPass, username, accountId }) => {
    const {getAsset, activePlugin} = usePluginsContext()

    return <FlexGridItem itemsFlexEnd justifyFlexEnd width='50%'>
        <FlexGrid>
            {isMobile ? null : 
                (!activePlugin &&
                    <FlexGridItem grow={0} width='25%'>
                        <ImagePlaceHolder
                            large={isMobile ? '50%' :'100%'}
                            withBorder={false}
                            source={getAsset('password_beachter.png')}
                        />
                    </FlexGridItem>
                )
            }

            <FlexGridItem itemsCenter>
                <PasswordStrengthChecker
                    accountId={accountId}
                    // show={displayPassChecker}
                    password={newPass}
                    strengthDescriptors={useStrengthDescriptors(
                        username
                    )}
                />
            </FlexGridItem>
        </FlexGrid>
    </FlexGridItem>
}

export default PasswordStrengthCheckerAnimation
