import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
      position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
  }

  &:hover input ~ .checkmark {
  background-color: #ccc;
}

input:checked ~ .checkmark {
  background-color: ${p => p.theme.color.checked};
}

input:checked ~ .checkmark:after {
  display: none;
}

.checkmark:after {
    display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`

const Checkmark = styled.span`
display: flex;
    justify-content: center;
    align-items: center;
position: absolute;
  top: 0;
  border-radius: 5px;
  left: 0;
    width: ${p => p.width ? p.width : '22px'};
    height: ${p => p.height ? p.height : '22px'};
  background: ${p => p.theme.color.color15};

    i {
        font-size: ${p => p.width ? `calc(${p.width} - 6px)` : '16px'} !important;
        color: ${p => p.theme.color.color5}
    }
  &:after {
  content: "";
  position: absolute;
  display: none;
}
`
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })``

const Checkbox = (props) => {
    const { checked, onChange, onClick } = props
    return <label onClick={onClick}>
        <CheckboxContainer>
            <HiddenCheckbox onChange={onChange} checked={checked} {...props} />
            <Checkmark checked={checked} {...props} className='checkmark'>
                <i className="material-icons">{checked ? 'done' : ''}</i>
            </Checkmark>
        </CheckboxContainer>
    </label> 
}

export default Checkbox
