import { call, put } from '@redux-saga/core/effects'
import { api } from 'application/api'
import { updateDataOnMessage } from 'application/redux/actions/messages'
import { getFoldersInRegisteredMessage } from 'application/redux/selectors'
import { select } from 'redux-saga/effects'
import { isFolderExistSaga } from './is_folder_exist_saga'

export function* putFoldersMessageSaga(action) {
    const curFolders = yield select(getFoldersInRegisteredMessage, action.messageKey)
    const resultMessageDetails = yield call(api.gateway.pages.getMessageDetails, action.accountId, action.akteIdHash)
    const updatedFolders = resultMessageDetails.folders
    yield put(updateDataOnMessage(action.messageKey, { folders: updatedFolders }))
    const inFolder = action.folderId !== ''
    if (inFolder) {
        yield isFolderExistSaga({ ...action, curFolders, newLoaded: false })
    }
}