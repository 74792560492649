import { connect } from 'react-redux'
import SettingsComponent from './component'

const mapStateToProps = state => ({
    allAktenLoaded: state.common.allAkten.isLoaded,
    allAkten: state.common.allAkten.payload,
    allAccounts: state.common.allAccounts.payload,
    auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsComponent)