import { ACTIONS } from 'application/constants'
import default_store from '../default_store'

const akteMeta = (state = default_store.akteMeta, action) => {
    switch (action.type) {
    case ACTIONS.AKTE_META_DATA_REQUEST:
        return {
            ...state,
            isLoading: true,
        }
    case ACTIONS.AKTE_META_DATA_POPULATE:
        return {
            ...state,
            isLoading: false,
            isLoaded: true,
            payload: action.payload,
        }
    case ACTIONS.AKTE_META_DATA_FAILED:
        return {
            ...state,
            isLoading: false,
            isLoaded: true,
            failed: true,
            payload: {},
        }
    default:
        return state
    }
}

export default akteMeta
