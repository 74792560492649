import React from 'react'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import * as Fragments from './fragments'
import HeaderExternalLoginAccountSection from './sections/header'
import FooterLoginAccountSection from './sections/footer'
import ExternalRequestsSection from './sections/external_requests'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import WelcomeGreetings from '../../auth/login_account/sections/welcome_greetings'
import WebHeaderLogoEffect from 'application/common/web_header_logo'
import { PluginAlternateView } from 'plugins'

const DefaultContentProdulyPresents = (props) => {

    const {useMobile = false, accountPublicInfo} = props

    return (
        <WebHeaderLogoEffect accountPublicInfo={accountPublicInfo}>
            <FlexGrid directionColumn noWrap>
                <Fragments.MainBackground>
                <HeaderExternalLoginAccountSection {...props} />
                <Fragments.AsclarSpacer padding={'40px'}/>
                <WelcomeGreetings {...props} />
                <Fragments.AsclarSpacer padding={'40px'}/>
                <div id='service'>
                    <Fragments.AsclarSpacer padding={'40px'}/>
                    <Fragments.AssistantAvailableBackground>
                        {props.assistantsAvailable && <ExternalRequestsSection useDesktop {...props} />}
                    </Fragments.AssistantAvailableBackground>
                </div>
                <PluginAlternateView id="login.account.download.app">
                    <FlexGrid>
                        <FlexGrid directionColumn>
                            <Fragments.AsclarSpacer padding={'25px'}/>
                            <FlexGridItem>
                                <FlexGrid directionColumn>
                                    <center>
                                        {/* <Fragments.DownloadText>Get it on</Fragments.DownloadText> */}
                                        {/* <Fragments.AsclarSpacer padding={'20px'}/> */}
                                        <FlexGridItem justifyCenter>
                                            <Fragments.DownloadStore flexDirection={useMobile ? 'column' : 'row' }>
                                            <ImagePlaceHolder linkHref={process.env.REACT_APP_INSTALL_APP_IOS} withBorder={false} source={'/assets/images/app_ios.png'} large={'224px'} />
                                            <ImagePlaceHolder linkHref={process.env.REACT_APP_INSTALL_APP_ANDROID} withBorder={false} source={'/assets/images/app_android.png'} large={'224px'} />
                                            </Fragments.DownloadStore>
                                        </FlexGridItem>
                                    </center>
                                </FlexGrid>
                            </FlexGridItem>
                        </FlexGrid>
                    </FlexGrid>
                <Fragments.AsclarSpacer padding={'20px'}/>
                </PluginAlternateView>
                </Fragments.MainBackground>
                <FooterLoginAccountSection {...props} />
            </FlexGrid>
        </WebHeaderLogoEffect>
    )
}

export default DefaultContentProdulyPresents