import { passwordMethods as strengthMethods } from './use_validation.methods'

const getInitialGroupConfigHelper = username => [
    {
        name: 'passwordCriteriaHeading',
        groupMethod: (methods, password) => {
            let count = 0
            methods.forEach(({ method }) => {
                if (method(password)) {
                    count++
                }
            })
            return count >= 3
        }
    },
    {
        name: 'passwordLengthCriteriaHeading',
        groupMethod: (methods, password) => {
            let count = 0
            methods.forEach(({ method }) => {
                if (method(password)) {
                    count++
                }
            })
            return count === methods.length
        }
    },
    {
        name: 'passwordSpecialCriteriaHeading',
        initialVisibility: false,
        groupMethod: (methods, password) => {
            let visibility = false
            methods.forEach(({ method }) => {
                if (!method(password)) {
                    visibility = true
                }
            })
            return !visibility
        },
        payload: username
    }
]

const getStrengthConfigHelper = initialGroupConfigHelper => ({
    includesLowerCaseCharacter: {
        message: 'includesLowerCaseCharacter' ,
        group: initialGroupConfigHelper[0].name
    },
    includesUpperCaseCharacter: {
        message: 'includesUpperCaseCharacter',
        group: initialGroupConfigHelper[0].name
    },
    includesSpecialCharacter: {
        message: 'includesSpecialCharacter',
        group: initialGroupConfigHelper[0].name
    },
    includesNumericCharacter: {
        message: 'includesNumericCharacter',
        group: initialGroupConfigHelper[0].name
    },
    passwordMinLength: {
        message: 'passwordMinLength',
        group: initialGroupConfigHelper[1].name
    },
    passwordNotBlacklisted: {
        message: 'passwordNotBlacklisted',
        group: initialGroupConfigHelper[2].name
    },
    usernameNotIncluded: {
        message: 'usernameNotIncluded',
        group: initialGroupConfigHelper[2].name
    }
})

const getInitialGroupConfig = initialGroupConfigHelper => {
    const obj = {}
    initialGroupConfigHelper.forEach(config => {
        obj[config.name] = {
            title: config.name,
            methods: [],
            groupMethod: config.groupMethod,
            initialVisibility:
                config.initialVisibility === undefined
                    ? true
                    : config.initialVisibility,
            payload: config.payload
        }
    })
    return obj
}

export const useStrengthDescriptors = (username) => {
    const initialGroupConfigHelper = getInitialGroupConfigHelper(username)
    const strengthConfigHelper = getStrengthConfigHelper(
        initialGroupConfigHelper
    )
    return Object.keys(strengthMethods).reduce((acc, key) => {
        
        let objKey = null
        if(strengthConfigHelper[key] !== undefined){
            objKey = strengthConfigHelper[key].group
        }

        acc[objKey].methods.push({
            name: key,
            method:
                acc[strengthConfigHelper[key].group].payload === undefined
                    ? strengthMethods[key]
                    : strengthMethods[key].bind(this, acc[objKey].payload),
            message: strengthConfigHelper[key].message
        })
        
        return acc
    }, getInitialGroupConfig(initialGroupConfigHelper))
}
