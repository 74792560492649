import React from 'react'
import ChatBubbleFavoriteComponent from './favorite'
import DefaultChatBubble from './default'
import UnreadMessageComponent from './unread'

const ChatBubbleComponent = (props) => {
    const {
        messageDetail,
        showInFavoriteSidebar,
    } = props
    const { read } = messageDetail
    return showInFavoriteSidebar ?
        <ChatBubbleFavoriteComponent {...props} /> :
        read ? <DefaultChatBubble {...props} /> :
            <UnreadMessageComponent {...props} />
}

export default ChatBubbleComponent