import { useEffect, useReducer, useState } from 'react'
import { pluginReducer } from './plugin_reducer'
import { PLUGIN_ACTIONS, APPLICATION_MODE } from './plugin_constants'


export function usePlugins(plugins = [], options = {
  defaultLocalization: {},
  defaultTheme: {}
}) {
  const localUserData = JSON.parse(localStorage.getItem('storage_ud'))
  const [state, dispatch] = useReducer(pluginReducer, {
    mode: APPLICATION_MODE.DEV,
    user: {
      accountId: (localUserData ? localUserData.accountId : null),
    },
    lang: 'de',
    plugins,
    activePlugin: null,
    theme: options.defaultTheme,
    config: {},
    localization: options.defaultLocalization,
  })

  useEffect(() => {
    const mode = global.window.BROWSER_ENV === 'production' ? APPLICATION_MODE.PROD : APPLICATION_MODE.DEV
    if (mode === APPLICATION_MODE.PROD) {
      dispatch({ type: PLUGIN_ACTIONS.PROD_MODE })
    }
    else {
      //TN::: redundant? DEV_MODE is activated by default
      // I am not sure to have multiple config for different environments is a good idea. It might be better to have a single config and use environment variables to change it.
      dispatch({ type: PLUGIN_ACTIONS.DEV_MODE })
    }

    const hostName = document.location.hostname
    const activePlugin = state.plugins.find(
        (plugin) => {
          return plugin.getConfig(mode).hostName === hostName || (state.user.accountId != null && state.user.accountId === plugin.getConfig(mode).accountId)
        }
      )

      if (activePlugin) {
        dispatch({ type: PLUGIN_ACTIONS.SET_ACTIVE_PLUGIN, payload: activePlugin })
      }
    }, [state.plugins, state.user.accountId])
  
    return {
      theme: state.theme,
      config: state.config,
      localization: state.localization,
      activePlugin: state.activePlugin,
      getComponent(id) {
        if (!state.activePlugin)
          return null
  
        return state.activePlugin.getComponent(id)
      },
      getAsset(filename){
        if (!state.activePlugin)
          return `/assets/images/${filename}`
        return `${state.config.assetPath}${filename}`
      },
      setUserAccountId(accountId){
        dispatch({type: PLUGIN_ACTIONS.SET_USER_ACCOUNT_ID, payload: accountId})
      }
      
    }
  }