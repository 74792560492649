import { textHelpers } from 'application/common'
import ActionButton from 'application/components/controls/button/action_button/component'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { CONSENT } from 'application/constants'
import React from 'react'
import { AcceptanceWrapper, ConsentContainer, ConsentWrapper, LinkConsent, ParConsent } from './fragments'

const ConsentBannerComponent = ({ onConsentAccept, consentUseApp }) => {
    const handleAccept = () => {
        onConsentAccept(textHelpers._enText(CONSENT.ACCEPT_TEXT + new Date()))
    }
    if (consentUseApp) return null
    return <ConsentContainer>
        <ConsentWrapper>
            <FlexGrid noWrap>
                <FlexGridItem itemsCenter>
                    <FlexGrid directionColumn>
                        <ParConsent>
                            Für den reibungslosen Betrieb und um e.sy One verbessern zu können, würden gerne Daten im Local Storage deines Browsers ablegen.
                        </ParConsent>
                        <AcceptanceWrapper>
                            <FlexGridItem>
                                <LinkConsent target='_blank' href='https://www.e-consult.de/datenschutz/' >
                                    Datenschutz-Bestimmungen
                                </LinkConsent>
                            </FlexGridItem>
                            <FlexGridItem>
                                <ActionButton
                                    height='30px'
                                    fontSize='14px'
                                    onButtonClick={handleAccept}
                                    buttonText='Akzeptieren'
                                />
                            </FlexGridItem>
                        </AcceptanceWrapper>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <ImagePlaceHolder
                        withBorder={false}
                        source='/assets/images/clicktoaccept.png'
                        large='200px'
                    />
                </FlexGridItem>
            </FlexGrid>
            
        </ConsentWrapper>
    </ConsentContainer>
}

export default ConsentBannerComponent