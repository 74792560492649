export const ERRORS = {
    FAILED_TO_FETCH: 'TypeError: Failed to fetch'
}

export const ERROR_TYPE_CODE = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    LOCKED: 423,
    GONE: 410,
	UPLOAD_VIRUS: 422,
	NOT_ACCEPTABLE: 406
}