import styled from 'styled-components'

export const StyledAttachment = styled.div`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    margin-right: ${props => props.marginRight ? '20px' : '0px'};
    padding: 5px 10px 11px 10px;
    background: ${props => props.isActive ? props.theme.color.color10 : 'transparent'};
    border-radius: 2px;
`

export const StyledCaption = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.light};
    margin: 0;
    max-width: 100px;
`

export const StyledThumbnail= styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    height: ${props => props.isSizeFixed ? props.divHeight ? props.divHeight : '70px' : `${props.theme.thumbnail.height}px`};
    width: ${props => props.isSizeFixed ? props.divWidth ? props.divWidth : '70px' : `${props.theme.thumbnail.height}px`};
    background: ${props => props.isSizeFixed ? `url(${props.imageSrc})` : 'unset'};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    justify-content: center;
    &:hover>.overlay{
        display:flex;
    }
`

export const StyledThumbnailImage = styled.img`
    max-height: ${props => props.theme.thumbnail.height - 2}px;
    max-width: ${props => props.theme.thumbnail.width - 2}px;
    width: auto;
    height: auto;
    border: 1px solid ${props => props.isActive ? props.theme.color.turqouise : props.theme.color.color15};
    outline: 2px solid ${props => props.isActive ? props.theme.color.turqouise : 'transparent'}
`
