import styled from 'styled-components'

export const ChatBubbleWrapper = styled.div`
    display: flex;
    align-self: ${props => props.isOwnMessage ? 'flex-end' : 'flex-start'};
    height: auto;
    margin: 16px;
    width: ${props => props.serviceAkte ? '97%' : '70%'};
    cursor: ${props => props.onBubbleClick ? 'pointer' : 'default'};
`

export const ChatBubbleInner = styled.div`
    background-color: ${p => p.theme.color.color10};
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
	animation: ${p => p.isFavoriteSelected ? 'background-fade 5s' : 'unset'};
    height: 100%;

    @-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(211,137,187, 0.7);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgb(211,137,187, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgb(211,137,187, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(211,137,187, 0.7);
    box-shadow: 0 0 0 0 rgb(211,137,187, 0.7);
  }
  70% {
      -moz-box-shadow:  0 0 0 10px rgb(211,137,187, 0);
      box-shadow:  0 0 0 10px rgb(211,137,187, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgb(211,137,187, 0);
      box-shadow: 0 0 0 0 rgb(211,137,187, 0);
  }
}

@-webkit-keyframes background-fade {
    0% {
        background-color:${props => props.theme.color.color30};
    }
    100% {
        background-color:${props => props.theme.color.color10};
    }
}
@-moz-keyframes background-fade {
    0% {
        background-color:${props => props.theme.color.color30};
    }
    100% {
        background-color:${props => props.theme.color.color10};
    }
}
@keyframes background-fade {
    0% {
        background-color:${props => props.theme.color.color30};
    }
    100% {
        background-color:${props => props.theme.color.color10};
    }
}
`

export const ChatBubbleCreator = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.color30};
`

export const IconWrapper = styled.div`
    display: flex;
    align-self: center;

    div {
        margin-right: 2px;
    }
`

export const ChatBubleMessage = styled.span`
    font-family: ${props => props.theme.fontFamily.primary};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.color90};
    /* word-break: break-all; */
    word-spacing: inherit;
    white-space: pre-line;
    width: 100%;
    word-break: break-word;

    a {
         font-size: ${props => props.theme.fontSize.standard};
         font-weight: ${props => props.theme.fontWeight.bold};
         color: ${props => props.theme.color.primary};

         &:hover{
            color: ${props => props.theme.color.primary};
         }
    }
`

export const ChatBubleMessageHeader = styled.span`
    font-size: ${props => props.theme.fontSize.headline3};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-family: ${props => props.theme.fontFamily.secondary};
    color: ${props => props.theme.color.color90};
    /* word-break: break-all; */
    word-spacing: inherit;
    white-space: pre-line;
    width: 100%;
    word-break: break-word;
`

export const ChatBubleDate = styled.span`
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.color30};
    display: flex;
    align-items: flex-end;
	flex: 1;
	justify-content: flex-end;
    span {
        font-weight: ${p => p.theme.fontWeight.bold};
        /* font-style: italic; */
    }
`
export const UnreadWrapper = styled.div`
    background-color: ${props => props.theme.color.color5};
    display: flex;
    border-radius: 18px;
    flex-direction: column;
    padding: 16px;
    padding-top: 0;
    flex: 1 1 100%;
`

export const UnreadMessageDescription = styled.div`
    color: ${props => props.theme.color.color80};
    font-size: ${props => props.theme.fontSize.standard};
    font-style: ${props => props.theme.fontStyle.italic};
    /* text-align: center; */
    padding-top: 16px; 
`