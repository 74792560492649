import ActionButton from 'application/components/controls/button/action_button/component'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { Filler, PushBottom20, PushLeft40 } from 'application/components/fragments/grid'
import { WizardFooterRight } from 'application/components/pages/_layout/fragments/wizard'
import { ROUTES } from 'application/constants'
import { hashUserKey } from 'application/storage'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

const ButtonCheckUsername = ({ onUpdateWizardPrev, usernameRequest, handleSubmit, onSubmitSuccess, accountId }) => {
    const {t} = useTranslation()
    const theme = useTheme()
    return <Fragment>
        <Filler />
        <FlexDividerHorizontal />
        <PushBottom20 />
        <WizardFooterRight>
            <ActionButton
                noBackground={true}
                onButtonClick={() => onUpdateWizardPrev(2, 1)}
                linkTo={ROUTES.WIZARD_REG_BASE + '/' + hashUserKey.get() + ROUTES.WIZARD_REG_WELCOME}
                buttonText={t('BackButtonText')}
                color='#000'
            />
            <PushLeft40 />
            {
                !usernameRequest ?
                    <ActionButton
                        backgroundColor={theme.color.primary}
                        buttonText={t('NextButtonText')}
                        onButtonClick={handleSubmit(onSubmitSuccess)}
                    /> : <PreloaderSpin
                        primaryColor={theme.color.primary}
                    />
            }
        </WizardFooterRight>
    </Fragment>
}

export default ButtonCheckUsername
