import SliderInquiry from 'application/components/building_blocks/slider_inquiry'
import { SliderInquiryHeadline } from 'application/components/building_blocks/slider_inquiry/fragments'
import IconLabel from 'application/components/controls/icon/icon_label'
import IconLink from 'application/components/controls/icon/icon_link'
import TagCategory from 'application/components/controls/tag_category'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { FlexGrid, FlexGridItem, PushBottom10, PushBottom30 } from 'application/components/fragments/grid'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import React from 'react'
import styled, { useTheme, withTheme } from 'styled-components'
import DisplayFilesAkteDetails from './display_files'
import DisplayFavoritesAkteDetails from './display_favorite'
import { DateSidebarAkteDetails } from './fragments'
import { AKTEN } from 'application/constants'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { useTranslation } from 'react-i18next'
import CreatorProfilePicture from 'application/components/building_blocks/creator_profiile_picture'
import { isTablet } from 'react-device-detect'
import { ROUTE_PARAMETERS } from 'application/constants'
import { buildQueryString } from 'application/common/route_helpers'
import { AccountPublicInfoInternalLoader, RefreshForm } from 'application/common'
import { useLocation } from 'react-router-dom'
import DateComponent from 'application/components/controls/date'
import Time from 'application/components/controls/time'
import { PluginAlternateView } from 'plugins'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const Wrapper = styled.div`
    padding: 20px;
    width: 100%;
    overflow: ${(props) => (!props.isoverflow ? 'hidden' : 'auto')};
    max-height: ${(props) => (props.maxHeight === undefined ? '100%' : props.maxHeight)};
`

const AktenDetailSidebarComponent = (props) => {
    const {
        thumbnailsToShow = [],
        closeAkteDetails,
        metaInfo,
        openAkteSidebar,
        onOpenGallery,
        selectedAkte,
        userFirstName,
        userLastName,
        email,
        parentId,
        messages,
        setFormReturnUrl,
        refreshFavoriteMessages,
        accountPublicInfoInternal,
    } = props
    let { location, search } = useLocation()
    const url = location + search
    const { creationDate, creatorFirstName, creatorLastName } = selectedAkte
    const { t } = useTranslation()
    const firstNameUpdated = creatorFirstName === undefined || creatorFirstName === null || creatorFirstName === '' ? '' : creatorFirstName
    const params = buildQueryString([
        {
            key: ROUTE_PARAMETERS.ASSISTANTS.EMAIL,
            value: email,
        },
        {
            key: ROUTE_PARAMETERS.ASSISTANTS.LASTNAME,
            value: userLastName,
        },
        {
            key: ROUTE_PARAMETERS.ASSISTANTS.FIRSTNAME,
            value: userFirstName,
        },
    ])

    const handleFavorite = () => {
        openAkteSidebar(AKTEN.SIDEBAR.KEY.FAVORITE)
        refreshFavoriteMessages()
    }
    const isToday = new Date().toDateString() === new Date(creationDate).toDateString()
    const assistants = metaInfo.assistants !== null || metaInfo.assistants !== undefined || metaInfo.assistants.length > 0 ? metaInfo.assistants : []
    const assistantsVisible = assistants.length > 0
    const accountPublicInfo = accountPublicInfoInternal?.payload
    const theme = useTheme()
    const { config, activePlugin } = usePluginsContext()


    return (
        <Wrapper maxHeight='100%' isoverflow={true}>
            {!isTablet && (
                <FlexGridItem>
                    <IconLink title={t('Close case details')} width='40px' iconKey='clear' onClick={closeAkteDetails} />
                </FlexGridItem>
            )}
            {metaInfo === null ? (
                <FlexGrid justifyCenter itemsCenter>
                    <PreloaderSpin />
                </FlexGrid>
            ) : (
                <React.Fragment>
                    <FlexGridItem justifyCenter>
                        <FlexGrid itemsCenter directionColumn>
                            {accountPublicInfo && (
                                <CreatorProfilePicture
                                    large={'120'}
                                    akteIdHash={selectedAkte.idHash}
                                    creatorIdHash={selectedAkte.creatorIdHash}
                                    creatorFirstName={creatorFirstName}
                                    creatorLastName={creatorLastName}
                                    accountId={metaInfo.accountId}
                                    mainColor={
                                        theme.color.primary
                                    }
                                />
                            )}
                            <PluginAlternateView id='akten.sidebar.phone'>
                                <FlexGrid directionColumn justifyCenter>
                                    {metaInfo.phone !== null && <IconLabel linkHref={`tel:${metaInfo.phone}`} icon='phone' label={metaInfo.phone} />}
                                </FlexGrid>
                            </PluginAlternateView>
                        </FlexGrid>
                    </FlexGridItem>
                    <RefreshForm {...props}>
                        <React.Fragment>
                            <PushBottom24 />
                            <PluginAlternateView id='akten.sidebar.assistants_button'>
                                {assistantsVisible && assistants.length > 5 ? (
                                    <SliderInquiry
                                        inquiry={assistants}
                                        sliderInquiryHeadline={t('Request')}
                                        accountId={metaInfo.accountId}
                                        backgroundcolor={theme.color.anfrageTag || metaInfo.mainColor}
                                        firstName={userFirstName}
                                        lastName={userLastName}
                                        email={email}
                                        params={params}
                                        onInquiryClick={() => setFormReturnUrl(url)}
                                    />
                                ) : (
                                    assistants.length > 0 > 0 && (
                                        <FlexGrid directionColumn justifyCenter itemsCenter>
                                            <SliderInquiryHeadline>{t('Request')}</SliderInquiryHeadline>
                                            <FlexGridItem>
                                                <FlexGrid justifyCenter>
                                                    {assistants.map((q, i) => {
                                                        const formattedUrl = activePlugin ? q.url.replace('app.esy.one', config.hostName) :  q.url
                                                        return (
                                                            <TagCategory
                                                                key={i}
                                                                text={q.name}
                                                                href={formattedUrl + params}
                                                                target=''
                                                                backgroundcolor={theme.color.anfrageTag || metaInfo.mainColor}
                                                                onTagClick={() => setFormReturnUrl(url)}
                                                            />
                                                        )
                                                    })}
                                                </FlexGrid>
                                            </FlexGridItem>
                                            <FlexGridItem width='90%'></FlexGridItem>
                                        </FlexGrid>
                                    )
                                )}
                            </PluginAlternateView>

                        </React.Fragment>
                    </RefreshForm>
                </React.Fragment>
            )}
            <PushBottom30 />
            <FlexDividerHorizontal />
            <PushBottom10 />
            <DateSidebarAkteDetails>
                {isToday ? t('CreationDateTextToday') : t('CreationDateText')} <DateComponent date={creationDate} todayString={t('Today')} />,{' '}
                <Time date={creationDate} timeHourSuffix='' />
            </DateSidebarAkteDetails>
            {/* <PushBottom10 /> */}
            {thumbnailsToShow.length !== 0 && (
                <React.Fragment>
                    <DisplayFilesAkteDetails
                        messages={messages}
                        akteIdHash={selectedAkte.idHash}
                        parentId={parentId}
                        thumbnails={thumbnailsToShow}
                        onOpenGallery={onOpenGallery}
                        onClick={() => openAkteSidebar(AKTEN.SIDEBAR.KEY.FILES)}
                    />
                </React.Fragment>
            )}
            <DisplayFavoritesAkteDetails onClick={handleFavorite} />
            <AccountPublicInfoInternalLoader {...props} />
        </Wrapper>
    )
}

export default withTheme(AktenDetailSidebarComponent)
