import { sendFormSupportRequest } from 'application/redux/actions/pages/settings'
import { connect } from 'react-redux'
import AboutSettingComponent from './component'

const mapStateToProps = state => ({
    formRequest: state.pages.settings.about.formRequest,
    formFailed: state.pages.settings.about.formFailed,
    accountId: state.auth.common.user.accountIds[0],
    email: state.auth.common.user.email,
})

const mapDispatchToProps = dispatch => ({
    sendFormSuppportRequest: (accountId, payload, errorMessage, successMessage) => dispatch(sendFormSupportRequest(accountId, payload, errorMessage, successMessage))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutSettingComponent)