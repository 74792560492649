import React, { useContext } from 'react'
import * as Fragments from 'application/components/pages/external/auth/fragments'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import styled from 'styled-components'
import { TitlePin } from 'application/components/pages/external/_common'
import RequestButton from '../../auth/request_pin/sections/request_button'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const DivTop = styled.div``

const DivMiddle = styled.div`
    text-align: center;
    position: relative;
    height: 20rem;
    width: 20rem;
    margin: 0 auto;
`

const DivBottom = styled.div``
const Image = styled.img`
    width: 100%;
    height: auto;
`

const RequestPinMobile = props => {
    const accountId = props.akteMeta.accountId
    const {getAsset} = usePluginsContext()
	return <Fragments.LayoutInputMobile>
        <DivTop>
            <LanguageSwitchHoc withLabel={false} />
            <TitlePin />
        </DivTop>
        <DivMiddle>
            <Image
                src={
                    getAsset('request_pin.png')
                }
            />
        </DivMiddle>
        <DivBottom>
            <RequestButton useMobile {...props} />
        </DivBottom>
    </Fragments.LayoutInputMobile>
}

export default RequestPinMobile
