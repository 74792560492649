import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import FontIcon from 'application/components/controls/font_icon'

const StyledButtonNavLink = styled(NavLink)`
    padding: 0px;
    margin: 0px;
    border: none;
    cursor: pointer;
    outline: none;
    width:${props => props.strechted ? '100%' : props.widthbutton !== undefined ? props.widthbutton : 'auto'};
    text-decoration: none;
`
const StyledButton = styled.button`
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    border: none;
    cursor: pointer;
    outline: none;
    width:${props => props.strechted ? '100%' : props.widthbutton !== undefined ? props.widthbutton : 'auto'};
    text-decoration: none;
`

const StyledButtonWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: ${p => p.justifyContent};
    flex-shrink: 0;
    border-style: solid;
    border-width: 1px;
    position: relative;
    z-index: 0;
    min-width: 100px;
    border-color: ${props => props.outline ? props.borderColor !== '' ? props.borderColor :
        props.secondary ? props.theme.color.color100 : props.theme.color.primary :
        props.noBackground ? 'transparent' : props.backgroundColor ? props.backgroundColor :
            props.secondary ? props.theme.color.color100 : props.theme.color.primary};
    border-radius: ${props => props.secondary ? props.borderradius ? props.borderradius : '0px' : props.borderradius ? props.borderradius : '5px'};
    height: auto;
    padding: 19px 24px;
    transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s, color 0.2s ease 0s;
    opacity: ${props => props.disabled ? '0.5' : '1'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    background-color: ${props => props.noBackground ? 'transparent' : props.backgroundColor ? props.backgroundColor : props.secondary ? props.theme.color.color100 : props.theme.color.primary};
    max-width: 100%;
    color: ${props => props.color};
    font-weight: ${props => props.theme.fontWeight.medium};
    font-size: ${props => props.fontSize};
    line-height: 20px;
    visibility: visible;
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricprecision;
    text-size-adjust: none;
		align-items: center;

    i {
        ${props => props.iconPosition === 'before' ? 'margin-right : 8px;'
        : ' margin-left : 8px;' }
        align-self: center;
		display: flex;
		align-items: center;
        color: ${props => props.color};
        font-size: ${props => props.fontSize};
        ${props => props.fontSize && `min-height: calc(${props.fontSize} + 2px) !important;`}
    }

    &::hover {
        background-color: ${props => props.secondary ? props.theme.color.color90 : props.theme.color.primary};
    }
`

const ActionButtonLinkWithIcon = ({
    buttonText,
    className,
    onButtonClick,
    strechted,
    disabled = false,
    secondary = false,
    noBackground = false,
    linkTo = '',
    color = '#fff',
    fontSize = '18px',
    height,
    borderradius,
    iconPosition = 'before',
    iconKey,
    widthbutton,
    outline = false,
	borderColor = '',
	backgroundColor,
	justifyContent = 'space-between'
}) => {
    return linkTo === '' ? <StyledButton onClick={onButtonClick} widthbutton={widthbutton} strechted={strechted}>
		<StyledButtonWrapper
			justifyContent={justifyContent}
			backgroundColor={backgroundColor}
            borderradius={borderradius}
            height={height}
            color={color}
            fontSize={fontSize}
            noBackground={noBackground}
            secondary={secondary}
            className={className}
            outline={outline}
			borderColor={borderColor}
			iconPosition={iconPosition}
            disabled={disabled}>
            {iconPosition === 'before' ? <>
                <FontIcon color='#fff' icon={iconKey} />
                {buttonText}
            </> :
                <>{buttonText}
                    <FontIcon color='#fff' icon={iconKey} />
                </>}

        </StyledButtonWrapper>
    </StyledButton> : <StyledButtonNavLink widthbutton={widthbutton} onClick={onButtonClick} to={linkTo} strechted={strechted}>
        <StyledButtonWrapper
            borderradius={borderradius}
            backgroundColor={backgroundColor}
            height={height}
            color={color}
            fontSize={fontSize}
            noBackground={noBackground}
            secondary={secondary}
            className={className}
            outline={outline}
            borderColor={borderColor}
            iconPosition={iconPosition}
            disabled={disabled}>
            {iconPosition === 'before' ? <>
                <FontIcon color='#fff' icon={iconKey} />
                {buttonText}
            </>  :
                <>{buttonText}
                    <FontIcon color='#fff' icon={iconKey} />
                </>  }
            
        </StyledButtonWrapper>
    </StyledButtonNavLink>
}


export default ActionButtonLinkWithIcon