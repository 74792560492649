import { PushBottom40 } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import { TextInputWithLabel, Form } from 'application/components/controls/form_elements'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'
import { ErrorLabel } from 'application/components/fragments/error_information'

const FormCheckUsername = ({ handleSubmit, onSubmitSuccess, register, validationMethods, errors, usernameNotValid, usernameSet, usernameRequest, errorMessage, useMobile = false }) => {
    const {t} = useTranslation()
    return <Fragment>
        <PushBottom40 />
        <Form onSubmit={handleSubmit(onSubmitSuccess)}>
            <TextInputWithLabel
                width={useMobile ? '100%' : isTablet ? '80%' : '40%'}
                label={t('username')}
                name='username'
                register={register}
                validate={{ notEmpty: validationMethods.notEmpty }}
                error={errors.username}
                errorMessageOverride='UsernameNotEmpty'
                onInputBlur={handleSubmit(onSubmitSuccess)}
                cssValidationError={usernameNotValid}
                isSuccess={usernameSet}
                disabled={usernameRequest}
                htmlId={nanoid()}
            />
            {
                usernameNotValid && <ErrorLabel>
                    {t(errorMessage)}
                </ErrorLabel>
            }
            <input type='submit' style={{ display: 'none' }} value='weiter' />
        </Form>
        <PushBottom40 />
    </Fragment>
}

export default FormCheckUsername
