import ActionButtonHref from 'application/components/controls/button/action_button/href_button'
import { FlexGrid, PushBottom30 } from 'application/components/fragments/grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
	width: 100%;

	@media (min-width: ${p => p.theme.breakpoint.sm}){
		width: 50%
	}
`

export const AboutSettingForm = (props) => {

    const {t} = useTranslation()

    return <React.Fragment>
        <PushBottom30 />
        <FlexGrid justifySpaceBetween>
            <ButtonWrapper>
                <ActionButtonHref
                    hrefTo={process.env.REACT_APP_LINK_FEEDBACK}
                    buttonText={t('Feedback')}
                    strechted={true}
                />
            </ButtonWrapper>
        </FlexGrid>
    </React.Fragment>
}