import styled from 'styled-components'

export const LayoutWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 100%;
    overflow: hidden;
`

export const LayoutInfoWrapper = styled.div`
    width: 30%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-image: url(${props => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
    @media (min-width: ${props => props.theme.breakpoint.tablet}px) {
        display: block;
    }
`


export const LayoutInputWrapper = styled.div`
    width: 100%;
    overflow: auto;
    padding: 20px;
    height: 100%;
    @media (min-width: ${props => props.theme.breakpoint.tablet}px) {
        width: 70%;
        padding-top: ${props => props.paddingTop ? props.paddingTop : '50px'};
        padding-right: ${props => props.paddingRight ? props.paddingRight : '90px'};
        padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : '70px'};
        padding-left: ${props => props.paddingLeft ? props.paddingLeft : '90px'};
    }
`

export const LayoutContentWrapper = styled.div`
    padding: 0px;
    @media (min-width: ${props => props.theme.breakpoint.tablet}px) {
        padding-right: ${p => p.needPadding ? '90px' : '0px'}
    }
`


export const WizardFooterRight = styled.div`
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
`