import { DIALOG } from 'application/constants'
import { getAkteMetaRequest } from 'application/redux/actions/akte_meta'
import { sendPinRequest } from 'application/redux/actions/auth'
import { setConsentAccept } from 'application/redux/actions/consent_use_app'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import RequestPinComponent from './component'

const mapStateToProps = state => ({
    akteMetaLoaded: state.akteMeta.isLoaded,
    akteMetaLoading: state.akteMeta.isLoading,
    akteMetaFailed: state.akteMeta.failed,
    akteMeta: state.akteMeta.payload,
    pinSent: state.auth.pin.sent,
    pinRequest: state.auth.pin.request,
    auth: state.auth,
    consentUseApp: state.consentUseApp.accept
})

const mapDispatchToProps = dispatch => ({
    onOpenDialogSendPinOption: (hashAuthKey) => dispatch(openDialogByName(DIALOG.NAME.SEND_PIN_OPTION, true, { hashAuthKey }, 'Request PIN')),
    onOpenDialogSendPinOptionOnMobile: (hashAuthKey) => {
        dispatch(openDialogByName(DIALOG.NAME.SEND_PIN_OPTION, true, { hashAuthKey }, 'Request PIN', DIALOG.SCREEN.MOBILE))
    },
    getAkteMetaRequest: (hashUserKey) => dispatch(getAkteMetaRequest(hashUserKey)),
    reqPin: (hashAuthKey, methode, errorMessage, successMessage) => dispatch(sendPinRequest(hashAuthKey, methode, errorMessage, successMessage)),
    setConsent: (dateAccept) => dispatch(setConsentAccept(dateAccept))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestPinComponent)