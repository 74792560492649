export default {
  'dev': {
    accountId: 764671,
    uniqueAccountKey: 'demo-wourage',
    link: 'https://www.wourage.de/',
    hostName: 'app.wourage.de',
    assetPath: '/assets/images/wourage/',
    disableDashboard: {
      redirectTo: 'AKTEN'
    },
    disableAccountPage:{
      redirectTo: 'https://www.wourage.de'
    },
    datenschutzLink: 'https://www.wourage.de/datenschutz',
    agbLink: 'https://www.wourage.de/agb',
    impressumLink: 'https://www.wourage.de/impressum',
    logoLink: 'https://www.wourage.de/',
    companyIcon: '/assets/images/wourage.png',
    companyIconWidth: 320,

    customHeaderTitles:{
      '/page/wourage/guidance/wourage-kontakt': 'Wourage - Kontaktformular',
      '/page/wourage/guidance/wourage-hinweis': 'Wourage - Hinweis',
    },
  },

  'prod': {
    accountId: 764671,
    uniqueAccountKey: 'wourage',
    link: 'https://www.wourage.de/',
    hostName: 'app.wourage.de',
    assetPath: '/assets/images/wourage/',
    disableDashboard: {
      redirectTo: 'AKTEN'
    },
    disableAccountPage:{
      redirectTo: 'https://www.wourage.de'
    },
    datenschutzLink: 'https://www.wourage.de/datenschutz',
    agbLink: 'https://www.wourage.de/agb',
    impressumLink: 'https://www.wourage.de/impressum',
    logoLink: 'https://www.wourage.de/',
    companyIcon: '/assets/images/wourage.png',
    companyIconWidth: 320,
    customHeaderTitles:{
      '/page/wourage/guidance/wourage-kontakt': 'Wourage - Kontaktformular',
      '/page/wourage/guidance/wourage-hinweis': 'Wourage - Hinweis',
    },

  }
}
