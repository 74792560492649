import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { isTablet } from 'react-device-detect'
import styled, { withTheme } from 'styled-components'
import IconLink from 'application/components/controls/icon/icon_link'
import LoadEffect from 'application/common/load_effect'
import { MessageList } from 'application/components/building_blocks/messages'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AktenTitle = styled.h1`
    margin-top: 40px;
    margin-bottom: 45px;
    margin-left: 20px;
    color: ${props => props.theme.color.color100};
    font-size: ${props => props.theme.fontSize.headline1};
    font-family: ${props => props.theme.fontFamily.secondary};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const StyledTriggerToolTip = styled.a`
    text-decoration: none;
`
const MessageListSection = (props) => {
    const { widthMessageList, theme, allAktenLoading, allAccountsLoading, messageKey, updateAkten, folderId, allAccounts } = props
    let params = useParams()
    const { akteIdHash, accountId } = params
    const {t} = useTranslation()
    return <FlexGridItem hideMobile grow={0.9} shrink={0} width={widthMessageList} widthMobile={widthMessageList}>
        <FlexGrid height='100%' noWrap directionColumn>
            <FlexGridItem grow={0}>
                <FlexGrid itemsCenter justifySpaceBetween>
                    <AktenTitle>
                        {t('Akten')}
                    </AktenTitle>
                    {
                        !isTablet &&
                        <FlexGridItem itemsFlexEnd needSpaceRight='16px' grow={0}>
                            <StyledTriggerToolTip data-tip data-for="refresh">
                                <IconLink title={t('Refresh cases')} iconSize={theme.fontSize.headline2} iconKey='autorenew' onClick={() => updateAkten(params, folderId, messageKey)} color={theme.color.color70} />
                            </StyledTriggerToolTip>
                        </FlexGridItem>
                    }
                </FlexGrid>
            </FlexGridItem>
            <LoadEffect loading={allAktenLoading} allAccounts={allAccounts}>
                <MessageList
                    rootKey={akteIdHash}
                    messageKey={messageKey}
                    selectedAkteId={akteIdHash}
                    selectedAccountId={accountId}
                />
            </LoadEffect>
        </FlexGrid>
    </FlexGridItem>
}

export default withTheme(MessageListSection)
