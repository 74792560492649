import ACTIONS from 'application/constants'
import default_store from '../../../default_store'

const aktenActionDownloadAkteReducer = (state = default_store.pages.akten.action.downloadAkte, action) => {
    switch (action.type) {
    case ACTIONS.PAGES_AKTEN_DOWNLOAD_AKTE_ZIP: return {
        ...state,
        request: true,
    }
    case ACTIONS.PAGES_AKTEN_DOWNLOAD_AKTE_ZIP_SUCCEED: return {
        ...state,
        request: false,
        succeed: true,
    }
    case ACTIONS.PAGES_AKTEN_DOWNLOAD_AKTE_ZIP_FAILED: return {
        ...state,
        request: false,
        succeed: false,
        failed: true,
    }
    default:
        return state
    }
}

export default aktenActionDownloadAkteReducer