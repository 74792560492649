import React from 'react'
// import PropTypes from 'prop-types'

import AttachmentViewer from 'application/components/building_blocks/attachments/attachment_viewer'
import ThumbnailSlider from 'application/components/building_blocks/attachments/slider'
import useEscapeKey from 'application/components/hooks/use_escape_key'

import {
  StyledOverlayDimmer,
  StyledOverlayContent,
  StyledHeader,
  StyledSidebarHeaderCaption,
  StyledSidebarHeaderCloseButton,
  StyledSidebarHeaderDownloadButton,
  StyledHeaderRow,
  StyledFooterRow,
  StyledContent,
} from './fragments'
import { accessTokenRegistered } from 'application/storage'
import { accessTokenAdhoc, accessTokenForm, accessTokenUnregistered } from 'application/storage/access_token'
import { textHelpers } from 'application/common'
import { useParams } from 'react-router-dom'
import OverridedIconButton from 'application/components/controls/icon/icon_button/component'

const AttachmentGallery = ({
  gallery,
  onSelectAttachment,
  onCloseGallery,
  downloadAttachment,
}) => {
  useEscapeKey(onCloseGallery)
  let params = useParams()
  const inForm =
    params.processGuid !== undefined || params.externalRequestKey !== undefined || gallery.inAufgabe
  const storage = inForm ? accessTokenAdhoc : accessTokenRegistered
  const truncateFileName = textHelpers.truncateMiddleText(
    gallery.selectedFileName,
    50
  )
  const handleSelectAttachment = (att) => {
    onSelectAttachment(
      att.fileId,
      att.fileName,
      gallery.accountId,
      att.akteIdHash,
      att.parentId
    )
  }
  const handleDownload = () => {
    downloadAttachment(
      gallery.selectedFileId,
      gallery.selectedFileName,
      gallery.accountId,
      storage
    )
  }

  //   console.log(downloadAttachmentAdHoc)

  return gallery.isVisible ? (
    <StyledOverlayDimmer isVisible={gallery.isVisible}>
      <StyledOverlayContent>
        <StyledHeaderRow>
          <StyledHeader>
            <StyledSidebarHeaderCaption>
              {truncateFileName}
            </StyledSidebarHeaderCaption>
            <StyledSidebarHeaderDownloadButton>
              <OverridedIconButton
                iconSize='24px'
                className='downloadBtn'
                iconKey='file_download'
                onButtonClick={handleDownload}
                width='auto'
                height='auto'
              />
            </StyledSidebarHeaderDownloadButton>
            <StyledSidebarHeaderCloseButton>
              <OverridedIconButton
                iconSize='24px'
                className='closeBtn'
                iconKey='close'
                onButtonClick={onCloseGallery}
                width='auto'
                height='auto'
              />
            </StyledSidebarHeaderCloseButton>
          </StyledHeader>
        </StyledHeaderRow>
        <StyledContent>
          <AttachmentViewer
            fileId={gallery.selectedFileId}
            fileName={gallery.selectedFileName}
            accountId={gallery.accountId}
            storage={storage}
            // withHeadline={false}
            onAttachmentDownload={handleDownload}
            metaData={{
              accountId: gallery.accountId,
              akteIdHash: gallery.akteIdHash,
              parentId: gallery.parentId,
            }}
          />
        </StyledContent>
        {gallery.thumbnails.length > 0 && (
          <StyledFooterRow>
            <ThumbnailSlider
              thumbnailsForSlider={gallery.thumbnails}
              onThumbnailClick={handleSelectAttachment}
              selectedThumbnailId={parseInt(gallery.selectedFileId)}
              accountId={gallery.accountId}
              akteIdHash={gallery.akteIdHash}
              parentId={gallery.parentId}
            />
          </StyledFooterRow>
        )}
      </StyledOverlayContent>
    </StyledOverlayDimmer>
  ) : (
    <React.Fragment></React.Fragment>
  )
}

// AttachmentGallery.propTypes = {
//     gallery: PropTypes.object.isRequired,
//     onThumbnailClick: PropTypes.func.isRequired,
//     onCloseGallery: PropTypes.func.isRequired,
//     downloadAttachment: PropTypes.func.isRequired
// }

export default AttachmentGallery
