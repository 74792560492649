import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { textHelpers } from 'application/common'
import { NavLink } from 'react-router-dom'

const StyledTagComponent = styled.div`
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    border: 1px solid ${props => props.theme.color.color15};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: ${p => p.padding};
    border-radius: ${p => p.borderradius};
    margin-right: 4px;
    margin-bottom: 4px;
    background-color: ${props => props.backgroundcolor !== '' && props.backgroundcolor !== null ? props.backgroundcolor : props.theme.color.mainAkte};
    border-color: ${props => props.backgroundcolor !== '' && props.backgroundcolor !== null ? props.backgroundcolor : props.theme.color.mainAkte};
    
    & > i {
        margin-right: ${p => p.text === '' ? '0px' : '4px' };
        font-size: ${props => props.fontSize !== undefined ? props.fontSize : props.theme.fontSize.small};
        align-self: center;
        justify-content: center;
        color:${props => props.theme.color.color0};
    }

    & > span {
        white-space: nowrap;
        align-self: center;
        font-size: ${props => props.fontSize !== undefined ? props.fontSize : props.theme.fontSize.small};
        font-weight: ${props => props.theme.fontSize.bold};
        color:${props => props.theme.color.color0};
    }
`

const StyledTagHref = styled.a`
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    border: 1px solid ${props => props.theme.color.color15};
    display: flex;
    text-decoration: none;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: ${p => p.padding};
    border-radius: ${p => p.borderradius};
    margin-right: 4px;
    margin-bottom: 4px;
    background-color: ${props => props.backgroundcolor !== '' && props.backgroundcolor !== null ? props.backgroundcolor : props.theme.color.mainAkte};
    border-color: ${props => props.backgroundcolor !== '' && props.backgroundcolor !== null ? props.backgroundcolor : props.theme.color.mainAkte};
    
    & > i {
        margin-right: ${p => p.text === '' ? '0px' : '4px' };
        font-size: ${props => props.fontSize !== undefined ? props.fontSize : props.theme.fontSize.small};
        align-self: center;
        justify-content: center;
        align-items: center;
        color:${props => props.theme.color.color0};
    }

    & > span {
        word-break: break-word;
        align-self: center;
        font-size: ${props => props.fontSize !== undefined ? props.fontSize : props.theme.fontSize.small};
        font-weight: ${props => props.theme.fontSize.bold};
        color:${props => props.theme.color.color0};
    }
`
const StyledTagNavlink = styled(NavLink)`
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    border: 1px solid ${props => props.theme.color.color15};
    display: flex;
    text-decoration: none;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: ${p => p.padding};
    border-radius: ${p => p.borderradius};
    margin-right: 4px;
    margin-bottom: 4px;
    background-color: ${props => props.backgroundcolor !== '' && props.backgroundcolor !== null ? props.backgroundcolor : props.theme.color.mainAkte};
    border-color: ${props => props.backgroundcolor !== '' && props.backgroundcolor !== null ? props.backgroundcolor : props.theme.color.mainAkte};
    
    & > i {
        margin-right: ${p => p.text === '' ? '0px' : '4px' };
        font-size: ${props => props.fontSize !== undefined ? props.fontSize : props.theme.fontSize.small};
        align-self: center;
        justify-content: center;
        align-items: center;
        color:${props => props.theme.color.color0};
    }

    & > span {
        word-break: break-word;
        align-self: center;
        font-size: ${props => props.fontSize !== undefined ? props.fontSize : props.theme.fontSize.small};
        font-weight: ${props => props.theme.fontSize.bold};
        color:${props => props.theme.color.color0};
    }
`

const TagCategoryComponent = ({
    pillClassName = '',
    backgroundcolor,
    iconKey = '',
    iconColor = '',
    text = '',
    disabled = false,
    isActive = true,
    borderradius = '5px',
    padding= '10px',
    fontSize,
    onTagClick = () => { },
    href = '',
    linkTo = '',
    truncate = false,
    displayTitle = false,
    truncateAt = 15,
    target = '_blank'
}) => {
    const textCategory = textHelpers.truncateMiddleText(text, truncateAt)
    if (href !== '') {
        return <StyledTagHref
            href={href}
            target={target}
            className={pillClassName}
            iconColor={iconColor}
            disabled={disabled}
            isActive={isActive}
            backgroundcolor={backgroundcolor}
            onClick={onTagClick}
            fontSize={fontSize}
            borderradius={borderradius}
            padding={padding}
            text={text}
        >

            {
                iconKey !== '' &&
                <i className="material-icons">{iconKey}</i>
            }
            <span>{truncate ? textCategory : text}</span>
        </StyledTagHref>
    }
    if (linkTo !== '') {
        return <StyledTagNavlink
            to={linkTo}
            className={pillClassName}
            iconColor={iconColor}
            disabled={disabled}
            backgroundcolor={backgroundcolor}
            onClick={onTagClick}
            fontSize={fontSize}
            borderradius={borderradius}
            padding={padding}
            text={text}
        >

            {
                iconKey !== '' &&
                <i className="material-icons">{iconKey}</i>
            }
            <span>{truncate ? textCategory : text}</span>
        </StyledTagNavlink>
    }
    return <StyledTagComponent
        className={pillClassName}
        iconColor={iconColor}
        disabled={disabled}
        isActive={isActive}
        backgroundcolor={backgroundcolor}
        onClick={onTagClick}
        fontSize={fontSize}
        borderradius={borderradius}
        padding={padding}
        text={text}
        title={displayTitle ? text : ''}
    >

        {
            iconKey !== '' &&
            <i className="material-icons">{iconKey}</i>
        }
        <span>{truncate ? textCategory : text}</span>
    </StyledTagComponent>
}

TagCategoryComponent.propTypes = {
    pillClassName: PropTypes.string,
    onPillClick: PropTypes.func,
    onPillAbort: PropTypes.func,
    hoverBackground: PropTypes.string,
    activeBackground: PropTypes.string,
    iconKey: PropTypes.string,
    iconColor: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool
}

export default TagCategoryComponent


