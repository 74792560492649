export const default_store = {
    auth: {
        registered: {
            accessToken: '',
            tokenSet: false,
            loggedIn: false,
            loginRequest: false,
            loginFailed: false,
            payloadFailed: null,
        },
        unregistered: {
            accessToken: '',
            tokenSet: false,
            loggedIn: false,
            loginRequest: false,
            loginFailed: false,
        },
        pin: {
            request: false,
            sent: false,
            failed: false,
        },
        forget: {
            username: {
                request: false,
                succeed: false,
                failed: false,
            },
            password: {
                request: false,
                succeed: false,
                failed: false,
            },
            newPassword: {
                request: false,
                succeed: false,
                failed: false,
            },
            errorMessage: '',
        },
        common: {
            user: {
                firstName: '',
                lastName: '',
                salutation: null,
                accountId: null,
                accountIds: [],
                userId: '',
                profilePicture: {
                    id: null,
                    isLoaded: false,
                    data: '',
                    has: false,
                },
            },
            returnUrl: '',
            logoutClicked: false,
            pushToDashboard: false,
            HandlePush: {
                url: '',
                pagePush: false,
            },
            errorMessage: '',
            previousAction: {},
        },

    },
    files: {
        thumbnails: [],
        fullFiles: [],
        mainLogo: [],
        secondaryLogo: [],
        profilePictureCreator: [],
    },
    akteMeta: {
        isLoaded: false,
        isLoading: false,
        failed: false,
        payload: {},
    },
    dialogs: {
        registeredDialogs: [],
        dialogsOpen: false,
    },
    gallery: {
        isVisible: false,
        accountId: null,
        parentId: null,
        selectedFileId: null,
        selectedFileName: '',
        thumbnails: [],
        inAufgabe: false,
        onQueue: {
            isVisible: false,
            accountId: null,
            parentId: null,
            selectedFileId: null,
            selectedFileName: '',
            selectedData: null,
            waitingToShow: false,
            send: {
                request: false,
                succeed: false,
                failed: false,
            },
        },
    },
    notifications: {
        toast: {
            message: '',
            messageType: '',
        },
    },
    waitingScreen: {
        active: false,
        inDialog: true,
        message: '',
        subMessages: [],
    },
    wizard: {
        isLoaded: false,
        currentStep: '',
        data: [],
        registrationData: {
            registered: {
                request: false,
                completed: false,
                failed: false,
            },
            regKey: '',
            usernameSet: false,
            usernameNotValid: false,
            usernameRequst: false,
            passwordSet: false,
            errorMessage: '',
            username: '',
            password: '',
            passwordConfirmation: '',
        },
    },
    pages: {
        dashboard: {},
        akten: {
            details: {
                isRequest: false,
                isLoaded: false,
                isFailed: false,
                onFolderIsRequest: false,
                onFolderIsLoaded: false,
                onFolderIsFailed: false,
                filesOnQueue: [],
                filesToSend: [],
                messages: [],
                messageCount: 0,
                messageFavorite: [],
            },
            sidebar: {
                key: '',
                visible: true,
            },
            common: {
                searchTerm: '',
                downloadMessage: {
                    request: false,
                    succeed: false,
                    failed: false,
                },
                metaInfo: null,
                messageRaw: null,
                folders: [],
                selectedAkte: null,
                foldersView: true,
                selectedFolder: null,
                breadcrumbsView: false,
                breadcrumbsData: [],
                messageOnQueue: [],
            },
            action: {
                markFavorite: {
                    id: null,
                    request: false,
                    succeed: false,
                    failed: false,
                },
                unmarkFavorite: {
                    id: null,
                    request: false,
                    succeed: false,
                    failed: false,
                },
                downloadMessage: {
                    id: null,
                    request: false,
                    succeed: false,
                    failed: false,
                },
                downloadAkte: {
                    request: false,
                    succeed: false,
                    failed: false,
                },
                sendMessage: {
                    id: null,
                    messageIdQueue: [],
                    request: false,
                    succeed: false,
                    failed: false,
                },
                readMessage: {
                    id: null,
                    request: false,
                    succeed: false,
                    failed: false,
                },
                newMessages: {
                    request: false,
                    succeed: false,
                    failed: false,
                },
                newMessagesOnFolder: {
                    request: false,
                    succeed: false,
                    failed: false,
                },
                newFiles: {
                    request: false,
                    succeed: false,
                    failed: false,
                },
            },
            errorMessage: '',
        },
        // serviceProvider: {

        // },
        settings: {
            about: {
                formRequest: false,
                formSent: false,
                formFailed: false,
            },
            account: {
                newPasswordRequest: false,
                newPasswordSucceed: false,
                newPasswordFailed: false,
            },
        },
    },
    common: {
        agbDatenschutz: {
            accountId: null,
            isAccepted: false,
            registeredDatenshutz: [],
            download: {
                request: false,
                succeed: false,
                failed: false,
                at: null,
            },
        },
        allAkten: {
            lastSync: null,
            isLoaded: false,
            isLoading: false,
            failed: false,
            payload: [],
        },
        allAccounts: {
            lastSync: null,
            isLoaded: false,
            isLoading: false,
            failed: false,
            payload: [],
        },
        accountDetails: {
            accountDetailsVisible: false,
            selectedAccount: {},
            publicInfoLoading: false,
            publicInfoLoaded: false,
            publicInfoFailed: false,
            publicInfo: null,
            activate: {
                accountId: null,
                request: false,
                succeed: false,
                failed: false,
            },
        },
        searchTerm: {
            akten: '',
        },
        accountPublicInfoInternalAssets: [],
    },
    messages: {
        registered: [],
        foldersAkte: [],
        favorites: {
            request: false,
            failed: false,
            succeed: false,
            payload: [],
        },
    },
    consentUseApp: {
        accept: false,
    },
    form: {
        metaData: null,
        withPin: false,
        authenticated: false,
        loginAttempt: 0,
        succeed: false,
        request: false,
        failed: false,
        redirected: false,
        payload: null,
        resultText: '',
        returnUrl: null,
        send: {
            id: null,
            request: false,
            succeed: false,
            failed: false,
            formData: null,
            files: null,
        },
        theme: {
            formElementBackground: '#fff',
            formContainerBackground: '#fff',
            // formElementBorder: '1px solid #aaa',
            // formElementBorderRadius: '0',
            buttonBackground: '#134f5c',
            // buttonTextColor: '#333',
            // fontFamily: 'Times New Roman',
            // error:'#ff0088',
        },
    },
    sidebars: {
        akten: {
            visible: false,
            title: 'cases',
            dialogVisible: false,
        },
        provider: {
            visible: false,
            titel: 'Service Provider',
            dialogVisible: false,
        },
        contactInfo: {
            visible: false,
            titel: 'Contact information',
            dialogVisible: false,
            payload: {},
        },
        navigationLinkMobile: {
            visible: false,
            title: '',
            dialogVisible: false,
            payload: {},
        },
    },
}

export default default_store
