import { api } from 'application/api'
import { AKTEN } from 'application/constants'
import { registeredMessageFullyLoaded } from 'application/redux/actions/messages'
import { getNewMessageOnFolderSucceed } from 'application/redux/actions/pages/akten'
import { call, put } from 'redux-saga/effects'
import { isMeAndMyRootExist } from '../messages/is_me_and_my_root_exist'
import { messageRawMapper } from './message_raw_mapper'

export function* getFolderDetailsSaga(action) {
    const folderId = action.folderId !== undefined ? action.folderId : action.metaData.folderId
    // check folder exist
    const { isMeExist } = yield isMeAndMyRootExist({ ...action, folderId, metaData: {} })
    if (isMeExist) {
        const resultFolderDetails = yield call(api.gateway.pages.getMessageDetailsOnFolder, action.accountId, action.akteIdHash, folderId)
        const metadata = yield messageRawMapper(resultFolderDetails, action.akteIdHash, action.accountId, AKTEN.TYPE.FOLDER)
        yield put(registeredMessageFullyLoaded(action.messageKey, metadata))
        yield put(getNewMessageOnFolderSucceed())
    }
}
