import { API_VERSION_ESYMANDANT, fetchHandler, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { fetchRequestBuilder } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const postLoginPin = (key, password) => {
    const payload = {
        key,
        password
    }

    const request = fetchRequestBuilder(API_VERSION_ESYMANDANT + '/authenticate/adhoc', HTTP_METHOD.POST, payload, true, false, true)

    // request.data.credentials = 'include'

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}