import React from 'react'
import * as Fragment from '../fragments'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import { useTranslation } from 'react-i18next'
import { TextInputPassword, TextInputWithLabel } from 'application/components/controls/form_elements'
import Form from 'application/components/controls/form_elements/form'
import { ErrorLabel } from 'application/components/fragments/error_information'
import { useForm } from 'react-hook-form'
import { textHelpers, validationMethods } from 'application/common'
import { AuthLayout } from 'application/components/pages/_layout'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import styled, { useTheme, withTheme } from 'styled-components'
import { LoginRedirect } from '../../_common'
import { FlexGrid } from 'application/components/fragments/grid'
import ActionButton from 'application/components/controls/button/action_button/component'
import { CONSENT, ROUTES } from 'application/constants'
import { NavLink, useParams } from 'react-router-dom'
import { ForgotUsernameEffects, LoginLoadEffect } from './effects'
import { nanoid } from 'nanoid'
import { lockedLoginAttemptTime } from 'application/storage'
import CountdownBanner from 'application/components/building_blocks/countdown_banner'
import WebHeaderLogoEffect from 'application/common/web_header_logo'
import { PluginAlternateView } from '../../../../../../plugins'

const StyledButtonInput = styled.div`
    input {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        align-items: stretch;
        flex-direction: row;
        flex-shrink: 0;
        border-style: solid;
        border-width: 1px;
        position: relative;
        z-index: 0;
        min-height: 0px;
        min-width: 80px;
        border-color: ${(props) => props.theme.color.color100};
        border-radius: 0px;
        height: 60px;
        justify-content: center;
        padding: 19px 24px;
        transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s, color 0.2s ease 0s;
        cursor: pointer;
        background-color: ${(props) => props.theme.color.color100};
        max-width: 100%;
        color: ${(props) => props.theme.color.color0};
        font-weight: ${(props) => props.theme.fontWeight.medium};
        font-size: ${(props) => props.theme.fontSize.medium18};
        line-height: 20px;
        visibility: visible;
        -webkit-font-smoothing: antialiased;
        text-rendering: geometricprecision;
        text-size-adjust: none;

        &::hover {
            background-color: ${(props) => props.theme.color.color90};
        }
    }
`

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`

const Login = (props) => {
    const { auth, onLoginFormSubmit, theme, refreshForgetPassword, consentUseApp, setConsent, refresPayloadFailed, accountPublicInfo } = props
    const { registered } = auth
    const { payloadFailed } = registered
    const { t } = useTranslation()
    const defaultValues = {
        username: '',
        password: '',
    }

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
    } = useForm({ defaultValues })
    const validationInput = {
        notEmpty: validationMethods.notEmpty,
    }

    const onSubmitSuccess = () => {
        onLoginFormSubmit(getValues())
        !consentUseApp.accept && setConsent(textHelpers._enText(CONSENT.ACCEPT_TEXT + new Date()))
    }

    const handleRefresForgetPassword = () => {
        refreshForgetPassword()
    }
    const onRefreshPayloadFailed = () => {
        refresPayloadFailed()
    }

    const { hashUserKey } = useParams()
    // console.log(theme.classes.loginTitle)
    // console.log(hashUserKey)

    return (
        <WebHeaderLogoEffect accountPublicInfo={accountPublicInfo}>
            <LoginRedirect {...props}>
                <ForgotUsernameEffects {...props}>
                    <LoginLoadEffect {...props}>
                        <AuthLayout accountUniqueKey={accountPublicInfo?.accountUniqueKey}>
                            <Fragment.LayoutInput>
                                <LanguageSwitchHoc />
                                <Fragment.FormLoginWrapper>
                                    <Fragment.TitleLoginWrapper>
                                        <Fragment.TitleLogin
                                            style={theme.classes.loginTitle}
                                        >
                                            {t('Login')}
                                        </Fragment.TitleLogin>
                                    </Fragment.TitleLoginWrapper>
                                    {auth.registered.loginRequest ? (
                                        <PreloaderSpin
                                            primaryColor={theme.color.primary}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            <Form onSubmit={handleSubmit(onSubmitSuccess)}>
                                                <TextInputWithLabel
                                                    disabled={lockedLoginAttemptTime.get() !== ''}
                                                    placeholder={t('username')}
                                                    name='username'
                                                    register={register}
                                                    validate={validationInput}
                                                    error={errors.username}
                                                    showMarkLabel={false}
                                                    errorMessageOverride='UsernameNotEmpty'
                                                    pushBottom={true}
                                                    htmlId={nanoid()}
                                                />
                                                <TextInputPassword
                                                    disabled={lockedLoginAttemptTime.get() !== ''}
                                                    placeholder={t('password')}
                                                    name='password'
                                                    register={register}
                                                    validate={validationInput}
                                                    error={errors.password}
                                                    errorMessageOverride={t('PasswordNotEmpty')}
                                                    showMarkLabel={false}
                                                    pushBottom={true}
                                                />
                                                <StyledButtonInput>
                                                    <input
                                                        style={theme.classes.loginButton}
                                                        type='submit'
                                                        value={t('login')}
                                                    />
                                                    {payloadFailed !== null && (payloadFailed.nextPossibleRetry !== undefined || payloadFailed.nextPossibleRetry !== null) && (
                                                        <CountdownBanner
                                                            infoText={'🔒' + t('Account is locked. Login attempt exceeded') + '. ' + t('Try again in')}
                                                            timeGiven={payloadFailed.nextPossibleRetry}
                                                            onComplete={onRefreshPayloadFailed}
                                                        />
                                                    )}
                                                </StyledButtonInput>
                                            </Form>
                                            <PushBottom24 />
                                            <FlexGrid justifySpaceBetween>
                                                <ActionButton
                                                    noPadding
                                                    noBackground
                                                    buttonText={t('Forgot Username')}
                                                    linkTo={ROUTES.FORGET_USERNAME}
                                                    color={theme.classes.forgotButton.color}
                                                    fontSize={theme.fontSize.small}
                                                />
                                                <ActionButton
                                                    noPadding
                                                    noBackground
                                                    buttonText={t('Forgot Password')}
                                                    linkTo={ROUTES.FORGET_PASSWORD}
                                                    color={theme.classes.forgotButton.color}
                                                    fontSize={theme.fontSize.small}
                                                    onButtonClick={handleRefresForgetPassword}
                                                />
                                            </FlexGrid>
                                            {auth.registered.loginFailed && payloadFailed === null && (
                                                <React.Fragment>
                                                    <PushBottom24 />
                                                    <ErrorLabel>{t(auth.common.errorMessage)}</ErrorLabel>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    <PushBottom24 />
                                    <PushBottom24 />
                                </Fragment.FormLoginWrapper>
                                <Fragment.LogoWrapper>
                                    <PluginAlternateView id="login.footer.logo">
                                        <Fragment.StyledBigLogo>
                                            <StyledNavLink to={ROUTES.BASE}>
                                                <Fragment.StyledImageBigLogo src='/assets/images/icon_esy_one.png' />
                                            </StyledNavLink>
                                        </Fragment.StyledBigLogo>
                                    </PluginAlternateView>
                                </Fragment.LogoWrapper>
                            </Fragment.LayoutInput>
                        </AuthLayout>
                    </LoginLoadEffect>
                </ForgotUsernameEffects>
            </LoginRedirect>
        </WebHeaderLogoEffect>
    )
}

export default withTheme(Login)
