import React, { Suspense } from 'react'

import { Provider } from 'react-redux'
import store from 'application/redux/create_store'

import { BrowserRouter } from 'react-router-dom'
import Routes from 'application/components/pages/_routes'

import { ThemeProvider } from 'styled-components'
import theme from 'application/style/theme'

import { resources } from 'application/translation'

import { PluginsContext, usePlugins } from 'plugins'
import { mykPlugin, wouragePlugin } from 'plugins/customers'

export const App = () => {
    //TN::: I think it's a good idea to inject the plugins list from outside, to be more open for changes in the future 
    const pluginsProvider = usePlugins([mykPlugin, wouragePlugin], {
        defaultTheme: theme,
        // using resource here is not an ideal solution might change later for more dynamic approach
        defaultLocalization: resources,
    })

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Provider store={store}>
                <PluginsContext.Provider value={pluginsProvider}>
                    <ThemeProvider theme={pluginsProvider.theme}>
                        <BrowserRouter>
                            <Routes />
                        </BrowserRouter>
                    </ThemeProvider>
                </PluginsContext.Provider>
            </Provider>
        </Suspense>
    )
}

export default App
