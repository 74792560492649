import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'

const FavoriteMessagesLoader = ({ allAktenLoaded, getMessageFavorite, messageKey, children, accountId, akteIdHash, folderId, favoriteLoaded }) => {
    useEffect(() => {
        allAktenLoaded && !favoriteLoaded && getMessageFavorite(messageKey, accountId, akteIdHash, folderId)
    }, [allAktenLoaded, favoriteLoaded, messageKey, getMessageFavorite, accountId, akteIdHash, folderId])
    return <Fragment>{children}</Fragment>
}

FavoriteMessagesLoader.propTypes = {
    allAktenLoaded: PropTypes.bool,
    getMessageFavorite: PropTypes.func,
    messageKey: PropTypes.string,
    children: PropTypes.element,
    accountId: PropTypes.number,
    akteIdHash: PropTypes.string,
    folderId: PropTypes.string,
}

export default FavoriteMessagesLoader
