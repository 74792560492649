import React from 'react'
import RedirectDeletedAkteFolder from './redirect_deleted_akte_folder'
import RedirectDeletedAkteRoot from './redirect_deleted_akte_root'

const RedirectDeletedMessage = (props) => {
    const { children } = props
    return <RedirectDeletedAkteRoot {...props}>
        <RedirectDeletedAkteFolder {...props}>
            {children}
        </RedirectDeletedAkteFolder>
    </RedirectDeletedAkteRoot>
}

export default RedirectDeletedMessage
