import { wizardDefinitions } from './wizard_definitions'

const mapSectionToWizard = (section) => {
    var sections = {
        'REGISTRATION': () => (wizardDefinitions.registration),
        'DEFAULT': () => (wizardDefinitions.registration),
    }
    return (sections[section] || sections['DEFAULT'])()
}

export const getWizards = (action = { section: 'DEFAULT' }) => {
    return mapSectionToWizard(action.wizardType)
}