import { api } from 'application/api'
import { getAllAccounts } from 'application/redux/selectors'
import { call, select } from 'redux-saga/effects'
import { responseArray } from '../common/all_akten/function'

export function* getUpdatedAktenPayloadSaga() {
    const accounts = yield select(getAllAccounts)
    const response = yield call(api.gateway.common.getAllAkten)
    const messages = responseArray(response, accounts)

    return messages
}