import ACTIONS from 'application/constants'

export const getCommonAllAktenRequest = () => ({
    type: ACTIONS.COMMON_ALL_AKTEN_REQUEST_DATA,
})

export const getAktenInOneAccount = (accountId) => ({
    type: ACTIONS.COMMON_ALL_AKTEN_IN_ONE_ACCOUNT_REQUEST_DATA,
    accountId,
})

export const updateAkten = (params, folderId = '', messageKey = '') => ({
    type: ACTIONS.COMMON_ALL_AKTEN_UPDATE_DATA,
    params,
    folderId,
    messageKey
})

export const updateAktenFailed = (params) => ({
    type: ACTIONS.COMMON_ALL_AKTEN_UPDATE_DATA_FAILED,
    params,
})

export const populateCommonAllAktenData = (payload) => ({
    type: ACTIONS.COMMON_ALL_AKTEN_POPULATE_DATA,
    payload
})

export const unregisterAkte = (idHash) => ({
    type: ACTIONS.COMMON_ALL_AKTEN_UNREGISTER_AKTE,
    idHash
})

export const getCommonAllAktenFailed = () => ({
    type: ACTIONS.COMMON_GET_ALL_AKTEN_FAILED,
})

export const populateAllAktenInOneAccountData = (payload) => ({
    type: ACTIONS.COMMON_ALL_AKTEN_IN_ONE_ACCOUNT_POPULATE_DATA,
    payload
})

export const putLastMessage = (akteIdHash, message) => ({
    type: ACTIONS.COMMON_ALL_AKTEN_PUT_LAST_MESSAGE,
    akteIdHash,
    message
})

export const refreshAllAktenDAta = () => ({
    type: ACTIONS.COMMON_REFRESH_ALL_AKTEN_DATA,
})

export const recountUnreadMessage = (akteIdHash) => ({
    type: ACTIONS.COMMON_RECOUNT_UNREAD_MESSAGE,
    akteIdHash
})

export const updateSingleAkte = (akteIdHash, payload) => ({
    type: ACTIONS.COMMON_UPDATE_SINGLE_AKTE,
    akteIdHash,
    payload
})

export const recountUnreadMessageInFolder = (akteIdHash) => ({
    type: ACTIONS.COMMON_RECOUNT_UNREAD_MESSAGE_IN_FOLDER,
    akteIdHash,
})

export const getCreatorProfilePicture = (accountId, creatorIdHash, akteIdHash) => ({
    type: ACTIONS.COMMON_GET_CREATOR_PROFILE_PICTURE_REQUEST,
    accountId,
    creatorIdHash,
    akteIdHash
})

export const registerCreatorProfilePicture = (accountId, creatorIdHash, akteIdHash) => ({
    type: ACTIONS.COMMON_REGISTER_CREATOR_PROFILE_PICTURE,
    accountId,
    creatorIdHash,
    akteIdHash
})

export const populateCreatorProfilePicture = (akteIdHash, data, creatorIdHash,) => ({
    type: ACTIONS.COMMON_POPULATE_CREATOR_PROFILE_PICTURE,
    akteIdHash,
    data,
    creatorIdHash
})

export const creatorProfilePictureHasNoData = (creatorIdHash) => ({
    type: ACTIONS.COMMON_CREATOR_PROFILE_PICTURE_HAS_NO_DATA,
    creatorIdHash,
})