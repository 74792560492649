import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { getAkteMetaFailed, populateAkteMeta } from 'application/redux/actions/akte_meta'
import { hashUserKey } from 'application/storage'
import { call, put, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../errors'

function* getAkteMetaRequestSaga(action) {
    try {
        const response = yield call(api.gateway.akteMeta.getAkteMetaData, action.hashUserKey)
        hashUserKey.store(action.hashUserKey)
        yield put(populateAkteMeta(response))
    } catch (e) {
        const error = {
            ...e,
            data : {
                ...e.data,
                reasonMessage: 'LinkInvalid'
            }
        }
        yield handleError(error, action)
        yield put(getAkteMetaFailed())
    }
}

export function* akteMetaWatcher() {
    yield takeEvery(ACTIONS.AKTE_META_DATA_REQUEST, getAkteMetaRequestSaga)
}