import React from 'react'
import PropTypes from 'prop-types'
import { CurrentLocationMobile, CurrentLocationName, LocationContainer } from './fragments'
import { IconButtonWithLabel } from 'application/components/controls/icon'
import { useSearchParams } from 'react-router-dom'
import { ROUTE_PARAMETERS } from 'application/constants'
import SnippetFolderRoundedIcon from '@mui/icons-material/SnippetFolderRounded'
import FolderZipRoundedIcon from '@mui/icons-material/FolderZipRounded'
import { withTheme } from 'styled-components'

const CurrentLocation = ({ currentLocationName, parentName, parentId, rootId, isRoot, backToParent, theme }) => {
	let toRoot = parentId === rootId
	const [searchParams] = useSearchParams()
	let inFolder = searchParams.get(ROUTE_PARAMETERS.AKTEN.FOLDER_ID)
    return (
        <CurrentLocationMobile>
            {!isRoot && (
                <LocationContainer>
                    <IconButtonWithLabel
                        padding='0px'
                        justifyContent='flex-start'
                        backgroundColor={'transparent'}
                        onButtonClick={() => backToParent(parentId, toRoot)}
                        iconKey='chevron_left'
                        label={parentName}
                        activeBackground='transparent'
                        hoverBackground='transparent'
                    />
                </LocationContainer>
            )}
            <LocationContainer>
				<CurrentLocationName isRoot={isRoot}>
					{
					inFolder && <SnippetFolderRoundedIcon sx={{ color: theme.color.folderColor, fontSize: 20 }} />
					}
					{
						isRoot && <FolderZipRoundedIcon sx={{ color: theme.color.aktenSourceColor, fontSize: 20 }} />
					}
					{currentLocationName}
				</CurrentLocationName>
				
            </LocationContainer>
        </CurrentLocationMobile>
    )
}

CurrentLocation.propTypes = {
    currentLocationName: PropTypes.string,
    parentName: PropTypes.string,
    parentId: PropTypes.string || PropTypes.number,
    rootId: PropTypes.string || PropTypes.number,
    isRoot: PropTypes.bool,
    backToParent: PropTypes.func,
}

export default withTheme(CurrentLocation)
