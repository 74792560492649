import React, { Fragment } from 'react'
import { isMobileOnly, isTablet, withOrientationChange } from 'react-device-detect'
import { LoginSpecificAccountMobile } from '../../../mobile/auth'
import { TabletPortrait } from '../../../tablet'

const AccountPublicInfoMobileRedirect = (props) => {
    const { isPortrait, publicInfoLoaded } = props
    const useTablet = isTablet

    if (isMobileOnly) {
        return publicInfoLoaded && <LoginSpecificAccountMobile {...props} />
    }
    if (useTablet && (isPortrait)) {
        return publicInfoLoaded && <TabletPortrait.Auth.LoginSpecificAccountTabletPortrait {...props} />
    }
    return <Fragment>
        {props.children}
    </Fragment>
}

export default withOrientationChange(AccountPublicInfoMobileRedirect)
