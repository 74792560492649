import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { calculateTimeLeft } from './helpers'

const StyledCountDown = styled.span`
    color: ${p => p.theme.color.color40};
    font-size: ${p => p.theme.fontSize.small};
    font-weight: ${p => p.theme.fontWeight.bold};
    display: flex;
    justify-content: center;
`

const CountdownWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 2px;
    margin: 0px 4px;
    align-self: flex-end;
`

const CountdownComponent = ({ timeGiven, onComplete }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(timeGiven))

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft(timeGiven))
        }, 1000)
    })

    const timerComponents = []

    Object.keys(timeLeft).forEach((interval) => {
        const seconds = interval === 'seconds'
        if (!timeLeft[interval]) {
            return
        }

        timerComponents.push(
            <StyledCountDown>
                {
                    seconds ? timeLeft[interval] : timeLeft[interval] + ':'
                }
            </StyledCountDown>
        )
    })

    if (!timerComponents.length) {
        onComplete()
    }
    return <CountdownWrapper>
        {timerComponents.length ? timerComponents : <StyledCountDown>Time's up!</StyledCountDown>}
    </CountdownWrapper>
}

export default CountdownComponent
