import LoadEffect from 'application/common/load_effect'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid } from 'application/components/fragments/grid'
import React, { useEffect } from 'react'
// import { Fragment } from 'react'
// import DisplayDatenshutz from './display_datenshutz'

const DatenshutzLoader = (props) => {
    const { registeredDatenshutz, accountId, getDatenshutzAccount, accountName, children, userRegistered } = props
    //check if datenshutz is registered in redux store
    const datenshutzRegistered = registeredDatenshutz.filter(e => e.accountId === accountId).length > 0

    //preloader display depends on this value
    const datenshutzLoading = (datenshutzRegistered && !registeredDatenshutz.filter(e => e.accountId === accountId)[0].isLoaded)

    //fallback datenshutz display depends on this value
    const datenshutzFailed = datenshutzRegistered ? registeredDatenshutz.find(t => t.accountId === accountId).failed : false

    //datenshutz data
    // const datenschutz = datenshutzRegistered ? registeredDatenshutz.find(t => t.accountId === accountId).datenschutz : null
    useEffect(() => {
        !datenshutzRegistered && getDatenshutzAccount(accountId, accountName, userRegistered)
    }, [accountId, accountName, datenshutzRegistered, getDatenshutzAccount, userRegistered])
    
    if (datenshutzFailed) {
        return <FlexGrid justifyCenter itemsCenter>
            <ImagePlaceHolder
                source='/assets/images/nodatadatenshutz.png'
                large='400px'
                withBorder={false}
            />
        </FlexGrid>
    }
    return <LoadEffect loading={datenshutzLoading}>
        {children}
    </LoadEffect>
}

export default DatenshutzLoader
