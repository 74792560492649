import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { ROUTES } from 'application/constants'
import React from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { LayoutBase } from '../../_layout'
import ContentNotFound from './content_not_found'
import * as Fragments from './fragments'
import { PluginAlternateView } from 'plugins'

const NotFoundPageBigfoot = () => {
    const maxWidthImageWrapper = isMobile ? '100%' : '50%'
    return <LayoutBase withToaster={false}>
        <PluginAlternateView id='notfound.bigfoot'>
            <Fragments.Layout>
                    <FlexGrid directionColumn>
                        <FlexGridItem grow={1} itemsFlexEnd justifyCenter>
                            <Fragments.StyledNavLink to={ROUTES.BASE}>
                                <ImagePlaceHolder large='50px' withBorder={false} source='/assets/images/icon_esy_one.png' />
                            </Fragments.StyledNavLink>
                        </FlexGridItem>
                        <FlexGrid>
                            <FlexGridItem maxWidth={maxWidthImageWrapper}>
                                <ImagePlaceHolder toCenter large={isTablet ? '40%' : '90%'} height='100%' withBorder={false} source='/assets/images/404_bigfoot.png' />
                            </FlexGridItem>
                            <FlexGridItem itemsCenter>
                                <ContentNotFound withDesc flexStart maxWidth='100%' />
                            </FlexGridItem>
                        </FlexGrid>
                    </FlexGrid>
            </Fragments.Layout>
        </PluginAlternateView>

    </LayoutBase> 
}

export default NotFoundPageBigfoot
