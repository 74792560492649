import { populateTotalUnredMessageEachAccount } from 'application/redux/actions/common/all_accounts'
import { put } from 'redux-saga/effects'

export function* putTotalUnreadMessageEachAccountSaga(action) {
    const { akten } = action
    for (const a of akten) {
        const totalUnreadMessage = +a.totalUnreadMessage + +a.totalUnreadMessageInFolder
        yield put(populateTotalUnredMessageEachAccount(a.accountId, totalUnreadMessage))
    }
    yield
}