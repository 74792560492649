import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import ThumbnailComponent from 'application/components/building_blocks/attachments/thumbnail'
import { fileHelpers } from 'application/common'

const StyledSlider = styled.div`
    max-width: calc(100% - 100px);
    margin: auto;
    & .slick-arrow:before{
        color: ${props => props.theme.color.color70}
    }
`
export const shouldLoadAttachment = (extension) => {
    const allowedExtensions = process.env.REACT_APP_SUPPORTED_THUMBNAIL_EXTENSIONS
    const extensionsArray = allowedExtensions.split(',')
    return extensionsArray.indexOf(extension.toLowerCase()) > -1
}
const ThumbnailSliderOnQueue = ({
    thumbnailsForSlider, //thumbnails for this slider
    onThumbnailClick,
    selectedThumbnailId,
    accountId,
}) => {
    const sliderSettings = {
        dots: false,
        infinite: false, //weird behavior if used with effect
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true, //bit buggy?
        variableWidth: true,
        centerPadding: '0'
    }

    const slider = useRef(null)

    useEffect(() => { //scroll to selected thumbnail on init
        var currentIndex = 0
        if (thumbnailsForSlider.length > 1) {
            currentIndex = thumbnailsForSlider.map(x => x.attachment.fileId).indexOf(selectedThumbnailId)
            slider.current.slickGoTo(currentIndex)
        }
    }, [selectedThumbnailId, thumbnailsForSlider])

    return (
        thumbnailsForSlider.length > 1 && <StyledSlider>
            <Slider ref={slider} {...sliderSettings}>
                {
                    thumbnailsForSlider.map((thumbnail, index) => {
                        const current = thumbnail.attachment
                        const attachment = {
                            fileId: current.fileId,
                            accountId,
                            hasThumbnail: shouldLoadAttachment(fileHelpers.getFileExtension(current.fileName)),
                            thumbnailIsLoading: false,
                            thumbnail: current.thumbnail,
                            fileExtension: fileHelpers.getFileExtension(current.fileName),
                            fileName: current.fileName,
                        }
                        return <ThumbnailComponent
                            key={index}
                            attachment={attachment}
                            onAttachmentClick={onThumbnailClick}
                            isActive={current.fileId === selectedThumbnailId}
                            showCaption={false}
                        />
                    })
                }
            </Slider>
        </StyledSlider>
    )
}

export default ThumbnailSliderOnQueue