import { useLocation } from 'react-router-dom'

export const useQuery = () => {
    const query = new URLSearchParams(useLocation().search)
    query.getParsedParam = (paramName, defaultValue = 0) => typeof query.get(paramName) === 'string' && query.get(paramName) !== '' && !isNaN(query.get(paramName)) ? parseInt(query.get(paramName)) : defaultValue
    query.getStringParam = (paramName, defaultValue = '') => typeof query.get(paramName) === 'string' && query.get(paramName) !== '' ? query.get(paramName) : defaultValue
    const all = []
    for (const param of query) {all.push(param)}
    query.all = Array.from(all, (a) => ({key: a[0], value: a[1]}))
    return query
}