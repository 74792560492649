import ActionButton from 'application/components/controls/button/action_button/component'
import {FlexGrid, FlexGridItem, PushBottom10 } from 'application/components/fragments/grid'
import { ROUTES } from 'application/constants'
import React, { useContext } from 'react'
import { WizardFooterRight } from 'application/components/pages/_layout/fragments/wizard'
import { ImageWizard } from '../../fragments'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { Navigate } from 'react-router-dom'
import FinalWizardRedirect from './final_redirect'
import { accessTokenRegistered, accessTokenUnregistered } from 'application/storage'
import { useTranslation } from 'react-i18next'
import TopContentWizardReg from '../_common/top_content'
import SuccessTitleRegistration from './sections/success_title'
import RedirectMobile from 'application/common/redirect_mobile'
import { WizardMobileVersion } from '../../../mobile'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { useTheme } from 'styled-components'

const WizardRegistratioFinalComponent = (props) => {

    const { wizardData, wizardLoaded, wizardDone, auth, akteMeta } = props
    const { t } = useTranslation()
    const {getAsset, activePlugin} = usePluginsContext()
    const theme = useTheme()
    const accountName = akteMeta.accountName
    const mobileProps = {
        ...props,
        accountName
    }

    if (!wizardLoaded) return <Navigate to={ROUTES.WIZARD_REG_WELCOME} />
    return <FinalWizardRedirect {...props}>
        <RedirectMobile
                MobileComponent={WizardMobileVersion.FinalWizardMobile}
                {...mobileProps}
            >
                <TopContentWizardReg accountId={akteMeta.accountId} wizardData={wizardData} />
                <FlexGridItem grow={0}>
                    <FlexGrid>
                            <FlexGridItem basis='35%'>
                                <FlexGrid directionColumn>
                                    {!activePlugin &&
                                        <ImageWizard
                                            imgWidth='55%'
                                            src={getAsset('welcome_mandant_compressed/fast_communication.png')}
                                            alt='Congrats!! you are a user now'
                                        />
                                    }
                                    <SuccessTitleRegistration accountName={accountName} />
                                </FlexGrid>
                            </FlexGridItem>
                        <FlexGridItem basis='35%' justifyFlexEnd />
                    </FlexGrid>
                </FlexGridItem>
                <FlexDividerHorizontal />
                <PushBottom10 />
                <WizardFooterRight>
                    {
                        akteMeta.userStatus === 0 || auth.unregistered.loggedIn ?
                            <ActionButton
                                backgroundColor={theme.color.primary}
                                buttonText={t('zum Dashboard')}
                                onButtonClick={() => wizardDone(accessTokenUnregistered.get())}
                            /> : auth.registered.loggedIn ? <ActionButton
                                backgroundColor={theme.color.primary}
                                buttonText={t('zum Dashboard')}
                                onButtonClick={() => wizardDone(accessTokenRegistered.get())}
                                linkTo={ROUTES.DASHBOARD}
                            /> : <ActionButton
                                backgroundColor={theme.color.primary}
                                buttonText={t('login')}
                                linkTo={ROUTES.LOGIN}
                            />
                    }
                </WizardFooterRight>
            </RedirectMobile>
    </FinalWizardRedirect>
}

export default WizardRegistratioFinalComponent