import ActionButton from 'application/components/controls/button/action_button/component'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { Spacer } from 'application/components/pages/internal/dashboard/fragments'
import { ROUTES } from 'application/constants'
import { accessTokenRegistered, accessTokenUnregistered } from 'application/storage'
import React from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import SuccessTitleRegistration from '../../wizard/registration/final/sections/success_title'
import * as WizardMobileStyled from './styles'
import { useContext } from 'react'
import { PluginAlternateView } from 'plugins'
import { useTheme } from 'styled-components'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const FinalWizardMobile = (props) => {
    const { accountName, akteMeta, auth, wizardDone } = props
    const { t } = useTranslation()
    const { getAsset, activePlugin } = usePluginsContext()
    const theme = useTheme()
    return (
        <WizardMobileStyled.Layout height={'100%'}>
            <WizardMobileStyled.ContainerImage>
                <WizardMobileStyled.Image
                    // src='/assets/images/registration_mobile_done.png'
                    src={
                        getAsset('registration_mobile_done.png')
                    }
                />
            </WizardMobileStyled.ContainerImage>
            <WizardMobileStyled.Container>
                <SuccessTitleRegistration className={'succeed-reg-title-ec_mobile'} accountName={accountName} />
            </WizardMobileStyled.Container>
            <WizardMobileStyled.Container>
                <FlexGridItem grow={1} justifyCenter itemsFlexEnd>
					<FlexGrid directionColumn>
						 {akteMeta.userStatus === 0 || auth.unregistered.loggedIn ? (
                        <ActionButton
                            backgroundColor={theme.color.primary}
                            buttonText={t('zum Dashboard')}
                            onButtonClick={() => wizardDone(accessTokenUnregistered.get())}
                        />
                    ) : auth.registered.loggedIn ? (
                        <ActionButton
                            backgroundColor={theme.color.primary}
                            buttonText={t('zum Dashboard')}
                            onButtonClick={() => wizardDone(accessTokenRegistered.get())}
                            linkTo={ROUTES.DASHBOARD}
                        />
                    ) : (
                        <ActionButton
                            backgroundColor={theme.color.primary}
                            buttonText={t('login')}
                            linkTo={ROUTES.LOGIN}
                        />
						)}
						<Spacer />
						<FlexDividerHorizontal />
                        {!activePlugin &&
                            <WizardMobileStyled.ParLine>
                                {t('Or log in app.')}
                            </WizardMobileStyled.ParLine>
                        }

                        {isIOS && 
                            (<PluginAlternateView>
                                <FlexGridItem justifyCenter>
                                    <ImagePlaceHolder linkHref={process.env.REACT_APP_INSTALL_APP_IOS} large='150px' withBorder={false} source='/assets/images/app-store-badge.png' />
                                </FlexGridItem>
                            </PluginAlternateView>)
                        }
                        {isAndroid && 
                            (<PluginAlternateView>
                                <FlexGridItem justifyCenter>
                                    <ImagePlaceHolder
                                        linkHref={process.env.REACT_APP_INSTALL_APP_ANDROID}
                                        large='150px'
                                        withBorder={false}
                                        source='/assets/images/google-play-badge.png'
                                    />
                                </FlexGridItem>
                            </PluginAlternateView>)
                        }
                    </FlexGrid>
                </FlexGridItem>
            </WizardMobileStyled.Container>
        </WizardMobileStyled.Layout>
    )
}

export default FinalWizardMobile
