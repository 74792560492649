import ACTIONS from 'application/constants'

export const openProviderPopupSidebar = () => ({
    type: ACTIONS.SIDEBAR_OPEN_PROVIDER
})

export const closeProviderPopupSidebar = () => ({
    type: ACTIONS.SIDEBAR_CLOSE_PROVIDER
})

export const setDialogVisibleProviderSidebar = (condition) => ({
    type: ACTIONS.SIDEBAR_SET_DIALOG_VISIBLE_PROVIDER,
    condition
})