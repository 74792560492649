import { StyledButtonInput } from 'application/components/pages/external/mobile_assistant/fragments'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

export function RequestPinButton({ handleRequestPin }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <StyledButtonInput>
      <input
        style={{
          background: theme.color.primary,
          fontFamily: 'Verdana',
          borderRadius: '6px',
          border: 'none',
        }}
        type='button'
        value={t('RequestPIN')}
        onClick={handleRequestPin}
      />
    </StyledButtonInput>
  )
}
