import ACTIONS from 'application/constants'
import { getNewMessagesFailed, markFavoriteMessageFailed, unmarkFavoriteMessageFailed } from 'application/redux/actions/pages/akten'
import { getAkteMetaInfoSaga } from './get_akte_meta_info_saga'
import { getFilesFromMessagesSaga } from './get_files_from_messages_saga'
import { getFolderDetailsSaga } from './get_folder_details_saga'
import { getMessagesSaga } from './get_messages_saga'
import { getSingleAkteSaga } from './get_single_akte_saga'
import { markFavoriteMessageSaga } from './mark_favorite_message_saga'
import { sendMessageAkteSaga } from './send_message_akte_saga'
import { unmarkFavoriteMessageSaga } from './unmark_favorite_message_saga'
import { getNewMessagesAkteSaga } from './get_new_messages_akte_saga'
import { handleAkteFolderNotExistOnNewLoad, handleAkteForbidden, handleError } from '../../errors'
import { sendMessageErrorSaga } from '../../errors/send_message_error_saga'

export const getActionAkten = (type) => {
    switch (type) {
        case ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS:
            return [getAkteMetaInfoSaga, getMessagesSaga, getSingleAkteSaga]
        case ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS_ON_FOLDER:
            return [getAkteMetaInfoSaga, getFolderDetailsSaga]
        case ACTIONS.PAGES_AKTEN_SEND_MESSAGE_REQUEST:
            return [sendMessageAkteSaga]
        case ACTIONS.PAGES_AKTEN_NEW_FILES_REQUEST:
            return [getFilesFromMessagesSaga]
        case ACTIONS.PAGES_AKTEN_NEW_MESSAGE_REQUEST:
            return [getNewMessagesAkteSaga]
        case ACTIONS.PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_REQUEST:
            return [getFolderDetailsSaga]
        case ACTIONS.PAGES_AKTEN_MARK_FAVORITE_MESSAGE_REQUEST:
            return [markFavoriteMessageSaga]
        case ACTIONS.PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_REQUEST:
            return [unmarkFavoriteMessageSaga]
        case ACTIONS.PAGES_AKTEN_NEW_FOLDERS_REQUEST:
            return [getMessagesSaga]
        default:
            break
    }
}
export const getHandleFailed = (type, key, e, action) => {
    switch (type) {
        case ACTIONS.PAGES_AKTEN_SEND_MESSAGE_REQUEST:
            return sendMessageErrorSaga(e, action)
        case ACTIONS.PAGES_AKTEN_NEW_MESSAGE_REQUEST:
            return getNewMessagesFailed()
        case ACTIONS.PAGES_AKTEN_MARK_FAVORITE_MESSAGE_REQUEST:
            return markFavoriteMessageFailed()
        case ACTIONS.PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_REQUEST:
            return unmarkFavoriteMessageFailed()
        case ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS:
            return handleAkteForbidden(e, key)
        case ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS_ON_FOLDER:
            return handleAkteFolderNotExistOnNewLoad(e, action.akteIdHash, key)
        default:
            return handleError
    }
}
