import ACTIONS from 'application/constants'
import default_store from '../../default_store'

const settingsReducer = (state = default_store.pages.settings, action) => {
    switch (action.type) {
    case ACTIONS.PAGES_SETTINGS_ABOUT_FORM_REQUEST: return {
        ...state,
        about: {
            ...state.about,
            formRequest: true,
        }
    }
    case ACTIONS.PAGES_SETTINGS_ABOUT_FORM_SUCCEED: return {
        ...state,
        about: {
            ...state.about,
            formRequest: false,
            formSent: true,
        }
    }
    case ACTIONS.PAGES_SETTINGS_ABOUT_FORM_FAILED: return {
        ...state,
        about: {
            ...state.about,
            formRequest: false,
            formSent: false,
            fromFailed: true,
        }
    }
    case ACTIONS.PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_REQUEST: return {
        ...state,
        account: {
            ...state.account,
            newPasswordRequest: true,
        }
    }
    case ACTIONS.PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_SUCCEED: return {
        ...state,
        account: {
            ...state.account,
            newPasswordRequest: false,
            newPasswordSucceed: true,
        }
    }
    case ACTIONS.PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_FAILED: return {
        ...state,
        account: {
            ...state.account,
            newPasswordRequest: false,
            newPasswordSucceed: false,
            newPasswordFailed: true,
        }
    }
    default:
        return state
    }
}

export default settingsReducer