import React from 'react'
import {
    isMobileOnly,
    withOrientationChange
} from 'react-device-detect'
import BaseRedirect from './base_redirect'

const BaseDetectDeviceComponent = (props) => {
    const useMobile = isMobileOnly
    const customProps = {
        useMobile,
        ...props
    }
    return <BaseRedirect {...customProps}>
        {props.children}
    </BaseRedirect>
}

const BaseDetectDevice = withOrientationChange(BaseDetectDeviceComponent)
export default BaseDetectDevice