import { createContext } from 'react'

export const PluginsContext = createContext({
  theme: {},
  config: {},
  // is it resources or localization for better naming?
  localization: {},
  activePlugin: null,
  getComponent(id) { },
  getAsset(filename){ },
  setUserAccountId(accountId){},
  getUserAccountId(){},
})

export const PluginsProvider = (props) => {
  const { children, value } = props

  return (
    <PluginsContext.Provider value={value}>
      {children}
    </PluginsContext.Provider>
  )
}
