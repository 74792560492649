import React from 'react'
import PropTypes from 'prop-types'
import * as Fragments from './fragments'
import { ThumbnailContainer } from 'application/components/building_blocks/attachments/thumbnail'
import { useTranslation } from 'react-i18next'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { PluginAlternateView } from 'plugins'

const FilesContent = ({ files, onOpenGallery, accountId, parentId, akteIdHash }) => {
	const {t} = useTranslation()
    const { activePlugin } = usePluginsContext()
    return (
        <Fragments.LayoutContentAktenMobile>
            {files.length !== 0 ? (
                <Fragments.WrapperContentAktenMobile>
                    {files
                        ?.sort((a, b) => b.id - a.id)
                        .map((s, i) => {
                            return (
                                <ThumbnailContainer
                                    key={i}
                                    fileId={s.id}
                                    fileName={s.fileName}
                                    messageId={s.messageId}
                                    akteIdHash={akteIdHash}
                                    parentId={parentId}
                                    onAttachmentClick={() => onOpenGallery(s.id, s.fileName, accountId, parentId, akteIdHash, files)}
                                    showCaption={true}
                                    marginRight={false}
                                    isSizeFixed={true}
                                    divHeight='90px'
                                    divWidth='90px'
                                    creationDate={s.creationDate}
                                    accountId={accountId}
                                />
                            )
                        })}
                </Fragments.WrapperContentAktenMobile>
            ) : (
                <PluginAlternateView id='mobile_view.akten.files'>
                    <Fragments.CardNoContentWrapper>
                        <Fragments.ImageNoContent src='/assets/images/no_files.png' />
                        <Fragments.ParNoContent>{t('No files')}</Fragments.ParNoContent>
                    </Fragments.CardNoContentWrapper>
                </PluginAlternateView>
            )}
        </Fragments.LayoutContentAktenMobile>
    )
}

FilesContent.propTypes = {
    files: PropTypes.array,
    onOpenGallery: PropTypes.func,
    accountId: PropTypes.string || PropTypes.number,
    akteIdHash: PropTypes.string || PropTypes.number,
    parentId: PropTypes.string || PropTypes.number,
}

export default FilesContent
