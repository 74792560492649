import { textHelpers } from 'application/common'
import GravatarWithInitial from 'application/components/controls/gravatar'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { DASHBOARD, ROUTES } from 'application/constants'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import ProfilePictureLoader from '../profile_picture_loader'
const StyledNavlinkImage = styled(NavLink)`
    text-decoration: none;
    cursor: pointer;
        color: inherit;

    &:hover {
        color: inherit
        }
    &:focus {
        color: inherit
        }
    &:active {
        color: inherit
        }
`
const ProfilePicture = ({isCircle = true, radius = '100%', radiusGravatar = '100%', profileLarge = '50px', gravatarLarge = '50', profileHeight = '50px', ...props}) => {
    const { profilePicture, auth, theme, accountPublicInfo } = props
    const firstName = auth.common.user.firstName
    const lastName = auth.common.user.lastName
    const initials = textHelpers.getInitials(firstName, lastName)
    return <ProfilePictureLoader {...props}>
	  {
            profilePicture.isLoaded && profilePicture.has ? <StyledNavlinkImage to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.ACCOUNT}>
                <ImagePlaceHolder
                    isClick={true}
                    isCircle={isCircle}
                    source={profilePicture.data}
                    large={profileLarge}
                    height={profileHeight}
                    toCenter={true}
                    radius={radius}
                />
            </StyledNavlinkImage> : <StyledNavlinkImage to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.ACCOUNT}>
                {accountPublicInfo && (
                    <GravatarWithInitial
                        initial={initials}
                        borderRadius={radiusGravatar}
                        large={gravatarLarge}
                        backgroundcolor={
                            theme.color.gravatar || theme.color.primary
                        }
                    />
                )}
            </StyledNavlinkImage>
        }
    </ProfilePictureLoader>
}

export default withTheme(ProfilePicture)
