export const ROUTES = {
  BASE: '/',
  HASHUSERKEY: ':hashUserKey',
  WIZARD_PAGE: ':wizardPage',
  SELECTED_SETTING: ':selectedSetting',
  BASE_BUSINESS: '/business-beschleunigen',
  LOGIN: '/login',
  LOGIN_HASH: '/login/:hashUserKey',
  ENTER_PIN: '/enter-pin',
  ENTER_PIN_HASH: '/enter-pin/:hashUserKey',
  REQUEST_PIN: '/start',
  REQUEST_PIN_HASH: '/start/:hashUserKey',
  WIZARD_REG_BASE: '/registration',
  WIZARD_REG_BASE_HASH: '/registration/:hashUserKey',
  WIZARD_REG: '/registration/:hashUserKey/:wizardPage',
  WIZARD_REG_WELCOME: '/welcome',
  WIZARD_REG_USERNAME: '/username',
  WIZARD_REG_PASSWORD: '/password',
  WIZARD_REG_DONE: '/done',
  FORGET_USERNAME: '/forget-username',
  FORGET_PASSWORD: '/forget-password',
  FORGET_USERNAME_HASH: '/login/:hashUserKey/forget-username',
  FORGET_PASSWORD_HASH: '/login/:hashUserKey/forget-password',
  RESET_PASSWORD_HASH: '/reset-password/:passwordKey',
  PASSWORD_HASH: ':passwordKey',
  RESET_PASSWORD_DONE: '/reset-password-done',
  DASHBOARD: '/dashboard',
  AKTEN: '/akten',
  AKTEN_PRESELECTED: '/akten/:akteIdHash/accounts/:accountId',
  SERVICE_PROVIDER: '/service-provider',
  SETTINGS: '/settings',
  SETTINGS_ACCOUNT: '/settings/account',
  SETTINGS_GENERAL: '/settings/general',
  SETTINGS_ABOUT: '/settings/about',
  SETTINGS_PRESELECTED: '/settings/:selectedSetting',
  NOT_FOUND: '/404',
  YOU_IN_MOBILE: '/download-and-no-ragrets',
  ACTIVATE_MOBILE_NO_APP: '/activate-with-app',
  FILL_THE_FORM_BASE: '/externalform/',
  FILL_THE_FORM: '/externalform/:processGuid',
  FILL_THE_FORM_SUCCEED: '/externalform/:processGuid/succeed',
  FILL_THE_FORM_EXTERNAL_REQUEST:
    '/proudly-presents/:accountIdentity/guidance/:externalRequestKey',
  FILL_THE_FORM_EXTERNAL_REQUEST_SUCCEED:
    '/proudly-presents/:accountIdentity/guidance/:externalRequestKey/confirmation-page',
  LOGIN_SPECIFIC_ACCOUNT: '/proudly-presents/:accountIdentity',
  PROUDLY_PRESENTS_BASE: '/proudly-presents/',
  PAGE_BASE: '/page/',
  PAGE_LOGIN_SPECIFIC_ACCOUNT: '/page/:accountIdentity',
  PAGE_FILL_THE_FORM_EXTERNAL_REQUEST_SUCCEED:
    '/page/:accountIdentity/guidance/:externalRequestKey/confirmation-page',
  PAGE_FILL_THE_FORM_EXTERNAL_REQUEST:
    '/page/:accountIdentity/guidance/:externalRequestKey',
}

export const COMMON_PARAMS = {
  HASHUSERKEY: 'hashUserKey',
  WIZARD_PAGE: 'wizardPage',
  SELECTED_SETTING: 'selectedSetting',
  PASSWORD_HASH: 'passwordKey',
  AKTEIDHAS: 'akteIdHash',
  ACCOUNTID: 'accountId',
  PROCESSGUID: 'processGuid',
  ACCOUNTIDENTITY: 'accountIdentity',
  EXTERNAL_REQUEST_KEY: 'externalRequestKey',
}

export const ROUTE_PARAMETERS = {
  DEFAULT: {
    OFFSET: 'of',
    LIMIT: 'li',
    ORDER_BY: 'or',
    DIRECTION: 'di',
    FIRSTNAME: 'fn',
    LASTNAME: 'ln',
    SALUTATION: 'slt',
    EMAIL: 'em',
    MOBILE: 'ph',
    COMPANY: 'cmp',
  },
  AKTEN: {
    FOLDER_ID: 'fid',
    FOLDER_PARENT_ID: 'fpid',
    FOLDER_ROOT_ID: 'frid',
    SEARCH_BY_HEADER: 'asbh',
    FAVORITE_MESSAGE_ID: 'fmi',
    FAVORITE_MESSAGE_ID_ON_FOLDER: 'fmiof',
  },
  DASHBOARD: {
    ACCOUNT_ID: 'accid',
    ACTIVATE_MODE: 'activateMode',
  },
  ASSISTANTS: {
    FIRSTNAME: 'm_vorname',
    LASTNAME: 'm_name',
    EMAIL: 'm_email',
    PHONE: 'm_handy',
  },
}
