import { api } from 'application/api'
import { AKTEN } from 'application/constants'
import { registeredMessageFullyLoaded } from 'application/redux/actions/messages'
import { getNewMessagesSucceed, populateMessages } from 'application/redux/actions/pages/akten'
import { call, put, select } from 'redux-saga/effects'
import { messageRawMapper } from './message_raw_mapper'
import { messageRegisteredOffset } from 'application/redux/selectors'

export function* getMessagesSaga(action) {
    const offset = yield select(messageRegisteredOffset, action.akteIdHash)
    const resultMessageDetails = yield call(api.gateway.pages.getMessageDetails, action.accountId, action.akteIdHash, offset, 50)
    const metadata = yield (messageRawMapper(resultMessageDetails, action.akteIdHash, action.accountId, AKTEN.TYPE.ROOT))
    yield put(populateMessages(resultMessageDetails.messages.timeline, resultMessageDetails.messages.totalCount))
    yield put(registeredMessageFullyLoaded(action.messageKey, metadata))
    yield put(getNewMessagesSucceed())

    return resultMessageDetails
}