import { connect } from 'react-redux'

import PasswordStrengthCheckerComponent from './component'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordStrengthCheckerComponent)
