import DatePicker from 'react-date-picker'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ErrorLabel } from 'application/components/fragments/error_information'

const StyledDatePicker = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;

    color: ${props => props.theme.color.color90};

    & label.label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.small};
    }

    & div.react-date-picker__wrapper {
        height: 35px;
        width: 100%;
        border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.color15};
        font-size: ${props => props.theme.fontSize.standard};
        color: ${props => props.value ? props.theme.color.color90 : props.theme.color.color80}
    }

    & .react-date-picker__button:active * {
        color: ${props => props.theme.color.color90};
        border:none;
    }

    & .react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
        stroke: ${props => props.theme.color.color90};
        color: ${props => props.theme.color.color90};
        border: none;
    }

    &  button.react-date-picker__clear-button.react-date-picker__button:focus,
    button.react-date-picker__calendar-button.react-date-picker__button:focus {
        outline: none;
        border: none;
    }


    & button.react-date-picker__calendar-button  i,
      button.react-date-picker__clear-button i 
     {
        color: ${props => props.theme.color.color90};
        font-size: ${props => props.theme.fontSize.standard};
    }

    & div.react-date-picker__inputGroup {
        height: 35px;
        padding-left: 4px;
    }

    & input.react-date-picker__inputGroup__input:invalid {
        background: ${props => props.theme.color.color0}
    }


    & div.react-date-picker__inputGroup span {
        /* opacity ${props => props.opacity} */
    }

    & div.react-date-picker__wrapper:hover {
        border: 1px solid ${props => {
        if( props.disabled ){
            return props.theme.color.color15
        }if(props.cssValidationError) {
            return props.theme.color.red
        }
        return props.theme.color.lightPowderGray
    }};
    }

    & div.react-date-picker__wrapper:active {
        border: 1px solid ${props => props.disabled ? props.theme.color.color15 : props.theme.color.color90};
    }

    & div.react-date-picker--disabled {
        & i {
            color: ${props => props.theme.color.color80};
        }

        & input {
            color: ${props => props.theme.color.color80};
        }
        color: ${props => props.theme.color.color80};
        background: ${props => props.theme.color.color5}
    }
`

const DateInputWithLabel = ({
    inputClassName = '',
    onChange,
    isRequired=false,
    value = new Date(),
    clearIconKey = 'clear',
    calendarIconKey = 'today',
    label = '',
    placeholder = '',
    disabled = false,
    validationHandlers = {},
    dateTemplate = null,
    validationErrors = []    
}) => {
    const {t} = useTranslation()

    const sameDate = () => {
        if(isRequired && (validationHandlers.value !== '' || validationHandlers.value !== null)) {
            if ((validationHandlers.value !== null ) && 
        (dateTemplate !== null )) {
                validationHandlers.value = new Date(validationHandlers.value)
                dateTemplate = new Date(dateTemplate)
                return validationHandlers.value.getDay() === dateTemplate.getDay() 
            && validationHandlers.value.getMonth() ===dateTemplate.getMonth() 
            && validationHandlers.value.getFullYear() ===dateTemplate.getFullYear()
            }
        } else {
            if ((value !== null ) && 
            (dateTemplate !== null)) {
                value = new Date(value)
                dateTemplate = new Date(dateTemplate)
                return value.getDay() === dateTemplate.getDay() 
                && value.getMonth() ===dateTemplate.getMonth() 
                && value.getFullYear() ===dateTemplate.getFullYear()
            } else {return false}
        }
        
    }

    const selectChange = (value) => {
        if (!disabled) {
            if(onChange !== undefined) {
                onChange(value)
            }
            if(validationHandlers.onChange !== undefined) {
                validationHandlers.onChange(value)
            }
        } 
    }

    // convert date
    const theDate = dateTemplate instanceof Date ? dateTemplate.toLocaleDateString() : new Date(dateTemplate).toLocaleDateString()
    const today = new Date(Date.now()).toLocaleDateString()
    const displayedString = theDate === today ? t('Today') : theDate

    const labelWithDateTemplate = isRequired ? label + ' (' + t('before') + ' ' + displayedString
    + ')*' : label + ' (' + t('before') + ' ' + displayedString
    + ')'

    const finalLabel = isRequired ? label + '*' : label

    return ( <React.Fragment>
        <StyledDatePicker opacity={ !value ? '0' : '1'} disabled={disabled} cssValidationError={isRequired && validationErrors.length > 0}  >
            <label className='label' >
                {
                    sameDate() || dateTemplate === null ? finalLabel : labelWithDateTemplate
                }</label>
            <DatePicker
                className={ inputClassName }
                onChange={ selectChange }
                value={ isRequired ? validationHandlers.value : value }
                clearIcon={ !clearIconKey ? null : <i className="material-icons">{clearIconKey}</i>} //null - kein icon, nix - defaulticon 'clear', customiconkey - customicon
                calendarIcon={ !calendarIconKey ? null  : <i className="material-icons">{calendarIconKey}</i>} //
                dayPlaceholder= { placeholder }
                monthPlaceholder={ null } 
                yearPlaceholder={ null }
                disabled={disabled}
            />
            {
                isRequired && validationErrors.length > 0 && validationErrors.map((v, i) => {
                    return <ErrorLabel key={i}>
                        {t(v)}
                    </ErrorLabel>
                })
            }
        </StyledDatePicker> <br />
       
    </React.Fragment>
        
    )
}

DateInputWithLabel.propTypes = {
    inputClassName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    clearIconKeyKey: PropTypes.string,
    calendarcalendarIconKey: PropTypes.string,
    label: PropTypes.string,
    placeHolder: PropTypes.string,
    disabled: PropTypes.bool,
    validationHandlers: PropTypes.object,
    validationErrors: PropTypes.array
}

export default DateInputWithLabel