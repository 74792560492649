import { isDate } from 'moment'

export const passwordMethods = {
    //method returns true = is valid
    passwordMinLength: value => {
        return value.length >= 8
    },
    includesLowerCaseCharacter: value => {
        return value.match(/[a-z]/)
    },
    includesUpperCaseCharacter: value => {
        return value.match(/[A-Z]/)
    },
    includesSpecialCharacter: value => {
        return value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)
    },
    includesNumericCharacter: value => {
        return value.match(/\d+/)
    },
    passwordNotBlacklisted: value => {
        const blacklist = process.env.REACT_APP_PASSWORD_BLACKLIST.split(',')
        let notBlacklisted = true
        blacklist.forEach(b => {
            if (value.indexOf(b) !== -1) {
                notBlacklisted = false
            }
        })
        return notBlacklisted
    },
    usernameNotIncluded: (username, password) => {
        return !(username.length > 3 && password.indexOf(username) !== -1)
    },
}

export const methods = {
    //method returns true = is valid
    passwordMinLength: value => {
        return value.length >= 8
    },
    includesLowerCaseCharacter: value => {
        return value.match(/[a-z]/)
    },
    includesUpperCaseCharacter: value => {
        return value.match(/[A-Z]/)
    },
    includesSpecialCharacter: value => {
        return value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)
    },
    includesNumericCharacter: value => {
        return value.match(/\d+/)
    },
    passwordNotBlacklisted: value => {
        const blacklist = process.env.REACT_APP_PASSWORD_BLACKLIST.split(',')
        let notBlacklisted = true
        blacklist.forEach(b => {
            if (value.indexOf(b) !== -1) {
                notBlacklisted = false
            }
        })
        return notBlacklisted
    },
    usernameNotIncluded: (username, password) => {
        return !(username.length > 3 && password.indexOf(username) !== -1)
    },
    emailRegex: value => {
        return value.match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$/)
    },
    phoneRegex: value => {
        return value.match(/^.{4,15}[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/)
    },
    rangeLengthRegex : value => {
        return value.match(/^.{3,5}$/)
    },
    rangeLengthPayload : valueKey => {
        return (valueKey.length > 2 && valueKey.length < 5)
    },
    maxLengthRegex : value => {
        return value.match(/^.{0,10}$/)
    },
    maxLengthPayload : valueKey => {
        return (valueKey.length < 11)
    },
    minLengthRegex : value => {
        return value.match(/^.{2,}$/)
    },
    minLengthPayload : valueKey => {
        return (valueKey.length > 0)
    },
    zipCodeRegex : value => {
        return value.match(/^\d{5}$/)
    }
}

const getErrorKey = (defaultKey, payload) => {
    return typeof payload !== 'undefined'
        ? payload.errorKey
        : defaultKey
}

export const validators = {
    notEmpty: (key, state, payload) => {
        
        //check if the value is more than max or less than min or in range of
        if (state[payload]) {
            const keyValue = state[payload.valueKey].value
            if (!methods.maxLengthPayload(keyValue)) {
                return {
                    isValid: false,
                    errorKey: getErrorKey('validationValueTooLong', payload)
                }
            }
        }
        //signature of validators must always match this pattern
        const value = state[key].value
        return {
            isValid: value !== '',
            errorKey: getErrorKey('validationIsEmpty', payload)
        }
    },
    validPassword: (key, state, payload) => {
        const value = state[key].value
        //check for min length
        if (!methods.passwordMinLength(value)) {
            return {
                isValid: false,
                errorKey: getErrorKey('validationPasswordMinlength')
            }
        }

        //check for blacklist
        if (!methods.passwordNotBlacklisted(value)) {
            return {
                isValid: false,
                errorKey: getErrorKey('passwordNotBlacklisted')
            }
        }
        if (!methods.includesLowerCaseCharacter(value)) {
            return {
                isValid: false,
                errorKey: getErrorKey('includesLowerCaseCharacter')
            }
        }
        // if (!methods.includesUpperCaseCharacter(value)) {
        //     return {
        //         isValid: false,
        //         errorKey: getErrorKey('includesUpperCaseCharacter')
        //     }
        // }
        if (!methods.includesSpecialCharacter(value)) {
            return {
                isValid: false,
                errorKey: getErrorKey('includesSpecialCharacter')
            }
        }
        if (!methods.includesNumericCharacter(value)) {
            return {
                isValid: false,
                errorKey: getErrorKey('includesNumericCharacter')
            }
        }

        //check if username is included in password
        if (state[payload.userNameKey]) {
            const userNameValue = state[payload.userNameKey].value
            if (!methods.usernameNotIncluded(userNameValue, value)) {
                return {
                    isValid: false,
                    errorKey: getErrorKey('validationPasswordUsernameIncluded')
                }
            }
        }

        //check other criterias
        const otherCriterias = [
            methods.includesLowerCaseCharacter,
            methods.includesUpperCaseCharacter,
            methods.includesNumericCharacter,
            methods.includesSpecialCharacter
        ]

        let matchedCriterias = 0

        otherCriterias.forEach(c => {
            if (c(value)) {
                matchedCriterias++
            }
        })

        if (matchedCriterias < 3) {
            return {
                isValid: false,
                errorKey: getErrorKey('validationPasswordCriterias')
            }
        }

        return {
            isValid: true,
            errorKey: ''
        }
    },
    shouldBeEmail: (key, state, payload) => {
        //check if the value is more than max or less than min or in range of
        if (state[payload]) {
            const keyValue = state[payload.valueKey].value
            if (!methods.minLengthPayload(keyValue)) {
                return {
                    isValid: false,
                    errorKey: getErrorKey('validationIsEmpty', payload)
                }
            }
        }
        //signature of validators must always match this pattern
        const value = state[key].value
        return {
            isValid: methods.emailRegex(value),
            errorKey: getErrorKey('validationNotEmail', payload)
        }
    },
    shouldBePhoneOptional: (key, state, payload) => {
        //signature of validators must always match this pattern
        const value = state[key].value
        return {
            isValid: methods.phoneRegex(value) || value === '',
            errorKey: getErrorKey('validationNotPhoneNumber', payload)
        }
    },
    shouldBePhone: (key, state, payload) => {
        //check if the value is more than max or less than min or in range of
        if (state[payload]) {
            const keyValue = state[payload.valueKey].value
            if (!methods.minLengthPayload(keyValue)) {
                return {
                    isValid: false,
                    errorKey: getErrorKey('validationIsEmpty', payload)
                }
            }
        }
        //signature of validators must always match this pattern
        const value = state[key].value
        return {
            isValid: methods.phoneRegex(value),
            errorKey: getErrorKey('validationNotPhoneNumber', payload)
        }
    },
    shouldBeZipCodeOptional: (key, state, payload) => {
        const value = state[key].value
        return {
            isValid: methods.zipCodeRegex(value) || value === '',
            errorKey: getErrorKey('validationNotZipCode', payload)
        }
    },
    shouldBeZipCode: (key, state, payload) => {
        //check if the value is more than max or less than min or in range of
        if (state[payload]) {
            const keyValue = state[payload.valueKey].value
            if (!methods.minLengthPayload(keyValue)) {
                return {
                    isValid: false,
                    errorKey: getErrorKey('validationIsEmpty', payload)
                }
            }
        }
        const value = state[key].value
        return {
            isValid: methods.zipCodeRegex(value),
            errorKey: getErrorKey('validationNotZipCode', payload)
        }
    },
    mustMatch: (key, state, payload) => {
        const value = state[key].value
        const compareValue = state[payload.compareKey].value
        return {
            isValid: value === compareValue,
            errorKey: getErrorKey('validationDoesntMatch', payload)
        }
    },
    mustBeChecked: (key, state, payload) => {
        const value = state[key].value
        return {
            isValid: value,
            errorKey: getErrorKey('validationNotChecked', payload)
        }
    },
    mustBeSelected: (key, state, payload) => {
        const selected = state[key].value
        return {
            isValid: selected !== '' && selected !== null,
            errorKey: getErrorKey('validationNotSelected', payload)
        }
    },
    dateNotEmpty: (key, state, payload) => {
        const selectedDate = state[key].value
        return {
            isValid: selectedDate !== '' && selectedDate !== null && isDate(selectedDate),
            errorKey: getErrorKey('validationNotSelectedOnDate', payload)
        }
    }
}
