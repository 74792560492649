import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { validationMethods } from 'application/common'
import { useRef } from 'react'
import { StartRedirect } from '../../../_common'
import { useTranslation } from 'react-i18next'
import { passwordStrengthScore } from 'application/common/validation_methods'
import styled, { withTheme } from 'styled-components'
import { scroller } from 'react-scroll'
import { nanoid } from 'nanoid'
import { isTablet } from 'react-device-detect'
import { accessTokenUnregistered } from 'application/storage'
import TopContentWizardReg from '../_common/top_content'
import TitleWizard from '../_common/title_wizard'
import NotesPassword from './sections/notes_password'
import ButtonSubmitPassword from './sections/button_submit_password'
import FormPasswordRegistration from './sections/form_password_registration'
import PasswordStrengthCheckerAnimation from './sections/password_strength_checker_animation'
import RedirectMobile from 'application/common/redirect_mobile'
import { WizardMobileVersion } from '../../../mobile'


const StylledContainer = styled.div`
	height: 100%;
`

const WizardRegistrationPasswordComponent = (props) => {
    
    const { wizardData, akteMeta, registeredRequest, onRegistrationRequest, username, regKey, registeredFailed, errorMessage, accessTokenUnregisteredStore } = props
    const staticIdBottomPassWizard = nanoid()
    const staticIdBottomPassWizardContainer = nanoid()
    const defaultValues = {
        password: '',
        passwordConfirmation: '',
    }

    const validation = {
        notEmptyPassword: validationMethods.notEmptyPassword,
        passwordStrength: passwordStrengthScore,
        passwordMinLength: validationMethods.passwordMinLength,
    }

    useEffect(() => {
        !isTablet && scroller.scrollTo(`${staticIdBottomPassWizard}`, {
            smooth: true,
            containerId: `${staticIdBottomPassWizardContainer}`,
            offset: 0,
            duration: 1000,
            delay: 100,
        })
    }, [staticIdBottomPassWizard, staticIdBottomPassWizardContainer])

    const handleFocusPass = () => {
    }

    const handleBlurPass = () => {
    }

    const [newPass, setNewPass] = useState('')
    const { register,
        formState: { errors },
        handleSubmit,
        getValues,
        watch } = useForm({ defaultValues })

    const password = useRef({})
    password.current = watch('password', '')

    const token = accessTokenUnregisteredStore !== '' ? accessTokenUnregisteredStore : accessTokenUnregistered.get()

    const agbsAccepted = true
    const onSubmitSuccess = () => {
        onRegistrationRequest(username, getValues().password, getValues().passwordConfirmation, akteMeta.accountId, regKey, akteMeta.userId, agbsAccepted, token)
    }

    const handleChangeNewPass = (e) => {
        setNewPass(e.target.value)
    }
    const { t } = useTranslation()
    const mobileProps = {
        ...props,
        handleBlurPass,
        handleChangeNewPass,
        validation,
        staticIdBottomPassWizard,
        staticIdBottomPassWizardContainer,
        defaultValues,
        newPass,
        setNewPass,
        password,
        token,
        agbsAccepted,
        onSubmitSuccess,
        register,
        errors,
        handleSubmit,
        getValues,
        watch,
    }
	return <StylledContainer id={staticIdBottomPassWizardContainer}>
	<StartRedirect {...props}>
        <RedirectMobile
                MobileComponent={WizardMobileVersion.PasswordWizardMobile}
                {...mobileProps}
            >
                <TopContentWizardReg accountId={akteMeta.accountId} wizardData={wizardData} />
                <FlexGridItem grow={0}>
                    <FlexGrid directionColumn>
                        {/* TODO */}
                        <TitleWizard step={3} headline={t('EsyMandantPasswordConfirmation')} desc='' akteMeta={akteMeta} />
                        {/* <NotesPassword /> */}
                        <FlexGrid>
                            <FormPasswordRegistration
                                handleBlurPass={handleBlurPass}
                                handleChangeNewPass={handleChangeNewPass}
                                handleFocusPass={handleFocusPass}
                                handleSubmit={handleSubmit}
                                onSubmitSuccess={onSubmitSuccess}
                                register={register}
                                registeredFailed={registeredFailed}
                                registeredRequest={registeredRequest}
                                validation={validation}
                                errors={errors}
                                newPass={newPass}
                                password={password}
                                errorMessage={errorMessage}
                            />
                            <PasswordStrengthCheckerAnimation accountId={akteMeta.accountId} username={username} newPass={password.current} />
                        </FlexGrid>
                    </FlexGrid>
                </FlexGridItem>
                <ButtonSubmitPassword accountId={akteMeta.accountId} registeredRequest={registeredRequest} handleSubmit={handleSubmit} onSubmitSuccess={onSubmitSuccess} staticIdBottomPassWizard={staticIdBottomPassWizard} />
            </RedirectMobile>
    </StartRedirect>
	</StylledContainer> 
}

export default withTheme(WizardRegistrationPasswordComponent)