import { getAncestorsByParent, getSelectedFolder } from 'application/components/building_blocks/tree/helpers'
import { useQuery } from 'application/components/hooks'
import { ROUTE_PARAMETERS } from 'application/constants'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DataAkteLevelLoader from './data_akte_level_loader'
import DataFolderLevelLoader from './data_folder_level_loader'
import SelectedFolderEffect from './selected_folder_effect'

const MessageDetailsDataLoader = (props) => {
    const {
        children,
        selectedAkte,
        registeredMessages,
        messageKey,
        auth,
        breadcrumbsData,
        metaRootMessage,
        shouldLoadWithOffset,
        setShouldLoadWithOffset
    } = props
    

    let params = useParams()
    const query = useQuery()
    const folderId = query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID) === '' ? '' : query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID)
    let folders = metaRootMessage?.folders
    const parent = getAncestorsByParent(folders, folderId)
    const { accountId, akteIdHash } = params

    const breadcrumbDataToSelect =
        folderId === '' ||
            folders?.length === 0 ||
            parent === null ?
            [] :
            [{ id: akteIdHash, name: selectedAkte !== null && selectedAkte.header, root: true }, ...parent]
    const foundedFolder =
        folders?.length > 0 &&
        folderId !== '' &&
        getSelectedFolder(folders, folderId)
    
    const messageRegistered = registeredMessages.filter(r => r.key === messageKey).length > 0
    const messageLoaded = messageRegistered && registeredMessages.find(r => r.key === messageKey).isFullLoaded
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn

    const shouldLoad = !messageLoaded && loggedIn
    const customProps = {
        ...props,
        folderId,
        parent,
        accountId,
        akteIdHash,
        foundedFolder,
        breadcrumbsData,
        shouldLoad,
        shouldLoadWithOffset,
        setShouldLoadWithOffset,
        messageLoaded,
        messageRegistered,
        breadcrumbDataToSelect,
        loggedIn, 
    }

    return <DataAkteLevelLoader {...customProps}>
        <DataFolderLevelLoader {...customProps}>
            <SelectedFolderEffect {...customProps}>
                {children}
            </SelectedFolderEffect>
        </DataFolderLevelLoader>
    </DataAkteLevelLoader>
}

export default MessageDetailsDataLoader