import React, { Fragment, useEffect } from 'react'

const ProfilePictureLoader = (props) => {
    const {
        profilePictureLoaded,
        accountId,
        userId,
        getProfilePicture,
        children,
        auth,
        allAccountsLoaded,
    } = props
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    useEffect(() => {
        loggedIn && allAccountsLoaded && !profilePictureLoaded && getProfilePicture(accountId, userId)
    }, [accountId, allAccountsLoaded, getProfilePicture, loggedIn, profilePictureLoaded, userId])
    return <Fragment>
        {children}
    </Fragment>
}

export default ProfilePictureLoader