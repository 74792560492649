import React from 'react'
import TopBarComponent from './component'

const TopBarEffects = (props) => {
    // import props

    // useEffect

    // condition

    // preloader

    return <TopBarComponent {...props} />
}

export default TopBarEffects