export const getUsernameReg = (state) => state.wizard.registrationData.usernameSet
export const getUserId = (state) => state.auth.common.user.userId
export const getUserLastName = (state) => state.auth.common.user.lastName
export const getUserEmail = (state) => state.auth.common.user.email
export const getUsernameNotValid = (state) => state.wizard.registrationData.usernameNotValid
export const getPasswordReg = (state) => state.wizard.registrationData.passwordSet
export const getAllAccounts = (state) => state.common.allAccounts.payload
export const getAllAkten = (state) => state.common.allAkten.payload
export const getSingleAkte = (state, akteIdHash) => state.common.allAkten.payload.find(p => p.idHash === akteIdHash)
export const getAllRegisteredMessages = (state) => state.messages.registered
export const getFoldersInRegisteredMessage = (state, messageKey) => state.messages.registered.filter(r => r.key === messageKey).length > 0 && state.messages.registered.find(r => r.key === messageKey).folders
export const isRegisteredMessageFullLoaded = (state, messageKey) => state.messages.registered.filter(r => r.key === messageKey).length > 0
export const getStatusLoginRegistered = (state) => state.auth.registered.loggedIn
export const getStatusLoginUnregistered = (state) => state.auth.unregistered.loggedIn
export const getMessagesFavorite = (state) => state.pages.akten.details.messages.filter(m => m.isFavorite)
export const getMetaMessageByMessageKey = (state, messageKey) => state.messages.registered.find(m => m.key === messageKey)
export const getFavoriteMessagesByMetaMessage = (metaMessage) => metaMessage.messages.filter(m => m.isFavorite)
export const getAktenFolders = (state) => state.pages.akten.common.messageRaw.folders
export const getRawMessage = (state) => state.pages.akten.common.messageRaw
export const getAkteMetaInfo = (state) => state.pages.akten.common.metaInfo
export const getStateFolders = (state) => state.pages.akten.common.folders
export const getAktenFiles = (state) => state.pages.akten.common.messageRaw.files
export const getMessageOnQueue = (state) => state.pages.akten.common.messageOnQueue
export const getMessagesTimeline = (state) => state.pages.akten.common.messageRaw.messages.timeline
export const profilePictureLoaded = (state) => state.auth.common.user.profilePicture.isLoaded
export const hasProfilePicture = (state) => state.auth.common.user.profilePicture.has
export const thumbnailLoaded = (state, thumbnailId) => state.files.thumbnails.filter(t => t.id === thumbnailId && t.isLoaded).length > 0
export const creatorPictureLoaded = (state, creatorIdHash) => state.files.profilePictureCreator.filter(t => t.creatorIdHash === creatorIdHash && t.isLoaded).length > 0
export const fullFilesLoaded = (state, fileId) => state.files.fullFiles.filter(t => t.id === fileId && t.isLoaded).length > 0
export const payloadForm = (state) => state.form.send.formData
export const getAutoResponderSetting = (state, id) => state.common.allAccounts.payload.find((a) => a.id === id).autoResponderSetting

//messages
export const messageRegisteredAndLoaded = (state, messageKey) => state.messages.registered.filter(r => r.key === messageKey && r.isFullLoaded).length > 0
export const messageRegistered = (state, messageKey) => state.messages.registered.filter(r => r.key === messageKey).length > 0
export const messageRegisteredOffset = (state, messageKey) => state.messages.registered.filter(r => r.key === messageKey)[0].offset