import ACTIONS from 'application/constants'

export const onAgbDatenschutztAccept = (condition, accountId) => ({
    type: ACTIONS.COMMON_ON_AGB_DATENSCHUTZ_ACCEPT,
    condition,
    accountId
})

export const getDatenshutzRequest = (accountId, accountName, userRegistered = true) => ({
    type: ACTIONS.COMMON_ON_DATENSHUTZ_REQUEST,
    accountId,
    accountName,
    userRegistered
})

export const populateDatenshutz = (accountId, datenschutz) => ({
    type: ACTIONS.COMMON_POPULATE_DATENSCHUTZ_ACCOUNT,
    accountId,
    datenschutz
})

export const getDatenshutzFailed = (accountId) => ({
    type: ACTIONS.COMMON_ON_DATENSHUTZ_FAILED,
    accountId,
})

export const downloadAgbAccount = (accountId, accountName, userRegistered = true) => ({
    type: ACTIONS.COMMON_ON_AGB_DOWNLOAD,
    accountId,
    accountName,
    userRegistered
})

export const downloadAgbAccountSucceed = (accountId) => ({
    type: ACTIONS.COMMON_ON_AGB_DOWNLOAD_SUCCEED,
    accountId
})

export const downloadAgbAccountFailed = (accountId) => ({
    type: ACTIONS.COMMON_ON_AGB_DOWNLOAD_FAILED,
    accountId
})