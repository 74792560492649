import ActionButton from 'application/components/controls/button/action_button/component'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { PushBottom20 } from 'application/components/fragments/grid'
import {
    LayoutContentWrapper,
    WizardFooterRight,
} from 'application/components/pages/_layout/fragments/wizard'
import { ROUTES, WIZARD_STEP } from 'application/constants'
import { hashUserKey } from 'application/storage'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

const ButtonUpdateWizard = ({
    onUpdateWizardNext,
    accountHasLaw,
    agbsAndDatenschutzAccepted,
    useMobile = false,
    accountId,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()

    return (
        <LayoutContentWrapper needPadding={!useMobile}>
            <FlexDividerHorizontal />
            <PushBottom20 />
            {useMobile ? (
                <ActionButton
                    backgroundColor={theme.color.primary}
                    buttonText={t('FirstStep')}
                    onButtonClick={() => onUpdateWizardNext(1, 2)}
                    linkTo={
                        ROUTES.WIZARD_REG_BASE +
            '/' +
            hashUserKey.get() +
            '/' +
            WIZARD_STEP.REGISTRATION.USERNAME
                    }
                    disabled={accountHasLaw ? !agbsAndDatenschutzAccepted : false}
                    strechted
                    borderradius="10px"
                />
            ) : (
                <WizardFooterRight>
                    <ActionButton
                        backgroundColor={theme.color.primary}
                        buttonText={t('FirstStep')}
                        onButtonClick={() => onUpdateWizardNext(1, 2)}
                        linkTo={
                            ROUTES.WIZARD_REG_BASE +
              '/' +
              hashUserKey.get() +
              '/' +
              WIZARD_STEP.REGISTRATION.USERNAME
                        }
                        disabled={accountHasLaw ? !agbsAndDatenschutzAccepted : false}
                    />
                </WizardFooterRight>
            )}
        </LayoutContentWrapper>
    )
}

export default ButtonUpdateWizard
