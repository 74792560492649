import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, { useEffect, useState } from 'react'
import { withTheme } from 'styled-components'
import SearchInput from 'application/components/controls/form_elements/search_input'
import { ThumbnailContainer } from 'application/components/building_blocks/attachments/thumbnail'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { WrapperFilesSidebar } from '../fragments'
import { Spacer } from 'application/components/pages/internal/dashboard/fragments'

const DefaultDisplayFilesSidebarComponent = (props) => {
    const { thumbnailsToShow, onOpenGallery, theme, akteIdHash, parentId } = props
    let params = useParams()
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState(thumbnailsToShow)

    useEffect(() => {
        const results = thumbnailsToShow.filter((a) =>
            a.name !== undefined ? a.name.toLowerCase().includes(search.toLowerCase()) : a.fileName.toLowerCase().includes(search.toLowerCase())
        )
        setSearchResults(results)
    }, [search, thumbnailsToShow])

    const { t } = useTranslation()

    return (
        <div>
            <Spacer />
            <FlexGridItem>
                <SearchInput
                    backgroundcolor={theme.color.color10}
                    placeholder={t('Search files')}
                    onChangeSearch={(e) => {
                        setSearch(e.target.value)
                    }}
                    value={search}
                />
            </FlexGridItem>
            <Spacer />
            <WrapperFilesSidebar height='74vh' noPadd maxHeight='100%' isoverflow>
                <FlexGrid>
                    {searchResults
                        .sort((a, b) => b.id - a.id)
                        .map((s, i) => {
                            return (
                                <ThumbnailContainer
                                    key={i}
                                    fileId={s.id}
                                    fileName={s.fileName}
                                    messageId={s.messageId}
                                    akteIdHash={akteIdHash}
                                    parentId={parentId}
                                    onAttachmentClick={() => onOpenGallery(s.id, s.fileName, params.accountId, parentId, akteIdHash, thumbnailsToShow)}
                                    showCaption={true}
                                    marginRight={false}
                                    isSizeFixed={true}
                                    divHeight='65px'
                                    divWidth='65px'
                                    creationDate={s.creationDate}
                                    accountId={params.accountId}
                                />
                            )
                        })}
                </FlexGrid>
            </WrapperFilesSidebar>
        </div>
    )
}

const DisplayDefault = withTheme(DefaultDisplayFilesSidebarComponent)
export { DisplayDefault }
export default DisplayDefault
