import IconLink from 'application/components/controls/icon/icon_link'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, { useState } from 'react'
import styled from 'styled-components'
import { AKTEN } from 'application/constants'
import NoFilesRedirect from './redirect'
import { useTranslation } from 'react-i18next'
import { WrapperFilesSidebar } from './fragments'

const TitleSidebar = styled.span`
     color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const LayoutFilesSidebar = (props) => {
    const {
        openAkteSidebar,
        thumbnailsToShow,
        children
    } = props
    const thumbnailsLength = thumbnailsToShow.length
    const initialFilterCondition = thumbnailsLength > 12
    const [onFilterFiles, setOnFilterFiles] = useState(initialFilterCondition)
    const handleFilter = () => {
        onFilterFiles ? setOnFilterFiles(false) : setOnFilterFiles(true)
    }
    const iconFilter = onFilterFiles ? 'search' : 'filter_list'
    const { t } = useTranslation()

    return <NoFilesRedirect {...props}>
        <WrapperFilesSidebar height='89vh'>
            <FlexGrid directionColumn>
                <FlexGridItem>
                    <FlexGrid justifySpaceBetween itemsCenter>
                        <FlexGridItem>
                            <TitleSidebar>
                                {t('Files')}
                            </TitleSidebar>
                        </FlexGridItem>
                        <FlexGridItem justifyFlexEnd>
                            <IconLink borderradius='15px' title={t('Filtering files')} width='40px' iconKey={iconFilter} onClick={handleFilter} />
                            <IconLink borderradius='15px' title={t('Open case details')} width='40px' iconKey='clear' onClick={() => openAkteSidebar(AKTEN.SIDEBAR.KEY.DETAILS)} />
                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                {React.cloneElement(children, { onFilterFiles, ...props })}
            </FlexGrid>
        </WrapperFilesSidebar>
    </NoFilesRedirect>
}

export default LayoutFilesSidebar