import { getAncestorsByParent } from 'application/components/building_blocks/tree/helpers'
import IconLink from 'application/components/controls/icon/icon_link'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { AKTEN, ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { BreadcrumbWrapper } from 'application/components/building_blocks/breadcrumb'
import { useTranslation } from 'react-i18next'
import { isMobileOnly, isTablet } from 'react-device-detect'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Fragments from './fragments'
import CreatorProfilePicture from 'application/components/building_blocks/creator_profiile_picture'
import CategorizedAktenContent from './categorized_akten_content'
import CurrentLocation from './current_location'
import { useQuery } from 'application/components/hooks'
import { StyledRedDotWithNumber } from 'application/components/fragments/red_dot'

const   MessageDetailsTopBarComponent = ({
    accountName,
    accountId,
    akteIdHash,
    metaRootMessage,
    openDetailsMessage,
    downloadAkte,
    downloadAkteRequest,
    metaInfo,
    breadcrumbsView,
    setSelectedFolder,
    refreshRoot,
    breadcrumbsData,
    aktenkurzbezeichnung,
    sidebarVisible,
    handleOpenContactInformationPopupSidebar,
    messageKey,
    parentId,
    selectedAkte,
    header,
    metaMessage,
    setDisplayContentMobile,
    displayContentMobile,
    selectedFolder,
    backToParent,
    refreshMessageFavorite,
    updateDataSelectedMessageFolder,
	updateDataSelectedMessageRoot,
	refreshAkten,
	refreshAccounts,
    headerMenus,
    menus,
    setMenus,
    openAufgabeCount
}) => {
    let navigate = useNavigate()
    let location = useLocation()
    const errorMessage = 'This message cannot be downloaded'
    let folders = metaRootMessage?.folders

    const query = useQuery()
    let isRoot = query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID) === ''
    let currentLocationName = isRoot ? header : selectedFolder?.name
    const handleBreadClick = (fid, selectedData, akteIdHash, header) => {
        if (selectedData.root !== undefined && selectedData.root) {
            refreshRoot()
            navigate(`${location.pathname}`)
        } else {
            const breadcrumbsData = [{ id: akteIdHash, name: header, root: true }, ...getAncestorsByParent(folders, fid)]
            setSelectedFolder(selectedData, breadcrumbsData)
            navigate(`${location.pathname}?${ROUTE_PARAMETERS.AKTEN.FOLDER_ID}=${fid}&`)
        }
    }

    const handleBackToParent = (parentId, toRoot) => {
        if (toRoot) {
            navigate(`${location.pathname}`)
        } else {
            const breadcrumbsData = [{ id: akteIdHash, name: header, root: true }, ...getAncestorsByParent(folders, parentId)]
            backToParent(breadcrumbsData[breadcrumbsData.length - 1])
            navigate(`${location.pathname}?${ROUTE_PARAMETERS.AKTEN.FOLDER_ID}=${parentId}&`)
        }
        setDisplayContentMobile({view: AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.MESSAGES, payload: {}})
    }

    const { t } = useTranslation()
    const handleOpenContactInformation = () => {
        isTablet ? handleOpenContactInformationPopupSidebar({ akteIdHash, messageKey, parentId, selectedAkte }) : openDetailsMessage()
	}

	const refreshCommonSources = () => {
		refreshAkten()
		refreshAccounts()
	}

    return (
        <React.Fragment>
            {isMobileOnly ? (
				<Fragments.MessageTopBarWrapper>
                    <Fragments.MessageTopBarActionMobile>
                        <Fragments.ContentMobile>
                            <IconLink to={ROUTES.DASHBOARD} onClick={refreshCommonSources} iconKey='chevron_left' width='40px' />
                        </Fragments.ContentMobile>
                        <Fragments.ContentMobile>
                            <CreatorProfilePicture
                                large={'70'}
                                borderRadius='28px'
                                creatorIdHash={metaMessage?.rootInfo?.creatorIdHash}
                                creatorFirstName={metaMessage?.rootInfo?.creatorFirstName}
                                creatorLastName={metaMessage?.rootInfo?.creatorLastName}
                                accountId={metaMessage?.rootInfo?.accountId}
                                akteIdHash={metaMessage?.rootInfo?.akteIdHash}
                                mainColor={metaInfo?.mainColor}
                            />
                        </Fragments.ContentMobile>
                        {downloadAkteRequest ? (
                            <PreloaderSpin paddingTop='0px' />
                        ) : (
                            <IconLink
                                title={t('Download case')}
                                width='40px'
                                iconKey='file_download'
                                onClick={() => downloadAkte(accountId, akteIdHash, accountName, aktenkurzbezeichnung, errorMessage)}
                            />
                        )}
                    </Fragments.MessageTopBarActionMobile>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '18px',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        {headerMenus.map((menu, index) => (
                            (selectedAkte.isEsyAkte === true || menu === 'messages') ?
                            <div
                                key={index}
                                style={{
                                    color: menus === menu ? '#000000' : '#D8D8D8',
                                    cursor: 'pointer',
                                    borderBottom: menus === menu ? '1px solid #000000' : 'none',
                                    padding: '0px 8px 8px 8px',
                                    position:'relative',
                                }}
                                onClick={() => setMenus(menu)}>
                                    {t(menu)}
                                {menu == 'task' && openAufgabeCount != 0 && 
                                    <StyledRedDotWithNumber>
                                        {openAufgabeCount}
                                    </StyledRedDotWithNumber>
                                }
                            </div> : null
                        ))}
                    </div>
                    {menus === 1 && (
                        <CategorizedAktenContent
                            displayContentMobile={displayContentMobile}
                            setDisplayContentMobile={setDisplayContentMobile}
                            refreshMessageFavorite={refreshMessageFavorite}
                            accountId={accountId}
                            akteIdHash={akteIdHash}
                            selectedAkte={selectedAkte}
                            selectedFolder={selectedFolder}
                            updateDataSelectedMessageFolder={updateDataSelectedMessageFolder}
                            updateDataSelectedMessageRoot={updateDataSelectedMessageRoot}
                            totalUnreadMessageInFolder={metaMessage?.rootInfo?.totalUnreadMessageInFolder}
                        />
                    )}
                    <CurrentLocation
                        currentLocationName={currentLocationName}
                        parentName={selectedFolder?.parentName}
                        isRoot={isRoot}
                        parentId={selectedFolder?.parentId}
                        rootId={selectedFolder?.rootId}
                        backToParent={handleBackToParent}   
                    />
                </Fragments.MessageTopBarWrapper>
            ) : (
                <Fragments.MessageTopBarWrapper>
                    {metaInfo !== null ? (
                        <React.Fragment>
                            <FlexGridItem hideMobile>
                                <FlexGrid directionColumn>
                                    <Fragments.MessageTopBarAccountName>{accountName}</Fragments.MessageTopBarAccountName>
                                    <Fragments.MessageTopBarHeader title={header}>{header}</Fragments.MessageTopBarHeader>
                                    {breadcrumbsView && (
                                        <FlexGridItem>
                                            <BreadcrumbWrapper payload={breadcrumbsData} onBreadClick={(i, s) => handleBreadClick(i, s, akteIdHash, header)} />
                                        </FlexGridItem>
                                    )}
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '18px',
                                            borderBottom: '1px solid #e0e0e0',
                                            width: '100%',
                                        }}
                                    >
                                        {headerMenus.map((menu, index) => (
                                            (selectedAkte.isEsyAkte === true || menu === 'messages') ?
                                            <div
                                                key={index}
                                                style={{
                                                    position:'relative',
                                                    color: menus === menu ? '#000000' : '#D8D8D8',
                                                    cursor: 'pointer',
                                                    borderBottom: menus === menu ? '1px solid #000000' : 'none',
                                                    padding: '0px 8px 8px 8px',
                                                }}
                                                onClick={() => setMenus(menu)}>{t(menu)}
                                                {menu === 'task' && openAufgabeCount != 0 && 
                                                    <StyledRedDotWithNumber>
                                                        {openAufgabeCount}
                                                    </StyledRedDotWithNumber>
                                                }
                                            </div> : null
                                        ))}
                                    </div>
                                </FlexGrid>
                            </FlexGridItem>
                            <FlexGridItem grow={0} widthMobile='auto' marginBottom='unset' itemsCenter>
                                <FlexGrid justifyFlexEnd>
                                    {(!sidebarVisible || isTablet) && (
                                        <IconLink title={t('Open details message')} width='40px' iconKey='info' onClick={handleOpenContactInformation} />
                                    )}
                                    {downloadAkteRequest ? (
                                        <PreloaderSpin paddingTop='0px' />
                                    ) : (
                                        <IconLink
                                            title={t('Download case')}
                                            width='40px'
                                            iconKey='file_download'
                                            onClick={() => downloadAkte(accountId, akteIdHash, accountName, aktenkurzbezeichnung, errorMessage)}
                                        />
                                    )}
                                </FlexGrid>
                            </FlexGridItem>
                        </React.Fragment>
                    ) : (
                        <FlexGrid justifyCenter itemsCenter>
                            <PreloaderSpin />
                        </FlexGrid>
                    )}
                    {/* 
                <Fragments.ContentMobile>
                    <CategorizedAktenContent setDisplayContentMobile={setDisplayContentMobile} />
                </Fragments.ContentMobile> */}
                </Fragments.MessageTopBarWrapper>
            )}
        </React.Fragment>
    )
}

export default MessageDetailsTopBarComponent
