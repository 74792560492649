import ACTIONS from 'application/constants'

export const sendMessageAkte = (payload, metaData, errorMessage, params, idOnQueue, messageKey,) => ({
    type: ACTIONS.PAGES_AKTEN_SEND_MESSAGE_REQUEST,
    payload,
    metaData,
    errorMessage,
    params,
    idOnQueue,
    messageKey,
})

export const sendMessageAkteSucceed = () => ({
    type: ACTIONS.PAGES_AKTEN_SEND_MESSAGE_SUCCEED
})

export const sendMessageAkteFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_SEND_MESSAGE_FAILED
})


export const downloadAkteAsZip = (accountId, akteIdHash, accountName, aktenkurzbezeichnung, errorMessage) => ({
    type: ACTIONS.PAGES_AKTEN_DOWNLOAD_AKTE_ZIP,
    accountId,
    akteIdHash,
    accountName,
    aktenkurzbezeichnung,
    errorMessage
})

export const downloadAkteSucceed = () => ({
    type: ACTIONS.PAGES_AKTEN_DOWNLOAD_AKTE_ZIP_SUCCEED
})

export const downloadAkteFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_DOWNLOAD_AKTE_ZIP_FAILED
})

export const refreshNewMessage = () => ({
    type: ACTIONS.PAGES_AKTEN_REFRESH_NEW_MESSAGES
})

export const refreshNewMessageOnFolder = () => ({
    type: ACTIONS.PAGES_AKTEN_REFRESH_NEW_MESSAGES_ON_FOLDER
})

export const getNewMessagesRequest = (accountId, akteIdHash, params, messageKey) => ({
    type: ACTIONS.PAGES_AKTEN_NEW_MESSAGE_REQUEST,
    accountId,
    akteIdHash,
    params,
    messageKey
})

export const getNewMessagesSucceed = () => ({
    type: ACTIONS.PAGES_AKTEN_NEW_MESSAGE_SUCCEED
})

export const getNewMessagesFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_NEW_MESSAGE_FAILED
})

export const getNewMessageOnFolderRequest = (accountId, akteIdHash, folderId, params, messageKey) => ({
    type: ACTIONS.PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_REQUEST,
    accountId,
    akteIdHash,
    folderId,
    params,
    messageKey
})

export const getNewMessageOnFolderSucceed = () => ({
    type: ACTIONS.PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_SUCCEED
})

export const getNewMessageOnFolderFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_FAILED
})

export const refreshNewFiles = () => ({
    type: ACTIONS.PAGES_AKTEN_REFRESH_NEW_FILES
})

export const getNewFilesRequest = (accountId, akteIdHash, params) => ({
    type: ACTIONS.PAGES_AKTEN_NEW_FILES_REQUEST,
    accountId,
    akteIdHash,
    params,
})

export const getNewFilesSucceed = () => ({
    type: ACTIONS.PAGES_AKTEN_NEW_FILES_SUCCEED
})

export const getNewFilesFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_NEW_FILES_FAILED
})

export const readMessageRequest = (accountId, messageId, akteIdHash, messageKey, rootKey, folderId) => ({
    type: ACTIONS.PAGES_AKTEN_READ_MESSAGE_REQUEST,
    accountId,
    messageId,
    akteIdHash,
    messageKey,
    rootKey,
    folderId
})


export const readMessageSucceed = () => ({
    type: ACTIONS.PAGES_AKTEN_READ_MESSAGE_SUCCEED,
})

export const readMessageFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_READ_MESSAGE_FAILED,
})

export const markFavoriteMessageRequest = (accountId, messageId, params, messageKey,) => ({
    type: ACTIONS.PAGES_AKTEN_MARK_FAVORITE_MESSAGE_REQUEST,
    accountId,
    messageId,
    params,
    messageKey,
})

export const markFavoriteMessageSucceed = () => ({
    type: ACTIONS.PAGES_AKTEN_MARK_FAVORITE_MESSAGE_SUCCEED,
})

export const markFavoriteMessageFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_MARK_FAVORITE_MESSAGE_FAILED,
})

export const unmarkFavoriteMessageRequest = (accountId, messageId, params, messageKey,) => ({
    type: ACTIONS.PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_REQUEST,
    accountId,
    messageId,
    params,
    messageKey,
})

export const unmarkFavoriteMessageSucceed = () => ({
    type: ACTIONS.PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_SUCCEED,
})

export const unmarkFavoriteMessageFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_FAILED,
})

export const downloadMessageRequest = (accountId, messageId, messageHeader) => ({
    type: ACTIONS.PAGES_AKTEN_DOWNLOAD_MESSAGE_REQUEST,
    accountId,
    messageId,
    messageHeader
})

export const downloadMessageSucceed = () => ({
    type: ACTIONS.PAGES_AKTEN_DOWNLOAD_MESSAGE_SUCCEED,
})

export const downloadMessageFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_DOWNLOAD_MESSAGE_FAILED,
})

export const getNewFoldersRequest = (params, messageKey) => ({
    type: ACTIONS.PAGES_AKTEN_NEW_FOLDERS_REQUEST,
    params,
    messageKey
})