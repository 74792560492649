import { takeEvery } from '@redux-saga/core/effects'
import { api } from 'application/api'
import ACTIONS, { AKTEN } from 'application/constants'
import { updateSingleAkte } from 'application/redux/actions/common/all_akten'
import { getMessageFavoriteFailed, populateMessageFavorite, updateDataInFolderByMessageKey, updateDataOnMessage } from 'application/redux/actions/messages'
import { setSelectedAkte, setSelectedFolder } from 'application/redux/actions/pages/akten'
import { isRegisteredMessageFullLoaded } from 'application/redux/selectors'
import { call, put, select } from 'redux-saga/effects'
import { handleAkteFolderForbidden, handleAkteForbidden, handleError } from '../../errors'
import { messageRawMapper } from '../pages/message_raw_mapper'
import { isMeAndMyRootExist } from './is_me_and_my_root_exist'

function* updateSelectedMessageRootSaga(action) {
    const { akteIdHash, accountId, metaData } = action
    try {
        const isFullLoaded = yield select(isRegisteredMessageFullLoaded, akteIdHash)
        if (isFullLoaded) {
            const response = yield call(api.gateway.pages.getMessageDetails, accountId, akteIdHash)
            const responseSingleAkte = yield call(api.gateway.pages.getSingleAkte, accountId, akteIdHash)
            const messageDataWrapper = yield messageRawMapper(response, akteIdHash, accountId, AKTEN.TYPE.ROOT)
            yield put(updateDataOnMessage(akteIdHash, messageDataWrapper))
            yield put(updateSingleAkte(akteIdHash, responseSingleAkte))
            yield put(setSelectedAkte(responseSingleAkte))
        }
    } catch (e) {
        yield handleAkteForbidden(e, akteIdHash, metaData.aktenzeichen)
    }
}

function* updateSelectedMessageFolderSaga(action) {
    const { akteIdHash, accountId, folderId, metaData } = action
    try {
        const isFullLoaded = yield select(isRegisteredMessageFullLoaded, folderId)
        if (isFullLoaded) {
            const { isMeExist, updatedFoldersFlatten } = yield isMeAndMyRootExist(action)
            const selectedFolder = updatedFoldersFlatten.find((u) => u.id === folderId)
            if (isMeExist) {
                const response = yield call(api.gateway.pages.getMessageDetailsOnFolder, accountId, akteIdHash, folderId)
                const metadata = yield messageRawMapper(response, akteIdHash, accountId, AKTEN.TYPE.FOLDER)
                yield put(updateDataOnMessage(folderId, metadata))
                yield put(setSelectedFolder(selectedFolder))
                yield put(updateDataInFolderByMessageKey(akteIdHash, folderId, selectedFolder))
            }
        }
        yield
    } catch (e) {
        yield handleAkteFolderForbidden(e, folderId, metaData.name)
    }
}

const injectMessageKeyInFavorite = (favorites, messageKey) => {
    let arr = []
    if (favorites.length > 0) {
        favorites.forEach((f) => {
            arr.push({
                ...f,
                messageKey,
            })
        })
        return arr
    } else {
        return favorites
    }
}

function* getFavoritesMessageSaga(action) {
    let { messageKey, accountId, akteIdHash, folderId } = action
    try {
        let inFolder = folderId !== ''
        if (inFolder) {
            const response = yield call(api.gateway.pages.getMessageDetailsOnFolder, accountId, akteIdHash, folderId)
            let favorites = response.messages?.timeline?.filter((t) => t.isFavorite)
            yield put(populateMessageFavorite(injectMessageKeyInFavorite(favorites, messageKey)))
        } else {
            const response = yield call(api.gateway.pages.getMessageDetails, accountId, akteIdHash)
            let favorites = response.messages?.timeline?.filter((t) => t.isFavorite)
            yield put(populateMessageFavorite(injectMessageKeyInFavorite(favorites, messageKey)))
        }
    } catch (e) {
        yield handleError(e)
        yield put(getMessageFavoriteFailed())
    }
}

export function* messagesWatcher() {
    yield takeEvery(ACTIONS.MESSAGES_AKTEN_UPDATE_SELECTED_MESSAGE_ROOT, updateSelectedMessageRootSaga)
    yield takeEvery(ACTIONS.MESSAGES_AKTEN_UPDATE_SELECTED_MESSAGE_FOLDER, updateSelectedMessageFolderSaga)
    yield takeEvery(ACTIONS.MESSAGES_AKTEN_GET_FAVORITE_MESSAGES, getFavoritesMessageSaga)
}
