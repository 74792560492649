import ACTIONS from 'application/constants'
import defaultStore from '../default_store'

const providerSidebarReducer = (state = defaultStore.sidebars.provider, action) => {
    switch (action.type) {
    case ACTIONS.SIDEBAR_OPEN_PROVIDER:
        return {
            ...state,
            visible: true,
        }
    case ACTIONS.SIDEBAR_CLOSE_PROVIDER:
        return {
            ...state,
            visible: false
        }
    case ACTIONS.SIDEBAR_SET_DIALOG_VISIBLE_PROVIDER:
        return {
            ...state,
            dialogVisible: action.condition
        }
    default:
        return state
    }
}

export default providerSidebarReducer