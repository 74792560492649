import React, { Fragment, useEffect } from 'react'

const AccountPublicInfoInternalLoader = props => {
    const { children, allAccountsLoaded, auth, getPublicInfoAccountInternalAssets, accountPublicInfoInternal, accountId } = props
    const succeed = accountPublicInfoInternal !== null ? accountPublicInfoInternal.succeed : false
    const failed = accountPublicInfoInternal !== null ? accountPublicInfoInternal.failed : false
    const shouldLoad = !succeed && !failed
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    useEffect(() => {
        loggedIn && allAccountsLoaded && shouldLoad && getPublicInfoAccountInternalAssets(accountId)
    }, [accountId, allAccountsLoaded, getPublicInfoAccountInternalAssets, loggedIn, shouldLoad])
    return <Fragment>
        {children}
    </Fragment>
}

export default AccountPublicInfoInternalLoader
