import styled from 'styled-components'

export const LayoutWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 100%;
    overflow: hidden;
`

export const LayoutInfoWrapper = styled.div`
    width: 40%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-image: url('${props => props.loginImageRight}');
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        display: none;
    }
`


export const LayoutInputWrapper = styled.div`
    width: 60%;
    height: 100%;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        width: 100%;
    }
`

export const ExternalLoginAccountWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
`