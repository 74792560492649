import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'

const LoginPinFormContentAesthetic = (props) => {
    const { maxWidthContainer = '60%', largeImage = '55%', heightImage = '100%', flexGrow, flexShrink, flexBasis } = props
    return <FlexGridItem maxWidth={maxWidthContainer} justifyCenter grow={flexGrow} shrink={flexShrink} basis={flexBasis} itemsCenter>
        <ImagePlaceHolder toCenter withBorder={false} source={'/assets/images/layout_assistant.png'} large={largeImage} height={heightImage} />
    </FlexGridItem>
}

export default LoginPinFormContentAesthetic
