import React, { Fragment, useEffect } from 'react'

const RefreshForm = props => {
    const { sendFormSucceed, formLoaded, formReturnUrl, refreshForm, children } = props
    const shouldRefresh = sendFormSucceed || formLoaded || formReturnUrl !== null
    useEffect(() => {
        shouldRefresh && refreshForm()
    }, [refreshForm, shouldRefresh])
    return <Fragment>
        {children}
    </Fragment>
}

export default RefreshForm
