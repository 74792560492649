import React from 'react'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Headline2 } from 'application/components/fragments/typography'
import ActionButton from 'application/components/controls/button/action_button/component'

const SendPinOptionDialog = ({ abort, reqPin, dialog }) => {
    const { t } = useTranslation()

    const payload = dialog.payload

    const errorMessage = '🔥 ' + t('Pin cannot be sent')
    const successMessage = '🔑 ' + t('Pin has been sent')

    return <React.Fragment>
        <DialogMainContent>
            <FlexGrid itemsCenter directionColumn>
                <FlexGridItem justifyCenter basis='70px' grow='0'>
                    <Headline2>
                        {t('How do you want to request your PIN?')}
                    </Headline2>
                </FlexGridItem>
                <FlexGridItem width='50%'>
                    <FlexGrid grow='0' justifyCenter>
                        <FlexGridItem needSpaceRight='24px'>
                            <ActionButton 
                                secondary={true}
                                buttonText='SMS'
                                onButtonClick={() => reqPin(dialog.name, payload.hashAuthKey, 'Sms', errorMessage, successMessage)}
                                strechted={true}
                            />
                        </FlexGridItem>
                        <FlexGridItem>
                            <ActionButton
                                secondary={true}
                                buttonText='EMail'
                                onButtonClick={() => reqPin(dialog.name, payload.hashAuthKey, 'Email', errorMessage, successMessage)}
                                strechted={true}
                            />
                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
        </DialogMainContent>
    </React.Fragment>

}

export default SendPinOptionDialog