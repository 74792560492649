import styled from 'styled-components'

export const MessageTopBarWrapper = styled.div`
    width: auto;
    display: flex;
    flex-flow: column;
    flex: 1;
    gap: 20px;
    padding: 5px;
	@supports (-webkit-touch-callout: none) and (not (translate: none)) {
		& div:not(:last-child) {
		margin-bottom: 10px;
		}
	}
    @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
        padding: 25px 40px 20px 40px;
        flex: 0 0 auto;
        flex-direction: row;
        gap: unset;
        justify-content: space-between;
        align-items: center;
    }
`

export const MessageTopBarActionMobile = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const MessageTopBarAccountName = styled.span`
    color: ${(props) => props.theme.color.color100};
    font-size: ${(props) => props.theme.fontSize.headline1};
    font-family: ${props => props.theme.fontFamily.secondary};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin-bottom: 8px;
    word-break: word-break;
    @media (max-width: ${(props) => props.theme.breakpoint.laptopL}px) {
        font-size: ${(props) => props.theme.fontSize.headline3};
    }
`

export const MessageTopBarHeader = styled.span`
    color: ${(props) => props.theme.color.color45};
    font-family: ${props => props.theme.fontFamily.secondary};
    font-size: ${(props) => props.theme.fontSize.headline2};
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    word-break:break-word;

    margin-bottom: 8px;
    @media (max-width: ${(props) => props.theme.breakpoint.laptopL}px) {
        font-size: ${(props) => props.theme.fontSize.medium18};
    }
`

export const ContentMobile = styled.div`
    display: block;
    @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
        display: none;
    }
`

export const CurrentLocationMobile = styled.div`
    width: 100%;
    display: flex;
    padding: 15px 5px;
    align-items: center;
    border-top: 1px solid ${(props) => props.theme.color.color15};
`

export const CurrentLocationName = styled.p`
    margin: 0px;
	display: flex;
	align-items: center;
	justify-content: ${(p) => (p.isRoot ? 'center' : 'flex-end')};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    font-family: ${p => p.theme.fontFamily.secondary};
    font-size: ${(p) => p.theme.fontSize.medium};
    color: ${(p) => (p.current ? p.theme.color.color100 : p.theme.color.color100)};

	svg {
		margin-right: 4px;
	}
`

export const LocationContainer = styled.div`
    font-family: ${p => p.theme.fontFamily.secondary};
    flex: 1;
`
