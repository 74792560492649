import ACTIONS from 'application/constants'


export const loginRequest = (username, password) => ({
    type: ACTIONS.AUTH_LOGIN_REQUEST,
    username,
    password
})

export const loginRequestSuccess = () => ({
    type: ACTIONS.AUTH_LOGIN_SUCCESS
})

export const loginRequestFailed = (payload) => ({
    type: ACTIONS.AUTH_LOGIN_FAILED,
    payload
})

export const writeToken = (accessToken) => ({
    type: ACTIONS.AUTH_WRITE_ACCESS_TOKEN,
    accessToken
})

export const refresPayloadFailed = () => ({
    type: ACTIONS.AUTH_RERESH_PAYLOAD_FAILED,
})