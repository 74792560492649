import React from 'react'
import { Fragment } from 'react'
import LogoLoginForm from 'application/components/pages/external/mobile_assistant/login_pin/sections/logo_login_form'
import FormPinSection from 'application/components/pages/external/mobile_assistant/login_pin/sections/form'
import LoginPinFormContentAesthetic from 'application/components/pages/external/mobile_assistant/login_pin/sections/content_aesthetic'

export const LoginPinFormTabletPortrait = (props) => {
    return <Fragment>
        <LogoLoginForm flexGrow={0} flexShrink={0} flexBasis={'10%'} />
        <LoginPinFormContentAesthetic flexGrow={1} flexShrink={0} flexBasis={'50%'} maxWidthContainer='100%' largeImage={'60%'} heightImage='100%' />
        <FormPinSection flexGrow={1} flexShrink={1} flexBasis='auto' {...props} />
    </Fragment>
}

export default LoginPinFormTabletPortrait
