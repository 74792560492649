import React from 'react'
import { withTheme } from 'styled-components'
import LogoTopBarSection from './sections/logo'
import GreetingTopBarSection from './sections/greeting'
import SearchFielTopBarSection from './sections/search_field'
import ProfileTopBarSection from './sections/profile_language'
import { FlexGrid } from 'application/components/fragments/grid'



const DefaultTopBarComponent = (props) => {
    return <FlexGrid itemsCenter noWrap>
        <LogoTopBarSection {...props}  />
        <GreetingTopBarSection />
        <SearchFielTopBarSection {...props} />
        <ProfileTopBarSection {...props} />
    </FlexGrid>
}

export default withTheme(DefaultTopBarComponent)