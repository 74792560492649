export const convertingFilesToUploadIds = (keyValue, state, fileId, uploadId) => {
    const key = keyValue
    const obj = state.send.formData.formInput[key]
    const fullFiles = obj.filter(o => o.attachment !== undefined)
    const uploadIds = obj.filter(o => o.attachment === undefined)
    const foundedObj = fullFiles.filter(o => o.attachment.fileId !== fileId).concat(uploadId)
    const mergedUploadIds = uploadIds.concat(foundedObj)
    const newObj = Object.fromEntries(new Map([
        [key, mergedUploadIds],
    ]))

    return newObj
}