import React from 'react'
import styled, {keyframes, withTheme} from 'styled-components'
import PropTypes from 'prop-types'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledThemedPreloaderWrapper = styled.div`
    padding-top: ${props => props.paddingTop};
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
`

const StyledThemedPreloader = styled.div`
    flex: 0 0 ${props => props.small ? '13px' : '26px'};
    display: flex;
    justify-content: center;
`

const StyledThemedPreloaderInner = styled.div`
	display:block;
    height: ${props => props.small ? '13px' : '26px'};
    width: ${props => props.small ? '13px' : '26px'};
	background-color: ${props => props.backgroundcolor};
	border-radius: 13px;
	box-shadow:inset 0 0 0 0.8em ${props => props.backgroundcolor};
	background: linear-gradient(-45deg, ${props => props.primaryColor}, ${props => props.primaryColor} 50%, ${props => props.secondaryColor} 50%, ${props => props.secondaryColor});
	background-blend-mode: multiply;
	border-top:${props => props.small ? '3px' : '4px'} solid ${props => props.primaryColor};
	border-left:${props => props.small ? '3px' : '4px'} solid ${props => props.primaryColor};
	border-bottom:${props => props.small ? '3px' : '4px'} solid ${props => props.secondaryColor};
    border-right:${props => props.small ? '3px' : '4px'} solid ${props => props.secondaryColor};
    animation:${rotate} ${props => props.speed} infinite linear;
`

const StyledThemedPreloaderText = styled.div`
    text-align: center;
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.standard};
`

const PreloaderSpin = ({primaryColor = null, paddingTop = '12px', secondaryColor = null, backgroundcolor = null, speed = '1.15s', preloaderText = '', small=false, theme}) => {

    primaryColor = primaryColor === null ? theme.color.primary : primaryColor
    secondaryColor = secondaryColor === null ? theme.color.color15 : secondaryColor
    backgroundcolor = backgroundcolor === null ? theme.color.color0 : backgroundcolor

    const hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null
    }

    const hexToRgbString = (hex) => {
        const result = hexToRgb(hex)
        return `rgb(${result.r}, ${result.g}, ${result.b})`
    }

    return <StyledThemedPreloaderWrapper className='jest-themed-preloader' paddingTop={paddingTop}>
        <StyledThemedPreloader small={small}>
            <StyledThemedPreloaderInner small={small} primaryColor={hexToRgbString(primaryColor)} secondaryColor={hexToRgbString(secondaryColor)} backgroundcolor={hexToRgbString(backgroundcolor)} speed={speed} />
        </StyledThemedPreloader>
        {
            preloaderText !== null && <StyledThemedPreloaderText>{preloaderText}</StyledThemedPreloaderText>
        }
    </StyledThemedPreloaderWrapper>
}

PreloaderSpin.propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    backgroundcolor: PropTypes.string,
    preloaderText: PropTypes.string,
    speed: PropTypes.string
}

export default withTheme(PreloaderSpin)