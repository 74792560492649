import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledIconButtonWithLabel = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: ${(p) => p.justifyContent};
    flex-direction: row;
    border-radius: ${(p) => p.borderradius};
    box-shadow: ${(p) => (p.withShadow ? `1px 3px 6px ${p.theme.color.color20}` : 'unset')};
    border-bottom: 1px solid ${(props) => (props.cssUnderlined ? props.theme.color.color20 : 'transparent')};
    padding: ${(p) => p.padding};
    background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : p.theme.color.color0)};
    color: ${(props) => (props.priority === '3' ? props.theme.color.red : '')};
    :hover {
        background: ${(props) => (props.hoverBackground ? props.hoverBackground : props.theme.color.black10)};
        color: ${(props) => props.theme.color.color100};
        border-radius: ${(p) => p.borderradius};
    }

    :active {
        background: ${(props) => (props.activeBackground ? props.activeBackground : props.theme.color.black20)};
        color: ${(props) => props.theme.color.color100};
    }

    & > i {
        align-self: center;
        font-size: ${(props) => (props.iconSize !== '' ? props.iconSize : props.theme.fontSize.medium)};
        color: ${(props) => props.theme.color.color100};
    }
    & > span {
        align-self: center;
        font-size: ${(props) => (props.fontSize !== '' ? props.fontSize : props.theme.fontSize.standard)};
        padding-left: ${(props) => (props.paddingLeft !== '' ? props.paddingLeft : '8px')};
    }
    cursor: ${(props) => (!props.cssDisabled ? 'pointer' : 'default')};
    opacity: ${(props) => (!props.cssDisabled ? '1' : '0.3')};
`

const IconButtonWithLabel = ({
    className = '',
    justifyContent = 'center',
    padding = '16px',
    borderradius = '10px',
    withShadow = false,
    onButtonClick,
    iconKey,
    label,
    underlined = false,
    disabled = false,
    fontSize = '',
    iconSize = '',
    paddingLeft = '0px',
    hoverBackground = '',
    activeBackground = '',
    priority,
    backgroundColor,
}) => {
    return (
        <StyledIconButtonWithLabel
            padding={padding}
            justifyContent={justifyContent}
            backgroundColor={backgroundColor}
            borderradius={borderradius}
            withShadow={withShadow}
            className={className}
            cssUnderlined={underlined}
            onClick={!disabled && typeof onButtonClick !== 'undefined' ? onButtonClick : () => {}}
            cssDisabled={disabled}
            fontSize={fontSize}
            iconSize={iconSize}
            paddingLeft={paddingLeft}
            hoverBackground={hoverBackground}
            activeBackground={activeBackground}
            priority={priority}
        >
            <i className='material-icons'>{iconKey}</i>
            <span>{label}</span>
        </StyledIconButtonWithLabel>
    )
}

IconButtonWithLabel.propTypes = {
    className: PropTypes.string,
    onButtonClick: PropTypes.func,
    iconKey: PropTypes.string.isRequired,
    label: PropTypes.string,
    underlined: PropTypes.bool,
    disabled: PropTypes.bool,
    fontSize: PropTypes.string,
    iconSize: PropTypes.string,
    paddingLeft: PropTypes.string,
    hoverBackground: PropTypes.string,
    activeBackground: PropTypes.string,
}

export default IconButtonWithLabel
