import { call, put } from '@redux-saga/core/effects'
import { api } from 'application/api'
import { setAccountPublicInfo } from 'application/redux/actions/common/account_details'

export function* getPublicAccountInfo(action) {
    const accountKey = action.accountId !== undefined ? action.accountId : action.accountIdentify
    const isLookUp = action.getLookUpFirst !== undefined ? action.getLookUpFirst : false
    let responsePublicAccountInfo
    let accountId
    if (isLookUp) {
        const response = yield call(api.gateway.common.getAccountIdByAccountIdentify, accountKey)
        accountId = response.accountId
        responsePublicAccountInfo = yield call(api.gateway.common.getAccountPublicInfo, response.accountId)
    } else {
        responsePublicAccountInfo = yield call(api.gateway.common.getAccountPublicInfo, accountKey)
    }
    yield put(setAccountPublicInfo({
        ...responsePublicAccountInfo,
        accountId: accountId
    }))

    return responsePublicAccountInfo
}