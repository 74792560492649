import SearchInput from 'application/components/controls/form_elements/search_input'
import { useQuery } from 'application/components/hooks'
import { ROUTE_PARAMETERS, ROUTES } from 'application/constants'
import React from 'react'
import { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const SearchFieldTopBarSection = ({ userId }) => {
    const query = useQuery()
    let navigate = useNavigate()
    const searchValueAkte = query.getStringParam(ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER) !== '' ? query.getStringParam(ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER) : ''
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState(searchValueAkte)

    const search = (e) => {
        if (!isMobileOnly) {
            if (e.nativeEvent.keyCode === 13 && searchValue.length !== 0) {
                navigate(`${ROUTES.AKTEN}?${ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER}=${searchValue}`)
            } else {
                if (e.nativeEvent.keyCode === 13 && searchValue.length === 0) {
                    navigate(ROUTES.AKTEN)
                }
            }
        } else {
            navigate(`${ROUTES.DASHBOARD}?${ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER}=${searchValue}`)
        }
    }

    const handleChange = (e) => {
        setSearchValue(e.target.value)
        if (!isMobileOnly) {
            navigate(`${ROUTES.AKTEN}?${ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER}=${e.target.value}`)
        } else {
            navigate(`${ROUTES.DASHBOARD}?${ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER}=${e.target.value}`)
        }
    }

    const handleClick = () => {
        if (isMobileOnly) {
            navigate(ROUTES.DASHBOARD)
        }
    }

    return (
        <SearchInput
            handleSearchInputClick={handleClick}
            autoFocus={searchValueAkte.length !== 0}
            placeholder={t('Search Akten')}
            onKeyPress={search}
            value={searchValue}
            onChangeSearch={handleChange}
        />
    )
}

export default SearchFieldTopBarSection
