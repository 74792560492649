import { call, put } from '@redux-saga/core/effects'
import { api } from 'application/api'
import { updateDataOnMessage } from 'application/redux/actions/messages'
import { registeredMessageQueueSent, unregisterMessageQueue } from 'application/redux/actions/pages/akten/common'
import dayjs from 'dayjs'
import { replaceQueueMessageFolderSaga } from '../messages/replace_queue_message_folder_saga'

export function* sendSingleMessageFolderSaga(action) {
    yield call(api.gateway.pages.postMessageOnFolder, action.metaData.accountId, action.metaData.akteIdHash, action.metaData.folderId, action.payload)
    yield put(registeredMessageQueueSent(action.idOnQueue))
    yield call(replaceQueueMessageFolderSaga, action)
    yield put(unregisterMessageQueue(action.idOnQueue))
    yield put(updateDataOnMessage(action.messageKey, {lastSync: dayjs()}))
}