import { populateMetaInfoAkte } from 'application/redux/actions/pages/akten'
import { getAllAccounts } from 'application/redux/selectors'
import { put, select } from 'redux-saga/effects'

export function* getAkteMetaInfoSaga(action) {
    const accounts = yield select(getAllAccounts)
    const isExist = accounts.filter(a => a.id === parseInt(action.accountId)).length > 0
    const accountName = isExist ? accounts.find(a => a.id === parseInt(action.accountId)).name : ''
    const selectedAccount = isExist ? accounts.find(a => a.id === parseInt(action.accountId)) : {}
    let metaInfo = {
        accountId: action.accountId,
        accountName,
        ...selectedAccount,
    }
    yield put(populateMetaInfoAkte(metaInfo))
    return metaInfo
}