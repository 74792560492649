import { SettingPreview } from 'application/components/controls/menu_preview'
import { FlexGrid } from 'application/components/fragments/grid'
import { DASHBOARD, ROUTES } from 'application/constants'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { useParams } from 'react-router-dom'
import { PluginAlternateView } from 'plugins'

const SettingListComponent = ({ accountPublicInfo }) => {
    let params = useParams()
    const {t} = useTranslation()
    
    return <React.Fragment>
        <FlexGrid directionColumn>
            <SettingPreview 
                withBorder
                isselected={params.selectedSetting === DASHBOARD.LINK.SETTINGS.ACCOUNT}
                to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.ACCOUNT}
                titleMenu={t('Profile Setting')}
                descMenu={t('Profile picture, Password')}
                width='90%'
            />
            <SettingPreview  
                withBorder
                isselected={params.selectedSetting === DASHBOARD.LINK.SETTINGS.GENERAL}
                to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.GENERAL}
                titleMenu={t('General')}
                descMenu={t('Language')}
                width='90%'
            />
            {/* <PluginAlternateView id="settings.about">
                <SettingPreview
                        withBorder
                        isselected={params.selectedSetting === DASHBOARD.LINK.SETTINGS.ABOUT}
                        to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.ABOUT}
                        titleMenu={t('About e.sy One')}
                        descMenu={t('Feedback')}
                        width='90%'
                />
            </PluginAlternateView>      */}
        </FlexGrid>
    </React.Fragment>
}

export default SettingListComponent