import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { FileIcon, defaultStyles } from 'react-file-icon'

const StyledFallbackThumbnailComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid 'transparent';
    height: ${(props) => (props.divHeight ? props.divHeight : '70px')};
    width: ${(props) => (props.divWidth ? props.divWidth : '70px')};
    & svg {
        width: 75%;
    }
`
const FallbackThumbnailComponent = (props) => {
    const { divHeight, divWidth } = props
    const theme = useTheme()
    const fileExtensionLowercase = props.fileExtension.toLowerCase()
    const isColorPrimary = fileExtensionLowercase === 'png' || fileExtensionLowercase === 'jpg'
    return (
        <StyledFallbackThumbnailComponent isActive={props.isActive} divHeight={divHeight} divWidth={divWidth}>
            <FileIcon extension={fileExtensionLowercase} labelColor={isColorPrimary && theme.color.primary} {...defaultStyles[fileExtensionLowercase]}  />
        </StyledFallbackThumbnailComponent>
    )
}

FallbackThumbnailComponent.propTypes = {
    fileExtension: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
}

export default FallbackThumbnailComponent
