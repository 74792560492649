import React from 'react'
import { useTranslation } from 'react-i18next'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import ActionButton from 'application/components/controls/button/action_button/component'
import { PluginAlternateView } from 'plugins'
import { useTheme } from 'styled-components'

const RequestButton = ({ akteMeta, pinRequest, handleRequestPin, useMobile = false }) => {
    const {t} = useTranslation()
    const theme = useTheme()
    return !akteMeta.isSmsAvailable && pinRequest ? <PreloaderSpin
        primaryColor={theme.color.primary}
    /> :
    <PluginAlternateView id="request_pin.button" handleRequestPin={handleRequestPin}>
        <ActionButton
            buttonText={t('RequestPIN')}
            onButtonClick={handleRequestPin}
            strechted
            secondary
            borderradius={useMobile ? '10px' : '0px'}
        />
    </PluginAlternateView>

}

export default RequestButton
