import React from 'react'
import Div100vh from 'react-div-100vh'
import { GlobalStyle } from './global_style'
import * as Fragments from '../fragments'
import Toaster from 'application/components/building_blocks/toaster'
import Dialogs from 'application/components/building_blocks/dialog/xdialogs'
import WaitingScreen from 'application/components/building_blocks/waiting_screen'
import Gallery, { GallerySendFilesMessage } from 'application/components/building_blocks/attachments/gallery'
import BaseDetectDevice from './base_detect_device'
import { Helmet } from 'react-helmet'
import { nanoid } from 'nanoid'
import { isAndroid, isIOS, isMobileOnly } from 'react-device-detect'
import { useLocation, useParams } from 'react-router-dom'
import { getRoutePath } from 'application/common/route_helpers'
import { ROUTES } from 'application/constants'
import { useTheme } from 'styled-components'
import { useEffect } from 'react'

const WithDiv100 = props => {
    return <Div100vh id={`ec-container-${nanoid()}`}>
        <Fragments.Wrapper id='wrapperLB--ec-w-100_vh' {...props}>
            {props.children}
        </Fragments.Wrapper>
    </Div100vh>
}

export const LayoutBase = (props) => {
    const loggedIn = props.registeredLoggedIn || props.unregisteredLoggedIn
	const { withToaster = true, internal = false, withDiv100 = true } = props
    const theme = useTheme()

	let location = useLocation()
	let params = useParams()
	let iosHeightAdjust = isMobileOnly && isIOS
	let mobileAndroid = isMobileOnly && isAndroid
	let matchUrlPattern = getRoutePath(location, params)
	let inAkten = matchUrlPattern === ROUTES.AKTEN

    useEffect(() => {
        document.title = theme.title
        document.querySelector('link[rel~=icon]').href = theme.favicon
    }, [theme])
    
    return <BaseDetectDevice {...props}>
        <React.Fragment>
            {
                props.newMessageTitle !== '' && loggedIn &&
                    <Helmet>
                        <title>{props.newMessageTitle}</title>
                    </Helmet>
                
            }
            <GlobalStyle inAkten={inAkten} mobileAndroid={mobileAndroid} iosHeightAdjust={iosHeightAdjust} internal={internal} />
            {
				withDiv100 ? <WithDiv100 {...props}>
					{props.children}
				</WithDiv100> : <Fragments.Wrapper id='wrapperLB--ec' {...props}>
                    {props.children}
                </Fragments.Wrapper>
            }
            {
                withToaster &&
                <Toaster />
            }
            <WaitingScreen />
            <Dialogs />
            <Gallery />
            <GallerySendFilesMessage />
        </React.Fragment>
    </BaseDetectDevice>
}

export default LayoutBase