import { accessTokenRegistered, accessTokenUnregistered } from 'application/storage'
import React, { Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import { accessTokenForm } from 'application/storage/access_token'

const SetToken = props => {
    const { children } = props
    let tokenReg = accessTokenRegistered.get()
    let tokenUnReg = accessTokenUnregistered.get()
    if (tokenReg !== '' && tokenUnReg === '') {
        accessTokenUnregistered.store(tokenReg)
        accessTokenForm.store(tokenReg)

    }
    if (tokenReg === '' && tokenUnReg !== '') {
        accessTokenRegistered.store(tokenUnReg)
        accessTokenForm.store(tokenUnReg)
    }
    
    if (tokenReg === '' && tokenUnReg === '') {
        return <Navigate to={ROUTES.BASE} />
    }
    

    
    return <Fragment>
        {children}
    </Fragment>
}

export default SetToken
