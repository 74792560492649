export const validationMethods = {
    
    //for new validation with useForm
    notEmpty: value => {
        return (value !== '' && value !== undefined && value !== null) || 'RequiredFieldValidation'
    },
    notEmptyPassword: value => {
        return (value !== '' && value !== undefined && value !== null) || 'PasswordNotEmpty'
    },
    notEmptyNewPassword: value => {
        return (value !== '' && value !== undefined && value !== null) || 'PasswordNewNotEmpty'
    },
    notEmptyExistingPassword: value => {
        return (value !== '' && value !== undefined && value !== null) || 'ExistingPasswordNotEmpty'
    },
    notEmptyDescription: value => {
        return (value !== '' && value !== undefined && value !== null) || 'DescriptionNotEmpty'
    },
    notEmptyEmail: value => {
        return (value !== '' && value !== undefined && value !== null) || 'EmailNotEmpty'
    },
    mustBeEmail: value => {
        return (value === '' || value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) || 'EmailValidation'
    },
    //deprecated, for reference
    passwordMinLength: value => {
        return value.length >= 8
    },
    includesLowerCaseCharacter: value => {
        return value.match(/[a-z]/)
    },
    includesUpperCaseCharacter: value => {
        return value.match(/[A-Z]/)
    },
    includesSpecialCharacter: value => {
        return value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)
    },
    includesNumericCharacter: value => {
        return value.match(/\d+/)
    },
    emailRegex: value => {
        return value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    },
    phoneRegex: value => {
        return value.match(/^.{4,15}[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/)
    },
    rangeLengthRegex : value => {
        return value.match(/^.{3,5}$/)
    },
    rangeLengthPayload : valueKey => {
        return (valueKey.length > 2 && valueKey.length < 5)
    },
    maxLengthRegex : value => {
        return value.match(/^.{0,10}$/)
    },
    maxLengthPayload : valueKey => {
        return (valueKey.length < 11)
    },
    minLengthRegex : value => {
        return value.match(/^.{2,}$/)
    },
    minLengthPayload : valueKey => {
        return (valueKey.length > 0)
    },
    zipCodeRegex : value => {
        return value.match(/^\d{5}$/)
    },
}

export const getErrorMessage = () => { }

export const passwordStrengthScore = value => {
    let score = 0
    if (validationMethods.includesLowerCaseCharacter(value)) {
        score += 1
    }
    if (validationMethods.includesUpperCaseCharacter(value)) {
        score += 1
    }
    if (validationMethods.includesSpecialCharacter(value)) {
        score += 1
    }
    if (validationMethods.includesNumericCharacter(value)) {
        score += 1
    }
    return score > 2 || 'PasswordSecurityCriteria'
}