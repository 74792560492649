import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem, PushBottom10 } from 'application/components/fragments/grid'
import { Paragraph } from 'application/components/fragments/headlines'
import { LayoutMobile } from 'application/components/pages/_layout'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import {
    isIOS,
    isAndroid
} from 'react-device-detect'
import { NavLink } from 'react-router-dom'
import { ROUTES } from 'application/constants'

const MobileWrapper = styled.div`
    padding: 0px 20px;
`

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`

const ActivateModeComponent = (props) => {
    return <LayoutMobile>
        <PushBottom10 />
        <FlexGrid directionColumn justifySpaceBetWeen height='100%' maxWidth='100%'>
            <FlexGridItem grow={0}>
                <FlexGrid directionColumn>
                    <FlexGridItem justifyCenter>
                        <StyledNavLink to={ROUTES.BASE}>
                            <ImagePlaceHolder large='30px' withBorder={false} source='/assets/images/icon_esy_one.png' />
                        </StyledNavLink>
                    </FlexGridItem>
                    <FlexGridItem justifyCenter>
                        <ImagePlaceHolder large='420px' withBorder={false} source='/assets/images/activate_account.png' />
                    </FlexGridItem>
                    <FlexGridItem textCenter justifyCenter>
                        <MobileWrapper>
                            <Paragraph fontWeight={props.theme.fontWeight.bold} textAlign='center'>
                                to activate account. you should download the app.
                            </Paragraph>
                        </MobileWrapper>
                    </FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
            <FlexGridItem grow={1} justifyCenter itemsFlexEnd>
                <FlexGrid>
                    {
                        isIOS &&
                        <FlexGridItem justifyCenter>
                            <ImagePlaceHolder linkHref={process.env.REACT_APP_INSTALL_APP_IOS} large='150px' withBorder={false} source='/assets/images/app-store-badge.png' />
                        </FlexGridItem>
                    }
                    {
                        isAndroid &&
                        <FlexGridItem justifyCenter>
                            <ImagePlaceHolder linkHref={process.env.REACT_APP_INSTALL_APP_ANDROID} large='150px' withBorder={false} source='/assets/images/google-play-badge.png' />
                        </FlexGridItem>
                    }
                </FlexGrid>
            </FlexGridItem>
        </FlexGrid>
    </LayoutMobile>
}

export default withTheme(ActivateModeComponent)