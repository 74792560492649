import { call, put, takeEvery } from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { 
    populateUserData,
    populateTokenData,
    loginRequestSuccess,
    populateProfilePicture,
    getProfilePictureFailed,
} from 'application/redux/actions/auth'
import { handleError } from '../../errors'

function* userDataSaga(action) {
    try {
        const userDataResponse = yield call(api.external.auth.getUserData, action)
        yield put(populateUserData(userDataResponse))

        const tokenDataResponse = yield call(api.external.auth.getTokenData, action)
        yield put(populateTokenData(tokenDataResponse))

        yield put(loginRequestSuccess())
    } catch (e) {
        //yield put(showLoginError(e.data.errorKey))
    }
}

function* userProfilePictureSaga(action) {
    try {
        const result = yield call(api.gateway.auth.getUserProfilePicture, action.accountId, action.userId)
        const blobUrl = window.URL.createObjectURL(result)
        yield put(populateProfilePicture(blobUrl))
    } catch (e) {
        if (e.response.status === 404) {
            yield put(getProfilePictureFailed())
        } else {
            yield handleError(e, action)
        }
    }
}

export function* userDataWatcher() {
    yield takeEvery(ACTIONS.AUTH_GET_USER_DATA_REQUEST, userDataSaga)
    yield takeEvery(ACTIONS.AUTH_GET_USER_PROFILE_PICTURE, userProfilePictureSaga)
}