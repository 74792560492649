import { Plugin } from '../../model/plugin_class'
import { LoginFooterLogo } from './components/auth/login/login_footer_logo'
import { AboutSettings } from './components/settings/about'
import { LoginButtonSubmit } from './components/auth/login/login_button_submit'
import { LoginAccountDownloadApp } from './components/auth/login_account/login_account_download_app'
import { LoginAccountFooterPowered } from './components/auth/login_account/login_account_footer_powered'
import { LawBannerTextMyk } from './components/building_blocks/law_banner/law_banner_text'
import { LogoTopBar } from './components/building_blocks/top_bar/logo_top_bar'
import { MobileAssistantFormSucceed } from './components/mobile/mobile_assistant_form_succeed'
import Config from './config'
import Theme from './theme'
import { MobileAssistantFormPowered } from './components/mobile/mobile_assistant_form_powered'
import { RequestPinButton } from './components/auth/request_pin/request_pin_button'
import Resources from './resources'
import { NavigationDashboard } from './components/building_blocks/nav_internal/navigation_dashboard'
import { AufgabeNoData } from './components/building_blocks/aufgaben/aufgabe_nodata'
import { AktenSelectCase } from './components/building_blocks/akten/select_case'
import { MessageEmpty } from './components/building_blocks/messages/empty_message'
import { AktenSidebarAssistantsButton } from './components/building_blocks/akten/sidebar_assistants_button'
import { AktenSidebarAssistantsPhone } from './components/building_blocks/akten/sidebar_assistants_phone'
import { MobileAssistantFooterPolicy } from './components/mobile/mobile_assistant_footer_policy'
import { MobileViewAktenFavorites } from './components/mobile/mobile_akten_favorites'
import { MobileViewAktenFolders } from './components/mobile/mobile_akten_folders'
import { MobileViewAktenFiles } from './components/mobile/mobile_akten_files'
import { NotFoundBigFoot } from './components/building_blocks/not_found/not_found_big_foot'
import { RequestCredentialsMailSentImage } from './components/auth/request_credentials/request_credentials_mail_sent'


const mykPlugin = new Plugin()
mykPlugin.id = 'myk'
mykPlugin.name = 'MyK'
mykPlugin.description = 'MyK Plugin'
mykPlugin.version = '1.0.0'
mykPlugin.config = Config
mykPlugin.theme = Theme
mykPlugin.resources = Resources
mykPlugin.components = {
  'settings.about': AboutSettings,
  'login.footer.logo': LoginFooterLogo,
  'login.button.submit': LoginButtonSubmit,
  'login.account.download.app': LoginAccountDownloadApp,
  'login.account.footer.powered': LoginAccountFooterPowered,
  'request.credentials.mail_sent_image': RequestCredentialsMailSentImage,
  'lawbanner.text': LawBannerTextMyk,
  'topbar.logo': LogoTopBar,
  'mobile.assistant.form.succeed' : MobileAssistantFormSucceed,
  'mobile.assistant.footer.powered': MobileAssistantFormPowered,
  'mobile.assistant.footer.policy': MobileAssistantFooterPolicy,
  'request_pin.button': RequestPinButton,
  'navigation.dashboard': NavigationDashboard,
  'aufgabe.nodata': AufgabeNoData,
  'akten.select_case': AktenSelectCase,
  'messages.empty_message': MessageEmpty,
  'akten.sidebar.assistants_button': AktenSidebarAssistantsButton,
  'akten.sidebar.phone': AktenSidebarAssistantsPhone, 
  'mobile_view.akten.folders': MobileViewAktenFolders,
  'mobile_view.akten.files': MobileViewAktenFiles,
  'mobile_view.akten.favorites': MobileViewAktenFavorites,
  'notfound.bigfoot': NotFoundBigFoot, 
}

export {
  mykPlugin
}