import { getDatenshutzRequest } from 'application/redux/actions/common/agb_datenschutz'
import { connect } from 'react-redux'

import DatenshutzDialog from './component'

const mapStateToProps = (state, props) => {
    const registeredDatenshutz = state.common.agbDatenschutz.registeredDatenshutz
    return {
        dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name),
        registeredDatenshutz,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDatenshutzAccount: (accountId, accountName, userRegistered) => {
            dispatch(getDatenshutzRequest(accountId, accountName, userRegistered))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DatenshutzDialog)