import { setConsentAccept } from 'application/redux/actions/consent_use_app'
import { connect } from 'react-redux'
import ConsentBannerComponent from './component'

const mapStateToProps = (state) => ({
    consentUseApp: state.consentUseApp.accept
})
const mapDispatchToProps = (dispatch) => ({
    onConsentAccept: (dateAccept) => dispatch(setConsentAccept(dateAccept))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConsentBannerComponent)