export const userMapperImport = (userData) => {
    return {
        firstName: userData.firstName,
        lastName: userData.lastName,
        accountId: userData.accountId,
        accountIds: userData.accountIds,
        salutation: userData.salutation,
        userId: userData.userId,
        email: userData.email
    }
}