import { registerFullFileById, registerThumbnailById } from 'application/redux/actions/files'
import { getNewFilesSucceed } from 'application/redux/actions/pages/akten'
import { getAktenFiles } from 'application/redux/selectors'
import { put, select } from 'redux-saga/effects'

export function* getFilesFromMessagesSaga(action) {
    const files = yield select(getAktenFiles)
    if (files.length > 0) {
        for (const file of files) {
            yield put(registerThumbnailById(file.id, file.fileName, { messageId: file.messageId, accountId: action.accountId, parentId: action.akteIdHash, }))
            yield put(registerFullFileById(file.id, file.fileName, { messageId: file.messageId, accountId: action.accountId, parentId: action.akteIdHash, }))
        }
    }
    yield put(getNewFilesSucceed())
}