import ACTIONS from 'application/constants'
import default_store from '../../../default_store'

const aktenActionReadMessageReducer = (state = default_store.pages.akten.action.readMessage, action) => {
    switch (action.type) {
    case ACTIONS.PAGES_AKTEN_READ_MESSAGE_REQUEST: return {
        ...state,
        request: true,
        id: action.messageId,
    }
    case ACTIONS.PAGES_AKTEN_READ_MESSAGE_SUCCEED: return {
        ...state,
        request: false,
        succeed: true,
        id: null,
    }
    case ACTIONS.PAGES_AKTEN_READ_MESSAGE_FAILED: return {
        ...state,
        request: false,
        succeed: false,
        failed: true,
        id: null
    }
    default:
        return state
    }
}

export default aktenActionReadMessageReducer