import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import { LayoutBase } from '..'
import * as Fragment from '../fragments/wizard'
import { WIZARD_STEP } from 'application/constants'
import { isMobileOnly, isTablet } from 'react-device-detect'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const WizardLayout = ({ accountId, step, children, wizardData, paddingTop, paddingRight, paddingBottom, paddingLeft, noPaddingRightOnContent = false, idInput = '', heightInput = '100%', backStepArrowMobile = false }) => {
    const { getAsset } = usePluginsContext()
    
    const bg1 = getAsset('/wizard/wiz1.jpg')
    const bg2 = getAsset('/wizard/wiz2.jpg')
    const bg3 = getAsset('/wizard/wiz3.jpg')

    const wizardBg = (step) => {
        switch (step) {
        case WIZARD_STEP.REGISTRATION.WELCOME:
            return bg1 
        case WIZARD_STEP.REGISTRATION.USERNAME:
            return bg2
        case WIZARD_STEP.REGISTRATION.PASSWORD:
            return bg3
        case WIZARD_STEP.REGISTRATION.DONE:
            return bg3
        default:
            return bg1
        }
    }

    return  <LayoutBase withDiv100={false}>
            <Fragment.LayoutWrapper id='LayoutWrapper-ec'>
                <Fragment.LayoutInfoWrapper background={wizardBg(step)} style={{ backgroundPosition: 'center' }} />
                <Fragment.LayoutInputWrapper
                    paddingTop={paddingTop}
                    paddingRight={paddingRight}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    id={idInput}
                >
                    <FlexGrid noWrap heightTablet='100%' height={isMobileOnly ? '100%' : isTablet ? heightInput : 'auto'} justifySpaceBetween directionColumn>
                        {children}
                    </FlexGrid>
                </Fragment.LayoutInputWrapper>
            </Fragment.LayoutWrapper>
        </LayoutBase>
}

export default WizardLayout