import { ACTIONS } from 'application/constants'
import { accessTokenRegistered } from 'application/storage'
import default_store from '../../default_store'

const registeredReducer = (state = {
    ...default_store.auth.registered,
    tokenSet: accessTokenRegistered.get() !== '',
    loggedIn: accessTokenRegistered.get() !== ''
}, action) => {
    switch (action.type) {
    case ACTIONS.AUTH_LOGIN_REQUEST: {
        return {
            ...state,
            loginRequest: true,
            loggedIn: false,
            loginFailed: false,
        }
    }
    case ACTIONS.AUTH_WRITE_ACCESS_TOKEN: {
        accessTokenRegistered.store(action.accessToken)
        return {
            ...state,
            tokenSet: true,
        }
    }
    case ACTIONS.AUTH_LOGIN_SUCCESS: {
        return {
            ...state,
            loggedIn: true,
            loginRequest: false,
            loginFailed: false,
        }
    }
    case ACTIONS.AUTH_LOGIN_FAILED: {
        return {
            ...state,
            loggedIn: false,
            loginRequest: false,
            loginFailed: true,
            payloadFailed: action.payload,
        }
    }
    case ACTIONS.AUTH_RERESH_PAYLOAD_FAILED: {
        return {
            ...state,
            loggedIn: false,
            loginRequest: false,
            loginFailed: false,
            payloadFailed: null,
        }
    }
    default:
        return state
    }
}

export default registeredReducer