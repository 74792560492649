import { loginRequestSuccess, writeToken } from 'application/redux/actions/auth'
import { connect } from 'react-redux'
import WizardRegistratioFinalComponent from './component'

const mapStateToProps = state => ({
    wizardData: state.wizard.data,
    wizardLoaded: state.wizard.isLoaded,
    akteMeta: state.akteMeta.payload,
    auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
    wizardDone: (accessToken) => {
        dispatch(writeToken(accessToken))
        dispatch(loginRequestSuccess())
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardRegistratioFinalComponent)