import styled from 'styled-components'

export const LayoutContentAktenMobile = styled.div`
    width: 100%;
    flex: 3.5;
    height: 100%;
    padding: 20px;
    overflow: auto;
`

export const WrapperContentAktenMobile = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${(p) => (p.gap ? p.gap : 'unset')};
`

export const FolderWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 30%;
`

export const FolderName = styled.p`
    text-align: center;
    margin: 0px;
`

export const CardNoContentWrapper = styled.div`
    width: 70%;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`

export const ImageNoContent = styled.img`
    width: 100%;
    height: auto;
`

export const ParNoContent = styled.p`
    font-weight: ${(p) => p.theme.fontWeight.bold};
    text-align: center;
`
