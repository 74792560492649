import ActionButton from 'application/components/controls/button/action_button/component'
import FilterPill from 'application/components/controls/filter_pill_basic/component'
import { FlexGrid, FlexGridItem, PushBottom30 } from 'application/components/fragments/grid'
import { ROUTES } from 'application/constants'
import React from 'react'
import { Fragment } from 'react'
import * as Fragments from './fragments'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const ContentLayout = ({flexStart, children, maxWidth}) => {
    return flexStart ? <FlexGrid directionColumn maxWidth={maxWidth}>
        {children}
    </FlexGrid> : <FlexGrid directionColumn itemsCenter maxWidth={maxWidth}>
        {children}
    </FlexGrid>
}

const ContentNotFound = ({maxWidth = '50%', flexStart = false, withDesc = false, errorCodeText = 'Error 404', enablePill = true, textAlignHeadline = 'start'}) => {
    const widthPill = isMobile ? '80%' : '20%'
    const {t} = useTranslation()
    const {activePlugin} = usePluginsContext()
    return <ContentLayout flexStart={isMobile ? false : flexStart} maxWidth={maxWidth}>
        {enablePill === true &&
            <FilterPill width={widthPill} withIcon={false} text='Page not found' disabled={true} />
        }
        <PushBottom30 />
        {
            withDesc && <Fragment>
                <Fragments.HeadlineNotFound isMobile={isMobile} isTablet={isTablet} textAlignHeadline={textAlignHeadline}>
                    {errorCodeText}
                </Fragments.HeadlineNotFound>
                {/* {!activePlugin &&
                    <Fragments.DescriptionNotFound isMobile={isMobile} isTablet={isTablet}>
                        The page is broken.
                    </Fragments.DescriptionNotFound>
                }
                {!activePlugin &&
                    <Fragments.DescriptionNotFound isMobile={isMobile} isTablet={isTablet}>
                        Comeback to the homepage.
                    </Fragments.DescriptionNotFound>
                } */}
            </Fragment>
        }
        <PushBottom30 />
        <FlexGridItem>
            <ActionButton linkTo={ROUTES.BASE} fontSize={'14px'} buttonText={t('ErrorRedirectButtonText')} strechted={isMobile} />
        </FlexGridItem>
    </ContentLayout>
}

export default ContentNotFound
