import { forgotPasswordRequest } from 'application/redux/actions/auth'
import { connect } from 'react-redux'
import RequestPasswordComponent from './component'
import { getAccountPublicInfo } from 'application/redux/actions/common/account_details'

const mapStateToProps = state => ({
    passwordRequest: state.auth.forget.password.request,
    passwordSucceed: state.auth.forget.password.succeed,
    accountPublicInfo: state.common.accountDetails.publicInfo,
    publicInfoFailed: state.common.accountDetails.publicInfoFailed,
})

const mapDispatchToProps = dispatch => ({
    onReqPassword: (username, errorMessage, successMessage) => dispatch(forgotPasswordRequest(username, errorMessage, successMessage)),
    getAccountPublicInfo: (accountIdentify) => dispatch(getAccountPublicInfo(accountIdentify)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestPasswordComponent)