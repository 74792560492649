import React from 'react'
import { useEffect } from 'react'
import { Fragment } from 'react'

const AkteEffects = (props) => {
    const { children, selectedAkte, setSelectedAkte, akteIdHash, accountId, allAkten, allAktenLoaded, allAccountsLoaded } = props
    const akteToSelect = allAkten.length > 0 && allAkten.filter(a => a.idHash === akteIdHash).length > 0 && allAkten.find(a => a.idHash === akteIdHash)
    const shouldSelect = selectedAkte === null && akteIdHash !== '' && accountId !== '' && allAccountsLoaded && allAktenLoaded && akteToSelect
    useEffect(() => {
        shouldSelect && setSelectedAkte(akteToSelect)
    },[akteToSelect, setSelectedAkte, shouldSelect])
    return <Fragment>
        {children}
    </Fragment>
}

export default AkteEffects
