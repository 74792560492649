import { FlexGridItem } from 'application/components/fragments/grid'
import React, { useState } from 'react'
import { MainLogoCompany } from '../company_logo'
import { StyledProviderCard } from './fragments'
import CategoriesServiceProviderCard from './categories'
import styled, { withTheme } from 'styled-components'
import Badge from 'application/components/controls/badge/component'
import { Spacer } from 'application/components/pages/internal/dashboard/fragments'
import FontIcon from 'application/components/controls/font_icon'

const StyledCompanyName = styled.p`
  font-size: ${props => props.theme.fontSize.headline3};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
  color: ${props => props.color};
`

const ServiceProviderCardActive = ({
    accountDetail,
    isActive = false,
    onCardClick = () => { },
    theme,
}) => {
    const { totalUnreadMessage } = accountDetail
    const [expanded, setExpanded] = useState(false)
    return <StyledProviderCard expanded={expanded} onMouseDown={() => setExpanded(!expanded)} isActive={isActive} onClick={onCardClick}>
        {
            totalUnreadMessage > 0 ?
                <FlexGridItem justifyFlexEnd>
                    <FontIcon fontSize='24px' icon='email' color={theme.color.anthracite} />
                    <Badge fontSize='10px' large='20px' mountNotification={totalUnreadMessage} />
                </FlexGridItem> : <Spacer distance='24px' />
        }
        <FlexGridItem itemsCenter justifyCenter>
            <MainLogoCompany
                activated={true}
                accountId={accountDetail.id}
                accountName={accountDetail.name}
            />
        </FlexGridItem>
        <FlexGridItem itemsFlexEnd justifyCenter>
            <StyledCompanyName color={accountDetail.mainColor}>
                {accountDetail.name}
            </StyledCompanyName>
        </FlexGridItem>
        <CategoriesServiceProviderCard accountDetail={accountDetail} />
    </StyledProviderCard>
}

export default withTheme(ServiceProviderCardActive)