import React from 'react'
import styled from 'styled-components'

export const SliderInquiryHeadline = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.color.color90};
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const NextArrowSliderInquiry = (props) => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'none' }}
            onClick={onClick}
        />
    )
}

export const PrevArrowSliderInquiry = (props) => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'none' }}
            onClick={onClick}
        />
    )
}