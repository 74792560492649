import ACTIONS from 'application/constants'
import default_store from '../../default_store'

const allAccounts = (state = default_store.common.allAccounts, action) => {
    switch (action.type) {
    case ACTIONS.COMMON_ALL_ACCOUNTS_POPULATE_DATA:
        return {
            ...state,
            isLoaded: true,
            isLoading: false,
            payload: action.payload
        }
    case ACTIONS.COMMON_GET_ALL_ACCOUNTS_FAILED:
        return {
            ...state,
            isLoaded: true,
            isLoading: false,
            failed: true,
        }
    case ACTIONS.COMMON_ALL_ACCOUNTS_UPDATE_DATA_BY_ID:
        return {
            ...state,
            isLoaded: true,
            isLoading: false,
            payload: state.payload.map(d => {
                return d.id === action.accountId ? {
                    ...d,
                    ...action.payload
                } : d
            })
        }
    case ACTIONS.COMMON_POPULATE_TOTAL_UNREAD_MESSAGE_EACH_ACCOUNT:
        return {
            ...state,
            payload: state.payload.map(d => {
                return d.id === +action.accountId ? {
                    ...d,
                    totalUnreadMessage: d.totalUnreadMessage + action.totalUnreadMessage,
                } : d
            })
        }
    case ACTIONS.COMMON_ALL_ACCOUNTS_REQUEST_DATA:
        return {
            ...state,
            isLoading: true,
            isLoaded: false,
            lastSync: new Date(),
        }
    case ACTIONS.COMMON_REFRESH_ALL_ACCOUNTS_DATA: return {
        ...state,
        lastSync: null,
        isLoaded: false,
        isLoading: false,
        failed: false,
        payload: [],
    }
    default:
        return state
    }
}

export default allAccounts