import * as Fragment from '../../../../../../application/components/pages/external/auth/fragments'
import { useTranslation } from 'react-i18next'

export function LoginFooterLogo() {
  const { t } = useTranslation()

  return <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }}>
    <Fragment.StyledImageBigLogo style={{ width: 80 }} src='/assets/images/wourage.png' />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'space-around',
      height: '100%',
      fontSize: '12px',
      margin: '0 0 0 12px',
      gap: '8px'
    }}>
      <a href='https://www.wourage.de/agb' target='_blank' style={{ color: '#000', textDecoration: 'none' }} rel="noreferrer">{t('agbText')}</a>
      <a href='https://www.wourage.de/datenschutz' target='_blank' style={{ color: '#000', textDecoration: 'none' }} rel="noreferrer">{t('privacy')}</a>
      <a href='https://www.wourage.de/impressum' target='_blank' style={{ color: '#000', textDecoration: 'none' }} rel="noreferrer">{t('legal notice')}</a>
    </div>
  </div>
}