import { useQuery } from 'application/components/hooks'
import { ROUTE_PARAMETERS } from 'application/constants'
import React, { Fragment, useEffect } from 'react'

const ServiceProviderEffects = (props) => {
    const { children, onCardClick, accountDetail, isActive } = props
    const query = useQuery()

    const accid = query.getStringParam(ROUTE_PARAMETERS.DASHBOARD.ACCOUNT_ID) === '' ? '' : query.getStringParam(ROUTE_PARAMETERS.DASHBOARD.ACCOUNT_ID)

    const shouldClick = accid !== '' && parseInt(accid) === accountDetail.id && !isActive
    useEffect(() => {
        shouldClick && onCardClick()
    }, [accid, accountDetail, onCardClick, shouldClick])
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default ServiceProviderEffects
