import React from 'react'
import * as Fragment from './fragments'
import { FlexGrid, FlexGridItem, PushBottom10, PushLeft10 } from 'application/components/fragments/grid'
import { withTheme } from 'styled-components'
import { Anchorme } from 'react-anchorme'
import ChatBubbleFilesLoader from 'application/components/controls/preloader/files_loader'
import DateComponent from 'application/components/controls/date'
import Time from 'application/components/controls/time'
import FontIcon from 'application/components/controls/font_icon'

const DefaultChatBubbleNotDeliveredComponent = (props) => {
    const {
        messageDetail,
        theme,
        isFavoriteSelected,
    } = props
    const { message, creatorName, creationDate, isOwnMessage, attachments } = messageDetail
    const dateOnMessage = creationDate
    const showedFiles = attachments.length > 4 ? attachments.slice(0, 4) : attachments
    return <React.Fragment>
        <Fragment.ChatBubbleWrapper isOwnMessage={isOwnMessage}>
           
            <Fragment.ChatBubbleInner isFavoriteSelected={isFavoriteSelected}>
                <FlexGrid justifySpaceBetween>
                    <Fragment.ChatBubbleCreator>
                        {creatorName}
                    </Fragment.ChatBubbleCreator>
                </FlexGrid>
                <PushBottom10 />
                <PushBottom10 />
                <React.Fragment>
                    <FlexGrid>
                        {
                            showedFiles.length > 0 && <React.Fragment>
                                <PushBottom10 />
                                {
                                    showedFiles.map((s, i) => {
                                        return <ChatBubbleFilesLoader key={i} />
                                    })}
                            </React.Fragment>
                        }
                    </FlexGrid>
                </React.Fragment>
                <FlexGridItem>
                    <Fragment.ChatBubleMessage>
                        <Anchorme target="_blank" rel="noreferrer noopener">
                            {message}
                        </Anchorme>
                    </Fragment.ChatBubleMessage>
                </FlexGridItem>
                <PushBottom10 />
                <Fragment.ChatBubleDate>
                    <DateComponent date={dateOnMessage} /> - <Time date={dateOnMessage} timeHourSuffix='' />
                    <PushLeft10 />
                    <FontIcon icon='done' color={theme.color.color70} fontSize={theme.fontSize.medium18} />
                </Fragment.ChatBubleDate>
            </Fragment.ChatBubbleInner>
            
        </Fragment.ChatBubbleWrapper>
    </React.Fragment>
}

const DefaultChatBubbleNotDelivered = withTheme(DefaultChatBubbleNotDeliveredComponent)
export default DefaultChatBubbleNotDelivered