import React from 'react'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { DialogMainContent } from 'application/components/fragments/dialog'
import DisplayDatenshutz from './display_datenshutz'

const DialogLoaded = (props) => {
    return <DialogMainContent>
        <FlexGrid itemsCenter directionColumn>
            <FlexGridItem>
                <FlexGrid grow='0' justifyCenter>
                    <FlexGridItem>
                        <DisplayDatenshutz {...props} isLoaded={true} />
                    </FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        </FlexGrid>
    </DialogMainContent>
}

export default DialogLoaded
