import { useQuery } from 'application/components/hooks'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { urlRedirect } from 'application/storage'
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RedirectDashboard = (props) => {
    const { children, auth } = props
    let navigate = useNavigate()
    const query = useQuery()
    const accid = query.getStringParam(ROUTE_PARAMETERS.DASHBOARD.ACCOUNT_ID) !== '' ? query.getStringParam(ROUTE_PARAMETERS.DASHBOARD.ACCOUNT_ID) : ''
    const sbh = query.getStringParam(ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER) !== '' ? query.getStringParam(ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER) : ''
    const accountIds = auth.common.user.accountIds
    const accidInclude = accid !== '' && accountIds.includes(parseInt(accid))

    const urlHandle = urlRedirect.get()
    useEffect(() => {
        if(!accidInclude && sbh === '') {
            if (urlHandle !== '') {
                // navigate(urlHandle)
                window.location.href = urlHandle
            } else {

                navigate(ROUTES.DASHBOARD)
            }
            
            
        }
    }, [accidInclude, navigate, sbh])

    

    return <Fragment>
        {children}
    </Fragment>
}

export default RedirectDashboard
