import { DIALOG } from 'application/constants'
import { croppingAndSendPicture, putPicture } from 'application/redux/actions/files'
import { connect } from 'react-redux'

import SendPinOptionDialog from './component'

const mapStateToProps = (state, props) => ({
    dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name),
})

const mapDispatchToProps = dispatch => {
    return {
        uploadProfilePicture: (imageSrc, croppedAreaPixels, rotation, dialogName, userId, accountId, file, errorMessage, successMessage) => {
            dispatch(croppingAndSendPicture({imageSrc, croppedAreaPixels, rotation}, {dialogName, file, userId, accountId, errorMessage, successMessage}))
        },
        putProfilePicture: (file) => { dispatch(putPicture(file, {dialogName: DIALOG.NAME.UPLOAD_PROFILE_PICTURE})) },
        onFileChange: (file) => dispatch(putPicture(file))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SendPinOptionDialog)