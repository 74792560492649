import styled from 'styled-components'

export const Content = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 8px ${props => props.inDashboard ? '0px' : '24px'} 0px 24px;
    overflow: auto;
`
export const ContentRow = styled.div`
    display: flex;
    flex-direction: ${props => props.isColumn ? 'column' : 'row'};
    flex: ${props => typeof props.cssHeight !== 'undefined' ? '0 0 ' + props.cssHeight : '1 1 auto'};
    /* overflow: auto; */
`