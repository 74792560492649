import React, { useState, useRef } from 'react'
import styled, { withTheme } from 'styled-components'

import { useEscapeKey, useClickOutside } from 'application/components/hooks'
import IconButton from 'application/components/controls/icon/icon_button/component'

const DisplayContext = styled.div`
    display: flex;
    flex-direction: column;
`

const DisplayTrigger = styled.div`
    border-radius: 18px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-right: ${props => props.isLabel ? '8px' : '0px'};
    align-items: center;
    cursor: pointer;
    background: ${props => props.changeColor ? props.theme.color.color20 : 'transparent'};

    i {
        color: ${props => props.theme.color.color70};
    }

    span {
        color: ${props => props.theme.color.color70};
    }
`

const StyledContextMenu = styled.div`
    position: relative;
    z-index: ${props => props.isInDialog ? '2001' : '0'};
`

const StyledContextMenuList = styled.ul`
    position: absolute;
    top: 40px;
    display: inline;
    right: 0;
    list-style-type: none;
    z-index: 2002;
`

const ContextMenu = ({ children, isInDialog, onMenuOpen = () => { }, onMenuClose = () => { }, label = '', backgroundColorIconTrigger , iconKey }) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const closeMenu = () => {
        setMenuOpen(false)
        onMenuClose()
    }

    const handleMenu = () => {
        if (menuOpen) {
            closeMenu()
        } else {
            setMenuOpen(true)
            onMenuOpen()
        }
    }

    const handleParent = () => {
        if (menuOpen) {
            closeMenu()
        }
    }

    const ref = useRef()
    useClickOutside(ref, closeMenu)
    useEscapeKey(closeMenu)

    return <StyledContextMenu isInDialog={isInDialog} ref={ref} onClick={handleParent}>
        <DisplayContext>
            <DisplayTrigger changeColor={menuOpen} strechted={label !== ''} isLabel={label !== ''} >
                <IconButton
                    onButtonClick={handleMenu}
                    iconKey={iconKey ? iconKey : 'more_horiz'}
                    width="32px"
                    height="32px"
                    iconSize="20px"
                    backgroundColor={backgroundColorIconTrigger}
                    borderradius='18px'
                /> {
                    label !== '' && <span>
                        {label}
                    </span>
                }
            </DisplayTrigger>
            {
                menuOpen && <StyledContextMenuList isInDialog={isInDialog}>
                    {children}
                </StyledContextMenuList>
            }
        </DisplayContext>

    </StyledContextMenu>
}

export default withTheme(ContextMenu)