import { connect } from 'react-redux'
import ProviderPopupSidebarComponent from './component'

const mapStateToProps = (state) => ({
    allAktenLoaded: state.common.allAkten.isLoaded,
    allAkten: state.common.allAkten.payload,
    allAccountsLoaded: state.common.allAccounts.isLoaded,
    allAccounts: state.common.allAccounts.payload,
    selectedAccount: state.common.accountDetails.selectedAccount,
    accountDetails: state.common.accountDetails,
    auth: state.auth,
    user: state.auth.common.user
})

const mapDispatchToProps = {
    
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ProviderPopupSidebarComponent)
