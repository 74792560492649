import { combineReducers } from 'redux'
import downloadAkte from './download_akte'
import downloadMessage from './download_message'
import markFavorite from './mark_favorite'
import newFiles from './new_files'
import newMessages from './new_messages'
import newMessagesOnFolder from './new_messages_on_folder'
import readMessage from './read_message'
import sendMessage from './send_message'
import unmarkFavorite from './unmark_favorite'

export default combineReducers({
    downloadAkte,
    downloadMessage,
    markFavorite,
    newFiles,
    newMessages,
    readMessage,
    sendMessage,
    unmarkFavorite,
    newMessagesOnFolder,
})