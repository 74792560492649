import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { populateThumbnail, populateThumbnailFailed } from 'application/redux/actions/files'
import { thumbnailLoaded } from 'application/redux/selectors'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { handleErrorFile } from '../../errors'

function* fetchThumbnailByIdSaga(action) {
    try {

        const isLoaded = yield select(thumbnailLoaded, action.id)
        
        if (!isLoaded) {
            const result = yield call(api.gateway.files.getThumbnailsByAccountId, action.metaData.accountId, action.id, action.metaData.storage)
            const blobUrl = window.URL.createObjectURL(result)
            yield put(populateThumbnail(action.id, action.fileName, blobUrl))
        }

    } catch (e) {
        yield handleErrorFile(e, action)
        yield put(populateThumbnailFailed(action.id, action.fileName, null))
    }
}

export function* fetchThumbnailByIdWatcher() {
    yield takeEvery(ACTIONS.FILES_GET_THUMBNAIL_REQUEST, fetchThumbnailByIdSaga)
}