import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { DASHBOARD, ROUTES } from 'application/constants'
import React from 'react'
import { isAndroid, isTablet } from 'react-device-detect'
import { NavLink } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import ProfilePicture from './profile_picture'
const StyledNavlinkImage = styled(NavLink)`
    text-decoration: none;
    cursor: pointer;
        color: inherit;

    &:hover {
        color: inherit
        }
    &:focus {
        color: inherit
        }
    &:active {
        color: inherit
        }
`


const ProfileTopBarSection = (props) => {
    const { auth, theme } = props

    return <FlexGridItem itemsCenter grow={2} >
        <FlexGrid justifyFlexEnd>
            <FlexGridItem justifyFlexEnd grow={0.03}>
                <ProfilePicture {...props} />
            </FlexGridItem>
            {
                !isAndroid &&
                    <FlexGridItem justifyFlexEnd={isTablet} needSpaceLeft='4px' itemsCenter grow={0.1}>
                        <StyledNavlinkImage to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.ACCOUNT}>
                            {auth.common.user.firstName} {auth.common.user.lastName}
                        </StyledNavlinkImage>
                    </FlexGridItem>
            }
            {
                !isTablet &&
                    <FlexGridItem itemsCenter grow={0.1}>
                        <LanguageSwitchHoc withLabel={false} customColor={theme.color.color70} />
                    </FlexGridItem>
            }
        </FlexGrid>
    </FlexGridItem>
}

export default withTheme(ProfileTopBarSection)
