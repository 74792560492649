import styled from 'styled-components'

export const DashboardHeadline = styled.h1`
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-bottom: 34px;
`

export const DashboardDate = styled.div`
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.bold};
`

export const TotalCountWrapper = styled.div`
    display: flex;

    div {
        margin-right: 16px;
    }
`

export const IconWrapper = styled.div`
    display: flex;

    div {
        margin-right: 2px;
    }
`

export const ServiceProviderWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 20%;
`