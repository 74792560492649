
export const getAncestorsByParentFlat = (
    nodes,
    currentContainer
) => {
    const foundList = []
    const search = currentParent => {
        nodes.forEach(n => {
            if (
                !foundList.includes(n.id) &&
                n.parentId === currentParent &&
                n.id !== currentParent
            ) {
                foundList.push(n.id)
                search(n.id)
            }
        })
    }
    search(currentContainer.id)

    return nodes.filter(n => foundList.includes(n.id))
}

export const getAncestorsByParent = (
    nodes,
    currentContainerId,
) => {
    if (typeof nodes != 'undefined') {
        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].id === currentContainerId) return [nodes[i]]
            var a = getAncestorsByParent(nodes[i].descendants, currentContainerId)
            if (a != null) {
                a.unshift(nodes[i])
                return a
            }
        }
    }
    return null
}

export const getSelectedFolder = (
    nodes,
    currentContainerId,
) => {
    if (typeof nodes != 'undefined') {
        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].id === currentContainerId) return nodes[i]
            var a = getSelectedFolder(nodes[i].descendants, currentContainerId)
            if (a != null) {
                return a
            }
        }
    }
    return null
}

export const getFoldersFlatten = data => {
    return data.reduce((r, { descendants, ...rest }) => {
        r.push({ descendants, ...rest })
        if (descendants) r.push(...getFoldersFlatten(descendants))
        return r
    }, [])
}

export const getAllAncestorsForCurrentContainers = (
    nodeList,
    containerItems
) => {
    return containerItems.map(parent => {
        return getAncestorsByParent(nodeList, parent)
    })
}

export const getChildrenByParent = (
    nodes,
    parentId
) => {
    return nodes.filter(n => n.parentId === parentId)
}