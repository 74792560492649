import { ACTIONS } from 'application/constants'
import defaultStore from '../default_store'

export const FilesReducer = (state = defaultStore.files, action) => {
    switch (action.type) {
    case ACTIONS.FILES_GET_THUMBNAIL_REQUEST: {
        return {
            ...state,
            thumbnails: state.thumbnails.filter(t => t.id === action.id).length > 0
                ? state.thumbnails
                : [
                    ...state.thumbnails,
                    {
                        id: action.id,
                        isLoaded: false,
                        name: action.fileName,
                        failed: false,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.FILES_REGISTER_THUMBNAIL: {
        return {
            ...state,
            thumbnails: state.thumbnails.filter(t => t.id === action.id).length > 0
                ? state.thumbnails
                : [
                    ...state.thumbnails,
                    {
                        id: action.id,
                        isLoaded: false,
                        name: action.fileName,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.FILES_GET_FULL_FILE_REQUEST: {
        return {
            ...state,
            fullFiles: state.fullFiles.filter(t => t.id === action.id).length > 0
                ? state.fullFiles
                : [
                    ...state.fullFiles,
                    {
                        id: action.id,
                        isLoaded: false,
                        name: action.fileName,
                        failed: false,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.COMMON_GET_MAIN_LOGO_COMPANY_REQUEST: {
        return {
            ...state,
            mainLogo: state.mainLogo.filter(t => t.accountId === action.accountId).length > 0
                ? state.mainLogo
                : [
                    ...state.mainLogo,
                    {
                        accountId: action.accountId,
                        isLoaded: false,
                        has: false,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.COMMON_POPULATE_MAIN_LOGO_COMPANY: {
        return {
            ...state,
            mainLogo: state.mainLogo.map((t, i) => (t.accountId === action.accountId ? {
                ...t,
                data: action.data,
                isLoaded: true,
                has: true,
            } : t))
        }
    }
    case ACTIONS.COMMON_COMPANY_HAS_NO_MAIN_LOGO: {
        return {
            ...state,
            mainLogo: state.mainLogo.map((t, i) => (t.accountId === action.accountId ? {
                ...t,
                data: null,
                isLoaded: true,
                has: false,
            } : t))
        }
    }
    case ACTIONS.COMMON_GET_SECONDARY_LOGO_COMPANY_REQUEST: {
        return {
            ...state,
            secondaryLogo: state.secondaryLogo.filter(t => t.accountId === action.accountId).length > 0
                ? state.secondaryLogo
                : [
                    ...state.secondaryLogo,
                    {
                        accountId: action.accountId,
                        isLoaded: false,
                        has: false,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.COMMON_POPULATE_SECONDARY_LOGO_COMPANY: {
        return {
            ...state,
            secondaryLogo: state.secondaryLogo.map((t, i) => (t.accountId === action.accountId ? {
                ...t,
                data: action.data,
                isLoaded: true,
                has: true,
            } : t))
        }
    }
    case ACTIONS.COMMON_COMPANY_HAS_NO_SECONDARY_LOGO: {
        return {
            ...state,
            secondaryLogo: state.secondaryLogo.map((t, i) => (t.accountId === action.accountId ? {
                ...t,
                data: null,
                isLoaded: true,
                has: false,
            } : t))
        }
    }
    case ACTIONS.FILES_REGISTER_FULL_FILE: {
        return {
            ...state,
            fullFiles: state.fullFiles.filter(t => t.id === action.id).length > 0
                ? state.fullFiles
                : [
                    ...state.fullFiles,
                    {
                        id: action.id,
                        isLoaded: false,
                        name: action.fileName,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.FILES_POPULATE_THUMBNAIL: {
        return {
            ...state,
            thumbnails: state.thumbnails.map((t, i) => (t.id === action.id ? {
                ...t,
                data: action.data,
                isLoaded: true,
                failed: false,
            } : t))
        }
    }
    case ACTIONS.FILES_POPULATE_THUMBNAIL_FAILED: {
        return {
            ...state,
            thumbnails: state.thumbnails.map((t, i) => (t.id === action.id ? {
                ...t,
                data: action.data,
                isLoaded: true,
                failed: true,
            } : t))
        }
    }

    case ACTIONS.FILES_POPULATE_FULL_FILE: {
        return {
            ...state,
            fullFiles: state.fullFiles.map((t, i) => (t.id === action.id ? {
                ...t,
                data: action.data,
                isLoaded: true,
                failed: false,
            } : t))
        }
    }
    case ACTIONS.FILES_POPULATE_FULL_FILE_FAILED: {
        return {
            ...state,
            fullFiles: state.fullFiles.map((t, i) => (t.id === action.id ? {
                ...t,
                data: action.data,
                isLoaded: true,
                failed: true,
            } : t))
        }
    }
    case ACTIONS.FILES_DELETE_FILE: {
        return {
            ...state,
            thumbnails: state.thumbnails.filter(t => t.id !== action.fileId),
            fullFiles: state.fullFiles.filter(t => t.id !== action.fileId),
            meta: state.meta.filter(t => t.id !== action.fileId),
        }
    }
    case ACTIONS.COMMON_REGISTER_CREATOR_PROFILE_PICTURE: {
        return {
            ...state,
            profilePictureCreator: state.profilePictureCreator.filter(t => t.creatorIdHash === action.creatorIdHash).length > 0
                ? state.profilePictureCreator
                : [
                    ...state.profilePictureCreator,
                    {
                        creatorIdHash: action.creatorIdHash,
                        accountId: action.accountId,
                        akteIdHash: action.akteIdHash,
                        isLoaded: false,
                        has: false,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.COMMON_POPULATE_CREATOR_PROFILE_PICTURE: {
        return {
            ...state,
            profilePictureCreator: state.profilePictureCreator.map((t, i) => (t.creatorIdHash === action.creatorIdHash && !t.isLoaded ? {
                ...t,
                data: action.data,
                isLoaded: true,
                has: true,
            } : t))
        }
    }
    case ACTIONS.COMMON_CREATOR_PROFILE_PICTURE_HAS_NO_DATA: {
        return {
            ...state,
            profilePictureCreator: state.profilePictureCreator.map((t, i) => (t.creatorIdHash === action.creatorIdHash ? {
                ...t,
                data: null,
                isLoaded: true,
                has: false,
            } : t))
        }
    }
    default:
        return state
    }
}

export default FilesReducer