import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const TimeComponent = ({ date }) => {
    const { t } = useTranslation()
    return (
        <span>{date instanceof Date ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{t('TimeHourSuffix')}</span>
    )
}

TimeComponent.propTypes = {
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]).isRequired
}

export default TimeComponent