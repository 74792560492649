import styled from 'styled-components'

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const BodyWrapper = styled.form`
    display: flex;
    flex-direction: column;
`
export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin: 15px 0px;
`
export const SubjectInput = styled.input`
    outline: none;
    border: none;
    border-bottom: 1px solid #9F9F9F;
    padding: 10px 0px;
    
    &::placeholder{
        color:${p => p.theme.color.color30}
    }
`
export const MessageInput = styled.textarea`
    outline: none;
    border: none;
    border-bottom: 1px solid #C8C7C7;
    padding: 4px 0px 8px 0px;
    resize: none;
    height: 100px;
    &::placeholder{
        color:${p => p.theme.color.color30}
    }
`
export const SendButton = styled.button`
    color: white;
    display:flex;
    align-items: center;
    padding: 10px 15px;
    gap: 15px;
    border-radius: 5px;
    background-color: ${p => p.theme.color.color90};
    font-size: ${(p) => p.theme.fontSize.medium};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    border: none;
    transition: all;
    transition-duration: 300ms;
    cursor: pointer;
    &:disabled{
        background-color: ${p => p.theme.color.color50};
    }
    &:hover{
        background-color: #303030;
    }
`
export const ErrorLabel = styled.span`
    color: ${p => p.theme.color.red};
    font-size: ${p => p.theme.fontSize.small};
`
export const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`

export const FilesContainer = styled.div`
    width: 100%;
    height: 100%;
    /* overflow: auto; */
`

export const FilesWrapper = styled.div`
    overflow: ${(props) => (!props.overflow ? 'hidden' : 'auto')};
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    /* max-height: ${(props) => (props.maxHeight === undefined ? '100%' : props.maxHeight)}; */
`

export const RequiredIndicator = () => {
    return <span style={{ color: '#bc3640' }}>*</span>
}