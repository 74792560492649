import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form_elements/_common'
import { useTranslation } from 'react-i18next'
import PureSelect from './pure_component'

const StyledSelectInputWithLabel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 100%;
    height: ${props => props.cssHeight !== undefined ? props.cssHeight : 'auto'};
    &>div{
        height: ${props => props.cssHeight !== undefined ? props.cssHeight : 'auto'};
    }
    margin-bottom: ${props => props.cssMarginBottom !== undefined ? props.cssMarginBottom : '0'};
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.color90};

    & label.label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.small};
    }
`

export const SelectElement = ({
    label = '', 
    name, 
    register, 
    options, 
    error, 
    setValue, 
    validate, 
    hideValidationMessage = false, 
    hideValidationBox = false,
    defaultValue = null, 
    isClearable = true, 
    errorMessageOverride='', 
    menuPlacement='auto', 
    noOptionsMessage='no options',
    placeholder='start typing or select',
    isLoading=false,
    isSearchable=true,
    onChange=()=>{},
    onInputChange=()=>{},
    onBlur=()=>{},
    onFocus=()=>{},
    maxMenuHeight = 300,
    listenToDefaultValueChange = false,
    optionComponent,
    valueComponent,
    control,
    htmlId
}) => {
    const { t } = useTranslation()
    const [selectedOption, setSelectedOption] = useState(defaultValue)
    const hasLabel = label !== ''
    const handleChange = (selectedOption) => {
        setValue !== undefined && setValue(name, selectedOption !== null ? selectedOption.value : null, { shouldValidate: true })
        setSelectedOption(selectedOption)
        onChange(selectedOption)
    }

    useEffect(() => {
        if (listenToDefaultValueChange) {
            setValue(name, defaultValue !== null ? defaultValue.value : null)
        }
        if (selectedOption !== defaultValue && listenToDefaultValueChange) {
            setSelectedOption(defaultValue)
        }
    }, [defaultValue, listenToDefaultValueChange, name, selectedOption, setValue])

    return <StyledSelectInputWithLabel className="reactSelectWrapper">
        {
            hasLabel && <label className='label' htmlFor={htmlId}>{label}<RequiredFieldMarker validate={validate}/></label>
        }
        <PureSelect
            className="reactSelect"
            isClearable={isClearable}
            name={name}
            value={selectedOption}
            options={options}
            onChange={handleChange}
            onInputChange={onInputChange}
            onBlur={onBlur}
            onFocus={onFocus}
            menuPlacement={menuPlacement}
            noOptionsMessage={()=>{return t(noOptionsMessage)}}
            placeholder={t(placeholder)}
            isLoading={isLoading}
            isSearchable={isSearchable}
            maxMenuHeight={maxMenuHeight}
            optionComponent={optionComponent}
            valueComponent={valueComponent}
            id={htmlId}
            defaultValue={selectedOption}
            register={register}
            validate={validate}
            control={control}
            setValue={setValue}
        />
        {
            !hideValidationBox && <ValidationErrorMessage hideValidationMessage={hideValidationMessage} error={error} errorMessageOverride={errorMessageOverride} />
        }
    </StyledSelectInputWithLabel>
}

export default SelectElement