import { IconLink } from 'application/components/controls/icon'
import { POSITION_LABEL_ICON } from 'application/components/controls/icon/icon_link/helpers_link'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { NavigationLinkMobileWrapper } from './fragments'
import NavigationMobileSettings from './navigation_settings'
import { useTheme } from 'styled-components'

const NavInternalMobile = ({refreshClickNavMobile, logout, accountId}) => {
	let { pathname } = useLocation()
	const {t} = useTranslation()
    const theme = useTheme()
    return <NavigationLinkMobileWrapper>
        <IconLink onClick={refreshClickNavMobile} padding='10px' borderradius='50px' isActive={pathname === ROUTES.DASHBOARD} iconSize='28px' fontSize='16px' justifyContent='flex-start' iconKey={theme.dashboardMobileViewIconKey || 'home'} label={t('DashboardMobileNavigationBar')} to={ROUTES.DASHBOARD} positionLabel={POSITION_LABEL_ICON.RIGHT} />
        <FlexDividerHorizontal />
        <NavigationMobileSettings accountId={accountId} refreshClickNavMobile={refreshClickNavMobile} />
        <IconLink onClick={logout} padding='10px'  borderradius='50px' iconSize='28px' isActive={false} fontSize='16px' justifyContent='flex-start' iconKey={'logout'} label={t('logout')} positionLabel={POSITION_LABEL_ICON.RIGHT} />
    </NavigationLinkMobileWrapper>
}

export default NavInternalMobile
