import React from 'react'
import styled from 'styled-components'

const RequiredFieldMarker = styled.span`
    color: ${props => props.theme.color.red};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    font-size: inherit;
`

export const RequiredFieldMarkerLabel = ({ validate, showMarkLabel = true}) => {
    const hasNotEmptyProperty = validate !== null && typeof validate !== 'function' ? validate.hasOwnProperty('notEmpty') : false
    return hasNotEmptyProperty && showMarkLabel
        ? <RequiredFieldMarker>*</RequiredFieldMarker>
        : null
}

export default RequiredFieldMarkerLabel