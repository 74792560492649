import { ACTIONS } from 'application/constants'
import defaultStore from '../../default_store'
import { storageUserData } from 'application/storage'
import { logoutStorageDelete } from '../function'

export const CommonReducer = (state = {
    ...defaultStore.auth.common,
    user: {
        ...defaultStore.auth.common.user,
        ...storageUserData.get()
    },
}, action) => {
    switch (action.type) {
    case ACTIONS.AUTH_UPDATE_PASSWORD: {
        return {
            ...state,
            password: action.password
        }
    }
    case ACTIONS.AUTH_UPDATE_USERNAME: {
        return {
            ...state,
            username: action.username
        }
    }
    case ACTIONS.AUTH_SET_RETURN_URL: {
        return {
            ...state,
            returnUrl: action.returnUrl
        }
    }   
    case ACTIONS.AUTH_SET_PUSH_TO_DASHBOARD: {
        return {
            ...state,
            pushToDashboard: action.condition
        }
    }   
    case ACTIONS.AUTH_POPULATE_TOKEN_DATA: {
        return {
            ...state,
            accountId: action.tokenData.accountid,
            permissions: action.tokenData.permission
        }
    }
    case ACTIONS.AUTH_POPULATE_USER_DATA: {
        storageUserData.store(action.userData)
        return {
            ...state,
            user: {
                ...state.user,
                ...action.userData
            }
        }
    }
    case ACTIONS.AUTH_SHOW_LOGIN_ERROR: {
        return {
            ...state,
            errorMessage: action.errorMessage
        }
    }   
    case ACTIONS.AUTH_LOGOUT_REQUEST: {
        logoutStorageDelete()
        return {
            ...defaultStore.auth.common,
            logoutClicked: action.clicked
        }
    }
    case ACTIONS.AUTH_POPULATE_USER_PROFILE_PICTURE: {
        return {
            ...state,
            user: {
                ...state.user,
                profilePicture: state.user.profilePicture.isLoaded ? state.user.profilePicture : {
                    ...state.user.profilePicture,
                    isLoaded: true,
                    data: action.data,
                    has: true,
                }
            }
        }
    }
    case ACTIONS.AUTH_GET_USER_PROFILE_PICTURE: {
        return {
            ...state,
            user: {
                ...state.user,
                profilePicture: {
                    ...state.user.profilePicture,
                    id: action.userId
                }
            }
        }
    }
    case ACTIONS.AUTH_GET_USER_PROFILE_PICTURE_FAILED: {
        return {
            ...state,
            user: {
                ...state.user,
                profilePicture: {
                    ...state.user.profilePicture,
                    isLoaded: true,
                    has: false,
                }
            }
        }
    }
    case ACTIONS.AUTH_REFRESH_USER_PROFILE_PICTURE: {
        return {
            ...state,
            user: {
                ...state.user,
                profilePicture: {
                    ...state.user.profilePicture,
                    isLoaded: false,
                    data: '',
                    has: false,
                }
            }
        }
    }
    case ACTIONS.AUTH_HANDLE_PUSH: {
        return {
            ...state,
            HandlePush: {
                url: action.url,
                pagePush: action.status
            }
        }
    }
    default:
        return state
    }
}

export default CommonReducer