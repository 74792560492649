import { logoutRequest } from 'application/redux/actions/auth'
import { closeSelectedAccountDetails } from 'application/redux/actions/common/account_details'
import { refreshAllAccountsDAta } from 'application/redux/actions/common/all_accounts'
import { refreshAllAktenDAta } from 'application/redux/actions/common/all_akten'
import { closeAktenPopupSidebar } from 'application/redux/actions/sidebars'
import { connect } from 'react-redux'
import NavInternal from './component'

const mapDispatchToProps = dispatch => ({
    onLogout: (clicked) => {
        dispatch(logoutRequest(clicked))
    },
    refreshCommon: () => {
        dispatch(refreshAllAccountsDAta())
        dispatch(refreshAllAktenDAta())
        dispatch(closeSelectedAccountDetails())
        dispatch(closeAktenPopupSidebar())
    },
    refreshAkten: () => {
        dispatch(refreshAllAktenDAta())
    },
    refreshAccounts: () => {
        dispatch(refreshAllAccountsDAta())
    }
})

export default connect(
    state => state,
    mapDispatchToProps
)(NavInternal)