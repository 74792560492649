import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { I18N } from 'application/constants'
import { resources } from './resources'

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        detection: I18N.LANGUAGE_DETECTION_OPTIONS,
        fallbackLng: I18N.LANGUAGES,
        keySeparator: false, // we do not use keys in shape 'messages.welcome' right now
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

export default i18n
