import { api } from 'application/api'
import ACTIONS, { WIZARD_STEP } from 'application/constants'
import { populateUsername, registrationComplete, registrationFailed, updateWizardDataAllDone, updateWizardNext, usernameNotValid, wizardWriteErrorMessage } from 'application/redux/actions/wizard'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { updateWizardSagas } from './api_mappers'

function* fetchWizardDataSaga(action) {
    try {
        const sagas = yield call(updateWizardSagas, action.wizardType)
        yield all(sagas.map(saga => call(saga, action)))
        
    } catch (e) {
        yield handleError(e, action)
    }
}

function* getCheckUsernameSaga(action) {
    try {
        const response = yield call(api.gateway.auth.getCheckUsernameExist, action.username, action.userId)
        if (response.isValid) {
            yield put(populateUsername(action.username))
            yield put(updateWizardNext(2, 3, WIZARD_STEP.REGISTRATION.PASSWORD))
        } else {
            yield put(usernameNotValid())
            yield put(wizardWriteErrorMessage('Username is already exist'))
        }
    } catch (e) {
        yield handleError(e, action)
    }
}

function* postRegistrationSaga(action) {
    try {
        yield call(api.gateway.auth.postRegistration, action.username, action.password, action.passwordConfirmation, action.accountId, action.Key, action.userId, action.agbsAndDatenschutzAccepted, action.token)
        yield put(registrationComplete())
        yield put(updateWizardDataAllDone(WIZARD_STEP.REGISTRATION.DONE))
    } catch (e) {
        yield handleError(e, action)
        yield put(registrationFailed())
        yield put(wizardWriteErrorMessage(e.data.reasonMessage))
    }
}

export function* wizardWatcher() {
    yield takeEvery(ACTIONS.WIZARD_GET_DATA_REQUEST, fetchWizardDataSaga)
    yield takeEvery(ACTIONS.WIZARD_CHECK_USERNAME, getCheckUsernameSaga)
    yield takeEvery(ACTIONS.WIZARD_REGISTRATION_REQUEST, postRegistrationSaga)
}