import { FlexGrid } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import { StyledCompanyLogo } from '../fragments'
import SecondaryLogoLoader from './secondary_logo_loader'

const SecondaryLogoCompanyComponent = (props) => {
    const { secondaryLogo, secondaryLogoLoaded, hasSecondaryLogo, accountName } = props
    return <Fragment>
        <SecondaryLogoLoader {...props}>
            {
                hasSecondaryLogo && secondaryLogoLoaded ? <StyledCompanyLogo activated={true} src={secondaryLogo} alt={accountName} /> : <FlexGrid justifyCenter>
                    <StyledCompanyLogo src='assets/images/default/default_placeholder_company.png' alt={accountName} />
                </FlexGrid>
            }
        </SecondaryLogoLoader>
    </Fragment>
}

export default SecondaryLogoCompanyComponent