import { put, takeEvery, delay } from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'

function* debounceSaga(action) {
    try {
        const timeout = parseInt(process.env.REACT_APP_API_RETRY_TIMEOUT)
        yield delay(timeout)
        yield put(action.debouncedAction)
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* debounceWatcher() {
    yield takeEvery(ACTIONS.DEBOUNCE_REQUEST, debounceSaga)
}