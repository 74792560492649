import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconButtonWithLabel from '../icon_button_with_label/component'

const StyledOverridedIconButton = styled.div`
    span {
        :hover {
            background: ${props => props.hoverBackground ? props.hoverBackground : props.theme.color.black10};
            color: ${props => props.theme.color.color100};
        }

        :active {
            background: ${props => props.activeBackground ? props.activeBackground : props.theme.color.black20};
            color: ${props => props.theme.color.color100};
        }
    }   
`
const OverridedIconButton = ({ 
    hoverBackground = '',
    activeBackground = '',
    onButtonClick, 
    iconKey, 
    width, 
    height, 
    iconSize, 
    color = '', 
    buttonClassName = '',
    withShadow = false,
    borderradius = '100%',
    backgroundColor
}) => {
    return <StyledOverridedIconButton hoverBackground={hoverBackground} activeBackground={activeBackground}>
        <IconButtonWithLabel backgroundColor={backgroundColor} borderradius={borderradius} withShadow={withShadow} onButtonClick={onButtonClick} iconKey={iconKey} width={width} height={height} iconSize={iconSize} color={color} className={buttonClassName} />
    </StyledOverridedIconButton>
}

OverridedIconButton.propTypes = {
    hoverBackground: PropTypes.string,
    activeBackground: PropTypes.string,
    onButtonClick: PropTypes.func,
    iconKey: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    iconSize: PropTypes.string,
    color: PropTypes.string,
    buttonClassName: PropTypes.string
}


export default OverridedIconButton