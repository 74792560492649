import React from 'react'
import DashboardSidebarComponent from './component'

const DashboardSidebarEffects = (props) => {
    //import props
    //useEffect
    //condition
    //preloader
    return <DashboardSidebarComponent {...props} />
}

export default DashboardSidebarEffects