import ACTIONS from 'application/constants'

export const sendPinRequest = (hashAuthKey, methode, errorMessage, successMessage) => ({
    type: ACTIONS.AUTH_SEND_PIN_REQUEST,
    hashAuthKey,
    methode,
    errorMessage,
    successMessage
})

export const sendPinSucceed = () => ({
    type: ACTIONS.AUTH_SEND_PIN_SUCCEED,
})

export const sendPinFailed = () => ({
    type: ACTIONS.AUTH_SEND_PIN_FAILED,
})