import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { ROUTES } from 'application/constants'
import ActionButtonNavlink from 'application/components/controls/button/action_button/navlink_button'
import CommonContentServiceProviderEasyMode from './common_content'

export const StyledCardAccountsEasyMode = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;
    padding: 20px;
    border-radius: 23px;
    width: 100%;
    text-decoration: none;
    background-color: ${p => p.theme.color.color0};
    margin: 5px;
    min-width: 300px;
    min-height: 300px;
`

const ServiceProviderCardDefaultEasyMode = ({ categories, theme, address, website, uniqueAccountKey, mainColor, accountId, accountName, refreshPublicInfoAccount }) => {
    return <StyledCardAccountsEasyMode>
		<CommonContentServiceProviderEasyMode 
			mainColor={mainColor}
			accountId={accountId}
			accountName={accountName}
			address={address}
			categories={categories}
			uniqueAccountKey={uniqueAccountKey}
			website={website}
		/>
        <ActionButtonNavlink 
            linkTo={ROUTES.PAGE_BASE + uniqueAccountKey}
            borderradius='80px'
            backgroundColor={mainColor}
            color={theme.color.color0}
            buttonText={'Page'}
            onButtonClick={refreshPublicInfoAccount}
        />
    </StyledCardAccountsEasyMode>
}

ServiceProviderCardDefaultEasyMode.propTypes = {
    categories: PropTypes.array,
    address: PropTypes.string,
    website: PropTypes.string,
    uniqueAccountKey: PropTypes.string,
    accountId: PropTypes.number,
    accountName: PropTypes.string,
    theme: PropTypes.object,
    refreshPublicInfoAccount: PropTypes.func,
}

export default withTheme(ServiceProviderCardDefaultEasyMode)
