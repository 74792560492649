import { call, put } from '@redux-saga/core/effects'
import { api } from 'application/api'
import { formAuthenticated, formLoginAttempt, populateFormMetaData } from 'application/redux/actions/form'
import { accessTokenForm, pinForm } from 'application/storage/access_token'

export function* postAuthForm(action) {
    const pinToken = pinForm.get() !== '' && action.loginAttempt === 0 ? pinForm.get() : action.pin
    action.loginAttempt !== 0 && pinForm.store(action.pin)
    const payload = {
        key: action.key,
        password: pinToken,
        attempt: action.loginAttempt
    }

    const response = yield call(api.gateway.formAssistant.postKeyAssistant, payload)

    // if (e.response.status === 400) {
    //     yield put(HandlePush('/', true))
    // }

    accessTokenForm.store(response.accessToken)
    yield put(formLoginAttempt(action.loginAttempt + 1))
    yield put(formAuthenticated())
    yield put(populateFormMetaData({ core: response }))

    return response
}