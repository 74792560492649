import React, { useEffect, useState } from 'react'
import { TreeComponentStyled } from './fragments'
import TreeContainer from './tree_container'

const TreeComponent = (props) => {
    const {
        size,
        nodes,
        onSelect,
        grow,
        iconSet,
        noIcons,
        rootId,
        // parentId,
        containerStyle,
        folderId,
        selectedFolder,
        breadcrumbsData,
        initiallyToggle
    } = props

    // STATE
    const [selected, setSelected] = useState(selectedFolder)

    useEffect(() => {
        selectedFolder && setSelected(selectedFolder)
    }, [selectedFolder])
    // select the node and call onSelect callback
    const selectNode = (e, selectedNode) => {
        setSelected(selectedNode)
        onSelect(e, selectedNode)
    }

    return <TreeComponentStyled
        grow={grow}
        size={size}
        style={{...containerStyle}}
    >
        {
            nodes.length > 0 && <TreeContainer 
                selected={selected}
                onSelect={selectNode}
                parent={rootId}
                nodes={nodes}
                noIcons={noIcons}
                iconSet={iconSet}
                rootId={rootId}
                folderId={folderId}
                breadcrumbsData={breadcrumbsData}
                initiallyToggle={initiallyToggle}
            />
        }
    </TreeComponentStyled>
}

TreeComponent.defaultProps = {
    nodes: [],
    size: 'full',
    isLoading: false,
    onSelect: () => { },
    theme: 'dark',
    grow: false,
    showEmptyItems: false,
    iconSet: null,
    noIcons: false,
    containerStyle: undefined,
    folderId: ''
}

export default TreeComponent