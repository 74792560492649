export const favoriteHelper = (
    isFavorite,
    accountId,
    unmarkMessageAsFavorite,
    markMessageFavoriteId,
    messageId,
    messageKey,
    params,
    theme,
    markMessageAsFavorite,
    setFavorite,
    messageDetail,
    setPayloadFavoriteMessages,
    deletePayloadFavoriteMessages
) => {
    if (isFavorite) {
        return {
            favoriteColor: '#F4D03F',
            favoriteIcon: 'star',
            favoriteId: markMessageFavoriteId,
            handleFavorite: () => {
                unmarkMessageAsFavorite(accountId, messageId, params, messageKey)
                setFavorite(false)
                deletePayloadFavoriteMessages(messageId)
            },
        }
    } else {
        return {
            favoriteColor: theme.color.color90,
            favoriteIcon: 'star_border',
            favoriteId: markMessageFavoriteId,
            handleFavorite: () => {
                markMessageAsFavorite(accountId, messageId, params, messageKey)
                setFavorite(true)
                setPayloadFavoriteMessages({ ...messageDetail, messageKey })
            },
        }
    }
}
