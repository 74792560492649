import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TagCategory from 'application/components/controls/tag_category'
import { MainLogoCompany } from '../../company_logo'
import { IconLabel } from 'application/components/controls/icon'
import { Spacer } from 'application/components/pages/internal/dashboard/fragments'

const StyledLogo = styled.div`
    width: 200px;
    height: 200px;
`

const StyledAccountName = styled.h3`
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.color.color100};
    margin-bottom: 4px;
 `
const Filler = styled.div`
    flex-grow: 2;
    justify-content: flex-end;
    align-items: flex-end;
`
const StyledCategoriesAccountsEasyMode = styled.div`
    display: flex;
`
const CommonContentServiceProviderEasyMode = ({accountId, accountName, address, website, categories, mainColor}) => {
	return <Fragment>
		<StyledLogo>
            <MainLogoCompany accountId={accountId} accountName={accountName} />
        </StyledLogo>
        <StyledAccountName>
            {accountName}
        </StyledAccountName>
        <Spacer />
        {
            address !== '' && <IconLabel textAlign='left' alignItems='flex-start' justifyContent='flex-start' icon='location_on' label={address} />
        }
        {
            website !== null && <IconLabel textAlign='left' alignItems='flex-start' justifyContent='flex-start' linkHref={website} icon='launch' label={website} />
        }
        <StyledCategoriesAccountsEasyMode>
            {
                categories.length > 1 && categories.slice(0, 2).map((c, i) => (
                    <TagCategory key={i} truncate text={c} backgroundcolor={mainColor} borderradius='50px' />
                ))
            }
        </StyledCategoriesAccountsEasyMode>
        <Filler />
	</Fragment>
}

CommonContentServiceProviderEasyMode.propTypes = {
	categories: PropTypes.array,
    address: PropTypes.string,
    website: PropTypes.string,
    uniqueAccountKey: PropTypes.string,
	accountId: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
    accountName: PropTypes.string,
}

export default CommonContentServiceProviderEasyMode
