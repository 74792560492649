import { ACTIONS } from 'application/constants'
import { accessTokenUnregistered } from 'application/storage'
import default_store from '../../default_store'

const unregisteredReducer = (state = {
    ...default_store.auth.unregistered,
    tokenSet: accessTokenUnregistered.get() !== '',
    loggedIn: accessTokenUnregistered.get() !== ''
}, action) => {
    switch (action.type) {
      case ACTIONS.AUTH_LOGIN_REQUEST_PIN: {
        return {
          ...state,
          loginRequest: true,
          loggedIn: false,
          loginFailed: false,
        }
      }
      case ACTIONS.AUTH_ADHOC_ACCESS: {
        return {
          ...state,
          loginRequest: true,
          loggedIn: false,
          loginFailed: false,
        }
      }
      case ACTIONS.AUTH_WRITE_ACCESS_TOKEN_UNREGISTERED: {
        accessTokenUnregistered.store(action.accessToken)
        return {
          ...state,
          tokenSet: true,
          accessToken: action.accessToken,
        }
      }
      case ACTIONS.AUTH_LOGIN_SUCCESS_PIN: {
        return {
          ...state,
          loggedIn: true,
          loginRequest: false,
          loginFailed: false,
        }
      }
      case ACTIONS.AUTH_LOGIN_FAILED_PIN: {
        return {
          ...state,
          loggedIn: false,
          loginRequest: false,
          loginFailed: true,
        }
      }
      default:
        return state
    }
}

export default unregisteredReducer