import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragment from '../auth/fragments'

import { useTheme } from 'styled-components'

const TitlePin = props => {
    const { t } = useTranslation()
    const theme = useTheme()

    const {
        title = t('GainAccessTitle'),
        subTitle = t('GainAccessSubTitle'),
        accountId,
    } = props
    return <Fragment.TitleLoginWrapper>
        <Fragment.TitleLogin
            style={{
                    color: theme.color.titlePin || theme.color.primary,
                }}
        >
            {title}
        </Fragment.TitleLogin>
        <Fragment.SubTitleLogin>
            {subTitle}
        </Fragment.SubTitleLogin>
    </Fragment.TitleLoginWrapper>
}

export default TitlePin
