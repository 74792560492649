import { LayoutBase } from '../pages/_layout'
import { FlexGrid, FlexGridItem, PushBottom70 } from './grid'
import React from 'react'
import { Headline1 } from './typography'
import Preloader3D from '../controls/preloader/3d'
import styled from 'styled-components'

const WaitingScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 40px;
    align-items: center;
    z-index: ${props => '10' + props.dialogIndex.toString() + '2'};
    overflow: auto;
`

export const BasicLoader = () => <LayoutBase>
    <FlexGrid justifyCenter itemsCenter directionColumn width='100%'>
        <FlexGridItem justifyCenter itemsCenter grow={0}>
            <Preloader3D />
        </FlexGridItem>
        <PushBottom70 />
        <Headline1>
            Loading...
        </Headline1>
    </FlexGrid>
</LayoutBase>

export const LoaderWaitingScreen = () => {
    return <WaitingScreen dialogIndex={2}>
        <FlexGrid justifyCenter itemsCenter directionColumn width='100%'>
            <FlexGridItem justifyCenter itemsCenter grow={0}>
                <Preloader3D />
            </FlexGridItem>
            <PushBottom70 />
            <Headline1>
                Loading...
            </Headline1>
        </FlexGrid>
    </WaitingScreen>
} 