import React from 'react'
import ServiceProviderCard from 'application/components/building_blocks/service_provider_card'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { filterAkteOnAccount, setSelectedAccountDetailsMapper } from '../../dashboard/function'
import { isTablet } from 'react-device-detect'

const ProviderSection = ({
    allAccounts = [],
    selectedAccount,
    allAkten,
    setSelectedAccountDetails,
    accountDetails,
    handleOpenProviderPopupSidebar
}) => {
    const handleCardClick = (ac) => {
        setSelectedAccountDetails(setSelectedAccountDetailsMapper(ac, filterAkteOnAccount(ac.id, allAkten)))
        isTablet && handleOpenProviderPopupSidebar()
    }
    return <FlexGridItem>
        <FlexGrid>
            {
                allAccounts.map((ac, i) => {
                    return <ServiceProviderCard
                        key={i}
                        accountDetail={ac}
                        isActive={selectedAccount.id !== undefined && selectedAccount.id === ac.id}
                        onCardClick={() => handleCardClick(ac)}
                        accountMetaData={accountDetails}
                    />
                })
            }
        </FlexGrid>
    </FlexGridItem>
}

export default ProviderSection