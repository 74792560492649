import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import ContentNotFound from 'application/components/pages/external/not_found/content_not_found'
import { ROUTES } from 'application/constants'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { NavLink } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`

export function NotFoundBigFoot(){
    const theme = useTheme()

    useEffect(() => {
        document.title = theme.title
        document.querySelector('link[rel~=icon]').href = theme.favicon
    }, [theme])

    return  <FlexGrid directionColumn style={{ 
        minHeight: '100vh',
        color: 'black'
     }}>
        <FlexGridItem justifyCenter style={{ 
            marginTop: '20px',
         }}>
            <StyledNavLink to={ROUTES.BASE}>
                <ImagePlaceHolder large='75px' withBorder={false} source='/assets/images/myk-law.png' />
            </StyledNavLink>
        </FlexGridItem>
        <FlexGridItem justifyCenter >
            <ContentNotFound 
                withDesc 
                errorCodeText='Seite wurde nicht gefunden.' 
                enablePill={false} 
                textAlignHeadline='center'
                maxWidth={isMobile ? '80%' : '50%'}
            />
        </FlexGridItem>
    </FlexGrid>
}