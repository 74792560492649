import styled from 'styled-components'

const StyledLabel = styled.label`
    margin-bottom: 4px;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.color90};
`

export { StyledLabel }

