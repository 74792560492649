import { useEffect, Fragment, useContext } from 'react'
import { getSpecialAccount, isSpecialAccount } from './special_accounts'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { useTheme } from 'styled-components'

const WebHeaderLogoEffect = (props) => {
    const { children, accountPublicInfo, page } = props
    const { activePlugin, config, getAsset } = usePluginsContext()
    const theme = useTheme()
    const customTitle = config.customHeaderTitles && config.customHeaderTitles[page]
    

    useEffect(() => {
        if(!activePlugin) return
        let link = document.querySelector('link[rel~="icon"]')
        let title = document.querySelector('title')
        if (!link && !title) {
            link = document.createElement('link')
            link.rel = 'icon'
            title = document.createElement('title')
            document.getElementsByTagName('head')[0].appendChild(title)
            document.getElementsByTagName('head')[0].appendChild(link)
        }
        if (customTitle) {
            link.href = customTitle.toLowerCase().includes('wourage')
                ? '/assets/images/wourage/favicon.png'
                : `/assets/images/${accountPublicInfo?.accountUniqueKey
                    ?? accountPublicInfo?.uniqueAccountKey
                    ?? getSpecialAccount(accountPublicInfo?.accountId)?.uniqueAccountKey}/favicon.png`
        } else {
            link.href = getAsset('favicon.png')
        }
        // title.text = customTitle ? customTitle : accountPublicInfo?.accountName ?? accountPublicInfo?.name
        title.text = customTitle ? customTitle : theme.title
    }, [accountPublicInfo, customTitle])

    return <Fragment>
        {children}
    </Fragment>
}

export default WebHeaderLogoEffect
