import React from 'react'
import { getSidebarAktenDetails } from './mappers'

const AktenSidebarComponent = (props) => {
    const {
        sidebarKey,
        messageFullLoaded
    } = props

    const SidebarComponent = getSidebarAktenDetails(sidebarKey)

    return <React.Fragment>
        {messageFullLoaded && <SidebarComponent {...props} />}
    </React.Fragment> 
} 

export default AktenSidebarComponent