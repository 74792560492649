import React from 'react'
import * as Fragment from '../fragments/dashboard'
import TopBar from 'application/components/building_blocks/top_bar'
import PropTypes from 'prop-types'

const HeaderInternal = ({ inAkten, accountPublicInfo }) => {
    return <Fragment.HeaderWrapper inAkten={inAkten}>
        <TopBar inAkten={inAkten} accountPublicInfo={accountPublicInfo} />
    </Fragment.HeaderWrapper>
}

HeaderInternal.propTypes = {
    inAkten: PropTypes.bool
}

export default HeaderInternal
