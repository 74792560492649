import React from 'react'
import styled from 'styled-components'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form_elements/_common'

const StyledTextAreaWrapper = styled.div`
    display: flex;
    margin-bottom:0px;
    width: ${props => props.cssWidth};
    height: 100%;
    & label.label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.small};
    }
`

const StyledTextArea = styled.textarea`
    font-family: ${props => props.theme.fontFamily};
        font-size: ${props => props.fontSize};
    font-weight: ${props => props.theme.fontWeight.medium};
    color: ${props => props.theme.color.color90};
    box-shadow: none;
    /* margin-top: 4px;
    margin-bottom: -6px; */
    box-sizing: border-box;
    padding: 9px;
    width: inherit;
    min-height: ${props => props.cssMinHeight};
    max-height: ${props => props.cssMaxHeight};
    resize: none;
    border: 1.5px solid ${props => props.disabled ? props.theme.color.color10 : props.cssValidationError ? props.theme.color.red : props.isSuccess ? props.theme.color.success : props.borderColor ? props.borderColor : props.theme.color.color15};
    border-bottom: 0px;
	border-bottom-style: solid;
	border-bottom-color: ${props => props.extendedFeature ? 'transparent' : props.borderColor ? props.borderColor : props.theme.color.color15};
    border-top-left-radius: ${p => p.radius ? p.radius : '15px'};
    border-top-right-radius: ${p => p.radius ? p.radius : '15px'};
    border-bottom-right-radius: ${props => props.extendedFeature ? '0px' : props.radius ? props.radius : '15px'};
    border-bottom-left-radius: ${props => props.extendedFeature ? '0px' : props.radius ? props.radius : '15px'};

	@media (min-width: ${props => props.theme.breakpoint.sm}){
   	 border-bottom: 1.5px solid ${props => props.extendedFeature ? 'transparent' : props.borderColor ? props.borderColor : props.theme.color.color15};
	}

     ::placeholder { 
        color: ${props => props.theme.color.color80};
        opacity: 1; /* Firefox */

        font-size: ${props => props.fontSize};
        font-weight: ${props => props.theme.fontWeight.medium};
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props.theme.color.color80};

        font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.medium};
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props.theme.color.color80};

        font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.medium};
      }

    /* :hover {
        border: 1px solid ${props => props.disabled ? props.theme.color.color15 : props.cssValidationError ? props.theme.color.red : props.theme.color.lightPowderGray};
    } */

    /* :active {
        border: 1px solid ${props => props.disabled ? props.theme.color.color15 : props => props.theme.color.color90};
    } */

    :disabled {
        background-color: ${props => props.theme.color.color5};
        color: ${props => props.theme.color.color80};
        border: 1px solid ${props => props.theme.color.color15};
    }

    :focus{
        outline: none;
    }
    
    
`

export const TextArea = ({
    label,
    name,
    placeholder = '',
    disabled = false,
    minHeight = '120px',
    maxHeight = '120px',
    register,
    validate = {},
    error,
    hideValidationMessage = false,
    defaultValue = '',
    errorMessageOverride = '',
    onChange = () => { },
    hideValidationBox = false,
    showMarkLabel = true,
    extendedFeature = false,
    borderColor,
    radius,
    fontSize = '18px',
    marginBottom,
    onKeyPress,
    htmlId,
}) => {
    return <StyledTextAreaWrapper extendedFeature={extendedFeature} cssWidth='100%'>
        {
            label !== '' && <label className='label' htmlFor={htmlId}>{label}<RequiredFieldMarker showMarkLabel={showMarkLabel} validate={validate} /></label>
        }
        <StyledTextArea
            fontSize={fontSize}
            radius={radius}
            id={htmlId}
            placeholder={placeholder}
            cssMinHeight={minHeight}
            cssMaxHeight={maxHeight}
            disabled={disabled}
            onChange={onChange}
            extendedFeature={extendedFeature}
            borderColor={borderColor}
            marginBottom={marginBottom}
            onKeyPress={onKeyPress}
            name={name}
            defaultValue={defaultValue}
            {...register(name, {
                validate: validate
            })}
        />
        {
            !hideValidationBox && <ValidationErrorMessage hideValidationMessage={hideValidationMessage} error={error} errorMessageOverride={errorMessageOverride} />
        }
    </StyledTextAreaWrapper>
}

export default TextArea