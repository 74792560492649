import { SecondaryLogoCompany } from 'application/components/building_blocks/company_logo'
import { FlexGridItem } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import { StyledAccountNameServiceProviderDetail } from '../fragments'

const HeaderServiceProviderDetail = (props) => {
    const { selectedAccount } = props
    
    return <Fragment>
        <SecondaryLogoCompany
            accountId={selectedAccount.id}
            accountName={selectedAccount.name}
            {...props}
        />
        <FlexGridItem justifyCenter>
            <StyledAccountNameServiceProviderDetail mainColor={selectedAccount.mainColor}>
                {selectedAccount.name}
            </StyledAccountNameServiceProviderDetail>
        </FlexGridItem>
    </Fragment>
}

export default HeaderServiceProviderDetail
