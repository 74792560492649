import { refreshAllAktenDAta } from 'application/redux/actions/common/all_akten'
import { populateBreadscrumbsData, refreshMessageOnRoot, setBreadscurmbsView, setFoldersView, setSelectedAkte, setSelectedFolder } from 'application/redux/actions/pages/akten'
import { connect } from 'react-redux'
import { getCommonAllAktenRequest } from 'application/redux/actions/common/all_akten'
import MessagesSidebarComponent from './component'

const mapStateToProps = state => ({
    allAkten: state.common.allAkten.payload,
    allAktenLoaded: state.common.allAkten.isLoaded,
    loading: state.common.allAkten.isLoading,
    allAccounts: state.common.allAccounts.payload,
    allAccountsLoaded: state.common.allAccounts.isLoaded,
    auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
    setSelectedAkte: payload => {
        dispatch(setSelectedAkte(payload))
        dispatch(refreshMessageOnRoot())
        dispatch(refreshAllAktenDAta())
        dispatch(setSelectedFolder(null))
        dispatch(setBreadscurmbsView(false))
        dispatch(setFoldersView(true))
        dispatch(populateBreadscrumbsData([]))
    },
    getAllAkten: () => {
        dispatch(getCommonAllAktenRequest())
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MessagesSidebarComponent)