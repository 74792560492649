import styled from 'styled-components'


export const ErrorBox = styled.div`
    height: fit-content !important;
`

export const ErrorLabel = styled.span`
    font-size: 10px;
    margin-top: 4px;
    font-weight: 700;
    color: ${props => props.theme.color.red};
	text-align: center;
`