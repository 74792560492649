import { connect } from 'react-redux'
import ServiceProviderEffects from './effects'
import { getCommonAllAccountsRequest } from 'application/redux/actions/common/all_accounts'
import { getCommonAllAktenRequest } from 'application/redux/actions/common/all_akten'
import { setSelectedAccountDetails } from 'application/redux/actions/common/account_details'

const mapStateToProps = state => ({
    allAccountsLoaded: state.common.allAccounts.isLoaded,
    allAccounts: state.common.allAccounts.payload,
    selectedAccount: state.common.accountDetails.selectedAccount,
    allAktenLoaded: state.common.allAkten.isLoaded,
    allAkten: state.common.allAkten.payload,
})

const mapDispatchToProps = dispatch => ({
    getAllAktenRequest: () => dispatch(getCommonAllAktenRequest()),
    getAllAccountsRequest: () => dispatch(getCommonAllAccountsRequest()),
    setSelectedAccountDetails: (details) => dispatch(setSelectedAccountDetails(details)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceProviderEffects)