import { refreshMessageOnFolder, refreshMessageOnRoot } from 'application/redux/actions/pages/akten'
import { connect } from 'react-redux'
import AktenComponent from './component'
import { populateBreadscrumbsData, setBreadscurmbsView, setFoldersView, setSelectedAkte, setSelectedFolder } from 'application/redux/actions/pages/akten/common'
import { getCommonAllAktenRequest, updateAkten } from 'application/redux/actions/common/all_akten'

const mapStateToProps = (state, props) => {
    return {
        allAktenLoaded: state.common.allAkten.isLoaded,
        allAktenLoading: state.common.allAkten.isLoading,
        allAktenFailed: state.common.allAkten.isFailed,
        allAkten: state.common.allAkten.payload,
        auth: state.auth,
        selectedAkte: state.pages.akten.common.selectedAkte,
        messageDetailsRequest: state.pages.akten.details.isRequest,
        messageDetailsLoaded: state.pages.akten.details.isLoaded,
        folderDataRequest: state.pages.akten.details.onFolderIsRequest,
        folderDataFailed: state.pages.akten.details.onFolderIsFailed,
        allAccountsLoaded: state.common.allAccounts.isLoaded,
        allAccountsLoading: state.common.allAccounts.isLoading,
        allAccounts: state.common.allAccounts.payload,
        sidebarVisible: state.pages.akten.sidebar.visible,
        folderDataLoaded: state.pages.akten.action.newMessagesOnFolder.succeed,
    }
}

const mapDispatchToProps = dispatch => ({
    refreshMessagesDetails: () => {
        dispatch(refreshMessageOnRoot())
        dispatch(refreshMessageOnFolder())
    },
    setSelectedFolder: (selectedFolder, breadcrumbsData) => {
        dispatch(setBreadscurmbsView(true))
        dispatch(setFoldersView(true))
        dispatch(setSelectedFolder(selectedFolder))
        dispatch(populateBreadscrumbsData(breadcrumbsData))
    },
    getAllAkten: () => dispatch(getCommonAllAktenRequest()),
    updateAkten: (params, folderId, messageKey) => dispatch(updateAkten(params, folderId, messageKey)),
    setSelectedAkte: (payload) => dispatch(setSelectedAkte(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AktenComponent)