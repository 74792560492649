import { ROUTES } from 'application/constants'
import React from 'react'
import { Navigate } from 'react-router-dom'

const LoginRedirect = (props) => {
    const { auth } = props


    if (auth.registered.loggedIn || auth.unregistered.loggedIn) {
        const returnUrl = auth.common.returnUrl === '' ? ROUTES.DASHBOARD : auth.common.returnUrl
        return <Navigate to={returnUrl} />
    }


    return <React.Fragment>
        {props.children}
    </React.Fragment>
}

export default LoginRedirect