const specialAccounts = [
  {
    accountId: 757508,
    uniqueAccountKey: 'myk-law',
    mainColor: '#2C4490',
    secondaryColor: '#F5BFB8',
    link: 'https://www.kanzlei-myk.de/',
  },
  {
    accountId: 725079,
    uniqueAccountKey: 'demo-kanzlei',
    mainColor: '#2C4490',
    secondaryColor: '#F5BFB8',
    link: 'https://www.kanzlei-myk.de/',
  },
]

export const getSpecialAccount = (key) =>
  specialAccounts.find(
    (acc) => acc.accountId == key || acc.uniqueAccountKey == key
  )

export const isSpecialAccount = (key) =>
  specialAccounts.filter(
    (acc) => acc.accountId == key || acc.uniqueAccountKey == key
  ).length > 0
