import React, { useEffect } from 'react'

const SelectedBreadcrumbEffect = (props) => {
    const { allAccountsLoaded, accountId, akteIdHash, folderId, messageFullLoaded, breadcrumbsData, breadcrumbDataToSelect, selectedFolder, setBreadcumData, children, loggedIn } =
        props
    useEffect(() => {
        allAccountsLoaded &&
            loggedIn &&
            messageFullLoaded &&
            accountId !== undefined &&
            akteIdHash !== undefined &&
            folderId !== '' &&
            breadcrumbDataToSelect?.length > 0 &&
            breadcrumbsData?.length === 0 &&
            setBreadcumData(true, breadcrumbDataToSelect)
    }, [accountId, akteIdHash, allAccountsLoaded, breadcrumbDataToSelect, breadcrumbsData.length, folderId, loggedIn, messageFullLoaded, selectedFolder, setBreadcumData])

    return <React.Fragment>{children}</React.Fragment>
}

export default SelectedBreadcrumbEffect
