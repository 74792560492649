import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { POSITION_LABEL_ICON } from './helpers_link'

export const StyledIconLink = styled(NavLink)`
    text-decoration: none;
    list-style: none;
    justify-content: ${p => p.justifyContent ? p.justifyContent : 'center'};
    align-items: center;
    display: flex;
	padding: 8px;
    width: ${props => props.width !== undefined ? props.width : '60px'};
    height: ${props => props.width !== undefined ? props.height ? props.height : props.width : '60px'};
    border-radius: ${props => props.borderradius ? props.borderradius : '23px'};
    background-color: ${props => props.selected ? props.positionLabel === POSITION_LABEL_ICON.LEFT || props.positionLabel === POSITION_LABEL_ICON.RIGHT ? props.theme.color.color15 :
        props.theme.color.color90 : props.backgroundcolor ? props.backgroundcolor : 'transparent'};
    color: ${props => props.selected ? props.positionLabel === POSITION_LABEL_ICON.LEFT || props.positionLabel === POSITION_LABEL_ICON.RIGHT ? props.theme.color.color100 :
        props.theme.color.color0 : props.color ? props.color : props.theme.color.color90};
    padding: ${props => props.padding ? props.padding : 'unset'};

    & i {
        align-self: center;
        font-size: ${props => props.iconSize ? props.iconSize : '24px'};
        font-weight: ${props => props.active ? 'bold' : ''};

    }

    :hover {
        color: ${props => props.selected ? props.theme.color.color0 : props.color ? props.color : props.theme.color.color100};
		background: ${props => props.selected ? props.theme.color.color90 : props.hoverBackground ? props.hoverBackground : props.theme.color.black10};
   		 border-radius: ${props => props.borderradius ? props.borderradius : '23px'};
    }

    :active {
        color: ${props => props.selected ? props.theme.color.color0 : props.color ? props.color : props.theme.color.color100};
		background: ${props => props.selected ? props.theme.color.color90 : props.activeBackground ? props.activeBackground : props.theme.color.black10};
   		 border-radius: ${props => props.borderradius ? props.borderradius : '23px'};
    }
`
export const StyledIconLinkDiv = styled.div`
    text-decoration: none;
	cursor: pointer;
    list-style: none;
    justify-content: ${p => p.justifyContent ? p.justifyContent : 'center'};
    align-items: center;
    display: flex;
	padding: 8px;
    width: ${props => props.width !== undefined ? props.width : '60px'};
    height: ${props => props.width !== undefined ? props.height ? props.height : props.width : '60px'};
    border-radius: ${props => props.borderradius ? props.borderradius : '23px'};
    background-color: ${props => props.selected ? props.positionLabel === POSITION_LABEL_ICON.LEFT || props.positionLabel === POSITION_LABEL_ICON.RIGHT ? props.theme.color.color15 :
        props.theme.color.color90 : props.backgroundcolor ? props.backgroundcolor : 'transparent'};
    color: ${props => props.selected ? props.positionLabel === POSITION_LABEL_ICON.LEFT || props.positionLabel === POSITION_LABEL_ICON.RIGHT ? props.theme.color.color100 :
        props.theme.color.color0 : props.color ? props.color : props.theme.color.color90};
    padding: ${props => props.padding ? props.padding : 'unset'};

    & i {
        align-self: center;
        font-size: ${props => props.iconSize ? props.iconSize : '24px'};
        font-weight: ${props => props.active ? 'bold' : ''};

    }

    :hover {
        color: ${props => props.selected ? props.theme.color.color0 : props.color ? props.color : props.theme.color.color100};
		background: ${props => props.noHover ? props.backgroundcolor : props.selected ? props.theme.color.color90 : props.hoverBackground ? props.hoverBackground : props.theme.color.black10};
   		 border-radius: ${props => props.borderradius ? props.borderradius : '23px'};
    }

    :active {
        color: ${props => props.selected ? props.theme.color.color0 : props.color ? props.color : props.theme.color.color100};
		background: ${props => props.selected ? props.theme.color.color90 : props.activeBackground ? props.activeBackground : props.theme.color.black10};
   		 border-radius: ${props => props.borderradius ? props.borderradius : '23px'};
    }
`

export const StyledLabel = styled.span`
    font-family: ${props => props.theme.fontFamily.secondary};
    font-size: ${props => props.fontSize ? props.fontSize : props.theme.fontSize.small};
    margin-top: 4px;
    text-transform: ${p => p.textTransformLabel};
    font-weight: ${p => p.theme.fontWeight.bold};
    margin-right: ${p => p.positionLabel ? p.positionLabel === POSITION_LABEL_ICON.LEFT ? '8px' : 'unset' : 'unset'};
    margin-left: ${p => p.positionLabel ? p.positionLabel === POSITION_LABEL_ICON.RIGHT ? '8px' : 'unset' : 'unset'};
`