import { fetchHandler, fetchRequestBuilder, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'
import { accessTokenForm } from 'application/storage/access_token'

export const getAccessedProcess = (key, CustomerId) => {

        const request = fetchRequestBuilder(`e.consult.${CustomerId}/assistants/process?workflowKey=`+key, HTTP_METHOD.GET, {}, true, false, false, accessTokenForm)

        return limiter.schedule(() => fetchHandler(request.url, request.data))
            .then(handleApiResponse)
    
}