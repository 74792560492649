import React from 'react'
import DateComponent from 'application/components/controls/date'
import Time from 'application/components/controls/time'
import * as Fragment from './fragments'
import { FlexGrid, FlexGridItem, PushBottom10 } from 'application/components/fragments/grid'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import TagCategory from 'application/components/controls/tag_category'
import { scroller } from 'react-scroll'
import { textHelpers } from 'application/common'
import { withTheme } from 'styled-components'
import { Anchorme } from 'react-anchorme'
import { useTranslation } from 'react-i18next'
import IconLabel from 'application/components/controls/icon/icon_label'
import IconLink from 'application/components/controls/icon/icon_link'
import { AKTEN } from 'application/constants'
import { isMobileOnly } from 'react-device-detect'

const ChatBubbleFavoriteComponent = (props) => {
    const { messageDetail, downloadMessage, filesToShow, mainColor, messageId, accountId, theme, downloadMessageRequest, downloadMessageId, setView } = props
    const { id, message, header, creatorName, creationDate, isOwnMessage, labels } = messageDetail

    const dateOnMessage = creationDate
    const truncateMessage = textHelpers.truncateEndText(message, 200)
    const handleScrollOnDetails = () => {
        isMobileOnly && setView({ view: AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.MESSAGES, payload: { favoriteId: id } })
        !isMobileOnly &&
            scroller.scrollTo(id, {
                smooth: true,
                containerId: AKTEN.CONTAINER_ID.MESSAGES_WRAPPER,
                offset: 0,
            })
    }

    const { t } = useTranslation()

    return (
        <Fragment.ChatBubbleWrapper onClick={handleScrollOnDetails} onBubbleClick={true} isOwnMessage={isOwnMessage}>
            <Fragment.ChatBubbleInner>
                <FlexGrid justifySpaceBetween>
                    <Fragment.ChatBubbleCreator>{creatorName}</Fragment.ChatBubbleCreator>
                    <Fragment.IconWrapper>
                        {accountId !== 0 && downloadMessageRequest && downloadMessageId === messageId ? (
                            <PreloaderSpin paddingTop='0px' small={true} />
                        ) : (
                            <IconLink
                                title={t('Download message')}
                                onClick={() => downloadMessage(accountId, messageId, header)}
                                color={theme.color.color90}
                                iconKey='file_download'
                                iconSize={theme.fontSize.medium}
                                width='20px'
                            />
                        )}
                    </Fragment.IconWrapper>
                </FlexGrid>
                <FlexGridItem>
                    <FlexGrid>
                        {labels.length > 0 && (
                            <React.Fragment>
                                {labels.map((l, i) => {
                                    return (
                                        <React.Fragment>
                                            <TagCategory key={i} backgroundcolor={mainColor} text={l} />
                                        </React.Fragment>
                                    )
                                })}
                            </React.Fragment>
                        )}
                    </FlexGrid>
                </FlexGridItem>
                <PushBottom10 />
                <FlexGridItem>
                    <Fragment.ChatBubleMessage>
                        <Anchorme target='_blank' rel='noreferrer noopener'>
                            {truncateMessage}
                        </Anchorme>
                    </Fragment.ChatBubleMessage>
                </FlexGridItem>
                <PushBottom10 />
                <FlexGrid itemsCenter justifySpaceBetween>
                    <React.Fragment>
                        <IconLabel icon='attach_file' label={filesToShow.length} />
                    </React.Fragment>
                    <Fragment.ChatBubleDate>
                        <DateComponent date={dateOnMessage} /> - <Time date={dateOnMessage} timeHourSuffix='' />
                    </Fragment.ChatBubleDate>
                </FlexGrid>
            </Fragment.ChatBubbleInner>
        </Fragment.ChatBubbleWrapper>
    )
}

export default withTheme(ChatBubbleFavoriteComponent)
