import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import theme from 'plugins/customers/myk/theme'
import { useTranslation } from 'react-i18next'


export function LawBannerTextMyk() {
  const { t } = useTranslation()
  const { config } = usePluginsContext()


  return (
    <>
      {t('LawBannerText1')} {' '}
      <a
        style={theme.classes.lawBannerAgreement}
        href={config.datenschutzLink}
        target='_blank'
      >
              {t('LawBannerText2')}

      </a>
      {' '}{t('LawBannerText3')}
    </>
  )
}
