import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import FallbackThumbnail from 'application/components/controls/fallback_thumbnail'
import * as Fragments from './fragments'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { FlexGrid } from 'application/components/fragments/grid'
import { textHelpers } from 'application/common'
import DateComponent from 'application/components/controls/date'

const ThumbnailViewer = (props) => {
    return (
        <React.Fragment>
            <Fragments.StyledThumbnailImage src={props.attachment.thumbnail} alt={props.attachment.fileName} isActive={props.isActive} />
        </React.Fragment>
    )
}

const getAttachmentViewer = (extension) => {
    switch (extension.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'jpe':
        case 'png':
        case 'gif':
            return ThumbnailViewer
        default:
            return FallbackThumbnail
    }
}

const AttachmentThumbnailComponent = ({
    attachment,
    onAttachmentClick,
    showCaption = true,
    isActive = false,
    marginRight = true,
    isSizeFixed = false,
    creationDate,
    divHeight,
    divWidth,
    thumbnailFailed,
}) => {
    const ThumbnailComponent = getAttachmentViewer(attachment.fileExtension)
    const truncateFileName = textHelpers.truncateMiddleText(attachment.fileName, 10)
    return (
        <Fragments.StyledAttachment
            marginRight={marginRight}
            onClick={
                onAttachmentClick !== undefined
                    ? () => {
                          onAttachmentClick(attachment)
                      }
                    : undefined
            }
            isActive={isActive}
            className='jest-attachment-thumbnail'
        >
            <Fragment>
                {thumbnailFailed ? (
                    <FallbackThumbnail fileExtension={attachment.fileExtension} />
                ) : (
                    <Fragments.StyledThumbnail divHeight={divHeight} divWidth={divWidth} imageSrc={attachment.thumbnail} isSizeFixed={isSizeFixed && attachment.hasThumbnail}>
                        {attachment.hasThumbnail ? (
                            attachment.thumbnailIsLoading ? (
                                <PreloaderSpin />
                            ) : (
                                !isSizeFixed && (
                                    <ThumbnailComponent
                                        divWidth={divWidth}
                                        divHeight={divHeight}
                                        attachment={attachment}
                                        isActive={isActive}
                                        fileExtension={attachment.fileExtension}
                                    />
                                )
                            )
                        ) : (
                            <FallbackThumbnail divWidth={divWidth} divHeight={divHeight} fileExtension={attachment.fileExtension} />
                        )}
                    </Fragments.StyledThumbnail>
                )}
                {showCaption && (
                    <FlexGrid directionColumn>
                        <Fragments.StyledCaption>{truncateFileName}</Fragments.StyledCaption>
                        {creationDate && (
                            <Fragments.StyledCaption>
                                <DateComponent date={creationDate} />
                            </Fragments.StyledCaption>
                        )}
                    </FlexGrid>
                )}
            </Fragment>
        </Fragments.StyledAttachment>
    )
}

AttachmentThumbnailComponent.propTypes = {
    attachment: PropTypes.object,
    onAttachmentClick: PropTypes.func,
    showCaption: PropTypes.bool,
    isActive: PropTypes.bool,
}

export default AttachmentThumbnailComponent
