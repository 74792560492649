export const filterDefinitions = {
    consultant: [
        {
            name: 'unassigned',
            displayStatus: 'unassigned',
            label: 'not assigned',
            query: 'status=0'
        },
        {
            name: 'hasdeadline',
            displayStatus: 'hasdeadline',
            label: 'deadlines',
            query: 'deadline=true'
        },
        {
            name: 'inprogress',
            displayStatus: 'inprogress',
            label: 'in progress',
            query: 'status=1&status=2&status=3&status=4status=5'
        },
        {
            name: 'inreview',
            displayStatus: 'inreview',
            label: 'in review',
            query: 'status=6'
        }
    ],
    basic: [
        {
            name: 'done',
            display_status: 'done',
            label: 'done',
            query: 'status=7'
        }
    ]
}