import ACTIONS from 'application/constants'

export const forgotUsernameRequest = (email, errorMessage, successMessage) => ({
    type: ACTIONS.AUTH_FORGET_USERNAME_REQUEST,
    email,
    errorMessage,
    successMessage
})

export const refreshForgotUsername = () => ({
    type: ACTIONS.AUTH_RERESH_FORGET_USERNAME,
})

export const refreshForgotPassword = () => ({
    type: ACTIONS.AUTH_RERESH_FORGET_PASSWORD,
})

export const forgotUsernameSucceed = () => ({
    type: ACTIONS.AUTH_FORGET_USERNAME_SUCCEED,
})

export const forgotUsernameFailed = () => ({
    type: ACTIONS.AUTH_FORGET_USERNAME_FAILED,
})

export const forgotPasswordRequest = (username, errorMessage, successMessage) => ({
    type: ACTIONS.AUTH_FORGET_PASSWORD_REQUEST,
    username,
    errorMessage,
    successMessage
})

export const forgotPasswordSucceed = () => ({
    type: ACTIONS.AUTH_FORGET_PASSWORD_SUCCEED,
})

export const forgotPasswordFailed = () => ({
    type: ACTIONS.AUTH_FORGET_PASSWORD_FAILED,
})

export const sendNewPassword = (passwordHashKey, password) => ({
    type: ACTIONS.AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD,
    passwordHashKey,
    password
})

export const sendNewPasswordSucceed = () => ({
    type: ACTIONS.AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD_SUCCEED
})

export const sendNewPasswordFailed = () => ({
    type: ACTIONS.AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD_FAILED
})

export const writeMessageErrorForgotCredential = (errorMessage) => ({
    type: ACTIONS.AUTH_FORGET_CREDENTIAL_SHOW_ERROR,
    errorMessage
})