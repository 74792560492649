import { FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import PushToDashboard from '../../../auth/login_account/effects/push_to_dashboard'
import { LoginAccountContainer } from '../../../auth/login_account/fragments'
import ContentLoginAccountSection from '../../../auth/login_account/sections/content'
import ExternalRequestsSection from '../../../auth/login_account/sections/external_requests'
import FooterLoginAccountSection from '../../../auth/login_account/sections/footer'
import HeaderExternalLoginAccountSection from '../../../auth/login_account/sections/header'
import WelcomeGreetings from '../../../auth/login_account/sections/welcome_greetings'

const LoginSpecificAccountTabletPortrait = (props) => {
    return <PushToDashboard {...props}>
        <LoginAccountContainer useMobile useTablet>
            <HeaderExternalLoginAccountSection useTablet {...props} />
            <WelcomeGreetings useTablet {...props} />
            <ExternalRequestsSection useTablet {...props} />
            <ContentLoginAccountSection useTablet {...props} />
            <FlexGridItem> 
                <FooterLoginAccountSection useTablet notDesktop {...props} />
            </FlexGridItem>
        </LoginAccountContainer>
    </PushToDashboard>
}

export default LoginSpecificAccountTabletPortrait
