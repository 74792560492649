export const webViewerChecker = () => {
    var standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent)
    
    const isIosWebView = ios && !standalone && !safari
    const isAndroidWebView = !ios && userAgent.includes('wv')
    return {
        isIosWebView,
        isAndroidWebView
    }
}