import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, {useState} from 'react'
import * as Fragments from '../fragments'
import { useTranslation } from 'react-i18next'
import { ROUTES } from 'application/constants'
import { Link, useNavigate } from 'react-router-dom'

const HeaderExternalLoginAccountSection = (props) => {
    const [openMenu, setOpenMenu] = useState(false)
    const navigate = useNavigate()
	const { 
        accountPublicInfo,
        useMobile = false,
        loggedIn, 
        handleLogin,
    } = props
	const {t} = useTranslation()
    const sizeLogo = useMobile ? '55px' :'80px'
    const {
        primaryLogoUrl,
        secondaryLogoUrl,
        accountName,
        primaryColor,
        assistants,
        accountUniqueKey
    } = accountPublicInfo
    const imageSource = primaryLogoUrl !== null ? primaryLogoUrl : '/assets/images/icon_esy_one.png'
    const displayName = accountName.toLowerCase()
    const paddingLogoAndText = useMobile ? '15px' : '25px'
    const sizeFontLogo = useMobile ? '16px' : '28px'

    const goToDashboard = () => {
        navigate(ROUTES.DASHBOARD)
    }

    const getUrl = (id) => {
        if (window !== 'undefined') {

            const urlBase = window.location.pathname

            const checklast = urlBase.substring(urlBase.length - 1)

            let slice = ''
            if (checklast === '/') {
                slice = urlBase.slice(0, -1)
            } else {

                slice = urlBase
            }

            const url = slice + '/guidance/' + id
            return url
        }
    }

    const handleScroll = (home) => {
        var anchorComment = document.getElementById(`${home}`)


        if (anchorComment) {

            // anchorComment.scrollBy({top: 1000, left: 0, behavior: 'smooth'})
            // var offset = 50 // sticky nav height
            // window.scroll({ top: (anchorComment.offsetTop - offset), left: 0, behavior: 'smooth' })

            anchorComment.scrollIntoView({ behavior: 'smooth', block: 'start'})
        }

    }

    const menus = [
        {
            name: 'Home',
            url: '#',
            backgroundColor: '#ffffff',
            color: '#404040',
            loggedIn: false,
            handleLogin: () => {
                handleScroll('homing')
            },
            t: t('Home'),
        },
        // {
        //     name: 'About',
        //     url: '#',
        //     backgroundColor: '#ffffff',
        //     color: '#404040',
        //     loggedIn: false,
        //     handleLogin: () => {},
        //     t: t('About'),
        // },
        {
            name: 'Services',
            url: '#service',
            backgroundColor: '#ffffff',
            color: '#404040',
            loggedIn: false,
            handleLogin: () => {
                handleScroll('service')
            },
            t: t('Services'),
        },
        {
            name: loggedIn ? 'Dashboard' :'Login',
            url: '#',
            backgroundColor: '#ffffff',
            color: '#404040',
            loggedIn: loggedIn,
            handleLogin: loggedIn ? goToDashboard : handleLogin,
            t: t('Login'),
        },
    ]

    return (
        <Fragments.AsclarMainContainerCustom>
            <FlexGrid>
                <Fragments.HeaderContainer id='homing'>
                    {/* <Fragments.AsclarMainContainer> */}
                       <Fragments.Header>
                            <Fragments.ImageTextContainer>
                                <ImagePlaceHolder radius={primaryLogoUrl !== null ? '15px' : '0px'} withBorder={false} source={imageSource} large={sizeLogo} />
                                <Fragments.LogoPlaceholder whiteSpace={useMobile ? 'nowrap' : 'wrap'}>
                                <Fragments.AsclarText isBold transform={'capitalize'} fontSize={sizeFontLogo} paddingLeft={paddingLogoAndText} >{displayName}</Fragments.AsclarText>
                                </Fragments.LogoPlaceholder>
                            </Fragments.ImageTextContainer>
                            {useMobile ? (
                            <Fragments.BurgerMenu onClick={() => setOpenMenu(!openMenu)}>
                                <Fragments.BurgerMenuIcon src='/assets/images/burger-menu.png' />
                            </Fragments.BurgerMenu>
                            ) : (
                            <Fragments.HeaderMenuItemContainer>
                                {menus.map((menu, index) => (
                                        <Fragments.HeaderMenuItem 
                                            backgroundColor={menu.backgroundColor}
                                            color={menu.color}
                                            key={index}
                                            onClick={menu.handleLogin}

                                        >
                                            {menu.name}
                                        </Fragments.HeaderMenuItem>
                                ))}
                                {assistants.length > 0 && assistants.map((reason) => {

                                    if (reason.isMainService) {
                                        return (
        
                                            <Fragments.PrimaryButtonService href={reason.url}
                                                backgroundColor={primaryColor}
                                                target='_blank'
                                                rel='noreferrer noopener'
                                            >
                                                {reason.name}
                                            </Fragments.PrimaryButtonService>
                                        )
                                    }
                                }) 


                                
                                }
                            </Fragments.HeaderMenuItemContainer>
                            )}
                       </Fragments.Header>
                    {/* </Fragments.AsclarMainContainer> */}
                    <Fragments.AsclarSpacer padding={ useMobile ? '10px' : '15px'}/>
                    <Fragments.HeroContainer>
                        <FlexGridItem itemsCenter>
                            <Fragments.AsclarBannerContainer border={'0px'} mobile={0}>
                                {
                                    useMobile ?
                                    <ImagePlaceHolder withBorder={false} large={'100%'} source={secondaryLogoUrl} />
                                    :
                                    <Fragments.AsclarBannerContentCustom isMobile={0} imageSrc={secondaryLogoUrl} />
                                }
                            </Fragments.AsclarBannerContainer>
                        </FlexGridItem>
                    </Fragments.HeroContainer>
                </Fragments.HeaderContainer>
            </FlexGrid>
            {openMenu && (
            <Fragments.MenuMobileContainer onClick={() => setOpenMenu(false)}>
                <Fragments.MenuMobilePosition>
                    {menus.map((menu, index) => (
                        <Fragments.MenuMobileItem 
                            backgroundColor={menu.backgroundColor}
                            color={menu.color}
                            key={index}
                            onClick={() => {
                                handleLogin()
                            }}
                        >
                            {menu.name}
                        </Fragments.MenuMobileItem>
                    ))}
                    {assistants.length > 0 && (
                        <Fragments.PrimaryButtonServiceMobile href={assistants[0].url}
                            backgroundColor={primaryColor}
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            {assistants[0].name}
                        </Fragments.PrimaryButtonServiceMobile>
                    )}
                </Fragments.MenuMobilePosition> 
            </Fragments.MenuMobileContainer>
            )}
        </Fragments.AsclarMainContainerCustom>
    )
}

export default HeaderExternalLoginAccountSection