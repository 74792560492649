import React, { Fragment, useEffect } from 'react'

const FormLoader = (props) => {
    const { formLoaded, children, getFormAsssistant, getLookUpFirst, valueParams, formLoadedFailed, formRedirected, formType } = props
    const { key, externalRequestKey} = valueParams
    const shouldLoad = !formLoaded && !formLoadedFailed && !formRedirected && key !== '' && key !== undefined && key !== null
    useEffect(() => {
        shouldLoad && getFormAsssistant(key, '', 0, { getLookUpFirst, key, externalRequestKey, formType })
    }, [externalRequestKey, formType, getFormAsssistant, getLookUpFirst, key, shouldLoad])

    return <Fragment>
        {children}
    </Fragment>
}

export default FormLoader
