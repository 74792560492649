import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { LayoutBase } from '..'
import * as Fragment from '../fragments/auth'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

const AuthLayout = ({ accountUniqueKey, children }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    
    return <LayoutBase withDiv100={false}>
        <Fragment.LayoutWrapper>
            <Fragment.LayoutInputWrapper>
                {children}
            </Fragment.LayoutInputWrapper>
            {
                !isMobileOnly &&
                <Fragment.LayoutInfoWrapper accountUniqueKey={accountUniqueKey} loginImageRight={theme.loginRightImage} />
            }
        </Fragment.LayoutWrapper>
    </LayoutBase>
}

export default AuthLayout