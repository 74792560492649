import Moment from 'moment'

export const getOffset = (d) => {
    return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
}

export const dateIsBefore = (d1, d2) => {
    const date1 = new Moment(new Moment(d1).format('YYYY-MM-DD')).toDate()
    const date2 = new Moment(new Moment(d2).format('YYYY-MM-DD')).toDate()
    return new Date(date1) < new Date(date2)
}

export const datesRange = (d1, d2) => {
    const date1 = new Moment(new Moment(d1).format('YYYY-MM-DD'))
    const date2 = new Moment(new Moment(d2).format('YYYY-MM-DD'))
    return date2.diff(date1, 'days')
}