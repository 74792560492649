import ACTIONS from 'application/constants'

export const showGallery = (fileId = null, fileName = '', accountId, parentId = '', akteIdHash = '', thumbnails = [], inAufgabe = false) => ({
    type: ACTIONS.GALLERY_SHOW_GALLERY,
    fileId,
    fileName,
    accountId,
    parentId,
    akteIdHash,
    thumbnails,
    inAufgabe
})

export const setActiveFile = (fileId, fileName, accountId, akteIdHash, parentId) => ({
    type: ACTIONS.GALLERY_SET_ACTIVE_FILE,
    fileId,
    fileName,
    accountId,
    akteIdHash,
    parentId,
})

export const showGalleryOnQueue = (fileId = null, fileName = '', data, accountId, parentId = null) => ({
    type: ACTIONS.GALLERY_SHOW_GALLERY_ON_QUEUE,
    fileId,
    fileName,
    data,
    accountId,
    parentId,
})

export const setActiveFileOnQueue = (fileId, fileName, data, accountId) => ({
    type: ACTIONS.GALLERY_SET_ACTIVE_FILE_ON_QUEUE,
    fileId,
    fileName,
    data,
    accountId
})

export const sendFilesMessage = (accountId, files) => ({
    type: ACTIONS.GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE,
    accountId,
    files
})

export const sendFilesMessageSucceed = () => ({
    type: ACTIONS.GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE_SUCCEED,
})

export const sendFilesMessageFailed = () => ({
    type: ACTIONS.GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE_FAILED,
})

export const hideGallery = () => ({
    type: ACTIONS.GALLERY_HIDE_GALLERY
})

export const hideGalleryOnQueue = () => ({
    type: ACTIONS.GALLERY_HIDE_GALLERY_ON_QUEUE
})