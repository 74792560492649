import Preloader3D from 'application/components/controls/preloader/3d'
import { FlexGrid } from 'application/components/fragments/grid'
import NotFoundPage from '../not_found'
import { Fragment } from 'react'
import { useEffect } from 'react'


const LoginWithoutPin = (props) => {
  const {
    children,
    loginWithoutPin,
    akteMeta,
    errorMessage,
    loginFailed,
    loginRequest,
    auth
  } = props

  useEffect(() => {
    loginWithoutPin(akteMeta.authentication, errorMessage)
  }, [])

  if (!loginRequest && loginFailed) {
    return <NotFoundPage />
  }

  if (!loginRequest && auth.unregistered.loggedIn) {
    return <Fragment>{children}</Fragment>
  }


  return <FlexGrid height='100vh' justifyCenter itemsCenter>
    <Preloader3D />
  </FlexGrid>
}

export default LoginWithoutPin
