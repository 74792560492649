import { FlexGridItem, FlexGrid } from 'application/components/fragments/grid'
import React, { useContext } from 'react'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { useTranslation } from 'react-i18next'
import { Headline1, Headline2, Headline3 } from 'application/components/fragments/typography'
import { LayoutBase } from 'application/components/pages/_layout'
// import ReturnUrlSucceed from '../sections/return_url_succeed'
import ReturnUrlSection from '../sections/return_url_section'
import { PluginAlternateView, PluginsContext } from 'plugins'
import { useTheme } from 'styled-components'

const FormSucceed = (props) => {
    const { resultText = '' } = props
    const {t} = useTranslation()
    const hasSubmittedForm = props.accountPublicInfo?.primaryLogoUrl !== undefined ? true : false
    const theme = useTheme()

    return <LayoutBase>
        {/* <FlexGrid directionColumn itemsCenter> */}
            { hasSubmittedForm ?
            <div 
                style={theme.classes.succeedContainer}
            >
                <React.Fragment>
                    <div style={{
                        paddingTop: '20px',
                        marginBottom: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // gap: '10px',
                    }}>
                        {/* <ImagePlaceHolder large='50px' withBorder={false} radius={'999px'} altText={props.accountPublicInfo.accountName} source={props.accountPublicInfo.primaryLogoUrl} /> */}
                        <FlexGridItem grow={0} justifyCenter>
                            <ImagePlaceHolder
                                withBorder={false}
                                large={'70%'}
                                source={props.accountPublicInfo.primaryLogoUrl}
                                toCenter
                            />
                        </FlexGridItem>
                    </div>

                    <PluginAlternateView id='mobile.assistant.form.succeed' resultText={resultText}>
                        <Headline1 style={{ fontWeight: '500', color: '#8C8C8C' }}>{props.accountPublicInfo.accountName}</Headline1>
                        <Headline1 cssAlign='center'>
                                <div style={{
                                    marginTop: '20px',
                                }}>
                                {t('You have successfully submitted your answer')}
                                <FlexGridItem itemsCenter justifyCenter widthMobile={'80%'} needSpace={'auto'} needSpaceLeft={'auto'}>
                                    {
                                        resultText !== '' && 
                                            <div
                                                style={{
                                                    width: '80%',
                                                    padding: '20px 0px',
                                                    lineHeight: '1.5',
                                                }}
                                            >
                                                <Headline3 style={{ fontWeight: 500 }}>
                                                    {resultText}
                                                </Headline3>
                                            </div>
                                    }
                                </FlexGridItem>
                                </div>
                            </Headline1>
                            <ReturnUrlSection withText={false} withImage={false} {...props} />
                    </PluginAlternateView>
                </React.Fragment>
                </div>
                 : <FlexGridItem itemsCenter justifyCenter widthMobile={'80%'}>
                    <Headline1 cssAlign='center'>
                        {t('Forbidden access to requested resource')}
                    </Headline1>
                </FlexGridItem>
            }
        {/* </FlexGrid> */}
    </LayoutBase>
}

export default FormSucceed
