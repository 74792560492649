import React from 'react'
import SettingsSidebarComponent from './component'

const SettingsSidebarEffects = (props) => {
    //import props

    //useEffect

    //condition

    //preloader

    return <SettingsSidebarComponent {...props} />
}

export default SettingsSidebarEffects