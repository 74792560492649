import React from 'react'
import { isIOS, isMobileOnly, isSafari, isTablet, osVersion } from 'react-device-detect'
import { withTheme } from 'styled-components'
import { LayoutBase } from '..'
import * as Fragments from '../fragments/auth'

const ExternalLoginAccountLayout = ({ children, theme }) => {
    const with100 = (isIOS && isMobileOnly && parseFloat(osVersion) < 15.0) || (isIOS && !isSafari) || (isIOS && isTablet)
    return <LayoutBase withDiv100={with100} backgroundColor={theme.color.backgroundPrimary}>
        <Fragments.ExternalLoginAccountWrapper>
            {children}
        </Fragments.ExternalLoginAccountWrapper>
    </LayoutBase>
}

export default withTheme(ExternalLoginAccountLayout)
