import { AKTEN, ROUTES } from 'application/constants'
import React from 'react'
import { useEffect } from 'react'
import { Fragment } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const RedirectDeletedAkteRoot = (props) => {
    const { children, metaRootMessage, setSelectedAkte } = props
    let navigate = useNavigate()
    const exist = metaRootMessage && metaRootMessage.exist
    const messageType = metaRootMessage && metaRootMessage.messageType
    const messageFailed = metaRootMessage && metaRootMessage.isFailed
    const inRoot = messageType === AKTEN.TYPE.ROOT
    useEffect(() => {
        metaRootMessage && !exist && inRoot && !messageFailed &&
                setSelectedAkte(null) &&
            navigate(ROUTES.AKTEN)
    }, [exist, inRoot, messageFailed, metaRootMessage, navigate, setSelectedAkte])

    if (messageFailed && inRoot) {
        return <Navigate to={ROUTES.AKTEN} />
    }
    return <Fragment>
        {children}
    </Fragment>
}

export default RedirectDeletedAkteRoot
