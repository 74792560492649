import { refreshGetFormElements, refreshReturnUrlForm, refreshSendForm } from 'application/redux/actions/form'
import { connect } from 'react-redux'
import FormSucceed from './succeed'

const mapStateToProps = (state) => ({
    accountPublicInfo: state.common.accountDetails.publicInfo,
    sendRequest: state.form.send.request,
    sendSucceed: state.form.send.succeed,
    sendFailed: state.form.send.failed,
    formData: state.form.payload,
    resultText: state.form.resultText,
    waitingScreen: state.waitingScreen,
    auth: state.auth,
    formReturnUrl: state.form.returnUrl,
})

const mapDispatchToProps = dispatch => ({
    refreshReturnUrlForm: () => {
        dispatch(refreshReturnUrlForm())
        dispatch(refreshSendForm())
        dispatch(refreshGetFormElements())
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormSucceed)