import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import React, { useEffect } from 'react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { WrapperServiceProviderDetail } from '../fragments'
import HeaderServiceProviderDetail from '../sub_component/header'
import MenuServiceProvider from '../sub_component/menu'
import CategoriesServiceProviderDetail from '../sub_component/categories'
import ContactDetailServiceProvider from '../sub_component/contact_detail'
import ExternalFormServiceProvider from '../sub_component/external_form'
import AllAktenInOneAccountLoader from './akten_in_one_account_loader'
import ActionButton from 'application/components/controls/button/action_button/component'
import { isTablet } from 'react-device-detect'

const ServiceProviderDetailSidebarDefault = (props) => {
    const { closeServiceProvideDetailSidebar, activateModeToFalse, selectedAccount, handleOpenAktenPopupSidebar, handleOpenAgbAccount, handleDisplayDatenshutz, allAccountsLoaded, isActivateModeParams } = props
    const selectedAkten = selectedAccount?.akten

    useEffect(() => {
        isActivateModeParams && activateModeToFalse()
    }, [activateModeToFalse, isActivateModeParams])

    const {t} = useTranslation()
    return <AllAktenInOneAccountLoader {...props}>
        {
            allAccountsLoaded ? <WrapperServiceProviderDetail maxHeight={'100%'} isOverflow={true}>
                <MenuServiceProvider selectedAccount={selectedAccount} closeServiceProvideDetailSidebar={closeServiceProvideDetailSidebar} openAgb={handleOpenAgbAccount} openDatenshutz={handleDisplayDatenshutz} />
                <FlexGridItem maxHeight='auto' justifyCenter>
                    <FlexGrid directionColumn>
                        <HeaderServiceProviderDetail {...props} />
                        {
                            selectedAkten?.length > 0 && !isTablet &&
                            <ActionButton backgroundColor={selectedAccount.mainColor} onButtonClick={handleOpenAktenPopupSidebar} buttonText={t('Show cases')} strechted={true} />
                        }
                        <CategoriesServiceProviderDetail {...props} />
                        <ContactDetailServiceProvider selectedAccount={selectedAccount} />
                    </FlexGrid>
                </FlexGridItem>
                <ExternalFormServiceProvider {...props} />
                <PushBottom24 />
                <FlexDividerHorizontal />
                <PushBottom24 />
            </WrapperServiceProviderDetail> : <FlexGrid justifyCenter>
                <PreloaderSpin />
            </FlexGrid>
        }
    </AllAktenInOneAccountLoader>
}

export default withTheme(ServiceProviderDetailSidebarDefault)