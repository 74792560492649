import { call, put } from '@redux-saga/core/effects'
import { api } from 'application/api'
import { replaceMessageQueueById, updateDataOnMessage } from 'application/redux/actions/messages'
import { setDeliveredOnOwnMessage } from '../pages/message_raw_mapper'

export function* replaceQueueMessageFolderSaga(action) {
    const folderId = action.folderId !== undefined ? action.folderId : action.metaData.folderId
    const { accountId, akteIdHash } = action.params
    const result = yield call(api.gateway.pages.getMessageDetailsOnFolder, accountId, akteIdHash, folderId)
    const deliveredMessages = setDeliveredOnOwnMessage(result.messages.timeline)
    const files = result.files
    yield put(replaceMessageQueueById(action.messageKey, action.idOnQueue, deliveredMessages[deliveredMessages.length - 1]))
    yield put(updateDataOnMessage(action.messageKey, { files }))
}