import styled from 'styled-components'

export const SettingListMobileWrapper = styled.div`
	display: flex;
	flex-flow: column nowrap;
	gap: 10px;
`

export const SettingListMobileContentWrapper = styled.div`
	display: flex;
	align-items: center;
	border-radius: 10px;
	padding: 5px;
	background-color: ${p => p.isselected ? p.theme.color.color30 : 'transparent'};
`