import { put } from '@redux-saga/core/effects'
import { popMessageOnQueue } from 'application/redux/actions/messages'
import { sendMessageAkteFailed } from 'application/redux/actions/pages/akten'
import { handleError } from '.'

export function* sendMessageErrorSaga(e, action) {
    yield put(popMessageOnQueue(action.messageKey, action.idOnQueue))
    yield put(sendMessageAkteFailed())
    let error = e
    if(e.data.httpStatusCode === 403)
        error = {
            ...e,
            data : {
                ...e.data,
                reasonMessage: 'UploadLimit'
            }
        }

    yield handleError(error)
}