import React from 'react'
import { Select } from 'application/components/controls/form_elements'
import { I18N } from 'application/constants'
import { LanguageImageMapper, LanguageNameMapper, valueIntLanguage } from './language_mapper'
import { components } from 'react-select'
import { FlexGrid, PushLeft20 } from 'application/components/fragments/grid'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'

const LanguageSelect = ({
    name='language',
    register,
    setValue,
    initialValue,
    onChange,
    control
}) => {
    const { t } = useTranslation()
    const languageTypOption = [
        {
            value: I18N.LANGUAGES_TYPE.DEUTSCH,
            label: <LanguageNameMapper languageType={I18N.LANGUAGES_TYPE.DEUTSCH} />,
            icon: <LanguageImageMapper languageType={I18N.LANGUAGES_TYPE.DEUTSCH} />
        },
        {
            value: I18N.LANGUAGES_TYPE.ENGLISH,
            label: <LanguageNameMapper languageType={I18N.LANGUAGES_TYPE.ENGLISH} />,
            icon: <LanguageImageMapper languageType={I18N.LANGUAGES_TYPE.ENGLISH} />
        },
    ]

    const { Option } = components
    const IconOption = props => (
        <Option {...props}>
            <FlexGrid>
                {props.data.icon}
                <PushLeft20 />
                {props.data.label}
            </FlexGrid>
        </Option>
    )

    const ValueContainer = ({ children, ...props }) => {
        return (
            components.ValueContainer && (
                <components.ValueContainer {...props}>
                    {children &&
                        <FlexGrid itemsCenter>
                            <LanguageImageMapper languageType={props.selectProps.value.value} />
                            {children}
                        </FlexGrid>}
                </components.ValueContainer>
            )
        )
    }

    return <Select 
        label={t('Languages')}
        name={name}
        register={register}
        setValue={setValue}
        options={languageTypOption}
        defaultValue={languageTypOption[valueIntLanguage(initialValue)]}
        validate={null}
        isClearable={false}
        valueComponent={ValueContainer}
        optionComponent={IconOption}
        onChange={onChange}
        listenToDefaultValueChange={true}
        htmlId={nanoid()}
        control={control}
    />
}

export default LanguageSelect