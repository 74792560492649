import { motion } from 'framer-motion'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
    // getAllAncestorsForCurrentContainers,
    getChildrenByParent
} from './helpers'
// import LeafElementTree from './leaf_element'
import NodeElementTree from './node_element'

const TreeContainerStyled = styled(motion.div)`
  min-width: 0;
  flex-direction: column;
  display: flex;
  max-width: 100%;
  overflow: hidden;
`

const DropZone = styled(TreeContainerStyled)``

const Content = styled(TreeContainerStyled)``

const Children = styled(TreeContainerStyled)``

const TreeContainer = (props) => {
    const {
        nodes,
        parent,
        level,
        selected,
        onSelect,
        iconSet,
        noIcons,
        rootId,
        folderId,
        breadcrumbsData,
        initiallyToggle
    } = props

    const containerItems = getChildrenByParent(nodes, parent)
    const initial = initiallyToggle || initiallyToggle === undefined
    const [isOpen, setIsOpen] = useState(
        Array(containerItems.length).fill(initial)
    ) // keeping track of open folders

    const handleToggle = (itemId, k) => {
        setIsOpen(o => {
            const _o = o.slice()
            _o[k] = !_o[k]
            return _o
        })
    }

    return <TreeContainerStyled parent={parent}>
        <DropZone>
            {
                nodes.length > 0 && nodes.map((item, k) => {
                    return <Content key={k}>
                        <NodeElementTree 
                            data={item}
                            toggle={() => handleToggle(item.id, k)}
                            onSelect={onSelect}
                            isOpen={isOpen[k]}
                            isOpenState={isOpen}
                            isRoot={parent !== rootId}
                            level={level}
                            selected={selected}
                            noIcons={noIcons}
                            iconSet={iconSet}
                            descendants={item.descendants}
                            folderId={folderId}
                            breadcrumbsData={breadcrumbsData}
                            totalUnreadMessage={item.totalUnreadMessage}
                            tooltipName={item.name}
                        />
                        {
                            isOpen[k] && <Children>
                                <TreeContainer
                                    parent={item.id}
                                    rootId={rootId}
                                    nodes={item.descendants}
                                    level={level + 1}
                                    onSelect={onSelect}
                                    selected={selected}
                                    noIcons={noIcons}
                                    iconSet={iconSet}
                                    folderId={folderId}
                                    breadcrumbsData={breadcrumbsData}
                                />
                            </Children>
                        }
                    </Content>
                })
            }
        </DropZone>
    </TreeContainerStyled>
}

TreeContainer.defaultProps = {
    nodes: [],
    parent: null,
    level: 0,
    selected: null,
    onSelect: () => { },
    iconSet: null,
    noIcons: undefined,
    folderId: ''
}

export default TreeContainer