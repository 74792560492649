import ActionButton from 'application/components/controls/button/action_button/component'
import ActionButtonLinkWithIcon from 'application/components/controls/button/action_button_with_icon/component'
import ActionButtonLinkWithIconCustom from 'application/components/controls/button/action_button_with_icon/componentCustom'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem, PushBottom40 } from 'application/components/fragments/grid'
import { ROUTES } from 'application/constants'
import { PluginAlternateView } from 'plugins'
import React, { Fragment } from 'react'
import { isAndroid, isIOS, isMobile, isMobileOnly, isTablet } from 'react-device-detect'

export const returnUrlProps = (returnUrlIndex, loggedIn, formReturnUrl, params, location) => {
    const { accountIdentity, externalRequestKey } = params
    const { pathname } = location
    if (returnUrlIndex) {
        switch (true) {
        case returnUrlIndex.includes(ROUTES.AKTEN):
            return {
                buttonText: 'AKTEN',
                returnUrl: formReturnUrl
            }
        case returnUrlIndex.includes(ROUTES.DASHBOARD):
            return {
                buttonText: 'DASHBOARD',
                returnUrl: formReturnUrl
            }
        case returnUrlIndex.includes(ROUTES.PROUDLY_PRESENTS_BASE) && loggedIn:
            return {
                buttonText: 'DASHBOARD',
                returnUrl: ROUTES.DASHBOARD
            }
        case returnUrlIndex.includes(ROUTES.PAGE_BASE) && loggedIn:
            return {
                buttonText: 'DASHBOARD',
                returnUrl: ROUTES.DASHBOARD
            }
        case returnUrlIndex.includes(ROUTES.PROUDLY_PRESENTS_BASE) && !loggedIn:
            return {
                buttonText: 'HOME',
                returnUrl: ROUTES.BASE
            }
        case returnUrlIndex.includes(ROUTES.PAGE_BASE) && !loggedIn:
            return {
                buttonText: 'HOME',
                returnUrl: ROUTES.BASE
            }
        default:
            return {
                buttonText: loggedIn ? 'DASHBOARD' : 'HOME',
                returnUrl: `/page/${accountIdentity}/guidance/${externalRequestKey}`
            }
        }
    } else {
        switch(true){
        case pathname.includes(ROUTES.FILL_THE_FORM_BASE): 
            return {
                hideBackButton: true
            }
        default: 
            return {
                buttonText: loggedIn ? 'DASHBOARD' : 'HOME',
                returnUrl: `/page/${accountIdentity}/guidance/${externalRequestKey}`
            }
        }
    }
}


export const contentChoices = [
    {
        imageSrc: '/assets/images/alien_want_to_back.png',
        title: 'The Handiest Way To Organize Teamwork',
        desc: 'Feeling afraid to fill this form, feel free to comeback soon'
    },
    {
        imageSrc: '/assets/images/astronot_want_to_back.png',
        title: 'The Handiest Way To Organize Teamwork',
        desc: 'Feeling busy to fill this form, feel free to comeback soon'
    },
]


const CallbackButton = ({ refreshReturnUrlForm, backgroundColor }) => {
    return <FlexGridItem widthMobile='50%'>
        <ActionButton onButtonClick={refreshReturnUrlForm} backgroundColor={backgroundColor} hrefTo={'https://esything.io/'} fontSize={'14px'} buttonText={'HOME'} strechted />
    </FlexGridItem>
}

const IosButton = ({ isIosWebView, refreshReturnUrlForm, backgroundColor }) => {
    return isIOS ?
        isIosWebView ?
            <CallbackButton refreshReturnUrlForm={refreshReturnUrlForm} backgroundColor={backgroundColor} /> :
            <ImagePlaceHolder linkHref={process.env.REACT_APP_INSTALL_APP_IOS} withBorder={false} source={'/assets/images/app_ios.png'} large={'200px'} /> : null
}
const AndroidButton = ({ isAndroidWebView, refreshReturnUrlForm, backgroundColor }) => {
    return isAndroid ?
        isAndroidWebView ?
            <CallbackButton refreshReturnUrlForm={refreshReturnUrlForm} backgroundColor={backgroundColor} /> :
            <ImagePlaceHolder linkHref={process.env.REACT_APP_INSTALL_APP_ANDROID} withBorder={false} source={'/assets/images/app_android.png'} large={'200px'} /> : null
}

export const ContentButton = ({ refreshReturnUrlForm, theme, returnUrl, buttonText, hideBackButton, isItExplore, accountIdentity, isIosWebView, isAndroidWebView }) => {
    const useMobile = isMobileOnly
    return <FlexGridItem width={isTablet ? '65%' : '100%'} widthTablet='50%'>
        <FlexGrid justifyCenter>
            {
                !useMobile && !hideBackButton && <FlexGridItem widthMobile='50%'>
                        <ActionButton onButtonClick={refreshReturnUrlForm} backgroundColor={theme.color.color100} linkTo={returnUrl} fontSize={'14px'} buttonText={'Back'} strechted={true} />
                </FlexGridItem>
            }
            {
                isItExplore && <FlexGridItem itemsCenter justifyCenter>
                    {
                        isMobile ? <React.Fragment>
                            <PushBottom40 />
                                <ActionButtonLinkWithIcon widthbutton={'100%'} color={theme.color.color100} noBackground borderColor={theme.color.color100} outline linkTo={ROUTES.PAGE_BASE + accountIdentity} onButtonClick={refreshReturnUrlForm} buttonText={'Page'} iconKey='arrow_forward' iconPosition='after' />
                        </React.Fragment> :
                        <ActionButtonLinkWithIcon widthbutton={'80%'} color={theme.color.color100} noBackground borderColor={theme.color.color100} outline linkTo={ROUTES.PAGE_BASE + accountIdentity} onButtonClick={refreshReturnUrlForm} buttonText={'Page'} iconKey='arrow_forward' iconPosition='after' />
                    }
                </FlexGridItem>
            }
            {
                useMobile && 
                <PluginAlternateView>
                    <Fragment>
                        <IosButton backgroundColor={theme.color.color100} isIosWebView={isIosWebView} refreshReturnUrlForm={refreshReturnUrlForm} />
                        <AndroidButton backgroundColor={theme.color.color100} isAndroidWebView={isAndroidWebView} refreshReturnUrlForm={refreshReturnUrlForm} />
                    </Fragment>
                </PluginAlternateView>
            }
        </FlexGrid>
    </FlexGridItem>
}

export const BackButton = ({ refreshReturnUrlForm, isExternalRequest, theme, returnUrl, buttonText, isItExplore, accountIdentity, isIosWebView, isAndroidWebView }) => {
    const useMobile = isMobileOnly
    return <FlexGridItem width={isTablet ? '65%' : '100%'} widthTablet='50%'>
        <FlexGrid justifyCenter>
            {
                !useMobile && <FlexGridItem itemsCenter justifyCenter widthMobile='50%'>
                    <ActionButtonLinkWithIconCustom widthbutton={'50%'} color={'#FFFFFF'} backgroundColor={theme.color.color100} linkTo={ROUTES.PAGE_BASE + accountIdentity + '/guidance/' + isExternalRequest} onButtonClick={refreshReturnUrlForm} buttonText={'Back'} iconKey='arrow_backward' />
                </FlexGridItem>
            }
            {
                useMobile && <Fragment>
                    <IosButton backgroundColor={theme.color.color100} isIosWebView={isIosWebView} refreshReturnUrlForm={refreshReturnUrlForm} />
                    <AndroidButton backgroundColor={theme.color.color100} isAndroidWebView={isAndroidWebView} refreshReturnUrlForm={refreshReturnUrlForm} />
                </Fragment>
            }
        </FlexGrid>
    </FlexGridItem>
}