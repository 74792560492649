import Preloader3D from 'application/components/controls/preloader/3d'
import { FlexGrid } from 'application/components/fragments/grid'
import React, { useState, useEffect, useRef } from 'react'
import { getDataSelectedAkte } from './mappers'
import MessageDetailsDataLoader from './loader/message_details_data_loader'
import MessageDetailsTopBar from './message_details_top_bar'
import styled from 'styled-components'
import RedirectDeletedMessage from './redirect/redirect_deleted_message'
import { AKTEN, ROUTE_PARAMETERS } from 'application/constants'
import {
  MessagesContent,
  FilesContent,
  FavoritesContent,
  FoldersContent,
} from './content'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import Aufgaben from './aufgaben'
import { useTranslation } from 'react-i18next'
import { scrollSession } from 'application/storage/scroll_session'
import Overview from './overview/component'
import  AufgabenLoader  from './loader/aufgaben_loader'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const MessageDetailsWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
`
const MessageDetailsComponent = (props) => {
  const {
    selectedAkte,
    folderId,
    parentId,
    messageFullLoaded,
    metaMessage,
    allAktenLoaded,
    messageKey,
    onOpenGallery,
    setSelectedFolder,
    metaInfo,
    getMessageFavorite,
    favoriteLoaded,
    favoriteLoading,
    favorites,
    favoriteFailed,
    metaRootMessage,
    openAccountStatement,
    onSendFormRequest,
    allAccounts,
    getMessageDetails,
    loginAdHoc,
    waitingScreen,
    sendSucceed,
    onOpenGalleryThumbnail
  } = props
  const accountPublicInfo = allAccounts[0]
  const {
    accountId,
    accountName,
    akteIdHash,
    header,
    aktenkurzbezeichnung,
    permissionToReply,
    serviceAkte,
    mainColor,
    secondaryColor,
  } = getDataSelectedAkte(selectedAkte)
  const customProps = {
    accountPublicInfo,
    ...props,
    ...getDataSelectedAkte(selectedAkte),
  }


  const [displayContentMobile, setDisplayContentMobile] = useState({
    view: AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.MESSAGES,
    payload: {},
  })
  let params = useParams()
  let navigate = useNavigate()
  let location = useLocation()
  const handleSelectFolder = (payload) => {
    setSelectedFolder(payload, params, akteIdHash, accountId)
    navigate(
      `${location.pathname}?${ROUTE_PARAMETERS.AKTEN.FOLDER_ID}=${payload.id}&`
    )
    setDisplayContentMobile({
      view: AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.MESSAGES,
      payload: {},
    })
  }

  const { t } = useTranslation()
  const { activePlugin } = usePluginsContext()

  const [menus, setMenus] = useState('messages')
  const [aufgaben, setAufgaben] = useState([])

  const [aufgabenIsLoading, setAufgabenIsLoading] = useState(false)
  const [refreshAufgaben, setRefreshAufgaben] = useState(true)
  const [selectedFilter, setSelectedFilter] = useState(0)




  const headerMenus = activePlugin ? ['task', 'messages' ] : ['overview', 'task', 'messages']
  const filterMenus = [t('All'), t('Open'), t('Done'), t('Expired')]

  const [allowScroll, setAllowScroll] = useState(true)
  const [lastScrollBottom, setLastScrollBottom] = useState(0)
  const [shouldLoadWithOffset, setShouldLoadWithOffset] = useState(false)
  const scrollRef = useRef(null)
  const loadMessageSensorRef = useRef(null)

  function loadMessageCallback(entries) {
    if (entries[0].isIntersecting) {
      setShouldLoadWithOffset(true)
      setLastScrollBottom(calculateScrollBottom(scrollRef))
      setAllowScroll(false)
    }
  }

  function calculateScrollBottom(ref) {
    const scrollBottom =
      ref.current.scrollHeight -
      ref.current.clientHeight -
      ref.current.scrollTop
    return scrollBottom
  }

  function calculateScrollTop(lastScrollBottom, ref) {
    const scrollTop =
      ref.current.scrollHeight - ref.current.clientHeight - lastScrollBottom
    return scrollTop
  }

  useEffect(() => {
    if (!loadMessageSensorRef) return
    if (!loadMessageSensorRef.current) return
    if (!messageFullLoaded) return

    const loadMessageObserver = new IntersectionObserver(loadMessageCallback, {
      root: null,
      threshold: 1,
    })

    loadMessageObserver.observe(loadMessageSensorRef.current)

    return () =>
      loadMessageSensorRef.current &&
      loadMessageObserver.unobserve(loadMessageSensorRef.current)
  }, [loadMessageSensorRef.current, scrollRef.current])

  useEffect(() => {
    if (!scrollRef.current) return

    const handleScroll = (e) => {
      if (!allowScroll) {
        setTimeout(() => {
          scrollRef.current?.scrollTo(
            0,
            calculateScrollTop(lastScrollBottom, scrollRef)
          )
          setAllowScroll(true)
        }, 1000)
      }
    }
    scrollRef.current?.addEventListener('scroll', handleScroll)

    return () => scrollRef.current?.removeEventListener('scroll', handleScroll)
  }, [allowScroll, lastScrollBottom, scrollRef.current])

  const messageDetailsDataLoaderProps = {
    ...props,
    shouldLoadWithOffset,
    setShouldLoadWithOffset,
  }

  const aufgabenLoaderProps = {
    ...props,
    ...getDataSelectedAkte(selectedAkte),
    setAufgaben: setAufgaben,
    refreshAufgaben: refreshAufgaben,
    setRefreshAufgaben: setRefreshAufgaben,
    setAufgabenIsLoading: setAufgabenIsLoading
  }

  useEffect(() => {
    return () => {
      scrollSession.delete()
    }
  }, [])

  useEffect(() => {
    if(selectedAkte && selectedAkte.isEsyAkte === false)
      setMenus('messages')
  }, [selectedAkte])

  const Content = () => {
    switch (displayContentMobile.view) {
      case AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.MESSAGES:
        return (
          <>
            <MessagesContent
              allAktenLoaded={allAktenLoaded}
              accountName={accountName}
              mainColor={mainColor}
              accountId={accountId}
              akteIdHash={akteIdHash}
              folderId={folderId}
              messageKey={messageKey}
              permissionToReply={permissionToReply}
              secondaryColor={secondaryColor}
              serviceAkte={serviceAkte}
              metaMessage={metaMessage}
              customProps={customProps}
              displayContentMobile={displayContentMobile}
              setDisplayContentMobile={setDisplayContentMobile}
              scrollRef={scrollRef}
              loadMessageSensor={loadMessageSensorRef}
              messageFullLoaded={messageFullLoaded}
              shouldLoadWithOffset={shouldLoadWithOffset}
            />
          </>
        )
      case AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FILES:
        return (
          <FilesContent
            files={metaMessage?.files}
            accountId={accountId}
            akteIdHash={akteIdHash}
            parentId={parentId}
            onOpenGallery={onOpenGallery}
          />
        )
      case AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FOLDERS:
        return (
          <FoldersContent
            folders={metaMessage?.folders}
            setSelectedFolder={handleSelectFolder}
            metaMessage={metaMessage}
          />
        )
      case AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FAVORITES:
        return (
          <FavoritesContent
            accountId={accountId}
            allAktenLoaded={allAktenLoaded}
            folderId={folderId}
            getMessageFavorite={getMessageFavorite}
            favoriteLoaded={favoriteLoaded}
            favoriteLoading={favoriteLoading}
            favorites={favorites}
            favoriteFailed={favoriteFailed}
            akteIdHash={akteIdHash}
            metaInfo={metaInfo}
            parentId={parentId}
            messageKey={messageKey}
            setDisplayContentMobile={setDisplayContentMobile}
          />
        )
      default:
        return (
          <MessagesContent
            allAktenLoaded={allAktenLoaded}
            accountName={accountName}
            mainColor={mainColor}
            accountId={accountId}
            akteIdHash={akteIdHash}
            folderId={folderId}
            messageKey={messageKey}
            permissionToReply={permissionToReply}
            secondaryColor={secondaryColor}
            serviceAkte={serviceAkte}
            customProps={customProps}
            scrollRef={scrollRef}
            loadMessageSensor={loadMessageSensorRef}
            messageFullLoaded={messageFullLoaded}
            shouldLoadWithOffset={shouldLoadWithOffset}
          />
        )
    }
  }

  return (
    <MessageDetailsDataLoader {...messageDetailsDataLoaderProps}>
      <RedirectDeletedMessage {...customProps}>
          <AufgabenLoader {...aufgabenLoaderProps}>
            {messageFullLoaded ? (
                <React.Fragment>
                    <MessageDetailsWrapper>
                      <MessageDetailsTopBar
                        accountId={accountId}
                        accountName={accountName}
                        header={header}
                        akteIdHash={akteIdHash}
                        metaInfo={metaMessage.metaInfo}
                        parentId={parentId}
                        aktenkurzbezeichnung={aktenkurzbezeichnung}
                        setDisplayContentMobile={setDisplayContentMobile}
                        displayContentMobile={displayContentMobile}
                        metaRootMessage={metaRootMessage}
                        menus={menus}
                        headerMenus={headerMenus}
                        setMenus={setMenus}
                        openAufgabeCount={aufgaben.filter((aufgabe) => aufgabe.hasAnswer === false && aufgabe.isClosed === false).length}
                        {...props}
                      />
                      {menus === 'overview' ? (
                        <Overview 
                          {...props} 
                          setMenus={setMenus}
                          aufgaben={aufgaben}
                          aufgabenIsLoading={aufgabenIsLoading}
                        />
                      ) : menus === 'messages' ? <Content />  : (
                        <div
                          style={{
                            overflow: 'auto',
                            height: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                              padding: '10px 40px 20px 50px',
                              width: '100%',
                            }}
                          >
                            {filterMenus.map((filter, index) => (
                              <button
                                key={index}
                                style={{
                                  border: '1px solid #000',
                                  background:
                                    selectedFilter === index ? '#000' : 'none',
                                  cursor: 'pointer',
                                  color:
                                    selectedFilter === index ? '#ffffff' : '#000000',
                                  fontSize: '14px',
                                  borderRadius: '5px',
                                  padding: '5px 10px',
                                }}
                                onClick={() => setSelectedFilter(index)}
                              >
                                {filter}
                              </button>
                            ))}
                          </div>
                          <Aufgaben
                            selectedFilter={selectedFilter}
                            onSendFormRequest={onSendFormRequest}
                            openAccountStatement={openAccountStatement}
                            metaInfo={metaInfo}
                            selectedAkte={selectedAkte}
                            parentId={parentId}
                            accountId={accountId}
                            akteIdHash={akteIdHash}
                            onOpenGallery={onOpenGallery}
                            accountPublicInfo={accountPublicInfo}
                            loginAdHoc={loginAdHoc}
                            waitingScreen={waitingScreen}
                            sendSucceed={sendSucceed}
                            aufgaben={aufgaben}
                            aufgabenIsLoading={aufgabenIsLoading}
                            setRefreshAufgaben={setRefreshAufgaben}
                            onOpenGalleryThumbnail={onOpenGalleryThumbnail}
                          />
                        </div>
                      )}
                      {/* {menus === 1 ? (
                                        <Aufgaben onSendFormRequest={onSendFormRequest} openAccountStatement={openAccountStatement} metaInfo={metaInfo} selectedAkte={selectedAkte} accountId={accountId} akteIdHash={akteIdHash} />
                                    ) : menus === 2 ? (
                                        <Content />)
                                    : menus === 3 ? (
                                        'Ordner'
                                    ) : menus === 4 ? (
                                        'Datein'
                                    ) : 'Ubersicht'
                                    } */}
                    </MessageDetailsWrapper>
                </React.Fragment>
              ) : (
                akteIdHash !== undefined && (
                  <FlexGrid justifyCenter itemsCenter>
                    {accountPublicInfo && <Preloader3D />}
                  </FlexGrid>
                )
              )}
          </AufgabenLoader>
      </RedirectDeletedMessage>
    </MessageDetailsDataLoader>
  )
}

export default MessageDetailsComponent
