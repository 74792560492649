import { call, put, takeEvery } from '@redux-saga/core/effects'
import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { getFormElementsSucceed, populateFormElements, refreshFormElements, sendFormFailed, sendFormSucceed, updateResultTextForm } from 'application/redux/actions/form'
import { addNewSubMessage, addToLastSubMessage, hideWaitingScreen, showWaitingScreen } from 'application/redux/actions/waiting_screen'
import { accessTokenForm } from 'application/storage/access_token'
import { delay } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { FormPostProcessor } from '@ec/dynamic-form-postprocessors'
import { setThemeFromAccountPublicInfo } from './set_theme_from_account_public_info'
import { getPublicAccountInfo } from '../common/account_details/get_public_info_account'
import { getPublicFileInfo } from './get_public_file_info'
import { postAuthForm } from './post_auth_form'
import { getFormErrorSaga } from './get_form_error_saga'
import { FORMS } from 'application/constants'
import { urlRedirect } from 'application/storage'

function* preGetFormElementSaga(action) {
    let key = action.key
    if (action.metaData !== null && action.metaData.getLookUpFirst) {
        // get account id from kanzlei unique name
        const response = yield call(api.gateway.common.getAccountIdByAccountIdentify, action.key)
        // get workflow key from external request shortname
        const responseExternalKey = yield call(api.gateway.formAssistant.getWorkflowKey, response.accountId, action.metaData.externalRequestKey)
        key = responseExternalKey.workflowKey
    }
    const responseAuth = yield postAuthForm({...action, key})

    const responsePublicAccountInfo = yield getPublicAccountInfo(responseAuth)
    yield setThemeFromAccountPublicInfo(responsePublicAccountInfo)
    yield getPublicFileInfo(responseAuth)
    if (responseAuth.workflowType !== FORMS.EXTERNALREQUEST) {
        yield call(api.gateway.formAssistant.getAccessedProcess, responseAuth.workflowKey, responseAuth.accountId)
    }
}

function* getFormElementsRequestSaga(action) {
    try {

        urlRedirect.delete()
        yield preGetFormElementSaga(action)
        yield delay(500)
        const response = yield call(api.gateway.formAssistant.getFormElementsAssistant)
        yield put(populateFormElements(response))
        yield put(getFormElementsSucceed())
    } catch (e) {
        yield getFormErrorSaga(action, e)

        
        
    }
}

function* sendFormElementsRequestSaga(action) {
    try {
        const {formData, isAufgabe, workflowKey} = action

        const apiUrl = {
            form: isAufgabe ? `assistants/process?workflowKey=${workflowKey}`: 'assistants/process',
            fileUpload: 'files/upload'
        }

        const postProcessor = new FormPostProcessor(formData, accessTokenForm.get(), process.env.REACT_APP_API_URL_BASE, ['checkbox_agb', 'checkbox_datenschutz'], 'v2.0', apiUrl).processForm()

        yield postProcessor.uploadFiles(
            function* () { yield put(showWaitingScreen('Uploading files')) },
            function* (file) { yield put(addNewSubMessage(file.metaData.name + ' ... ')) },
            function* () { yield put(addToLastSubMessage('✓')) }
        )
            
        yield postProcessor.sendForm(
            function* (response) {
                yield put(updateResultTextForm(response.resultText))
                yield put(hideWaitingScreen())
                yield put(sendFormSucceed())
            }
        )
    } catch (e) {
        yield put(sendFormFailed())
        yield handleError(e, action)
        yield put(hideWaitingScreen())
        yield put(refreshFormElements())
    }
}

export function* formAssistantWatcher() {
    yield takeEvery(ACTIONS.FORM_GET_FORM_ELEMENTS_REQUEST, getFormElementsRequestSaga)
    yield takeEvery(ACTIONS.FORM_SEND_REQUEST, sendFormElementsRequestSaga)
}