import { connect } from 'react-redux'
import TreeComponent from './component'

const mapStateToProps = state => ({
    selectedFolder: state.pages.akten.common.selectedFolder,
    breadcrumbsData: state.pages.akten.common.breadcrumbsData,
})

const mapDispatchToProps = dispatch => ({})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TreeComponent)