import dayjs from 'dayjs'
import i18next from 'i18next'

export const calculateTimeLeft = timeGiven => {
    let convTime = dayjs(timeGiven)
    let difference = convTime.diff(dayjs())

    let timeLeft = {}
    
    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toLocaleString(i18next.language, {
                minimumIntegerDigits: 2,
                useGrouping: false
            }),
            minutes: Math.floor((difference / 1000 / 60) % 60).toLocaleString(i18next.language, {
                minimumIntegerDigits: 2,
                useGrouping: false
            }),
            seconds: Math.floor((difference / 1000) % 60).toLocaleString(i18next.language, {
                minimumIntegerDigits: 2,
                useGrouping: false
            })
        }
    }

    return timeLeft
}