import { requestDataAccountPublicInfoInternalAssets } from 'application/redux/actions/common/account_public_info_internal_assets'
import { refreshGetFormElements, refreshReturnUrlForm, refreshSendForm, setReturnUrlForm } from 'application/redux/actions/form'
import { refreshMessageFavorite } from 'application/redux/actions/messages'
import { connect } from 'react-redux'
import AktenDetailSidebarComponent from './component'

const mapStateToProps = (state, props) => {
    const accountId = parseInt(props.metaInfo.accountId)
    const accountPublicInfoInternal =
        state.common.accountPublicInfoInternalAssets.filter((a) => a.accountId === accountId).length > 0
            ? state.common.accountPublicInfoInternalAssets.find((a) => a.accountId === accountId)
            : null
    return {
        userFirstName: state.auth.common.user.firstName,
        userLastName: state.auth.common.user.lastName,
        email: state.auth.common.user.email,
        filesRegistered: state.pages.akten.action.newFiles.succeed,
        selectedAkte: state.pages.akten.common.selectedAkte,
        allAccountsLoaded: state.common.allAccounts.isLoaded,
        auth: state.auth,
        accountId,
        accountPublicInfoInternal,
        sendFormSucceed: state.form.send.succeed,
        formReturnUrl: state.form.returnUrl,
        formLoaded: state.form.succeed,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getPublicInfoAccountInternalAssets: (accountId) => dispatch(requestDataAccountPublicInfoInternalAssets(accountId)),
    setFormReturnUrl: (url) => dispatch(setReturnUrlForm(url)),
    refreshForm: () => {
        dispatch(refreshReturnUrlForm())
        dispatch(refreshSendForm())
        dispatch(refreshGetFormElements())
    },
    refreshFavoriteMessages: () => dispatch(refreshMessageFavorite()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AktenDetailSidebarComponent)
