import { connect } from 'react-redux'
import FilesSidebarComponent from './component'

const mapStateToProps = state => ({
    filesRegistered: state.pages.akten.action.newFiles.succeed
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilesSidebarComponent)