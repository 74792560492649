import { FlexGrid, PushBottom30 } from 'application/components/fragments/grid'
import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getRanges } from 'application/common/time_helpers'
import Moment from 'moment'
import styled from 'styled-components'
import { WrapperFilesSidebarSidePadd } from '../fragments'
import { ThumbnailContainer } from 'application/components/building_blocks/attachments/thumbnail'
import { useParams } from 'react-router-dom'

const StyledRangeHeader = styled.h3`
    color: ${(props) => props.theme.color.color70};
    font-size: ${(props) => props.theme.fontSize.medium};
    font-weight: ${(props) => props.theme.fontWeight.standardBold};
`

const DisplayFilterTime = (props) => {
    const { thumbnailsNoPdf, akteIdHash, parentId, onOpenGallery } = props
    let params = useParams()
    let existedThumbnail = []

    const [expanded, setExpanded] = useState(false)
    const useRanges = getRanges()
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <WrapperFilesSidebarSidePadd height='70vh' noPadd maxHeight='100%' isoverflow={true}>
            <PushBottom30 />
            {useRanges.map((ur, i) => {
                return (
                    thumbnailsNoPdf.some((t) => ur.range.contains(new Moment(t.creationDate))) &&
                    thumbnailsNoPdf.filter((t) => !existedThumbnail.includes(t.id)).length > 0 && (
                        <React.Fragment key={i}>
                            <Accordion expanded={expanded === ur.headerKey} onChange={handleChange(ur.headerKey)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${ur.headerKey}bh-content`}
                                    id={`${ur.headerKey}bh-header`}
                                >
                                    <StyledRangeHeader>{ur.headerKey}</StyledRangeHeader>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FlexGrid>
                                        {thumbnailsNoPdf
                                            .filter((t) => !existedThumbnail.includes(t.id))
                                            .map((s, i) => {
                                                const mom = new Moment(s.creationDate)
                                                return (
                                                    ur.range.contains(mom) &&
                                                    existedThumbnail.push(s.id) && (
                                                        <ThumbnailContainer
                                                            key={i}
                                                            fileId={s.id}
                                                            fileName={s.fileName}
                                                            messageId={s.messageId}
                                                            akteIdHash={akteIdHash}
                                                            parentId={parentId}
                                                            onAttachmentClick={() =>
                                                                onOpenGallery(
                                                                    s.id,
                                                                    s.fileName,
                                                                    params.accountId,
                                                                    parentId,
                                                                    akteIdHash,
                                                                    thumbnailsNoPdf
                                                                )
                                                            }
                                                            showCaption={true}
                                                            marginRight={false}
                                                            isSizeFixed={true}
                                                            creationDate={s.creationDate}
                                                            accountId={params.accountId}
                                                            thumbnailFirst
                                                        />
                                                    )
                                                )
                                            })}
                                    </FlexGrid>
                                </AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                    )
                )
            })}
        </WrapperFilesSidebarSidePadd>
    )
}

export { DisplayFilterTime }
export default DisplayFilterTime
