import React from 'react'
import { Fragment } from 'react'
import NotFoundPageBigfoot from '../../not_found/not_found_page_bigfoot'

const RedirectGetFormFailed = (props) => {
    const { formLoadedFailed, withPin, children } = props
    if (formLoadedFailed && !withPin) {
        return <NotFoundPageBigfoot />
    }
    
    return <Fragment>
        {children}
    </Fragment>
}

export default RedirectGetFormFailed
