import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { StyledButtonWrapper } from './fragments'

const StyledButtonNavLink = styled(NavLink)`
    padding: 0px;
    margin: 0px;
    border: none;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: ${props => props.theme.color.color0};
    width:${props => props.strechted ? '100%' : 'auto'};
    text-decoration: none;
    font-family: ${props => props.theme.fontFamily.secondary};

`

const ActionButtonNavlink = ({
    buttonText,
    className,
    onButtonClick,
    strechted = false,
    disabled = false,
    secondary = false,
    noBackground = false,
    noPadding = false,
    linkTo = '',
    color = '#fff',
    fontSize = '18px',
    height,
    borderradius,
    backgroundColor,
    outline = false,
    borderColor = ''
}) => {

    return disabled ? <StyledButtonWrapper
        borderradius={borderradius}
        height={height}
        color={color}
        fontSize={fontSize}
        backgroundColor={backgroundColor}
        noBackground={noBackground}
        secondary={secondary}
        className={className}
        outline={outline}
        borderColor={borderColor}
        noPadding={noPadding}
        disabled={disabled}>
        {buttonText}
    </StyledButtonWrapper> : <StyledButtonNavLink onClick={onButtonClick} to={linkTo} strechted={strechted}>
        <StyledButtonWrapper
            borderradius={borderradius}
            height={height}
            color={color}
            fontSize={fontSize}
            noBackground={noBackground}
            backgroundColor={backgroundColor}
            secondary={secondary}
            className={className}
            outline={outline}
            borderColor={borderColor}
            noPadding={noPadding}
            disabled={disabled}>
            {buttonText}
        </StyledButtonWrapper>
    </StyledButtonNavLink>
}


export default ActionButtonNavlink