import { updateSingleAkte } from 'application/redux/actions/common/all_akten'
import { updateDataOnMessage } from 'application/redux/actions/messages'
import { setSelectedAkte } from 'application/redux/actions/pages/akten'
const { api } = require('application/api')
const { call, put } = require('redux-saga/effects')

export function* getSingleAkteSaga(action) {
    const resultSingleAkte = yield call(api.gateway.pages.getSingleAkte, action.accountId, action.akteIdHash)
    yield put(updateDataOnMessage(action.messageKey, {rootInfo: resultSingleAkte}))
    yield put(updateSingleAkte(action.akteIdHash, resultSingleAkte))

    yield put(setSelectedAkte(resultSingleAkte))

    return resultSingleAkte
}
