import ACTIONS from 'application/constants'

export const refreshMessageOnRoot = () => ({
    type: ACTIONS.PAGES_AKTEN_REFRESH_MESSAGE_ON_ROOT,
})

export const refreshMessageOnFolder = () => ({
    type: ACTIONS.PAGES_AKTEN_REFRESH_MESSAGE_ON_FOLDER,
})

export const getMessageDetails = (accountId, akteIdHash ,params, messageKey, messageType) => ({
    type: ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS,
    accountId,
    akteIdHash,
    params,
    messageKey,
    messageType
})

export const getMessageDetailsFailed = () => ({
    type: ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS_FAILED,
})

export const populateFavoriteMessages = (payload) => ({
    type: ACTIONS.PAGES_AKTEN_POPULATE_FAVORITE_MESSAGES,
    payload
})

export const populateMessages = (payload, count, rest) => ({
    type: ACTIONS.PAGES_AKTEN_POPULATE_MESSAGES,
    payload,
    count,
    rest
})


export const populateMessagesOnFolder = (payload, count) => ({
    type: ACTIONS.PAGES_AKTEN_POPULATE_MESSAGES_ON_FOLDER,
    payload,
    count
})

export const refreshMessageDetails = () => ({
    type: ACTIONS.PAGES_AKTEN_REFRESH_MESSAGES_DETAILS
})


export const getMessageDetailsOnFolder = (accountId, akteIdHash, folderId, params, messageKey, rootKey = '') => ({
    type: ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS_ON_FOLDER,
    accountId,
    akteIdHash,
    folderId,
    params,
    messageKey,
    rootKey
})


export const populateFilesOnQueue = (files) => ({
    type: ACTIONS.PAGES_AKTEN_POPULATE_FILES_ON_QUEUE,
    files
})

export const populateFilesToSend = (files) => ({
    type: ACTIONS.PAGES_AKTEN_POPULATE_FILES_TO_SEND,
    files
})

export const deleteFilesonQueueAktenById = (fileId) => ({
    type: ACTIONS.PAGES_AKTEN_DELETE_FILE_ON_QUEUE_BY_ID,
    fileId
})

export const deleteFilesToSendAktenById = (fileId) => ({
    type: ACTIONS.PAGES_AKTEN_DELETE_FILE_TO_SEND_BY_ID,
    fileId
})

export const addFilesOnQueue = (files) => ({
    type: ACTIONS.PAGES_AKTEN_ADD_FILES_ON_QUEUE,
    files
})

export const clearAllSendToFiles = () => ({
    type: ACTIONS.PAGES_AKTEN_CLEAR_ALL_FILES_TO_SEND
})

export const clearAllFIlesOnQueue = () => ({
    type: ACTIONS.PAGES_AKTEN_CLEAR_ALL_FILES_ON_QUEUE
})


export const messageRead = (messageId) => ({
    type: ACTIONS.PAGES_AKTEN_MESSAGE_READ_CONDITION,
    messageId
})


export const putMessageAsFavorite = (messageId, condition) => ({
    type: ACTIONS.PAGES_AKTEN_PUT_MESSAGE_AS_FAVORITE,
    messageId,
    condition
})