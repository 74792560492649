import React from 'react'
import {
  FlexGrid,
  FlexGridItem,
  PushBottom10,
} from 'application/components/fragments/grid'
import * as Fragments from '../fragments'
import { isMobile } from 'react-device-detect'
import AttachmentViewerHoc from 'application/components/building_blocks/attachments/attachment_viewer'
import { accessTokenAdhoc, accessTokenForm } from 'application/storage/access_token'
import { Fragment } from 'react'
import { fileHelpers, textHelpers } from 'application/common'
import { ThumbnailContainer } from 'application/components/building_blocks/attachments/thumbnail'
import styled from 'styled-components'
import default_store from 'application/redux/reducers/default_store'

const StyledCaption = styled.div`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  margin: 0;
  max-width: 100%;
  text-align: center;
`

const FullContentAsistentNotice = (props) => {
    const { formData, showAssistantImage, downloadAttachment, downloadAttachmentAdHoc, formMetaData, onOpenGallery, onOpenGalleryThumbnail } = props
    const coreMetaData = formMetaData !== null && formMetaData.core
    return <Fragment>
        {
            isMobile ? <FlexGridItem itemsCenter justifyCenter grow={1} shrink={0}>
                <Fragments.AssistantNoticeBannerText textAlign='center'>
                    {formData.assistantNotice}
                </Fragments.AssistantNoticeBannerText>
            </FlexGridItem> : <FlexGridItem itemsCenter grow={1} shrink={0}>
                <Fragments.AssistantNoticeBannerText textAlign='center'>
                    {formData.assistantNotice}
                </Fragments.AssistantNoticeBannerText>
            </FlexGridItem>
        }
        <PushBottom10 />
        {
            formData.attachments.length < 2 && showAssistantImage ? formData.attachments.map((a, i) => {
                const truncateFileName = textHelpers.truncateMiddleText(a.fileName, 20)
                const includeShowThumbnail = process.env.REACT_APP_SUPPORTED_ATTACHMENT_EXTENSIONS.includes(fileHelpers.getFileExtension(a.fileName))
                return (
                  <FlexGrid key={i} justifyCenter itemsCenter directionColumn>
                    <AttachmentViewerHoc
                      widthFixed={isMobile ? '150px' : '200px'}
                      heightFixed={
                        isMobile
                          ? !includeShowThumbnail
                            ? '200px'
                            : '150px'
                          : '200px'
                      }
                      withHeadline={true}
                      fontSizeHeadline='10px'
                      withDownload={true}
                      onAttachmentDownload={() => {
                        downloadAttachmentAdHoc(
                          a.id,
                          a.fileName,
                          coreMetaData.accountId
                        )
                      }}
                      storage={accessTokenAdhoc}
                      fileId={a.id}
                      fileName={a.fileName}
                      initiallyLoad={true}
                      isSizeFixed={true}
                      onFullFileClick={() =>
                        onOpenGallery(a.id, a.fileName, coreMetaData.accountId)
                      }
                    //   onFullFileClick={() =>
                    //     alert('test')
                    //   }
                      metaData={{
                        accountId: coreMetaData.accountId,
                      }}
                    />
                    <StyledCaption>{truncateFileName}</StyledCaption>
                  </FlexGrid>
                )
                        
            })
                : <FlexGrid justifyCenter>
                    {
                        formData.attachments.map((s, i) => {
                            return <ThumbnailContainer
                                key={i}
                                fileId={s.id}
                                fileName={s.fileName}
                                onAttachmentClick={() => onOpenGalleryThumbnail(s.id, s.fileName, coreMetaData.accountId, '', '', formData.attachments)}
                                marginRight={false}
                                showCaption={true}
                                storage={accessTokenAdhoc}
                                thumbnailFirst={true}
                                accountId={coreMetaData.accountId}
                            />
                        })
                    
                    }
                </FlexGrid>
        }
    </Fragment>
}

const AsistantNoticeSection = (props) => {
  const { themeParentApplication, showAssistantImage, formData } = props
  return (
    <Fragments.AssistantNoticeWrapper isMobile={isMobile}>
      <FlexGridItem justifyCenter width='80%'>
        <Fragments.AssistantNoticeBannerContainer
          // borderColor={themeParentApplication.buttonBackground}
          // backgroundColor={themeParentApplication.formContainerBackground}
          borderColor={'#d9d9d9'}
          backgroundColor={'#ffffff'}
        >
          {!showAssistantImage ? (
            <FlexGridItem
              width='100%'
              itemsCenter
              justifyCenter
              grow={0}
              shrink={0}
            >
              <Fragments.AssistantNoticeBannerText textAlign='center'>
                {formData.assistantNotice}
              </Fragments.AssistantNoticeBannerText>
            </FlexGridItem>
          ) : !isMobile ? (
            <FlexGrid directionColumn justifyCenter itemsCenter>
              <FullContentAsistentNotice {...props} />
            </FlexGrid>
          ) : (
            <FlexGrid justifyCenter>
              <FullContentAsistentNotice {...props} />
            </FlexGrid>
          )}
        </Fragments.AssistantNoticeBannerContainer>
      </FlexGridItem>
    </Fragments.AssistantNoticeWrapper>
  )
}

export default AsistantNoticeSection
