import React, { useEffect } from 'react'

const ForgotUsernameEffects = (props) => {
    const { auth, refreshForgotUsername, children } = props
    const usernameReqSucceed = auth.forget.username.succeed
    useEffect(() => {
        usernameReqSucceed && refreshForgotUsername()
    }, [refreshForgotUsername, usernameReqSucceed])

    return <React.Fragment>
        {children}
    </React.Fragment>
}

export default ForgotUsernameEffects