import React from 'react'
import { AboutSettingForm } from '../../form'
import ContentSettingLayout from '../common/layout'
import { useTranslation } from 'react-i18next'

const AboutSettingComponent = (props) => {
    const {t} = useTranslation()

    return <ContentSettingLayout
        headline={t('About e.sy One')}
        subHeadline={t('We are happy for your feedback!')}
        // description='et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum'
    >
        <AboutSettingForm {...props} />
    </ContentSettingLayout>
}

export default AboutSettingComponent