import { activateAccountRequest } from 'application/redux/actions/common/account_details'
import { connect } from 'react-redux'
import ActivateProviderMobileScreen from './component'

const mapStateToProps = (state) => ({
    activateRequest: state.common.accountDetails.activate.request,
    agbAndDatenschutzAccepted: state.common.agbDatenschutz.isAccepted,
    checkedAccountId: state.common.agbDatenschutz.accountId,
})

const mapDispatchToProps = dispatch => ({
    activateAccount: (accountId, payload) => {dispatch(activateAccountRequest(accountId, payload))}
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivateProviderMobileScreen)