import { textHelpers, validationMethods } from 'application/common'
import { Form, TextInputWithLabel } from 'application/components/controls/form_elements'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { ErrorLabel } from 'application/components/fragments/error_information'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import { CONSENT } from 'application/constants'
import { nanoid } from 'nanoid'
import React from 'react'
import { Fragment } from 'react'
import { isMobile, isMobileOnly, isTablet, withOrientationChange } from 'react-device-detect'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRouteLocationForm } from '../../context/use_route_location_form_context'
import * as Fragments from '../../fragments'

const FormPinSection = (props) => {
    const { formLoading, loginAttempt, isLandscape, getFormAsssistant, setConsent, consentUseApp, formLoadedFailed, responseInfo, flexGrow, flexShrink, flexBasis } = props
    const defaultValues = {
        pin: '',
    }

    const { register, formState: { errors }, handleSubmit, getValues } = useForm({ defaultValues })
    const validationInput = {
        notEmpty: validationMethods.notEmpty
    }
    const { valueParams, ...rest } = useRouteLocationForm()
    const onSubmitSuccess = () => {
        getFormAsssistant(valueParams.key, getValues().pin, loginAttempt, { valueParams, ...rest })
        !consentUseApp.accept && setConsent(textHelpers._enText(CONSENT.ACCEPT_TEXT + new Date()))
    }
    const { t } = useTranslation()
    return <FlexGridItem grow={flexGrow} shrink={flexShrink} basis={flexBasis}>
        <Fragments.LoginFormContainer isMobile={isMobile} isTablet={isTablet && isLandscape}>
            <FlexGrid directionColumn noWrap justifyBetween>
                {!isMobileOnly && <Fragment>
                    <Fragments.TitleLogin>
                        {t('Login')}
                    </Fragments.TitleLogin>
                    <PushBottom24 />
                    <PushBottom24 />
                </Fragment>}
                {
                    formLoading ? <PreloaderSpin /> :
                        <React.Fragment>
                            <Form onSubmit={handleSubmit(onSubmitSuccess)}>
                                <TextInputWithLabel
                                    placeholder={t('pin')}
                                    name='pin'
                                    register={register}
                                    validate={validationInput}
                                    error={errors.pin}
                                    showMarkLabel={false}
                                    pushBottom={true}
                                    htmlId={nanoid()}
                                />
                                <Fragments.StyledButtonInput>
                                    <input type='submit' value={t('login')} />
                                </Fragments.StyledButtonInput>
                            </Form>
                            {
                                formLoadedFailed && loginAttempt !== 1 && <React.Fragment>
                                    <ErrorLabel>
                                        {t(responseInfo.reasonMessage)}
                                    </ErrorLabel>
                                </React.Fragment>
                            }
                        </React.Fragment>

                }
                <PushBottom24 />
            </FlexGrid>
        </Fragments.LoginFormContainer>
    </FlexGridItem>
}

export default withOrientationChange(FormPinSection)
