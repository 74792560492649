import React from 'react'
import DialogLoaded from './_loaded'
import DialogDefault from './_default'

const DatenshutzDialog = (props) => {
    const { dialog } = props
    const { isLoaded } = dialog
    return isLoaded ? <DialogLoaded {...props} /> : <DialogDefault {...props} />

}

export default DatenshutzDialog