import { api } from 'application/api'
import ACTIONS, { NOTIFICATION } from 'application/constants'
import { ERROR_TYPE_CODE } from 'application/constants/errors'
import { postFilesFailed, postFilesSucceed } from 'application/redux/actions/files'
import { hideGalleryOnQueue, sendFilesMessageFailed, sendFilesMessageSucceed } from 'application/redux/actions/gallery'
import { showToast } from 'application/redux/actions/notifications'
import {
    populateFilesToSend
} from 'application/redux/actions/pages/akten'
import { addNewSubMessage, addToLastSubMessage, hideWaitingScreen, showWaitingScreen } from 'application/redux/actions/waiting_screen'
import { call, put, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../errors'

function* postFilesFromMessageSaga(action) {
    yield put(showWaitingScreen('Uploading files'))
    for (const file of action.files) {
        try {
            yield put(addNewSubMessage(file.metaData.name + ' ... '))
            const postFilesResponse = yield call(api.gateway.files.postFilesByAccountId, action.accountId, file.metaData)
            yield put(addToLastSubMessage('✔️'))
            yield put(populateFilesToSend(postFilesResponse.files[0]))
        } catch (e) {
            if (ERROR_TYPE_CODE.UPLOAD_VIRUS === e.response.status) {
                yield put(showToast('Virus detected, but we are vaccinated 🦠', NOTIFICATION.LEVEL.ERROR))
                yield put(addToLastSubMessage('❌'))
            } else {
                yield handleError(e)
                yield put(hideWaitingScreen())
                yield put(sendFilesMessageFailed())
                yield put(hideGalleryOnQueue())
            }
        }
    }
    yield put(sendFilesMessageSucceed())
    yield put(hideGalleryOnQueue())
    yield put(hideWaitingScreen())
}

function* postFilesSaga(action) {
    try {
        for (const file of action.files) {
            yield put(addNewSubMessage(file.metaData.name + ' ... '))
            yield call(api.gateway.files.postFiles, file.metaData)
            yield put(addToLastSubMessage('✓'))
        }
        yield put(postFilesSucceed())
    } catch (e) {
        yield handleError(e, action)
        yield put(postFilesFailed())
    }
}

export function* postFilesWatcher() {
    yield takeEvery(ACTIONS.GALLERY_SEND_FILES_MESSAGE_FROM_GALLERY_ON_QUEUE, postFilesFromMessageSaga)
    yield takeEvery(ACTIONS.FILES_POST_FILES_REQUEST, postFilesSaga)
}