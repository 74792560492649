import ChatBubble from 'application/components/building_blocks/chat_bubble'
import { FlexGrid, PushBottom10 } from 'application/components/fragments/grid'
import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { Element } from 'react-scroll'
import DividerText from 'application/components/controls/divider/divider_text'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
// import MessageWrapperReadEffects from './read_effects'
import MessagesScrollEffect from './effect_scroll_messages'
import { useTranslation } from 'react-i18next'
import { AKTEN } from 'application/constants'
import { useRef } from 'react'
import { scrollSession } from 'application/storage/scroll_session'
import { PluginAlternateView } from 'plugins'

const isOverflown = (element) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
}

export const WrapperMessageDetails = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 3.2;
    overflow-y: auto;
    scroll-behaviorr:smooth;
    padding-bottom: 70px;

    & h3:nth-child(odd) {
        display: flex;
        justify-content: flex-end;
    }
`

const BubbleWrapper = styled.div`
    width: 100%;
`

const NoMessagePlaceholder = styled.div`
    display: flex;
    color: ${(p) => p.theme.color.anthacite50};
    border-radius: 18px;
    text-align: center;
    padding: 15px;
    justify-content: center;
    align-items: center;
    width: 40%;
    flex-direction: column;
    font-size: ${(p) => p.theme.fontSize.standard};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    word-break: break-word;
`

const WrapperMessagesComponent = (props) => {
    const { 
        metaInfo, 
        theme, 
        akteIdHash, 
        folderId, 
        messages, 
        favoriteId, 
        messageKey, 
        displayContentMobile, 
        scrollRef, 
        loadMessageSensor, 
        messagesTotalCount, 
        messageFullLoaded, 
        shouldLoadWithOffset
    } = props

    const parentId = folderId !== '' ? folderId : akteIdHash
    let countDiv = 0
    let payloadMobile = displayContentMobile?.payload
    let favid = payloadMobile?.favoriteId !== undefined ? payloadMobile.favoriteId : ''
    const { t } = useTranslation()
    const messagesEndRef = useRef(null)

    return (
        <MessagesScrollEffect messagesEndRef={messagesEndRef} handleScroll={scrollRef} messages={messages} favoriteId={favid}>
            <WrapperMessageDetails isOverflown={isOverflown(WrapperMessageDetails)} id={AKTEN.CONTAINER_ID.MESSAGES_WRAPPER} ref={scrollRef} onScroll={(e) => {
                scrollSession.store(e.target.scrollTop + 150)
            }}>
                
                {(messages.length != messagesTotalCount && !shouldLoadWithOffset && messageFullLoaded) ?
                    <div ref={loadMessageSensor}></div> : null
                }
                
                {!messages?.length > 0 && (
                    <PluginAlternateView id='messages.empty_message'>
                        <FlexGrid justifyCenter>
                            <NoMessagePlaceholder>
                                    <ImagePlaceHolder source='/assets/images/no_messages.png' large='150px' withBorder={false} />
                                    {t('EmptySharedMessageContent')}
                            </NoMessagePlaceholder>
                        </FlexGrid>
                    </PluginAlternateView>

                )}

                <BubbleWrapper>
                    <FlexGrid noWrap directionColumn>
                        {messages.map((m, i) => {
                            countDiv = !m.read ? countDiv + 1 : countDiv
                            return (
                                <React.Fragment key={i}>
                                    {/* DIVIDER NOT READ */}
                                    {!m.read && countDiv < 2 && (
                                        <React.Fragment>
                                            <PushBottom10 /> <DividerText title={t('UnreadMessage')} />{' '}
                                        </React.Fragment>
                                    )}
                                    <Element name={m.id} />
                                    <ChatBubble
                                        chatId={m.id}
                                        messageDetail={m}
                                        messageId={m.id}
                                        mainColor={metaInfo === null ? theme.color.primary : metaInfo.mainColor}
                                        accountId={metaInfo === null ? 0 : metaInfo.accountId}
                                        akteIdHash={akteIdHash}
                                        parentId={parentId}
                                        isFavoriteSelected={favoriteId === m.id}
                                        messageKey={messageKey}
                                    />
                                </React.Fragment>
                            )
                        })}
                    </FlexGrid>
                    <div ref={messagesEndRef} />
                </BubbleWrapper>
            </WrapperMessageDetails>
        </MessagesScrollEffect>
    )
}

export default withTheme(WrapperMessagesComponent)
