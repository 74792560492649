import React from 'react'
import ServiceProviderSidebarComponent from './component'

const ServiceProviderSidebarEffects = (props) => {
    //import props

    //useEffect

    //condition

    //preloader

    return <ServiceProviderSidebarComponent {...props} />
}

export default ServiceProviderSidebarEffects