import { showGalleryOnQueue } from 'application/redux/actions/gallery'
import { clearAllSendToFiles, deleteFilesToSendAktenById, populateFilesOnQueue, sendMessageAkte } from 'application/redux/actions/pages/akten'
import { putMessageOnQueue } from 'application/redux/actions/messages'
import { connect } from 'react-redux'
import InputMessageComponent from './component'
import { clearAllFIlesOnQueue } from 'application/redux/actions/pages/akten/details'
import { closeDialogByName, openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => ({
    filesToSend: state.pages.akten.details.filesToSend,
    filesOnQueue: state.pages.akten.details.filesOnQueue,
    userFirstName: state.auth.common.user.firstName,
    userLastName: state.auth.common.user.lastName,
    userId: state.auth.common.user.userId,
    sendMessageRequest: state.pages.akten.action.sendMessage.request,
    sendMessageRequestId: state.pages.akten.action.sendMessage.id,
})

const mapDispatchToProps = dispatch => ({
    clearFileToSend: (id) => { dispatch(deleteFilesToSendAktenById(id))},
    openGalleryOnQueue: (files, accountId) => {
        dispatch(populateFilesOnQueue(files))
        dispatch(showGalleryOnQueue(null, null, null, accountId, null))
    },
    createMessage: (payload, metaData, errorMessage, params, idOnQueue, messageOnQueue, messageKey) => {
        dispatch(clearAllSendToFiles())
        dispatch(clearAllFIlesOnQueue())
        dispatch(sendMessageAkte(payload, metaData, errorMessage, params, idOnQueue, messageKey))
        dispatch(putMessageOnQueue(messageKey, messageOnQueue))
        // dispatch(registerMessageQueue(messageKey, idOnQueue, payload, metaData))
    },
    openSendMessageDialog: (props, title) => {
        dispatch(openDialogByName(DIALOG.NAME.SEND_MESSAGE, true, props, title))
    },
    closeSendMessageDialog: () => {
        dispatch(closeDialogByName(DIALOG.NAME.SEND_MESSAGE))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputMessageComponent)