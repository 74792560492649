import IconLink from 'application/components/controls/icon/icon_link'
import { PushBottom30 } from 'application/components/fragments/grid'
import { DASHBOARD, ROUTES } from 'application/constants'
import React from 'react'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import * as Fragment from './fragments'
import { PluginAlternateView } from 'plugins'
import { useTheme } from 'styled-components'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const NavInternal = ({ onLogout, refreshCommon }) => {
    let location = useLocation()
    let params = useParams()
    const {t} = useTranslation()
    const theme = useTheme()
    const {activePlugin, config} = usePluginsContext()
    
    return <React.Fragment>
        <Fragment.NavigationWrapper>
            {
                !config.disableDashboard &&
                <IconLink
                    to={ROUTES.DASHBOARD}
                    iconKey='home'
                    isActive={location.pathname === ROUTES.DASHBOARD}
                    title='Dashboard'
                    label='Dashboard'
                    onClick={refreshCommon}
                    textTransformLabel={activePlugin ? 'none' : 'uppercase'}
                    // positionLabel={POSITION_LABEL_ICON.RIGHT}
                />
            }
            <PushBottom30 /> 
            <IconLink
                to={ROUTES.AKTEN}
                iconKey={theme.navigationMessagesIconKey}
                isActive={location.pathname === ROUTES.AKTEN || params.akteIdHash !== undefined}
                title={t('MessagesNavigationBar')}
                label={t('MessagesNavigationBar')}
                onClick={refreshCommon}
                textTransformLabel={activePlugin ? 'none' : 'uppercase'}
                
                // positionLabel={POSITION_LABEL_ICON.RIGHT}
            />
            <PushBottom30 /> 
            <IconLink
                to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.ACCOUNT}
                iconKey='settings'
                isActive={location.pathname === ROUTES.SETTINGS || params.selectedSetting !== undefined}
                title={t('Settings')}
                label={t('Settings')}
                onClick={refreshCommon}
                textTransformLabel={activePlugin ? 'none' : 'uppercase'}


                // positionLabel={POSITION_LABEL_ICON.RIGHT}
            />
            {
                isTablet &&
                <React.Fragment>
                    <PushBottom30 />
                    <IconLink
                        iconKey='exit_to_app'
                        onClick={() => onLogout(true)}
                        title={t('logout')}
                    />
                </React.Fragment>
            }
        </Fragment.NavigationWrapper>
        <Fragment.LogoutWrapper>
            {
                !isTablet &&
                <IconLink
                    iconKey='exit_to_app'
                    onClick={() => onLogout(true)}
                    title={t('logout')}
                />
            }
        </Fragment.LogoutWrapper>
    </React.Fragment>
}

export default NavInternal