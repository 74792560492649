import { useQuery } from 'application/components/hooks'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import ServiceProviderCardActive from './active'
import ServiceProviderEffects from './effects'
import { generateLinksServiceProvider } from './helpers'
import ServiceProviderCardNotActive from './not_active'

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    width: 33%;
    height: 100%;
`

const ServiceProviderCard = (props) => {
    const { accountDetail } = props
    let location = useLocation()
    const { isActivated } = accountDetail
    const query = useQuery()
    const link = generateLinksServiceProvider(location, query, accountDetail.id)
    return <ServiceProviderEffects {...props}>
        <StyledNavLink to={link}>
            {isActivated ? <ServiceProviderCardActive link={link} {...props} /> : <ServiceProviderCardNotActive link={link} {...props} />}
        </StyledNavLink>
    </ServiceProviderEffects> 
}

export default ServiceProviderCard
