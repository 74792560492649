import React, { useState } from 'react'
import styled from 'styled-components'
import ImageWithLabel from 'application/components/controls/image_with_label'


const StyledLanguageSwitchButton = styled.div`
    cursor: pointer;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
`

const StyledLanguageSwitchWrapper = styled.div`
    flex: 0 0 auto;
    width: ${p => p.stretched ? '100%' : 'auto'};
    display: flex;
    justify-content: ${props => props.justify !== undefined ? props.justify : 'flex-start'};
`

const StyledLanguageDisplay = styled.div`
    cursor: pointer;
    padding: 10px;
    position: relative;
    left: -10px;
`

const StyledLanguageSwitchOverlay = styled.div`
    position: relative;
    left: -10px;
    box-sizing: border-box;
    width: auto;
    max-width: 100%;
    flex: 0 0 auto;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    padding: 9px;
`

const LanguageSwitch = ({ currentLanguage, languages, onSwitchLanguages, switchCondition = false, justify, withLabel, stretched = true}) => {
    const [switchVisible, setSwitchVisible] = useState(switchCondition)
    const sortedLanguages = [currentLanguage, ...languages.filter(l => l.substring(0, 2) !== currentLanguage)]
    return <StyledLanguageSwitchWrapper stretched={stretched} justify={justify}>
        {
            switchVisible ?
                <StyledLanguageSwitchOverlay>
                    {
                        sortedLanguages.map((lang, idx) => {
                            const subStringLang = lang.substring(0, 2)
                            return <StyledLanguageSwitchButton
                                className={`jestButtonLanguage-${idx}-${subStringLang}`}
                                key={idx}
                                onClick={() =>
                                {
                                    onSwitchLanguages(subStringLang)
                                    setSwitchVisible(false)
                                }
                                }
                            >
                                <ImageWithLabel withLabel={withLabel} source={subStringLang} />
                            </StyledLanguageSwitchButton>
                        })
                    }
                </StyledLanguageSwitchOverlay> : <StyledLanguageDisplay className='jestButtonToSwitch' onClick={() => setSwitchVisible(true)}>
                    <ImageWithLabel withLabel={withLabel} source={currentLanguage} />
                </StyledLanguageDisplay>
                
        }
    </StyledLanguageSwitchWrapper>
}

export default LanguageSwitch