import config from './config'

const common = {
  color: {
    primary: '#e13582',
    secondary: '#438aaa',
    preloaderColor: '#438aaa',
    footerColor: '#438AAA',
    // backgroundColorPin: '#E135823E',
    // textColorPin: '#e13582',
    backgroundColorPin: '#F0F0F0',
    textColorPin: '#438aaa',
    success: '#438aaa',
    redDot: '#eb8302',
    dividerTitle: '#eb3b86',
    dividerWrapper: '#FEF1F7',
    policyTextColor: '#eb3b86',
    filterPill: '#eb8302',
    requestPin: 'black',
    gravatar: '#438aaa',
    anfrageTag: '#e13582',
    titlePin: '#438aaa',
    counterColor: '#e13582',
    checked: '#e13582',
  },

  fontFamily: {
    primary: 'Helvetica, sans-serif',
    secondary: 'Helvetica, sans-serif',
  },

  fontWeight: {
    bold: '700',
    semiBold: '700',
  },

  loginRightImage: `${config.prod.assetPath}/image_login.png`,
  companyIcon: `${config.prod.assetPath}/wourage.png`,
  companyName: 'wourage',
  title: 'wourage - Sei mutig',
  favicon: '/assets/images/wourage/favicon.png',
  dashboardMobileViewIconKey: 'mail',
}

export default {
  ...common,
  classes: {
    loginTitle: {
      color: common.color.secondary,
      fontFamily: common.fontFamily.primary,
    },
    succeedContainer:{
      justifyContent: 'start',
    },
    loginSubtitle: {
      fontFamily: common.fontFamily.secondary
    },
    loginButton: {
      background: '#e13582',
      fontFamily: common.fontFamily.secondary,
      borderRadius: '6px',
      border: 'none',
    },
    lawBannerText: {
      fontFamily: common.fontFamily.secondary,
    },
    lawBannerAgreement: {
      color: common.color.primary,
      fontFamily: common.fontFamily.secondary
    },
    forgotButton: {
      color: 'black'
    },
    wizardHeadline:{
      fontFamily: common.fontFamily.primary
    },
    wizardPar:{
      fontFamily: common.fontFamily.secondary,
    }
  }
}