import ACTIONS from 'application/constants'

export const loginAdHoc = (key) => ({
  type: ACTIONS.AUTH_ADHOC_ACCESS,
  key,
})

export const writeTokenUnregistered = (accessToken) => ({
  type: ACTIONS.AUTH_WRITE_ACCESS_TOKEN_UNREGISTERED,
  accessToken,
})

// export const loginRequestSuccessPin = () => ({
//     type: ACTIONS.AUTH_LOGIN_SUCCESS_PIN
// })

// export const loginRequestFailedPin = () => ({
//     type: ACTIONS.AUTH_LOGIN_FAILED_PIN
// })
