
export const urlRedirect = {
    store: (data) => {
        localStorage.removeItem('urlRedirect')
        localStorage.setItem('urlRedirect', data)
    },
    get: () => {
        const storageEntry = localStorage.getItem('urlRedirect')
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem('urlRedirect')
    }
}
