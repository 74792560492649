import React, { Fragment, useEffect } from 'react'

const AllAktenInOneAccountLoader = (props) => {
    const { allAccountsLoaded, getAllAktenInOneAccount, children, auth, selectedAccount } = props
    const accountId = selectedAccount.id
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    useEffect(() => {
        loggedIn && allAccountsLoaded && getAllAktenInOneAccount(accountId)
    }, [accountId, allAccountsLoaded, getAllAktenInOneAccount, loggedIn])

    return <Fragment>
        {children}
    </Fragment>
}

export default AllAktenInOneAccountLoader