export const PLUGIN_ACTIONS = {
    DEV_MODE: 'DEV_MODE',
    PROD_MODE: 'PROD_MODE',
    SET_LANG: 'SET_LANG',
    SET_ACTIVE_PLUGIN: 'SET_ACTIVE_PLUGIN',
    SET_USER_ACCOUNT_ID: 'SET_USER_ACCOUNT_ID'
}

export const APPLICATION_MODE = {
    PROD: 'prod',
    DEV: 'dev'
}