import { AKTEN } from 'application/constants'
import { scrollSession } from 'application/storage/scroll_session'
import React, { useEffect } from 'react'
import { scroller } from 'react-scroll'

const MessagesScrollEffect = (props) => {
    const { children, favoriteId, messagesEndRef, handleScroll, messages, scrolling } = props
    useEffect(() => {

        const scrollnum = scrollSession.get()
        if (favoriteId !== '') {
            scroller.scrollTo(favoriteId, {
                smooth: true,
                containerId: AKTEN.CONTAINER_ID.MESSAGES_WRAPPER,
                offset: 0,
                duration: 500,
                delay: 100,
            })
        } else {

            let scroll = handleScroll.current

            if (scrollnum === 0) {
                
                messagesEndRef.current?.scrollIntoView()

            } else {
                scroll.scrollTop = Math.floor(scrollnum)

            }

            // console.log('scroll', scroll.scrollHeight)
            // console.log('scroll1', scrollnum)
        }
    }, [favoriteId, messagesEndRef, messages, handleScroll])

    return <React.Fragment>{children}</React.Fragment>
}

export default MessagesScrollEffect
