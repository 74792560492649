import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import FormPinSection from './form'
import LogoLoginForm from './logo_login_form'

const LoginPinFormSection = (props) => {
    return <FlexGridItem maxWidth='40%'>
        <FlexGrid height='80vh' directionColumn>
            <LogoLoginForm />
            <FormPinSection {...props} />
        </FlexGrid>
    </FlexGridItem> 
}

export default LoginPinFormSection
