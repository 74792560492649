import { AKTEN } from 'application/constants'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RefreshRootMessagesEffects from './refresh_root_messages_effects'

const DataAkteLevelLoader = (props) => {
    const { allAccountsLoaded, accountId, akteIdHash, folderId, getMessageDetails, children, messageKey,shouldLoad, shouldLoadWithOffset,setShouldLoadWithOffset, allAktenLoaded, isMessageRequesting, isSendingMessage } = props
    let params = useParams()
    
    useEffect(() => {
        shouldLoad &&
            allAccountsLoaded &&
            allAktenLoaded &&
            accountId !== undefined &&
            akteIdHash !== undefined &&
            folderId === '' &&
            getMessageDetails(accountId, akteIdHash, params, messageKey, AKTEN.TYPE.ROOT)


    }, [accountId, allAccountsLoaded, akteIdHash, folderId, getMessageDetails, params, messageKey, shouldLoad, allAktenLoaded])
    useEffect(() => {
        !isMessageRequesting && 
        !isSendingMessage &&
        shouldLoadWithOffset &&
        allAccountsLoaded &&
        allAktenLoaded &&
        accountId !== undefined &&
        akteIdHash !== undefined &&
            getMessageDetails(accountId, akteIdHash, params, messageKey, AKTEN.TYPE.ROOT)

        setShouldLoadWithOffset(false)
    }, [shouldLoadWithOffset])
    return <RefreshRootMessagesEffects {...props}>{children}</RefreshRootMessagesEffects>
}

export default DataAkteLevelLoader
