import { openAkteSidebar, setSelectedAkte } from 'application/redux/actions/pages/akten'
import { populateBreadscrumbsData, setBreadscurmbsView, setFoldersView, setSelectedFolder } from 'application/redux/actions/pages/akten/common'
import { getCommonAllAktenRequest } from 'application/redux/actions/common/all_akten'
import { connect } from 'react-redux'
import MessageListComponent from './component'
import { updateDataSelectedMessageFolder, updateDataSelectedMessageRoot } from 'application/redux/actions/messages'
import { AKTEN } from 'application/constants'

const mapStateToProps = (state, props) => {
    const messageRootRegistered = state.messages.registered.filter((r) => r.key === props.rootKey && r.isFullLoaded).length > 0
    const messageFullLoaded = state.messages.registered.filter((r) => r.key === props.messageKey && r.isFullLoaded).length > 0
    const folders = messageRootRegistered ? state.messages.registered.find((r) => r.key === props.rootKey).folders : []
    return {
        akten: state.common.allAkten.payload,
        folders,
        messageFullLoaded,
        foldersView: state.pages.akten.common.foldersView,
        breadcrumbsView: state.pages.akten.common.breadcrumbsView,
        allAktenLoaded: state.common.allAkten.isLoaded,
        allAkten: state.common.allAkten.payload,
        allAccountsLoaded: state.common.allAccounts.isLoaded,
        allAccounts: state.common.allAccounts.payload,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getAllAkten: () => dispatch(getCommonAllAktenRequest()),
    setSelectedAkte: (payload, params, akteIdHash, accountId) => {
        dispatch(setSelectedAkte(payload))
        dispatch(updateDataSelectedMessageRoot(params, akteIdHash, accountId, payload))
        dispatch(setSelectedFolder(payload))
        dispatch(populateBreadscrumbsData([]))
        dispatch(setBreadscurmbsView(false))
        dispatch(openAkteSidebar(AKTEN.SIDEBAR.KEY.DETAILS))
    },
    refreshMessageOnRoot: () => {
        dispatch(setBreadscurmbsView(false))
    },
    openMessageExtendedData: (foldersView, breadcrumbsView) => {
        dispatch(setFoldersView(foldersView))
        dispatch(setBreadscurmbsView(breadcrumbsView))
    },
    setSelectedFolder: (payload, params, akteIdHash, accountId) => {
        dispatch(setSelectedFolder(payload))
        dispatch(updateDataSelectedMessageFolder(params, payload.id, akteIdHash, accountId, payload))
        dispatch(openAkteSidebar(AKTEN.SIDEBAR.KEY.DETAILS))
    },
    populateBreadcrumbsData: (payload) => {
        dispatch(populateBreadscrumbsData(payload))
        dispatch(setBreadscurmbsView(true))
    },
    refreshMessagesOnFolder: () => {},
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageListComponent)
