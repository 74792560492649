import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { Headline1 } from 'application/components/fragments/typography'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { useTheme } from 'styled-components'

export function MobileAssistantFormSucceed({resultText}) {
  const theme = useTheme()
  const {getAsset} = usePluginsContext()
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Headline1 style={{textAlign:'center'}}>
        {resultText}
      </Headline1>
      <a href='https://www.wourage.de' target='_blank' rel='noopener noreferrer' style={{ 
        cursor:'pointer',
        color: theme.color.primary,
        fontSize: '18px',
        marginTop: '20px'
      }}>
        www.wourage.de
      </a>
    </div>
  )
}
