import React from 'react'
import { withTheme } from 'styled-components'
import { AccountSettingForm } from '../../form'
import ContentSettingLayout from '../common/layout'
import { useTranslation } from 'react-i18next'

const AccountSettingComponent = (props) => {
    const {t} = useTranslation()
    return <ContentSettingLayout
        headline={t('Account Settings')}
    >
        <AccountSettingForm {...props} />
    </ContentSettingLayout>
}

export default withTheme(AccountSettingComponent)