import { IconButton } from 'application/components/controls/icon'
import React, { Fragment } from 'react'
import { withTheme } from 'styled-components'
import ProfilePicture from '../sections/profile_picture'
import SearchFieldTopBarSection from '../sections/search_field'
import { HeaderMobileInternalWrapper } from './fragments'

const TopBarMobile = ({ theme, openNavigationLinkSidebar, ...props }) => {
	return <Fragment>
	<HeaderMobileInternalWrapper>
        <IconButton onButtonClick={openNavigationLinkSidebar} withShadow iconKey='dashboard' iconSize={theme.fontSize.headline1} />
        <SearchFieldTopBarSection {...props} />
        <div>
            <ProfilePicture radiusGravatar='23px' isCircle={false} profileLarge='55px' profileHeight='55px' gravatarLarge='55' radius='23px'  {...props} />
        </div>
    </HeaderMobileInternalWrapper>
	</Fragment>
}

export default withTheme(TopBarMobile)
