import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import FallbackThumbnail from 'application/components/controls/fallback_thumbnail'
import { Headline2 } from 'application/components/fragments/headlines'
import IconButtonWithLabel from 'application/components/controls/icon/icon_button_with_label'
import { fileHelpers } from 'application/common'
import { FlexGrid } from 'application/components/fragments/grid'

const StyledFallbackViewer = styled.div`
    width: auto;
    margin: auto;
    max-width: 90%;
    text-align: center;
`

const Headline = styled.span`
  font-size: ${props => props.fontSizeHeadline};
  font-weight: ${props => props.theme.fontWeight.headline3};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
`

const StyledSpan = styled.span`
    display: inline-block;
    width: auto;
    text-align: center;
`

export const StyledCaption = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.light};
    margin: 0;
    max-width: 100px;
`

const FallbackViewerComponent = ({ fileId, fileName, onAttachmentDownload, theme, withHeadline = true, withDownload = true, fontSizeHeadline = '20px'}) => {
    const { t } = useTranslation()
    const fileExtension = fileHelpers.getFileExtension(fileName)
    return <StyledFallbackViewer className='jestFallbackAttachmentViewer'>
        <FlexGrid justifyCenter itemsCenter>
            <FallbackThumbnail fileExtension={fileExtension} />
        </FlexGrid>
        {
            withHeadline && <Headline fontSizeHeadline={fontSizeHeadline} textAlign='center'>
                {/* <Trans i18nKey="FileXnotPreviewable" fileName={fileName}>Keine Vorschau für die Datei "{{ fileName }}" möglich.</Trans> */}
                <Trans i18nKey="FileXnotPreviewable" fileName={fileName}>{{ fileName }}</Trans>
            </Headline>
        }
        {
            withDownload &&
            <Headline2 textAlign='center'>
                <StyledSpan>
                    <IconButtonWithLabel fontSize={theme.fontSize.headLine2} iconKey='save_alt' label={t('DownloadFile')} onButtonClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        onAttachmentDownload(fileId, fileName)
                    }} />
                </StyledSpan>
            </Headline2>
        }
    </StyledFallbackViewer>
}

FallbackViewerComponent.propTypes = {
    fileType: PropTypes.string,
    filePath: PropTypes.string,
    fileName: PropTypes.string,
    onAttachmentDownload: PropTypes.func,
    errorComponent: PropTypes.element,
}

export default withTheme(FallbackViewerComponent)