import SearchInput from 'application/components/controls/form_elements/search_input'
import IconLink from 'application/components/controls/icon/icon_link'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { FlexGrid, FlexGridItem, PushBottom20 } from 'application/components/fragments/grid'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import React, {
    useEffect
} from 'react'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import AkteCard from '../../akte_card'
import { useTranslation } from 'react-i18next'
import LoadEffect from 'application/common/load_effect'
const Wrapper = styled.div`
    padding: 10px 20px;
    width: 100%;
    overflow: ${props => props.overflow}
`

const MessageSidebarTitle = styled.h1`
    font-size: ${props => props.theme.fontSize.large};
    font-family: ${props => props.theme.fontFamily.secondary};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-bottom: 16px;
`

const IconWrapper = styled.div`
    display: flex;
    align-self: center;

    div {
        margin-right: 2px;
    }
`

const MessagesSidebarComponent = (props) => {

    const { allAkten, setSelectedAkte, allAktenLoaded } = props
    
    const [toggleSearch, setToggleSearch] = useState(false)
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState(allAkten)
    const {t} = useTranslation()


    useEffect(() => {
        const results = allAkten.filter(a =>
            a.header.toLowerCase().includes(search.toLowerCase())
        )
        setSearchResults(results)
    }, [allAkten, search])

    return <React.Fragment>
        <FlexGridItem grow={0}>
            <Wrapper overflow='hidden'>
                {
                    toggleSearch ? <React.Fragment>
                        <FlexGrid justifySpaceBetween>
                            <FlexGridItem itemsCenter basis='70%'>
                                <SearchInput placeholder={t('Search Akten')} onChangeSearch={(e) => { setSearch(e.target.value) }} value={search} />
                            </FlexGridItem>
                            <FlexGridItem grow={0}>
                                <IconWrapper>
                                    <IconLink title={t('Close search case')} width='40px' iconKey='clear' onClick={() => {
                                        setToggleSearch(false)
                                        setSearch('')
                                    }} />
                                </IconWrapper>
                            </FlexGridItem>
                        </FlexGrid>
                        <PushBottom20 />
                    </React.Fragment> : <FlexGrid justifySpaceBetween>
                        <MessageSidebarTitle>
                            {t('Messages')}
                        </MessageSidebarTitle>
                        {
                            allAktenLoaded &&
                            <IconWrapper>
                                <IconLink title={t('Search Akten')} width='40px' iconKey='search' onClick={() => setToggleSearch(true)} />
                            </IconWrapper>
                        }
                    </FlexGrid>
                }
            </Wrapper>
        </FlexGridItem>
        <FlexDividerHorizontal />
        <PushBottom24 />
        <LoadEffect {...props}>

            {
                allAktenLoaded && !props.loading ? <Wrapper overflow='auto'>
                    {
                        searchResults.map((a, i) => {
                            return <AkteCard
                                setSelectedAkte={() => setSelectedAkte(a)}
                                key={i}
                                akteIdHash={a.idHash}
                                accountId={a.accountId}
                                accountName={a.accountName}
                                creatorFirstName={a.creatorFirstName}
                                creatorLastName={a.creatorLastName}
                                headline={a.header}
                                totalUnreadMessage={a.totalUnreadMessage}
                                lastMessageDate={a.lastMessageDate}
                                lastMessage={a.lastMessage}
                                mainColor={a.mainColor}
                                secondaryColor={a.secondaryColor}
                                totalUnreadMessageInFolder={a.totalUnreadMessageInFolder}
                                creatorIdHash={a.creatorIdHash}
                            />
                        })
                    }
                </Wrapper> : null
            }
        </LoadEffect>
    </React.Fragment>
}

export default MessagesSidebarComponent