import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { creatorProfilePictureHasNoData, populateCommonAllAktenData, populateCreatorProfilePicture, populateAllAktenInOneAccountData, getCommonAllAktenFailed, updateAktenFailed } from 'application/redux/actions/common/all_akten'
import { handleError } from 'application/redux/saga/errors'
import { creatorPictureLoaded, getAllAccounts } from 'application/redux/selectors'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { responseArray } from './function'
import { updateSelectedAccountDetails } from 'application/redux/actions/common/account_details'
import { updateAktenDataSaga } from './update_akten_data_saga'
import { putTotalUnreadMessageEachAccountSaga } from '../all_accounts/put_total_unread_message_each_account_saga'

export function* getCommonAllAktenDataSaga(action) {
    try {
        const accounts = yield select(getAllAccounts)
        const response = yield call(api.gateway.common.getAllAkten)
        const convertArray = responseArray(response, accounts)
        yield putTotalUnreadMessageEachAccountSaga({akten: convertArray})
        yield put(populateCommonAllAktenData(convertArray))
    } catch (e) {
        yield handleError(e, action)
        yield put(getCommonAllAktenFailed())
    }
}

export function* getAllAktenInOneAccountDataSaga(action) {
    try {
        const accounts = yield select(getAllAccounts)
        const response = yield call(api.gateway.common.getAllAktenInOneAccount, action.accountId)
        yield put(populateAllAktenInOneAccountData(responseArray(response, accounts)))
        yield put(updateSelectedAccountDetails({ akten: responseArray(response, accounts) }))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateCommonAllAktenDataSaga(action) {
    try {
        yield updateAktenDataSaga(action)
    } catch (e) {
        yield handleError(e, action)
        yield put(updateAktenFailed(action.params))
    }
}

function* getCreatorProfilePictureSaga(action) {
    try {
        const isLoaded = yield select(creatorPictureLoaded, action.creatorIdHash)
        if (!isLoaded) {
            const response = yield call(api.gateway.common.getCreatorProfilePicture, action.accountId, action.creatorIdHash)
            const blobUrl = window.URL.createObjectURL(response)
            yield put(populateCreatorProfilePicture(action.akteIdHash, blobUrl, action.creatorIdHash))
        }
        yield
    } catch (e) {
        if (e.response.status === 404) {
            yield put(creatorProfilePictureHasNoData(action.creatorIdHash))
        }
        // yield handleError(e)
    }
}

export function* allAktenWatcher() {
    yield takeEvery(ACTIONS.COMMON_ALL_AKTEN_REQUEST_DATA, getCommonAllAktenDataSaga)
    yield takeEvery(ACTIONS.COMMON_ALL_AKTEN_IN_ONE_ACCOUNT_REQUEST_DATA, getAllAktenInOneAccountDataSaga)
    yield takeEvery(ACTIONS.COMMON_ALL_AKTEN_UPDATE_DATA, updateCommonAllAktenDataSaga)
    yield takeEvery(ACTIONS.COMMON_GET_CREATOR_PROFILE_PICTURE_REQUEST, getCreatorProfilePictureSaga)
}