import { cropperHelpers } from 'application/common'

export function croppingPicturePatternSaga(action) {
    const { imageSrc, croppedAreaPixels, rotation } = action.payload
    const croppedImage = cropperHelpers.getCroppedImgAsObjectFile(
        imageSrc,
        croppedAreaPixels,
        rotation
    )

    return croppedImage
}