import ACTIONS from 'application/constants'
import defaultStore from '../default_store'

const aktenSidebarReducer = (state = defaultStore.sidebars.akten, action) => {
    switch (action.type) {
    case ACTIONS.SIDEBAR_OPEN_AKTEN:
        return {
            ...state,
            visible: true,
        }
    case ACTIONS.SIDEBAR_CLOSE_AKTEN:
        return {
            ...state,
            visible: false
        }
    default:
        return state
    }
}

export default aktenSidebarReducer