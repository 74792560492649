import React, { useState } from 'react'
import * as Common from './fragments'
import moment from 'moment'
import positiv from './images/excellent.png'
import neutral from './images/average.png'
import negativ from './images/poor.png'
import { ThumbnailContainer }  from 'application/components/building_blocks/attachments/thumbnail'



const images = [
    {
        type: 'positiv',
        image: positiv,
    },
    {
        type: 'neutral',
        image: neutral,
    },
    {
        type: 'negativ',
        image: negativ,
    }
]

const AnsweredElem = ({ userinfo, parentId, akteIdHash, accountId, onOpenGallery, baseUrl, token, answerFormElements }) => {


    const elements = answerFormElements.map((element) => {
        
        switch (element.type) {
            case 'headline':
                return (
                    <Common.StyledHeadline>{element.description}</Common.StyledHeadline>
                )
            case 'display':
                return (
                    <Common.StyledDisplayDescription>{element.description}</Common.StyledDisplayDescription>
                )
            case 'input':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <Common.StyledInput type='text' readOnly value={element.answer} />
                    </Common.StyledInputWrapper>
                )
            case 'date':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <Common.StyledInput type='text' readOnly value={element.answer} />
                    </Common.StyledInputWrapper>
                )
            case 'time':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <Common.StyledInput type='text' readOnly value={element.answer} />
                    </Common.StyledInputWrapper>
                )
            case 'number':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <Common.StyledInput type='text' readOnly value={element.answer} />
                    </Common.StyledInputWrapper>
                )
            case 'signature':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        {/* <div>{element?.answer[0]?.name}</div> */}

                        <div style={{display: 'flex', alignItems: 'center'}}>

                        {element.answer.map((e, key) => 
                            {
                                const files = element.answer.map((v) => {
                                    return {
                                        'id': v.entityId,
                                        'fileName': v.name + '.' + v.extension
                                    }
                                })

                                
                                
                                return (

                                    <div key={key}>
                                        {/* <GetImage baseUrl={baseUrl} accountId={accountId} parentId={parentId} akteIdHash={akteIdHash} element={e} token={token} index={key} /> */}

                                        <ThumbnailContainer
                                            key={key}
                                            fileId={e?.entityId}
                                            fileName={e?.name + '.' + e.extension}
                                            akteIdHash={akteIdHash}
                                            parentId={parentId}
                                            onAttachmentClick={() => onOpenGallery(e?.entityId, e?.name + '.' + e.extension, accountId, parentId, akteIdHash, files)}
                                            marginRight={false}
                                            isSizeFixed={false}
                                            divHeight='70px'
                                            divWidth='70px'
                                            showCaption={true}
                                            accountId={accountId}
                                        />
                                    </div>
                                
                                )
                            }
                            )}
                        </div>
                    </Common.StyledInputWrapper>
                )
                
            case 'mobilenumber':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <Common.StyledInput type='text' readOnly value={element.answer} />
                    </Common.StyledInputWrapper>
                )
            case 'file':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        {/* {element.answer.map(async(e) => (
                            <div>
                                <GetImage baseUrl={baseUrl} accountId={accountId} element={e} token={token} />
                            </div>
                               
                        )
                        )} */}
                        <div style={{display: 'flex', alignItems: 'center'}}>


                            {element.answer.map((e, key) => 
                            {
                                const files = element.answer.map((v) => {
                                    return {
                                        'id': v.entityId,
                                        'fileName': v.name + '.' + v.extension
                                    }
                                })
                                console.log(files)
                                return (

                                    <div key={key}>
                                        {/* <GetImage baseUrl={baseUrl} accountId={accountId} parentId={parentId} akteIdHash={akteIdHash} element={e} token={token} index={key} /> */}

                                        <ThumbnailContainer
                                            key={key}
                                            fileId={e?.entityId}
                                            fileName={e?.name + '.' + e.extension}
                                            akteIdHash={akteIdHash}
                                            parentId={parentId}
                                            onAttachmentClick={() => onOpenGallery(e?.entityId, e?.name + '.' + e.extension, accountId, parentId, akteIdHash, files)}
                                            marginRight={false}
                                            isSizeFixed={false}
                                            divHeight='90px'
                                            divWidth='90px'
                                            showCaption={true}
                                            accountId={accountId}
                                        />
                                    </div>
                                
                                )
                            }
                            )}
                        </div>
                        
                    </Common.StyledInputWrapper>
                )
            case 'geolocation':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        {/* <div>{element.answer.lat}</div> */}
                    </Common.StyledInputWrapper>
                )
            case 'rating':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <Common.StyledRatingWrapper>
                            {images.map((image) => (
                                <Common.StyledImageContainer selected={image.type === element.answer}>
                                    <img src={image.image} style={{width: '150px'}}/>
                                </Common.StyledImageContainer>
                            ))}
                        </Common.StyledRatingWrapper>
                    </Common.StyledInputWrapper>
                )
            case 'textarea':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <Common.StyledTextArea readOnly defaultValue={element.answer} />
                    </Common.StyledInputWrapper>
                )
            case 'simplechoice':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateAreas: 'select',
                                alignItems: 'center',
                                position: 'relative',
                                border: '1px solid #d9d9d9',
                                borderRadius: '6px',
                                padding: '0 10px',
                                lineHeight: '24px',
                                backgroundColor: '#fff',
                                backgroundImage: 'linear-gradient(to top, #f9f9f9, #fff 33%)',
                                width: '100%',
                            }}
                        >

                            <select
                                style={{
                                    appearance: 'none',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    padding: '0 1em 0 0',
                                    margin: '0',
                                    width: '100%',
                                    fontFamily: 'inherit',
                                    fontSize: 'inherit',
                                    cursor: 'inherit',
                                    lineHeight: 'inherit',
                                    zIndex: '1',
                                    outline: 'none',
                                }}
                                disabled
                            >
                                <option value={element.answer}>{element.answer}</option>
                            </select>
                        </div>
                    </Common.StyledInputWrapper>
                )
            case 'checkbox':
                return (
                    <Common.StyledCheckboxWrapper>
                        <input type='checkbox' style={{
                           transform: 'scale(1.5)',
                        }} checked={element.answer} disabled />
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                    </Common.StyledCheckboxWrapper>
                )
            case 'multiplechoice':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateAreas: 'select',
                                alignItems: 'center',
                                position: 'relative',
                                border: '1px solid #d9d9d9',
                                borderRadius: '6px',
                                padding: '0 10px',
                                lineHeight: '24px',
                                backgroundColor: '#fff',
                                backgroundImage: 'linear-gradient(to top, #f9f9f9, #fff 33%)',
                                width: '100%',
                            }}
                        >

                            <select
                                style={{
                                    appearance: 'none',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    padding: '0 1em 0 0',
                                    margin: '0',
                                    width: '100%',
                                    fontFamily: 'inherit',
                                    fontSize: 'inherit',
                                    cursor: 'inherit',
                                    lineHeight: 'inherit',
                                    zIndex: '1',
                                    outline: 'none',
                                }}
                                disabled
                            >
                                <option value={element.answer}>{element.answer}</option>
                            </select>
                        </div>
                    </Common.StyledInputWrapper>
                )
            case 'inputregex':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <Common.StyledInput type='text' readOnly value={element.answer} />
                    </Common.StyledInputWrapper>
                )

            case 'gutachterchoice':
                return (
                    <Common.StyledInputWrapper>
                        <Common.StyledLabel>{element.description}</Common.StyledLabel>
                        <Common.StyledInput type='text' readOnly value={element.answer} />
                    </Common.StyledInputWrapper>
                )
            
            // case 'repeater':
            //     return (
            //         <div>
            //             {element.answer.map((repeatedItem, index) => (
            //                 <div key={index}>
            //                     <AnsweredElem
            //                         userinfo={userinfo}
            //                         parentId={parentId}
            //                         akteIdHash={akteIdHash}
            //                         accountId={accountId}
            //                         onOpenGallery={onOpenGallery}
            //                         baseUrl={baseUrl}
            //                         token={token}
            //                         answerFormElements={repeatedItem.answerFormElements} 
            //                     />
            //                 </div>
            //             ))}
            //         </div>
            //     )

            default:
                return null
    }})
  return (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
            {userinfo?.secondaryLogoUrl && (
                <img 
                    style={{
                        marginTop: '32px',
                        width: '70%',
                        height: '70%',
                        objectFit: 'cover',
                        borderRadius: '15px',
                    }}
                    src={userinfo?.secondaryLogoUrl}
                    alt="secondaryLogoUrl"
                />
            )}
        
        <div style={{
            width: '72%',
        }}>
            {elements.map((item, index) => {
                return (
                    <div key={index}>
                        {item}
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default AnsweredElem