import { getAktenInOneAccount, refreshAllAktenDAta } from 'application/redux/actions/common/all_akten'
import { populateBreadscrumbsData, refreshMessageOnRoot, setBreadscurmbsView, setFoldersView, setSelectedAkte, setSelectedFolder } from 'application/redux/actions/pages/akten'
import { connect } from 'react-redux'
import ServiceProviderDetailSidebarDefault from './component'
import { getCommonAllAktenRequest } from 'application/redux/actions/common/all_akten'
import { openAktenPopupSidebar } from 'application/redux/actions/sidebars'

const mapStateToProps = state => ({
    firstName: state.auth.common.user.firstName,
    lastName: state.auth.common.user.lastName,
    email: state.auth.common.user.email,
    allAktenLoaded: state.common.allAkten.isLoaded,
    allAccountsLoaded: state.common.allAccounts.isLoaded,
    auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
    getAllAkten: () => dispatch(getCommonAllAktenRequest()),
    getAllAktenInOneAccount: (accountId) => dispatch(getAktenInOneAccount(accountId)),
    handleOpenAktenPopupSidebar: () => dispatch(openAktenPopupSidebar()),
    setSelectedAkte: payload => {
        dispatch(setSelectedAkte(payload))
        dispatch(refreshMessageOnRoot())
        dispatch(refreshAllAktenDAta())
        dispatch(setSelectedFolder(null))
        dispatch(setBreadscurmbsView(false))
        dispatch(setFoldersView(true))
        dispatch(populateBreadscrumbsData([]))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceProviderDetailSidebarDefault)