import React, { Suspense, lazy } from 'react'
import * as Fragments from '../fragments'
import { BasicLoader } from 'application/components/fragments/basic_loader'

const FooterWelcome = lazy(() => import('../sub_component/footer'))
const HeaderWelcomePage = lazy(() => import('../sub_component/header'))
const PricingCardBusiness = lazy(() => import('./pricing_card'))
const ExplanationCta = lazy(() => import('./expplanation_cta'))
const MainInfoWelcome = lazy(() => import('../sub_component/info_main'))
const QnABusinessWelcome = lazy(() => import('../sub_component/qna/business'))
const StepBusiness = lazy(() => import('../sub_component/info_turqouise/step_business'))
const LogosBusiness = lazy(() => import('./logos'))



export const BusinessWelcomeContent = () => {
    return <Suspense fallback={<BasicLoader />}>
        <HeaderWelcomePage business />
        <Fragments.MainWrapper>
            <MainInfoWelcome business />
            <StepBusiness />
            <ExplanationCta />
            <PricingCardBusiness />
            <LogosBusiness />
            <QnABusinessWelcome />
            <FooterWelcome />
        </Fragments.MainWrapper>
    </Suspense>
}

export default BusinessWelcomeContent