import React from 'react'
import { I18N } from 'application/constants'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'

export const LanguageNameMapper = ({ languageType }) => {
    const mapSchadenTypeName = (type) => {
        const names = new Map([
            [I18N.LANGUAGES_TYPE.DEUTSCH, 'Deutsch'],
            [I18N.LANGUAGES_TYPE.ENGLISH, 'English'],
        ])
        return names.get(type)
    }

    return <React.Fragment>
        {mapSchadenTypeName(languageType)}
    </React.Fragment>
}

export const valueIntLanguage = (languageType) => {
    switch (languageType) {
    case I18N.LANGUAGES_TYPE.DEUTSCH:
        return 0
    case I18N.LANGUAGES_TYPE.ENGLISH:
        return 1
    default:
        return 1
    }
}

export const LanguageImageMapper = ({languageType }) => {
    const getImageSrc = languageType => {
        switch (languageType) {
        case I18N.LANGUAGES_TYPE.ENGLISH:
            return '/assets/images/en.png'
        case I18N.LANGUAGES_TYPE.DEUTSCH:
            return '/assets/images/de.png'
        default:
            return '/assets/images/de.png'
        }
    }

    return <ImagePlaceHolder large='80%' withBorder={false} source={getImageSrc(languageType)} />
}