import { ROUTES } from 'application/constants'
import { hashUserKey } from 'application/storage'
import React from 'react'
import { Navigate } from 'react-router-dom'

const WizardRedirect = (props) => {
    const { auth, loginFailed } = props
    if (!loginFailed && auth.unregistered.loggedIn) {
        return (
            <Navigate to={ROUTES.WIZARD_REG_BASE + '/' + hashUserKey.get() + ROUTES.WIZARD_REG_WELCOME} />
        )
    }

    return <React.Fragment>{props.children}</React.Fragment>
}

export default WizardRedirect
