import { MessagePreview } from 'application/components/controls/menu_preview'
import { FlexGrid, PushBottom10 } from 'application/components/fragments/grid'
import { AKTEN, ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import React, { useEffect, useState }  from 'react'
import styled from 'styled-components'
import { Element } from 'react-scroll'
import { useQuery } from 'application/components/hooks'
import MessageListEffectsScroll from './effects_scroll_list'
import DividerText from 'application/components/controls/divider/divider_text'
import { getAncestorsByParent } from '../../tree/helpers'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

const WrapperMessageList = styled.div`
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 10px;
    height: 100%;
    max-width:100%;
    overflow: auto;
    overflow-x: hidden;
`

const PlaceholderAkteNotFound = styled.div`
    padding: 10px;
    border-radius: 10px;
    background: ${p => p.theme.color.color5};
    font-family: ${props => props.theme.fontFamily.secondary};
    color: ${p => p.theme.color.color40};
    border-color: 'transparent';
    height: 50px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: ${p => p.theme.fontWeight.bold};
    font-style: italic;
`

const MessageListComponent = (props) => {
    const {
        akten = [],
        aktenDetailsRequest,
        folders,
        selectedAkteId,
        selectedAccountId,
        setSelectedAkte,
        refreshMessageOnRoot,
        foldersView,
        openMessageExtendedData,
        breadcrumbsView,
        setSelectedFolder,
        populateBreadcrumbsData,
        refreshMessagesOnFolder,
    } = props
    const [filteredAkte, setFilteredAkte] = useState(akten)
    let params = useParams()
    let location = useLocation()
    let navigate = useNavigate()
    const query = useQuery()
    const searchValueAkte = query.getStringParam(ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER) !== '' ? query.getStringParam(ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER) : ''
    const folderId = query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID) !== '' ? query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID) : ''
    

    useEffect(() => {
        const results = akten.filter(a =>
            a.header.toLowerCase().includes(searchValueAkte.toLowerCase())
            || a.accountName.toLowerCase().includes(searchValueAkte.toLowerCase())
            || a.creatorFirstName.toLowerCase().includes(searchValueAkte.toLowerCase())
            || a.creatorLastName.toLowerCase().includes(searchValueAkte.toLowerCase())
        )
        setFilteredAkte(results)
    }, [akten, searchValueAkte])

    const handleSetMessageExtendedData = (e) => {
        e.stopPropagation()
        e.preventDefault()
        openMessageExtendedData(!foldersView, !breadcrumbsView)
        folderId !== '' && refreshMessageOnRoot()
        navigate(`${location.pathname}`)
    }

    const handleSelectFolder = (e, selectedFolder, id, name, accountId) => {
        setSelectedFolder(selectedFolder, params, id, accountId)
        const payload = [{ id, name, root: true }, ...getAncestorsByParent(folders, selectedFolder.id)]
        populateBreadcrumbsData(payload)
        refreshMessagesOnFolder()
        navigate(`${location.pathname}?${ROUTE_PARAMETERS.AKTEN.FOLDER_ID}=${selectedFolder.id}&`)
    }
    const {t} = useTranslation()
    return <MessageListEffectsScroll {...props}>
        <WrapperMessageList id={AKTEN.CONTAINER_ID.MESSAGES_LIST}>
            {
                searchValueAkte !== '' && <DividerText title={t('RelatedSearch')} description={searchValueAkte} onCloseClick={() => navigate(ROUTES.AKTEN)} />
            }
            <FlexGrid noWrap directionColumn>
                {
                    filteredAkte.length > 0 ? filteredAkte.map((a, i) => {
                        return <React.Fragment key={i}>
                            <Element name={a.idHash} />
                            <MessagePreview
                                id={a.idHash}
                                onClick={() => {
                                    setSelectedAkte(a, params, a.idHash, a.accountId)
                                }}
                                mainColor={a.mainColor}
                                key={i}
                                totalUnreadMessage={a.totalUnreadMessage}
                                isselected={a.idHash === selectedAkteId ? parseInt(a.accountId) === parseInt(selectedAccountId) : false}
                                lastMessageDate={a.lastMessageDate}
                                lastMessage={a.lastMessage}
                                folderCount={a.folderCount}
                                creatorFirstName={a.creatorFirstName}
                                creatorLastName={a.creatorLastName}
                                accountName={a.accountName}
                                header={a.header}
                                akteDataRequest={aktenDetailsRequest}
                                folders={folders}
                                akteIdHash={a.idHash}
                                linkTo={ROUTES.AKTEN + '/' + a.idHash + '/accounts/' + a.accountId}
                                foldersView={foldersView}
                                openMessageExtendedData={(e) => handleSetMessageExtendedData(e)}
                                onSelectFolder={(e, selectedFolder) => handleSelectFolder(e, selectedFolder, a.idHash, a.header, a.accountId)}
                                folderId={folderId}
                                totalUnreadMessageInFolder={a.totalUnreadMessageInFolder}
                            />
                        </React.Fragment>
                    }) : <FlexGrid justifyCenter>
                        <PushBottom10 />
                        <PlaceholderAkteNotFound>
                            {t('NoCasesFound')}
                        </PlaceholderAkteNotFound>
                    </FlexGrid>
                }
            </FlexGrid>
        </WrapperMessageList>
    </MessageListEffectsScroll>
}

export default MessageListComponent