import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledDate = styled.span`
    font-family: ${props => props.theme.fontFamily.primary};
`

const DateComponent = ({ date }) => {
    const { t } = useTranslation()
    const theDate = date instanceof Date ? date.toLocaleDateString() : new Date(date).toLocaleDateString()
    const today = new Date(Date.now()).toLocaleDateString()
    const displayedString = theDate === today ? t('Today') : theDate
    return (
        <StyledDate>{displayedString}</StyledDate>
    )
}

DateComponent.propTypes = {
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]).isRequired
}

export default DateComponent