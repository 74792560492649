import { DIALOG } from 'application/constants'
import { getAkteMetaRequest } from 'application/redux/actions/akte_meta'
import { loginRequestPin } from 'application/redux/actions/auth'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { sendPinRequest } from 'application/redux/actions/auth'
import { connect } from 'react-redux'
import EnterPin from './component'
import { loginWithoutPin } from 'application/redux/actions/auth/login_pin'

const mapStateToProps = state => ({
    akteMeta: state.akteMeta.payload,
    auth: state.auth,
    akteMetaLoaded: state.akteMeta.isLoaded,
    akteMetaLoading: state.akteMeta.isLoading,
    akteMetaFailed: state.akteMeta.failed,
    loginRequest: state.auth.unregistered.loginRequest,
    loginFailed: state.auth.unregistered.loginFailed,
    loggedIn: state.auth.unregistered.loggedIn,
    pinRequest: state.auth.pin.request,
    responseInfo: state.notifications.toast.message,
})



const mapDispatchToProps = dispatch => ({
    sendPin: (authHashKey, pin, errorMessageSetPin) => {
        dispatch(loginRequestPin(authHashKey, pin, errorMessageSetPin))
    },
    loginWithoutPin: (authHashKey, errorMessage) => {
        dispatch(loginWithoutPin(authHashKey,errorMessage))
    },
    onOpenDialogSendPinOption: (hashAuthKey) => dispatch(openDialogByName(DIALOG.NAME.SEND_PIN_OPTION, true, { hashAuthKey }, 'Request PIN')),
    onOpenDialogSendPinOptionOnMobile: (hashAuthKey) => dispatch(openDialogByName(DIALOG.NAME.SEND_PIN_OPTION, true, { hashAuthKey }, 'Request PIN', DIALOG.SCREEN.MOBILE)),
    getAkteMetaRequest: (hashUserKey) => dispatch(getAkteMetaRequest(hashUserKey)),
    reqPin: (hashAuthKey, methode, errorMessage, successMessage) => dispatch(sendPinRequest(hashAuthKey, methode, errorMessage, successMessage))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnterPin)