import styled from 'styled-components'

export const ConsentContainer = styled.div`
    padding: 0.5rem;
    z-index: 100;
    left: 0;
    bottom: 0;
    position: fixed;
    @media (min-width: ${props => props.theme.breakpoint.mobileL}px) {
        padding: 2.5rem;
    }
`

export const ConsentWrapper = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.12), 0 2px 4px 0 rgba(0,0,0,.08);
    width: 100%;
    padding-left: 1.5rem;
    border-radius: .5rem;
    background-color: #fff;

    @media (min-width: ${props => props.theme.breakpoint.mobileL}px) {
        width: auto;
    }
`

export const ImageOnConsentWrapper = styled.div`
    width: 4rem;
    position: relative;
    margin-top: -2.5rem;
    margin-bottom: .75rem;
    margin-left: auto;
    margin-right: auto;
`

export const ImageConsent = styled.img`
    max-width: 100%;
    height: auto;
    margin-top: -.25rem;
`

export const ParConsent = styled.span`
    font-size: .75rem;
    color: #3d4852;
    margin-bottom: .75rem;
    line-height: 1.5;
    display: block;
    width: 100%;

    @media (min-width: ${props => props.theme.breakpoint.mobileL}px) {
        width: 18rem;
    }
`

export const AcceptanceWrapper = styled.div`
    justify-content: space-between;
    align-items: center;
    display: flex;
`

export const LinkConsent = styled.a`
    font-size: .75rem;
    color: #8795a1;
    margin-right: .25rem;
    text-decoration: none;
    background-color: transparent;
`