import React, { Fragment } from 'react'
import NotFoundPageBigfoot from '../../../not_found/not_found_page_bigfoot'

const AccountPublicInfoFailedRedirect = (props) => {
    const { publicInfoFailed, children } = props
    if (publicInfoFailed) {
        return <NotFoundPageBigfoot />
    }

    return <Fragment>
        {children}
    </Fragment>
}

export default AccountPublicInfoFailedRedirect
