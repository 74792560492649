import { call, put, takeEvery } from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import {
    forgotPasswordFailed,
    forgotPasswordSucceed,
    forgotUsernameFailed,
    forgotUsernameSucceed,
    sendNewPasswordFailed,
    sendNewPasswordSucceed,
    writeMessageErrorForgotCredential,
} from 'application/redux/actions/auth'
import { handleError } from '../../errors'
import { showToast } from 'application/redux/actions/notifications'

function* forgotUsernameSaga(action) {
    try {
        yield call(api.gateway.auth.getUsername, action.email)
        yield put(forgotUsernameSucceed())
        yield put(showToast(action.successMessage))
    } catch (e) {
        yield handleError(e, action)
        yield put(forgotUsernameFailed())
        // yield put(writeMessageErrorForgotCredential)
        // yield put(showToast(action.errorMessage, NOTIFICATION.LEVEL.ERROR))
    }
}

function* forgotPasswordSaga(action) {
    try {
        yield call(api.gateway.auth.getPassword, action.username)
        yield put(forgotPasswordSucceed())
        yield put(showToast((action.successMessage)))
    } catch (e) {
        yield handleError(e, action)
        yield put(forgotPasswordFailed())
        // yield put(writeMessageErrorForgotCredential)
        // yield put(showToast(action.errorMessage, NOTIFICATION.LEVEL.ERROR))
    }
}

function* sendNewPasswordSaga(action) {
    try {
        yield call(api.gateway.auth.updatePassword, action.passwordHashKey, action.password)
        yield put(sendNewPasswordSucceed())
    } catch (e) {
        yield handleError(e, action)
        yield put(sendNewPasswordFailed())
        yield put(writeMessageErrorForgotCredential(e.data.reasonMessage))
        // yield put(showToast(e.data.reasonMessage, NOTIFICATION.LEVEL.ERROR))
    }
}

export function* forgotCredentialsWatcher() {
    yield takeEvery(ACTIONS.AUTH_FORGET_USERNAME_REQUEST, forgotUsernameSaga)
    yield takeEvery(ACTIONS.AUTH_FORGET_PASSWORD_REQUEST, forgotPasswordSaga)
    yield takeEvery(ACTIONS.AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD, sendNewPasswordSaga)
}