import { showGallery } from 'application/redux/actions/gallery'
import { closeAkteSidebar, openAkteSidebar } from 'application/redux/actions/pages/akten'
import { connect } from 'react-redux'
import AktenDetailSidebarComponent from './component'
import { accessTokenRegistered } from 'application/storage'
import { getFullFileById } from 'application/redux/actions/files'

const mapStateToProps = (state, props) => {
    const registered = state.messages.registered
    const messageFullLoaded = registered.length > 0 && registered.filter(r => r.key === props.messageKey && r.isFullLoaded).length > 0
    const metaMessage = messageFullLoaded && registered.find(r => r.key === props.messageKey)
    const favoriteMessages = messageFullLoaded && registered.find(r => r.key === props.messageKey).favoriteMessages
    const messages = messageFullLoaded && metaMessage.messages
    const files = messageFullLoaded && metaMessage.files
    const readFiles = files.length > 0 && messages.length > 0 ? files.filter(s => messages.filter(m => m.id === s.messageId).length > 0 && messages.find(m => m.id === s.messageId).read) : []
    return {
        thumbnailsToShow: readFiles,
        filesToShow: readFiles,
        sidebarKey: state.pages.akten.sidebar.key,
        sidebarVisible: state.pages.akten.sidebar.visible,
        messageDetailsLoaded: state.pages.akten.details.isLoaded,
        newMessagesLoaded: state.pages.akten.action.newMessages.succeed,
        newMessagesOnFolderLoaded: state.pages.akten.action.newMessagesOnFolder.succeed,
        filesRequest: state.pages.akten.action.newFiles.request,
        filesLoaded: state.pages.akten.action.newFiles.succeed,
        favoriteMessages,
        metaInfo: metaMessage.metaInfo,
        messageFullLoaded,
        registeredMessages: registered,
        metaMessage,
        messages,
    }
}

const mapDispatchToProps = dispatch => ({
    closeAkteDetails: () => dispatch(closeAkteSidebar()),
    onOpenGallery: (fileId, fileName, accountId, parentId, akteIdHash, thumbnails) => {
        dispatch(getFullFileById(fileId, fileName, {
            accountId: accountId, 
            storage: accessTokenRegistered
        }))
        dispatch(showGallery(fileId, fileName, accountId, parentId, akteIdHash, thumbnails))
    },
    openAkteSidebar: (sidebarKey) => {
        dispatch(openAkteSidebar(sidebarKey))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AktenDetailSidebarComponent)