export const responseArray = (response, accounts) => {
    const array = Object.keys(response).reduce(function (r, k) {
        const isAccount = accounts.filter(a => a.id === parseInt(k)).length > 0
        const accountName = isAccount && accounts.find(a => a.id === parseInt(k)).name
        const mainColor = isAccount && accounts.find(a => a.id === parseInt(k)).mainColor
        const secondaryColor = isAccount && accounts.find(a => a.id === parseInt(k)).secondaryColor
        const categories = isAccount && accounts.find(a => a.id === parseInt(k)).categories

        const newObj = {
            accountId: k,
            accountName,
            mainColor,
            secondaryColor,
            categories,
        }
        const newArr = isAccount ? response[k].map((a) => {
            return {
                ...a,
                ...newObj,
            }
        }) : []
        return r.concat(newArr)
    }, [])

    return array
}