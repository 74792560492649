import React, { Fragment } from 'react'
import { LayoutBase } from '../../../_layout'
import LayoutWelcome from '../_layout'
import BusinessWelcomeContent from './content'

const BusinessWelcomePage = () => {
    return <Fragment>
        <LayoutBase>
            <LayoutWelcome>
                <BusinessWelcomeContent />
            </LayoutWelcome>
        </LayoutBase>
    </Fragment>
}

export default BusinessWelcomePage