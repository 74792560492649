import { FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { PushToDashboardEffect } from '../../auth/login_account/effects'
import { LoginAccountContainer } from '../../auth/login_account/fragments'
import ExternalRequestsSection from '../../auth/login_account/sections/external_requests'
import FooterLoginAccountSection from '../../auth/login_account/sections/footer'
import HeaderExternalLoginAccountSection from '../../auth/login_account/sections/header'
import WelcomeGreetings from '../../auth/login_account/sections/welcome_greetings'

const LoginSpecificAccountMobile = (props) => {
    return <PushToDashboardEffect {...props}>
		 <LoginAccountContainer useMobile>
            <HeaderExternalLoginAccountSection useMobile {...props} />
            <WelcomeGreetings useMobile {...props} />
            <ExternalRequestsSection useMobile {...props} />
            <FlexGridItem grow={0} shrink={0}> 
                <FooterLoginAccountSection notDesktop useMobile {...props} />
            </FlexGridItem>
        </LoginAccountContainer>
    </PushToDashboardEffect>
}

export default LoginSpecificAccountMobile
