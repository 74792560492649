import React from 'react'
import { MobileDashboardWrapper } from '../../dashboard/fragments'
import SectionAktenMobileComponent from './sections/akten'
import SectionAccountsMobileComponent from './sections/accounts'

const DashboardMobile = ({ allAkten, allAktenLoaded, allAccounts, allAccountsLoaded, setSelectedAkte }) => {
    return (
        <MobileDashboardWrapper>
            <SectionAktenMobileComponent allAkten={allAkten} allAktenLoaded={allAktenLoaded} setSelectedAkte={setSelectedAkte} />
			<SectionAccountsMobileComponent allAccounts={allAccounts} allAccountsLoaded={allAccountsLoaded} />
        </MobileDashboardWrapper>
    )
}

export default DashboardMobile
