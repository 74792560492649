import { downloadFile, downloadFileAdHoc, getFullFileById } from 'application/redux/actions/files'
import { hideGallery, setActiveFile } from 'application/redux/actions/gallery'
import { connect } from 'react-redux'
import AttachmentGallery from './component'
import { accessTokenRegistered } from 'application/storage'

const mapStateToProps = (state, ownProps) => ({
    gallery: state.gallery
})

const mapDispatchToProps = (dispatch) => ({
  onSelectAttachment: (fileId, fileName, accountId, akteIdHash, parentId) => {
    dispatch(getFullFileById(fileId, fileName, {
      accountId: accountId, 
      storage: accessTokenRegistered
    }))
    dispatch(setActiveFile(fileId, fileName, accountId, akteIdHash, parentId))
  },
  downloadAttachment: (fileId, fileName, accountId, storage) =>
    dispatch(downloadFile(fileId, fileName, accountId, storage)),
  downloadAttachmentAdHoc: (fileId, fileName, accountId) => {
    dispatch(downloadFileAdHoc(fileId, fileName, accountId))
  },
    
  onCloseGallery: () => dispatch(hideGallery()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentGallery)