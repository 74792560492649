import styled from 'styled-components'

export const HeaderMobileInternalWrapper = styled.header`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	position: sticky;

	@supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & div:not(:last-child) {
      margin-right: 15px;
    }
  }
`