import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import { TextAreaInputWithLabel } from '.'
import { SimpleUploader } from '..'
import FileName from '../../file_name'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { nanoid } from 'nanoid'
import { isMobileOnly, isTablet } from 'react-device-detect'
import { isEmpty } from 'lodash'
import IconLink from '../../icon/icon_link'
import TextFieldsRoundedIcon from '@mui/icons-material/TextFieldsRounded'

const ExtendedWrapper = styled.div`
    width: 100%;
    border: 1.5px solid ${(props) => props.theme.color.color15};
    border-top: 1px solid transparent;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 5px;
    height: 100%;
    background-color: ${(props) => props.theme.color.color10};
    /* overflow: auto; */
`

const Wrapper = styled.div`
    overflow: ${(props) => (!props.overflow ? 'hidden' : 'auto')};
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    /* max-height: ${(props) => (props.maxHeight === undefined ? '100%' : props.maxHeight)}; */
`

const MesssageInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
        flex-flow: row nowrap;
        align-items: flex-end;
    }
`

const MessageAction = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    background-color: ${(p) => p.theme.color.color0};

    @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
        display: none;
    }
`

const MessageActionIcon = styled.div`
    display: flex;
    align-items: center;
`

const SendingButton = styled.button`
    background-color: transparent;
    color: ${(p) => (p.isMessageFilled ? p.theme.color.primary : p.theme.color.color30)};
    padding: 5px;
    font-size: ${(p) => p.theme.fontSize.medium18};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    border: none;
`

const ChatTextArea = (props) => {
    const {
        register,
        name = 'message',
        files = [],
        openSendAttachment = () => {},
        sendMessage,
        onChange = () => {},
        clearFile = () => {},
        theme,
        accountId,
        watch,
        fontSize,
        onKeyPress,
        accountName,
        accountPublicInfo,
    } = props
    const { t } = useTranslation()
    const isSmallLaptop = useMediaQuery({ minWidth: 1366, maxWidth: 1440 })
    let placeholder = t('Write your message to ') + accountName
    const isMessageFilled = !isEmpty(watch()) && watch(name).length > 0
    return (
        <React.Fragment>
            <MesssageInputContainer>
                {!isMobileOnly && (
                    <FlexGridItem hideMobile widthMobile='7%' width='7%' grow={1} itemsCenter justifyCenter>
                        <SimpleUploader
                            title={t('Upload')}
                            onFileAdded={(files) => openSendAttachment(files, accountId)}
                            onFilesAdded={(files) => openSendAttachment(files, accountId)}
                        />
                    </FlexGridItem>
                )}
                <FlexGridItem marginBottom='0px' selfStretch widthMobile='100%' width='85%' grow={2} justifyCenter itemsFlexEnd>
                    <FlexGrid heightTablet='100%' height='100%' noWrap directionColumn>
                        <TextAreaInputWithLabel
                            onKeyPress={onKeyPress}
                            onChange={onChange}
                            placeholder={placeholder}
                            name={name}
                            register={register}
                            validate={null}
                            minHeight={files.length > 0 ? '80%' : '100%'}
                            showMarkLabel={false}
                            extendedFeature={files.length > 0}
                            fontSize={fontSize}
                            marginBottom='0px'
                            htmlId={nanoid()}
                            radius={isMobileOnly ? '0px' : '8px'}
                        />
                        {files.length > 0 && (
                            <ExtendedWrapper>
                                <FlexGridItem overflow='auto' height='40px'>
                                    <FlexGrid>
                                        <FlexGridItem width='100%' maxWidth='100%' justifyFlexStart>
                                            <FlexGrid>
                                                <Wrapper overflow={true}>
                                                    {files.map((f, i) => {
                                                        return (
                                                            <React.Fragment>
                                                                <FileName fileName={f.name} clearFile={() => clearFile(f.fileuploadId)} />
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </Wrapper>
                                            </FlexGrid>
                                        </FlexGridItem>
                                    </FlexGrid>
                                </FlexGridItem>
                            </ExtendedWrapper>
                        )}
                    </FlexGrid>
                </FlexGridItem>
                {!isMobileOnly && (
                    <FlexGridItem hideMobile widthMobile='18%' width={isTablet ? '15%' : isSmallLaptop ? '10%' : '8%'} grow={1} shrink={0} itemsCenter justifyFlexEnd>
                        <IconLink
                            title={t('Send message')}
                            onClick={isMessageFilled ? sendMessage : () => {}}
                            iconKey='send'
                            // backgroundcolor={isMessageFilled ? theme.color.primary : theme.color.color90}
                            backgroundcolor={
                                isMessageFilled ?
                                 theme.color.primary
                                    : theme.color.color90
                            }
                            color={theme.color.color0}
                            width='55px'
                            height='55px'
                            noHover
                            borderradius='100%'
                            fontSize={theme.fontSize.headline3}
                        />

                        
                    </FlexGridItem>
                )}
                <MessageAction>
                    <MessageActionIcon>
                        <TextFieldsRoundedIcon sx={{ color: theme.color.primary }} />
                        <SimpleUploader
                            title={t('Upload')}
							iconKey='attach_file'
                            onFileAdded={(files) => openSendAttachment(files, accountId)}
							onFilesAdded={(files) => openSendAttachment(files, accountId)}
							width={'2.2em'}
							height={'1em'}
                        />
                        <SimpleUploader
                            title={t('Upload')}
							iconKey='photo_camera'
							capture
                            onFileAdded={(files) => openSendAttachment(files, accountId)}
							onFilesAdded={(files) => openSendAttachment(files, accountId)}
							width={'2.2em'}
							height={'1em'}
                        />
                    </MessageActionIcon>
                    <SendingButton onClick={sendMessage} disabled={!isMessageFilled} isMessageFilled={isMessageFilled}>
                        {t('Send')}
                    </SendingButton>
                </MessageAction>
            </MesssageInputContainer>
        </React.Fragment>
    )
}

export default withTheme(ChatTextArea)
