import { call, put, takeEvery } from '@redux-saga/core/effects'
import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { activateAccountFailed, activateAccountSucceed, getAccountPublicInfoFailed, updateSelectedAccountDetails } from 'application/redux/actions/common/account_details'
import { updateDataAccountById } from 'application/redux/actions/common/all_accounts'
import { getCommonAllAktenRequest } from 'application/redux/actions/common/all_akten'
import { closeDialogByName } from 'application/redux/actions/dialogs'
import { handleError } from 'application/redux/saga/errors'
import { getPublicAccountInfo } from './get_public_info_account'

function* activateAccountSaga(action) {
    try {
		yield call(api.gateway.common.postActivateAccountById, action.accountId, action.payload)
        yield put(activateAccountSucceed(action.accountId))
        yield put(updateSelectedAccountDetails({isActivated: true}))
		yield put(updateDataAccountById(action.accountId, { isActivated: true }))
		
		if (action.payload.closeDialog) {
			yield put(getCommonAllAktenRequest())
			yield put(closeDialogByName(action.payload.dialogName))
		}
    } catch (e) {
        yield put(activateAccountFailed(action.accountId))
        yield handleError(e, action)
    }
}
function* getPublicInfoAccountSaga(action) {
    try {
        yield getPublicAccountInfo(action)
    } catch (e) {
        yield put(getAccountPublicInfoFailed())
        yield handleError(e, action)
    }
}

export function* accountDetailsWatcher() {
    yield takeEvery(ACTIONS.COMMON_ACTIVATE_ACCOUNT_REQUEST, activateAccountSaga)
    yield takeEvery(ACTIONS.COMMON_GET_ACCOUNT_PUBLIC_INFO, getPublicInfoAccountSaga)
}