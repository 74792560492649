import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

const StyledFilterPill = styled.div`
    width: ${props => props.width};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    opacity: ${props => props.disabled ? props.theme.disabledOpacity : '1'};

    :hover {
        background: ${   props => props.disabled ? 'none' :
        props => props.hoverBackground ? props.hoverBackground : props.theme.color.black10};
        color: ${props => props.theme.color.black};
    }

    :active {
        background: ${props => props => props.disabled ? 'none' : props.activeBackground ? props.activeBackground : props.theme.color.black20};
        color: ${props => props.theme.color.black};
    }

    border: 1px solid ${props => props.isActive ? props.theme.color.filterPill : props.theme.color.gray15};
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.standard};
    display: flex;
    justify-content: ${props => props.withIcon ? 'flex-start' : 'center'};
    flex-direction: row;
    flex-wrap: nowrap;
    height: 35px;
    border-radius: 16px;

    
    & > i:first-child {
        color: ${props => props.iconColor ? props.iconColor : props.theme.color.anthracite};
        font-size: 16px;
        align-self: center;
        padding-left: 8px;
    }

    & > span {
        white-space: nowrap;
        align-self: center;
        padding-left: 4px;
        padding-right: 4px;
        color: ${props => props.isActive ? props.theme.color.filterPill : props.theme.color.anthracite};
    }   

    & > i:last-child {
        font-size: 12px;
        align-self: center;
        padding-right: 8px;
        min-width: 12px;
        color: ${props => props.isActive ? props.theme.color.filterPill : props.theme.color.anthracite};
    }

    margin-right: 4px;
    &:last-child{
        margin-right: 0;
    }
`

const FilterPill = ({
    pillClassName = '',
    onPillClick,
    onPillAbort,
    hoverBackground = '',
    activeBackground = '',
    iconKey,
    iconColor = '',
    text = '',
    disabled = false,
    isActive = true,
    withIcon = true,
    width = '100%',
}) => {

    var activeIcon = isActive ? 'check' : ''

    const pillClick = () => {
        if(!disabled){
            if(isActive){
                onPillAbort()
            }else{
                onPillClick() 
            }
        }
    }

    return <StyledFilterPill
        className={pillClassName}
        hoverBackground={hoverBackground}
        activeBackground={activeBackground}
        iconColor={iconColor}
        disabled={disabled}
        isActive={isActive}
        onClick={pillClick}
        width={width}
        withIcon={withIcon}
    >
        {
            withIcon ? <Fragment>
                <i className="material-icons">{iconKey}</i>
                <span>{text}</span>
                <i className="material-icons">{activeIcon}</i>
            </Fragment> : <span>{text}</span>
        }
    </StyledFilterPill>
}

FilterPill.propTypes = {
    pillClassName: PropTypes.string,
    onPillClick: PropTypes.func,
    onPillAbort: PropTypes.func,
    hoverBackground: PropTypes.string,
    activeBackground: PropTypes.string,
    iconKey: PropTypes.string,
    iconColor: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool
}

export default FilterPill


