import { connect } from 'react-redux'
import MessageDetailsComponent from './component'
import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import {
    getMessageDetails,
    getMessageDetailsOnFolder,
    populateBreadscrumbsData,
    setBreadscurmbsView,
    setSelectedAkte,
    setSelectedFolder,
} from 'application/redux/actions/pages/akten'
import { sendFormRequest } from 'application/redux/actions/form'
import { getMessageFavorite, refreshRegisteredMessage, registerMessage, updateDataSelectedMessageFolder } from 'application/redux/actions/messages'
import { showGallery } from 'application/redux/actions/gallery'
import { downloadFile, getFullFileById } from 'application/redux/actions/files'
import { loginAdHoc } from 'application/redux/actions/auth/adhoc_access'
import { accessTokenAdhoc, accessTokenForm } from 'application/storage/access_token'
import { getMainLogo } from 'application/redux/actions/common/all_accounts'


const mapStateToProps = (state, props) => {
    const registered = state.messages.registered
    const isMessage = registered.length > 0 && registered.filter((r) => r.key === props.messageKey).length > 0
    const messageFullLoaded = registered.length > 0 && registered.filter((r) => r.key === props.messageKey && r.isFullLoaded).length > 0
    const metaMessage = isMessage && registered.find((r) => r.key === props.messageKey)
    const metaRootMessage = isMessage && registered.find((r) => r.key === props.rootKey)
    return {
        messageFullLoaded,
        registeredMessages: registered,
        metaMessage,
        metaRootMessage,
        metaInfo: metaMessage.metaInfo,
        selectedFolder: state.pages.akten.common.selectedFolder,
        breadcrumbsData: state.pages.akten.common.breadcrumbsData,
        waitingScreen: state.waitingScreen,
        isMessageRequesting: state.pages.akten.details.isRequest,
        isSendingMessage : state.pages.akten.action.sendMessage.request,
        auth: state.auth,
        sendSucceed: state.form.send.succeed,
        mainLogo: state.files.mainLogo
    }
}

const mapDispatchToProps = (dispatch) => ({
  getMessageDetails: (
    accountId,
    akteIdHash,
    params,
    messageKey,
    messageType
  ) => {
    dispatch(registerMessage(messageKey, messageType))
    dispatch(
      getMessageDetails(accountId, akteIdHash, params, messageKey, messageType)
    )
  },
  getMessageDetailsOnFolder: (
    accountId,
    akteIdHash,
    folderId,
    params,
    messageKey,
    rootKey,
    messageType
  ) => {
    dispatch(registerMessage(messageKey, messageType))
    dispatch(
      getMessageDetailsOnFolder(
        accountId,
        akteIdHash,
        folderId,
        params,
        messageKey,
        rootKey
      )
    )
  },
  refreshRegisteredMessage: (key) => dispatch(refreshRegisteredMessage(key)),
  setSelectedAkte: (payload) => dispatch(setSelectedAkte(payload)),
  setSelectedFolder: (payload, params, akteIdHash, accountId) => {
    dispatch(setSelectedFolder(payload))
    dispatch(
      updateDataSelectedMessageFolder(
        params,
        payload.id,
        akteIdHash,
        accountId,
        payload
      )
    )
  },
  setBreadcumData: (condition, payload) => {
    dispatch(populateBreadscrumbsData(payload))
    dispatch(setBreadscurmbsView(condition))
  },
  onOpenGallery: (
    fileId,
    fileName,
    accountId,
    parentId,
    akteIdHash,
    thumbnails
  ) => {
    dispatch(getFullFileById(fileId, fileName, {
      accountId: accountId, 
      storage: accessTokenAdhoc
    }))
    dispatch(
      showGallery(fileId, fileName, accountId, parentId, akteIdHash, thumbnails, true)
    )
  },
  onOpenGalleryThumbnail: (
    selectedId,
    selectedName,
    selectedAccountId,
    parentId,
    akteIdHash,
    thumbnails
  ) =>{
      thumbnails.forEach((t) => dispatch(getFullFileById(t.id, t.fileName, {
          accountId: selectedAccountId,
          storage: accessTokenAdhoc,
      })))
      dispatch(
          showGallery(
              selectedId,
              selectedName,
              selectedAccountId,
              parentId,
              akteIdHash,
              thumbnails,
              true
          )
      )
  },
  getMessageFavorite: (messageKey, accountId, akteIdHash, folderId) => {
    dispatch(getMessageFavorite(messageKey, accountId, akteIdHash, folderId))
  },
  getMainLogo: (accountId) => {
    dispatch(getMainLogo(accountId))
  },
  openAccountStatement: (statement, title) =>
    dispatch(
      openDialogByName(
        DIALOG.NAME.DISPLAY_ACCOUNT_STATEMENT,
        true,
        { statement },
        title
      )
    ),
  onSendFormRequest: (workflowKey, formData) => {
    dispatch(sendFormRequest(workflowKey, formData, true))
  },
  loginAdHoc: (workflowKey) => dispatch(loginAdHoc(workflowKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageDetailsComponent)
