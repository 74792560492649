export const getDataSelectedAkte = (selectedAkte) => {
    return {
        accountId: selectedAkte === null ? '' : selectedAkte.accountId,
        accountName: selectedAkte === null ? '' : selectedAkte.accountName,
        header: selectedAkte === null ? '' : selectedAkte.header,
        akteIdHash: selectedAkte === null ? '' : selectedAkte.idHash,
        aktenkurzbezeichnung: selectedAkte === null ? '' : selectedAkte.aktenkurzbezeichnung,
        permissionToReply: selectedAkte === null ? true : selectedAkte.permissionToReply,
        serviceAkte: selectedAkte === null ? false : selectedAkte.serviceAkte,
        mainColor: selectedAkte === null ? '' : selectedAkte.mainColor,
        secondaryColor: selectedAkte === null ? '' : selectedAkte.secondaryColor,
    }
}