import ActionButton from 'application/components/controls/button/action_button/component'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { Headline1 } from 'application/components/fragments/typography'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { ROUTES } from 'application/constants'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

export function NotFoundBigFoot({imgUrl}){
    const theme = useTheme()
    const { config } = usePluginsContext()
    const { t } = useTranslation()
    useEffect(() => {
        document.title = theme.title
        document.querySelector('link[rel~=icon]').href = theme.favicon
    }, [theme])
    return (
      <div
        style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            fontFamily: theme.fontFamily.primary,
            minHeight: '100vh',
            justifyContent: 'center',
            padding: isMobile && '0 5vw'
        }}
      >
        <ImagePlaceHolder
            withBorder={false}
            large={isMobile ? '80vw' : '300px'}
            source={config.companyIcon}
        />
        <Headline1 style={{textAlign:'center', margin: '10vh 0 3vh 0'}}>
        Seite wurde nicht gefunden.
        </Headline1>
        <ActionButton linkTo={ROUTES.BASE} fontSize={'14px'} buttonText={t('ErrorRedirectButtonText')} strechted={isMobile} />
      </div>
    )
}