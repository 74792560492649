export const de = {
    translation: {
        'Akten': 'Akten',
        'NoCasesFound': 'Keine Akte vorhanden',
        'NoMessageFound': 'Keine Nachricht vorhanden',
        'SelectOneCase': 'Wählen Sie eine Akte aus',
        'select_all': 'Alle auswählen',
        'to case': 'Zum Fall',
        'deselect_all': 'Alle abwählen',
        'task management': 'Task Management',
        'unreleased': 'Nicht freigegeben',
        'not assigned': 'Nicht zugewiesen',
        'deadlines': 'Fristen',
        'inprogress': 'In Bearbeitung',
        'inreview': 'Zu prüfen',
        'done': 'Abgeschlossen',
        'All': 'Alle',
        'Today': 'heute',
        'select contact person' : 'Ansprechpartner auswählen',
        'withoutAgent': 'ohne Bearbeiter',
        'release': 'Freigeben',
        'case': 'Fall',
        'cases': 'Akten',
        'templates': 'Vorlagen',
        'template': 'Vorlage',
        'yes': 'JA',
        'no': 'NEIN',
        'rejected': 'Abgelehnt',
        'edit task template': 'Aufgaben-Vorlage bearbeiten',
        'discardChange': 'Änderung verwerfen?',
        'accept': 'Annehmen',
        'decline': 'Ablehnen',
        'Notifications': 'Meldungen',
        'lastOpenedCases': 'Zuletzt geöffnete Fälle',
        'New comments': 'Neue Kommentare',
        'Deadline due': 'Frist erreicht',
        'caseName': 'Fallname',
        'assignePerson': 'Bearbeiter',
        'assignment': 'Aufgabe',
        'assignments': 'Aufgaben',
        'lists': 'Liste',
        'details': 'Details',
        'date': 'Datum',
        'taskOverview': 'Aufgabenübersicht',
        'todo': 'Zu Bearbeiten',
        'todo by others': 'Zu Bearbeiten Durch Dritte',
        'immediately' : 'Sofort',
        'later': 'Später',
        'overview': 'Übersicht',
        'documents': 'Dokumente',
        'document': 'Dokument',
        'photo': 'foto',
        'active': 'Aktive',
        'deactive': 'Geschlossen',
        'searchCasesByName': 'Nach Fallnamen suchen...',
        'Edit task': 'Aufgabe bearbeiten',
        'upload documents': 'Dokumente hochladen',
        'upload more documents': 'weitere Dokumente hochladen',
        'drag your files here to upload them': 'Ziehen Sie Dateien hierher, um sie hochzuladen',
        'file' : 'Ordner',
        'h': 'Uhr',
        'history_state_assigned': 'Zugewiesen',
        'history_state_created': 'Angelegt',
        'history_state_closed': 'Abgeschlossen',
        'Company': 'Unternehmen',
        'Contact Persons': 'Ansprechpartner',
        'no contact persons assigned': 'keine Ansprechpartner zugeordnet',
        'edit': 'Bearbeiten',
        'logout': 'Ausloggen',
        'editing' : 'Bearbeitung',
        'search by templates cases or assignment': 'Nach Fallvorlage oder Aufgabenvorlage suchen...',
        'newAssignmentsTemplate' : 'Aufgaben-Vorlage',
        'Task description': 'Augabenbeschreibung',
        'Since': 'Seit',
        'close': 'Schließen',
        'should_case_be_closed': 'Soll Fall <1>{{name}}</1>  geschlossen werden  ?',
        'Resolve task': 'Aufgabe abschließen',
        'Forward task': 'Aufgabe weitergeben',
        'last name': 'Nachname',
        'first name' : 'Vorname',
        'email address' : 'E-Mail-Adresse',
        'phone' : 'Telefon',
        'mobile phone' : 'Handy',
        'role' : 'Rolle',
        'select role' : 'Rolle auswählen ...',
        'name from company' : 'Name des Unternehmens',
        'street' : 'Straße',
        'house number' : 'Hausnummer',
        'zip code' : 'Postleitzahl',
        'place' : 'Ort',
        'title' : 'Titel',
        'general' : 'Allgemeines',
        'due date' : 'Fälligkeit',
        'contact person' : 'Ansprechpartner',
        'please select' : 'Bitte auswählen',
        'without template' : 'Ohne Vorlage',
        'save' : 'Speichern',
        'cancel' : 'Abbrechen',
        'create case' : 'Neuer Fall',
		'without deadline': 'Ohne Fälligkeit',
		'Password hint': 'Passworthinweis',
        'before' : 'vorher',
        'copy case': 'Fall kopieren',
        'delete information from company and contact person' : 'Unternehmens und Ansprechpartner-infos leeren',
        'add information on case': 'Fall-Infos hinzufügen',
        'no mandatory when submitting!' : 'bei Vorlage kein Mandatory!',
        'difference' : 'Unterschied',
        'save as template' : 'Als Vorlage speichern',
        'create case from template' : 'Fall aus Vorlage erstellen',
        'copy' : 'Kopieren',
        'add document' : 'Dokument hinzufügen',
        'download document' : 'Dokumente herunterladen',
        'show history' : 'Historie anzeigen',
        'delete' : 'Löschen',
        'add assignment' : 'Aufgabe hinzufügen',
        'add lists' : 'Liste hinzufügen',
        'sort by' : 'Sortieren nach',
        'menu' : 'Menü',
        'close case' : 'Fall schliessen',
        'save case as template' : 'Als Vorlage speichern',
        'additionally save as a task template' : 'Zusätzlich als Aufgaben-Vorlage speichern',
        'without a parent list' : 'Ohne übergeordnete Liste',
        'parent task list' : 'Übergeordnete Aufgaben-Liste',
        'priority' : 'Priorität',
        'without priority' : 'Ohne Priorität',
        'assign task' : 'Aufgabe zuweisen',
        'forward task' : 'Aufgabe weitergeben',
        'was successfull forwarded' : ' wurde erfolgreich weitergegeben',
        'email' : 'E-Mail',
        'messages': 'Nachricht',
        'external assign person' : 'Bearbeiter-Extern',
        'low' : 'Niedrig',
        'middle' : 'Mittel',
        'high' : 'Hoch',
        'hide' : 'Ausblenden',
        'do you have a comment?' : 'Haben Sie einen Kommentar?',
        'you can use the comment feature for inquiries': 'Sie können die Kommentarfunktion für Rückfragen nutzen.', 
        'write an info to the client...' : 'Schreiben Sie eine Info an den Mandanten...',
        'task description' : 'Aufgabenbeschreibung',
        'add description...' : 'Beschreibung hinzufügen...',
        'create task' : 'Neue Aufgabe',
        'create task for template' : 'Neue Aufgaben-Vorlage',
        'copy task' : 'Aufgabe kopieren',
        'save task as template' : 'Als Vorlage speichern',
        'create task from template' : 'Aufgabe aus Vorlage erstellen',
        'validationNotSelected' : 'Mindestens eins muss ausgewählt werden',
        'mandatory field' : 'Pflichtfeld',
        'validationNotSelectedOnDate' : 'Datum muss gewählt werden',
        'validationIsEmpty' : 'Pflichtfeld',
        'validationNotZipCode' : 'Bitte verwenden Sie die richtige Postleitzahl',
        'Unexpected error': 'Unerwarteter Fehler',
        'should_user_be_deleted': ' Soll Nutzer <1>{{name}}</1>  gelöscht werden  ? ',
        'should_task_be_deleted': ' Soll Task <1>{{title}}</1>  gelöscht werden  ? ',
        'should_template_be_deleted': ' Soll Vorlage <1>{{title}}</1>  gelöscht werden  ? ',
        'user_assigned_this_task_do_you_want_to_take_them': '<0>{{name}}</0> hat ihnen diese Aufgabe zugewiesen. Wollen Sie das nehmen?',
        'you have been assigned' : 'Ihnen wurde Aufgabe zugewiesen',
        'validationNotEmail' : 'Ungultige E-Mail-Adresse',
        'validationNotPhoneNumber' : 'Ungultige Telefonnummer',
        'is mandatory field' : 'ist Pflichtfeld',
        'validationValueTooLong' : 'Zu lang eingegeben',
        'zipcode' : 'Postleitzahl',
        'user_administration' : 'Benutzerverwaltung',
        'userDetails': 'Benutzer Details',
        'username': 'Benutzername',
        'password': 'Passwort',
        'legal notice' : 'Impressum',
        'privacy' : 'Datenschutz',
        'conditions' : 'AGB',
        'login' : 'Einloggen',
        'Login' : 'Anmelden',
        'ReturnLoginPage': 'Zurück zum Einloggen',
        'deutsch' : 'Deutschland',
        'english' : 'England',
        'salutation': 'Anrede',
        'company': 'Firma',
        'mobile': 'Telefon',
        'User Administration': 'Benutzerverwaltung',
        'user' : 'Benutzer',
        'has been successfull deleted' : 'wurde erfolgreich gelöscht',
        'could not be deleted' : 'kann nicht gelöscht werden',
        'has been successfull closed' : 'wurde erfolgreich geschlossen',
        'could not be closed' : 'kann nicht geschlossen werden',
        'your contact': 'Ihr Kontakt',
        'LastWeek': 'Letzte Woche',
        'LastMonth': 'Letzter Monat',
        'Older' : 'Älter',
        'NoDocumentsFound': 'Es sind keine Dokumente vorhanden',
        'EmptyDocumentsMessage': 'Hier werden Dokumente aus Nachrichten angezeigt, die Sie gelesen oder an die Kanzlei gesendet haben.',
        'has been successfull copied': 'wurde erfolgreich kopiert',
        'Copy': 'Kopie',
        'task not found': 'Aufgabe ist nicht vorhanden',
        'questions': 'Rückfragen',
        'reminder': 'Erinnern',
        'cleared': 'Geklärt',
        'finish': 'Abschließen',
        'delete task' : 'Aufgabe löschen',
        'task': 'Aufgabe',
        'closed' : 'Geschlossen',
        'PasswordStrength': 'Passwortstärke',
        'passwordCriteriaHeading': 'Ihr Passwort muss 3 dieser 4 Kriterien erfüllen:',
        'passwordLengthCriteriaHeading': 'Außerdem muss Ihr Passwort:',
        'passwordSpecialCriteriaHeading': 'Ihr Passwort darf außerdem:',
        'includesLowerCaseCharacter': 'Mindestens ein Kleinbuchstabe muss enthalten sein.',
        'includesUpperCaseCharacter': 'Mindestens ein Großbuchstabe muss enthalten sein.',
        'includesSpecialCharacter': 'Mindestens ein Sonderzeichen muss enthalten sein.',
        'includesNumericCharacter': 'Mindestens eine Zahl muss enthalten sein.',
        'validationPasswordCriterias': 'Weniger als 3 Kriterien erfüllt',
        'validationPasswordMinlength': 'Passwort Mindestlänge: 8 Zeichen',
        'validationPasswordBlacklisted': 'Passwort nicht erlaubt',
        'validationPasswordUsernameIncluded': 'Benutzername enthalten',
        'registration now': 'Jetzt registrieren',
        'please check before proceeding': 'Bitte überprüfen Sie, bevor Sie fortfahren',
        'passwordNotBlacklisted': 'Password darf nicht trivial sein (z.B.“12345678“).',
        'passwordMinLength': 'Passwort muss mindestens 8 Zeichen lang sein.',
        'usernameNotIncluded': 'Password darf nicht den von Ihnen gewählten Benutzernamen enthalten.',
        'i have read and accept the terms and conditions': 'Ich habe die AGB gelesen und akzeptiert',
        'delete case': 'Fall löschen',
        'case not found': 'Fall konnte nicht gefunden werden',
        'should_case_be_deleted': ' Soll Fall <1>{{title}}</1>  gelöscht werden  ? ',
        'SomethingWentWrong': 'Für die eingegebene URL wurde kein Inhalt gefunden.',
        'oClock': 'Uhr',
        'at': 'um',
        'edit case': 'Fall bearbeiten',
        'copy case template': 'Fallvorlage kopieren',
        'edit case template': 'Fallvorlage editieren',
        'delete case template': 'Fallvorlage löschen',
        'create task template': 'Aufgabenvorlage erstellen',
        'No tasks available with this status': 'Keine Aufgaben mit diesem Status vorhanden',
        'Enter PIN': 'PIN eingeben',
        // welcome page translate
        'Communication with the lawyer': 'Kommunikation mit dem Anwalt*innen',
        'WelcomeTextTopWithHooray': 'Hurra - du hast den richtigen Anwalt*innen gefunden! Langes Prozedere und E-Mail-Schlachten gehören der Vergangenheit an: Mit der App kommunizierst du e.sy per Chat. Du entscheidest selbst, wann und wo du deine Nachrichten schreibst. Alles im Nu möglich mit dem e.syOne: Kontakt mit dem Anwalt*innen war nie einfacher!',
        'Four steps to get started with our free app': 'In vier Schritten zum Start mit unserer kostenlosen App',
        'Download the app': 'App downloaden',
        'Smartlink received from lawyer': 'Smartlink vom Anwalt*innen erhalten',
        'Register': 'Registrieren',
        'Lets go': 'Los geht\'s',
        'e.syOne - your paperless exchange for legal stuff': 'e.syOne – dein papierloser Austausch für juristischen Kram',
        'Simple - communication via chat': 'Einfach - Kommunikation per Chat',
        'Safe - thanks to WebAkte': 'Sicher - dank WebAkte',
        'Fast - all information at a glance': 'Schnell – alle Infos auf einen Blick',
        'Efficient - time and location-independent exchange': 'Effizient – Zeit und ortsunabhängiger Austausch',
        'Simply clarify legal issues': 'Einfach juristische Anliegen klären',
        'InfoWithBildOne': 'Lehnen Sie sich zurück und klären Sie Ihre rechtlichen Fragen bequem vom Sofa aus. Melden Sie sich mit Ihrem Fingerabdruck oder Face ID an und lösen Sie Ihre Probleme in aller Ruhe. Keine Sorge - alle Ihre Daten sind sicher.',
        'zum Dashboard': 'zum Dashboard',
        'Write your message to ': 'Bitte schreiben Sie hier Ihre Nachricht an ',
        'InfoWithBildTwo': 'Maximale Selbstbestimmung - Ihre Akte ist auf Ihrem Smartphone immer dabei, egal wo Sie sind. Sie beantworten die Fragen Ihres Anwalts, wann es Ihnen passt.',
        'Dont worry - all of your data is safe.': 'Keine Sorge - alle Ihre Daten sind sicher.',
        'InfoWithBildThree': 'Darauf können Sie sich verlassen: Mehr als 670.000 Kunden vertrauen auf unsere Softwarelösungen und Dienstleistungen. Wir sind seit 20 Jahren fest in der Rechtsbranche verankert. Über 18.000 Anwaltskanzleien nutzen täglich unsere einfachen und sicheren Lösungen.\n\n',
        'InfoWithBildThreeOne': 'Unser buntes Team besteht aus Experten verschiedener Disziplinen. Ein engagiertes Team aus zehn Kulturen steckt jeden Tag die Köpfe zusammen. Mit dem Ziel, dass Menschen wie Sie und ich rechtliche Angelegenheiten schnell und einfach lösen können.',
        'InfoWithBildFour': 'Worauf warten Sie noch? Machen Sie den ersten Schritt und lösen Sie Ihre Rechtsangelegenheiten so einfach wie nie zuvor. Sie finden das e.syOne kostenlos in der',
        'Languages': 'Sprache',
        'Forgot Username': 'Benutzername vergessen',
        'Forgot Password': 'Passwort vergessen',
        'This field is required.': 'Dies ist ein Pflichtfeld',
        'Search Akten': 'Aktensuche',
        'Messages': 'Nachrichten',
        'MessagesNavigationBar': 'Nachrichten',
        'Service Provider': 'Dienstleister',
        'Contact information': 'Kontaktinformation',
        'Unread Messages': 'ungelesene Nachrichten',
        'Settings': 'Einstellungen',
        'You Have Successfully Submitted Your Answer': 'Sie Haben Ihre Antwort Erfolgreich Gesendet',
        'There Is No Task': 'Keine Aufgabe',
        'General Settings': 'Allgemein',
        'Account Settings': 'Profildaten',
        'Profile Picture': 'Profilbild',
        'AccountDescription': 'AccountDescription',
        'Profile Setting': 'Profildaten',
        'Profile picture, Password': 'Profilbild, Passwortänderung',
        'General': 'Allgemein',
        'Language': 'Sprache',
        'About': 'Über uns',
        'AGB, Contact Us': 'AGB, Kontakt',
        'About Settings': 'Über uns',
        'Contact us': 'Kontaktieren Sie uns:',
        'Send': 'Absenden',
        'Email': 'E-Mail',
        'Open': 'Offen',
        'Done': 'Erfasst',
        'Expired': 'Abgelaufen',
        'EmailNotEmpty': 'Bitte geben Sie hier Ihre registrierte E-Mail Adresse ein',
        'Description': 'Beschreibung',
        'Question':'Frage',
        'Set profile picture': 'Neues Bild auswählen',
        'ExistingPassword': 'Altes Passwort',
        'ExistingPasswordNotEmpty': 'Altes Passwort darf nicht leer sein',
        'New Password': 'Neues Passwort',
        'Password confirmation': 'Passwort bestätigen',
        'Save password': 'Passwort speichern',
        'Please check your e-mail': 'Sie sollten eine E-Mail von e.syOne bekommen.',
        'Please enter a valid email adress.': 'Please enter a valid email adress.',
        'Herzlichen Glückwunsch!': 'Herzlichen Glückwunsch!',
        'External Request': 'Online beauftragen',
        'ResetPassword': 'Passwort zurücksetzen',
        'PasswordResetSuccess': 'Das Passwort wurde erfolgreich geändert.',
        'ForgotPassword': 'Passwort vergessen',
        'PasswordResetLinkError': '🔥 Link zum Zurücksetzen des Passworts kann nicht gesendet werden',
        'PasswordResetLinkSuccess': '📧 Der Link zum Zurücksetzen des Passworts wurde an deine E-Mail gesendet',
        'PasswordForgotSubHeadline': 'Bitte geben Sie Ihren Benutzernamen ein. Sie erhalten einen Link per E-Mail, um Ihr Passwort zu erneuern.',
        'ForgotUsername': 'Benutzername vergessen',
        'ForgotUsernameSubHeadline': 'Bitte geben Sie Ihre E-Mail-Adresse ein. Wir werden Ihnen Ihren Benutzernamen zusenden.',
        'UsernameResetLinkError': '💥 Benutzername kann nicht gesendet werden.',
        'UsernameResetLinkSuccess': '📣 Benutzername wurde an Ihre E-Mail-Adresse gesendet.',
        'RequiredFieldValidation': 'Dies ist ein Pflichtfeld.',
        'EmailValidation': 'Bitte geben Sie Ihre gültige E-Mail-Adresse ein.',
        'CheckReceivedEmail': 'Sie sollten eine E-Mail von e.syOne erhalten.',
        'SubmitButtonText': 'Senden',
        'GainAccessTitle': 'Zugang erhalten',
        'GainAccessSubTitle': 'Aus Sicherheitsgründen und zur eindeutigen Identifizierung fordern Sie bitte eine PIN an.',
        'RequestPIN': 'PIN anfordern',
        'RequestPINError': 'PIN kann nicht gesendet werden.',
        'RequestPINSuccess': 'PIN wurde gesendet.',
        'RequestPINSuccessEmail': 'PIN wurde an Ihre E-Mail gesendet.',
        'RequestPINSubTitle': 'Bitte geben Sie hier Ihre 6-stellige PIN ein, die Sie per E-Mail oder SMS erhalten haben.',
        'RequestPINQuestion': 'Sie haben keine PIN erhalten?',
        'RequestPINAnother' : 'Neuen PIN anfordern',
        'EsyMandantSalutation': 'Herzlich willkommen zum e.syOne',
        'EsyMandantSalutationText': 'Lösen Sie Ihre rechtlichen und steuerlichen Probleme im Handumdrehen.',
        'FirstStep': 'Los geht\'s', 
        'EsyMandantRegistration': 'Melden Sie sich jetzt an!',    
        'EsyMandantRegistrationSubTitle': 'Und erhalten Sie direkten Zugriff auf Ihre Dateien.',
        'EsyMandantPasswordConfirmation': 'Nur noch ein Schritt - Passwort vergeben.',
        'BackButtonText':'Zurück',
        'NextButtonText': 'Weiter',
        'PasswordInputMatchingError': 'Passworteingabe stimmt nicht überein!',
        'Files':'Dateien',
        'Search files': 'Dateien suchen',
        'TextOnDownload': 'e.syOne ist für Smartphones nur als App verfügbar.',
        'AddDocuments': 'Dateien hinzufügen',
        'Favorites': 'Favoriten',
        'Good morning.': 'Guten Morgen.',
        'Good afternoon.': 'Guten Tag.',
        'Good evening.': 'Guten Abend.',
        'Request': 'Anfrage',
        'Go eat lunch.': 'Mahlzeit.',
        'Welcome': 'Willkommen',
        'Registration': 'Registrierung',
        'Confirmation': 'Bestätigung',
        'PrivacyExplanationText': 'Indem ich auf "Einloggen" klicke, akzeptiere ich die e.syOne <1>Servicebedingungen</1> und bestätige, dass ich die <3>Datenschutzerklärung</3> und <5>AGB</5> von e.syOne gelesen habe.',
        'PrivacyExplanationFees': 'Es können Gebühren für Nachrichten und Daten anfallen. Indem ich auf "Einloggen" klicke, akzeptiere ich die e.syOne <1>Servicebedingungen</1> und bestätige, dass ich die <3>Datenschutzerklärung</3> und <4>AGB</4> von e.syOne gelesen habe.',
        'PrivacyExplanationPIN': 'Indem ich auf "PIN anfordern" klicke, akzeptiere ich die e.syOne <1>Servicebedingungen</1> und bestätige, dass ich die <3>Datenschutzerklärung</3> und <4>AGB</4> von e.syOne gelesen habe.',
        'ReadMessage': 'Nachricht lesen',
        'EmptySharedMessageContent': 'Keine Nachrichten vorhanden.',
        'LoadMoreService': 'weitere Akten',
        'EnlargeImage': 'Vergrößern',
        'RotateImage': 'Drehen',
        'ChangeProfilePicture': 'Profilbild durchsuchen',
        'ForBusiness': 'Für Unternehmen',
        'Partners': 'Partner',
        'CongratulationText1':'Jetzt einfach, schnell und sicher mit <1>{{accountName}}</1> kommunizieren',
        'CongratulationText1Kommunizieren': 'kommunizieren',
        'PasswordDontMatch': 'Passwörter stimmen nicht überein',
        'PrivacyExplanationTextCaptcha': 'Diese Seite ist durch reCAPTCHA geschützt. Mit dem Fortfahren bestätige ich, dass ich die Datenschutzbestimmungen von Google gelesen habe und die Nutzungsbedingungen von Google akzeptiere.',
        'UnreadMessage':'ungelesene Nachrichten',
        'CreationDateText': 'Erstellt am',
        'CreationDateTextToday': 'Erstellt',
        'UsernameNotEmpty': 'Bitte Benutzername eingeben.',
        'PasswordSecurityCriteria': 'Das Passwort ist nicht sicher. Bitte beachten Sie unsere Passwortsicherheitskriterien!',
        'PasswordNotEmpty': 'Bitte Password eingeben',
        'PasswordNewNotEmpty': 'Bitte neues Password eingeben',
        'DescriptionNotEmpty': 'Bitte beschreiben Sie die Frage',
        'January': 'Januar',
        'February': 'Februar',
        'March': 'März',
        'April': 'April',
        'May': 'Mai',
        'June': 'Juni',
        'July': 'Juli',
        'August': 'August',
        'September': 'September',
        'October': 'Oktober',
        'November': 'November',
        'December': 'Dezember',
        'Username / Password is incorrect': 'Benutzername / Passwort ist nicht vorhanden',
        'Unauthorized': 'Zugriff verweigert',
        'HideFolder': 'Ordner ausblenden',
        'NewMessageInFolders': 'Neue Nachricht im Ordner',
        'ExploreFolders': 'Ordner durchsuchen',
        'Files with not allowed extensions has been removed':'Dateityp ist nicht erlaubt',
        'PasswordUpdated': 'Passwort wurde aktualisiert',
        'PasswordUpdatedFailed': 'Passwort kann nicht aktualisiert werden',
        'PasswordUpdatedFailed2': 'Sie haben es zu oft versucht. Bitte versuchen Sie es später noch einmal',
        'Form cannot be sent': 'Form kann nicht gesendet werden',
        'Form has been sent': 'Form ist gesendet',
        'choose': 'auswählen',
        'RelatedSearch': 'gesuchte Akte:',
        'Username is already exist': 'Benutzername ist bereits vergeben',
        'Please choose a stronger Password!': 'Bitte geben Sie ein stärkeres Passwort ein',
        'FalsePin': 'Falsche PIN',
        'Invalid PIN. Please request a new PIN': 'Ungültige PIN. Bitte neue PIN anfordern',
        'ForbiddenAccess': 'Zugriff verweigert',
        'UploadLimit': 'Zur Zeit können Sie keine Dateien hochladen. Bitte wenden Sie sich an Ihren Anwalt.',
        'Forbidden access to requested resource': 'Verbotener Zugriff auf die angeforderte Ressource',
        'LinkInvalid': 'Der Link ist ungültig.',
        'Locked' : 'Gesperrt',
        'Not Acceptable': 'Bitte loggen Sie sich mit dem richtigen Benutzernamen ein',
        'Communicating with lawyers has never been easier!': 'Kommunikation mit Anwalt*innen war nie einfacher!',
        'We are happy for your feedback!': 'Wir freuen uns über Ihr Feedback!',
        'About e.syOne': 'Über e.syOne',
        'Set your profile picture': 'Wir ändern Ihr Bild',
        'Image has been uploaded': 'Profile picture ist geändert',
        'Image cannot be uploaded': 'Profile picture ist nicht geändert',
        'Bad Request': 'Ungültige Anforderung',
        'Uploading files': 'Dateien hochladen',
        'Show all files': 'Alle Dateien anzeigen',
        'LawBannerHasAGB': 'Ich habe die <3>AGB</3> von <1>{{companyName}}</1> gelesen und bin damit einverstanden! ',
        'LawBannerHasDatenschutz': 'Bitte informieren Sie sich weiter unter dem <1>Datenschutzhinweis</1> der Kanzlei! ',
        'Activate': 'Aktivieren',
        'activateAccountText': 'Zur Aktivierung dein Profil in dem Account von <1>{{companyName}}</1> bitte klicke "Aktivieren"!',
        'Send message': 'Nachricht senden',
        'Download message': 'Nachricht herunterladen',
        'Favorite message': 'Nachricht favoriten',
        'Open details message': 'Detailnachricht öffnen',
        'Download case': 'Akte herunterladen',
        'Close case details': 'Falldetails schließen',
        'Open case details': 'Falldetails öffnen',
        'Display your favorites message': 'Zeigen Ihre Lieblingsnachricht an',
        'Display your files': 'Zeigen Ihre Dateien',
        'Filtering files': 'Dateien filtern',
        'Close search case': 'Suchvorgang schließen',
		'Close service provider details': 'Details zum Dienstanbieter schließen',
        'Close': 'Schliessen',
        'Delete file': 'Datei löschen',
        'Upload': 'Hochladen',
        'Refresh cases': 'Akten aktualisieren',
        'Need to be activated': 'Muss aktiviert werden',
        'You have successfully submitted your answer': 'Sie haben Ihre Antwort erfolgreich gesendet',
        'Show cases': 'Akten anzeigen',
        'Show it more': 'Mehr anzeigen',
        'Show it less': 'Weniger anzeigen',
        'NotesPassword': 'Hinweis: Das Passwort darf kein triviales Passwort sein. Dazu gehören Passwörter, wie z.B. <1>"12345678"</1>, aber auch Passwörter, die Ihren <3>Benutzernamen</3> oder Ihre <5>E- Mail - Adresse</5> enthalten. Außerdem müssen Zeichenwiederholungen<7>(z.B. "aaa" oder "555")</7> vermieden werden',
        'Account is locked. Login attempt exceeded': 'Konto ist gesperrt. Anmeldeversuch überschritten',
        'Try again in': 'Versuchen Sie es noch einmal in',
		'TimeHourSuffix': ' Uhr',
		'Folders': 'Ordner',
		'No folders have been created yet.': 'Bisher sind in diesem Ordner keine Inhalte hinterlegt.',
		'No favorites have been saved yet.': 'Bisher sind keine Favoriten gespeichert.',
		'No files': 'Keine Dateien',
		'Or log in app.': 'Oder loggen sie sich in app ein.',
		'You do not have access to e.syOne. Please contact your lawyer.': 'Sie haben kein Zugang zu e.syOne. Bitte kontaktieren Sie ihren Anwalt.',
        'Drag your files or images here to start uploading' : 'Dateien oder Bilder hier ablegen',
        'Upload file or image': 'Datei oder Bild hochladen',
        'ValidUntil' : 'gültig bis',
        'ExpiredOn' : 'deaktiviert',
        'AnsweredOn': 'erfasst am',
        'LoginImageRightPath': 'assets/images/image_login.jpg',
        'DashboardMobileNavigationBar': 'Dashboard',
        'ErrorRedirectButtonText': 'Zurück zur Homepage',
        'step': 'Schritt',
        'overview': 'Übersicht',
        'Your assistance is needed' : 'Ihre Mithilfe wird benötigt',
        'In order to follow up your case': 'Um Ihren Fall weiterverfolgen zu können, benötigen wir noch eine angaben von Ihnen',
        'Case status': 'Fallstatus',
        'To your tasks': 'Zu Ihrer Aufgabe',
        'in this akte' : 'in diese Akte',
        'in folders' : 'in Unterordnern',
        'Fall Gemeldet': 'Fall Gemeldet',
        'Fall Wird Geprüft': 'Fall Wird Geprüft',
        'Fall In Bearbeitung': 'Fall in Bearbeitung',
        'Fall Abgeschlossen': 'Fall Abgeschlossen',
        'Ihr Fall wurde erfolgreich versendet.': 'Ihr Fall wurde erfolgreich versendet.',
        'Mandatsannahme steht aus.': 'Mandatsannahme steht aus.',
        'Ihr Fall wurde angenommen.': 'Ihr Fall wurde angenommen.',
        'YourCaseWillbe': 'Ihr Fall wird von uns bearbeitet.',
        'ConfirmTextExternalLinkModalStart': 'Sie werden auf die Webseite ',
        'ConfirmTextExternalLinkModalEnd': ' weitergeleitet. Bitte beachten Sie, dass auf dieser Seite Cookies und datenschutzrelevante Elemente aktiviert werden können. Möchten Sie fortfahren?',
        'NewsOverview' : 'Ungelesene Nachrichten',
        'New Message' : 'Neue Nachricht',
        'Add subject' : 'Bezeichnung',
        'Enter your message': 'Bitte geben Sie hier den Nachrichtentext ein...',
        'Enter a subject' : 'Bitte geben Sie hier die Bezeichnung',
        'DashboardMobileNavigationBar': 'Dashboard',
        'MessageLabel': 'Nachrichtentext',
        'NewPasswordPlaceholder': 'Passwort',    
        'NewPasswordConfirmationPlaceholder' : 'Passwort bestätigen',
        'Dieser Fall wurde bereits beendet.': 'Dieser Fall wurde bereits beendet.',
        'Created on': 'Erstellt am',
        'Add Attachment': 'Dateien auswählen',
        'Upload document': 'Dateien hochladen',
    }
}