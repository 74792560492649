import React, { useContext } from 'react'
import { ForgotCredentialsLayout } from '../_common'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import ActionButton from 'application/components/controls/button/action_button/component'
import { Form, TextInputWithLabel } from 'application/components/controls/form_elements'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import styled, { useTheme } from 'styled-components'
import { nanoid } from 'nanoid'
import { LoginLoadEffect } from '../../login/effects'
import { PluginAlternateView } from 'plugins'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'


const ImageWrapper = styled.div`
    text-align: center;
`

const RequestPasswordComponent = (props) => {
    const { onReqPassword, passwordRequest, passwordSucceed, accountPublicInfo } = props
    const { t } = useTranslation()
    const { getValues, handleSubmit, register, formState: { errors } } = useForm()
    const theme = useTheme()
    const {getAsset} = usePluginsContext()



    const validation = {
        notEmpty: validationMethods.notEmpty
    }

    const headline = passwordSucceed ? t('CheckReceivedEmail') : t('ForgotPassword')
    const errorMessage = t('PasswordResetLinkError')
    const successMessage = t('PasswordResetLinkSuccess')

    const onSubmitSuccess = () => {
        onReqPassword(getValues().username.trim(), errorMessage, successMessage)
    }
    return <React.Fragment>
        <LoginLoadEffect {...props}>
            <ForgotCredentialsLayout
                needDesc={!passwordSucceed}
                headline={headline}
                subHeadline= {t('PasswordForgotSubHeadline')}
                accountPublicInfo={accountPublicInfo}
            >
                <React.Fragment>
                    {
                        passwordRequest ? <PreloaderSpin
                            primaryColor={theme.color.primary}
                        /> : passwordSucceed ? <PluginAlternateView id='request.credentials.mail_sent_image'>
                                <ImageWrapper>
                                    <ImagePlaceHolder toCenter={true} withBorder={false} source={getAsset('mail_sent_1.png')} large='40%' height='40%' />
                                </ImageWrapper>
                            </PluginAlternateView> : <React.Fragment>
                            <Form onSubmit={handleSubmit(onSubmitSuccess)}>
                                <TextInputWithLabel
                                    placeholder={t('UsernamePlaceholderForgot')}
                                    name='username'
                                    register={register}
                                    validate={validation}
                                    error={errors.username}
                                    errorMessageOverride='UsernameNotEmpty'
                                    showMarkLabel={false}
                                    pushBottom={true}
                                    htmlId={nanoid()}
                                />
                                <input
                                    style={{ display: 'none' }}
                                    type='submit'
                                    value='username'
                                />
                            </Form>

                            <PluginAlternateView id="login.button.submit" handleSubmit={handleSubmit} onSubmitSuccess={onSubmitSuccess}>
                                <ActionButton
                                    secondary={true}
                                    buttonText= {t('SubmitButtonText')}
                                    onButtonClick={handleSubmit(onSubmitSuccess)}
                                />
                            </PluginAlternateView>
                        </React.Fragment>
                    }
                </React.Fragment>
            </ForgotCredentialsLayout>
        </LoginLoadEffect>
    </React.Fragment>
}

export default RequestPasswordComponent