import { ROUTES } from 'application/constants'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './use_auth'
import React from 'react'

export function RequireAuth({ children }) {
    let { auth, logoutClicked, onRedirectToLogin } = useAuth()
    let location = useLocation()

    if (auth.unregistered.tokenSet) {
        return children
    } else {
        if (auth.registered.tokenSet) {
            return children
        }
        !logoutClicked && onRedirectToLogin(location.pathname + location.search)


        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
    }

}