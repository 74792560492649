import { FlexGrid } from 'application/components/fragments/grid'
import i18n from 'application/translation'
import i18next from 'i18next'
import React from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import LanguageSelect from './language_select'

const LanguageSelectWrapper = styled.div`
	width: 100%;

	@media (min-width: ${p => p.theme.breakpoint.sm}) {
		width: 40%;
	}
`

export const GeneralSettingForm = (props) => {

    const defaultValues = {
        language: i18next.language
    }
    const { register, getValues, setValue, control } = useForm({ defaultValues })
    const changeLanguage = () => {
        i18n.changeLanguage(getValues().language)
    }

    return <FlexGrid>
        <LanguageSelectWrapper>
            <LanguageSelect
                register={register}
                setValue={setValue}
                initialValue={defaultValues.language}
                onChange={changeLanguage}
                control={control}
            />
        </LanguageSelectWrapper>
    </FlexGrid>
}