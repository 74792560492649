import { isTablet } from 'react-device-detect'
import styled from 'styled-components'

export const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.color.backgroundPrimary};
    /* overflow: auto; */
    overflow: auto;
    height: 100%;
    @media (min-width: 640px) {
        overflow: hidden;
    }
`

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
	backdrop-filter: blur(5px);
    padding: ${(p) => (p.inAkten ? 'unset' : '10px')};
    @media (min-width: 640px) {
        padding: 0px 60px;
        flex: 0.2 0 5%;
		backdrop-filter: unset;
    }
`

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media (min-width: 640px) {
        flex-direction: row;
        flex: 1 0 60%;
    }
`

export const NavWrapper = styled.div`
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 ${isTablet ? '7%' : '10%'};
    padding: 20px 40px;

    @media (min-width: 640px) {
        display: flex;
    }
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.inAkten ? 'row' : 'column')};
    flex: 1 1 ${(props) => (props.inAkten ? '90%' : isTablet ? '80%' : '60%')};
    background-color: transparent;
    overflow: hidden;

    &:hover {
        overflow-y: ${(props) => (props.inAkten ? 'hidden' : props.paddingRight ? 'scroll' : 'auto')};
        @media (min-width: 640px) {
            padding-right: ${(props) => (props.paddingRight ? '32px' : '0px')};
            overflow-y: ${(props) => (props.inAkten ? 'hidden' : props.paddingRight ? 'scroll' : 'auto')};
        }
    }
    @media (min-width: 640px) {
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        background-color: ${(props) => props.theme.color.color0};
        padding-top: ${(props) => (props.paddingTop ? '20px' : '0px')};
        padding-right: ${(props) => (props.paddingRight ? '40px' : '0px')};
        padding-bottom: ${(props) => (props.paddingBottom ? '20px' : '0px')};
        padding-left: ${(props) => (props.paddingLeft ? '40px' : '0px')};
        margin-right: 4px;
    }
`

export const SidebarWrapper = styled.div`
    display: none;
    flex-direction: column;
    flex: 1 1 30%;
    padding: 20px 0px;
    overflow: auto;
    @media (min-width: 640px) {
        display: flex;
    }
`
