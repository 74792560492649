import styled from 'styled-components'

//whole content
export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
`

//left column
export const Main = styled.div`
    display: flex;  
    flex: 1 1 auto;
    flex-direction: column;
    padding-right: 24px;
`

//Headlinebar
export const MainHeader = styled.div`
    display: flex;  
    flex: 0 0 35px;
`
//Table Dashboard
export const MainContent = styled.div`
    display: flex;  
    flex: 1 1 auto;
    margin-top: 32px;
`
//Sidebar - dashboard
export const Sidebar = styled.div`
    display: flex;
    flex: 0 0 350px;
`

export const PushBottom24 = styled.div`
    margin-bottom: 24px;
`
export const Spacer = styled.div`
    margin-bottom: ${p => p.distance ? p.distance : '16px'};
`

export const DashboardTitle = styled.h2`
    font-size: ${props => props.theme.fontSize.headline1};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-bottom: 24px;
`

// Mobile

export const MobileDashboardWrapper = styled.div`
    width: 100%;
    padding: 20px 10px 20px 10px;
`