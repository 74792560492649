export const de = {
  translation: {
    'Mandate Conditions': 'Mandatsbedingungen',
    'LawBannerText1' : 'Ich erkläre mich mit der',
    'LawBannerText2' : 'Datenschutzerklärung',
    'LawBannerText3' : 'einverstanden.',

    'Good Morning!': 'Guten Morgen.',
    'Good Afternoon!': 'Guten Tag.',
    'Good Evening!': 'Guten Abend.',
    'MessagesNavigationBar': 'Fälle',
    'DashboardMobileNavigationBar': 'Fälle',
    'Settings' : 'Einstellungen',

    'UsernamePlaceholderForgot': 'Benutzername',
    'UsernameResetLinkError': 'Der Benutzername kann nicht gesendet werden.',
    
    'UsernameResetLinkSuccess': 'Der Benutzername wurde an Ihre E-Mail Adresse gesendet.',
    'PasswordResetLinkSuccess': 'Der Link zum Zurücksetzen des Passworts wurde an deine E-Mail gesendet.',
    
    'agbStatement': 'Ich erkläre mich mit der Verarbeitung der eingegebenen Daten sowie der Datenschutzerklärung einverstanden.',
    'agbText': 'Mandatsbedingungen',
    'agbTextFormula': 'Mandatsbedingungen',
    'datenschutzStatement': 'Die Mandatsbedingungen habe ich zur Kenntnis genommen, verstanden und stimme diesen zu.',
    'datenschutzText': 'Datenschutzerklärung',

    'There Is No Task': 'Keine Aufgabe',
    'EmptySharedMessageContent': 'Keine Nachrichten',
    'NoCasesFound': 'Keine Akte',
    'Please check your e-mail': 'Sie haben eine E-Mail von uns bekommen.',
    'CheckReceivedEmail': 'Sie haben eine E-Mail von uns erhalten.',

		'No folders have been created yet.': 'Keine Ordner',
    'No favorites have been saved yet.': 'Keine Favoriten',

    'ErrorRedirectButtonText': 'Zurück zum Login',
    'EsyMandantRegistration': 'Registrieren Sie sich jetzt.',
    'EsyMandantRegistrationSubTitle':'',
    'EsyMandantPasswordConfirmation': 'Bitte vergeben Sie ein sicheres Passwort.',
    'EsyMandantSalutation': 'Herzlich Willkommen bei der Kanzlei MYK.',
    'EsyMandantSalutationText': '',
    'Herzlichen Glückwunsch!': 'Ihre Registrierung war erfolgreich.',
    'CongratulationText1':'',
    'FirstStep': 'Weiter', 

    'NewPasswordPlaceholder': 'Neues Passwort',    
    'NewPasswordConfirmationPlaceholder' : 'Neues Passwort bestätigen',
 
    'zum Dashboard': 'Zum Online-Portal',

    'Bitte geben Sie Ihre Versicherungsnummer ein.': 'Rechtsschutzversicherungnummer, falls bekannt:',
    'Besitzen Sie eine Rechtsschutzversicherung?' : 'Haben Sie eine Rechtsschutzversicherung?',

    'Add subject' : 'Betreff',
    'Enter a subject' : 'Bitte geben Sie hier Ihren Betreff ein.',
    'MessageLabel': 'Nachricht',
    'Enter your message': 'Bitte geben Sie hier Ihren Text ein.',
    'Add Attachment': 'Anhang hinzufügen',

    'RequestPINAnother' : 'Neuen PIN anfordern.',
  }
}