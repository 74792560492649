import { textHelpers } from 'application/common'
import React, { Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const BreadcrumItemViewStyled = styled.span`
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.standard};
`

const StyledTriggerTooltip = styled.a`
    text-decoration: none;
`

const OverrideStyledTootlipName = styled.span`
    font-weight: ${props => props.theme.fontWeight.medium} !important;
    font-size: ${props => props.theme.fontSize.small} !important;
    color: ${props => props.theme.color.color5} !important;
`

const BreadcrumItemView = ({ id, name, onBreadClick, selectedData, isselected }) => {
    const breadName = name && textHelpers.truncateMiddleText(name, 30)
    return <BreadcrumItemViewStyled onClick={() => onBreadClick(id, selectedData)} >
        {
            isselected && name.length > 15 ? <StyledTriggerTooltip data-tip data-for={`breadcumName${id}`}>
                {breadName}
            </StyledTriggerTooltip> : <Fragment>
                {breadName}
            </Fragment>
        }

        {
            isselected && name.length > 15 && <ReactTooltip place={'bottom'} effect='solid' id={`breadcumName${id}`}>
                <OverrideStyledTootlipName>
                    {name}
                </OverrideStyledTootlipName>
            </ReactTooltip>
        }
    </BreadcrumItemViewStyled>
}

export default BreadcrumItemView