import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import * as Fragments from '../fragments'
import InputMessage from '../message_details_input_message'
import WrapperMessages from '../wrapper_message'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { useTheme } from 'styled-components'

const MessagesContent = ({
    allAktenLoaded,
    metaMessage,
    folderId,
    akteIdHash,
    accountId,
    serviceAkte,
    mainColor,
    secondaryColor,
    permissionToReply,
    messageKey,
    accountName,
    displayContentMobile,
    setDisplayContentMobile,
    customProps,
    scrollRef,
    loadMessageSensor,
    messageFullLoaded,
    shouldLoadWithOffset,
}) => {
    const accountPublicInfo = customProps?.accountPublicInfo
    const theme = useTheme()


    return (
        <Fragment>
            {allAktenLoaded ? (
                <WrapperMessages
                    displayContentMobile={displayContentMobile}
                    messages={metaMessage?.messages}
                    messageKey={messageKey}
                    folderId={folderId}
                    akteIdHash={akteIdHash}
                    accountId={accountId}
                    scrollRef={scrollRef}
                    loadMessageSensor={loadMessageSensor}
                    messagesTotalCount={metaMessage.totalMessages}
                    messageFullLoaded={messageFullLoaded}
                    shouldLoadWithOffset={shouldLoadWithOffset}
                />
            ) : (
                <FlexGrid justifyCenter>
                    {accountPublicInfo && (
                        <PreloaderSpin
                            primaryColor={
                                theme.color.primary
                            }
                        />
                    )}
                </FlexGrid>
            )}
            {serviceAkte && (
                <FlexGridItem grow={0.6} basis='0%' justifyCenter>
                    <Fragments.ServiceAkteBannerContainer borderColor={mainColor} backgroundColor={secondaryColor}>
                        Hi esy-ers 👋🏻, this case is just a service. at e.sy One we call it Service Akte.{' '}
                        {permissionToReply ? (
                            <Fragment>
                                But you can still chat.{' '}
                                <span role='img' aria-label='chat'>
                                    📩
                                </span>
                            </Fragment>
                        ) : (
                            <Fragment>
                                And you can read the updates here.{' '}
                                <span role='img' aria-label='update'>
                                    👩‍🏫
                                </span>
                            </Fragment>
                        )}
                    </Fragments.ServiceAkteBannerContainer>
                </FlexGridItem>
            )}
            {permissionToReply && (
                <InputMessage
                    setDisplayContentMobile={setDisplayContentMobile}
                    messageKey={messageKey}
                    folderId={folderId}
                    akteIdHash={akteIdHash}
                    accountId={accountId}
                    accountName={accountName}
                    accountPublicInfo={accountPublicInfo}
                />
            )}
        </Fragment>
    )
}

MessagesContent.propTypes = {
    allAktenLoaded: PropTypes.bool,
    customProps: PropTypes.object,
    folderId: PropTypes.string,
    akteIdHash: PropTypes.string,
    accountId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
    serviceAkte: PropTypes.bool,
    mainColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    permissionToReply: PropTypes.bool,
    messageKey: PropTypes.string,
    accountName: PropTypes.string,
    metaMessage: PropTypes.object,
    displayContentMobile: PropTypes.object,
    setDisplayContentMobile: PropTypes.func,
}

export default MessagesContent
