import { API_VERSION_ESYMANDANT, fetchHandler, fetchRequestBuilderFixedToken, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const postRegistration = (username, password, passwordConfirmation, accountId, Key, userId, AgbsAccepted, token ) => {
    const payload = {
        username,
        password,
        passwordConfirmation,
        accountId,
        Key,
        AgbsAccepted
    }

    const request = fetchRequestBuilderFixedToken(API_VERSION_ESYMANDANT + '/e.consult.' + accountId + '/user/' + userId + '/register', HTTP_METHOD.POST, payload, true, false, false, token)

    // request.data.credentials = 'include'

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}