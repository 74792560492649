import { forgotUsernameRequest } from 'application/redux/actions/auth'
import { connect } from 'react-redux'
import RequestUsernameComponent from './component'
import { getAccountPublicInfo } from 'application/redux/actions/common/account_details'

const mapStateToProps = state => ({
    usernameRequest: state.auth.forget.username.request,
    usernameSucceed: state.auth.forget.username.succeed,
    accountPublicInfo: state.common.accountDetails.publicInfo,
    publicInfoFailed: state.common.accountDetails.publicInfoFailed,
})

const mapDispatchToProps = dispatch => ({
    onReqUsername: (email, errorMessage, successMessage) => dispatch(forgotUsernameRequest(email, errorMessage, successMessage)),
    getAccountPublicInfo: (accountIdentify) => dispatch(getAccountPublicInfo(accountIdentify)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestUsernameComponent)