import { api } from 'application/api'
import { closeAllDialogs } from 'application/redux/actions/dialogs'
import { showToast } from 'application/redux/actions/notifications'
import { hideWaitingScreen, showWaitingScreen } from 'application/redux/actions/waiting_screen'
import { call, put } from 'redux-saga/effects'

export function* uploadProfilePicture(action) {
    yield put(showWaitingScreen('Set your profile picture'))
    const uploadRespose = yield call(api.gateway.files.postFilesWithBlobByAccountId, action.payload.accountId, action.payload.croppedImage, action.payload.file.metaData)
    yield call(api.gateway.user.setProfilePicture, action.payload.accountId, action.payload.userId, uploadRespose.files[0].fileuploadId)
    yield put(hideWaitingScreen())
    yield put(showToast(action.metaData.successMessage))
    yield put(closeAllDialogs())
}