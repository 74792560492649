import { isAndroid, isTablet } from 'react-device-detect'
import styled from 'styled-components'

export const LayoutWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 100%;
    overflow: hidden;
`

export const LayoutInputWrapper = styled.div`
    width: 60%;
    height: ${isTablet ? '100%' : '100vh'};
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        width: 100%;
    }
`

export const LayoutInput = styled.div`
    display: flex;
    flex-flow: column nowrap;    
    padding: 20px 180px 50px 180px;
    justify-content: space-between;
    height: ${isTablet ? isAndroid ? '90vh' : '100%' : '100%'};
    position: fixed;
    width: 60%;

    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        padding: 70px 70px 20px 70px;
    }

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 20px 70px 20px 70px;
        width: 100%;
    }

    @media (max-width: ${props => props.theme.breakpoint.xSmall}px) {
        padding: 20px 40px 20px 40px;
        width: 100%;
    }
`

export const LayoutInputMobile = styled.div`
    display: flex;
    flex-flow: column nowrap;
    padding: 20px;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow: auto;
`

export const IconWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 90px;
    margin-bottom: 8px;

    i {
        font-size: ${props => props.theme.fontSize.xLarge};
        text-align: right;
        color: ${props => props.theme.color.iceBlue};
    }
    
`

export const FormLoginWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: ${props => props.stretched !== undefined ? 'space-between' : 'center'};
    align-items: ${props => props.stretched !== undefined ? 'space-between' : 'center'};
`

export const TitleLoginWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-shrink: 0;
    border-style: solid;
    border-width: 0px;
    position: relative;
    z-index: 0;
    min-height: 0px;
    min-width: 0px;
    padding-bottom: 40px;
`

export const TitleLogin = styled.h1`
    max-width: 100%;
    color: ${props => props.theme.color.color100};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    font-family: ${props => props.theme.fontFamily.secondary};
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -0.2px;
    text-align: center;
    text-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    padding-top: 3px;
    padding-bottom: 2px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-size-adjust: none;

    @media (min-width: 480px) {
        color: ${props => props.theme.color.primary};
        font-size: 44px;
    }

    @media (max-width: 360px) and (min-width: 310px) {
        font-size: 24px;
    }
`
export const SubTitleLogin = styled.h2`
    max-width: 100%;
    color: ${props => props.theme.color.color100};
    font-family: ${props => props.theme.fontFamily.secondary};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 20px;
    letter-spacing: -0.2px;
    text-align: center;
    text-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    padding-top: 20px;
    padding-bottom: 2px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-size-adjust: none;
     @media (min-width: 480px) {
    font-size: ${props => props.theme.fontSize.medium18};
    }
`

export const PrivacyExplanation = styled.p`
    max-width: 100%;
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.light};
    line-height: 20px;
    text-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    word-break: break-word;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 4px;
    padding-top: 1px;
    text-size-adjust: none;
    @media (min-width: 480px) {
    text-align: left;
    }
`

export const PrivacyLink = styled.a`
    border-radius: 2px;
    color: ${props => props.theme.color.color90};
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.light};
    outline: 0px;
    text-decoration: underline ${props => props.theme.color.color90};
    text-rendering: geometricprecision;
    transition: color 0.2s ease 0s;
    -webkit-font-smoothing: antialiased;
`

export const InfToGo = styled.div`
    display: none;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }
`

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`

export const StyledBigLogo = styled.div`
    height: 72px;
    width: 72px;
    @media (min-width: ${props => props.theme.breakpoint.desktop}px) {
        width: 152px;
    }
`

export const StyledSmallLogo = styled.div`
    height: 48px;
    width: 48px;
`

export const PolicyWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    font-size: ${props => props.theme.fontSize.xSmall};
    justify-content: space-evenly;
    align-items: center;

    a {
        text-decoration: none;
        color: ${props => props.theme.color.color70};
    }
`

export const LayoutInfoWrapper = styled.div`
    width: 40%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-image: url('assets/images/image_login.jpg');
    background-repeat: no-repeat;
  background-size: cover;
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        display: none;
    }
`

export const LayoutInfo = styled.div`
    display: flex;
    flex-flow: column nowrap;
    color: white;
    z-index: 0;
    /* overflow-y: auto; */
    margin-top: -50px;
`

export const StyledImageBigLogo = styled.img`
    width: 64px;
    height: auto;
    @media (min-width: ${props => props.theme.breakpoint.desktop}px) {
        width: 186px;
    }
`

export const StyledImageSmallLogo = styled.img`
    width: 100px;
    height: auto;
    margin-bottom: 16px;
    @media (min-width: ${props => props.theme.breakpoint.desktop}px) {
        width: 94px;
    }
`

export const StyledImageMain = styled.img`
    width: 730px;
    height: 530px;
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        width: 530px;
        height: 330px;
    }

    @media (min-width: ${props => props.theme.breakpoint.desktop}px) {
        width: 80%;
        height: auto;
    }
`

export const MainImage = styled.div`
    /* height: 530px; */
    width: ${props => props.isVideo ? '760px' : '530px'};
    margin: 120px 0px 0px -40px;
    z-index: 2002;

    @media (min-width: ${props => props.theme.breakpoint.desktop}px) {
        width: 100%;
        height: auto;
    }
`

export const InfoWrapper = styled.div`
    padding: 0px 120px 0px 100px;

    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        padding: 0px 70px 0px 80px;
    }
`

export const InfoLayoutBlue = styled.div`
    width: 100%;
    margin-top: -40px;
    background: ${props => props.theme.color.turqouise};
    padding: 150px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.color.color90};
`

export const Subtitle = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    margin-bottom: 32px;

    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        font-size: ${props => props.theme.fontSize.large};
    }
    @media (min-width: ${props => props.theme.breakpoint.desktop}px) {
        font-size: ${props => props.theme.fontSize.xxLarge};
    }
`

export const Title2 = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    padding: 24px;
    display: flex;
    justify-content: center;
    text-align: center;

    @media (min-width: ${props => props.theme.breakpoint.desktop}px) {
        font-size: ${props => props.theme.fontSize.xLarge};
    }
`

export const Title = styled.div`
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.headLine1};
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        font-size: ${props => props.theme.fontSize.xxLarge};
    }
`

export const Description = styled.div`
    color: ${props => props.theme.color.color0};
    font-size: ${props => props.theme.fontSize.medium};
    padding: 16px 24px;

    @media (min-width: ${props => props.theme.breakpoint.desktop}px) {
        font-size: ${props => props.theme.fontSize.headline1};
    }
`

export const DescriptionToGo = styled.div`
    color: ${props => props.theme.color.color70};
    font-size: ${props => props.theme.fontSize.small};
    height: 20%;

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        font-size: ${props => props.theme.fontSize.medium};
    }

    @media (max-width: ${props => props.theme.breakpoint.xSmall}px) {
        font-size: ${props => props.theme.fontSize.small};
    }
`

export const BottomImage = styled.div`
    /* width: 100%; */
    /* height: 100%; */
    /* test */
    /* background: green; */
`

export const StyledImageBottomLogin = styled.img`
    width: 100%;
    height: auto;
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        width: 200%;
    }
`


export const ListWrapper = styled.div`
    display: flex;
    flex-direction: row; 
    justify-content: center
`



export const BulletList = styled.ul`
    padding: 0;
    list-style-type: circle;
    list-style-position : outside;
    margin: 8px 0 0;
    margin-top: 20px;
    margin-bottom: 20px;
`

export const BulletListItem = styled.li`
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 180px;
    // &:before {
    //     content: "\\2022"; 
    //     color: ${props => props.cssColor !== undefined ? props.cssColor : '#d9d9d9'};
    //     font-weight: bold;
    //     display: inline-block; 
    //     width: 1em; 
    // }
`