import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const BadgeNotification = styled.sup`
    border-radius: 50px;
    height: ${p => p.large};
    width: ${p => p.large};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.color.color0};
    background: ${props => props.isActive ? props.theme.color.salmonDark : props.theme.color.color90};
    font-size: ${props => props.fontSize};
    top: ${p => p.top};
    margin-left: -10px;
`

const Badge = ({isActive = true, mountNotification, top = '-3px', large='15px', fontSize='8px'}) => {
    return <BadgeNotification top={top} isActive={isActive} large={large} fontSize={fontSize}>
        {mountNotification}
    </BadgeNotification>
}

Badge.propTypes = {
    isActive: PropTypes.bool,
    mountNotification: PropTypes.number
}

export default Badge