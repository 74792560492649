// import ImagePlaceHolder from 'application/components/controls/image_placeholder'
// import { FlexGrid } from 'application/components/fragments/grid'
// import { Headline2 } from 'application/components/fragments/typography'
// import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
// import { Fragment } from 'react'
// import { useTranslation } from 'react-i18next'

// export const AktenSelectCase = (props) => {
//     const { isTablet, accountPublicInfo } = props
//     const {t} = useTranslation()
//     const {getAsset} = usePluginsContext()
//     return <Fragment>
//     <FlexGrid directionColumn justifyCenter itemsCenter style={{ height: '100%' }}>
//         {accountPublicInfo && (
//             <ImagePlaceHolder
//                 source={
//                     getAsset('nomessagechoosen.png')
//                 }
//                 large={isTablet ? '100%' : '450px'}
//                 withBorder={false}
//             />
//         )}
//         <Headline2>
//             {t('SelectOneCase')}
//         </Headline2>
//     </FlexGrid>
// </Fragment>
// }


export const AktenSelectCase = () => {
    return null
}