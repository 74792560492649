
import styled from 'styled-components'

export const StyledContainer = styled.label`
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    color: ${props => props.disabled ? props.theme.color.color20 :  props.theme.color.standardRegular};
    align-items: center;
    align-content: center;
    justify-content: center;
    flex: 0 0 ${props => props.widthProp};
    display: flex;
    height: ${props => props.heightProp};
    box-sizing: border-box;
`

export const StyledInput = styled.input`
    display: none;
`

export const StyledLabel = styled.span`
    white-space: nowrap;
    padding: 0 8px;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    color: ${props => props.theme.color.standardRegular};
`