import { fetchHandler, fetchRequestBuilder, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'
import axios from 'axios'
import i18next from 'i18next'


export const postKeyAssistant = async(payload) => {

        const baseUrl = process.env.REACT_APP_API_URL_BASE
        const apiKey = process.env.REACT_APP_API_KEY
        const applicationKey = process.env.REACT_APP_APPLICATION_KEY

        var isLogin = false



        await axios.post(`${baseUrl}authenticate/adhoc/workflow`, payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18next.language,
                'X-Application-Key': applicationKey,
                'X-API-Key': apiKey,
                },
        })
        .then((res) => {
            isLogin = true
            
        })
        .catch((err) => {
            if(err?.response?.data?.payload?.isAppLoginRequired === true) {
                isLogin = false
            } else {
                isLogin = true
                
            }

            // console.log(err?.response?.data?.payload?.isAppLoginRequired)
        })

        const request = fetchRequestBuilder('authenticate/adhoc/workflow', HTTP_METHOD.POST, payload, true, false, isLogin)
            
            return limiter.schedule(() => fetchHandler(request.url, request.data))
                .then(handleApiResponse)

        
            
        


}