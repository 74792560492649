import ChatBubble from 'application/components/building_blocks/chat_bubble'
import IconLink from 'application/components/controls/icon/icon_link'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { AKTEN } from 'application/constants'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import FavoriteMessagesLoader from './favorite_loader'
import { LoadEffect } from 'application/common'

const Wrapper = styled.div`
    padding: ${(props) => (props.noPadd ? '0px' : '20px')};
    width: 100%;
    overflow: ${(props) => (!props.isoverflow ? 'hidden' : 'auto')};
    max-height: ${(props) => (props.maxHeight === undefined ? '100%' : props.maxHeight)};
    height: ${(props) => props.height};
`
const TitleSidebar = styled.span`
    color: ${(props) => props.theme.color.color90};
    font-size: ${(props) => props.theme.fontSize.medium18};
    font-weight: ${(props) => props.theme.fontWeight.bold};
`

const FavoriteSidebarComponent = (props) => {
    const {
        openAkteSidebar,
        favorites,
        akteIdHash,
        parentId,
        metaInfo,
        theme,
        messageKey,
        getMessageFavorite,
        allAktenLoaded,
        accountId,
        favoriteLoading,
        favoriteLoaded,
        folderId,
    } = props
    const { t } = useTranslation()
    return (
        <FavoriteMessagesLoader
            messageKey={messageKey}
            getMessageFavorite={getMessageFavorite}
            allAktenLoaded={allAktenLoaded}
            accountId={accountId}
            favoriteLoaded={favoriteLoaded}
            folderId={folderId}
            akteIdHash={akteIdHash}
        >
            <Wrapper height='89vh'>
                <FlexGrid directionColumn>
                    <FlexGridItem>
                        <FlexGrid justifySpaceBetween itemsCenter>
                            <TitleSidebar>{t('Favorites')}</TitleSidebar>
                            <IconLink title={t('Open case details')} width='40px' iconKey='clear' onClick={() => openAkteSidebar(AKTEN.SIDEBAR.KEY.DETAILS)} />
                        </FlexGrid>
                    </FlexGridItem>
                    <LoadEffect loading={favoriteLoading}>
                        {favoriteLoaded && (
                            <Wrapper noPadd height='78vh' maxHeight='100%' isoverflow={true}>
                                <FlexGrid directionColumn>
                                    <React.Fragment>
                                        {favorites.length > 0 &&
                                            favorites
                                                .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
                                                .map((m, i) => {
                                                    return (
                                                        <ChatBubble
                                                            key={i}
                                                            messageDetail={m}
                                                            messageId={m.id}
                                                            mainColor={metaInfo === null ? theme.color.primary : metaInfo.mainColor}
                                                            accountId={metaInfo === null ? 0 : metaInfo.accountId}
                                                            akteIdHash={akteIdHash}
                                                            parentId={parentId}
                                                            showInFavoriteSidebar={true}
                                                            {...props}
                                                        />
                                                    )
                                                })}
                                    </React.Fragment>
                                </FlexGrid>
                            </Wrapper>
                        )}
                    </LoadEffect>
                </FlexGrid>
            </Wrapper>
        </FavoriteMessagesLoader>
    )
}

export default withTheme(FavoriteSidebarComponent)
