import TagCategory from 'application/components/controls/tag_category'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { withTheme } from 'styled-components'
import { SyledRestCategoriesServiceProviderCard } from './fragments'

const CategoriesServiceProviderCard = ({ accountDetail, theme }) => {
    const categories = accountDetail.categories !== null ? accountDetail.categories.slice(0, 2) : null
    const restCategories = accountDetail.categories?.length > 1 ? accountDetail.categories?.length - 2 : 0
    return <FlexGridItem itemsFlexEnd>
        <FlexGrid justifyCenter>
            {categories !== null &&
                categories.map((c, i) => {
                    return c !== '' && c !== null && c !== undefined &&
                        <FlexGridItem widthMobile='auto' key={i} grow={0}>
                            <TagCategory
                                truncate={true}
                                key={i}
                                disabled={true}
                                text={c}
                                backgroundcolor={accountDetail.mainColor}
                            />
                        </FlexGridItem>
                })
            }
            <FlexGridItem widthMobile='auto' justifyCenter grow={0}>
                {restCategories !== 0 &&
                    <SyledRestCategoriesServiceProviderCard mainColor={accountDetail.mainColor !== null ? accountDetail.mainColor : theme.color.mainAkte}>
                        +{restCategories}
                    </SyledRestCategoriesServiceProviderCard>}
            </FlexGridItem>
        </FlexGrid>
    </FlexGridItem>
}

export default withTheme(CategoriesServiceProviderCard)
