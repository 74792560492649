import FormInputPin from 'application/components/controls/form_elements/input_pin'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { ErrorLabel } from 'application/components/fragments/error_information'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

const StyledFormContent = styled.div`
	display: flex;
	flex-direction: column;
`

const EnterPinForm = ({
    loginRequest,
    loginFailed,
    auth,
    sendPin,
    loggedIn,
    akteMeta,
    errorMessageSetPin,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [pin, setPin] = useState('')
    const pinLength = 6
    const updatePin = (e) => {
        setPin(e)
    }

    let completed = pin.length === pinLength
	
    useEffect(() => {
        !loggedIn && completed && sendPin(akteMeta.authentication, pin, errorMessageSetPin)
        setPin('')
    }, [loggedIn, completed, pin, akteMeta,errorMessageSetPin, sendPin ])

    return (
        <StyledFormContent>
            {loginRequest ? (
                <PreloaderSpin
                    primaryColor={
                        theme.color.primary
                    }
                />
            ) : (
                <FormInputPin
                    value={pin}
                    onUpdatePin={(e) => updatePin(e)}
                    isValid={!loginFailed}
                    pinLength={pinLength}
                    accountId={akteMeta.accountId}
                />
            )}
            {!loggedIn && <ErrorLabel>{t(errorMessageSetPin)}</ErrorLabel>}
        </StyledFormContent>
    )
}

export default EnterPinForm
