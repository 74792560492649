import { connect } from 'react-redux'
import LoginPinMobileAssistantComponent from './component'

const mapStateToProps = (state) => ({
    responseInfo: state.form.metaData.responseInfo
})

const mapDispatchToProps = {
    
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPinMobileAssistantComponent)