import React from 'react'
import SeparatorBreadcrumb from './separator'

const WithSeparator = (lastIndex, items = []) => (acc = [], child, index) => {
    const notLast = index < lastIndex
    if (notLast) {
        items.push([
            child,
            <SeparatorBreadcrumb />
        ])
    } else {
        items.push(child)
    }
    
    return <React.Fragment>
        {items}
    </React.Fragment>
}

export default WithSeparator