import React, { Suspense, lazy } from 'react'
import * as Fragments from '../fragments'
import { BasicLoader } from 'application/components/fragments/basic_loader'

const FooterWelcome = lazy(() => import('../sub_component/footer'))
const HeaderWelcomePage = lazy(() => import('../sub_component/header'))
const InfoCardWelcome = lazy(() => import('../sub_component/info_card'))
const InfoGrayWelcome = lazy(() => import('../sub_component/info_gray'))
const MainInfoWelcome = lazy(() => import('../sub_component/info_main'))
const QnAUserWelcome = lazy(() => import('../sub_component/qna/user'))
const StepUser = lazy(() => import('../sub_component/info_turqouise/step_user'))

export const UserWelcomeContent = () => {
    return <Suspense fallback={<BasicLoader />}>
        <HeaderWelcomePage />
        <Fragments.MainWrapper>
            <MainInfoWelcome />
            <StepUser />
            <InfoCardWelcome />
            <InfoGrayWelcome />
            <QnAUserWelcome />
            <FooterWelcome />
        </Fragments.MainWrapper>
    </Suspense>
}

export default UserWelcomeContent