import { AktenDetailSidebar, FavoriteSidebar, FilesSidebar } from 'application/components/building_blocks/sidebar/akten'
import { AKTEN } from 'application/constants'

export const getSidebarAktenDetails = (key) => {
    switch (key) {
    case AKTEN.SIDEBAR.KEY.FILES:
        return FilesSidebar
    case AKTEN.SIDEBAR.KEY.DETAILS:
        return AktenDetailSidebar
    case AKTEN.SIDEBAR.KEY.FAVORITE:
        return FavoriteSidebar
    default:
        return AktenDetailSidebar
    }
}