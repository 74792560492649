import React, { useEffect } from 'react'
import { Fragment } from 'react'

const MainLogoLoader = (props) => {
    const { children, accountId, mainLogoLoaded, getMainLogo, auth } = props
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    useEffect(() => {
        !mainLogoLoaded &&
            loggedIn &&
            getMainLogo(accountId)
    }, [accountId, getMainLogo, loggedIn, mainLogoLoaded])
    
    return <Fragment>
        {children}
    </Fragment>
}

export default MainLogoLoader