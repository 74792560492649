import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import React from 'react'
import * as Fragment from './fragments'

const ContentSettingLayout = ({ headline, subHeadline, description, children }) => {
    return <Fragment.WrapperSettingDashboard>
        <Fragment.HeadlineSetings>
            {headline}
        </Fragment.HeadlineSetings>
        <FlexDividerHorizontal />
        <Fragment.SubHeadlineSetings>
            {subHeadline}
        </Fragment.SubHeadlineSetings>
        <Fragment.DescSetings>
            {description}
        </Fragment.DescSetings>
        <React.Fragment>
            {children}
        </React.Fragment>
    </Fragment.WrapperSettingDashboard>
}

export default ContentSettingLayout