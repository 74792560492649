import { useTranslation } from 'react-i18next'

import { StyledButtonInput } from 'application/components/pages/external/mobile_assistant/fragments'
import { useTheme } from 'styled-components'

export function LoginButtonSubmit({handleSubmit, onSubmitSuccess}) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <StyledButtonInput>
      <input
        style={{
          background: '#e13582',
          fontFamily: theme.fontFamily.secondary,
          borderRadius: '6px',
          border: 'none',
        }}
        type='button'
        value={t('SubmitButtonText')}
        onClick={handleSubmit(onSubmitSuccess)}
      />
    </StyledButtonInput>
  )
}
