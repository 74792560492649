import React from 'react'

import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledHiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
const StyledCheckbox = styled.div`
    width: ${p => p.width ? p.width : '22px'};
    height: ${p => p.height ? p.height : '22px'};
    background: ${p => p.checked ? p.theme.color.salmonDark : 'none'};
    border: 1px solid ${p => p.theme.color.color45};
    -webkit-transition: all 150ms;
    transition: all 150ms;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
        font-size: ${p => p.width ? `calc(${p.width} - 6)px` : '16px'}
    }
`

const StyledCheckBoxWithLabel = styled.div`
    margin-bottom: 8px;
    & label {
        display: flex;

        & span {
            padding-left: 8px;
            display: flex;
            align-self: center;
            color: ${props => props.theme.color.color90};
            font-size: ${props => props.theme.fontSize.standard};
        }
    }
`

const StyledLabel = styled.span`
    font-size: ${props => props.fontSize !== '' ? props.fontSize : props.theme.fontSize.standard}
`

const HiddenCheckbox = ({
    label,
    checked,
    onChange
}) => (

    <StyledHiddenCheckbox
        type="checkbox"
        name={label}
        checked={checked}
        onChange={onChange}
    />

)

const Checkbox = ({
    checked,
    width,
    height
}) => (
    <StyledCheckbox width={width} height={height} checked={checked}>
        <i className="material-icons">{checked ? 'done' : ''}</i>
    </StyledCheckbox>
)



const CheckboxWithLabel = ({
    label,
    isRequired,
    isselected,
    onCheckboxChange = () => { },
    fontSize = '',
    width,
    height,
    name = ''
}) => {

    const finalLabel = isRequired ? label + '*' : label

    return (
        <StyledCheckBoxWithLabel>
            <label>
                <HiddenCheckbox
                    className='jest-hiddencheckbox-324ljklj-324'
                    label={label}
                    checked={isselected}
                    onChange={onCheckboxChange}
                    name={name}
                />
                <Checkbox
                    checked={isselected}
                    width={width}
                    height={height}
                />
                <StyledLabel fontSize={fontSize} >{finalLabel}</StyledLabel>
            </label>
        </StyledCheckBoxWithLabel>
    )
}

CheckboxWithLabel.propTypes = {
    label: PropTypes.string,
    isselected: PropTypes.bool.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
}

export default CheckboxWithLabel