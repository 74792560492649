import React, { useState, useRef } from 'react'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useEscapeKey, useClickOutside } from 'application/components/hooks'
import IndicatorWithColor from 'application/components/controls/indicator/override_color_indicator'
import * as Fragments from './fragments'
import { Paragraph } from 'application/components/fragments/typography'
import { DIALOG } from 'application/constants'
import FontIcon from 'application/components/controls/font_icon'

const Dialog = ({
    dialog,
    title = '',
    dialogIndex = 0, //needed?
    isHighest,
    onAbort,
    abortConfirmationMessage = '',
    dialogSize,
    abortConfirmCondition = false,
    children,
    AdditionalHeader,
    DialogFooter,
    DialogSubHeader=null,
    galleryVisible,
    dialogName,
    theme,
    iconKey=null,
    color=null,
    isHeaderWithBackgroundColor = false,
    waitingScreen,
    dialogScreen,
    disableHeader,
    putCenter,
    rounded,
    noPadding,
    dataLoaded //new property, can be used to show/hide a preloader
}) => {
    const { t } = useTranslation()
    const [dataChanged, setDataChanged] = useState(false)
    const [abortBlocked, setAbortBlocked] = useState(false)
    const [abortConfirm, setAbortConfirm] = useState(abortConfirmCondition)
    const abort = () => (dataChanged ? setAbortConfirm(true) : onAbort(dialogName))
    const ref = useRef()
    useClickOutside(ref, abort, !abortBlocked && !galleryVisible && isHighest && !waitingScreen.active)
    useEscapeKey(abort, !abortBlocked && !galleryVisible && isHighest && !waitingScreen.active)
    const blurred = abortConfirm || (waitingScreen.active && waitingScreen.inDialog)
    return (
        <React.Fragment>
            <Fragments.Overlay dialogIndex={dialogIndex}>
                <Fragments.Dialog rounded={rounded} noPadding={noPadding} putCenter={putCenter} dialogScreen={dialogScreen} borderColor={color} dialogSize={dialogSize} ref={ref} dialogIndex={dialogIndex}>
                    {disableHeader === false && (
                        dialogScreen !== DIALOG.SCREEN.MOBILE && <Fragments.DialogHeader withBackgroundColor={isHeaderWithBackgroundColor} blurred={blurred} dialogIndex={dialogIndex}>
                            {
                                AdditionalHeader && <AdditionalHeader setAbortBlocked={setAbortBlocked} title={title} />
                            }
                            {
                                AdditionalHeader === undefined && <React.Fragment>
                                    {
                                        iconKey !== null && <FontIcon icon={iconKey} color={color} fontSize={theme.fontSize.headline1} />
                                    }
                                    <Fragments.DialogTitle>
                                        {t(title)}
                                    </Fragments.DialogTitle>
                                </React.Fragment>
                            }
                            <IndicatorWithColor onButtonClick={abort} iconKey='clear' iconSize='20px' overrideColorWith='anthracite' />
                        </Fragments.DialogHeader>
                    )}
                    <Fragments.DialogContent blurred={blurred}>
                        {React.cloneElement(children, {setDataChanged, abort, setAbortBlocked, dialog})}
                    </Fragments.DialogContent>
                    {
                        //in use????
                        DialogFooter && <DialogFooter onCancel={abort} />
                    }
                   
                    {
                        waitingScreen.active && waitingScreen.inDialog && <Fragments.DialogWaitingScreen dialogIndex={dialogIndex}>
                            <Fragments.DialogAbortConfirmHeader>
                                {t(waitingScreen.message)}
                            </Fragments.DialogAbortConfirmHeader>
                            {
                                waitingScreen.subMessages.map((m, i)=>{
                                    return <Paragraph key={i}>{m}</Paragraph>
                                })
                            }
                        </Fragments.DialogWaitingScreen>
                    }
                </Fragments.Dialog>
            </Fragments.Overlay>
        </React.Fragment>
    )
}

export default withTheme(Dialog)

Dialog.propTypes = {
    title: PropTypes.string,
    dataChanged: PropTypes.bool,
    abortConfirmationMessage: PropTypes.string,
    dialogSize: PropTypes.string,
    abortConfirmCondition: PropTypes.bool,
    AdditionalHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isHighest: PropTypes.bool.isRequired
}
