import { getMainLogo } from 'application/redux/actions/common/all_accounts'
import { connect } from 'react-redux'
import MainLogoCompanyComponent from './component'

const mapStateToProps = (state, props) => {
    const mainLogoRegistered = state.files.mainLogo.filter(f => f.accountId === props.accountId && f.isLoaded).length > 0
    return {
        allAccountsLoaded: state.common.allAccounts.isLoaded,
        mainLogoLoaded: mainLogoRegistered,
        hasMainLogo: mainLogoRegistered && state.files.mainLogo.find(f => f.accountId === props.accountId).has,
        mainLogo: mainLogoRegistered && state.files.mainLogo.find(f => f.accountId === props.accountId).data,
        mainColor: state.common.allAccounts.payload.find(f => f.id === props.accountId).mainColor,
        auth: state.auth,
    }
}

const mapDispatchToProps = dispatch => ({
    getMainLogo: (accountId) => {
        dispatch(getMainLogo(accountId))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLogoCompanyComponent)