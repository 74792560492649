import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { ROUTES } from 'application/constants'
import React from 'react'
import { useForm } from 'react-hook-form'
import { validationMethods } from 'application/common'
import { StartRedirect } from '../../../_common'
import { useTranslation } from 'react-i18next'
import TopContentWizardReg from '../_common/top_content'
import TitleWizard from '../_common/title_wizard'
import FormCheckUsername from './sections/form_check_username'
import ButtonCheckUsername from './sections/button_check_username'
import RedirectMobile from 'application/common/redirect_mobile'
import { WizardMobileVersion } from '../../../mobile'
import { Navigate } from 'react-router-dom'

const WizardRegistrationUsernameComponent = (props) => {
    const { wizardData, wizardLoaded, onUpdateWizardPrev, onCheckUsername, usernameNotValid, usernameSet, usernameRequest, errorMessage, akteMeta, auth } = props
    const pregivenUsername = akteMeta.pregivenUsername !== null ? akteMeta.pregivenUsername : ''
    const defaultValues = {
        username: pregivenUsername,
    }
    const userId = auth.common.user.userId
    const { register,
        formState: { errors },
        handleSubmit,
        getValues
    } = useForm({ defaultValues })
    const onSubmitSuccess = () => {
        onCheckUsername(getValues().username, userId)
    }
    const { t } = useTranslation()
    const mobileProps = {
        ...props,
        pregivenUsername,
        defaultValues,
        userId,
        validationMethods,
        onSubmitSuccess,
        register,
        errors,
        handleSubmit,
        getValues,
    }

    if(!wizardLoaded) return <Navigate to={ROUTES.WIZARD_REG_WELCOME} />

    return <StartRedirect {...props}>
        <RedirectMobile
                MobileComponent={WizardMobileVersion.UsernameWizardMobile}
                {...mobileProps}
            >
                <TopContentWizardReg accountId={akteMeta.accountId} wizardData={wizardData} />
                <FlexGridItem grow={0}>
                    <FlexGrid directionColumn>
                        {/* TODO */}
                        <TitleWizard step={2} headline={t('EsyMandantRegistration')} desc={t('EsyMandantRegistrationSubTitle')} akteMeta={akteMeta} />
                        <FormCheckUsername
                            handleSubmit={handleSubmit}
                            onSubmitSuccess={onSubmitSuccess}
                            register={register}
                            validationMethods={validationMethods}
                            errors={errors}
                            usernameNotValid={usernameNotValid}
                            usernameSet={usernameSet}
                            usernameRequest={usernameRequest}
                            errorMessage={errorMessage}
                        />
                    </FlexGrid>
                </FlexGridItem>
                <ButtonCheckUsername accountId={akteMeta.accountId} onUpdateWizardPrev={onUpdateWizardPrev} usernameRequest={usernameRequest} handleSubmit={handleSubmit} onSubmitSuccess={onSubmitSuccess} />
            </RedirectMobile>
    </StartRedirect>
}

export default WizardRegistrationUsernameComponent