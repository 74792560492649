import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import Preloader3D from 'application/components/controls/preloader/3d'
import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import * as Fragments from './fragments'
import { EcDynamicForm } from '@ec/dynamic-form-react'
// import { EcDynamicForm } from 'application/dynamicform'
import { LoadEffect } from 'application/common'
import { MobileAssistantLayout } from '../../_layout'
import RedirectSucceed from './after_effects/redirect_send_form_succeed'
import { Fragment } from 'react'
import { useTheme, withTheme } from 'styled-components'
import AssistantNoticeSection from './sections/asistant_notice_section'
import FooterExternalForm from './sections/footer'
// import FooterLoginAccountSection from 'application/components/pages/external/auth/login_account/sections/footer'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import PreActionsMobileAssitant from './pre_actions'
import {
  isSafari,
  isTablet,
  withOrientationChange,
  isMobile,
} from 'react-device-detect'
import RouteLocationExternalFormProvider from './context/route_location_provider'
import { useTranslation } from 'react-i18next'
import ReturnUrlSection from './sections/return_url_section'
import { accessTokenForm } from 'application/storage/access_token'
import { HandlePush } from '../auth/login_account/effects'
import theme from 'application/style/theme'
import { useLocation, useParams } from 'react-router-dom'
import {
  customHeaderTitles,
  getCustomFormula,
} from 'application/common/custom_formula_pages'
import { useRef } from 'react'
import { isSpecialAccount } from 'application/common/special_accounts'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const CheckRS = (formElements, type) => {
  if (formElements) {
    const data = formElements.filter((v) => v.type === type)

    if (data.length > 0) {
      return true
    }
  }
  return false
}

const FormMobileAssistantComponent = (props) => {
  const {
    formData,
    formLoaded,
    accountPublicInfo,
    formContainerBackground,
    formLoading,
    waitingScreen,
    onSendFormRequest,
    sendRequest,
    themeParentApplication,
    formMetaData,
    openAccountStatement,
    auth,
    authenticated,
  } = props
  const [options, setOptions] = useState([])
  const [StatusShow, setStatusShow] = useState([])
  const [gutachterOptionsMetadata, setGutachterOptionsMetadata] =
    useState(undefined)
  const [gutachterOptions, setGutachterOptions] = useState([])
  const [gutachterIsLoading, setGutachterIsLoading] = useState(false)
  const [postalCode, setPostalCode] = useState('')
  const { t } = useTranslation()
  const { config, activePlugin, setUserAccountId } = usePluginsContext()
  const { accountIdentity, externalRequestKey } = useParams()
  const theme = useTheme()

  const coreMetaData =
    formMetaData !== null &&
    formMetaData.core !== undefined &&
    formMetaData.core

  // console.log(formMetaData)

  const showAssistantImage =
    formData !== null &&
    formData.attachments !== '' &&
    formData.attachments !== undefined &&
    formData.attachments !== null &&
    formData.attachments.length !== 0
  const showAssistantNotice =
    formData !== null &&
    formData.assistantNotice !== '' &&
    formData.assistantNotice !== undefined &&
    formData.assistantNotice !== null
  const hasAgb =
    accountPublicInfo !== null &&
    accountPublicInfo.esyThingAgb !== undefined &&
    accountPublicInfo.esyThingAgb !== null
  const hasDatenschutz =
    accountPublicInfo !== null &&
    accountPublicInfo.esyThingDatenschutz !== undefined &&
    accountPublicInfo.esyThingDatenschutz !== null
  const isHeaderLogoUrl =
    formData !== null &&
    formData.headerLogoUrl !== '' &&
    formData.headerLogoUrl !== undefined &&
    formData.headerLogoUrl !== null
  const headerLogoUrl = isHeaderLogoUrl && formData.headerLogoUrl
  const handleSendForm = (payload) => {
    onSendFormRequest(coreMetaData && coreMetaData.workflowKey, payload)
  }
  const baseUrl = process.env.REACT_APP_API_URL_BASE

  const formElements = formData?.formElement?.formElements

  let status = CheckRS(formElements, 'rechtsschutzformular')

  const page = useLocation().pathname
  // const customFormula = getCustomFormula(accountIdentity, page)

  useEffect(() => {
    if (!coreMetaData.accountId) return
    setUserAccountId(coreMetaData.accountId)
  }, [coreMetaData.accountId])

  const newProps = {
    accountIdentity,
    page,
    ...props,
  }

  const getOptions = useCallback(async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenForm.get()}`,
        'Content-Type': 'application/json',
        'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    }

    await axios
      .get(`${baseUrl}v1.0/insurances/rs`, config)
      .then((res) => {
        const data = res.data
        setOptions(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [baseUrl])

  const gutachterGetData = useCallback(async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenForm.get()}`,
        'Content-Type': 'application/json',
        'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
      mode: 'cors',
    }

    const url = `${baseUrl}e.consult.${coreMetaData.accountId}/participants/${postalCode}`
    setGutachterIsLoading(true)
    await axios
      .get(url, config)
      .then((res) => {
        const data = res.data
        setGutachterOptionsMetadata(data.participants)
      })
      .catch((err) => {
        setGutachterOptionsMetadata(undefined)
        setGutachterOptions([])
        console.log(err)
      })
      .finally(() => {
        setGutachterIsLoading(false)
      })
  }, [baseUrl, postalCode])

  useEffect(() => {
    authenticated && status && getOptions()
  }, [authenticated, getOptions, status])

  useEffect(() => {
    if (!gutachterOptionsMetadata) return
    setGutachterOptions(
      gutachterOptionsMetadata.map((m) => {
        return { value: m.id, label: m.name }
      })
    )
  }, [gutachterOptionsMetadata])

  const rsInsurancesOptions = options.map((m) => {
    return { value: m.foreignId, label: m.name }
  })
  // const newsProps = {
  //     ...props,
  //     url: '',
  //     pushToPage: false
  // }

  return (
    <RouteLocationExternalFormProvider>
      <HandlePush {...props}>
        <MobileAssistantLayout>
          <PreActionsMobileAssitant {...props}>
            <RedirectSucceed {...props} workflowKey={coreMetaData.workflowKey}>
              <Fragments.BackgroundFormAssistant
                backgroundColor={themeParentApplication.formElementBackground}
              >
                <LoadEffect loading={formLoading}>
                  <FlexGrid
                    height={'auto'}
                    noWrap
                    justifyCenter
                    itemsCenter
                    directionColumn
                  >
                    <FlexGridItem
                      basis={!isSafari ? '80vh' : 'auto'}
                      justifyCenter
                      width='65%'
                    >
                      <FlexGrid
                        height={isTablet ? '100%' : 'auto'}
                        noWrap
                        justifyCenter
                        itemsCenter
                        directionColumn
                      >
                        {formLoaded && (
                          <Fragment>
                            {/* {(showAssistantImage || showAssistantNotice) && ( */}
                            {formData.headerLogoUrl !== null ? (
                              <img
                                style={{
                                  marginTop: '32px',
                                  width: '70%',
                                  height: '70%',
                                  objectFit: 'cover',
                                  borderRadius: '15px',
                                }}
                                src={formData.headerLogoUrl}
                                alt='headerLogoUrl'
                              />
                            ) : (
                              <img
                                style={{
                                  marginTop: '32px',
                                  width: '70%',
                                  height: '70%',
                                  objectFit: 'cover',
                                  borderRadius: '15px',
                                }}
                                src={accountPublicInfo.secondaryLogoUrl}
                                alt='secondaryLogoUrl'
                              />
                            )}
                            <div
                              style={{
                                width: '76%',
                                display: 'flex',
                                marginTop: '24px',
                                padding: '0 28px',
                                flexDirection: 'column',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                {/* <h3 style={{
                                                                        fontSize: '24px',
                                                                        fontWeight: 'bold',
                                                                    }}>This is your first headline.</h3> */}
                                <p
                                  style={{
                                    fontSize: '16px',
                                    marginTop: '-5px',
                                    lineHeight: '24px',
                                    fontWeight: '300',
                                    textAlign: 'justify',
                                  }}
                                >
                                  {formData.assistantNotice}
                                </p>
                              </div>
                              {/* <div>
                                                                    <h3 style={{
                                                                        fontSize: '24px',
                                                                        fontWeight: 'bold',
                                                                    }}>Lohnsteuerhilfe für jeden...</h3>
                                                                    <p
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            marginTop: '-5px',
                                                                            lineHeight: '30px',
                                                                            fontWeight: '300'
                                                                        }}
                                                                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices dui suspendisse in aenean. Morbi leo tortor morbi vitae egestas. Et, tortor, eu, praesent egestas eu purus tristique turpis etiam. Aliquet leo lorem aliquam donec sed nullam elementum aliquet nisi.</p>
                                                                </div> */}
                            </div>
                            {showAssistantImage && (
                              <AssistantNoticeSection
                                showAssistantImage={showAssistantImage}
                                {...props}
                              />
                            )}
                            <EcDynamicForm
                              workflowKey={coreMetaData.workflowKey}
                              hasAgb={
                                coreMetaData.workflowType === 'EXTERNALREQUEST'
                                  ? true
                                  : formData?.showAgb
                              }
                              hasDatenschutz={
                                coreMetaData.workflowType === 'EXTERNALREQUEST'
                                  ? true
                                  : formData?.showDatenschutz
                              }
                              openAgb={() =>
                                activePlugin
                                  ? window.open(config.agbLink, '_blank')
                                  : openAccountStatement(
                                      accountPublicInfo.esyThingAgb,
                                      t('conditions') +
                                        ' - ' +
                                        accountPublicInfo.accountName
                                    )
                              }
                              openDatenschutz={() =>
                                activePlugin
                                  ? window.open(
                                      config.datenschutzLink,
                                      '_blank'
                                    )
                                  : openAccountStatement(
                                      accountPublicInfo.esyThingDatenschutz,
                                      t('privacy') +
                                        ' - ' +
                                        accountPublicInfo.accountName
                                    )
                              }
                              publicFileInfoOverride={
                                formMetaData.publicFileInfo
                              }
                              formData={formData}
                              rsInsurancesOptions={rsInsurancesOptions}
                              onSubmit={(data) => handleSendForm(data)}
                              formThemeOverride={{
                                formContainerBackground: '#ffffff',
                                formElementBackground: '#ffffff',
                                formElementBorderRadius: '0px',
                                fontFamily: theme.fontFamily.assistant || theme.fontFamily.secondary,
                                fontFamilyHeadline: theme.fontFamily.assistantHeadline || theme.fontFamily.primary,
                                buttonBackground: theme.color.primary,
                                buttonBackgroundHover: theme.color.secondary,
                                policyTextColor: theme.color.policyTextColor,
                              }}
                              localizedTextsOverride={
                                activePlugin
                                  ? {
                                      formElementSimpleChoiceNoOption: t('no'),
                                      formElementSimpleChoiceYesOption:
                                        t('yes'),
                                      agbStatement: t('agbStatement'),
                                      datenschutzStatement: t(
                                        'datenschutzStatement'
                                      ),
                                      showStatementTitle: false,
                                      datenschutzText: t('datenschutzText'),
                                      agbText: t('agbTextFormula'),
                                      dragFileText: t(
                                        'Drag your files or images here to start uploading'
                                      ),
                                      uploadFileText: t('Upload file or image'),
                                      confirmTextExternalLinkPopUpStart: t('ConfirmTextExternalLinkModalStart'),
                                      confirmTextExternalLinkPopUpEnd: t('ConfirmTextExternalLinkModalEnd'),
                                      rsInsuranceNumberLabel: t('Bitte geben Sie Ihre Versicherungsnummer ein.'),
                                      rsHaveInsuranceLabel: t('Besitzen Sie eine Rechtsschutzversicherung?'),
                                    }
                                  : {
                                      formElementSimpleChoiceNoOption: t('no'),
                                      formElementSimpleChoiceYesOption:
                                        t('yes'),
                                        confirmTextExternalLinkPopUpStart: t('ConfirmTextExternalLinkModalStart'),
                                        confirmTextExternalLinkPopUpEnd: t('ConfirmTextExternalLinkModalEnd'),
                                        rsInsuranceNumberLabel: t('Bitte geben Sie Ihre Versicherungsnummer ein.'),
                                        rsHaveInsuranceLabel: t('Besitzen Sie eine Rechtsschutzversicherung?'),
                                    }
                              }
  
                            />
                          </Fragment>
                        )}
                      </FlexGrid>
                    </FlexGridItem>
                    {formLoaded && (
                      <Fragment>
                        {/* <ReturnUrlSection
                                                        withText={false}
                                                        withImage={false}
                                                        {...props}
                                                    /> */}
                        <FooterExternalForm {...newProps} />
                      </Fragment>
                    )}
                  </FlexGrid>
                </LoadEffect>
                {sendRequest && !waitingScreen.active ? (
                  <Fragments.WaitingScreen dialogIndex={2}>
                    <Preloader3D />
                  </Fragments.WaitingScreen>
                ) : (
                  waitingScreen.active && (
                    <Fragments.WaitingScreen dialogIndex={2} />
                  )
                )}
              </Fragments.BackgroundFormAssistant>
            </RedirectSucceed>
          </PreActionsMobileAssitant>
        </MobileAssistantLayout>
      </HandlePush>
    </RouteLocationExternalFormProvider>
  )
}

export default withOrientationChange(withTheme(FormMobileAssistantComponent))
