import { FlexGrid } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import DefaultCompanyLogo from '../default'
import { StyledCompanyLogo } from '../fragments'
import MainLogoLoader from './main_logo_loader'

const StyledBorder = styled.div`
    width: 100%;
`

const MainLogoCompanyComponent = (props) => {
    const { hasMainLogo, mainLogoLoaded, mainLogo, accountName, activated } = props
    return (
        <Fragment>
            <MainLogoLoader {...props}>
                {hasMainLogo && mainLogoLoaded ? (
                    <FlexGrid directionColumn itemsCenter justifyCenter>
                        <StyledBorder>
                            <StyledCompanyLogo activated={true} src={mainLogo} />
                        </StyledBorder>
                    </FlexGrid>
                ) : (
                    <DefaultCompanyLogo activated={activated} accountName={accountName} />
                )}
            </MainLogoLoader>
        </Fragment>
    )
}

export default MainLogoCompanyComponent
