import { DASHBOARD } from 'application/constants'
import { AccountSetting, GeneralSetting, AboutSetting, DefaultContent } from './content'

export const getContentSettings = (location) => {
    switch (location) {
    case DASHBOARD.LINK.SETTINGS.ABOUT:
        return AboutSetting
    case DASHBOARD.LINK.SETTINGS.ACCOUNT:
        return AccountSetting
    case DASHBOARD.LINK.SETTINGS.GENERAL:
        return GeneralSetting
    default:
        return DefaultContent
    }
}