import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from '../fragments'
import CreatorProfilePicture from '../../creator_profiile_picture'
import { textHelpers } from 'application/common'
import TagCategory from 'application/components/controls/tag_category'
import DateComponent from 'application/components/controls/date'
import Time from 'application/components/controls/time'
import { useTranslation } from 'react-i18next'
import { ROUTES } from 'application/constants'
import Indicator from 'application/components/controls/indicator'
import { FlexGridItem } from 'application/components/fragments/grid'

const AkteCardEasyMode = (props) => {
    const { accountName, creatorFirstName, creatorLastName, headline, lastMessageDate, lastMessage, categories, mainColor, akteIdHash, accountId, setSelectedAkte, totalUnreadMessage,totalUnreadMessageInFolder  } = props
    const { t } = useTranslation()
    const latestMessage = lastMessage === null ? '' : lastMessage
    const truncateLatestMessage = textHelpers.truncateEndText(latestMessage, 30)
    const totalUnread = totalUnreadMessage + totalUnreadMessageInFolder
    return (
		<Styled.StyledAkteEasyModeWrapper onClick={setSelectedAkte} to={`${ROUTES.AKTEN}/${akteIdHash}/accounts/${accountId}`}>
            <CreatorProfilePicture large={'70'} borderRadius='28px' {...props} />
			<Styled.StyledContentEasyMode>
				<FlexGridItem justifyFlexEnd>
                    {
                        totalUnread !== 0 &&
                            <Indicator
                                isNotification={true}
                                text={totalUnread}
                            />
                    }
                </FlexGridItem>
                <Styled.CategoriesEasyModeWrapper>
                    {categories.length > 1 && categories.slice(0, 2).map((c, i) => <TagCategory key={i} truncate text={c} backgroundcolor={mainColor} borderradius='50px' />)}
                </Styled.CategoriesEasyModeWrapper>
                <Styled.StyledAccountNameAkteCard>{accountName}</Styled.StyledAccountNameAkteCard>
                <Styled.StyledCreatorNameAkteCard>
                    {creatorFirstName} {creatorLastName}
                </Styled.StyledCreatorNameAkteCard>
                <Styled.StyledHeaderAkteCard>{headline}</Styled.StyledHeaderAkteCard>
                <Styled.StyledLastMessageAkteCard>{truncateLatestMessage}</Styled.StyledLastMessageAkteCard>
                <Styled.StyledDateAkteCard>
                    <DateComponent date={lastMessageDate} todayString={t('Today')} />, <Time date={lastMessageDate} timeHourSuffix='' />
                </Styled.StyledDateAkteCard>
            </Styled.StyledContentEasyMode>
        </Styled.StyledAkteEasyModeWrapper>
    )
}

AkteCardEasyMode.propTypes = {
    accountName: PropTypes.string.isRequired,
    creatorFirstName: PropTypes.string,
    creatorLastName: PropTypes.string,
    headline: PropTypes.string,
    totalUnreadMessage: PropTypes.number,
    lastMessageDate: PropTypes.string,
    lastMessage: PropTypes.string,
    mainColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string,
    accountId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	akteIdHash: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
    setSelectedAkte: PropTypes.func,
	totalUnreadMessageInFolder: PropTypes.number,
}

export default AkteCardEasyMode
