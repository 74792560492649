import FontIcon from 'application/components/controls/font_icon'
import React from 'react'
import styled, { withTheme } from 'styled-components'

const SearchInputContainer = styled.div`
    display: flex;
    width: 100%;
    border-radius: 100px;
    font-family: ${props => props.theme.fontFamily.secondary};
    flex: 1 1 20%;
    align-items: center;
    box-shadow: ${(p) => `1px 3px 6px ${p.theme.color.color20}`};
    background-color: ${(props) => (props.backgroundcolor ? `${props.backgroundcolor} !important` : 'white !important')};
    @media (min-width: 640px) {
        box-shadow: unset;
        height: 40px;
    }
`

const SearchInputWrapper = styled.input`
    width: 100%;
    outline: none;
    border: none;
    border-left: none;
    height: 50px;
    padding: 4px 8px 4px 20px;
    border-radius: 100px;
    color: ${(props) => props.theme.color.color90};
    font-size: ${(props) => props.theme.fontSize.medium};
    background-color: ${(props) => (props.backgroundcolor ? `${props.backgroundcolor} !important` : 'white !important')};
    border-left: none;
    @media (min-width: 640px) {
        border-radius: 0px 100px 100px 0px;
        height: 40px;
    }

    &::placeholder {
        color: ${(props) => props.theme.color.color20};
        font-size: ${(props) => props.theme.fontSize.medium18};
        font-weight: ${(props) => props.theme.fontWeight.semiBold};
    }

    &:focus {
        outline: none;
        background-color: ${(props) => (props.backgroundcolor ? `${props.backgroundcolor} !important` : 'white !important')};
    }

    & input[type='text']:focus {
        outline: none;
        background-color: ${(props) => (props.backgroundcolor ? `${props.backgroundcolor} !important` : 'white !important')};
    }

    & input:-internal-autofill-selected {
        background-color: white !important;
        display: none;
    }

    & input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        background-color: white !important;
        display: none;
    }
`
const StyledIconWrapper = styled.div`
    i {
        padding: 4px 16px 4px 8px;
        background: ${(props) => (props.backgroundcolor ? props.backgroundcolor : props.theme.color.color0)};
        min-width: 10px;
        width: 30px;
        text-align: right;
        border: none;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        border-right: none;
        height: 40px;
        justify-content: flex-end;
        align-items: center;
        background-color: ${(props) => (props.backgroundcolor ? props.backgroundcolor : 'white')};
        display: none;
        @media (min-width: 640px) {
            display: flex;
        }
    }
`

const SearchInputComponent = ({
    placeholder = 'Search',
    theme,
    onChangeSearch,
    value,
    type = 'text',
    onKeyPress,
    ref,
    backgroundcolor,
    autoFocus = false,
    handleSearchInputClick = () => {},
}) => {
    return (
        <SearchInputContainer onClick={handleSearchInputClick} ref={ref}>
            <StyledIconWrapper backgroundcolor={backgroundcolor}>
                <FontIcon icon='search' fontSize={theme.fontSize.headline1} color={theme.color.color20} />
            </StyledIconWrapper>
            <input type='hidden' value='search' />
            <SearchInputWrapper
                name='search'
                id='search'
                autoFocus={autoFocus}
                autoComplete='off'
                backgroundcolor={backgroundcolor}
                onKeyPress={onKeyPress}
                placeholder={placeholder}
                onChange={onChangeSearch}
                value={value}
                type={type}
            />
        </SearchInputContainer>
    )
}

export default withTheme(SearchInputComponent)
