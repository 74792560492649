import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

export function PluginAlternateView(props) {
  const { id, children, ...restProps } = props
  const pluginsContext = usePluginsContext()

  if (!pluginsContext.activePlugin)
    return children

  const Component = pluginsContext.getComponent(id)

  if (!Component)
    return null

  return <Component { ...restProps}>{children}</Component>
}

