import { api } from 'application/api'
import { getFoldersFlatten } from 'application/components/building_blocks/tree/helpers'
import { NOTIFICATION } from 'application/constants'
import { unregisterMessage, updateDataOnMessage } from 'application/redux/actions/messages'
import { showToast } from 'application/redux/actions/notifications'
import { call, put } from 'redux-saga/effects'
import { isMeAndMyRootExist } from './is_me_and_my_root_exist'
import { unregisterFoldersFromUnexistedMessage } from './unregister_folders_from_unexisted_message'

export function* isFolderExistSaga(action) {
    const obj = yield call(api.gateway.pages.getMessageDetails, action.accountId, action.akteIdHash)
    const updatedFolders = obj.folders

    // get current folders length and get updated folders length
    // curFolders could be 'false', it means parent akte isnt registered yet
    const curFoldersFlatten = action.curFolders ? getFoldersFlatten(action.curFolders) : []
    const updatedFoldersFlatten = getFoldersFlatten(updatedFolders)

    // newLoaded === if user click refresh in browser
    // should check if in current akte selected -> if not, dont need to do anything
    // doesnt exist recognized by 'isCur' === null in updatedFolders version
    if (action.newLoaded && action.akteIdHash === action.params.akteIdHash) {
        yield isMeAndMyRootExist(action)
    }
    // do unregister folders message which no more exist in updated folders version
    if (updatedFoldersFlatten.length < curFoldersFlatten.length) {
        const difference = curFoldersFlatten.filter((x) => !updatedFoldersFlatten.some((y) => y.id === x.id))
        for (const dif of difference) {
            // check the subfolders and do unreg if theres any
            yield unregisterFoldersFromUnexistedMessage(dif.id)
            yield put(updateDataOnMessage(dif.id, { exist: false }))
            yield put(unregisterMessage(dif.id))
            if (action.akteIdHash === action.params.akteIdHash) {
                yield put(showToast(`${dif.name} is no more shared`, NOTIFICATION.LEVEL.ERROR))
            }
        }
    }
}
