import { call, put, takeEvery } from '@redux-saga/core/effects'
import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { populateDataByAccountIdAccountPublicInfoInternalAssets, requestDataFailedAccountPublicInfoInternalAssets, requestDataSucceedAccountPublicInfoInternalAssets } from 'application/redux/actions/common/account_public_info_internal_assets'
import { handleError } from 'application/redux/saga/errors'

function* getAccountPublicInfoSaga(action) {
    try {
        const responsePublicAccountInfo = yield call(api.gateway.common.getAccountPublicInfo, action.accountId)
        yield put(populateDataByAccountIdAccountPublicInfoInternalAssets(action.accountId, responsePublicAccountInfo))
        yield put(requestDataSucceedAccountPublicInfoInternalAssets(action.accountId))
    } catch (e) {
        yield handleError(e)
        yield put(requestDataFailedAccountPublicInfoInternalAssets(action.accountId))
    }
}

export function* accountPublicInfoInternalAssetsWatcher() {
    yield takeEvery(ACTIONS.COMMON_ACCOUNT_PUBLIC_INFO_INTERNAL_ASSETS_REQUEST_DATA, getAccountPublicInfoSaga)
}