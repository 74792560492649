import styled from 'styled-components'
import { DIALOG } from 'application/constants'

const Overlay = styled.div`
    position: fixed;
    z-index: ${props => '10' + props.dialogIndex.toString() + '0'};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    background-color:rgba(0, 0, 0, 0.55);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const DialogWrapper = styled.div`
    position: fixed;
    z-index: ${props => '10' + props.dialogIndex.toString() + '1'};
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;

    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const Dialog = styled.div`
    ${p => p.putCenter === true ? 'top: 50%' : p.dialogScreen === DIALOG.SCREEN.MOBILE ? 'bottom: 0;' : 'top: 5%;'}
    ${p => p.dialogScreen === DIALOG.SCREEN.MOBILE ? 'border-radius: 10% 10% 0 0;' : 'border-radius: 5px;'}
    position: fixed;
    z-index: 1002;
    background: white;
    display: flex;
    border-radius: ${p => p.rounded === true ? '10px' : '0px'};
    flex-direction: column;
    flex: 1;
    width: ${props => {
        switch (props.dialogScreen) {
        case DIALOG.SCREEN.MOBILE:
            return '100%'
        default:
            switch (props.dialogSize) {
            case DIALOG.SIZE.SMALL:
                return props.theme.dialog.size.small + 'px'
            case DIALOG.SIZE.MEDIUM:
                return props.theme.dialog.size.medium + 'px'
            case DIALOG.SIZE.MEDIUM50:
                return props.theme.dialog.size.medium50 + 'px'
            case DIALOG.SIZE.LARGE:
                return props.theme.dialog.size.large + 'px'
            default:
                return props.theme.dialog.size.medium + 'px'
            }
        }
        
    }};
    min-width: 370px;
    max-width: ${p => p.dialogScreen === DIALOG.SCREEN.MOBILE ? '100%' : '90%'};
    min-height: ${p => p.noPadding === true ? 'auto' : '200px'};
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.26);
    max-height: 90%;
    border: ${props => props.borderColor !== null ? `4px solid ${props.borderColor}` : 'unset'}
`

const DialogHeader = styled.div`
    flex: 0 0 auto;
    padding: 0 8px 0 16px;
    min-height: 48px;
    color: ${props => props.theme.color.color90};
    display: flex;
    align-items: center;
    justify-content: space-between;
    filter: blur(${props => props.blurred ? '5px' : '0'});
    font-size: ${props => props.theme.fontSize.headline2};
    position: relative;
    padding: 0px 16px;
    background: ${props => props.withBackgroundColor ? props.theme.color.color5 : props.theme.color.color0};
    z-index: ${props => '10' + props.dialogIndex.toString() + '1'};
`

const DialogSubHeader = styled.div`
    filter: blur(${props => props.blurred ? '5px' : '0'});
`

const DialogTitle = styled.h2`
    font-size: ${props => props.theme.fontSize.headline2}
`

const DialogContent = styled.div`
    /* flex: 1 1 auto; */
    filter: blur(${props => props.blurred ? '5px' : '0'});
    flex: 1 1 90%;
    text-align: left;
    /* padding-bottom: 16px; */
    overflow: hidden;
    display: flex;
    width: 100%;
`

const DialogContentWrapper = styled.div`
    padding: 0px 16px;
`

const DialogAbortConfirm = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.25);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    z-index: ${props => '10' + props.dialogIndex.toString() + '2'};
`

const DialogWaitingScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.25);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    padding-top: 40px;
    align-items: center;
    z-index: ${props => '10' + props.dialogIndex.toString() + '2'};
    overflow: auto;
`

const DialogAbortConfirmHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 15px;
    font-size: ${props => props.theme.fontSize.headline1};
    color: ${props => props.theme.color.primary};
    text-transform: uppercase;
    font-weight: 'bold';
    margin-bottom: 24px;
`

const DialogAbortConfirmButtonWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    width: 25%;
`

export {
    Overlay,
    DialogWrapper,
    Dialog,
    DialogHeader,
    DialogTitle,
    DialogContent,
    DialogContentWrapper,
    DialogAbortConfirm,
    DialogAbortConfirmHeader,
    DialogAbortConfirmButtonWrapper,
    DialogSubHeader,
    DialogWaitingScreen
}