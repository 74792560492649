import { LoadEffect } from 'application/common'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { config } from 'bluebird'
import React, { Fragment, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const AccountPublicInfoLoader = (props) => {
    const { children, publicInfoLoading, publicInfoLoaded, getAccountPublicInfo, accountPublicInfo } = props
    const { setUserAccountId, config } = usePluginsContext()
    let params = useParams()
    const { accountIdentity } = params
    const shouldLoad = accountIdentity !== undefined && accountIdentity !== null && accountIdentity !== '' && !publicInfoLoaded

    useEffect(() => {
        shouldLoad && getAccountPublicInfo(accountIdentity)
    }, [accountIdentity, getAccountPublicInfo, shouldLoad])

    useEffect(() => {
        if(!accountPublicInfo) return
        setUserAccountId(accountPublicInfo.accountId)
        if(config && config.disableAccountPage &&  accountPublicInfo.accountId == config.accountId)
            window.location.href = config.disableAccountPage.redirectTo
    }, [accountPublicInfo, config])

    if (publicInfoLoaded) {
        return <Fragment>
            {children}
        </Fragment>
    }

    return <LoadEffect loading={publicInfoLoading}>
        {children}
    </LoadEffect>
}

export default AccountPublicInfoLoader
