export const laneDefinitions = {
    admin: [
        {
            index: 0,
            name: 'unreleased',
            targetStatus: [0, 1],
            collapsed: false
        },
        {
            index: 1,
            name: 'inprogress',
            targetStatus: [2, 3, 4, 5],
            collapsed: false
        },
        {
            index: 4,
            name: 'inreview',
            targetStatus: [6],
            collapsed: false
        },
        {
            index: 6,
            name: 'done',
            targetStatus: [7],
            collapsed: true
        }
    ],    
    consultant: [
        {
            index: 0,
            name: 'unreleased',
            targetStatus: [0, 1],
            collapsed: false
        },
        {
            index: 1,
            name: 'inprogress',
            targetStatus: [2, 3, 4, 5],
            collapsed: false
        },
        {
            index: 4,
            name: 'inreview',
            targetStatus: [6],
            collapsed: false
        },
        {
            index: 6,
            name: 'done',
            targetStatus: [7],
            collapsed: true
        }
    ],
    internal: [
        {
            index: 0,
            name: 'todo',
            targetStatus: [2, 3, 5],
            collapsed: false
        },
        {
            index: 1,
            name: 'todo by others',
            targetStatus: [4],
            collapsed: false
        },
        {
            index: 4,
            name: 'done',
            targetStatus: [6],
            collapsed: false
        }
    ],
    user: [
        {
            index: 0,
            name: 'todo',
            targetStatus: [2, 3, 5],
            collapsed: false
        },
        {
            index: 1,
            name: 'todo by others',
            targetStatus: [4],
            collapsed: false
        },
        {
            index: 4,
            name: 'done',
            targetStatus: [6],
            collapsed: false
        }
    ],    
    basic: [
        {
            index: 0,
            name: 'all',
            targetStatus: [0, 1, 2, 3, 4, 5, 6, 7],
            collapsed: false
        }
    ]
}