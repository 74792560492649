export const getFileExtension = filename => /(?:\.([^.]+))?$/.exec(filename)[1]

const arrayBufferToBase64 = (buffer) => {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export const mapFileToAttachmentDataStructure = (file, binaryData) => {
    var extensionRegEx = /(?:\.([^.]+))?$/
    const displayableExtensions = process.env.REACT_APP_SUPPORTED_ATTACHMENT_EXTENSIONS
    const currentExtension = extensionRegEx.exec(file.name)[1]
    const key = Math.round(Math.random() * 10000)

    if (currentExtension === undefined || displayableExtensions.indexOf(currentExtension.toLowerCase()) === -1) {
        return {
            fileId: key,
            hasThumbnail: false,
            thumbnailIsLoading: false,
            fileExtension: currentExtension,
            fileName: file.name,
            thumbnail: ''
        }
    }

    return {
        fileId: key,
        hasThumbnail: true,
        thumbnailIsLoading: false,
        fileExtension: currentExtension,
        fileName: file.name,
        thumbnail: 'data:image/png;base64,' + arrayBufferToBase64(binaryData)
    }
}

export const getAccept = () => {
    const allowedExtensions = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_EXTENSIONS
    const extensionsArray = allowedExtensions.split(',')
    let accept = ''
    for (let i = 0; i < extensionsArray.length; i++) {
        accept += '.' + extensionsArray[i]
        if (i < extensionsArray.length - 1) {
            accept += ','
        }
    }
    return accept
}

export const getAcceptPp = () => {
    const allowedExtensions = process.env.REACT_APP_SUPPORTED_PROFILE_PICTURE_EXTENSIONS
    const extensionsArray = allowedExtensions.split(',')
    let accept = ''
    for (let i = 0; i < extensionsArray.length; i++) {
        accept += '.' + extensionsArray[i]
        if (i < extensionsArray.length - 1) {
            accept += ','
        }
    }
    return accept
}

export const isAccepted = ext => {
    const allowedExtensions = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_EXTENSIONS
    const extensionsArray = allowedExtensions.split(',')
    return extensionsArray.indexOf(ext) > -1
}

export const isAcceptedPp = ext => {
    const allowedExtensions = process.env.REACT_APP_SUPPORTED_PROFILE_PICTURE_EXTENSIONS
    const extensionsArray = allowedExtensions.split(',')
    return extensionsArray.indexOf(ext) > -1
}

export const readFiles = (acceptedFiles, callback, allFilesCallback = undefined) => {
    let allFiles = []
    let processedFiles = 0
    const filesCount = acceptedFiles.length

    acceptedFiles.forEach(file => {
        const reader = new FileReader()
        reader.onloadend = () => {
            callback({
                metaData: file,
                binaryData: reader.result,
                attachment: mapFileToAttachmentDataStructure(file, reader.result)
            })
            allFiles.push({
                metaData: file,
                binaryData: reader.result,
                attachment: mapFileToAttachmentDataStructure(file, reader.result)
            })
            processedFiles++
            if (allFilesCallback !== undefined && processedFiles === filesCount) {
                allFilesCallback(allFiles)
            }
        }
        reader.readAsArrayBuffer(file)
    })
}

// with promise

// export const readFiles = (acceptedFiles, callback, callbackAllFiles = () => {}) => {
//     let promises = []
//     for (let file of acceptedFiles) {
//         let filePromise = new Promise(resolve => {
            
//             let reader = new FileReader()
//             reader.readAsText(file)
//             reader.onload = () => {
//                 callback({
//                     metaData: file,
//                     binaryData: reader.result,
//                     attachment: mapFileToAttachmentDataStructure(file, reader.result)
//                 })
//                 resolve(reader.result)
//             }
//         })
//         promises.push(filePromise)
//     }
//     Promise.all(promises).then(results => {
//         callbackAllFiles(results.map((result, i) => {
//             return {
//                 metaData: acceptedFiles[i],
//                 binaryData: result,
//                 attachment: mapFileToAttachmentDataStructure(acceptedFiles[i], result)
//             }
//         }))
//     })
// }