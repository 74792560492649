import {
  API_VERSION_ESYMANDANT,
  fetchHandler,
  HTTP_METHOD,
  limiter,
} from 'application/api/request_builders'
import { fetchRequestBuilder } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'
import { accessTokenRegistered } from 'application/storage'

export const postloginAdHoc = (key) => {
  const payload = {
    key,
  }

  const request = fetchRequestBuilder(
    'authenticate/adhoc/workflow',
    HTTP_METHOD.POST,
    payload,
    true,
    false,
    false,
    accessTokenRegistered
  )

  // request.data.credentials = 'include'

  return limiter
    .schedule(() => fetchHandler(request.url, request.data))
    .then(handleApiResponse)
}
