import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import IconLabel from '../../controls/icon/icon_label/component'

const StyledMainContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
    align-items: flex-start
`

const PasswordStrengthCheckerComponent = ({
    password,
    strengthDescriptors,
    theme,
    show = true
}) => {
    const { t } = useTranslation()
    const getIcon = checker =>
        checker
            ? { icon: 'done', color: theme.color.primary }
            : { icon: 'warning', color: theme.color.warning }
    return show && <FlexGrid directionColumn>
        {Object.keys(strengthDescriptors).map(groupKey => {
            const group = strengthDescriptors[groupKey]
            let {
                title,
                methods,
                initialVisibility,
                groupMethod
            } = group
            const groupCheckDone = groupMethod(methods, password)
            const headerIcon = getIcon(groupCheckDone)

            const groupVisible = !groupCheckDone
                ? true
                : initialVisibility
            return (
                groupVisible && (
                    <FlexGridItem key={groupKey} itemsFlexStart>
                        <StyledMainContainerDiv key={groupKey}>
                            <IconLabel
                                icon={headerIcon.icon}
                                iconVisible={groupCheckDone}
                                iconColor={headerIcon.color}
                                label={t(title)}
                                fontSize={theme.fontSize.small}
                                fontWeight={theme.fontWeight.bold}
                                flexDirection={'row-reverse'}
                                gap={'5px'}
                            />
                            {methods.map((method) => {
                                const iconVisible = method.method(password)
                                    ? true
                                    : false
                                const criteriaIcon = getIcon(
                                    method.method(password)
                                )
                                return (
                                    <IconLabel
                                        key={method.name}
                                        icon={criteriaIcon.icon}
                                        iconColor={criteriaIcon.color}
                                        label={t(method.message)}
                                        fontSize={theme.fontSize.small}
                                        flexDirection={'row-reverse'}
                                        gap={'5px'}
                                        fontWeight={
                                            theme.fontWeight.bold
                                        }
                                        iconVisible={
                                            iconVisible ||
                                                !initialVisibility
                                        }
                                    />
                                )
                            })}
                        </StyledMainContainerDiv>
                    </FlexGridItem>
                )
            )
        })}
    </FlexGrid>
        
        
    
}

export default withTheme(PasswordStrengthCheckerComponent)
