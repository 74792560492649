import React from 'react'
import { isMobile, MobileView } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'

const Mobile = ({children}) => {
    const viewMobile = useMediaQuery({ maxWidth: 767 })
    const useMobile = isMobile || viewMobile
    return useMobile ? <MobileView>
        {children}
    </MobileView>  : null
}

export default Mobile