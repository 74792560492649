import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { withTheme } from 'styled-components'

const FormEffects = (props) => {
    const { formContainerBackground, children, setThemeOverride, theme, formLoaded } = props
    const changeBGformElement = formContainerBackground === '#fff' || formContainerBackground === '#ffffff' || formContainerBackground === 'white' || formContainerBackground === 'rgb(255,255,255)' || formContainerBackground === 'rgba(255,255,255,1)'
    useEffect(() => {
        formLoaded && changeBGformElement && setThemeOverride({
            formElementBackground: theme.color.smokewhite
        })
    }, [changeBGformElement, formLoaded, setThemeOverride, theme.color.smokewhite])
    return <Fragment>
        {children}
    </Fragment>
}

export default withTheme(FormEffects)
