import styled from 'styled-components'

export const StepText = styled.span`
    font-size: ${props => props.theme.fontSize.medium18};
    color: ${props => props.theme.color.color40};
    margin-bottom: 24px;
    @media (min-width: ${p => p.theme.breakpoint.tablet}px){
        display: none
    }
`

export const HeadlineWizard = styled.h1`
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.color.color100};
    font-family: ${props => props.theme.fontFamily.secondary};
    margin: 0px;
    margin-bottom: 10px;
    @media (min-width: ${p => p.theme.breakpoint.tablet}px){
    font-size: ${props => props.theme.fontSize.xxLarge};
    }
`

export const ParWizard = styled.h2`
    font-size: ${props => props.theme.fontSize.headline3};
    color: ${props => props.theme.color.color90};
    margin-top: 10px;

    &.succeed-reg-title-ec_mobile {
        text-align: center;
    }
    &#headline-succeed-reg-title-ec_mobile {
        font-size: ${props => props.theme.fontSize.xLarge};
    }
`

export const ImageWizard = styled.img`
    width: ${props => props.imgWidth !== undefined ? props.imgWidth : '80%'};
    height: auto;
`