import { DIALOG } from 'application/constants'
import { refreshProfilePictureSaga } from '../refresh'
import { getPinSaga } from './get_pin_saga'
import { uploadProfilePicture } from './upload_profile_picture'

export const getDialogDataSagas = (name) => {
    switch (name) {
    default:
        return []
    }
}

export const sendDialogDataSagas = (name) => {
    switch (name) {
    case DIALOG.NAME.SEND_PIN_OPTION:
        return [getPinSaga]
    case DIALOG.NAME.UPLOAD_PROFILE_PICTURE:
        return [uploadProfilePicture, refreshProfilePictureSaga]
    default:
        return []
    }
}