import styled from 'styled-components'

export const CropperWrapper = styled.div`
  position: relative;
        width: 100%;
        height: 300px;
        background: ${props => props.theme.color.color30};

        @media (max-width: ${props => props.theme.breakpoint.xSmall}px) {
        height: 300px;
        }
    & .reactEasyCrop_Container {
      
    }
`

export const SliderWrapper = styled.div`
width: 100%;
    & .MuiSlider-root {
        display: flex;
        flex: 1;
        margin-left: 16px;
        min-width: 65px;
        max-width: 80%;
        padding: 22px 0px;
        color: ${props => props.theme.color.primary};
    }

	@media (min-width: ${props => props.theme.breakpoint.sm}) {
    width: 50%;
	}
`