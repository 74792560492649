import React, { useEffect } from 'react'

const GalleryOnQueueEffects = ({ gallery, filesOnQueue, onSelectAttachment, children }) => {

    useEffect(() => {
        gallery.selectedFileId === null &&
            filesOnQueue.length > 0 &&
            onSelectAttachment(filesOnQueue[0].attachment.fileId, filesOnQueue[0].attachment.fileName, filesOnQueue[0].attachment.thumbnail, gallery.accountId)
    }, [filesOnQueue, gallery.accountId, gallery.selectedFileId, onSelectAttachment])

    return <React.Fragment>
        {children}
    </React.Fragment>
}

export default GalleryOnQueueEffects