import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import WizardStep from 'application/components/building_blocks/wizard_step'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import * as Fragments from 'application/components/pages/_layout/fragments/wizard'
import IconLink from 'application/components/controls/icon/icon_link'

const TopContentWizardReg = ({noPaddingRightOnContent = false, wizardData, useMobile = false, withBackStep = false, backStepClik, accountId}) => {
    return <Fragment>
        <Fragments.LayoutContentWrapper needPadding={noPaddingRightOnContent}>
            <FlexGridItem grow={0}>
                <FlexGrid justifySpaceBetween noWrap>
                    {
                        withBackStep && <FlexGridItem itemsCenter>
                            <IconLink width='35px' iconKey='chevron_left' onClick={backStepClik} />
                        </FlexGridItem>
                    }
                    <FlexGridItem>
                        <LanguageSwitchHoc withLabel={!useMobile} position={useMobile ? 'flex-end' : 'flex-start'} />
                    </FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
            <PushBottom24 />
            {
                !useMobile &&
                <FlexGridItem itemsCenter grow={0}>
                    <FlexGrid>
                        <FlexGridItem>
                            <WizardStep accountId={accountId} wizardData={wizardData} />
                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
            }
            <PushBottom24 />
            {
                !useMobile &&
                <FlexDividerHorizontal />
            }
        </Fragments.LayoutContentWrapper>
        <PushBottom24 />
    </Fragment>
}

export default TopContentWizardReg
