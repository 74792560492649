import styled from 'styled-components'

export const NavigationLinkMobileWrapper = styled.div`
	padding: 40px 20px;
	display: flex;
	flex-flow: column nowrap;
	gap: 30px;
`

export const NavigationLinkSettingsMobileWrapper = styled.div`
	display: flex;
	flex-flow: column nowrap;
	gap: 20px;
`

export const SettingsHeadline = styled.h2`
	font-size: ${p => p.theme.fontSize.headline2};
	font-family: ${p => p.theme.fontFamily.secondary};
	color: ${p => p.theme.color.color100};
`