import React from 'react'
import * as Fragments from '../fragments'
import { useTranslation } from 'react-i18next'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const ExternalRequestsSection = (props) => {
    const { accountPublicInfo, onExternalRequestClick, useMobile = false } = props
    const { assistants, primaryColor, accountUniqueKey } = accountPublicInfo
    const assistantsAvailable = assistants !== undefined && assistants !== null && assistants !== '' && assistants.length > 0
    const {activePlugin, config} = usePluginsContext()
    const formattedAssistants  = assistants.map((assistant) => {
        if(activePlugin)
            return {...assistant, url: assistant.url.replace('app.esy.one', config.hostName)}
        else return {...assistant}
    })
    const { t } = useTranslation()
    const mainServiceIndex = formattedAssistants.some(v => v.isMainService === true) ? formattedAssistants.findIndex(v => v.isMainService === true) : 0

    return <Fragments.AsclarMainContainer>
                <Fragments.AsclarSpacer padding={useMobile ? '5px' : '20px'}/>
                <Fragments.AsclarText isBold textAlign={useMobile ? 'left' : 'left'} fontSize={useMobile ? '18px' : '32px'}>{t('Services')}</Fragments.AsclarText>
                <Fragments.AsclarSpacer />
                {assistantsAvailable &&
                    <Fragments.MainAssistanceContainer direction={useMobile ? 'column' : 'row'}>
                        <Fragments.MainAssistanceContainerCustom width={useMobile ? '100%' : '48%'}>
                            {formattedAssistants[mainServiceIndex].headerLogoUrl !== null ? (
                                <Fragments.ImageMainService src={formattedAssistants[mainServiceIndex].headerLogoUrl} />
                            ) : (
                                <Fragments.ServiceMainLogoNotExist backgroundColor={primaryColor}> 
                                    {formattedAssistants[mainServiceIndex].name.charAt(0) + formattedAssistants[mainServiceIndex].name.charAt(1)}
                                </Fragments.ServiceMainLogoNotExist>
                            )}
                        </Fragments.MainAssistanceContainerCustom>
                        <Fragments.MainContentContainer width={useMobile ? '100%' : '48%'}>
                            <Fragments.MainServiceTitle>{formattedAssistants[mainServiceIndex].name}</Fragments.MainServiceTitle>
                            <Fragments.MainServiceText>{formattedAssistants[mainServiceIndex].description}</Fragments.MainServiceText>
                            <Fragments.MainServiceButton width={useMobile ? '85%' : 'fit-content'} href={formattedAssistants[mainServiceIndex].url} target='_blank' rel='noopener noreferrer'
                            >
                            zum Online-Formular 
                                <span>
                                    <Fragments.MainServiceButtonIcon src='/assets/images/arrow-right.png' />
                                </span>
                            </Fragments.MainServiceButton>
                        </Fragments.MainContentContainer>
                    </Fragments.MainAssistanceContainer>
                }
                <Fragments.AsclarSpacer />
                <Fragments.ServiceListContainer direction={useMobile ? 'column' : 'row'} >
                    {assistantsAvailable && formattedAssistants.map((assistant, index) => (
                        assistant.name && assistant.isMainService !== true && index != mainServiceIndex &&  (
                        <Fragments.ServiceListCard key={assistant.id} width={useMobile ? '100%' : '48%'}>
                            {assistant.headerLogoUrl != null ? (
                                <Fragments.ServiceListLogoExist src={assistant.headerLogoUrl} /> 
                            ) : (
                                <Fragments.ServiceListLogoNotExist backgroundColor={primaryColor}>
                                    {assistant.name.charAt(0) + assistant.name.charAt(1)}
                                </Fragments.ServiceListLogoNotExist>
                            )}
                            <Fragments.ServiceListContentContainer>
                                <Fragments.ServiceListTitle>{assistant.name}</Fragments.ServiceListTitle>
                                <Fragments.ServiceListText>{assistant.description}</Fragments.ServiceListText>
                            </Fragments.ServiceListContentContainer>
                            <Fragments.ServiceListButtonContainer>
                            <Fragments.ServiceListButton href={assistant.url} target='_blank' rel='noopener noreferrer' width={useMobile ? '90%' : '40%' }>
                            zum Online-Formular 
                                <span>
                                    <Fragments.MainServiceButtonIcon src='/assets/images/arrow-right.png' />
                                </span>
                            </Fragments.ServiceListButton>
                            </Fragments.ServiceListButtonContainer>
                        </Fragments.ServiceListCard>
                        )
                    ))}
                </Fragments.ServiceListContainer>
                <Fragments.AsclarSpacer />
        </Fragments.AsclarMainContainer>
}

export default ExternalRequestsSection
