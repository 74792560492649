import IconLink from 'application/components/controls/icon/icon_link'
import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import { withTheme } from 'styled-components'
import * as Fragment from './fragments'
import { useTranslation } from 'react-i18next'

const DisplayFavoritesAkteDetailsComponent = ({ theme, onClick }) => {
    const {t} = useTranslation()
    return <React.Fragment>
        <FlexGrid justifySpaceBetween itemsCenter>
            <Fragment.TitleRowAkteDetails>
                {t('Favorites')}
            </Fragment.TitleRowAkteDetails>
            <IconLink title={t('Display your favorites message')} iconKey='arrow_forward' onClick={onClick} color={theme.color.primary} size={theme.fontSize.medium} />
        </FlexGrid>
    </React.Fragment>
}

const DisplayFavoritesAkteDetails = withTheme(DisplayFavoritesAkteDetailsComponent)
export default DisplayFavoritesAkteDetails