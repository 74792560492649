import React from 'react'
import * as Fragment from '../fragments'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import { useTranslation } from 'react-i18next'
import { AuthLayout } from 'application/components/pages/_layout'
import { Spacer } from 'application/components/pages/internal/dashboard/fragments'
import { AkteMetaDataLoader, PinRedirect, StartRedirect } from '../../_common'
import WizardRedirect from '../../_common/wizard_redirect'
import { CONSENT } from 'application/constants/consent'
import { textHelpers } from 'application/common'
import {LogoAuth, TitlePin} from '../../_common'
import RequestButton from './sections/request_button'
import RedirectMobile from 'application/common/redirect_mobile'
import { AuthMobileVersion } from '../../mobile'
import { isMobileOnly } from 'react-device-detect'
import WebHeaderLogoEffect from 'application/common/web_header_logo'

const RequestPinComponent = (props) => {
    const { t } = useTranslation()

    const { akteMeta, onOpenDialogSendPinOption, onOpenDialogSendPinOptionOnMobile, reqPin, pinRequest, akteMetaLoaded, consentUseApp, setConsent } = props

    const errorMessage = '🔥 ' + t('RequestPINError')
    const successMessage = akteMetaLoaded && akteMeta.isSmsAvailable ? '🔑 ' + t('RequestPINSuccess') : '🔑 ' + t('RequestPINSuccessEmail')
    const handleRequestPin = () => {
        if (akteMeta.isSmsAvailable) {
            isMobileOnly ? onOpenDialogSendPinOptionOnMobile(akteMeta.authentication) : onOpenDialogSendPinOption(akteMeta.authentication)
        } else {
            reqPin(akteMeta.authentication, 'Email', errorMessage, successMessage)
        }
        !consentUseApp && setConsent(textHelpers._enText(CONSENT.ACCEPT_TEXT + new Date()))
    }
    const mobileProps = {
        ...props,
        handleRequestPin,
        errorMessage,
        successMessage,
    }
    return (
        <WebHeaderLogoEffect accountPublicInfo={akteMeta}>
            <AkteMetaDataLoader {...props}>
                <StartRedirect {...props}>
                    <PinRedirect {...props}>
                        <WizardRedirect {...props}>
                            <AuthLayout>
                                <RedirectMobile
                                    MobileComponent={AuthMobileVersion.RequestPinMobile}
                                    {...mobileProps}
                                >
                                    <Fragment.LayoutInput>
                                        <LanguageSwitchHoc />
                                        <Fragment.FormLoginWrapper stretched>
                                            <TitlePin accountId={akteMeta.accountId} />
                                            <Spacer distance='48px' />
                                            <RequestButton akteMeta={akteMeta} pinRequest={pinRequest} handleRequestPin={handleRequestPin} />
                                            <Spacer distance='48px' />
                                        </Fragment.FormLoginWrapper>
                                        <LogoAuth accountId={akteMeta.accountId} />
                                    </Fragment.LayoutInput>
                                </RedirectMobile>
                            </AuthLayout>
                        </WizardRedirect>
                    </PinRedirect>
                </StartRedirect>
            </AkteMetaDataLoader>
        </WebHeaderLogoEffect>
    )
}

export default RequestPinComponent