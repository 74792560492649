import React from 'react'
import ContentLoader from 'react-content-loader'

const ChatBubbleFilesLoader = (props) => (
    <ContentLoader
        speed={2}
        width={200}
        height={200}
        viewBox="0 0 400 460"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="-138" y="-147" rx="2" ry="2" width="544" height="544" />
    </ContentLoader>
)

export default ChatBubbleFilesLoader