import React from 'react'
import PropTypes from 'prop-types'
import * as Fragments from './fragments'
import SnippetFolderRoundedIcon from '@mui/icons-material/SnippetFolderRounded'
import { useTranslation } from 'react-i18next'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { PluginAlternateView } from 'plugins'

const FoldersContent = ({ folders, setSelectedFolder }) => {
	const { t } = useTranslation()
    const {activePlugin} = usePluginsContext()
	
    return (
        <Fragments.LayoutContentAktenMobile>
            {folders.length !== 0 ? (
                <Fragments.WrapperContentAktenMobile gap='15px'>
                    {folders?.map((f, i) => {
                        return (
                            <Fragments.FolderWrapper key={i} onClick={() => setSelectedFolder(f)}>
                                <SnippetFolderRoundedIcon sx={{ color: '#1696FB', fontSize: 100 }} />
                                <Fragments.FolderName>{f.name}</Fragments.FolderName>
                            </Fragments.FolderWrapper>
                        )
                    })}
                </Fragments.WrapperContentAktenMobile>
            ) : (
                <PluginAlternateView id='mobile_view.akten.folders'>
                    <Fragments.CardNoContentWrapper>
                        <Fragments.ImageNoContent src='/assets/images/no_folders.png' />
                        <Fragments.ParNoContent>{t('No folders have been created yet.')}</Fragments.ParNoContent>
                    </Fragments.CardNoContentWrapper>
                </PluginAlternateView>
            )}
        </Fragments.LayoutContentAktenMobile>
    )
}

FoldersContent.propTypes = {
    folders: PropTypes.array,
    setSelectedFolder: PropTypes.func,
}

export default FoldersContent
