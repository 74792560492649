import React from 'react'
import { Fragment } from 'react'
import LogoLoginForm from '../../../mobile_assistant/login_pin/sections/logo_login_form'
import FormPinSection from '../../../mobile_assistant/login_pin/sections/form'
import LoginPinFormContentAesthetic from '../../../mobile_assistant/login_pin/sections/content_aesthetic'

export const LoginPinMobileComponent = (props) => {
    const { isLandscape } = props
    return <Fragment>
        <LogoLoginForm flexGrow={0} flexShrink={0} flexBasis={isLandscape ? '20%' : '10%'} />
        <LoginPinFormContentAesthetic flexGrow={1} flexShrink={0} flexBasis={isLandscape ? 'auto' : '30%'} maxWidthContainer='100%' largeImage={isLandscape ? '30%' : '70%'} heightImage='100%' />
        <FormPinSection flexGrow={1} flexShrink={1} flexBasis='auto' {...props} />
    </Fragment>
}

export default LoginPinMobileComponent
