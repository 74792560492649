import { useTranslation } from 'react-i18next'

export function MessageEmpty(){
    const {t} = useTranslation()
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        fontSize: '20px',
        fontWeight: 'bold',
        height: '100%'
    }}>
        <span>{t('EmptySharedMessageContent')}</span>
    </div>
}