import Checkbox from 'application/components/controls/form_elements/checkbox'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { LawBannerCompanyName, LawBannerContainer, LawBannerLink, LawBannerText } from './fragments'
import { scroller } from 'react-scroll'
import { PluginAlternateView, PluginsContext } from 'plugins'
import { useTheme } from 'styled-components'

const LawBanner = ({ hasAgb, checkedAccountId, esyThingAgb, esyThingDatenschutz, hasDatenschutz, companyName = '', accountId, onAccept, agbsAndDatenschutzAccepted, borderColor, backgroundColor, acceptToBottom = false, onOpenAccountStatement}) => {
    const { count, t } = useTranslation()
    const clickToBottom = () => {   
        scroller.scrollTo('activationAccountComponentSidebarButton',{
            smooth: true,
            containerId: 'activationAccountComponentSidebar',
            offset: 0,
            duration: 1000,
            delay: 100,
        })
    }
    const handleAccept = (condition) => {
        onAccept(condition, accountId)
    }
    const handleDisplayDatenschutz = () => {
        onOpenAccountStatement(esyThingDatenschutz, t('privacy') + ' - ' + companyName)
    }
    const handleDisplayAgb = () => {
        hasDatenschutz && onOpenAccountStatement(esyThingAgb, t('conditions') + ' - ' + companyName)
    }

    const theme = useTheme()

    const checked = accountId === checkedAccountId && agbsAndDatenschutzAccepted
    return <Fragment>
        <LawBannerContainer borderColor={borderColor} backgroundColor={backgroundColor}>
            <FlexGrid noWrap>
                <FlexGridItem itemsFlexStart widthMobile='10%' width='10%' grow={0} shrink={0}>
                    {
                        acceptToBottom ?
                            <Checkbox onClick={clickToBottom} width='18px' height='18px' onChange={(e) => handleAccept(e.target.checked)} checked={checked} /> :
                            <Checkbox width='18px' height='18px' onChange={(e) => handleAccept(e.target.checked)} checked={checked} />
                    }
                </FlexGridItem>
                <FlexGridItem itemsFlexStart widthMobile='85%' width='85%' grow={0} shrink={0}>
                    <LawBannerText style={theme.classes.lawBannerText}>
                        <PluginAlternateView id='lawbanner.text'>
                            <>
                                {hasAgb && <Trans i18nKey='LawBannerHasAGB' count={count}>
                                    I have read <LawBannerCompanyName>{{ companyName }}</LawBannerCompanyName>'s <LawBannerLink style={{ color: theme.color.primary }} onClick={handleDisplayAgb}>Terms and Conditions</LawBannerLink> and I agree to them. {' '}
                                </Trans>}
                                {
                                    hasDatenschutz && <Trans i18nKey='LawBannerHasDatenschutz' count={count}>
                                        Find out more under the law firm's <LawBannerLink style={{ color: theme.color.primary }} onClick={handleDisplayDatenschutz}>Data protection notice</LawBannerLink>.
                                    </Trans>
                                }
                            </>
                        </PluginAlternateView>
                    </LawBannerText>
                </FlexGridItem>
            </FlexGrid>
        </LawBannerContainer>
    </Fragment>
}
export default LawBanner