import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { StyledLabel } from './../fragments/styled-label'

import Select, { components } from 'react-select'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ErrorLabel } from 'application/components/fragments/error_information'


const StyledSelectInputWithLabel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 100%;
    height: ${props => props.cssHeight !== undefined ? props.cssHeight : 'auto'};
    &>div{
        height: ${props => props.cssHeight !== undefined ? props.cssHeight : 'auto'};
    }
    margin-bottom: ${props => props.cssMarginBottom !== undefined ? props.cssMarginBottom : '0'};
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.color90};
    background: ${props => props.theme.color.color0};

    & label.label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.small};
    }

    & div.css-1wa3eu0-placeholder { 
        color: ${props => props.theme.color.color80};
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props.theme.color.color80}
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props.theme.color.color80}
      }

      /* // select icon customisation */

      & i.material-icons {
        font-size: ${props => props.theme.fontSize.standart};
      }

      &  span.css-1okebmr-indicatorSeparator {
          margin-top: 2px;
          height: 20px;
      }

      & div.css-1hb7zxy-IndicatorsContainer,
       div.css-tlfecz-indicatorContainer {
          height: 35px;
          padding: 4px;
      }

      & div.css-1oai5dw-control {
          border-color: ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.color15};
          height: ${props => props.cssHeight !== undefined ? props.cssHeight : 'auto'};
          align-content:center;
      }
      & div.css-ypr86o-control,
      & div.css-zbnekv-control{
        align-content:center;
        background: ${props => props.cssBackground};
        }
      & * {
        border-radius: 0px !important;
      }
`

const SelectInputWithLabel = ({
    selectClassName = '',
    isRequired = false,
    iconKey = 'keyboard_arrow_down',
    defaultValue = null,
    options = null,
    disabled = false,
    placeholder = '',
    label = '',
    isClearable = true,
    onSelectChange = () => { },
    validationHandlers = {},
    validationErrors = [],
    marginBottom,
    cssHeight,
    cssBackground = '',
    noOptionsMessage = 'no options',
    htmlId
}) => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)
    const defaultColor = theme.color.color15
    const hoverColor = theme.color.lightPowderGray
    const selectedColor = theme.color.color90
    const hoveredOptionColor = theme.color.black10
    const selectedOptionColor = theme.color.primary
    const bg = cssBackground === '' ? theme.color.color0 : cssBackground

    const customStyles = {
        control: (base, state) => ({
            ...base,
            height: cssHeight !== undefined ? cssHeight : '35px',
            minHeight: cssHeight !== undefined ? cssHeight : '35px',
            boxShadow: state.isFocused ? null : null,
            borderColor: state.isFocused ? selectedColor : defaultColor,
            '&:hover': {
                borderColor: hoverColor,
            }
        }),
        option: (base, state) => ({
            ...base,
            backgroundcolor: state.isselected ? selectedOptionColor : '',
            '&:hover': {
                background: hoveredOptionColor
            }
        })
    }

    const selectChange = (value) => {
        if (!disabled) {
            if (onSelectChange !== undefined) {
                onSelectChange(value)
            }
            if (validationHandlers.onChange !== undefined) {
                validationHandlers.onChange(value)
            }
        }
    }

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="material-icons">{iconKey}</i>
            </components.DropdownIndicator>
        )
    }

    const ClearIndicator = props => {
        return (
            <components.ClearIndicator {...props}>
                <i className="material-icons">close</i>
            </components.ClearIndicator>
        )
    }
    const finalLabel = isRequired ? label + '*' : label

    return <StyledSelectInputWithLabel
        disabled={disabled}
        cssHeight={cssHeight}
        cssBackground={bg}
        cssValidationError={isRequired && validationErrors.length > 0}
        cssMarginBottom={marginBottom}
    >
        <React.Fragment>
            {
                label !== '' && <StyledLabel htmlFor={htmlId}>{finalLabel}</StyledLabel>
            }
            <Select
                components={{ DropdownIndicator, ClearIndicator }}
                defaultValue={isRequired ? validationHandlers.value : defaultValue}
                styles={customStyles}
                className={selectClassName}
                placeholder={placeholder}
                onChange={selectChange}
                options={options}
                isDisabled={disabled}
                isClearable={isClearable}
                value={defaultValue}
                noOptionsMessage={() => { return t(noOptionsMessage) }}
            />
            {
                validationErrors.length > 0 && isRequired && validationErrors.map((v, i) => {
                    return <ErrorLabel key={i}>
                        {t(v)}
                    </ErrorLabel>
                })
            }
        </React.Fragment>
    </StyledSelectInputWithLabel>
}

SelectInputWithLabel.propTypes = {
    iconKey: PropTypes.string,
    selectClassName: PropTypes.string,
    defaultValue: PropTypes.object,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    isClearable: PropTypes.bool,
    onSelectChange: PropTypes.func,
    cssBackground: PropTypes.string
}

export default SelectInputWithLabel