import React from 'react'
import PropTypes from 'prop-types'
import DefaultIconLink from './default'
import IconWithLink from './with_link'
import IconLinkWithLabel from './with_label_link'
import { POSITION_LABEL_ICON } from './helpers_link'
import DefaultIconWithLabel from './with_label default'

const IconLink = (props) => {
    const {
        to = '',
        label = '',
        textTransformLabel = 'uppercase',
        positionLabel = POSITION_LABEL_ICON.BOTTOM,
    } = props
    if (to !== '') {
        if (label !== '') {
            return <IconLinkWithLabel positionLabel={positionLabel} textTransformLabel={textTransformLabel} {...props}  />
        }
        return <IconWithLink {...props} />
    }
    if (label !== '') {
        return <DefaultIconWithLabel positionLabel={positionLabel} {...props} />
    }
    return <DefaultIconLink {...props} />
}

IconLink.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    iconKey: PropTypes.string,
    iconSize: PropTypes.string,
    color: PropTypes.string,
    active: PropTypes.bool
}


export default IconLink