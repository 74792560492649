import { DIALOG } from 'application/constants'
import { setConsentAccept } from 'application/redux/actions/consent_use_app'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { downloadFile, getFullFileById } from 'application/redux/actions/files'
import {
    getFormElementsRequest,
    refreshGetFormElements,
    refreshReturnUrlForm,
    refreshSendForm,
    sendFormRequest,
    setThemeOverride,
} from 'application/redux/actions/form'
import { showGallery } from 'application/redux/actions/gallery'
import { connect } from 'react-redux'
import FormMobileAssistantComponent from './component'
import { accessTokenForm } from 'application/storage/access_token'

const mapStateToProps = (state) => ({
    formContainerBackground: state.form.theme.formContainerBackground,
    themeParentApplication: state.form.theme,
    withPin: state.form.withPin,
    authenticated: state.form.authenticated,
    loginAttempt: state.form.loginAttempt,
    formMetaData: state.form.metaData,
    formReturnUrl: state.form.returnUrl,
    formLoaded: state.form.succeed,
    formRedirected: state.form.redirected,
    formLoadedFailed: state.form.failed,
    formLoading: state.form.request,
    sendRequest: state.form.send.request,
    sendSucceed: state.form.send.succeed,
    sendFailed: state.form.send.failed,
    formData: state.form.payload,
    waitingScreen: state.waitingScreen,
    accountPublicInfo: state.common.accountDetails.publicInfo,
    consentUseApp: state.consentUseApp,
    auth: state.auth,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getFormAsssistant: (key, pin, loginAttempt, metaData) => {
            dispatch(getFormElementsRequest(key, pin, loginAttempt, metaData))
        },
        onSendFormRequest: (workflowKey, formData) => {
            dispatch(sendFormRequest(workflowKey, formData))
        },
        downloadAttachment: (fileId, fileName, accountId, storage) =>
            dispatch(downloadFile(fileId, fileName, accountId, storage)),
        onOpenGallery: (selectedId, selectedName, selectedAccountId) =>{
            dispatch(getFullFileById(selectedId, selectedName, {
                accountId: selectedAccountId, 
                storage: accessTokenForm
            }))
            dispatch(showGallery(selectedId, selectedName, selectedAccountId,))
        },
        onOpenGalleryThumbnail: (
            selectedId,
            selectedName,
            selectedAccountId,
            parentId,
            akteIdHash,
            thumbnails
        ) =>{
            thumbnails.forEach((t) => dispatch(getFullFileById(t.id, t.fileName, {
                accountId: selectedAccountId,
                storage: accessTokenForm,
            })))
            dispatch(
                showGallery(
                    selectedId,
                    selectedName,
                    selectedAccountId,
                    parentId,
                    akteIdHash,
                    thumbnails
                )
            )
        },

        openAccountStatement: (statement, title) =>
            dispatch(
                openDialogByName(
                    DIALOG.NAME.DISPLAY_ACCOUNT_STATEMENT,
                    true,
                    { statement },
                    title
                )
            ),
        setThemeOverride: (theme) => dispatch(setThemeOverride(theme)),
        setConsent: (dateAccept) => dispatch(setConsentAccept(dateAccept)),
        refreshReturnUrlForm: () => {
            dispatch(refreshReturnUrlForm())
            dispatch(refreshSendForm())
            dispatch(refreshGetFormElements())
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormMobileAssistantComponent)
