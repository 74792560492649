import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'


export function LawBannerTextMyk() {
  const { t } = useTranslation()
  const { config } = usePluginsContext()
  const theme = useTheme()

  return (
    <>
      {t('LawBannerText1')} {' '}
      <a
        style={theme.classes.lawBannerAgreement}
        href={config.datenschutzLink}
        target='_blank'
      >
              {t('LawBannerText2')}

      </a>
      {' '}{t('LawBannerText3')}
    </>
  )
}
