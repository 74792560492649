import React, { useState, useRef, Fragment } from 'react'
import * as Fragments from './fragments'
import { PushLeft18 } from 'application/components/fragments/grid'
import FontIcon from 'application/components/controls/font_icon'

const Accordion = ({
    title,
    titleDescription = <></>,
    children,
    iconLess = 'expand_less',
    iconMore = 'expand_more',
    iconPositionAfter = true,
    description = '',
    colorTitle = '#404040',
    iconColor = '#404040',
    isActive = false,
    onAccordionClick = () => { },
    contentMarginBottom = '24px',
    minHeight = '0px',
    withBorderBottom = true,
    contentMarginTop = '16px',
}) => {

    const [active, setActive] = useState(isActive)
    const [maxHeight, setMaxHeight] = useState(minHeight)

    const content = useRef(null)

    const toggleAccordion = () => {
        setActive(!active)
        setMaxHeight(
            active ? minHeight : 'auto'
        )
        onAccordionClick()
    }

    return <Fragments.AccordionSection withBorderBottom={withBorderBottom}>
        <Fragments.AccordionButton iconPositionAfter={iconPositionAfter} onClick={toggleAccordion} >
            {
                iconPositionAfter ? <Fragment>
                    <Fragments.AccordionTitle colorTitle={colorTitle}>
                        {title}
                    </Fragments.AccordionTitle>
                    {active ? <FontIcon
                        icon={iconLess}
                        color={iconColor}
                        isClickable={true}
                    /> : <FontIcon
                        icon={iconMore}
                        color={iconColor}
                        isClickable={true}
                    />}
                </Fragment> : <Fragment>
                    {active ? <FontIcon
                        icon={iconLess}
                        color={iconColor}
                        isClickable={true}
                    /> : <FontIcon
                        icon={iconMore}
                        color={iconColor}
                        isClickable={true}
                    />}
                    <PushLeft18 />
                    <Fragments.AccordionTitle colorTitle={colorTitle}>
                        {title}
                    </Fragments.AccordionTitle>
                </Fragment>
            }
        </Fragments.AccordionButton>
        {!active && titleDescription}
        <Fragments.AccordionContent contentMarginBottom={contentMarginBottom} contentMarginTop={contentMarginTop} ref={content} maxHeight={maxHeight}>
            {description}
        </Fragments.AccordionContent>
        {
            children && <Fragments.AccordionContent contentMarginBottom={contentMarginBottom} contentMarginTop={contentMarginTop} ref={content} maxHeight={maxHeight}>
                {children}
            </Fragments.AccordionContent>
        }
    </Fragments.AccordionSection>
}

export default Accordion