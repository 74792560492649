import ActionButtonLinkWithIcon from 'application/components/controls/button/action_button_with_icon/component'
import TagCategory from 'application/components/controls/tag_category'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { FlexGrid, FlexGridItem, PushBottom20 } from 'application/components/fragments/grid'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import React, { useState } from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

const CategoriesServiceProviderDetail = (props) => {
    const { selectedAccount, maxCountCategories = 8 } = props
    const {t} = useTranslation()
    const [maxLength, setMaxLength] = useState(maxCountCategories)
    const [showedAll, setShowedAll] = useState(false)
    const categories = selectedAccount?.categories !== null ? selectedAccount?.categories : null
    const categoriesLength = categories?.length
    const showButton = categoriesLength > maxCountCategories
    const buttonText = showedAll ? t('Show it less') : t('Show it more')
    const iconKey = showedAll ? 'expand_less' : 'expand_more'
    const handleClick = () => {
        setShowedAll(!showedAll)
        const countToShow = showedAll ? 8 : categoriesLength
        setMaxLength(countToShow)
    }
    return <Fragment>
        <PushBottom20 />
        <FlexGridItem grow={0.01}>
            <FlexGrid directionColumn>
                <FlexGrid justifyCenter>
                    {categories !== null &&
                        categories?.slice(0, maxLength).map((c, i) => {
                            return c !== '' && c !== null && c !== undefined &&
                                <FlexGridItem widthMobile='auto' key={i} grow={0}>
                                    <TagCategory
                                        key={i}
                                        disabled={true}
                                        text={c}
                                        backgroundcolor={selectedAccount.mainColor}
                                        truncate={true}
                                        truncateAt={25}
                                        displayTitle={c.length > 25}
                                    />
                                </FlexGridItem>
                        })
                    }
                </FlexGrid>
                <PushBottom20 />
                <FlexGridItem itemsCenter justifyCenter>
                    {showButton && <ActionButtonLinkWithIcon onButtonClick={() => handleClick()} noBackground buttonText={buttonText} color={props.theme.color.color70} iconPosition='after' iconKey={iconKey} fontSize={props.theme.fontSize.standard} />}
                </FlexGridItem>
            </FlexGrid>
        </FlexGridItem>
        {
            showButton && <Fragment>
                <PushBottom24 />
                <FlexDividerHorizontal />
                <PushBottom24 />
            </Fragment>
        }
    </Fragment> 
}

export default withTheme(CategoriesServiceProviderDetail)
