import React from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import NotesPassword from 'application/components/pages/external/wizard/registration/password/sections/notes_password'

const NotesPasswordRegistrationDialog = ({ abort, dialog }) => {
    return <React.Fragment>
        <DialogMainContent>
            <NotesPassword />
        </DialogMainContent>
    </React.Fragment>

}

export default NotesPasswordRegistrationDialog