import React from 'react'
import styled from 'styled-components'


const StyledIconCounter = styled.div`
    height: ${props => props.height ? props.height : '24px'};
    width: ${props => props.width ? props.width : '32px'};
    border-radius: 40px;
    background-color: ${props => props.isNotification ? props.theme.color.counterColor : 'transparent'};
    justify-content: center;
    padding: 8px;
    display: ${props => props.displayAdjust ? 'block' : 'flex'};
    flex: 0 0 20px;
    flex-direction: row;
    align-items: center;
    cursor: ${props => props.isFuncClicked !== null ? 'pointer' : ''};
    &>span{
        font-size: ${ props => props.fontSize !== '' ? props.fontSize : props.theme.fontSize.standard};
        color: ${props => props.cssDisabled ? props.theme.color.color20 : props.theme.color.color0};
        };
`

const Indicator = ({ width, height, className, isNotification=false, onButtonClick = null, iconKey, text = '', disabled = false, label = '', fontSize = '', iconSize = '', displayAdjust= false}) => {
    return <StyledIconCounter width={width} height={height} isNotification={isNotification} onClick={onButtonClick === null || disabled ? () => {} : onButtonClick} title={label} cssDisabled={disabled} isFuncClicked={onButtonClick} fontSize={fontSize} iconSize={iconSize} className={className} displayAdjust={displayAdjust}>
        <i className="material-icons">
            {iconKey}
        </i>
        {
            text !== '' && 
        <span>
            {text}
        </span>
        }
    </StyledIconCounter>
    
}

export default Indicator