import React, { useEffect } from 'react'

const SecondaryLogoLoader = (props) => {
    const { children, accountId, secondaryLogoLoaded, getSecondaryLogo, auth } = props
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    useEffect(() => {
        !secondaryLogoLoaded &&
            loggedIn &&
            getSecondaryLogo(accountId)
    }, [accountId, getSecondaryLogo, loggedIn, secondaryLogoLoaded])
    
    return <div>
        {children}
    </div>
}

export default SecondaryLogoLoader