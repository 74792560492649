import ACTIONS from 'application/constants'

export const closeNavigationLinkMobilePopupSidebar = () => ({
    type: ACTIONS.SIDEBAR_CLOSE_NAVIGATION_LINK_MOBILE
})

export const setDialogVisibleNavigationLinkMobileSidebar = (condition) => ({
    type: ACTIONS.SIDEBAR_SET_DIALOG_NAVIGATION_LINK_MOBILE,
    condition
})

export const openNavigationLinkMobilePopupSidebar = () => ({
    type: ACTIONS.SIDEBAR_OPEN_NAVIGATION_LINK_MOBILE
})