import React from 'react'
import styled from 'styled-components'
import { StyledButtonWrapper } from './fragments'

const StyledButtonHref = styled.a`
    padding: 0px;
    margin: 0px;
    /* background-color: ${props => props.theme.color.color0}; */
    border: none;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: ${props => props.theme.color.color0};
    width:${props => props.strechted ? '100%' : 'auto'};
    text-decoration: none;
`

const ActionButtonHref = ({
    buttonText,
    className,
    onButtonClick,
    strechted = false,
    disabled = false,
    secondary = false,
    noBackground = false,
    noPadding = false,
    hrefTo = '',
    color = '#fff',
    fontSize = '18px',
    height,
    borderradius,
    backgroundColor,
    outline = false,
    borderColor = '',
}) => {

    return <StyledButtonHref target='_blank' onClick={onButtonClick} href={hrefTo} strechted={strechted}>
        <StyledButtonWrapper
            borderradius={borderradius}
            height={height}
            color={color}
            fontSize={fontSize}
            noBackground={noBackground}
            secondary={secondary}
            className={className}
            backgroundColor={backgroundColor}
            outline={outline}
            borderColor={borderColor}
            noPadding={noPadding}
            disabled={disabled}>
            {buttonText}
        </StyledButtonWrapper>
    </StyledButtonHref>
}


export default ActionButtonHref