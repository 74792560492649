import { validationMethods } from 'application/common'
import ActionButton from 'application/components/controls/button/action_button/component'
import { Form, TextInputWithLabel } from 'application/components/controls/form_elements'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { nanoid } from 'nanoid'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ForgotCredentialsLayout } from '../_common'
import styled, { useTheme } from 'styled-components'
import { LoginLoadEffect } from '../../login/effects'
import { PluginAlternateView } from 'plugins'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'


const ImageWrapper = styled.div`
    text-align: center;
`

const RequestUsernameComponent = (props) => {
    const { onReqUsername, usernameRequest, usernameSucceed, accountPublicInfo } = props
    const {t} = useTranslation()
    const { getValues, handleSubmit, register, formState: { errors } } = useForm()
    const theme = useTheme()
    const { getAsset } = usePluginsContext()



    const validation = {
        notEmptyEmail: validationMethods.notEmptyEmail,
        mustBeEmail: validationMethods.mustBeEmail
    }
    
    const headline = usernameSucceed ? t('CheckReceivedEmail') : t('ForgotUsername')
    const errorMessage =  t('UsernameResetLinkError')
    const successMessage =  t('UsernameResetLinkSuccess')

    const onSubmitSuccess = () => {
        onReqUsername(getValues().email, errorMessage, successMessage)
    }

    return <React.Fragment>
        <LoginLoadEffect {...props}>
            <ForgotCredentialsLayout
                needDesc={!usernameSucceed}
                headline= {headline}
                subHeadline= {t('ForgotUsernameSubHeadline')}
                accountPublicInfo={accountPublicInfo}
            >
                <React.Fragment>
                    {
                        usernameRequest ? <PreloaderSpin
                            primaryColor={theme.color.primary}
                        /> : usernameSucceed ? <PluginAlternateView id='request.credentials.mail_sent_image'>
                                <ImageWrapper>
                                    <ImagePlaceHolder toCenter={true} withBorder={false} source={getAsset('mail_sent_1.png')} large='40%' height='40%' />
                                </ImageWrapper>
                            </PluginAlternateView> : <React.Fragment>
                            <Form onSubmit={handleSubmit(onSubmitSuccess)}>
                                <TextInputWithLabel
                                    placeholder={t('email')}
                                    name='email'
                                    register={register}
                                    validate={validation}
                                    error={errors.email}
                                    showMarkLabel={false}
                                    pushBottom={true}
                                    htmlId={nanoid()}
                                />
                                <input
                                    style={{ display: 'none' }}
                                    type='submit'
                                    value='email'
                                />
                            </Form>
                            <PluginAlternateView id="login.button.submit" handleSubmit={handleSubmit} onSubmitSuccess={onSubmitSuccess}>
                                <ActionButton
                                        secondary={true}
                                        buttonText= {t('SubmitButtonText')}
                                        onButtonClick={handleSubmit(onSubmitSuccess)}
                                />
                            </PluginAlternateView>
                        </React.Fragment>
                    }
                </React.Fragment>
            </ForgotCredentialsLayout>
        </LoginLoadEffect>
    </React.Fragment>
}

export default RequestUsernameComponent