import React, { Fragment } from 'react'
import * as Fragments from './fragments'
import PropTypes from 'prop-types'
import ChatBubble from 'application/components/building_blocks/chat_bubble'
import { withTheme } from 'styled-components'
import { FlexGrid } from 'application/components/fragments/grid'
import FavoriteMessagesLoader from 'application/components/building_blocks/sidebar/akten/favorite/favorite_loader'
import { LoadEffect } from 'application/common'
import { useTranslation } from 'react-i18next'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { PluginAlternateView } from 'plugins'

const FavoritesContent = ({
    parentId,
    akteIdHash,
    metaInfo,
    theme,
    messageKey,
    getMessageFavorite,
    allAktenLoaded,
    accountId,
    favoriteLoaded,
    folderId,
    favorites,
    favoriteLoading,
    favoriteFailed,
    setDisplayContentMobile,
}) => {
	const {t} = useTranslation()
    const {activePlugin} = usePluginsContext()
    if (favoriteFailed) return <Fragment />
    return (
        <Fragments.LayoutContentAktenMobile>
            <FavoriteMessagesLoader
                messageKey={messageKey}
                getMessageFavorite={getMessageFavorite}
                allAktenLoaded={allAktenLoaded}
                accountId={accountId}
                favoriteLoaded={favoriteLoaded}
                folderId={folderId}
                akteIdHash={akteIdHash}
            >
                <LoadEffect loading={favoriteLoading}>
                    {favoriteLoaded && favorites.length > 0 ? (
                        <FlexGrid directionColumn>
                            {favorites
                                .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
                                .map((m, i) => {
                                    return (
                                        <ChatBubble
                                            key={i}
                                            messageDetail={m}
                                            messageId={m.id}
                                            mainColor={metaInfo === null ? theme.color.primary : metaInfo.mainColor}
                                            accountId={metaInfo === null ? 0 : metaInfo.accountId}
                                            akteIdHash={akteIdHash}
                                            parentId={parentId}
                                            messageKey={messageKey}
                                            setView={setDisplayContentMobile}
                                            showInFavoriteSidebar
                                        />
                                    )
                                })}
                        </FlexGrid>
                    ) : (
                        <PluginAlternateView id='mobile_view.akten.favorites'>
                            <Fragments.CardNoContentWrapper>
                                <Fragments.ImageNoContent src='/assets/images/no_favorites.png' />                       
                                <Fragments.ParNoContent>{t('No favorites have been saved yet.')}</Fragments.ParNoContent>
                            </Fragments.CardNoContentWrapper>
                        </PluginAlternateView>
                    )}
                </LoadEffect>
            </FavoriteMessagesLoader>
        </Fragments.LayoutContentAktenMobile>
    )
}

FavoritesContent.propTypes = {
    parentId: PropTypes.string,
    akteIdHash: PropTypes.string,
    metaInfo: PropTypes.object,
    theme: PropTypes.object,
    messageKey: PropTypes.string,
    getMessageFavorite: PropTypes.func,
    allAktenLoaded: PropTypes.bool,
    accountId: PropTypes.string || PropTypes.number,
    favoriteLoaded: PropTypes.bool,
    folderId: PropTypes.string,
    favorites: PropTypes.array,
    favoriteLoading: PropTypes.bool,
    favoriteFailed: PropTypes.bool,
    setDisplayContentMobile: PropTypes.func,
}

export default withTheme(FavoritesContent)
