import { ACTIONS } from 'application/constants'

export const showWaitingScreen = (message = '', inDialog = true, subMessages = []) => ({
    type: ACTIONS.WAITING_SCREEN_SHOW,
    message,
    inDialog,
    subMessages
})

export const hideWaitingScreen = () => ({
    type: ACTIONS.WAITING_SCREEN_HIDE
})

export const addNewSubMessage = (message = '') => ({
    type: ACTIONS.WAITING_SCREEN_ADD_NEW_SUB_MESSAGE,
    message
})

export const addToLastSubMessage = (message = '') => ({
    type: ACTIONS.WAITING_SCREEN_ADD_TO_LAST_SUB_MESSAGE,
    message
})