import RedirectDevice from 'application/common/redirect_device'
import { FlexGrid } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import { MobileAssistantMobileVersion } from '../../mobile'
import { TabletLandscape, TabletPortrait } from '../../tablet'
import * as Fragments from '../fragments'
import LoginPinFormContentAesthetic from './sections/content_aesthetic'
import LoginPinFormFooterSection from './sections/footer'
import LoginPinFormSection from './sections/login_form'

const LoginPinMobileAssistantComponent = (props) => {
    return <Fragment>
        <Fragments.BackgroundLoginFormAssistant isMobile={isMobile}>
            <RedirectDevice
                TabletCompoentLandscape={TabletLandscape.MobileAssitant.LoginPinFormTabletLandscape}
                TabletCompoentPortrait={TabletPortrait.MobileAssitant.LoginPinFormTabletPortrait}
                MobileComponent={MobileAssistantMobileVersion.LoginPinMobileComponent}
                {...props}>
                <FlexGrid>
                    <LoginPinFormSection {...props} />
                    <LoginPinFormContentAesthetic />
                </FlexGrid>
                <LoginPinFormFooterSection />
            </RedirectDevice>
        </Fragments.BackgroundLoginFormAssistant>
    </Fragment>
}

export default LoginPinMobileAssistantComponent
