import * as Fragment from '../../../../../../application/components/pages/external/auth/fragments'
import { useTranslation } from 'react-i18next'

export function LoginFooterLogo() {
  const { t } = useTranslation()

  return <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }}>
    <Fragment.StyledImageBigLogo style={{ width: 60 }} src='/assets/images/myk-law.png' />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'space-evenly',
      height: '100%',
      fontSize: '12px',
      margin: '0 42px 0 12px',
      gap: '10px'
    }}>
      <a href='https://www.kanzlei-myk.de/datenschutz' target='_blank' style={{ color: '#000', textDecoration: 'none' }} rel="noreferrer">{t('privacy')}</a>
      <a href='https://www.kanzlei-myk.de/mandatsbedingungen' target='_blank' style={{ color: '#000', textDecoration: 'none' }} rel="noreferrer">{t('Mandate Conditions')}</a>
    </div>
  </div>
}