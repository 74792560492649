import { API_VERSION_ESYMANDANT, fetchHandler, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { fetchRequestBuilder } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const getUsername = (email) => {

    const request = fetchRequestBuilder(API_VERSION_ESYMANDANT + '/user/username', HTTP_METHOD.POST, { email })

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}