import { connect } from 'react-redux'
import SettingsSidebarEffects from './effects'

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsSidebarEffects)