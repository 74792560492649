import { RefreshForm } from 'application/common'
import { buildQueryString } from 'application/common/route_helpers'
import SliderInquiry from 'application/components/building_blocks/slider_inquiry'
import { SliderInquiryHeadline } from 'application/components/building_blocks/slider_inquiry/fragments'
import TagCategory from 'application/components/controls/tag_category'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import { ROUTE_PARAMETERS } from 'application/constants'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useTheme } from 'styled-components'

const ExternalFormServiceProvider = (props) => {
    const {t} = useTranslation()
    const { selectedAccount, firstName, lastName, email, setFormReturnUrl } = props
    const { pathname, search } = useLocation()
    const url = pathname + search
    const assistants = selectedAccount?.assistants !== null || selectedAccount?.assistants !== undefined || selectedAccount?.assistants?.length > 0 ? selectedAccount?.assistants : []
    const assistantsVisible = assistants?.length > 0
    const theme = useTheme()

    const firstNameUpdated = firstName === undefined || firstName === null || firstName === '' ? '' : firstName
    const params = buildQueryString([
        {
            key: ROUTE_PARAMETERS.ASSISTANTS.EMAIL,
            value: email
        },
        {
            key: ROUTE_PARAMETERS.ASSISTANTS.LASTNAME,
            value: lastName
        },
        {
            key: ROUTE_PARAMETERS.ASSISTANTS.FIRSTNAME,
            value: firstNameUpdated
        },
    ])
    return <RefreshForm {...props}>
        <Fragment>
            <PushBottom24 />
            {
                assistantsVisible && assistants?.length > 5 ?
                    <SliderInquiry
                        inquiry={assistants}
                        sliderInquiryHeadline={t('Request')}
                        accountId={selectedAccount.id}
                        backgroundcolor={theme.color.anfrageTag ||selectedAccount.mainColor}
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                        params={params}
                        onInquiryClick={() => setFormReturnUrl(url)}
                    /> : assistants?.length > 0 && <FlexGrid directionColumn justifyCenter itemsCenter>
                        <SliderInquiryHeadline>
                            {t('Request')}
                        </SliderInquiryHeadline>
                        <FlexGridItem>
                            <FlexGrid justifyCenter>
                                {assistants.map((q) => {
                                    return <TagCategory
                                        key={q.id}
                                        text={q.name}
                                        href={q.url + params}
                                        onTagClick={() => setFormReturnUrl(url)}
                                        backgroundcolor={selectedAccount.mainColor}
                                        target=''
                                    />
                                })}
                            </FlexGrid>
                        </FlexGridItem>
                    </FlexGrid>
            }
        </Fragment>
    </RefreshForm>
}

export default ExternalFormServiceProvider