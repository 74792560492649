import { AuthLayout } from 'application/components/pages/_layout'
import React from 'react'
import * as Fragment from '../../fragments'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import { ROUTES } from 'application/constants'
import styled, { useTheme } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { PluginAlternateView } from 'plugins'


const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`

const ForgotCredentialsLayout = ({ headline, subHeadline, children, needDesc, accountPublicInfo }) => {
    const theme = useTheme()

    return <React.Fragment>
        <AuthLayout accountUniqueKey={accountPublicInfo?.accountUniqueKey}>
            <Fragment.LayoutInput>
                <LanguageSwitchHoc />
                <Fragment.FormLoginWrapper stretched>
                    <Fragment.TitleLoginWrapper>
                        <Fragment.TitleLogin
                            style={
                                theme.classes.loginTitle
                            }
                        >
                            {headline}
                        </Fragment.TitleLogin>
                        {
                            needDesc &&
                            <Fragment.SubTitleLogin
                                style={
                                    theme.classes.loginSubtitle
                                }
                            >
                                {subHeadline}
                            </Fragment.SubTitleLogin>
                        }
                    </Fragment.TitleLoginWrapper>

                    <PushBottom24 />
                    <PushBottom24 />
                    {children}
                    <PushBottom24 />
                    <PushBottom24 />
                </Fragment.FormLoginWrapper>
                <PluginAlternateView id="login.footer.logo">
                    <Fragment.LogoWrapper>
                            <Fragment.StyledBigLogo>
                                <StyledNavLink to={ROUTES.BASE}>
                                    <Fragment.StyledImageBigLogo src='/assets/images/icon_esy_one.png'/>
                                </StyledNavLink>
                            </Fragment.StyledBigLogo>
                    </Fragment.LogoWrapper>
                </PluginAlternateView>

            </Fragment.LayoutInput>
        </AuthLayout>
    </React.Fragment>
}

export default ForgotCredentialsLayout