
import React from 'react'
import styled from 'styled-components'
import WithSeparator from './with_separator'
import WithCollapse from './with_collapse'

const BreadcrumContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    cursor: pointer;

    span:last-child{
        color: ${props => props.theme.color.salmonDark};
        font-weight: ${props => props.theme.fontWeight.bold};
        font-size: ${props => `calc(${props.theme.fontSize.standard} + 1px)`}
    }
`

const BreadcrumbComponent = (props) => {
    const {
        payload,
        onBreadClick,
        children
    } = props

    let childrenProps = React.Children.toArray(children)

    const maxItemToShow = 5
    const totalItems = payload.length
    const isPopOverNeeded = totalItems > maxItemToShow
    const lastIndex = totalItems - 1

    childrenProps = childrenProps
        .reduce(WithSeparator(lastIndex, []), [])

    if (isPopOverNeeded) {
        childrenProps = WithCollapse(2, 2, childrenProps, totalItems, onBreadClick)
    }
    return <React.Fragment>
        <BreadcrumContainer>
            {childrenProps}
        </BreadcrumContainer>
    </React.Fragment>
}

export default BreadcrumbComponent