import { closeSelectedAccountDetails } from 'application/redux/actions/common/account_details'
import { closeAktenPopupSidebar, closeContactInfoPopupSidebar, closeNavigationLinkMobilePopupSidebar, closeProviderPopupSidebar } from 'application/redux/actions/sidebars'
import { connect } from 'react-redux'
import PopupSidebars from './component'

const mapStateToProps = (state) => ({
    aktenSidebar: state.sidebars.akten,
    providerSidebar: state.sidebars.provider,
    contactInfoSidebar: state.sidebars.contactInfo,
    navigationLinkMobile: state.sidebars.navigationLinkMobile,
    accountDetailsVisible: state.common.accountDetails.accountDetailsVisible,
})

const mapDispatchToProps = dispatch => ({
    closeAktenSidebar: () => dispatch(closeAktenPopupSidebar()),
    closeNavigationLinkMobileSidebar: () => dispatch(closeNavigationLinkMobilePopupSidebar()),
    closeContactInfoSidebar: () => dispatch(closeContactInfoPopupSidebar()),
    closeProviderSidebar: () => {
        dispatch(closeProviderPopupSidebar())
        dispatch(closeSelectedAccountDetails())
    },
})

export default connect(
    mapStateToProps, mapDispatchToProps
)(PopupSidebars)
