import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SelectedBreadcrumbEffect from './selected_breadcrumb_effect'

const SelectedFolderEffect = (props) => {
    const { setSelectedFolder, allAccountsLoaded, accountId, akteIdHash, folderId, messageFullLoaded, selectedFolder, children, foundedFolder, parent, loggedIn } = props
    let params = useParams()
    useEffect(() => {
        allAccountsLoaded &&
            loggedIn &&
            messageFullLoaded &&
            accountId !== undefined &&
            akteIdHash !== undefined &&
            folderId !== '' &&
            parent !== null &&
            selectedFolder === null &&
            setSelectedFolder(foundedFolder, params, akteIdHash, accountId)
    }, [accountId, params, akteIdHash, allAccountsLoaded, folderId, foundedFolder, loggedIn, messageFullLoaded, parent, selectedFolder, setSelectedFolder])

    return <SelectedBreadcrumbEffect {...props}>{children}</SelectedBreadcrumbEffect>
}

export default SelectedFolderEffect
