import React from 'react'
import styled from 'styled-components'

const StyledDatenschutz = styled.p`
    font-size: ${p => p.theme.fontSize.standard};
    text-align: left;
    padding: 0px 30px 0px 10px;
    white-space: pre-line;
    line-height: 16px;
    word-break: break-word;
`

const DisplayDatenshutz = (props) => {
    const { dialog } = props
    const payload = dialog.payload
    const statement = payload.statement
    return (
        <StyledDatenschutz>
            {statement}
        </StyledDatenschutz>
    )
}

export default DisplayDatenshutz
