import Preloader3D from 'application/components/controls/preloader/3d'
import { FlexGrid } from 'application/components/fragments/grid'
import { WIZARD_STEP, WIZARD_TYPE } from 'application/constants'
import { accessTokenUnregistered } from 'application/storage'
import React, { useEffect } from 'react'

const WizardRegistrationDataLoader = (props) => {
    const {
        wizardLoaded,
        getWizardDataRegistration,
        akteMeta,
        wizardDone,
        userIdHash,
        akteMetaLoaded,
        accessTokenUnregisteredStore,
    } = props
    const userIdFound = userIdHash !== null && userIdHash !== undefined && userIdHash !== ''
    const userId = userIdFound ? userIdHash : akteMeta.userId
    const token =
    accessTokenUnregisteredStore !== ''
        ? accessTokenUnregisteredStore
        : accessTokenUnregistered.get()
    // useEffect load wizard
    useEffect(() => {
        !wizardLoaded &&
      akteMetaLoaded &&
      getWizardDataRegistration(WIZARD_TYPE.REGISTRATION, akteMeta.accountId, userId, token)
    }, [akteMeta.accountId, akteMetaLoaded, getWizardDataRegistration, token, userId, wizardLoaded])
    // useEffect load wizard
    useEffect(() => {
        akteMeta.userStatus !== 0 && wizardDone(WIZARD_STEP.REGISTRATION.DONE)
    }, [akteMeta.userStatus, wizardDone])

    if (wizardLoaded) {
        return <React.Fragment>{props.children}</React.Fragment>
    }

    return (
        <FlexGrid height="100vh" itemsCenter justifyCenter>
            <Preloader3D/>
        </FlexGrid>
    )
}

export default WizardRegistrationDataLoader
