import ACTIONS from 'application/constants'
import default_store from '../../../default_store'

const aktenCommonReducer = (state = default_store.pages.akten.common, action) => {
    switch (action.type) {
    case ACTIONS.PAGES_AKTEN_SET_SELECTED_AKTEN: return {
        ...state,
        selectedAkte: {
            ...state.selectedAkte,
            ...action.payload
        }
    }
    case ACTIONS.PAGES_AKTEN_SET_MESSAGE_RAW: return {
        ...state,
        messageRaw: action.payload
    }
    case ACTIONS.PAGES_AKTEN_POPULATE_SINGLE_AKTE: return {
        ...state,
        selectedAkte: action.payload
    }
    case ACTIONS.PAGES_AKTEN_POPULATE_FOLDERS: return {
        ...state,
        folders: action.payload,
    }
    case ACTIONS.PAGES_AKTEN_POPULATE_META_INFO: return {
        ...state,
        metaInfo: action.payload,
    }
    case ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS: return {
        ...state,
        metaInfo: null,
    }
    case ACTIONS.PAGES_AKTEN_SET_FOLDERS_VIEW: return {
        ...state,
        foldersView: action.condition
    }
    case ACTIONS.PAGES_AKTEN_SET_SELECTED_FOLDER: return {
        ...state,
        selectedFolder: action.payload
    }
    case ACTIONS.PAGES_AKTEN_POPULATE_BREADCRUMBS_DATA: return {
        ...state,
        breadcrumbsData: action.payload
    }
    case ACTIONS.PAGES_AKTEN_SEND_MESSAGE_REQUEST: return {
        ...state,
        messageOnQueue: state.messageOnQueue.filter(m => m.messageQueueId === action.idOnQueue).length > 0 ?
            state.messageOnQueue :
            [
                ...state.messageOnQueue,
                {
                    messageQueueId: action.idOnQueue,
                    messageKey: action.messageKey,
                    payload: action.payload,
                    metaData: action.metaData,
                    sent: false,
                }
            ]
    }
    case ACTIONS.PAGES_AKTEN_REGISTERED_MESSAGE_QUEUE_SENT: return {
        ...state,
        messageOnQueue: state.messageOnQueue.map(d => {
            return d.messageQueueId === action.messageQueueId  ? {
                ...d,
                sent: true,
            } : d
        })
    }
    case ACTIONS.PAGES_AKTEN_UNREGISTER_MESSAGE_QUEUE: return {
        ...state,
        messageOnQueue: state.messageOnQueue.filter(m => m.messageQueueId !== action.messageQueueId)
    }
    case ACTIONS.PAGES_AKTEN_SET_BREADCRUMBS_VIEW: return {
        ...state,
        breadcrumbsView: action.condition,
        breadcrumbsData: !action.condition ? [] : [
            ...state.breadcrumbsData,
        ]
    }
    default:
        return state
    }
}

export default aktenCommonReducer