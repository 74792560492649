import { isMacOs, isSafari, isTablet } from 'react-device-detect'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const LoginAccountContainer = styled.div`
    display: flex;
    width: 100%;
    flex-flow: ${p => p.useMobile || p.useTablet ? 'column' : 'row'} nowrap;
    height: ${p => (p.useMobile || isTablet || p.useTablet) ? 'auto' : '100vh'};

    @media (orientation: landscape) {
        height: 95vh;
    }
`

export const ExternalRequestsWrapper = styled.div`
    width: 100%;
    height: auto;
    background-color: ${p => p.theme.color.white};
    border: 3px solid black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin: ${p => p.mobile ? '24px 0px' : '0px'};
    flex: 0 0 auto;
    @media (min-width: 1024px) {
        @media (orientation: portrait) {
        height: auto;
    }
        height: ${p => p.assistantsAvailable ? '100%' : 'auto'}
    }
    @media (min-width: 1280px) {
        height: ${p => p.assistantsAvailable ? '100%' : 'auto'}
    }
`

export const ExternalLoginLogoBanner = styled.img`
    width: 20%;
    margin-bottom: 8px;
    border-radius: 20px;
    background-position: center;
    background-repeat: no-repeat;
`
export const ExternalLoginLogoBannerDiv = styled.div`
    display: flex;
    flex: 0 0 200px;
    width: 100%;
    border-radius: 20px;
    background-image: ${props => `url(${props.imageSrc})`};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: 768.1px) {
    flex: 0 0 300px;
  }
    @media (min-width: 1024px) {
    flex: 0 0 250px;
  }
    @media (min-width: 1280px) {
    flex: 0 0 250px;
  }
   @media (min-width: 1367px) {
    flex: 0 0 300px;
  }
   @media (min-width: 1440px) {
    flex: 0 0 300px;
  }
    @media (min-width: 1536px) {
    flex: 0 0 300px;
  }
`

export const RequestsCardWrapper = styled.div`
    padding: 0px 20px;
    display: ${p => p.assistantsAvailable ? 'flex' : 'none'};
    flex: 1 1 50%;
    flex-flow: column nowrap;
    align-items: center;
    overflow: auto;
    overflow-x: hidden;
    margin: 8px 0px 0px 0px;
`

export const LoginAccountInfoWrapper = styled.div`
    padding-right: 35px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
`

export const LoginAccountContentWrapper = styled.div`
    padding-right: ${p => p.useMobile ? '0px' : '70px'};
    padding-top: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex: ${p => p.useMobile || p.useTablet ? '0 0 auto' : '1 1 30%'};
    flex-direction: column;
`

export const LoginAccountWelcomeText = styled.p`
    padding: 10px 0px;
    font-size: ${p => p.theme.fontSize.standard};
    text-align: ${p => p.useMobile ? 'center' : 'left'};
    max-height:  ${p => p.useMobile || p.useTablet ? 'unset' : isMacOs && isSafari ? '15vh' : '25vh'};
    overflow:  auto;
    white-space: pre-line;
`

export const DividerLoginAccount = styled.div`
    width: 20%;
    border-top: 3px solid ${props => props.theme.color.color70};
`

export const StyledMoreAndLessText = styled.span`
    text-decoration: underline;
    cursor: pointer;
    font-size: ${p => p.theme.fontSize.standard};
    font-weight: ${p => p.theme.fontWeight.semiBold};
    color:${p => p.primaryColor};
    font-style: italic;
`

export const AsclarHeaderContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
`

export const AsclarText = styled.div`
    font-size: ${p => p.fontSize !== undefined ? p.fontSize : '1.8em' };
    font-weight: ${p => p.isBold !== undefined && p.isBold === true ? 'bold' : 'normal' };
    text-decoration: ${p => p.textDecoration !== undefined ? p.textDecoration : 'none'};
    text-align: ${p => p.textAlign !== undefined ? p.textAlign : ''};
    color: ${p => p.color !== undefined ? p.color : 'black'};
    padding-left: ${p => p.paddingLeft !== undefined ? p.paddingLeft : '0px' };
    text-transform: ${p => p.transform !== undefined ? p.transform : 'none' };
    margin: ${p => p.margin !== undefined ? p.margin : '0' };
    overflow: hidden;
    white-space: ${p => p.wrap !== undefined ? p.wrap : 'wrap'};
    line-height: 1.5;

    & a, & a:visited{
        color: ${p => p.color !== undefined ? p.color : 'black'};
    }
`

export const AsclarTextAssistantDescription = styled.div`
    color: ${p => p.color !== undefined ? p.color : 'black'};
    color: black;
    font-size: 12px;
    text-transform: none;
    margin: 0 0 auto 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: wrap;
    line-height: 1.5;
    -webkit-line-clamp: ${p => p.clamp !== undefined ? p.clamp : '2'};
    -webkit-box-orient: vertical;
`

export const AsclarBannerContainer = styled.div`
    width: 100%;
    height: auto;
    border: ${p => p.border !== undefined ? p.border : '3px' } solid black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin: ${p => p.mobile ? '24px 0px' : '0px'};
    flex: 0 0 auto;
    @media (min-width: 1024px) {
        @media (orientation: portrait) {
        height: auto;
    }
        height: ${p => p.assistantsAvailable ? '100%' : 'auto'}
    }
    @media (min-width: 1280px) {
        height: ${p => p.assistantsAvailable ? '100%' : 'auto'}
    }
`

export const AsclarBannerContent = styled.div`
    display: flex;
    flex: 0 0 350px;
    width: 100%;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${props => `url(${props.imageSrc})`};

    @media (min-width: 768.1px) {
        flex: 0 0 450px;
    }
    @media (min-width: 1024px) {
        flex: 0 0 400px;
    }
    @media (min-width: 1280px) {
        flex: 0 0 400px;
    }
    @media (min-width: 1367px) {
        flex: 0 0 450px;
    }
    @media (min-width: 1440px) {
        flex: 0 0 450px;
    }
    @media (min-width: 1536px) {
        flex: 0 0 450px;
    }
`

export const AsclarBannerContentCustom = styled.div`
    display: flex;
    aspect-ratio: 3/1;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${props => `url(${props.imageSrc})`};

    @media (min-width: 768.1px) {
        flex: 0 0 500px;
    }
    @media (min-width: 1024px) {
        flex: 0 0 550px;
    }
    @media (min-width: 1280px) {
        flex: 0 0 450px;
    }
    @media (min-width: 1367px) {
        flex: 0 0 600px;
    }
    @media (min-width: 1440px) {
        flex: 0 0 620px;
    }
    @media (min-width: 1536px) {
        flex: 0 0 620px;
    }
`

export const AsclarMainContainer = styled.div`
    width: 85%;
    min-width: 280px;
    margin:0 auto;
    padding: ${props => props.padding === 'header' ? '40px 0' : '10px 0' };
    @media (min-width: 768.1px) {
        padding: ${props => props.padding === 'header' ? '60px 0' : '10px 0' };
    }
`

export const AsclarMainContainerCustom = styled.div`
    width: 100%;
    min-width: 280px;
    margin:0 auto;
    padding: ${props => props.padding === 'header' ? '40px 0' : '10px 0' };
    @media (min-width: 768.1px) {
        padding: ${props => props.padding === 'header' ? '60px 0' : '10px 0' };
        }
`

export const AsclarFooterContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    padding-top: 25px;
    background: ${p => p.backgroundColor !== undefined ? p.backgroundColor : p.theme.color.color10 };
`

export const AsclarSpacer = styled.div`
    padding: ${p => p.padding !== undefined ? p.padding : '20px' };
`

export const AsclarServicesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 0;
`

export const AsclarServicesCard = styled.div`
    border-radius: 20px;
    border: solid #efefef;
    padding: ${p => p.isMobile ? '15px 10px' : '20px'};
    margin: ${p => p.isMobile ? '10px 0' : '20px'};
    overflow: hidden;
    width: ${p => p.isMobile ? '100%' : '25%'};
    height: 160px;
    min-width: 400px;
    @media (max-width: 1080px){
        min-width: 380px;
    }
    @media (max-width: 826px){
        min-width: 290px;
    }
    @media (max-width: 768px){
        min-width: 460px;
    }
    @media (max-width: 420px){
        min-width: 355px;
    }
    @media (max-width: 340px){
        min-width: 300px;
    }
`

export const AsclarServicesCardText = styled.div`
    overflow: hidden;
    margin-left: 18px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: left;
    height: 110px;
    padding: ${p => p.isMobile ? '0 5px 0 0' : '0 10px 0 0'};
`


export const AsclarServicesCardTextLink = styled.a`
    overflow: hidden;
    font-size: ${p => p.fontSize !== undefined ? p.fontSize : '12px' };
    font-weight: bold;
    text-decoration: underline;
    color: ${p => p.color !== undefined ? p.color : 'black'};
    padding-left: ${p => p.paddingLeft !== undefined ? p.paddingLeft : '0px' };
    text-transform: ${p => p.transform !== undefined ? p.transform : 'none' };
    margin: auto 0 0 0;
`


export const AsclarLawyerCard = styled.div`
    border: 1px solid ${p => p.theme.color.color20};
    border-radius: 15px;
    padding: 30px 20px;
    margin: 20px 0;
`

export const StyledExtReqCardLink = styled(NavLink)`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-decoration: none;
    color: ${p => p.theme.color.color70};
    padding: 20px;
    border-radius: 30px;
    width: 95%;
    margin: 8px 0px;
    flex: 0 0 auto;
`

export const StyledExtReqCard = styled.a`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-decoration: none;
    color: ${p => p.theme.color.color70};
    padding: 20px;
    background-color: ${props => props.theme.color.color10};
    border-radius: 30px;
    width: 95%;
    margin: 8px 0px;
    flex: 0 0 auto;
`

export const StyledCategoryImage = styled.img`
    width: ${props => props.width !== undefined ? props.width : '108px'};
    height: ${props => props.height !== undefined ? props.height : '108px'};
    border-radius: 20px;
    border: 2px solid ${props => props.color !== undefined ? props.color : props.theme.color.white};
    background-color: ${props => props.theme.color.white};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
`

export const HeadlineRequest = styled.h3`
    font-size: ${p => p.theme.fontSize.medium};
    font-weight: ${p => p.theme.fontWeight.medium};
    color: ${p => p.theme.color.color90};
    margin: 0px;
`

export const DescriptionRequest = styled.h5`
    font-size: ${p => p.theme.fontSize.small};
    font-weight: ${p => p.theme.fontWeight.light};
    color: ${p => p.theme.color.color70};
    margin: 0px;
    margin-top: 8px;
    display: none;

    @media (min-width: ${p => p.theme.breakpoint.tablet}px){
        display: block;
    }
`

export const StyledLink = styled.span`
    cursor: pointer;
` 

export const MainAssistanceContainer = styled.div`
    display: flex;
    flex-direction: ${p => p.direction !== undefined ? p.direction : 'row'};
    justify-content: space-between;
`

export const MainAssistanceContainerCustom = styled.div`
    width: ${p => p.width !== undefined ? p.width : '40%'};
    height: 300px;
    display: flex;
    align-items: center;
`

export const ImageMainService = styled.img`
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
    object-position: bottom;
    aspect-ratio: 3/1;
`

export const MainContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${p => p.width !== undefined ? p.width : '60%'};
`

export const MainServiceTitle = styled.h3`
    color: #404040;
    font-size: 24px;
`

export const MainServiceText = styled.p`
    color: #666666;
    line-height: 24px;
`

export const MainServiceButton = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e7e7e7;
    color: #404040;
    border: none;
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 20px;
    width: ${p => p.width !== undefined ? p.width : 'fit-content'};
`

export const MainServiceButtonIcon = styled.img`
    margin-left: 7px;
    margin-bottom: -2px;
    width: 16px;
    height: 16px;
`

export const ServiceListContainer = styled.div`
    display: flex;
    flex-direction: ${p => p.direction !== undefined ? p.direction : 'row'};
    justify-content: space-between;
    flex-wrap: wrap;
`

export const ServiceListCard = styled.div`
    width: ${p => p.width !== undefined ? p.width : '48%'};
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 14px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
`

export const ServiceListLogoExist = styled.img`
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    aspect-ratio: 3/1;
`

export const ServiceListLogoNotExist = styled.div`
    width: 100%;
    padding: 16.665% 0;
    line-height: 0;
    text-align: center;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    font-size: 32px;
    color: #FFFFFF;
    font-weight: bold;
    background-color: ${p => p.backgroundColor}
`

export const ServiceMainLogoNotExist = styled.div`
    width: 100%;
    height: 300px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #FFFFFF;
    font-weight: bold;
    background-color: ${p => p.backgroundColor}
`

export const ServiceListContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px 24px 24px;
    flex: 1;
`

export const ServiceListTitle = styled.h3`
    color: #404040;
    font-size: 20px;
`

export const ServiceListText = styled.div`
    color: #666666;
    font-size: 16px;
    line-height: 24px;
`

export const ServiceListButtonContainer = styled.div`
    padding: 0 24px 24px 24px;
`

export const ServiceListButton = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e7e7e7;
    color: #404040;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
    width: ${p => p.width !== undefined ? p.width : '40%'};
`

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: #FFFFFF;
    padding: 8px 8%;
    position: fixed;
    top: 0;
    z-index: 1;
    box-shadow: 0px 15px 10px -15px;
`

export const ImageTextContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
`

export const LogoPlaceholder = styled.div`
    white-space: ${p => p.whiteSpace !== undefined ? p.whiteSpace : 'wrap'};
    max-width: 300px;
`

export const BurgerMenu = styled.div`
    display: flex;
    align-items: center;
`

export const BurgerMenuIcon = styled.img`
    width: 20px;
    height: 20px;
`

export const HeaderMenuItemContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

export const HeaderMenuItem = styled.button`
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    background-color: ${p => p.backgroundColor};
    color: ${p => p.color};
`

export const PrimaryButtonService = styled.a`
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    background-color: ${p => p.backgroundColor};
    text-decoration: none;
    color: #FFFFFF;
    text-align: center;
`

export const HeroContainer = styled.div`
    margin-top: 58px;
`

export const MenuMobileContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
    z-index: 2;
    position: absolute;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
`

export const MenuMobilePosition = styled.div`
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const MenuMobileItem = styled.button`
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    background-color: ${p => p.backgroundColor};
    color: ${p => p.color};
`

export const PrimaryButtonServiceMobile = styled.a`
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    background-color: ${p => p.backgroundColor};
    color: #FFFFFF;
    text-decoration: none;
    text-align: center;
`

export const MainBackground = styled.div`
    background-color: #FFFFFF;
`

export const AssistantAvailableBackground = styled.div`
    background-color: #f3f6f8;
`

export const DownloadText = styled.div`
    color: #404040;
    font-size: 32px;
    font-weight: bold;
`

export const DownloadStore = styled.div`
    display: flex;
    flex-direction: ${p => p.flexDirection !== undefined ? p.flexDirection : 'row'};
`
