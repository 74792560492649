//redux action constants
import { ACTIONS } from './actions'
export { ACTIONS }

export { FORMS } from './forms'

export { WIZARD_STEP, WIZARD_TYPE } from './wizard'

export { DIALOG } from './dialogs'

export { NOTIFICATION } from './notifications'
export { ERRORS } from './errors'

export { DASHBOARD } from './dashboard'
export { AKTEN } from './akten'
export { CONSENT } from './consent'

export const I18N = {
    LANGUAGES: ['en', 'de'],
    LANGUAGE_DETECTION_OPTIONS: {
        // order and from where user language should be detected
        order: ['localStorage', 'navigator'],

        // keys or params to lookup language from
        lookupLocalStorage: 'i18nextLng',

        // cache user language on
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

        // optional expire and domain for set cookie
        cookieMinutes: 10,
        cookieDomain: 'myDomain',

        // optional htmlTag with lang attribute, the default is:
        htmlTag: document.documentElement
    },
    LANGUAGES_TYPE: {
        DEUTSCH: 'de',
        ENGLISH: 'en',
    }
}

export { ROUTES, ROUTE_PARAMETERS } from './routes'

export { STORAGE } from './storages'

export { PERMISSIONS } from './permissions'

export default ACTIONS