import React, { useEffect } from 'react'
import * as Fragment from '../fragments'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { AuthLayout } from 'application/components/pages/_layout'
import { AkteMetaDataLoader, StartRedirect } from '../../_common'
import WizardRedirect from '../../_common/wizard_redirect'
import { TitlePin } from '../../_common'
import RequestPinAgainIfUserDidntGetThePin from './sections/request_pin'
import PolicyWrapper from './sections/policy_wrapper'
import { isMobileOnly } from 'react-device-detect'
import RedirectMobile from 'application/common/redirect_mobile'
import { AuthMobileVersion } from '../../mobile'
import EnterPinForm from './sections/enter_pin_form'
import WebHeaderLogoEffect from 'application/common/web_header_logo'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import LoginWithoutPin from '../../_common/login_without_pin'
import NotFoundPage from '../../not_found'

const EnterPin = (props) => {
    const { t } = useTranslation()
    const {
        akteMeta,
        onOpenDialogSendPinOption,
        reqPin,
        pinRequest,
        onOpenDialogSendPinOptionOnMobile,
        sendPin,
        loginRequest,
        loginFailed,
        auth,
        loggedIn,
        responseInfo,
    } = props
    const errorMessageSetPin = responseInfo
    const errorMessage = '🔥 ' + t('RequestPINError')
    const successMessage = akteMeta.isSmsAvailable
        ? '🔑 ' + t('RequestPINSuccess')
        : '🔑 ' + t('RequestPINSuccessEmail')

    const handleRequestPin = () => {
        if (akteMeta.isSmsAvailable) {
            isMobileOnly
                ? onOpenDialogSendPinOptionOnMobile(akteMeta.authentication)
                : onOpenDialogSendPinOption(akteMeta.authentication)
        } else {
            reqPin(akteMeta.authentication, 'Email', errorMessage, successMessage)
        }
    }

    const { setUserAccountId } = usePluginsContext()
    useEffect(() => {
        if(!akteMeta.accountId) return
        setUserAccountId(akteMeta.accountId)
    },[akteMeta])

    const mobileProps = {
        ...props,
        handleRequestPin,
        successMessage,
        errorMessageSetPin,
        errorMessage,
    }
    return (
        <WebHeaderLogoEffect accountPublicInfo={akteMeta}>
            <AkteMetaDataLoader {...props}>
                <StartRedirect {...props}>
                    <LoginWithoutPin {...props} akteMeta={akteMeta} errorMessage={errorMessageSetPin}>
                        <WizardRedirect {...props}/>
                    </LoginWithoutPin>
                </StartRedirect>
            </AkteMetaDataLoader>
        </WebHeaderLogoEffect>
    )
}

export default withTheme(EnterPin)
