import { FlexGridItem, PushBottom40 } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { HighlightMark } from 'application/components/fragments/typography'

const StyledNote = styled.small`
    font-size: ${p => p.theme.fontSize.small};
    font-weight: ${p => p.theme.fontWeight.semiBold};
    color: ${p => p.theme.color.color70};
`
const NotesPassword = () => {
    return <Fragment>
        <FlexGridItem width='70%'>
            <StyledNote>
                <Trans i18nKey='NotesPassword'>
                    Hinweis: Das Passwort darf kein triviales Passwort sein. Dazu gehören Passwörter, wie z.B. <HighlightMark>
                        <strong>
                            "12345678"
                        </strong>
                    </HighlightMark>, aber auch Passwörter, die Ihren <HighlightMark>
                        <strong>
                            Benutzernamen
                        </strong>
                    </HighlightMark> oder Ihre <HighlightMark>
                        <strong>
                            E-Mail-Adresse
                        </strong>
                    </HighlightMark> enthalten. Außerdem müssen Zeichenwiederholungen <HighlightMark>
                        <strong>
                            (z.B. "aaa" oder "555")
                        </strong>
                    </HighlightMark> vermieden werden.
                </Trans>
            </StyledNote>
        </FlexGridItem>
        <PushBottom40 />
    </Fragment>
}

export default NotesPassword
