import ACTIONS from 'application/constants'

export const getCommonAllAccountsRequest = () => ({
    type: ACTIONS.COMMON_ALL_ACCOUNTS_REQUEST_DATA,
})

export const populateCommonAllAccountsData = (payload) => ({
    type: ACTIONS.COMMON_ALL_ACCOUNTS_POPULATE_DATA,
    payload
})

export const getCommonAllAccountsFailed = () => ({
    type: ACTIONS.COMMON_GET_ALL_ACCOUNTS_FAILED,
})

export const updateDataAccountById = (accountId, payload) => ({
    type: ACTIONS.COMMON_ALL_ACCOUNTS_UPDATE_DATA_BY_ID,
    accountId,
    payload
})

export const refreshAllAccountsDAta = () => ({
    type: ACTIONS.COMMON_REFRESH_ALL_ACCOUNTS_DATA,
})

export const getMainLogo = (accountId, metaData) => ({
    type: ACTIONS.COMMON_GET_MAIN_LOGO_COMPANY_REQUEST,
    accountId,
    metaData,
})

export const populateMainLogo = (accountId, data) => ({
    type: ACTIONS.COMMON_POPULATE_MAIN_LOGO_COMPANY,
    accountId,
    data
})

export const companyHasNoMainLogo = (accountId) => ({
    type: ACTIONS.COMMON_COMPANY_HAS_NO_MAIN_LOGO,
    accountId
})

export const getSecondaryLogo = (accountId) => ({
    type: ACTIONS.COMMON_GET_SECONDARY_LOGO_COMPANY_REQUEST,
    accountId
})

export const populateSecondaryLogo = (accountId, data) => ({
    type: ACTIONS.COMMON_POPULATE_SECONDARY_LOGO_COMPANY,
    accountId,
    data
})

export const companyHasNoSecondaryLogo = (accountId) => ({
    type: ACTIONS.COMMON_COMPANY_HAS_NO_SECONDARY_LOGO,
    accountId
})

export const populateTotalUnredMessageEachAccount = (accountId, totalUnreadMessage) => ({
    type: ACTIONS.COMMON_POPULATE_TOTAL_UNREAD_MESSAGE_EACH_ACCOUNT,
    accountId,
    totalUnreadMessage
})