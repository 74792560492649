import { ROUTES } from 'application/constants'
import { urlRedirect } from 'application/storage'
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const HandlePush = (props) => {
    const { auth, children } = props
    let navigate = useNavigate()

    const url = auth.common.HandlePush.url
    const pagePush = auth.common.HandlePush.pagePush
    
    useEffect(() => {
        if (pagePush) {

            urlRedirect.store(window.location.href)
            
            navigate(url)
        }
    }, [navigate, pagePush])

    return <Fragment>
        {children}
    </Fragment>
}

export default HandlePush
