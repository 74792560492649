import FontIcon from 'application/components/controls/font_icon'
import React from 'react'
import { withTheme } from 'styled-components'

const SeparatorBreadcrumbComponent = (props) => {
    return <React.Fragment>
        <FontIcon icon='chevron_right' fontSize={props.theme.fontSize.medium18} />
    </React.Fragment>
}

const SeparatorBreadcrumb = withTheme(SeparatorBreadcrumbComponent)
export default SeparatorBreadcrumb