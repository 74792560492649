import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { Spacer } from 'application/components/pages/internal/dashboard/fragments'
import React from 'react'
import BannerStatementAccount from '../../wizard/registration/welcome/sections/banner_statement_account'
import ButtonUpdateWizard from '../../wizard/registration/welcome/sections/button_update_wizard'
import TitleWizard from '../../wizard/registration/_common/title_wizard'
import TopContentWizardReg from '../../wizard/registration/_common/top_content'
import * as WizardMobileStyled from './styles'
import { useTranslation } from 'react-i18next'

const WelcomeWizardMobile = props => {
    const {wizardData, accountId} = props
    const {t} = useTranslation()
    return <WizardMobileStyled.Layout id='WelcomeWizardMobile-ec'>
        <WizardMobileStyled.Container>
            <TopContentWizardReg useMobile wizardData={wizardData} />
            <WizardMobileStyled.Container>
                <TitleWizard headline={t('EsyMandantSalutation')} desc={t('EsyMandantSalutationText')} spacerHeadlineAndStep='30px' />
                <FlexDividerHorizontal />
            </WizardMobileStyled.Container>
        </WizardMobileStyled.Container>
        <WizardMobileStyled.Container>
            <BannerStatementAccount {...props} />
            <Spacer distance='24px' />
            <FlexDividerHorizontal />
            <ButtonUpdateWizard useMobile {...props} />
        </WizardMobileStyled.Container>
    </WizardMobileStyled.Layout>
}

export default WelcomeWizardMobile
