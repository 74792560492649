import { downloadFile, getDownloadUrl } from 'application/api/helpers'
import { API_VERSION_ESYMANDANT, fetchHandler, fetchRequestBuilder, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { handleApiFilestreamResponse } from 'application/api/response_handlers'
import { accessTokenRegistered } from 'application/storage'

export const downloadFilesById = (accountId, fileId, fileName, storage = accessTokenRegistered) => {

    const request = fetchRequestBuilder(API_VERSION_ESYMANDANT + '/e.consult.' + accountId + '/files/download/' + fileId + '?resultType=Stream', HTTP_METHOD.GET, {}, true, false, false, storage)

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiFilestreamResponse)
        .then(getDownloadUrl)
        .then(downloadUrl => {
            downloadFile(downloadUrl, fileName)
        })
}