export const accountsArrayMapper = (response) => {
    let arr = []
    response.forEach(r => {
        arr.push({
            ...r,
            totalUnreadMessage: 0
        })
    })

    return arr
}