import { refreshAllAktenDAta } from 'application/redux/actions/common/all_akten'
import { populateBreadscrumbsData, refreshMessageOnRoot, setBreadscurmbsView, setFoldersView, setSelectedAkte, setSelectedFolder } from 'application/redux/actions/pages/akten'
import { connect } from 'react-redux'
import AktenPopupSidebar from './component'

const mapStateToProps = (state) => ({
    selectedAccount: state.common.accountDetails.selectedAccount,
    allAktenLoaded: state.common.allAkten.isLoaded,
    allAccountsLoaded: state.common.allAccounts.isLoaded,
})

const mapDispatchToProps = dispatch => ({
    setSelectedAkte: payload => {
        dispatch(setSelectedAkte(payload))
        dispatch(refreshMessageOnRoot())
        dispatch(refreshAllAktenDAta())
        dispatch(setSelectedFolder(null))
        dispatch(setBreadscurmbsView(false))
        dispatch(setFoldersView(true))
        dispatch(populateBreadscrumbsData([]))
    }
})

export default connect(
    mapStateToProps, mapDispatchToProps
)(AktenPopupSidebar)
