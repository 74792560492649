import { combineReducers } from 'redux'
import akten from './akten_sidebar'
import provider from './provider_sidebar'
import contactInfo from './contact_info_sidebar'
import navigationLinkMobile from './navigation_link_mobile'

export default combineReducers({
    akten,
    provider,
    contactInfo,
    navigationLinkMobile,
})