import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { withTheme } from 'styled-components'
import TagCategory from 'application/components/controls/tag_category'
import * as Fragment from './fragments'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'


const SliderInquiryComponent = ({ inquiry = [], onInquiryClick = () => { }, theme, sliderInquiryHeadline, accountId, backgroundcolor, params = '' }) => {
    const inquiryLength = inquiry.length
    const slideToShow = inquiryLength > 2 ? 3 : 1
    const { activePlugin, config } = usePluginsContext() 

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: slideToShow,
        slidesToScroll: Math.floor(inquiryLength / 3),
        // centerMode: true, //bit buggy?
        variableWidth: true,
        // centerPadding: '0',
        nextArrow: <Fragment.NextArrowSliderInquiry />,
        prevArrow: <Fragment.PrevArrowSliderInquiry />
    }

    return <React.Fragment>
        <Fragment.SliderInquiryHeadline>
            {sliderInquiryHeadline}
        </Fragment.SliderInquiryHeadline>
        <Slider {...sliderSettings}>
            {
                inquiry.map((q, i) => {
                    const formattedUrl = activePlugin ? q.url.replace('app.esy.one', config.hostName) :  q.url
                    return <TagCategory
                        onTagClick={onInquiryClick}
                        key={i}
                        text={q.name}
                        href={formattedUrl + params}
                        backgroundcolor={backgroundcolor}
                        target=''
                    />
                })
            }
        </Slider>
    </React.Fragment>
}

export default withTheme(SliderInquiryComponent)