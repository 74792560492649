import { webViewerChecker } from 'application/common'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { FlexGrid, FlexGridItem, PushBottom20, PushBottom40 } from 'application/components/fragments/grid'
import React, { Fragment } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useParams, useLocation } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { ContentButton, contentChoices, returnUrlProps } from './helpers'

const StyledBox = styled.div`
    padding: 10px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    flex-grow: 1;
    @media (min-width: 640px) {
    padding: 10px 50px;
    justify-content: flex-start;
    align-items: flex-start;
}
`
const StyledDesc = styled.p`
    font-size: ${p => p.theme.fontSize.medium18};
    font-weight: ${p => p.theme.fontWeight.standard};
    text-align: center;
    @media (min-width: 640px) {
    text-align: left;
  }
`
const StyledTitle = styled.h3`
    font-size: ${p => p.theme.fontSize.xxxLarge};
    font-weight: ${p => p.theme.fontWeight.standard};
    margin: 5px 0px;
    text-align: center;
    @media (min-width: 640px) {
    text-align: left;
  }
`

const ReturnUrlSection = props => {
    const { isIosWebView, isAndroidWebView } = webViewerChecker()
    const { withText = true, withImage = true, formReturnUrl, refreshReturnUrlForm, auth, theme } = props
    let params = useParams()
    const location = useLocation()
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    const returnUrlIndex = formReturnUrl !== null && '/' + formReturnUrl.split('/')[1] + '/'
    const isItExplore = params.accountIdentity !== undefined
    const accountIdentity = isItExplore && params.accountIdentity
    const { buttonText, returnUrl, hideBackButton } = returnUrlProps(returnUrlIndex, loggedIn, formReturnUrl, params, location)
    const random = Math.floor(Math.random() * contentChoices.length)
    return <StyledBox>
        <FlexDividerHorizontal />
        <PushBottom20 />
        <FlexGridItem>
            <FlexGrid justifyCenter itemsCenter>
                <FlexGridItem grow={0} basis={isMobile ? '90%' : '30%'} itemsCenter >
                    <FlexGrid itemsCenter directionColumn>
                        {
                            withText && <Fragment>
                                <StyledTitle>
                                    {contentChoices[random].title}
                                </StyledTitle>
                                <StyledDesc>
                                    {contentChoices[random].desc}
                                </StyledDesc>
                            </Fragment>
                        }
                        <ContentButton hideBackButton={hideBackButton} accountIdentity={accountIdentity} buttonText={buttonText} isAndroidWebView={isAndroidWebView} isIosWebView={isIosWebView} isItExplore={isItExplore} refreshReturnUrlForm={refreshReturnUrlForm} returnUrl={returnUrl} theme={theme} />
                    </FlexGrid>
                </FlexGridItem>
                {
                    withImage &&
                    <FlexGridItem maxWidth={isMobile ? '80%' : '25%'}>
                        <ImagePlaceHolder toCenter large={isTablet ? '40%' : '90%'} height='100%' withBorder={false} source={contentChoices[random].imageSrc} />
                    </FlexGridItem>
                }
            </FlexGrid>
        </FlexGridItem>
        <PushBottom40 />
    </StyledBox>
}

export default withTheme(ReturnUrlSection)
