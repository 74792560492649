import { STORAGE } from 'application/constants'

export const accessTokenRegistered = {
    store: (token) => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN_REGISTERED)
        localStorage.setItem(STORAGE.ACCESS_TOKEN_REGISTERED, token)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.ACCESS_TOKEN_REGISTERED)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN_REGISTERED)
    }
}

export const accessTokenUnregistered = {
    store: (token) => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN_UNREGISTERED)
        localStorage.setItem(STORAGE.ACCESS_TOKEN_UNREGISTERED, token)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.ACCESS_TOKEN_UNREGISTERED)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN_UNREGISTERED)
    }
}

export const accessTokenForm = {
    store: (token) => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN_FORM)
        localStorage.setItem(STORAGE.ACCESS_TOKEN_FORM, token)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.ACCESS_TOKEN_FORM)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN_FORM)
    }
}
export const accessTokenAdhoc = {
    store: (token) => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN_ADHOC)
        localStorage.setItem(STORAGE.ACCESS_TOKEN_ADHOC, token)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.ACCESS_TOKEN_ADHOC)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN_ADHOC)
    }
}

export const pinForm = {
    store: (pin) => {
        localStorage.removeItem(STORAGE.PIN_TOKEN_FORM)
        localStorage.setItem(STORAGE.PIN_TOKEN_FORM, pin)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.PIN_TOKEN_FORM)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.PIN_TOKEN_FORM)
    }
}

export const accessToken = {
    store: (token) => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN)
        localStorage.setItem(STORAGE.ACCESS_TOKEN, token)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.ACCESS_TOKEN)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.ACCESS_TOKEN)
    }
}