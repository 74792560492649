import { WIZARD_TYPE } from 'application/constants/wizard'
import { wizardDefaultSaga } from './wizard_default_saga'
import { updateWizardDataRegisterSaga } from './update_wizard_data_register_saga'
import { fetchWizardDataRegisterSaga } from './fetch_wizard_data_register_saga'

export const updateWizardSagas = type => {
    switch (type) {
    case WIZARD_TYPE.REGISTRATION:
        return [fetchWizardDataRegisterSaga, updateWizardDataRegisterSaga]
    default:
        return [wizardDefaultSaga]
    }
}