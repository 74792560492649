import { AKTEN } from 'application/constants'
import React, { useEffect } from 'react'

const NoFilesRedirect = (props) => {
    const { thumbnailsToShow, openAkteSidebar, children } = props

    useEffect(() => {
        thumbnailsToShow.length === 0 && openAkteSidebar(AKTEN.SIDEBAR.KEY.DETAILS)
    }, [openAkteSidebar, thumbnailsToShow.length])

    return <React.Fragment>
        {children}
    </React.Fragment>
}

export default NoFilesRedirect