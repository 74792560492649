import { logoutRequest } from 'application/redux/actions/auth'
import { refreshAllAccountsDAta } from 'application/redux/actions/common/all_accounts'
import { refreshAllAktenDAta } from 'application/redux/actions/common/all_akten'
import { closeNavigationLinkMobilePopupSidebar } from 'application/redux/actions/sidebars'
import { connect } from 'react-redux'
import NavInternalMobile from './component'

const mapStateToProps = (state) => ({
    accountId: state.auth.common.user.accountId,
})

const mapDispatchToProps = dispatch => ({
    refreshClickNavMobile: () => {
        dispatch(refreshAllAccountsDAta())
        dispatch(refreshAllAktenDAta())
        dispatch(closeNavigationLinkMobilePopupSidebar())
    },
    logout: () => dispatch(logoutRequest())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavInternalMobile)