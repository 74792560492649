import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { fileHelpers } from 'application/common'
import { shouldLoadAttachment } from './helpers'
import AttachmentViewer from './component'
import { accessTokenRegistered } from 'application/storage'

const AttachmentViewerHoc = ({ 
    files,
    fileId,
    fileName,
    onLoadAttachment, 
    metaData,
    isSizeFixed = false,
    onFullFileClick,
    onAttachmentDownload,
    withDownload,
    withHeadline,
    fontSizeHeadline,
    widthFixed,
    heightFixed,
    storage = accessTokenRegistered,
    withCaption = false,
    initiallyLoad = false,
}) => {
    //check if file is registered in redux store
    const attachmentRegistered = files.fullFiles.filter(e => e.id === fileId).length > 0

    //check if file should be loaded depending on the file extension
    const shouldLoad = shouldLoadAttachment(fileHelpers.getFileExtension(fileName))

    //file data url
    const attachment = attachmentRegistered ? files.fullFiles.filter(t => t.id === fileId)[0] : ''

    //preloader display depends on this value
    const attachmentIsLoading = shouldLoad && (!attachmentRegistered || (attachmentRegistered && !files.fullFiles.filter(e => e.id === fileId)[0].isLoaded))

    //fallback file display depends on this value
    const attachmentFailed = attachmentRegistered ? files.fullFiles.find(e => e.id === fileId).failed : false

    //request file if not registered
    useEffect(() => { 
        !attachmentRegistered && shouldLoad && initiallyLoad && onLoadAttachment(fileId, fileName, {...metaData, storage: storage}) 
    }, [attachmentRegistered, fileId, fileName, metaData, onLoadAttachment, shouldLoad, storage])

    return <AttachmentViewer
        withDownload={withDownload}
        withHeadline={withHeadline}
        isSizeFixed={isSizeFixed}
        fileId={fileId}
        fileName={fileName}
        attachment={attachment}
        onAttachmentDownload={onAttachmentDownload}
        extension={fileHelpers.getFileExtension(fileName)}
        isLoading={attachmentIsLoading}
        accountId={metaData.accountId}
        fontSizeHeadline={fontSizeHeadline}
        onFullFileClick={onFullFileClick}
        widthFixed={widthFixed}
        heightFixed={heightFixed}
        attachmentFailed={attachmentFailed}
        withCaption={withCaption}
    /> 
}

AttachmentViewerHoc.propTypes = {
    attachmentId: PropTypes.number,
    attachments: PropTypes.array,
    attachmentName: PropTypes.string,
    onLoadAttachment: PropTypes.func,
    onAttachmentDownload: PropTypes.func
}

export default AttachmentViewerHoc