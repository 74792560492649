import React, { Fragment, useEffect, useState } from 'react'
import DividerTextLine from 'application/components/controls/divider/divider_text_line/component'
import AkteCardEasyMode from 'application/components/building_blocks/akte_card/easy_mode'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ROUTE_PARAMETERS } from 'application/constants'
import { useQuery } from 'application/components/hooks'
import { Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SectionAktenMobile = styled.section``

const SectionAktenMobileComponent = ({ allAktenLoaded, allAkten, setSelectedAkte }) => {
    const [filteredAkte, setFilteredAkte] = useState(allAkten)
    const query = useQuery()
    const searchValueAkte = query.getStringParam(ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER) !== '' ? query.getStringParam(ROUTE_PARAMETERS.AKTEN.SEARCH_BY_HEADER) : ''

    useEffect(() => {
        const results = allAkten.filter(
            (a) =>
                a.header.toLowerCase().includes(searchValueAkte.toLowerCase()) ||
                a.accountName.toLowerCase().includes(searchValueAkte.toLowerCase()) ||
                a.creatorFirstName.toLowerCase().includes(searchValueAkte.toLowerCase()) ||
                a.creatorLastName.toLowerCase().includes(searchValueAkte.toLowerCase())
        )
        setFilteredAkte(results)
    }, [allAkten, searchValueAkte])
	let params = useParams()
	const {t} = useTranslation()
    return (
        <SectionAktenMobile>
            <DividerTextLine text={t('cases')} />
            {allAktenLoaded ? (
                <Fragment>
                    {filteredAkte.map((a, i) => {
                        return (
                            <AkteCardEasyMode
                                setSelectedAkte={() => setSelectedAkte(a, params, a.idHash, a.accountId)}
                                categories={a.categories}
                                key={i}
                                akteIdHash={a.idHash}
                                accountId={a.accountId}
                                accountName={a.accountName}
                                creatorFirstName={a.creatorFirstName}
                                creatorLastName={a.creatorLastName}
                                headline={a.header}
                                totalUnreadMessage={a.totalUnreadMessage}
                                lastMessageDate={a.lastMessageDate}
                                lastMessage={a.lastMessage}
                                mainColor={a.mainColor}
                                secondaryColor={a.secondaryColor}
                                totalUnreadMessageInFolder={a.totalUnreadMessageInFolder}
								creatorIdHash={a.creatorIdHash}
                            />
                        )
                    })}
                </Fragment>
            ) : (
                <Fragment>
                    <Skeleton animation='wave' variant='rectangular' width={'100%'} height={200} style={{ marginBottom: 6, borderRadius: 20 }} />
                    <Skeleton animation='wave' variant='rectangular' width={'100%'} height={200} style={{ marginBottom: 6, borderRadius: 20 }} />
                    <Skeleton animation='wave' variant='rectangular' width={'100%'} height={200} style={{ marginBottom: 6, borderRadius: 20 }} />
                </Fragment>
            )}
        </SectionAktenMobile>
    )
}

SectionAktenMobileComponent.propTypes = {
    allAkten: PropTypes.array,
    allAktenLoaded: PropTypes.bool,
}

export default SectionAktenMobileComponent
