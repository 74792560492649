import React, { useEffect } from 'react'
import * as Fragment from '../fragments/dashboard'
import LayoutBase from '../base/base_container'
import NavInternal from 'application/components/building_blocks/nav_internal'
import { getSidebarComponent } from './internal_mapper'
import { useLocation } from 'react-router-dom'
import AllAccountsLoader from 'application/common/all_accounts_loader'
import { isTablet } from 'react-device-detect'
import AllAktenLoader from 'application/common/all_akten_loader'
import PopupSidebars from 'application/components/popup_sidebars'
import HeaderInternal from './header'
import SetToken from 'application/common/set_token'

const InternalLayout = ({
    inAkten = false,
    sidebarLoaded = true,
    paddingTop = true,
    paddingRight = true,
    paddingBottom = true,
    paddingLeft = true,
    newMessageTitle = '',
    registeredLoggedIn,
    unregisteredLoggedIn,
    pushToDashboard,
	handleSetPushToDashboard,
	withDiv100,
    allAccounts,
    ...props
}) => {
    const { children } = props
    let location = useLocation()
    const SidebarComponent = getSidebarComponent(location.pathname)
    const accountPublicInfo = allAccounts[0]

    useEffect(() => {
        pushToDashboard && handleSetPushToDashboard(false)
    }, [handleSetPushToDashboard, pushToDashboard])
    return (
        <React.Fragment>
            <LayoutBase withDiv100={withDiv100} internal newMessageTitle={newMessageTitle} {...props}>
                <SetToken {...props}>
                    <AllAccountsLoader {...props}>
                        <AllAktenLoader {...props}>
                            <Fragment.DashboardWrapper>
                                <HeaderInternal
                                    inAkten={inAkten}
                                    accountPublicInfo={accountPublicInfo}
                                />
                                <Fragment.MainWrapper>
                                    <Fragment.NavWrapper>
                                        <NavInternal />
                                    </Fragment.NavWrapper>
                                    <Fragment.ContentWrapper
                                        paddingTop={paddingTop}
                                        paddingRight={paddingRight}
                                        paddingBottom={paddingBottom}
                                        paddingLeft={paddingLeft}
                                        inAkten={inAkten}
                                    >
                                        {children}
                                    </Fragment.ContentWrapper>
                                    {!inAkten && !isTablet && (
                                        <Fragment.SidebarWrapper>
                                            <SidebarComponent />
                                        </Fragment.SidebarWrapper>
                                    )}
                                </Fragment.MainWrapper>
                            </Fragment.DashboardWrapper>
                        </AllAktenLoader>
                    </AllAccountsLoader>
                </SetToken>
            </LayoutBase>
            <PopupSidebars />
        </React.Fragment>
    )
}

export default InternalLayout
