import React, { Fragment } from 'react'
import { withTheme } from 'styled-components'
import { isMobileOnly } from 'react-device-detect'
import TopBarMobile from './mobile'
import DefaultTopBar from './default'

const TopBarComponent = (props) => {
    const { inAkten } = props
    return isMobileOnly ? inAkten ? <Fragment /> : <TopBarMobile {...props} /> : <DefaultTopBar {...props} />
}

export default withTheme(TopBarComponent)