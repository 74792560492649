import { useQuery } from 'application/components/hooks'
import { ROUTE_PARAMETERS } from 'application/constants'
import React from 'react'
import { useEffect } from 'react'
import { Fragment } from 'react'

const CloseSelectedAccountEffect = (props) => {
    const { children, closeSelectedAccountDetails, accountDetails, handleCloseAktenPopupSidebar, handleCloseProviderPopupSidebar } = props
    const query = useQuery()
    const isNotAccid = query.getStringParam(ROUTE_PARAMETERS.DASHBOARD.ACCOUNT_ID) === ''
    useEffect(() => {
        isNotAccid && accountDetails.accountDetailsVisible && closeSelectedAccountDetails() && handleCloseAktenPopupSidebar() && handleCloseProviderPopupSidebar()
    }, [accountDetails.accountDetailsVisible, closeSelectedAccountDetails, handleCloseAktenPopupSidebar, handleCloseProviderPopupSidebar, isNotAccid])
    return <Fragment>
        {children}
    </Fragment>
}

export default CloseSelectedAccountEffect
