import { connect } from 'react-redux'
import InternalComponent from './internal'
import { getCommonAllAccountsRequest } from 'application/redux/actions/common/all_accounts'
import { getCommonAllAktenRequest } from 'application/redux/actions/common/all_akten'
import { setPushToDashboard } from 'application/redux/actions/auth'

const mapStateToProps = state => ({
    allAktenLoaded: state.common.allAkten.isLoaded,
    allAkten: state.common.allAkten.payload,
    allAccountsLoaded: state.common.allAccounts.isLoaded,
    allAccounts: state.common.allAccounts.payload,
    selectedAccount: state.common.accountDetails.selectedAccount,
    profilePictureLoaded: state.auth.common.user.profilePicture.isLoaded,
    userId: state.auth.common.user.userId,
    accountId: state.auth.common.user.accountIds[0],
    auth: state.auth,
    pushToDashboard: state.auth.common.pushToDashboard,
})

const mapDispatchToProps = dispatch => ({
    getAllAkten: () => dispatch(getCommonAllAktenRequest()),
    getAllAccounts: () => dispatch(getCommonAllAccountsRequest()),
    handleSetPushToDashboard: (condition) => dispatch(setPushToDashboard(condition))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InternalComponent)