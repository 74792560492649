import React, { useContext, useRef } from 'react'
import styled, { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useEscapeKey, useClickOutside } from 'application/components/hooks'
import HeadlineBar from 'application/components/building_blocks/headline_bar'
import IconButtonWithLabel from 'application/components/controls/icon/icon_button_with_label/component'

const Overlay = styled.div`
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    background-color:rgba(0, 0, 0, 0.55);
    display: flex;
    height: 100vh;
`

const StyledSidebarWrapper = styled.div`
    height:100vh;
    width: ${p => p.sidebarWidth};
    position: fixed;
    top: 0;
	${p => p.showRight ? 'right: 0;' : 'left: 0;'}
    background: ${props => props.theme.color.white};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    z-index: 11;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    overflow: auto;
`

const StyledHeadlineBarWrapper = styled.div`
    padding: 0 0 0 16px;
`

const StyledSidebarBottom = styled.div`
    margin-top: auto;
`

const SidebarWrapper = ({ title, onAbort, dialogsVisible, children, SidebarFooter = null, showRight = false, showHeadline = false, sidebarWidth='40%' }) => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)
    const sidebarRef = useRef()
    useClickOutside(sidebarRef, onAbort, !dialogsVisible)
    useEscapeKey(onAbort, !dialogsVisible)

    return <Overlay sidebarindex={10}>
        <StyledSidebarWrapper showRight={showRight} sidebarWidth={sidebarWidth} ref={sidebarRef}>
            <StyledHeadlineBarWrapper>
                {
                    showHeadline && 
                <HeadlineBar headline={t(title)}>
                    <IconButtonWithLabel className='jest-close-button' onButtonClick={onAbort} iconKey='close' iconSize={theme.fontSize.headline3} color={theme.color.black} />
                </HeadlineBar>
                }
            </StyledHeadlineBarWrapper>
            {children}
            {SidebarFooter !== null &&
                <StyledSidebarBottom>
                    <SidebarFooter />
                </StyledSidebarBottom>
            }
        </StyledSidebarWrapper>
    </Overlay> 
}

SidebarWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    onAbort: PropTypes.func.isRequired,
    dialogsVisible: PropTypes.bool,
    SidebarFooter: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}

export default SidebarWrapper