import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import LinkFooter from './link_footer'
import ContactCompany from './contact_company'
import { useTranslation } from 'react-i18next'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import * as Fragments from '../fragments'
import { PluginAlternateView } from 'plugins'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { useTheme } from 'styled-components'

const FooterLoginAccountSection = (props) => {
	const { 
		notDesktop = false,
		accountPublicInfo,
		useTablet = false,
		useMobile = false
		} = props
    const { 
		contact,
		esyThingAgb, 
		esyThingDatenschutz, 
		primaryColor 
		} = accountPublicInfo
    const hasAgb = esyThingAgb !== undefined && esyThingAgb !== null
	const {activePlugin} = usePluginsContext()
	const theme = useTheme()
	const hasDatenschutz = esyThingDatenschutz !== undefined && esyThingDatenschutz !== null
	const hasFullStatement = hasAgb && hasDatenschutz
	const hasContact = contact !== null
	const {t} = useTranslation()
	const getContrast = (hexcolor) => {
        const r = parseInt(hexcolor.substr(0,2),16)
        const g = parseInt(hexcolor.substr(2,2),16)
        const b = parseInt(hexcolor.substr(4,2),16)
    
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
        return (yiq >= 128) ? 'black' : 'white'
    }
	const textColor = getContrast(primaryColor)

	return <Fragments.AsclarFooterContainer backgroundColor={activePlugin ? theme.color.primary : primaryColor}>
				<Fragments.AsclarMainContainer>
					<FlexGridItem>
						<FlexGridItem height={'150px'} itemsCenter width={'45%'}>
							<FlexGrid directionColumn>
								{
									hasFullStatement && <LinkFooter useMobile textColor={textColor} {...props} />
								}
								{
									hasContact && (notDesktop && !useTablet) && <ContactCompany useMobile={useMobile} textColor={textColor} {...props} />
								}
							</FlexGrid>
						</FlexGridItem>
						{ hasContact &&
							!(notDesktop && !useTablet) && 
							<FlexGridItem height={'150px'} itemsCenter width={'45%'}>
								<ContactCompany useMobile={useMobile} textColor={textColor} {...props} /> 
							</FlexGridItem>
						}
					</FlexGridItem>
					<Fragments.AsclarSpacer padding={(notDesktop && !useTablet) ? '15px' : '5px'}/>
					<PluginAlternateView id="login.account.footer.powered">
						<FlexGridItem height={'80px'} itemsFlexEnd width={'100%'}>
							<FlexGrid justifyCenter itemsFlexEnd>
								<Fragments.AsclarText 
									color={getContrast(primaryColor)} 
									fontSize={(notDesktop && !useTablet) ? '12px' : '14px'}
									margin={'0 15px 0 0'}
								>{t('Powered By')}</Fragments.AsclarText>
								<ImagePlaceHolder radius={'8px'} withBorder={false} source={textColor === 'black' ? '/assets/images/icon_esy_one.png' : '/assets/images/icon_esy_one_white.png'} large={'40px'} />
							</FlexGrid>
						</FlexGridItem>
					</PluginAlternateView>
				</Fragments.AsclarMainContainer>
			</Fragments.AsclarFooterContainer>
}

export default FooterLoginAccountSection
