import React, { useState } from 'react'
import CheckboxWithLabel from './checkbox/component'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ActionButton from './../../button/action_button/component'
import { useTranslation } from 'react-i18next'


const StyledCheckboxGroup = styled.div`
    margin-bottom: ${props => props.displayButton ? '16px' : 'unset'};
`

const StyledButtonRow = styled.div`
    display: flex;
    flex-direction: row;


    & > div {
        flex-grow: 1;  
    }

    & button {
        width: 100%;
        border-radius: 0;
    }
`


const CheckboxGroup = ({
    checkboxes = null,
    onChange = () => {},
    selectAllButton = false,
    deSelectAllButton = false,
    displayButton = false,
}) => {
    const { t } = useTranslation()
    const [currentCheckboxes, setCheckboxes] = useState(checkboxes)
   
    const selectAllCheckboxes = isselected => {

        const checkboxNames = Object.keys(currentCheckboxes)

        var all = checkboxNames.reduce(
            (options, option) => ({
                ...options,
                [option]: isselected 
            }),
            {}
        )
        setCheckboxes(all)
        onChange(all)             
    }


    const checkAll = () => selectAllCheckboxes(true)
    const unCheckAll = () => selectAllCheckboxes(false)


    const handleCheckboxChange = changeEvent => {

        const { name } = changeEvent.target

        var settedCheckboxes = {
            ...currentCheckboxes,
            [name]:  !currentCheckboxes[name]
        }
        setCheckboxes(settedCheckboxes)
        onChange(settedCheckboxes)
    }

    const createCheckbox = currentCheckboxValue => (
        <CheckboxWithLabel
            label={currentCheckboxValue}
            isselected={currentCheckboxes[currentCheckboxValue]}
            onCheckboxChange={handleCheckboxChange}
            key={currentCheckboxValue}
        />
    )

    const createCheckboxes = () => Object.keys(checkboxes).map(createCheckbox)

    return (

        <StyledCheckboxGroup displayButton={displayButton} >
            {createCheckboxes()}
            {
                displayButton && <StyledButtonRow>
                    {
                        selectAllButton && 
                        <ActionButton
                            onButtonClick={checkAll}
                            buttonText={t('select_all')}
                        />               
                    }
                    {
                        deSelectAllButton &&      
                        <ActionButton
                            onButtonClick={unCheckAll}
                            buttonText={t('deselect_all')}
                        />
                    }
                </StyledButtonRow> 
            }
            
        </StyledCheckboxGroup>
    )
}


CheckboxWithLabel.propTypes = {
    checkboxes: PropTypes.object,
    onChange: PropTypes.func,
    selectAll: PropTypes.bool,
    disSelectAll: PropTypes.bool
}

export default CheckboxGroup
