import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { call, takeLatest } from 'redux-saga/effects'
import { handleError } from '../../errors'


export function* downloadAttachmentSaga(action) {
    try {
        yield call(api.gateway.files.downloadFilesById, action.accountId, action.id, action.fileName, action.storage)
    } catch (e) {
        yield handleError(e, action)
    }

}

export function* downloadFileWatcher() {
    yield takeLatest(ACTIONS.DOWNLOAD_FILE_BY_ID_REQUEST, downloadAttachmentSaga)
}