export const wizardDefinitions = {
    registration: [
        {
            step: 1,
            label: 'Welcome',
            isDone: false,
            inProgress: true
        },
        {
            step: 2,
            label: 'Registration',
            isDone: false,
            inProgress: false
        },
        {
            step: 3,
            label: 'Confirmation',
            isDone: false,
            inProgress: false
        },
    ]
}