import { IconLink } from 'application/components/controls/icon'
import { SettingPreview } from 'application/components/controls/menu_preview'
import { DASHBOARD, ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { SettingListMobileContentWrapper, SettingListMobileWrapper } from './fragments'
import { PluginAlternateView } from 'plugins'

const SettingListMobile = ({theme,refreshClickNavMobile,accountId}) => {
    const { t } = useTranslation()
    let params = useParams()
    return <SettingListMobileWrapper>
        <SettingListMobileContentWrapper isselected={params === DASHBOARD.LINK.SETTINGS.ACCOUNT}>
            <IconLink iconSize='20px' width='50px' height='50px' iconKey='face' backgroundcolor={theme.color.color10} borderradius='100%' />
            <SettingPreview 
                onClick={refreshClickNavMobile}
                to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.ACCOUNT}
                titleMenu={t('Profile Setting')}
                descMenu={t('Profile picture, Password')}
                defaultColor
                noBorder
                width='80%'
            />
        </SettingListMobileContentWrapper>
        <SettingListMobileContentWrapper isselected={params === DASHBOARD.LINK.SETTINGS.GENERAL}>
            <IconLink iconSize='20px' width='50px' height='50px' iconKey='account_balance' backgroundcolor={theme.color.color10} borderradius='100%' />
            <SettingPreview 
                onClick={refreshClickNavMobile}
                to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.GENERAL}
                titleMenu={t('General')}
                descMenu={t('Language')}
                defaultColor
                noBorder
                width='80%'
            />
        </SettingListMobileContentWrapper>
        {/* <PluginAlternateView>
            <SettingListMobileContentWrapper isselected={params === DASHBOARD.LINK.SETTINGS.ABOUT}>
                <IconLink iconSize='20px' width='50px' height='50px' iconKey='hearing' backgroundcolor={theme.color.color10} borderradius='100%' />
                <SettingPreview 
                    onClick={refreshClickNavMobile}
                    to={ROUTES.SETTINGS + '/' + DASHBOARD.LINK.SETTINGS.ABOUT}
                    titleMenu={t('About e.sy One')}
                    descMenu={t('Feedback')}
                    defaultColor
                    noBorder
                    width='80%'
                />
            </SettingListMobileContentWrapper>
        </PluginAlternateView> */}


    </SettingListMobileWrapper>
}

export default withTheme(SettingListMobile)
