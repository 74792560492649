import { FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import * as Fragments from '../../fragments'

const LoginPinFormFooterSection = () => {
    return <FlexGridItem width='100%' itemsFlexStart justifyCenter>
        <Fragments.FooterWelcomeCompanyName>
            © e.Consult AG {(new Date().getFullYear())}
        </Fragments.FooterWelcomeCompanyName>
    </FlexGridItem>
}

export default LoginPinFormFooterSection
