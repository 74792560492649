import { sendNewPassword } from 'application/redux/actions/auth'
import { connect } from 'react-redux'
import ResetPasswordComponent from './component'

const mapStateToProps = state => ({
    newPasswordRequest: state.auth.forget.newPassword.request,
    newPasswordSucceed: state.auth.forget.newPassword.succeed,
    newPasswordFailed: state.auth.forget.newPassword.failed,
    errorMessage: state.auth.forget.errorMessage,
})

const mapDispatchToProps = dispatch => ({
    onSendNewPassword: (passwordHashKey, password) => dispatch(sendNewPassword(passwordHashKey, password))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordComponent)