import { routeHelpers } from 'application/common'
import { ROUTE_PARAMETERS } from 'application/constants'

export const generateLinksServiceProvider = (location, query, accountId) => {
    const { buildQueryString } = routeHelpers
    const foreignParams = query.all.filter(l => l.key !== ROUTE_PARAMETERS.DASHBOARD.ACCOUNT_ID)

    const links = generateLink(accountId, foreignParams)
    const queryString = buildQueryString(links)

    const to = location.pathname + queryString

    return to
}


const generateLink = (accountId, foreignParams = []) => {
    const params = [
        {
            key: ROUTE_PARAMETERS.DASHBOARD.ACCOUNT_ID,
            value: accountId
        },
        ...foreignParams,
    ]

    return params

}