import ACTIONS from 'application/constants'
import { accessTokenRegistered } from 'application/storage'

export const getThumbnailById = (id, fileName, metaData) => ({
    type: ACTIONS.FILES_GET_THUMBNAIL_REQUEST,
    id,
    fileName,
    metaData
})

export const registerThumbnailById = (id, fileName, metaData) => ({
    type: ACTIONS.FILES_REGISTER_THUMBNAIL,
    id,
    fileName,
    metaData
})

export const getFullFileById = (id, fileName, metaData) => ({
    type: ACTIONS.FILES_GET_FULL_FILE_REQUEST,
    id,
    fileName,
    metaData
})

export const registerFullFileById = (id, fileName, metaData) => ({
    type: ACTIONS.FILES_REGISTER_FULL_FILE,
    id,
    fileName,
    metaData
})

export const getFileMeta = (id, fileName, metaData) => ({
    type: ACTIONS.FILES_GET_META_REQUEST,
    id,
    fileName,
    metaData
})

export const getFileMetaMessage = (id, fileName, metaData) => ({
    type: ACTIONS.FILES_GET_META_MESSAGE_REQUEST,
    id,
    fileName,
    metaData
})

export const populateThumbnail = (id, name, data) => ({
    type: ACTIONS.FILES_POPULATE_THUMBNAIL,
    id,
    name,
    data
})

export const populateThumbnailFailed = (id, name, data) => ({
    type: ACTIONS.FILES_POPULATE_THUMBNAIL_FAILED,
    id,
    name,
    data
})

export const populateFullFile = (id, name, data) => ({
    type: ACTIONS.FILES_POPULATE_FULL_FILE,
    id,
    name,
    data
})

export const populateFullFileFailed = (id, name, data) => ({
    type: ACTIONS.FILES_POPULATE_FULL_FILE_FAILED,
    id,
    name,
    data
})

export const populateFileMeta = (id, name, meta, isLoaded=false) => ({
    type: ACTIONS.FILES_POPULATE_META,
    id,
    name,
    meta,
    isLoaded
})


export const downloadFile = (id, fileName, accountId, storage = accessTokenRegistered) => ({
    type: ACTIONS.DOWNLOAD_FILE_BY_ID_REQUEST,
    id,
    fileName,
    accountId,
    storage
})

export const downloadFileAdHoc = (
  id,
  fileName,
  accountId,
) => ({
  type: ACTIONS.DOWNLOAD_FILE_BY_ID_ADHOC_REQUEST,
  id,
  fileName,
  accountId,
})

export const downloadFileMessage = (id, fileName, accountId, akteIdHash, messageId) => ({
    type: ACTIONS.DOWNLOAD_FILE_MESSAGE_BY_ID_REQUEST,
    id,
    fileName,
    accountId,
    akteIdHash,
    messageId
})


export const deleteFile = (fileId) => ({
    type: ACTIONS.FILES_DELETE_FILE,
    fileId
})

export const putPicture = (files, metaData) => ({
    type: ACTIONS.FILES_PUT_PICTURE,
    files,
    metaData
})

export const setPicture = (files, metaData) => ({
    type: ACTIONS.FILES_SET_PICTURE,
    files,
    metaData
})

export const croppingAndSendPicture = (payload, metaData) => ({
    type: ACTIONS.FILES_CROPPING_IMAGE,
    payload,
    metaData,
})

export const postFiles = (accountId, files) => ({
    type: ACTIONS.FILES_POST_FILES_REQUEST,
    accountId,
    files
})

export const postFilesSucceed = () => ({
    type: ACTIONS.FILES_POST_FILES_SUCCEED,
})

export const postFilesFailed = () => ({
    type: ACTIONS.FILES_POST_FILES_FAILED,
})