import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import FontIcon from 'application/components/controls/font_icon'

const StyledSpan = styled.span`
    text-align: ${p => p.textAlign};
    padding-right: 5px;
    opacity:  ${props => props.cssVisible ? '1' : '0'};
`

const StyledContainerHeadingDiv = styled.div`
    display: flex;
    align-items: ${p => p.alignItems};
    justify-content: ${p => p.justifyContent};
    flex-direction: ${p => p.flexDirection};
    gap: ${p => p.gap};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    cursor: ${props => props.onStyledClick ? 'pointer' : 'default'};
`

const StyledContainerHeadingHref = styled.a`
    display: flex;
    text-decoration: none;
    color: ${props => props.theme.color.color90};
    align-items: ${p => p.alignItems};
    justify-content: ${p => p.justifyContent};
    flex-direction: ${p => p.flexDirection};
    gap: ${p => p.gap};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-decoration: none;
`

const StyledParagraph = styled.p`
    margin: 0;
    padding-bottom: 4px;
    font-size: ${props => props.fontSize};
    color: ${props => props.iconColor};
    text-align: ${p => p.textAlign};
`

const IconLabelComponent = ({
    icon,
    iconColor = '#404040',
    label,
    fontSize = '12px',
    fontWeight,
    iconVisible = true,
    linkHref,
    onClick,
    textAlign = 'center',
    alignItems = 'center',
    justifyContent = 'center',
    flexDirection = 'row',
    gap = '0px'
}) => {
    if (linkHref !== undefined) {
        return <StyledContainerHeadingHref alignItems={alignItems} justifyContent={justifyContent} href={linkHref} target='_blank' fontSize={fontSize} fontWeight={fontWeight} flexDirection={flexDirection} gap={gap}>
            <StyledSpan textAlign={textAlign} cssVisible={iconVisible}>
                <FontIcon icon={icon} isClickable={false} color={iconColor} fontSize={fontSize} />
            </StyledSpan>
            <StyledParagraph textAlign={textAlign} fontSize={fontSize}>{label}</StyledParagraph>
        </StyledContainerHeadingHref>
    }
    return (
        <StyledContainerHeadingDiv alignItems={alignItems} justifyContent={justifyContent} onClick={onClick} fontSize={fontSize} fontWeight={fontWeight} flexDirection={flexDirection} gap={gap}>
            <StyledSpan textAlign={textAlign} cssVisible={iconVisible}>
                <FontIcon icon={icon} isClickable={false} color={iconColor} fontSize={fontSize} />
            </StyledSpan>
            <StyledParagraph textAlign={textAlign} fontSize={fontSize}>{label}</StyledParagraph>
        </StyledContainerHeadingDiv>
    )
}

IconLabelComponent.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    iconColor: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    iconVisible: PropTypes.bool,
    onClick: PropTypes.func
}

export default withTheme(IconLabelComponent)
