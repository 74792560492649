import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { recountUnreadMessage, recountUnreadMessageInFolder } from 'application/redux/actions/common/all_akten'
import { downloadAkteFailed, downloadAkteSucceed } from 'application/redux/actions/pages/akten'
import { downloadMessageFailed, downloadMessageSucceed, readMessageFailed, readMessageSucceed } from 'application/redux/actions/pages/akten/action'
import { call, put, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { getActionAkten, getHandleFailed } from './akten_mapper'
import { readMessage } from 'application/redux/actions/messages'
import { recountUnreadMessageInFolderByMessageKey } from 'application/redux/actions/messages'
import { getCommonAllAktenDataSaga } from '../common/all_akten'
import { getSingleAkteSaga } from './get_single_akte_saga'

function* actionAktenSaga(action) {
    try {
        const sagas = yield call(getActionAkten, action.type)
        for (const saga of sagas) {
            yield saga(action)
        }
    } catch (e) {
        yield call(getHandleFailed, action.type, action.messageKey, e, action)
    }
}

function* downloadAkteAsZipSaga(action) {
    try {
        yield call(api.gateway.pages.downloadAkteAsZip, action.accountId, action.akteIdHash, action.aktenkurzbezeichnung, action.accountName)
        yield put(downloadAkteSucceed())
    } catch (e) {
        yield handleError(e, action)
        yield put(downloadAkteFailed())
    }
}
function* downloadMessageAsPdf(action) {
    try {
        yield call(api.gateway.pages.downloadMessageAsPdf, action.accountId, action.messageId, action.messageHeader)
        yield put(downloadMessageSucceed())
    } catch (e) {
        yield handleError(e, action)
        yield put(downloadMessageFailed())
    }
}

function* refreshSingleAkteSaga(action) {
    try {
        yield (getSingleAkteSaga(action))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* readMessageSaga(action) {
    try {
        const messageInFolder = action.folderId !== ''
        yield call(api.gateway.pages.postReadMessage, action.accountId, action.messageId)
        if (messageInFolder) {
            yield put(recountUnreadMessageInFolder(action.akteIdHash))
            yield put(recountUnreadMessageInFolderByMessageKey(action.rootKey, action.folderId))
        } else {
            yield put(recountUnreadMessage(action.akteIdHash))
        }
        yield put(readMessage(action.messageKey, action.messageId))
        yield put(readMessageSucceed())
        yield getCommonAllAktenDataSaga(action)
    } catch (e) {
        yield handleError(e, action)
        yield put(readMessageFailed())
    }
}



export function* aktenWatcher() {
    yield takeEvery(ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS, actionAktenSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_GET_MESSAGE_DETAILS_ON_FOLDER, actionAktenSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_SEND_MESSAGE_REQUEST, actionAktenSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_NEW_FOLDERS_REQUEST, actionAktenSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_NEW_FILES_REQUEST, actionAktenSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_NEW_MESSAGE_REQUEST, actionAktenSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_NEW_MESSAGE_ON_FOLDER_REQUEST, actionAktenSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_MARK_FAVORITE_MESSAGE_REQUEST, actionAktenSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_UNMARK_FAVORITE_MESSAGE_REQUEST, actionAktenSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_DOWNLOAD_MESSAGE_REQUEST, downloadMessageAsPdf)
    yield takeEvery(ACTIONS.PAGES_AKTEN_READ_MESSAGE_REQUEST, readMessageSaga)
    yield takeEvery(ACTIONS.PAGES_AKTEN_DOWNLOAD_AKTE_ZIP, downloadAkteAsZipSaga)
    yield takeEvery(ACTIONS.PAGES_GET_SINGLE_AKTE, refreshSingleAkteSaga)
} 