import React from 'react'
import { StyledIconLinkDiv } from './fragments'

const DefaultIconLink = (props) => {
    const { color, className, onClick,noHover, iconSize, isActive, width, height, backgroundcolor, borderradius, title, iconKey, textTransform = 'uppercase'} = props
    return <StyledIconLinkDiv
        color={color}
        className={className}
        onClick={onClick}
        iconSize={iconSize}
        isActive={isActive}
        width={width}
        height={height}
        backgroundcolor={backgroundcolor}
        borderradius={borderradius}
        noHover={noHover}
        textTransform={textTransform}
    >
        <i className="material-icons" title={title}>{iconKey ? iconKey : 'perm_identity'}</i>
    </StyledIconLinkDiv>
}

export default DefaultIconLink
