import styled from 'styled-components'

export const WrapperSettingDashboard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
`

export const TitleSetings = styled.h1`
    margin-top: 40px;
    color: ${props => props.theme.color.color100};
    font-size: ${props => props.theme.fontSize.headline1};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    font-family: ${props => props.theme.fontFamily.secondary};
`
export const HeadlineSetings = styled.h1`
    margin-top: 40px;
    margin-bottom: 40px;
    color: ${props => props.theme.color.color100};
    font-size: ${props => props.theme.fontSize.headline2};
    font-family: ${props => props.theme.fontFamily.secondary};
    font-weight: ${props => props.theme.fontWeight.bold};
	text-align: center;
	@media (min-width: ${p => p.theme.breakpoint.sm}) {
		text-align: left;
	}
`
export const SubHeadlineSetings = styled.h2`
    margin-top: 30px;
    color: ${props => props.theme.color.anthacite};
    font-size: ${props => props.theme.fontSize.headline3};
    font-weight: ${props => props.theme.fontWeight.semiBold};
	text-align: center;
	@media (min-width: ${p => p.theme.breakpoint.sm}) {
		text-align: left;
	}
`
export const DescSetings = styled.span`
    color: ${props => props.theme.color.color40};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.regular};
`