import { PLUGIN_ACTIONS, APPLICATION_MODE } from './plugin_constants'
import * as R from 'ramda'
import i18next from 'i18next'

export const pluginReducer = (state, action) => {
  switch (action.type) {
    case PLUGIN_ACTIONS.DEV_MODE:
      return {
        ...state,
        mode: APPLICATION_MODE.DEV,
      }

    case PLUGIN_ACTIONS.PROD_MODE:
      return {
        ...state,
        mode: APPLICATION_MODE.PROD,
      }

    case PLUGIN_ACTIONS.SET_LANG:
      return {
        ...state,
        lang: action.payload,
      }

    case PLUGIN_ACTIONS.SET_USER_ACCOUNT_ID:
      return {
        ...state,
        user: {
          ...state.user,
          accountId: action.payload
        }
      }

    case PLUGIN_ACTIONS.SET_ACTIVE_PLUGIN:
      // update language resources with active plugin resources
      const localization = R.mergeDeepRight(state.localization, action.payload.getResources())
      for (let key in localization)
        for (let ns in localization[key])
          i18next.addResourceBundle(key, ns, localization[key][ns])


      return {
        ...state,
        activePlugin: action.payload,
        theme: R.mergeDeepRight(state.theme, action.payload.getTheme()),
        config: action.payload.getConfig(state.mode),
        localization,
      }

    default:
      return state
  }
}