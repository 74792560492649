import styled from 'styled-components'

export const StyledContentActivateProviderMobileScreen = styled.div`
	display: flex;
	padding: 20px;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	gap: 20px;

	@supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & div:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`
export const HeadlineWrapperActivateProviderMobileScreen = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 5px;
	width: 100%;
`

export const HeadlineTitleActivateProviderMobileScreen = styled.h3`
	font-size: ${p => p.theme.fontSize.headline3};
	margin: 0px;
`

export const StyledLogo = styled.div`
    width: 70px;
    height: 70px;
`