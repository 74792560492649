import { AKTEN } from 'application/constants'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RefreshFolderMessagesEffects from './refresh_folder_messages_effects'
import RootMessageLoader from './root_message_loader'

const DataFolderLevelLoader = (props) => {
    const { allAccountsLoaded, accountId, akteIdHash, folderId, getMessageDetailsOnFolder, children, messageKey, shouldLoad, allAktenLoaded, rootKey, registeredMessages } = props
    let params = useParams()
    const rootMessageRegistered = registeredMessages.filter((r) => r.key === rootKey).length > 0
    const rootMessageLoaded = rootMessageRegistered && registeredMessages.find((r) => r.key === rootKey).isFullLoaded
    useEffect(() => {
        shouldLoad &&
            rootMessageLoaded &&
            allAccountsLoaded &&
            allAktenLoaded &&
            accountId !== undefined &&
            akteIdHash !== undefined &&
            folderId !== '' &&
            getMessageDetailsOnFolder(accountId, akteIdHash, folderId, params, messageKey, rootKey, AKTEN.TYPE.FOLDER)
    }, [accountId, allAccountsLoaded, akteIdHash, folderId, getMessageDetailsOnFolder, params, messageKey, shouldLoad, allAktenLoaded, rootKey, rootMessageLoaded])

    return (
        <RootMessageLoader {...props}>
            <RefreshFolderMessagesEffects {...props}>{children}</RefreshFolderMessagesEffects>
        </RootMessageLoader>
    )
}

export default DataFolderLevelLoader
