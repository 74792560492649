import { API_VERSION_ESYMANDANT, fetchHandler, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { fetchRequestBuilder } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const getPassword = (username) => {

    const request = fetchRequestBuilder(API_VERSION_ESYMANDANT + '/user/password', HTTP_METHOD.POST, {username})

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}