import i18next from 'i18next'
import { accessTokenRegistered } from 'application/storage'
import { buildApiUrl } from 'application/api/helpers'

export const fileUploadRequestBuilder = (apiEndpoint, file, params = [], useApiKey = true, absolute = false, omitToken = false, storage = accessTokenRegistered) => {
    const url = buildApiUrl(apiEndpoint, absolute)
    const apiKey = process.env.REACT_APP_API_KEY
    const applicationKey = process.env.REACT_APP_APPLICATION_KEY
    
    let formData = new FormData()
    if (file !== null) {
        formData.append('File', file, file.name)
        formData.append('Name', file.name)
    }
    

    for(const param of params){
        formData.append(param.key, param.value)
    }

    let data = {
        method: 'POST',
        headers: { 
            'Accept-Language': i18next.language,
            'X-API-Key': apiKey,
            'X-Application-Key': applicationKey
        },
        mode: 'cors',
        body: formData
    }
    
    data.headers = !omitToken ? {
        ...data.headers,
        'Authorization': 'Bearer ' + storage.get(),
    } : data.headers

    return {
        data,
        url
    }
}