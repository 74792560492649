import ACTIONS from 'application/constants'

export const loginRequestPin = (key, password, errorMessage) => ({
    type: ACTIONS.AUTH_LOGIN_REQUEST_PIN,
    key,
    password,
    errorMessage
})

export const writeTokenUnregistered = (accessToken) => ({
    type: ACTIONS.AUTH_WRITE_ACCESS_TOKEN_UNREGISTERED,
    accessToken
})

export const loginRequestSuccessPin = () => ({
    type: ACTIONS.AUTH_LOGIN_SUCCESS_PIN
})

export const loginRequestFailedPin = () => ({
    type: ACTIONS.AUTH_LOGIN_FAILED_PIN
})

export const loginWithoutPin = (key, errorMessage) => ({
    type: ACTIONS.AUTH_LOGIN_WITHOUT_PIN,
    key,
    errorMessage,
})