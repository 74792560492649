import ImagePlaceHolder from 'application/components/controls/image_placeholder'

export function MobileAssistantFormSucceed({resultText}) {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <h1
        style={{
          textAlign: 'center',
          marginTop: '20px',
          fontFamily: 'Marcellus',
          color: 'black',
          marginBottom: '50px'
        }}
      >
        {resultText}
      </h1>
    </div>
  )
}
