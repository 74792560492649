import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import React from 'react'
import { SettingListMobile } from '../../menu_list/setting_list'
import { NavigationLinkSettingsMobileWrapper, SettingsHeadline } from './fragments'

const NavigationMobileSettings = ({refreshClickNavMobile, accountId}) => {
    return <NavigationLinkSettingsMobileWrapper>
        <SettingsHeadline>
			Settings
        </SettingsHeadline>
        <SettingListMobile accountId={accountId} refreshClickNavMobile={refreshClickNavMobile} />
        <FlexDividerHorizontal />
    </NavigationLinkSettingsMobileWrapper>
}

export default NavigationMobileSettings
