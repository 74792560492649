import { DIALOG } from 'application/constants'

//import all the dialog components
import { DefaultDialogContent, DefaultHeader } from 'application/components/dialogs'
import SendPinOptionDialog from 'application/components/dialogs/send_pin_option'
import UploadProfilePictureDialog from 'application/components/dialogs/upload_profile_picture'
import DatenshutzDialog from 'application/components/dialogs/datenshutz'
import LoginFormDialog from 'application/components/dialogs/login_form'
import AccountStatementDialog from 'application/components/dialogs/account_statement'
import NotesPasswordRegistrationDialog from 'application/components/dialogs/password_criteria'
import ActivateProviderMobileScreen from 'application/components/dialogs/activate_provider_mobile_screen'
import AutoReplyDialog from 'application/components/dialogs/auto_reply'
import SendMessageDialog from 'application/components/dialogs/send_message_dialog'

export const dialogSizeMapper = name => {
    switch (name) {
    case DIALOG.NAME.SEND_PIN_OPTION: return DIALOG.SIZE.MEDIUM
    case DIALOG.NAME.AUTO_REPLY_MESSAGE: return DIALOG.SIZE.MEDIUM
    default: return DIALOG.SIZE.MEDIUM50
    }
}
// 

export const dialogComponentsMapper = dialog => {
    switch (dialog.name) {   
    case DIALOG.NAME.SEND_PIN_OPTION: return {
        MainComponent: SendPinOptionDialog
    }
    case DIALOG.NAME.UPLOAD_PROFILE_PICTURE: return {
        MainComponent: UploadProfilePictureDialog
    }
    case DIALOG.NAME.DISPLAY_DATENSCHUTZ: return {
        MainComponent: DatenshutzDialog
    }
    case DIALOG.NAME.DISPLAY_ACCOUNT_STATEMENT: return {
        MainComponent: AccountStatementDialog
    }
    case DIALOG.NAME.LOGIN_FORM: return {
        MainComponent: LoginFormDialog
    }
    case DIALOG.NAME.AUTO_REPLY_MESSAGE: return {
        MainComponent: AutoReplyDialog,
        disableHeader: true,
        putCenter: true,
        rounded: true,
        noPadding: true,
    }
    case DIALOG.NAME.SEND_MESSAGE: return {
        MainComponent: SendMessageDialog,
        putCenter: true,
        rounded: true,
    }
    case DIALOG.NAME.SEND_MESSAGE: return {
        MainComponent: SendMessageDialog,
        putCenter: true,
    }
    case DIALOG.NAME.NOTES_PASSWORD_REGISTRATION: return {
        MainComponent: NotesPasswordRegistrationDialog
		}
		case DIALOG.NAME.ACTIVATE_MODE_MOBILE: return {
			MainComponent: ActivateProviderMobileScreen
		}
    default: return {
        AdditionalHeader: DefaultHeader,
        MainComponent: DefaultDialogContent
    }
    }
}

//abort confirmation message mapper?