import defaultStore from '../../default_store'
import { ACTIONS } from 'application/constants'

const forgetReducer = (state = defaultStore.auth.forget, action) => {
    switch (action.type) {
    case ACTIONS.AUTH_FORGET_USERNAME_REQUEST:
        return {
            ...state,
            username: {
                ...state.username,
                request: true,
            }
        }
    case ACTIONS.AUTH_RERESH_FORGET_USERNAME:
        return {
            ...state,
            username: {
                request: false,
                failed: false,
                succeed: false,
            }
        }
    case ACTIONS.AUTH_RERESH_FORGET_PASSWORD:
        return {
            ...state,
            password: {
                request: false,
                failed: false,
                succeed: false,
            }
        }
    case ACTIONS.AUTH_FORGET_USERNAME_FAILED:
        return {
            ...state,
            username: {
                ...state.username,
                failed: true,
                request: false,
                succeed: false,
            }
        }
    case ACTIONS.AUTH_FORGET_USERNAME_SUCCEED:
        return {
            ...state,
            username: {
                ...state.username,
                request: false,
                failed: false,
                succeed: true,
            }
        }
    case ACTIONS.AUTH_FORGET_PASSWORD_REQUEST:
        return {
            ...state,
            password: {
                ...state.password,
                request: true,
            }
        }
    case ACTIONS.AUTH_FORGET_PASSWORD_FAILED:
        return {
            ...state,
            password: {
                ...state.password,
                failed: true,
                request: false,
                succeed: false,
            }
        }
    case ACTIONS.AUTH_FORGET_PASSWORD_SUCCEED:
        return {
            ...state,
            password: {
                ...state.password,
                request: false,
                failed: false,
                succeed: true,
            }
        }
    case ACTIONS.AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD:
        return {
            ...state,
            newPassword: {
                ...state.newPassword,
                request: true,
            }
        }
    case ACTIONS.AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD_FAILED:
        return {
            ...state,
            newPassword: {
                ...state.newPassword,
                failed: true,
                request: false,
                succeed: false,
            }
        }
    case ACTIONS.AUTH_FORGET_PASSWORD_SEND_NEW_PASSWORD_SUCCEED:
        return {
            ...state,
            newPassword: {
                ...state.newPassword,
                request: false,
                failed: false,
                succeed: true,
            }
        }
    case ACTIONS.AUTH_FORGET_CREDENTIAL_SHOW_ERROR:
        return {
            ...state,
            errorMessage: action.errorMessage
        }
    default:
        return state
    }
}

export default forgetReducer