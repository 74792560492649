import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'

const LogoLoginForm = (props) => {
    const { large = '60px', toCenter = false, flexGrow, flexShrink, flexBasis } = props
    return <FlexGridItem grow={flexGrow} shrink={flexShrink} basis={flexBasis}>
        <ImagePlaceHolder large={large} toCenter={toCenter} withBorder={false} source='/assets/images/icon_esy_one.png' />
    </FlexGridItem>
}

export default LogoLoginForm
