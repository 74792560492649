import React, { Fragment } from 'react'
import { ParWizard } from '../../../fragments'
import { useTranslation, Trans } from 'react-i18next'

const SuccessTitleRegistration = ({ accountName, className = '' }) => {
    const {t} = useTranslation()
    return <Fragment>
        <ParWizard id='headline-succeed-reg-title-ec_mobile' className={className}>
            {t('Herzlichen Glückwunsch!')}
        </ParWizard>
        <ParWizard className={className}>
            <Trans i18nKey="CongratulationText1">
                Enjoy easy and secure communication with {{ accountName }}
            </Trans>
        </ParWizard>
    </Fragment>
}

export default SuccessTitleRegistration
