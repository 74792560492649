import ACTIONS from 'application/constants'
import default_store from '../../default_store'

const searchTerm = (state = default_store.common, action) => {
    switch (action.type) {
    case ACTIONS.COMMON_SET_SEARCH_TERM_AKTEN:
        return {
            ...state,
            searchTerm: action.value
        }
    default:
        return state
    }
}

export default searchTerm