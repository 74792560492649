import { AKTEN } from 'application/constants'
import { downloadAkteAsZip, openAkteSidebar } from 'application/redux/actions/pages/akten'
import { connect } from 'react-redux'
import MessageDetailsTopBarComponent from './component'
import { populateBreadscrumbsData, setBreadscurmbsView, setSelectedFolder } from 'application/redux/actions/pages/akten/common'
import { refreshMessageOnFolder } from 'application/redux/actions/pages/akten/details'
import { openContactInfoPopupSidebar } from 'application/redux/actions/sidebars'
import { refreshMessageFavorite, updateDataSelectedMessageFolder, updateDataSelectedMessageRoot } from 'application/redux/actions/messages'
import { refreshAllAktenDAta } from 'application/redux/actions/common/all_akten'
import { refreshAllAccountsDAta } from 'application/redux/actions/common/all_accounts'

const mapStateToProps = (state) => ({
    downloadAkteRequest: state.pages.akten.action.downloadAkte.request,
    messageDetailsLoaded: state.pages.akten.details.isLoaded,
    breadcrumbsView: state.pages.akten.common.breadcrumbsView,
    breadcrumbsData: state.pages.akten.common.breadcrumbsData,
    sidebarVisible: state.pages.akten.sidebar.visible,
    selectedFolder: state.pages.akten.common.selectedFolder,
})

const mapDispatchToProps = (dispatch) => ({
    openSearchMessage: () => {},
    openDetailsMessage: () => {
        dispatch(openAkteSidebar(AKTEN.SIDEBAR.KEY.DETAILS))
    },
    downloadAkte: (accountId, akteIdHash, accountName, aktenkurzbezeichnung, errorMessage) => {
        dispatch(downloadAkteAsZip(accountId, akteIdHash, accountName, aktenkurzbezeichnung, errorMessage))
    },
    setSelectedFolder: (payload, breadcrumbsData) => {
        dispatch(setSelectedFolder(payload))
        dispatch(populateBreadscrumbsData(breadcrumbsData))
        dispatch(refreshMessageOnFolder())
    },
    backToParent: (payload) => {
        dispatch(setSelectedFolder(payload))
        dispatch(refreshMessageOnFolder())
    },
    refreshRoot: () => {
        dispatch(setBreadscurmbsView(false))
    },
    handleOpenContactInformationPopupSidebar: (payload) => {
        dispatch(openContactInfoPopupSidebar(payload))
	},
	refreshAkten: () => dispatch(refreshAllAktenDAta()),
	refreshAccounts: () => dispatch(refreshAllAccountsDAta()),
    refreshMessageFavorite: () => dispatch(refreshMessageFavorite()),
    updateDataSelectedMessageFolder: (params, folderId, akteIdHash, accountId) => dispatch(updateDataSelectedMessageFolder(params, folderId, akteIdHash, accountId)),
    updateDataSelectedMessageRoot: (params, akteIdHash, accountId, selectedAkte) => dispatch(updateDataSelectedMessageRoot(params, akteIdHash, accountId, selectedAkte)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageDetailsTopBarComponent)
