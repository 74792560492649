import styled from 'styled-components'

export const StyledButtonWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-shrink: 0;
    border-style: solid;
    border-width: 1px;
    position: relative;
    z-index: 0;
    min-height: 0px;
    min-width: 80px;
    border-color: ${props => props.outline ? props.borderColor !== '' ? props.borderColor : 'transparent' :
        props.noBackground ? 'transparent' : props.backgroundColor ? props.backgroundColor :
            props.secondary ? props.theme.color.color100 : props.theme.color.primary};
    border-radius: ${props => props.secondary ? props.borderradius ? props.borderradius : '0px' : props.borderradius ? props.borderradius : '5px'};
    height: auto;
    justify-content: center;
    padding: ${p => p.noPadding ? '0px' : '19px 24px'};
    transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s, color 0.2s ease 0s;
    opacity: ${props => props.disabled ? '0.5' : '1'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    background-color: ${props => props.noBackground ? 'transparent' : props.backgroundColor ? props.backgroundColor : props.secondary ? props.theme.color.color100 : props.theme.color.primary};
    max-width: 100%;
    color: ${props => props.color};
    font-weight: ${props => props.theme.fontWeight.medium};
    font-size: ${props => props.fontSize};
    line-height: 20px;
    visibility: visible;
    text-size-adjust: none;

    &::hover {
        background-color: ${props => props.secondary ? props.theme.color.color90 : props.theme.color.primary};
    }
`