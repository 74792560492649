import { STORAGE } from 'application/constants'

export const hashUserKey = {
    store: (hash) => {
        localStorage.removeItem(STORAGE.HASH_USER_KEY)
        localStorage.setItem(STORAGE.HASH_USER_KEY, hash)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.HASH_USER_KEY)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.HASH_USER_KEY)
    }
}