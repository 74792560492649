import Moment from 'moment'
import { getOffset } from './date_time_helpers'

export const buildQueryString = (params) => {
    let queryString = '?'
    for (const param of params) {
        queryString += `${param.key}=${param.value}&` 
    }
    return queryString.substring(0, queryString.length - 1)
}

export const getParamValueByKey = (paramsArray, key, fallback = '') => {
    const entry = paramsArray.find(p => p.key === key)

    if (entry === undefined) {
        return fallback
    }

    return entry.value === undefined || entry.value === null || entry.value === '' ? fallback : entry.value.toString()
}

/**
 * Function converts path like /user/123 to /user/:id
 */
export const getRoutePath = (location, params) => {
    const { pathname } = location

    if (!Object.keys(params).length) {
        return pathname // we don't need to replace anything
    }

    let path = pathname
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}`)
        }
    })
    return path
}

export const getUrlParamValueString = (paramName, fallback = '') => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (!urlParams.has(paramName)) {
        return fallback
    }
    return urlParams.get(paramName)
}

export const getUrlParamValueNumber = (paramName, fallback = 0) => {
    const value = getUrlParamValueString(paramName, fallback)
    const parsed = parseInt(value)
    if (isNaN(parsed)) {
        return fallback
    }
    return parsed
}

export const getUrlParamValueDate = (param, fallback) => {
    const urlParamValue = getUrlParamValueString(param, fallback)
    const date = new Date(urlParamValue)
    const dateOffset = getOffset(date)
    return new Moment(dateOffset).toISOString()
}