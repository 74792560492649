import React from 'react'

import styled from 'styled-components'
import { FlexGridItem } from 'application/components/fragments/grid'
import { FlexDividerVertical } from 'application/components/fragments/flex_divider'

export const DialogFooter = styled.div`
    height: auto;
    filter: blur(${props => props.blurred ? '5px' : '0'});
    padding: 16px 16px 16px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid ${props => props.theme.color.color15};
    margin-top: auto;
    position: fixed;
    bottom: 0;
    background: ${props => props.theme.color.color0};
    width: 100%;
`

export const DialogFooterLeft = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
`

export const DialogFooterRight = styled.div`
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
`

export const DialogAdditionalHeader = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
`

export const DialogAdditionalHeaderItem = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    &:last-child{
        padding-right: 36px;
    }
`

export const DialogMainContent = styled.div`
    flex: 3;
    padding: 0 16px;
    margin: 28px 0px;
    overflow-y: auto;
    min-height: ${props => props.cssHeight !== undefined ? props.cssHeight : 'auto'};
    display: flex;
    flex-direction: column;
`
export const DialogMainContentWithSideBar = styled.div`
    flex: 3;
    padding-right: 16px;
    padding-left: 16px;
    overflow-y: auto;
    min-height: 550px;
`
export const DialogMainContentWithoutPadding = styled.div`
    flex: 3;
    overflow-y: auto;
    max-height: 550px;
`
export const SpaceBetween = styled.div`
    margin-bottom: 32px;
`

export const SubHeadline = styled.h3`
    color: ${props => props.theme.color.color90};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    margin: ${props => props.isFirst ? '0' : '8px'} 0 8px;
`

export const DialogSidebarScrollAble = styled.div`
    flex: 1;
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: stretch;
    margin-right: 8px;
    margin-left: 24px;
    max-height: 500px;
    overflow: auto;
    padding-bottom: 16px;
`

export const DialogSidebar = ({ children, cssWidth = '200px', withLine = true }) => {
    return <React.Fragment>
        {
            withLine && <FlexDividerVertical />
        }
        <FlexGridItem
            needSpaceLeft='24px'
            needSpaceRight='8px'
            shrink={0}
            grow={0}
            itemsFlexStart
            basis={cssWidth}
        >
            {children}
        </FlexGridItem>
    </React.Fragment>
}

