import React, { useState } from 'react'
import * as Fragment from './fragments'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import TagCategory from 'application/components/controls/tag_category'
import { FlexGrid, FlexGridItem, PushBottom10, PushLeft10 } from 'application/components/fragments/grid'
import { withTheme } from 'styled-components'
import AttachmentViewerHoc from '../attachments/attachment_viewer'
import { favoriteHelper } from './state_helper'
import DefaultChatBubbleNotDelivered from './default_not_delivered'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { Anchorme } from 'react-anchorme'
import { useParams } from 'react-router-dom'
import DateComponent from 'application/components/controls/date'
import Time from 'application/components/controls/time'
import FontIcon from 'application/components/controls/font_icon'
import IconLink from 'application/components/controls/icon/icon_link'
import IconLabel from 'application/components/controls/icon/icon_label'
import { isMobileOnly } from 'react-device-detect'

const DefaultChatBubbleComponent = (props) => {
    let params = useParams()
    const {
        messageDetail,
        downloadMessage,
        filesToShow,
        thumbnailsToShow,
        mainColor,
        messageId,
        accountId,
        theme,
        onOpenGallery,
        parentId,
        akteIdHash,
        downloadMessageRequest,
        downloadMessageId,
        isFavoriteSelected,
        downloadAttachment,
        serviceAkte,
        chatId,
        withFavorite = true,
        markMessageAsFavorite,
        unmarkMessageAsFavorite,
        markMessageFavoriteId,
        messageKey,
        setPayloadFavoriteMessages,
        deletePayloadFavoriteMessages,
    } = props
    const { message, header, creatorName, creationDate, isOwnMessage, labels, belegdatum, delivered, isFavorite } = messageDetail
    const [favorite, setFavorite] = useState(isFavorite)
    const dateOnMessage = creationDate

    const isSmallLaptop = useMediaQuery({ minWidth: 1366, maxWidth: 1440 })

    const { t } = useTranslation()
    const { favoriteColor, favoriteIcon, handleFavorite } = favoriteHelper(
        favorite,
        accountId,
        unmarkMessageAsFavorite,
        markMessageFavoriteId,
        messageId,
        messageKey,
        params,
        theme,
        markMessageAsFavorite,
        setFavorite,
        messageDetail,
        setPayloadFavoriteMessages,
        deletePayloadFavoriteMessages
    )
    const showedFiles = filesToShow.length > 4 ? filesToShow.slice(0, 4) : filesToShow

    if (!delivered) {
        return <DefaultChatBubbleNotDelivered {...props} />
    }
    return (
        <React.Fragment>
            <Fragment.ChatBubbleWrapper id={chatId} serviceAkte={serviceAkte} isOwnMessage={isOwnMessage}>
                <Fragment.ChatBubbleInner isFavoriteSelected={isFavoriteSelected}>
                    <FlexGrid justifySpaceBetween>
                        <Fragment.ChatBubbleCreator>{creatorName}</Fragment.ChatBubbleCreator>
                        <Fragment.IconWrapper>
                            {accountId !== 0 && downloadMessageRequest && downloadMessageId === messageId ? (
                                <PreloaderSpin paddingTop='0px' small={true} />
                            ) : (
                                <IconLink
                                    title={t('Download message')}
                                    onClick={() => downloadMessage(accountId, messageId, header)}
                                    color={theme.color.color90}
                                    iconKey='file_download'
                                    iconSize={theme.fontSize.medium}
                                    width='20px'
                                />
                            )}
                            {withFavorite && (
                                <IconLink
                                    title={t('Favorite message')}
                                    onClick={handleFavorite}
                                    color={favoriteColor}
                                    iconKey={favoriteIcon}
                                    iconSize={theme.fontSize.medium}
                                    width='20px'
                                />
                            )}
                        </Fragment.IconWrapper>
                    </FlexGrid>
                    <PushBottom10 />
                    <FlexGridItem>
                        <FlexGrid>
                            {labels.length > 0 && (
                                <React.Fragment>
                                    {labels.map((l, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <TagCategory backgroundcolor={mainColor} text={l} />
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )}
                        </FlexGrid>
                    </FlexGridItem>
                    <PushBottom10 />
                    <React.Fragment>
                        {filesToShow.length > 4 && (
                            <React.Fragment>
                                <FlexGrid>
                                    <IconLabel
                                        onClick={() => onOpenGallery(showedFiles[0].id, showedFiles[0].fileName, accountId, parentId, akteIdHash, thumbnailsToShow)}
                                        icon='remove_red_eye'
                                        iconColor={theme.color.salmonDark}
                                        fontSize={theme.fontSize.medium}
                                        label={t('Show all files')}
                                    />
                                </FlexGrid>
                                <PushBottom10 />
                            </React.Fragment>
                        )}
                        <FlexGrid>
                            {filesToShow.length > 0 && (
                                <React.Fragment>
                                    <PushBottom10 />
                                    {filesToShow.map((s, i) => {
                                        return (
                                            <AttachmentViewerHoc
                                                key={i}
                                                widthFixed={isMobileOnly ? '100px' : isSmallLaptop ? '150px' : '200px'}
                                                heightFixed={isMobileOnly ? '100px' : isSmallLaptop ? '150px' : '200px'}
                                                withHeadline={true}
                                                fontSizeHeadline='10px'
                                                withDownload={false}
                                                onAttachmentDownload={() => downloadAttachment(s.id, s.fileName, params.accountId)}
                                                fileId={parseInt(s.id)}
                                                fileName={s.fileName}
                                                isSizeFixed={true}
                                                onFullFileClick={() => onOpenGallery(s.id, s.fileName, params.accountId, parentId, akteIdHash, thumbnailsToShow)}
                                                metaData={{
                                                    accountId: params.accountId,
                                                    akteIdHash: akteIdHash,
                                                    parentId: parentId,
                                                }}
                                            />
                                        )
                                    })}
                                </React.Fragment>
                            )}
                        </FlexGrid>
                    </React.Fragment>
                    {!isOwnMessage && (
                        <React.Fragment>
                            <FlexGridItem>
                                <Fragment.ChatBubleMessageHeader>{header}</Fragment.ChatBubleMessageHeader>
                            </FlexGridItem>
                            <PushBottom10 />
                        </React.Fragment>
                    )}
                    <FlexGridItem>
                        <Fragment.ChatBubleMessage>
                            <Anchorme target='_blank' rel='noreferrer noopener'>
                                {message}
                            </Anchorme>
                        </Fragment.ChatBubleMessage>
                    </FlexGridItem>
                    <PushBottom10 />
                    <FlexGrid itemsFlexEnd justifySpaceBetween>
                        <FlexGridItem grow={0} widthMobile='15%'>
                            {belegdatum !== null && (
                                <Fragment.ChatBubleDate>
                                    <span>Belegdatum:</span> <DateComponent date={belegdatum} />
                                </Fragment.ChatBubleDate>
                            )}
                        </FlexGridItem>
                        <Fragment.ChatBubleDate>
                            <DateComponent date={dateOnMessage} /> - <Time date={dateOnMessage} timeHourSuffix='' />
                            {isOwnMessage && delivered && (
                                <React.Fragment>
                                    <PushLeft10 />
                                    <FontIcon icon='done_all' color={theme.color.primary} fontSize={theme.fontSize.medium18} />
                                </React.Fragment>
                            )}
                        </Fragment.ChatBubleDate>
                    </FlexGrid>
                </Fragment.ChatBubbleInner>
            </Fragment.ChatBubbleWrapper>
        </React.Fragment>
    )
}

const DefaultChatBubble = withTheme(DefaultChatBubbleComponent)
export default DefaultChatBubble
