import styled from 'styled-components'
import { motion } from 'framer-motion'

export const NodeText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  font-size: ${props => props.theme.fontSize.standard};
  color: ${props => props.selected ? props.theme.color.salmonDark : props.theme.color.color40};
  font-weight: ${props => props.selected ? props.theme.fontWeight.bold : props.theme.fontWeight.medium};
  padding-left: 8px;
  width: 100%;
`

export const WrapperTreeStyled = styled(motion.div)`
  padding-left: calc(${props => `20px * ${props.level}`});
  min-height: 20px;
  display: flex;
  align-items: center;
  min-width: 0;
`

export const IconTreeContainer = styled(motion.div)`
  display: flex;
  /* width: ${props => props.size ? props.size : props.theme.fontSize.standard};
  height: ${props => props.size ? props.size : props.theme.fontSize.standard}; */
  text-align: left;
  padding: 2px;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  padding-right: ${props => props.noPaddRight ? '0px' : '8px'};
  width: auto;

  & i {
      color: ${props => props.selected ? props.theme.color.salmonDark : props.totalUnreadMessage > 0 ? props.theme.color.salmonDark : props.theme.color.color40}
  }
`

export const ElementTree = styled(motion.div)`
  padding: 0px 15px 0px 4px;
  min-height: 20px;
  min-width: 0;
  display: flex;
  align-items: center;
  background: ${props => props.selected ? props.theme.color.color10 : 'unset'};
  justify-content: flex-start;
  /* ${props =>
        props.selected
            ? `
      border-left: 4px solid ${props.theme.color.primary};
      margin-left: -4px;
    `
            : ''} */
  &:hover {
    background: ${props => props.theme.color.color10};
    border-radius: 3px;
  }
  /* ${props =>
        props.selected
            ? `background-color: ${props.theme.color.color30};`
            : ''} */
`

export const EmptyTree = styled(Element)`
  color: ${props => props.theme.color.color90};
`

export const TreeComponentStyled = styled(motion.div)`
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  ${props => (props.grow ? 'flex-grow: 1' : '')};
  /* padding: 5px; */
  overflow-y: auto;
  width: ${props => {
        return props.size
            ? `${props.size}`
            : 'auto'
    }};
    
  color: ${props => props.theme.color.color90};
  background-color: transparent;
  & * {
    /* user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    display: flex; */
  }
`

export const TreeLoader = styled(motion.div)`
  align-self: center;
  margin: auto 0;
`