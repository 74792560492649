import { connect } from 'react-redux'
import ServiceProviderSidebarEffects from './effects'

const mapStateToProps = state => ({
    accountDetailsVisible: state.common.accountDetails.accountDetailsVisible,
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceProviderSidebarEffects)