import { STORAGE } from 'application/constants'

export const regDataUserNameSet = {
    store: (data) => {
        localStorage.removeItem(STORAGE.USERNAME_SET)
        localStorage.setItem(STORAGE.USERNAME_SET, data)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.USERNAME_SET)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.USERNAME_SET)
    }
}

export const regDataPasswordSet = {
    store: (condition) => {
        localStorage.removeItem(STORAGE.PASSWORD_SET)
        localStorage.setItem(STORAGE.PASSWORD_SET, condition)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.PASSWORD_SET)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.PASSWORD_SET)
    }
}

export const registrationCompleted = {
    store: (condition) => {
        localStorage.removeItem(STORAGE.REGISTRATION_COMPLETED)
        localStorage.setItem(STORAGE.REGISTRATION_COMPLETED, condition)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.REGISTRATION_COMPLETED)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.REGISTRATION_COMPLETED)
    }
}