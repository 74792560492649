import { select } from '@redux-saga/core/effects'
import { getSingleAkte } from 'application/redux/selectors'
import dayjs from 'dayjs'
import { getAkteMetaInfoSaga } from './get_akte_meta_info_saga'

export function* messageRawMapper(resultMessageDetails, akteIdHash = '', accountId = '',  messageType = '') {
    const messages = setDeliveredOnOwnMessage(resultMessageDetails.messages.timeline.reverse())
    const totalMessages = resultMessageDetails.messages.totalCount
    const folders = resultMessageDetails.folders.reverse()
    const files = resultMessageDetails.files.reverse()
    const favoriteMessages = resultMessageDetails.messages.timeline.reverse().filter(t => t.isFavorite)
    const messageRaw = resultMessageDetails
    const metaInfo = yield getAkteMetaInfoSaga({accountId})
    const rootInfo = yield select(getSingleAkte, akteIdHash)
    const lastSync = dayjs()
    const exist = true

    return { messages, folders, files, favoriteMessages, metaInfo, messageRaw, totalMessages, rootInfo, lastSync, exist, messageType }
}

export const setDeliveredOnOwnMessage = (messages) => {
    const deliveredMessages = messages.map(m => {
        return {
            ...m,
            delivered: true,
        }
    })

    return deliveredMessages
}