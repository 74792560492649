import { laneDefinitions } from './lane_definitions'

const mapRoleToLanes = (role) => {
    const r = role.toLowerCase()
    var roles = {
        'admin': () => (laneDefinitions.admin),
        'consultant': () => (laneDefinitions.consultant),
        'user': () => (laneDefinitions.user),
        'internal': () => (laneDefinitions.internal),
        'default': () => (laneDefinitions.basic)
    }
    return (roles[r] || roles['default'])()
}

export const getLanes = (action = { role: 'basic' }) => {
    return {
        lanes: mapRoleToLanes(action.role)
    }
}