import React, { useState } from 'react'
import BreadcrumbCollapser from './collapser'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const WithCollapse = (itemsBefore, itemsAfter, childrenProps = [], totalItems, handleBreadClick) => {
    const [anchor, setAnchor] = useState(null)

    const handleClick = (e) => {
        setAnchor(e.currentTarget)
    }

    const handleClose = () => {
        setAnchor(null)
    }

    const z = [
        childrenProps.props.children.slice(0, itemsBefore),
        <BreadcrumbCollapser handleClick={handleClick} key='more' />,
        childrenProps.props.children.slice(totalItems - itemsAfter, totalItems),
    ]

    const itemsToShowInPopOver = [
        childrenProps.props.children.slice(itemsBefore, totalItems - itemsAfter).map((p, i) => {
            return <MenuItem key={i}>{p}</MenuItem>
        }),
    ]

    return (
        <React.Fragment>
            {z}
            <Menu anchorEl={anchor} open={anchor} onClose={handleClose}>
                {itemsToShowInPopOver}
            </Menu>
        </React.Fragment>
    )
}

export default WithCollapse
