import React from 'react'
import styled from 'styled-components'
import { ToastContainer, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const StyledToast = styled.div`
    --toastify-color-success: ${props => props.theme.color.success};
    --toastify-icon-color-success: var(--toastify-color-success);

    & .Toastify__toast{
           position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 28px;
    border-radius: 8px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    display: flex;
    justify-content: center;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
    align-items: center;
        & .Toastify__toast-body{
            margin-top: -2px;
        }
    }
    & .Toastify__toast--error {
        background: ${p => p.theme.color.red};
    }
    & .Toastify__toast-container--top-center{
        width: 420px;
        margin-left: -210px;
        padding-top: 0;
        top: 0;
        right: 0;
        @media only screen and (max-width: ${props => props.theme.breakpoint.small}px){
            margin-left: 0;
            width: 100vw;
        }
    }
    & .Toastify__toast-container--top-left{
        width: 420px;
        padding-top: 0;
        padding-left: 0;
        top: 0;
        left: 0;
        @media only screen and (max-width: ${props => props.theme.breakpoint.small}px){
            width: 100vw;
        }
    }
    
    & .Toastify__toast--success{
        background: ${props => props.theme.color.primary};
    }

    & .Toastify__progress-bar--success {
        background: var(--toastify-color-success);
    }

    & .Toastify__toast-body > div:last-child {
        flex: 1 1;
        color: white;
    }
`

const Toaster = () => {
    return <StyledToast>
        <ToastContainer
            autoClose={6000}
            position='bottom-center'
            transition={Bounce}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            hideProgressBar={false}
            newestOnTop={false}
        />
    </StyledToast>
}

export default Toaster