import { useEffect } from 'react'
import axios from 'axios'

export const PhasesLoader = (props) => {
  const { accountId, akteIdHash, setPhases, children, setPhasesIsLoading, selectedAkte } = props
  useEffect(() => {
    if(selectedAkte && selectedAkte.isEsyAkte === true){
      setPhasesIsLoading(true)
      axios
        .get(
          `${process.env.REACT_APP_API_URL_BASE}v2.0/e.consult.${accountId}/akten/${akteIdHash}/esyaktehistory`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                'storage_access_token_registered'
              )}`,
              'Content-Type': 'application/json',
              'X-Application-Key': process.env.REACT_APP_APPLICATION_KEY,
              'X-API-Key': process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((res) => {
          setPhases(res?.data)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setPhasesIsLoading(false)
        })
    }
  }, [akteIdHash])


  return <>{children}</>
}
