import { call, put, select } from '@redux-saga/core/effects'
import { api } from 'application/api'
import { putLastMessage } from 'application/redux/actions/common/all_akten'
import { updateDataOnMessage } from 'application/redux/actions/messages'
import { registeredMessageQueueSent, unregisterMessageQueue } from 'application/redux/actions/pages/akten/common'
import dayjs from 'dayjs'
import { replaceQueueMessageAkteSaga } from '../messages/replace_queue_message_akte_saga'
import { DIALOG } from 'application/constants'
import { getAutoResponderSetting } from 'application/redux/selectors'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { isMobile } from 'react-device-detect'

export function* sendSingleMessageAkteSaga(action) {
    yield call(api.gateway.pages.postMessage, action.metaData.accountId, action.metaData.akteIdHash, action.payload)
    yield put(putLastMessage(action.metaData.akteIdHash, action.payload.TextContent))
    yield put(registeredMessageQueueSent(action.idOnQueue))
    yield call(replaceQueueMessageAkteSaga, action)
    yield put(unregisterMessageQueue(action.idOnQueue))
    yield put(updateDataOnMessage(action.messageKey, { lastSync: dayjs() }))
    const autoResponderSetting = yield select(getAutoResponderSetting,action.metaData.accountId)
    if(autoResponderSetting.isActivated === true && autoResponderSetting.message)
        yield put(openDialogByName(DIALOG.NAME.AUTO_REPLY_MESSAGE, true, {message: autoResponderSetting.message},'', isMobile ? DIALOG.SCREEN.MOBILE : DIALOG.SCREEN.DESKTOP))
}