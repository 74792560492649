import WizardIndicator from 'application/components/controls/wizard_indicator'
import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'

const WizardStep = ({ wizardData = [], accountId }) => {
    return <FlexGrid grow={2} noWrap>
        {
            wizardData.map((w, i) => {
                return <WizardIndicator 
                    key={i}
                    step={w.step}
                    isDone={w.isDone}
                    inProgress={w.inProgress}
                    label={w.label}
                    accountId={accountId}
                />
            })
        }
    </FlexGrid>
}

export default WizardStep