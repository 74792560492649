import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { fileHelpers } from 'application/common'
import { shouldLoadAttachment } from './helpers'
import AttachmentThumbnail from './component'
import { accessTokenRegistered } from 'application/storage'

const AttachmentThumbnailHoc = ({ 
    files, 
    fileId, 
    fileName,
    onAttachmentClick, 
    onLoadAttachmentThumbnail,
    extendedData,
    showCaption = true, 
    isActive = false,
    accountId,
    marginRight = true,
    isSizeFixed = false,
    divHeight,
    divWidth,
    akteIdHash,
    parentId,
    creationDate,
    storage = accessTokenRegistered,
    thumbnailFirst = false,
}) => {
    //check if thumbnail is registered in redux store
    const thumbnailRegistered = files.thumbnails.filter(e => e.id === fileId).length > 0

    //check if thumbnail should be loaded depending on the file extension
    const shouldLoad = shouldLoadAttachment(fileHelpers.getFileExtension(fileName))

    //thumbnail data url
    const thumbnail = thumbnailRegistered ? files.thumbnails.filter(t => t.id === fileId)[0].data : ''

    //preloader display depends on this value
    const thumbnailIsLoading = !thumbnailRegistered || (thumbnailRegistered && !files.thumbnails.filter(e => e.id === fileId)[0].isLoaded)

    //fallback thumbnail display depends on this value
    const thumbnailFailed = thumbnailRegistered ? files.thumbnails.find(t => t.id === fileId).failed : false
    //request thumbnail if not registered
    useEffect(() => {
        !thumbnailRegistered &&
            shouldLoad &&
            onLoadAttachmentThumbnail(fileId, fileName, accountId, akteIdHash, parentId, storage)
    }, [accountId, akteIdHash, fileId, fileName, onLoadAttachmentThumbnail, parentId, shouldLoad, storage, thumbnailRegistered])

    const attachment = {
        fileId,
        accountId,
        hasThumbnail: shouldLoad,
        thumbnailIsLoading: thumbnailIsLoading,
        thumbnail,
        fileExtension: fileHelpers.getFileExtension(fileName),
        fileName,
        ...extendedData,
    }

    return <AttachmentThumbnail
        attachment={attachment} 
        onAttachmentClick={() => onAttachmentClick(attachment)} 
        showCaption={showCaption}
        isActive={isActive}
        marginRight={marginRight}
        isSizeFixed={isSizeFixed}
        divHeight={divHeight}
        divWidth={divWidth}
        creationDate={creationDate}
        thumbnailFailed={thumbnailFailed}
    />

}

AttachmentThumbnailHoc.propTypes = {
    thumbnails: PropTypes.shape({
        loaded: PropTypes.array
    }),
    attachmentId: PropTypes.number,
    attachmentName: PropTypes.string,
    onAttachmentClick: PropTypes.func,
    onLoadAttachmentThumbnail: PropTypes.func,
    showCaption: PropTypes.bool,
    isActive: PropTypes.bool
}

export default AttachmentThumbnailHoc