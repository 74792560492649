import { populateCommonAllAktenData } from 'application/redux/actions/common/all_akten'
import { getAllAkten } from 'application/redux/selectors'
import { put, select } from 'redux-saga/effects'
import { getUpdatedAktenPayloadSaga } from '../../messages/get_updated_akten_payload_saga'
import { isAkteExistSaga } from '../../messages/is_akte_exist_saga'
import { putFoldersMessageSaga } from '../../messages/put_folders_message_saga'
import { getSingleAkteSaga } from '../../pages/get_single_akte_saga'
import { setSelectedAkteSaga } from '../../pages/set_selected_akte_saga'
import { setSelectedFolderSaga } from '../../pages/set_selected_folder_saga'

export function* updateAktenDataSaga(action) {
    const curAkten = yield select(getAllAkten)
    const payload = yield getUpdatedAktenPayloadSaga()

    yield put(populateCommonAllAktenData(payload))
    // check existed akte
    if (curAkten.length > 0 && payload.length > 0 && curAkten.length > payload.length) {
        yield isAkteExistSaga({...action, curAkten})
    }

    // check new curAkten in folder or akte level -- check existed folder
    for (const p of payload) {
        const messageKey = p.idHash
        const akteDetail = curAkten.length > 0 && curAkten.find(m => m.idHash === p.idHash)
        const curFolderCount = akteDetail !== undefined ? akteDetail.folderCount : 0
        const isCurAkteSelected = p.idHash === action.params.akteIdHash
        const newAct = { accountId: p.accountId, akteIdHash: p.idHash, messageKey, params: action.params, folderId: action.folderId, messages: curAkten, isCurAkteSelected }
        if (p.totalUnreadMessageInFolder > 0 || p.folderCount !== curFolderCount) {
            yield putFoldersMessageSaga(newAct)
            yield setSelectedFolderSaga(newAct)
        }
        if (p.totalUnreadMessage > 0) {
            yield getSingleAkteSaga(newAct)
            if (isCurAkteSelected) {
                yield setSelectedAkteSaga(newAct)
            }
        }
    }
}