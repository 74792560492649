import React, { useEffect } from 'react'
import ServiceProviderComponent from './component'

const ServiceProviderEffects = (props) => {
    
    //const props
    const { allAktenLoaded, allAccountsLoaded, getAllAktenRequest, getAllAccountsRequest } = props
    
    //useEffect
    useEffect(() => {
        !allAccountsLoaded && getAllAccountsRequest()
    }, [allAccountsLoaded, getAllAccountsRequest])
    
    useEffect(() => {
        !allAktenLoaded && allAccountsLoaded && getAllAktenRequest()
    }, [allAccountsLoaded, allAktenLoaded, getAllAktenRequest])

    return <ServiceProviderComponent {...props} />
}

export default ServiceProviderEffects