import { fetchHandler, HTTP_METHOD, API_VERSION_ESYMANDANT, limiter } from 'application/api/request_builders'
import { fetchRequestBuilder } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const getCheckUsernameExist = (username, userId) => {

    const request = fetchRequestBuilder(`${API_VERSION_ESYMANDANT}/user/${userId}/checkUsername/${username}`, HTTP_METHOD.GET)

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}