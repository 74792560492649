import styled from 'styled-components'

export const Headline1 = styled.span`
  font-size: ${props => props.theme.fontSize.headline1};
  font-weight: ${props => props.theme.fontWeight.heavy};
  text-align: ${props => props.cssAlign !== undefined ? props.cssAlign : 'left'};
`

export const Headline2 = styled.span`
  font-size: ${props => props.theme.fontSize.headline2};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: ${props => props.cssAlign !== undefined ? props.cssAlign : 'left'};
`

export const Headline3 = styled.h3`
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  margin: 6px 0;
  width: 100%;
  z-index: ${props => props.zIndex !== undefined ? props.zIndex : 'unset'};
`

export const Paragraph = styled.div`
  padding: ${props => props.cssPadding !== undefined ? props.cssPadding : '0'};
  font-size: ${props => props.theme.fontSize.standard};
  font-weight: ${props => props.theme.fontWeight.standard};
  color: ${props => props.theme.color.color90};
  text-align: ${props => props.cssAlign !== undefined ? props.cssAlign : 'left'};
  margin: 0 0 8px;
`

export const ParagraphTitle = styled.p`
  font-size: ${props => props.theme.fontSize.standard};
  font-weight: ${props => props.theme.fontWeight.standardBold};
  margin: 0 0 16px;
`

export const File = styled.p`
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.isActive ? props.theme.color.color100 : props.theme.color.color90};
    cursor: ${props => props.isClickable ? 'pointer': 'default'};
    margin-top: 0;
    margin-bottom: 6px;
    background: ${props => props.isActive ? props.theme.color.color10 : props.theme.color.color5};
    padding: 6px 8px;
    font-weight: ${props => props.isActive ? props.theme.fontWeight.standardBold : props.theme.fontWeight.standardRegular};
`

export const ButtonDescription = styled.p`
  font-size: ${props => props.theme.fontSize.standard};
  font-weight: ${props => props.theme.fontWeight.standard};
  color: ${props => props.theme.color.color80};
  margin: 0 4px;
`

export const HighlightMark = styled.mark`
  display: inline;
  background: ${p => p.colorMark !== undefined ? `linear-gradient(
              180deg
              ,transparent 60%,${p.colorMark} 0)` : `linear-gradient(
              180deg
              ,transparent 60%,#e6fc88 0)`} ;
`

// export const LinkButton = styled.span`
//   margin: 0;
//   color: ${props => props.isActive ? props.theme.color.primary : props.theme.color.color90};
//   cursor: ${props => props.isActive ? 'pointer' : 'default'};
//   text-decoration: underline;
//   &:before {
//     content: "\\00BB\\00a0\";
//     display: inline;
//   }
// `
