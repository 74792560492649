import React from 'react'
import { Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { useRouteLocationForm } from '../context/use_route_location_form_context'

const RedirectSucceed = (props) => {
    const { sendSucceed, children, workflowKey } = props
    const { returnUrlOnSucced } = useRouteLocationForm()
    if (sendSucceed) {
        localStorage.removeItem(workflowKey)
        return <Navigate to={returnUrlOnSucced} />
    }
    
    return <Fragment>
        {children}
    </Fragment>
}

export default RedirectSucceed
