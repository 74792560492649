import { FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import * as Fragment from '../fragments'
import { getGreetings } from '../function'

const GreetingTopBarSection = () => {
    const { t } = useTranslation()
    const greeting = getGreetings()
    return !isTablet ?
        <FlexGridItem itemsCenter grow={2}>
            <Fragment.Greeting>
                {t(`${greeting}`)}
            </Fragment.Greeting>
        </FlexGridItem> : <React.Fragment />
}

export default GreetingTopBarSection
