import React from 'react'
import './index.css'
import { useTheme } from 'styled-components'
// import * as Fragment from './fragment'

const Preloader3D = () => {
    const theme = useTheme()

    return <React.Fragment>
        <div className="boxes">
            <div className="box">
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div></div>
            </div>
            <div className="box">
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div></div>
            </div>
            <div className="box">
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div></div>
            </div>
            <div className="box">
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div style={{ background: theme.color.preloaderColor }}></div>
                <div></div>
            </div>
        </div>
    </React.Fragment>
}

export default Preloader3D