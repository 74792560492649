import Countdown from 'application/components/controls/countdown'
import React from 'react'
import styled from 'styled-components'
const BannerContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    margin: 16px 0px;
    color: ${p => p.theme.color.color40};
    font-size: ${p => p.theme.fontSize.small};
    font-weight: ${p => p.theme.fontWeight.bold};
    border: 2px dashed ${p => p.borderColor ? p.borderColor : p.theme.color.color15};
    background-color: ${p => p.backgroundColor ? p.backgroundColor : p.theme.color.color5};
`
const CountdownBanner = ({infoText = '', timeGiven, onComplete}) => {
    return <BannerContainer>
        {infoText} {' '} <Countdown timeGiven={timeGiven} onComplete={onComplete} />
    </BannerContainer>
}

export default CountdownBanner
