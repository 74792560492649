import { getThumbnailById } from 'application/redux/actions/files'
import { connect } from 'react-redux'
import Thumbnail from './load_thumbnail_hoc'
// import {getThumbnailById} from 'application/redux/actions/attachments'

const mapStateToProps = (state) => {
    return {
        files: state.files,
        allThumbnailsRegistered: state.pages.akten.action.newFiles.succeed
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onAttachmentClick: (attachment) => {
            props.onAttachmentClick(attachment)
        },
        onLoadAttachmentThumbnail: (fileId, fileName, accountId, akteIdHash, parentId, storage) => {
            dispatch(getThumbnailById(fileId, fileName, { accountId, akteIdHash, parentId, storage }))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Thumbnail)