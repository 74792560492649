import FontIcon from 'application/components/controls/font_icon'
import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { isTablet } from 'react-device-detect'

const WizardIndicatorContainer = styled.div`
    display: flex;
    margin-right: ${isTablet ? '16px' : '40px'};
`

const StyledBox = styled.div`
    width: ${isTablet ? '40px' : '55px'} ;
    height: ${isTablet ? '40px' : '55px'};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: ${props => props.inProgress ? props.theme.color.color0 : props.theme.color.color100};
    background-color: ${props => props.inProgress ? props.theme.color.primary : props.theme.color.color10};
    font-size: ${props => isTablet ? props.theme.fontSize.medium : props.theme.fontSize.medium18};
    margin-right: ${isTablet ? '8px' : '16px'};
`
const StyledLabel = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: ${props => props.theme.fontFamily.secondary};
    color: ${props => props.inProgress ? props.theme.color.color90 : props.theme.color.color100};
    font-size: ${props => isTablet ? props.theme.fontSize.medium : props.theme.fontSize.headline3};
    font-weight: ${props => props.inProgress ? props.theme.fontWeight.medium : props.theme.fontWeight.light};
`

const WizardIndicatorComponent = ({ isDone, step, label, inProgress, theme, accountId }) => {
    const {t} = useTranslation()
    return <WizardIndicatorContainer>
        <FlexGrid noWrap>
            <StyledBox inProgress={inProgress} accountId={accountId}>
                {
                    !isDone ? step : <FontIcon 
                        icon='done'
                        fontSize={theme.fontSize.headline2}
                        color={theme.color.primary}
                    />
                }
            </StyledBox>
            <StyledLabel inProgress={inProgress}>
                {t(`${label}`)}
            </StyledLabel>
        </FlexGrid>
    </WizardIndicatorContainer>
}

export default withTheme(WizardIndicatorComponent)