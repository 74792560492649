import { DIALOG } from 'application/constants'
import { onAgbDatenschutztAccept } from 'application/redux/actions/common/agb_datenschutz'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import LawBanner from './component'

const mapStateToProps = state => ({
    agbsAndDatenschutzAccepted: state.common.agbDatenschutz.isAccepted,
    registeredDatenshutz: state.common.agbDatenschutz.registeredDatenshutz,
    checkedAccountId: state.common.agbDatenschutz.accountId
})

const mapDispatchToProps = dispatch => ({
    onAccept: (condition, accountId) => {
        dispatch(onAgbDatenschutztAccept(condition, accountId))
    },
    onOpenAccountStatement: (statement, title) => dispatch(openDialogByName(DIALOG.NAME.DISPLAY_ACCOUNT_STATEMENT, true, { statement }, title)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LawBanner)