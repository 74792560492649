import React, { useRef, useState } from 'react'
import { ForgotCredentialsLayout } from '../_common'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import ActionButton from 'application/components/controls/button/action_button/component'
import { Form, TextInputPassword, TextInputPasswordToolTipChecker } from 'application/components/controls/form_elements'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import RedirectPasswordDone from './reset_password_done_redirect'
import { ErrorLabel } from 'application/components/fragments/error_information'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import { passwordStrengthScore } from 'application/common/validation_methods'
import { useParams } from 'react-router-dom'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const ResetPasswordComponent = (props) => {

    const { onSendNewPassword, newPasswordRequest, newPasswordFailed, errorMessage } = props
    let params = useParams()
    const { activePlugin } = usePluginsContext()

    const { t } = useTranslation()

    const defaultValues = {
        password: '',
        passwordConfirmation: ''
    }

    const { getValues, watch, handleSubmit, register, formState: { errors } } = useForm(defaultValues)

    const validationNewPass = {
        notEmpty: validationMethods.notEmpty,
        passwordStrength: passwordStrengthScore,
        passwordMinLength: validationMethods.passwordMinLength,
    }
    const [newPass, setNewPass] = useState('')

    const password = useRef({})
    password.current = watch('password', '')

    const onSubmitSuccess = () => {
        onSendNewPassword(params.passwordKey, getValues().password)
    }
    const handleChangeNewPass = (e) => {
        setNewPass(e.target.value)
    }

    return <RedirectPasswordDone {...props}>
        <ForgotCredentialsLayout
            needDesc={true}
            headline={t('ResetPassword')}
        >
            <React.Fragment>
                {
                    newPasswordRequest ? <PreloaderSpin /> : <React.Fragment>

                        <Form onSubmit={handleSubmit(onSubmitSuccess)}>
                            <TextInputPasswordToolTipChecker
                                placeholder={t('NewPasswordPlaceholder')}
                                name='password'
                                register={register}
                                validate={validationNewPass}
                                error={errors.password}
                                showMarkLabel={false}
                                pushBottom={true}
                                onChange={handleChangeNewPass}
                                password={newPass}
                            />
                            <TextInputPassword
                                placeholder={t('NewPasswordConfirmationPlaceholder')}
                                name='passwordConfirmation'
                                register={register}
                                validate={(value) => value === password.current || 'The passwords do not match'}
                                error={errors.passwordConfirmation}
                                showMarkLabel={false}
                            />
                            {
                                newPasswordFailed && <ErrorLabel>
                                    {errorMessage}
                                </ErrorLabel>
                            }
                            <input
                                style={{ display: 'none' }}
                                type='submit'
                                value='username'
                            />
                        </Form>
                        <PushBottom24 />
                        <ActionButton
                            secondary={activePlugin ? false : true}
                            buttonText={t('SubmitButtonText')}
                            onButtonClick={handleSubmit(onSubmitSuccess)}
                        />
                    </React.Fragment>
                }
            </React.Fragment>
        </ForgotCredentialsLayout>
    </RedirectPasswordDone>
}

export default ResetPasswordComponent