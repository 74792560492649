import { Form, SimpleUploader, TextInputPassword, TextInputPasswordToolTipChecker } from 'application/components/controls/form_elements'
import GravatarWithInitial from 'application/components/controls/gravatar'
import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem, PushBottom10 } from 'application/components/fragments/grid'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { textHelpers, validationMethods } from 'application/common'
import { PushBottom24 } from 'application/components/pages/internal/dashboard/fragments'
import ActionButton from 'application/components/controls/button/action_button/component'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { useWindowSize } from 'application/components/hooks'
import { passwordStrengthScore } from 'application/common/validation_methods'
import { isTablet } from 'react-device-detect'
import styled from 'styled-components'

const StyledChangeProfilePictureWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
const StyledUserEmailDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
`

export const AccountSettingForm = (props) => {

    const { profilePicture, user, setProfilePicture, userId, sendNewPassword, theme, accountId, sendNewPasswordRequest, allAccounts } = props
    const widthScreen = useWindowSize().width
    const firstName = user.firstName
    const lastName = user.lastName
    const initials = textHelpers.getInitials(firstName, lastName)
    const accountPublicInfo = allAccounts[0]

    const { t } = useTranslation()

    const defaultValues = {
        newpassword: '',
        oldpassword: '',
        newpasswordconfirmation: ''
    }

    const { getValues, watch, handleSubmit, register, formState: { errors } } = useForm(defaultValues)

    const validation = {
        notEmptyNewPassword: validationMethods.notEmptyNewPassword,
        passwordStrength: passwordStrengthScore,
        includesLowerCaseCharacter: validationMethods.passwordMinLength,
    }

    const [newPass, setNewPass] = useState('')

    const newpassword = useRef({})
    newpassword.current = watch('newpassword', '')

    const code1Message = t('PasswordUpdated')
    const code2Message = t('PasswordUpdatedFailed')
    const code3Message = t('PasswordUpdatedFailed2')

    const handleResponseMessage = {
        code1: `🔐 ${code1Message}`,
        code2: `🔥 ${code2Message}`,
        code3: `🔥 ${code3Message}`,
    }

    const onSubmitSuccess = () => {
        sendNewPassword(userId, getValues(), handleResponseMessage)
    }

    const handleChangeNewPass = (e) => {
        setNewPass(e.target.value)
    }
    return <React.Fragment>
        <FlexGrid directionColumn>
            <FlexGridItem itemsCenter justifyCenter>
                <StyledChangeProfilePictureWrapper>
                    {
                        accountPublicInfo && (
                            profilePicture.isLoaded && profilePicture.has ? <ImagePlaceHolder toCenter={true} isCircle={true} large={isTablet ? '300px' : widthScreen > theme.breakpoint.laptopL ? '320px' : widthScreen > theme.breakpoint.laptopM ? '250px' : '230px'} height={isTablet ? '300px' : widthScreen > theme.breakpoint.laptopL ? '320px' : widthScreen > theme.breakpoint.laptopM ? '250px' : '230px'} source={profilePicture.data} /> :
                                <GravatarWithInitial backgroundcolor={theme.color.gravatar || theme.color.primary} initial={initials} large={isTablet ? '300' : widthScreen > theme.breakpoint.laptopL ? '320' : widthScreen > theme.breakpoint.laptopM ? '250' : '230'} />
                        )
                    }
                    <StyledUserEmailDiv>
                        {user.email}
                    </StyledUserEmailDiv>
                    <SimpleUploader 
                        label={t('Set profile picture')}
                        iconKey='edit'
                        onFileAdded={(files) => setProfilePicture(files, userId, accountId)}
                        pp={true}
                    />
                </StyledChangeProfilePictureWrapper>
            </FlexGridItem>
            <FlexGridItem>
                {
                    sendNewPasswordRequest ? <FlexGrid justifyCenter itemsCenter> <PreloaderSpin /></FlexGrid> : <FlexGrid directionColumn>
                        <Form onSubmit={handleSubmit(onSubmitSuccess)}>
                            <TextInputPassword
                                placeholder={t('ExistingPassword')}
                                name='oldpassword'
                                register={register}
                                validate={{ notEmptyExistingPassword: validationMethods.notEmptyExistingPassword }}
                                error={errors.oldpassword}
                                showMarkLabel={false}
                            />
                            <PushBottom10 />
                            <TextInputPasswordToolTipChecker
                                placeholder={t('New Password')}
                                name='newpassword'
                                register={register}
                                validate={validation}
                                error={errors.newpassword}
                                showMarkLabel={false}
                                onChange={handleChangeNewPass}
                                password={newPass}
                            />
                            <PushBottom10 />
                            <TextInputPassword
                                placeholder={t('Password confirmation')}
                                name='newpasswordconfirmation'
                                register={register}
                                error={errors.newpasswordconfirmation}
                                validate={(value) => value === newpassword.current || t('PasswordDontMatch')}
                                showMarkLabel={false}
                            />
                            <PushBottom10 />
                            <input type='submit' style={{ display: 'none' }} />
                        </Form>
                        {accountPublicInfo && (
                            <ActionButton
                                backgroundColor={theme.color.primary}
                                buttonText={t('Save password')}
                                onButtonClick={handleSubmit(onSubmitSuccess)}
                            />
                        )}
                        <PushBottom24 />
                    </FlexGrid>
                }
            </FlexGridItem>
        </FlexGrid>
    </React.Fragment>
}