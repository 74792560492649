import styled from 'styled-components'

export const LawBannerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding: 20px;
    border: 2px dashed ${p => p.borderColor ? p.borderColor : p.theme.color.color15};
    background-color: ${p => p.backgroundColor ? p.backgroundColor : p.theme.color.color5};
`

export const LawBannerText = styled.p`
    color: ${p => p.theme.color.color40};
    font-weight: ${p => p.theme.fontWeight.medium};
    font-size: ${p => p.theme.fontSize.standard};
    margin: 0px;
`

export const LawBannerLink = styled.span`
    color: ${p => p.theme.color.primary};
    font-size: ${p => p.theme.fontSize.standard};
    cursor: pointer;
`

export const LawBannerCompanyName = styled.span`
    font-weight: ${p => p.theme.fontWeight.heavy};
    font-size: ${p => p.theme.fontSize.standard};
    /* color: ${p => p.theme.color.color70}; */
`