import ACTIONS from 'application/constants'

export const sendFormSupportRequest = (accountId, payload, errorMessage, successMessage) => ({
    type: ACTIONS.PAGES_SETTINGS_ABOUT_FORM_REQUEST,
    accountId,
    payload,
    errorMessage,
    successMessage
})

export const sendFormSupportSucceed = () => ({
    type: ACTIONS.PAGES_SETTINGS_ABOUT_FORM_SUCCEED,
})

export const sendFormSupportFailed = () => ({
    type: ACTIONS.PAGES_SETTINGS_ABOUT_FORM_FAILED,
})

export const setNewPasswordRequest = (userId, payload, handleResponseMessage) => ({
    type: ACTIONS.PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_REQUEST,
    userId,
    payload,
    handleResponseMessage
})

export const setNewPasswordSucceed = () => ({
    type: ACTIONS.PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_SUCCEED,
})

export const setNewPasswordFailed = () => ({
    type: ACTIONS.PAGES_SETTINGS_ACCOUNT_SET_NEW_PASSWORD_FAILED,
})