import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { FlexGrid } from 'application/components/fragments/grid'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import {AccountsCardEasyMode} from 'application/components/building_blocks/service_provider_card'
import { useTranslation } from 'react-i18next'

const SectionAccountsMobile = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 24px 0px 25px 0px;
    background-color: ${p => p.theme.color.color15};
    border-radius: 20px;
    padding: 20px 10px 20px 20px;
    min-height: 20hv;
`

const Headline = styled.h2`
    color: ${p => p.theme.color.color100};
    font-size: ${p => p.theme.fontSize.xLarge};
    font-weight: ${p => p.theme.fontWeight.bold};
`

const ContentWrapper = styled.div`
    display: flex;
    width: 94%;
    overflow-x: scroll;
    padding-left: 15px;
    gap: 15px;
`

const SectionAccountsMobileComponent = ({ allAccountsLoaded, allAccounts }) => {
	let accountsnotActivated = allAccounts?.filter(a => !a.isActivated)
	let accountsActivated = allAccounts?.filter(a => a.isActivated)
	const {t} = useTranslation()
    const theme = useTheme()
    return <SectionAccountsMobile>
        <Headline>
            {t('Service Provider')}
        </Headline>
		<ContentWrapper>
			{
				allAccountsLoaded && accountsnotActivated.map(a => {
                    let isUniqueKey = a.uniqueAccountKey !== null
                    let uniqueAccountKey = isUniqueKey ? a.uniqueAccountKey : a.id
                    return <AccountsCardEasyMode 
                        key={a.id}
                        mainColor={a.mainColor}
                        uniqueAccountKey={uniqueAccountKey}
                        address={a.address}
                        website={a.website}
                        categories={a.categories}
                        isActivated={a.isActivated}
                        accountId={a.id}
						accountName={a.name}
						esyThingAgb={a.esyThingAgb}
						esyThingDatenschutz={a.esyThingDatenschutz}
                    />
                })
			}
            {
                allAccountsLoaded ? accountsActivated.map(a => {
                    let isUniqueKey = a.uniqueAccountKey !== null
                    let uniqueAccountKey = isUniqueKey ? a.uniqueAccountKey : a.id
                    return <AccountsCardEasyMode 
                        key={a.id}
                        mainColor={a.mainColor}
                        uniqueAccountKey={uniqueAccountKey}
                        address={a.address}
                        website={a.website}
                        categories={a.categories}
                        isActivated={a.isActivated}
                        accountId={a.id}
                        accountName={a.name}
                    />
                }): <FlexGrid itemsCenter>
                    <PreloaderSpin />
                </FlexGrid>
            }
        </ContentWrapper>
    </SectionAccountsMobile>
}

SectionAccountsMobileComponent.propTypes = {
    allAccountsLoaded: PropTypes.bool,
    allAccounts: PropTypes.array,
}

export default SectionAccountsMobileComponent
