import React from 'react'
import Dialog from 'application/components/building_blocks/dialog'
import { dialogComponentsMapper, dialogSizeMapper } from './mappers'

const Dialogs = ({dialogs, galleryVisible, waitingScreen, onCloseDialog}) => {
    return <React.Fragment>
        {
            dialogs.registeredDialogs.map((dialog, index) => {
                const dialogSize = dialogSizeMapper(dialog.name)
                const components = dialogComponentsMapper(dialog)
                const DialogContent = components.MainComponent
                const iconKey = components.iconKey !== undefined && !dialog.payload.editMode ? components.iconKey : null
                const color = components.color !== undefined && !dialog.payload.editMode ? components.color : null
                return <Dialog 
                    isHeaderWithBackgroundColor={dialog.payload.displayStatusMode !== undefined && !dialog.payload.editMode}
                    iconKey={iconKey}
                    color={color}
                    DialogSubHeader={components.SubHeader === undefined ? null : components.SubHeader}
                    key={index}
                    title={dialog.title}
                    dialogIndex={index}
                    onAbort={onCloseDialog}
                    dialogSize={dialogSize}
                    dialogName={dialog.name}
                    galleryVisible={galleryVisible}
                    dataLoaded={dialog.isLoaded}
                    AdditionalHeader={components.AdditionalHeader}
                    isHighest={dialogs.registeredDialogs.length <= index + 1}
                    dialog={dialog}
                    waitingScreen={waitingScreen}
                    dialogScreen={dialog.screen}
                    disableHeader={components.disableHeader ? components.disableHeader : false}
                    putCenter={components.putCenter ? components.putCenter : false}
                    noPadding={components.noPadding ? components.noPadding : false}
                    rounded={components.rounded ? components.rounded : false}
                >
                    <DialogContent />
                </Dialog>
            })
        }
    </React.Fragment>
}

export default Dialogs