import { useTranslation } from 'react-i18next'
import theme from 'plugins/customers/myk/theme'
import { StyledButtonInput } from 'application/components/pages/external/mobile_assistant/fragments'

export function LoginButtonSubmit({handleSubmit, onSubmitSuccess}) {
  const { t } = useTranslation()

  return (
    <StyledButtonInput>
      <input
        style={{
          background: theme.color.primary,
          fontFamily: 'Verdana',
          borderRadius: '6px',
          border: 'none',
        }}
        type='button'
        value={t('SubmitButtonText')}
        onClick={handleSubmit(onSubmitSuccess)}
      />
    </StyledButtonInput>
  )
}
