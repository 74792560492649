import defaultStore from '../default_store'
import {ACTIONS} from 'application/constants'

const notificationReducer = (state = defaultStore.notifications, action) => {
    switch (action.type) {     
    case ACTIONS.NOTIFICATIONS_SHOW_TOAST:
        return {
            ...state,
            toast: {
                message: action.message,
                messageType: action.messageType
            }
        }
    default:
        return state
    }
}

export default notificationReducer