import React from 'react'
import styled, { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { textHelpers } from 'application/common'
import IconLink from '../icon/icon_link'

const FileNameWrapper = styled.div`
    display: flex;
    padding: 4px;
    margin-bottom: 4px;
    margin-right: 4px;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.color.color20};
    border-radius: 5px;
    height: 24px;
`

const LabelWrapper = styled.span`
    display: flex;
    flex: 2 0;
    font-size: ${props => props.theme.fontSize.small};
    margin-right: 8px;
`

const FileName = ({ fileName, clearFile, theme }) => {
    const { t } = useTranslation()
    const truncateFileName = textHelpers.truncateMiddleText(fileName, 20)
    return <React.Fragment>
        <FileNameWrapper>
            <LabelWrapper>
                {truncateFileName}
            </LabelWrapper>
            <IconLink title={t('Delete file')} iconKey='clear' onClick={clearFile} iconSize={theme.fontSize.small} width='12px' />
        </FileNameWrapper>
    </React.Fragment>
}

export default withTheme(FileName)