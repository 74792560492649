import ActionButton from 'application/components/controls/button/action_button/component'
import { ROUTES } from 'application/constants'
import { useTranslation } from 'react-i18next'


export function RequestCredentialsMailSentImage(){
    const { t } = useTranslation()

    return <>
        <ActionButton
            secondary={false}
            buttonText={t('ReturnLoginPage')}
            linkTo={ROUTES.LOGIN}
        />
    </>
}