import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import { useEscapeKey, useClickOutside } from 'application/components/hooks'
import IconButton from 'application/components/controls/icon/icon_button/component'
import FontIcon from 'application/components/controls/font_icon'
import { useTranslation } from 'react-i18next'

const StyledContextMenuItemComponent = styled.li`
    padding: 4px 8px;
    border-radius: 10px;
    background:  ${props => props.theme.color.color20};
    color: ${props => props.theme.color.color0};
    min-width: 210px;
    height: 30px;
    cursor: pointer;
    display: inline-block;
    display: flex;
    flex-direction: row;
    margin-bottom: 1px;
    justify-content: space-between;
    z-index: 2003;
    &:hover{
        background:  ${props => props.theme.color.color30};
    }
    font-size: ${props => props.theme.fontSize.small};
`

const StyledBox = styled.span`
    align-self: center;
    padding-right: 10px;
    font-size: 15px;
    span {
        color: ${p => p.theme.color.color70} !important;
    }
`

const StyledContextSubMenuList = styled.ul`
    position: absolute;
    min-width: 140px;
    left: 70px;
    list-style-type: none;
    font-size: 14px;
`
const StyledSubMenuContent = styled.li`
	 min-width: 140px;
    height: 20px;
    background: #4d4d4d;
    color: #ffffff;
    padding: 8px;
    border-radius: 1px;
    margin-bottom: 1px;
    position: relative;
    top: 78px;
`

const StyledSubMenu = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    i {
        color: ${props => props.theme.color.color0}
    }
`

const StyledMainMenu = styled.div`
    display: flex;
    flex-direction: row;
`

const ContextMenuItemComponent = ({
    onListElementClick,
    iconKey,
    children,
    className = '',
    title = '',
    submenu = null,
    theme,
    onMenuOpen = () => { },
    onMenuClose = () => { }
}) => {
    const { t } = useTranslation()
    const [menuOpen, setMenuOpen] = useState(false)

    const closeMenu = () => {
        setMenuOpen(false)
        onMenuClose()
    }

    const handleMenu = () => {
        if (menuOpen) {
            closeMenu()
        } else {
            setMenuOpen(true)
            onMenuOpen()
        }
    }

    const handleParentSubMenu = () => {
        if (menuOpen) {
            closeMenu()
        }
    }

    const ref = useRef()
    useClickOutside(ref, closeMenu)
    useEscapeKey(closeMenu)
    return <StyledContextMenuItemComponent onClick={onListElementClick} className={className} title={title} >
        <StyledMainMenu>
            <StyledBox>
                <FontIcon fontSize='14px' icon={iconKey} isClickable={true} color='white' />
            </StyledBox>
            <StyledBox>
                {children}
            </StyledBox>
        </StyledMainMenu>
        {
            submenu !== null && <StyledSubMenu ref={ref} onClick={handleParentSubMenu}>
                <IconButton
                    onButtonClick={handleMenu}
                    iconKey="keyboard_arrow_right"
                    width="24px"
                    height="24px"
                    iconSize="16px"
                    color={theme.color.color90}
                    hoverBackground="none"
                    activeBackground="none"
                />
                {
                    menuOpen && <StyledContextSubMenuList>
                        {submenu.map((s, index) => {
                            return <StyledSubMenuContent key={index}>
                                {t(s.label)}
                            </StyledSubMenuContent>
                        })}
                    </StyledContextSubMenuList>
                }
            </StyledSubMenu>
        }

    </StyledContextMenuItemComponent>
}

ContextMenuItemComponent.propTypes = {
    onListElementClick: PropTypes.func.isRequired,
    iconKey: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    title: PropTypes.string
}

export default withTheme(ContextMenuItemComponent)