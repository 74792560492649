import { getCommonAllAccountsRequest } from 'application/redux/actions/common/all_accounts'
import { getCommonAllAktenRequest } from 'application/redux/actions/common/all_akten'
import { closeSelectedAccountDetails, setSelectedAccountDetails } from 'application/redux/actions/common/account_details'
import { connect } from 'react-redux'
import Dashboard from './component'
import { closeAktenPopupSidebar, closeProviderPopupSidebar, openProviderPopupSidebar } from 'application/redux/actions/sidebars'

const mapStateToProps = state => {
    return {
        allAktenLoaded: state.common.allAkten.isLoaded,
        allAkten: state.common.allAkten.payload,
        allAccountsLoaded: state.common.allAccounts.isLoaded,
        allAccounts: state.common.allAccounts.payload,
        selectedAccount: state.common.accountDetails.selectedAccount,
        accountDetails: state.common.accountDetails,
        auth: state.auth,
        user: state.auth.common.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllAkten: () => dispatch(getCommonAllAktenRequest()),
        getAllAccounts: () => dispatch(getCommonAllAccountsRequest()),
        setSelectedAccountDetails: (details) => dispatch(setSelectedAccountDetails(details)),
        closeSelectedAccountDetails: () => dispatch(closeSelectedAccountDetails()),
        handleCloseAktenPopupSidebar: () => dispatch(closeAktenPopupSidebar()),
        handleCloseProviderPopupSidebar: () => dispatch(closeProviderPopupSidebar()),
        handleOpenProviderPopupSidebar: () => dispatch(openProviderPopupSidebar())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)