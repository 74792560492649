import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledAkteCard = styled(NavLink)`
    display: flex;
    text-decoration: none;
    padding: 20px;
    background-color: ${props => props.secondarycolor === '' || props.secondarycolor === null ? props.theme.color.secondaryAkte : props.secondarycolor};
    border-radius: 30px;
    max-width: 100%;
    margin-bottom: 8px;
`

export const StyledAccountNameAkteCard = styled.span`
    font-family: ${props => props.theme.fontFamily.secondary};
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.mainColor && props.mainColor !== '' && props.mainColor !== null ? props.mainColor : props.theme.color.color100};
    margin-bottom: 4px;
    max-width: 70%;
`

export const StyledCreatorNameAkteCard = styled.span`
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.regular};
    color: ${props => props.mainColor && props.mainColor !== '' ? props.mainColor : props.theme.color.color100};
    margin-bottom: 4px;
`

export const StyledHeaderAkteCard = styled.span`
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.regular};
    color: ${props => props.theme.color.color90};
    margin-bottom: 4px;
    max-width: 70%;
`

export const StyledLastMessageAkteCard = styled.span`
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.light};
    color: ${props => props.theme.color.color80};
    margin-bottom: 4px;
    max-width: 70%;
`

export const StyledDateAkteCard = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.color90};
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

// EASY MODE

export const StyledAkteEasyModeWrapper = styled(NavLink)`
    display: flex;
    text-decoration: none;
    align-items: center;
    border-radius: 25px;
    background-color: ${p => p.theme.color.color0};
    border: 2px solid ${p => p.theme.color.color45};
    padding: 15px;
    gap: 15px;
    margin-bottom: 8px;
`

export const StyledColEasyMode = styled.div`
    flex: 0;
`

export const StyledContentEasyMode = styled.div`
    display: flex;
    flex: 2 0;
    flex-flow: column nowrap;
    gap: 2px;
    align-items: ${p => p.alignItems ? p.alignItems : 'flex-start'};
`

export const CategoriesEasyModeWrapper = styled.div`
    display: flex;
`