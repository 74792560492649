import { SettingList } from 'application/components/building_blocks/menu_list'
import { FlexDividerVertical } from 'application/components/fragments/flex_divider'
import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import InternalLayout from '../../_layout'
import * as Fragment from './content/common/fragments'
import { getContentSettings } from './mappers'
import { useTranslation } from 'react-i18next'
import { getUnreadMessage } from '../_common/function'
import { useParams } from 'react-router-dom'
import WebHeaderLogoEffect from 'application/common/web_header_logo'

const Wrapper = styled.div`
    width: 100%;
    padding-left: 15px;
    padding-bottom: 40px;
`

const ListWrapper = styled.div`
	display: none;
	@media (min-width: ${p => p.theme.breakpoint.sm}) {
		display: flex;
		flex-flow: column nowrap;
		width: 25%;
		max-width: 25%;
	}
`

const ContentWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	@media (min-width: ${p => p.theme.breakpoint.sm}) {
		width: 70%;
		max-width: 70%;
	}
`

const SettingsComponent = (props) => {
    const { allAktenLoaded, allAkten, allAccounts, auth } = props
    let params = useParams()
    const { selectedSetting } = params
    const ContentComponent = getContentSettings(selectedSetting)
    const { t } = useTranslation()
    const unreadMessages = allAkten !== [] && getUnreadMessage(allAkten)
    const theme = useTheme()
    const newTitle = unreadMessages > 0 ? `(${unreadMessages}) new messages - ${theme.companyName}` : theme.companyName
    const accountPublicInfo = allAccounts[0]
    return (
        <WebHeaderLogoEffect accountPublicInfo={accountPublicInfo}>
            <InternalLayout withDiv100={false} newMessageTitle={newTitle} paddingTop={false} paddingBottom={false} paddingLeft={false} paddingRight={false} sidebarLoaded={allAktenLoaded}>
                <FlexGrid height='100%'>
                    <ListWrapper>
                        <Wrapper>
                            <Fragment.TitleSetings>
                                {t('Settings')}
                            </Fragment.TitleSetings>
                        </Wrapper>
                        <SettingList accountPublicInfo={accountPublicInfo} location={selectedSetting} />
                    </ListWrapper>
                    <FlexDividerVertical />
                    <ContentWrapper>
                        <ContentComponent />
                    </ContentWrapper>
                </FlexGrid>
            </InternalLayout>
        </WebHeaderLogoEffect>
    )
}

export default SettingsComponent