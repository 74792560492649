import { DIALOG } from 'application/constants'
import { closeSelectedAccountDetails } from 'application/redux/actions/common/account_details'
import { requestDataAccountPublicInfoInternalAssets } from 'application/redux/actions/common/account_public_info_internal_assets'
import { downloadAgbAccount } from 'application/redux/actions/common/agb_datenschutz'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { refreshGetFormElements, refreshReturnUrlForm, refreshSendForm, setReturnUrlForm } from 'application/redux/actions/form'
import { setDialogVisibleProviderSidebar } from 'application/redux/actions/sidebars/provider_siderbar'
import { isTablet } from 'react-device-detect'
import { connect } from 'react-redux'
import ServiceProviderDetailSidebarComponent from './component'


const mapStateToProps = (state, props) => {
    const accountId = props.selectedAccount.id
    const accountPublicInfoInternal = state.common.accountPublicInfoInternalAssets.filter(a => a.accountId === accountId).length > 0 ?
        state.common.accountPublicInfoInternalAssets.find(a => a.accountId === accountId) : null
    return {
        allAccountsLoaded: state.common.allAccounts.isLoaded,
        auth: state.auth,
        registeredDatenshutz: state.common.agbDatenschutz.registeredDatenshutz,
        accountId,
        accountPublicInfoInternal,
        sendFormSucceed: state.form.send.succeed,
        formReturnUrl: state.form.returnUrl,
        formLoaded: state.form.succeed,
    }
}

const mapDispatchToProps = dispatch => ({
    closeServiceProvideDetailSidebar: () => dispatch(closeSelectedAccountDetails()),
    onDownloadAgb: (accountId, accountName) => dispatch(downloadAgbAccount(accountId, accountName)),
    onDisplayStatement: (statement, title) => {
        dispatch(openDialogByName(DIALOG.NAME.DISPLAY_ACCOUNT_STATEMENT, true, { statement }, title))
        isTablet && dispatch(setDialogVisibleProviderSidebar(true))
    },
    getPublicInfoAccountInternalAssets: (accountId) => dispatch(requestDataAccountPublicInfoInternalAssets(accountId)),
    setFormReturnUrl: (url) => dispatch(setReturnUrlForm(url)),
    refreshForm: () => {
        dispatch(refreshReturnUrlForm())
        dispatch(refreshSendForm())
        dispatch(refreshGetFormElements())
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceProviderDetailSidebarComponent)