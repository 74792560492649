import { downloadFile, getDownloadUrl } from 'application/api/helpers'
import { fetchHandler, HTTP_METHOD, limiter, API_VERSION_ESYMANDANT } from 'application/api/request_builders'
import { fetchRequestBuilder } from 'application/api/request_builders'
import { handleApiFilestreamResponse } from 'application/api/response_handlers'

export const downloadAkteAsZip = (accountId, akteIdHash, aktenkurzbezeichnung, accountName) => {
    const request = fetchRequestBuilder(`${API_VERSION_ESYMANDANT}/e.consult.${accountId}/akten/${akteIdHash}/download`, HTTP_METHOD.GET)

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiFilestreamResponse)
        .then(getDownloadUrl)
        .then(downloadUrl => {
            downloadFile(downloadUrl, 'Documents-' + aktenkurzbezeichnung + '-' + accountName +'.zip')
        })
}