import { ROUTE_PARAMETERS } from 'application/constants'
import { isTablet } from 'react-device-detect'
import { getUnreadMessage } from '../_common/function'
import { isEmpty } from 'lodash'

export const aktenExtraProps = (query, params, theme, allAkten, sidebarVisible) => {
    const isAktenSelected = !isEmpty(params)
    const akteIdHash = isAktenSelected && params.akteIdHash
    const folderId = query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID) === '' ? '' : query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID)
    const favoriteId = folderId === '' ? query.getStringParam(ROUTE_PARAMETERS.AKTEN.FAVORITE_MESSAGE_ID) === '' ? '' : query.getStringParam(ROUTE_PARAMETERS.AKTEN.FAVORITE_MESSAGE_ID)
        : query.getStringParam(ROUTE_PARAMETERS.AKTEN.FAVORITE_MESSAGE_ID_ON_FOLDER) === '' ? '' : query.getStringParam(ROUTE_PARAMETERS.AKTEN.FAVORITE_MESSAGE_ID_ON_FOLDER)
    const parentId = folderId === '' ? akteIdHash : folderId
    const messageKey = folderId === '' ?  akteIdHash : folderId

    const widthMessageList = isTablet ? '30%' : sidebarVisible ? '15%' : '25%'
    const widthMessageDetails = isTablet ? '65%' : sidebarVisible ? '50%' : '60%'
    const unreadMessages = allAkten !== [] && getUnreadMessage(allAkten)
    const newTitle = unreadMessages > 0 ? `(${unreadMessages}) new messages - ${theme.companyName}` : theme.companyName

    return {
        favoriteId,
        parentId,
        messageKey,
        widthMessageList,
        widthMessageDetails,
        newTitle,
        folderId
    }
}