import ACTIONS from 'application/constants'
import defaultStore from '../default_store'

const contactInfoReducer = (state = defaultStore.sidebars.contactInfo, action) => {
    switch (action.type) {
    case ACTIONS.SIDEBAR_OPEN_CONTACT_INFO:
        return {
            ...state,
            visible: true,
            payload: action.payload
        }
    case ACTIONS.SIDEBAR_CLOSE_CONTACT_INFO:
        return {
            ...state,
            visible: false
        }
    default:
        return state
    }
}

export default contactInfoReducer