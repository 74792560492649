import Indicator from 'application/components/controls/indicator'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { withTheme } from 'styled-components'
import * as Fragment from './fragments'
import { ROUTES } from 'application/constants'
import CreatorProfilePicture from '../creator_profiile_picture'
import { useTranslation } from 'react-i18next'
import { textHelpers } from 'application/common'
import DateComponent from 'application/components/controls/date'
import Time from 'application/components/controls/time'

const AkteCardComponent = (props) => {
    const {
        accountName,
        creatorFirstName,
        creatorLastName,
        headline,
        totalUnreadMessage,
        lastMessageDate,
        lastMessage,
        mainColor,
        secondaryColor,
        accountId,
        akteIdHash,
        setSelectedAkte,
        totalUnreadMessageInFolder,
    } = props
    const { t } = useTranslation()
    const latestMessage = lastMessage === null ? '' : lastMessage
    const totalUnread = totalUnreadMessage + totalUnreadMessageInFolder
    const truncateAccountname = textHelpers.truncateEndText(accountName, 20)
    const truncateHeadline = textHelpers.truncateEndText(headline, 15)
    const truncateLatestMessage = textHelpers.truncateEndText(latestMessage, 30)
    return <Fragment.StyledAkteCard onClick={setSelectedAkte} to={ROUTES.AKTEN + '/' + akteIdHash + '/accounts/' + accountId} secondarycolor={secondaryColor}>
        <FlexGridItem grow={0.1} shrink={0}>
            <CreatorProfilePicture 
                large={'70'}
                borderRadius='50%'
                {...props}
            />
        </FlexGridItem>
        <FlexGridItem width='40%'>
            <FlexGrid directionColumn>
                <Fragment.StyledAccountNameAkteCard mainColor={mainColor}>
                    {truncateAccountname}
                </Fragment.StyledAccountNameAkteCard>
                <Fragment.StyledCreatorNameAkteCard mainColor={mainColor}>
                    {creatorFirstName} {creatorLastName}
                </Fragment.StyledCreatorNameAkteCard>
                <Fragment.StyledHeaderAkteCard>
                    {truncateHeadline}
                </Fragment.StyledHeaderAkteCard>
                <Fragment.StyledLastMessageAkteCard>
                    {truncateLatestMessage}
                </Fragment.StyledLastMessageAkteCard>
            </FlexGrid>
        </FlexGridItem>
        <FlexGridItem grow={0}>
            <FlexGrid directionColumn>
                <Fragment.StyledDateAkteCard>
                    <DateComponent date={lastMessageDate} todayString={t('Today')} />, <Time date={lastMessageDate} timeHourSuffix=''/>
                </Fragment.StyledDateAkteCard>
                <FlexGridItem justifyFlexEnd>
                    {
                        totalUnread !== 0 &&
                            <Indicator
                                isNotification={true}
                                text={totalUnread}
                            />
                    }
                </FlexGridItem>
            </FlexGrid>
        </FlexGridItem>
    </Fragment.StyledAkteCard>
}

export default withTheme(AkteCardComponent)