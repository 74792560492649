import React from 'react'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { DialogMainContent } from 'application/components/fragments/dialog'
import DisplayDatenshutz from './display_datenshutz'
import DatenshutzLoader from './datenshutz_loader'

const DialogDefault = (props) => {
    const { dialog } = props
    const payload = dialog.payload
    return <DatenshutzLoader {...payload} {...props} >
        <DialogMainContent>
            <FlexGrid itemsCenter directionColumn>
                <FlexGridItem>
                    <FlexGrid grow='0' justifyCenter>
                        <FlexGridItem>
                            <DisplayDatenshutz {...payload} {...props} />
                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
        </DialogMainContent>
    </DatenshutzLoader>
}

export default DialogDefault
