import { connect } from 'react-redux'
import ContactInfoPopupSidebarComponent from './component'

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ContactInfoPopupSidebarComponent)
