
export const scrollSession = {
    store: (data) => {
        localStorage.removeItem('scroll')
        localStorage.setItem('scroll', data)
    },
    get: () => {
        const storageEntry = localStorage.getItem('scroll')
        return storageEntry === null ? 0 : storageEntry
    },
    delete: () => {
        localStorage.removeItem('scroll')
    }
}
