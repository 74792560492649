import React, { Fragment } from 'react'
import { StyledIconLink, StyledLabel } from './fragments'

export const POSITION_LABEL_ICON = {
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
    RIGHT: 'RIGHT',
    LEFT: 'LEFT',
}
export const ContentFinder = (positionLabel) => {
    switch (positionLabel) {
    case POSITION_LABEL_ICON.TOP:
        return IconLinkWithLabelTopContent
    case POSITION_LABEL_ICON.RIGHT:
        return IconLinkWithLabelRightContent
    case POSITION_LABEL_ICON.BOTTOM:
        return IconLinkWithLabelBottomContent
    case POSITION_LABEL_ICON.LEFT:
        return IconLinkWithLabelLeftContent
    default:
        return IconLinkWithLabelBottomContent
    }
}
export const IconLinkWithLabelBottomContent = ({ positionLabel, textTransformLabel, color, onClick, className, to, iconSize, isActive, fontSize, width, height, backgroundcolor, borderradius, title, iconKey, label }) => {
    return <Fragment>
        <StyledIconLink
            color={color}
            onClick={onClick}
            className={className}
            to={to}
            iconSize={iconSize}
            selected={isActive}
            width={width}
            height={height}
            backgroundcolor={backgroundcolor}
            borderradius={borderradius}
            positionLabel={positionLabel}
        >
            <i className="material-icons" title={title}>{iconKey ? iconKey : 'perm_identity'}</i>
        </StyledIconLink>
        {
            label &&
            <StyledLabel fontSize={fontSize} textTransformLabel={textTransformLabel}>
                {label}
            </StyledLabel>
        }
    </Fragment>
}

export const IconLinkWithLabelTopContent = ({ positionLabel, color, onClick, className, to, iconSize, isActive, fontSize, width, height, backgroundcolor, borderradius, title, iconKey, label }) => {
    return <Fragment>
        {
            label &&
             <StyledLabel fontSize={fontSize}>
                 {label}
             </StyledLabel>
        }
        <StyledIconLink
            color={color}
            onClick={onClick}
            className={className}
            to={to}
            iconSize={iconSize}
            selected={isActive}
            width={width}
            height={height}
            backgroundcolor={backgroundcolor}
            borderradius={borderradius}
            positionLabel={positionLabel}
        >
            <i className="material-icons" title={title}>{iconKey ? iconKey : 'perm_identity'}</i>
        </StyledIconLink>
    </Fragment>
}

export const IconLinkWithLabelRightContent = ({ positionLabel, justifyContent, color, onClick, className, to, iconSize, isActive, fontSize, backgroundcolor, width = '100%', height = 'unset', borderradius = '28px', title, iconKey, label, padding = '25px 18px' }) => {
    return <Fragment>
        <StyledIconLink
            color={color}
            onClick={onClick}
            className={className}
            to={to}
            iconSize={iconSize}
            selected={isActive}
            backgroundcolor={backgroundcolor}
            positionLabel={positionLabel}
            borderradius={borderradius}
            width={width}
            height={height}
            padding={padding}
            justifyContent={justifyContent}
        >
            <i className="material-icons" title={title}>{iconKey ? iconKey : 'perm_identity'}</i>
            {
                label &&
				<StyledLabel
				    fontSize={fontSize}
				    positionLabel={positionLabel}>
				    {label}
				</StyledLabel>
            }
        </StyledIconLink>
    </Fragment>
}

export const IconLinkWithLabelLeftContent = ({ positionLabel, justifyContent, color, onClick, className, to, iconSize, isActive, fontSize, backgroundcolor, width = '100%', height = 'unset', borderradius = '28px', title, iconKey, label, padding = '25px 18px' }) => {
    return <Fragment>
        <StyledIconLink
            color={color}
            onClick={onClick}
            className={className}
            to={to}
            iconSize={iconSize}
            selected={isActive}
            width={width}
            backgroundcolor={backgroundcolor}
            borderradius={borderradius}
            height={height}
            positionLabel={positionLabel}
            padding={padding}
            justifyContent={justifyContent}
        >
            {
                label &&
				<StyledLabel
				    fontSize={fontSize}
				    positionLabel={positionLabel}>
				    {label}
				</StyledLabel>
            }
            <i className="material-icons" title={title}>{iconKey ? iconKey : 'perm_identity'}</i>
        </StyledIconLink>
    </Fragment>
}