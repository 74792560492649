import React from 'react'
import { withTheme } from 'styled-components'
import { LayoutBase } from 'application/components/pages/_layout'
import LayoutWelcome from '../_layout'
import UserWelcomeContent from './content'

const UserWelcomePage = ({ theme }) => {
    return (
        <LayoutBase>
            <LayoutWelcome>
                <UserWelcomeContent />
            </LayoutWelcome>
        </LayoutBase>
    )
}

export default withTheme(UserWelcomePage)