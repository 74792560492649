import { api } from 'application/api'
import { getFoldersFlatten } from 'application/components/building_blocks/tree/helpers'
import { registeredMessageFailed, unregisterMessage, updateDataOnMessage } from 'application/redux/actions/messages'
import { call, put } from 'redux-saga/effects'
import { handleAkteForbidden, handleMessageNoMoreExist } from '../../errors'
import { unregisterFoldersFromUnexistedMessage } from './unregister_folders_from_unexisted_message'

export function* isMeAndMyRootExist(action) {
    const { akteIdHash, accountId, metaData } = action
    let isMeExist, response, updatedFolders, updatedFoldersFlatten
    try {
        // get updated folders
        response = yield call(api.gateway.pages.getMessageDetails, accountId, akteIdHash)
        updatedFolders = response.folders
        updatedFoldersFlatten = getFoldersFlatten(updatedFolders)

        // check existence
        isMeExist = updatedFoldersFlatten.filter((u) => u.id === action.folderId).length > 0

        // do stuffs if doesnt exist anymore
        if (!isMeExist) {
            yield unregisterFoldersFromUnexistedMessage(action.folderId)
            yield put(updateDataOnMessage(action.folderId, { exist: false }))
            yield put(registeredMessageFailed(action.folderId))
            yield put(unregisterMessage(action.folderId))
            yield put(updateDataOnMessage(akteIdHash, { folders: updatedFolders }))
            yield handleMessageNoMoreExist(metaData.name)
        }

        return { isMeExist, response, updatedFolders, updatedFoldersFlatten }
    } catch (e) {
        yield handleAkteForbidden(e, akteIdHash)
        return { isMeExist: false, response: [], updatedFolders: [], updatedFoldersFlatten: [] }
    }
}
