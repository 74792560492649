import ACTIONS from 'application/constants'
import default_store from '../../default_store'

const agbDatenschutz = (state = default_store.common.agbDatenschutz, action) => {
    switch (action.type) {
    case ACTIONS.COMMON_ON_AGB_DATENSCHUTZ_ACCEPT:
        return {
            ...state,
            isAccepted: action.condition,
            accountId: action.accountId
        }
    case ACTIONS.COMMON_ON_AGB_DOWNLOAD:
        return {
            ...state,
            download: {
                ...state.download,
                request: true,
                at: new Date(),
            }
        }
    case ACTIONS.COMMON_ON_AGB_DOWNLOAD_SUCCEED:
        return {
            ...state,
            download: {
                ...state.download,
                request: false,
                succeed: true,
                failed: false,
            }
        }
    case ACTIONS.COMMON_ON_AGB_DOWNLOAD_FAILED:
        return {
            ...state,
            download: {
                ...state.download,
                request: false,
                succeed: false,
                failed: true,
            }
        }
    case ACTIONS.COMMON_ON_DATENSHUTZ_REQUEST:
        return {
            ...state,
            registeredDatenshutz: state.registeredDatenshutz.filter(t => t.accountId === action.accountId).length > 0
                ? state.registeredDatenshutz
                : [
                    ...state.registeredDatenshutz,
                    {
                        accountId: action.accountId,
                        accountName: action.accountName,
                        isLoaded: false,
                        failed: false,
                        datenschutz: null,
                    }
                ]
        }
    case ACTIONS.COMMON_POPULATE_DATENSCHUTZ_ACCOUNT: {
        return {
            ...state,
            registeredDatenshutz: state.registeredDatenshutz.map((t, i) => (t.accountId === action.accountId ? {
                ...t,
                datenschutz: action.datenschutz,
                isLoaded: true,
                failed: false,
            } : t))
        }
    }
    case ACTIONS.COMMON_ON_DATENSHUTZ_FAILED: {
        return {
            ...state,
            registeredDatenshutz: state.registeredDatenshutz.map((t, i) => (t.accountId === action.accountId ? {
                ...t,
                datenschutz: null,
                isLoaded: true,
                failed: true,
            } : t))
        }
    }
    default:
        return state
    }
}

export default agbDatenschutz