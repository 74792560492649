import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, { useContext } from 'react'
import { Fragment } from 'react'
import { MessageDetails } from 'application/components/building_blocks/messages'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { Headline2 } from 'application/components/fragments/headlines'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { useTheme } from 'styled-components'
import { PluginAlternateView } from 'plugins'

const MessageDetailsSection = (props) => {
    const { widthMessageDetails, allAktenLoaded, messageKey, parentId, folderId, favoriteId, akteIdHash, allAccounts } = props
    const {t} = useTranslation()
    const accountPublicInfo = allAccounts[0]
    const { getAsset } = usePluginsContext()
    const theme = useTheme()
    return <FlexGridItem grow={2} shrink={0} width={widthMessageDetails} widthMobile={widthMessageDetails}>
        {
            akteIdHash === undefined ? allAktenLoaded ? 
            <PluginAlternateView id='akten.select_case' isTablet={isTablet} accountPublicInfo={accountPublicInfo}>
                <Fragment>
                    <FlexGrid directionColumn justifyCenter itemsCenter>
                        {accountPublicInfo && (
                            <ImagePlaceHolder
                                source={
                                    getAsset('nomessagechoosen.png')
                                }
                                large={isTablet ? '100%' : '450px'}
                                withBorder={false}
                            />
                        )}
                        <Headline2>
                            {t('SelectOneCase')}
                        </Headline2>
                    </FlexGrid>
                </Fragment>
            </PluginAlternateView>
             : <FlexGrid justifyCenter>
                {accountPublicInfo && (
                    <PreloaderSpin
                        primaryColor={
                            theme.color.primary
                        }
                    />
                )}
            </FlexGrid> :
                <MessageDetails
                    rootKey={akteIdHash}
                    messageKey={messageKey}
                    parentId={parentId}
                    folderId={folderId}
                    favoriteId={favoriteId}
                    {...props} />
        }
    </FlexGridItem>
}

export default MessageDetailsSection
