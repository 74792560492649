import styled from 'styled-components'

export const AccordionSection = styled.div`
    display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color.color20};
  border-width: ${p => p.withBorderBottom ? '1px' : '0px'};
  margin-bottom: 16px;
`

export const AccordionButton = styled.button`
    background-color:${props => props.theme.color.color0};
  color: ${props => props.theme.color.color90};
  cursor: pointer;
  padding: 24px 24px 0px 24px;
  display: flex;
  justify-content: ${props => props.iconPositionAfter ? 'space-between' : 'flex-start'};
  align-items: center;
  border: none;
  outline: none;
  transition: ${props => props.theme.transition.slow} ease;
`

export const AccordionTitle = styled.h3`
    font-size: ${props => props.theme.fontSize.headline2};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-align: ${p => p.textAlign ? p.textAlign : 'left'};
    color: ${props => props.colorTitle};
    margin: 0px;
`

export const AccordionContent = styled.h4`
    background-color: ${props => props.theme.color.color0};
  overflow: hidden;
  max-height: ${props => props.maxHeight};
  transition: ${props => props.theme.transition.slow} ease;
  padding: 0px 24px;
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.regular};
  margin-bottom: ${p => p.contentMarginBottom};
  margin-top: ${p => p.contentMarginTop};
  position: relative;
`

export const AccordionText = styled.p`
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.regular};
    padding: 0px 24px;
    text-align: ${p => p.textAlign ? p.textAlign : 'left'};
    margin: 10px;
`