import { ACTIONS } from 'application/constants'
import defaultStore from '../default_store'

const dialogsReducer = (
    state = defaultStore.dialogs,
    action
) => {
    switch (action.type) {
    case ACTIONS.DIALOG_OPEN_DIALOG_BY_NAME:
        return ({
            ...state,
            dialogsOpen: true,
            registeredDialogs: [
                ...state.registeredDialogs, //check if dialog with same name is open first?
                {
                    name: action.name,
                    title: action.title,
                    isLoaded: action.initiallyLoaded,
                    payload: action.payload,
                    screen: action.screen
                }
            ]
        })
    case ACTIONS.DIALOG_CLOSE_DIALOG_BY_NAME: {
        const newRegisteredDialogs = state.registeredDialogs.filter(d => d.name !== action.name)
        return {
            ...state,
            dialogsOpen: newRegisteredDialogs.length > 0,
            registeredDialogs: newRegisteredDialogs
        }
    }
    case ACTIONS.DIALOG_CLOSE_ALL: {
        return {
            ...state,
            dialogsOpen: false,
            registeredDialogs: []
        }
    } 
    case ACTIONS.DIALOG_UPDATE_DIALOG_DATA_BY_NAME: {
        return {
            ...state,
            registeredDialogs: state.registeredDialogs.map(d => {
                return d.name === action.name ? {
                    ...d,
                    payload: {
                        ...d.payload,
                        ...action.payload
                    } 
                } : d
            }) 
                
        }
    }
    case ACTIONS.DIALOG_ADD_DOCUMENTS: {
        return {
            ...state,
            registeredDialogs: state.registeredDialogs.map(d => {
                return d.name === action.name ? {
                    ...d,
                    payload: {
                        ...d.payload,
                        documents: !action.initiallyLoaded ? [
                            ...d.payload.documents,
                            {
                                id: d.payload.documents.length + 1,
                                name: action.file.metaData.name,
                                comments: []
                            }
                        ] : [
                            {
                                id: 1,
                                name: action.file.metaData.name,
                                comments: []
                            }
                        ]
                    } 
                } : d
            }) 
                
        }
    }
    case ACTIONS.DIALOG_ADD_COMMENTS: {
        return {
            ...state,
            registeredDialogs: state.registeredDialogs.map(d => {
                return d.name === action.name ? {
                    ...d,
                    payload: {
                        ...d.payload,
                        comments: [
                            ...d.payload.comments,
                            action.payload
                        ]   
                    } 
                } : d
            }) 
                
        }
    }
    case ACTIONS.DIALOG_ADD_COMMENTS_ON_DOCUMENT_DIALOG: {
        return {
            ...state,
            registeredDialogs : state.registeredDialogs.map((r) => {
                return r.name === action.name ? {
                    ...r,
                    payload: {
                        ...r.payload,
                        documents: r.payload.documents.map((d) => {
                            return d.id === action.docId ? {
                                ...d,
                                comments: [
                                    ...d.comments,
                                    action.payload
                                ]
                            } : d
                        })
                    }
                } : r
            })
        }
    }
    case ACTIONS.DIALOG_POPULATE_DIALOG_CONTENT_BY_NAME: {
        return {
            ...state,
            registeredDialogs: state.registeredDialogs.map(d => {
                return d.name === action.name ? {
                    ...d,
                    payload: {
                        ...d.payload, //keep the previous data, eg an id
                        ...action.payload
                    },
                    
                } : d
            }) 
                
        }
    }
    case ACTIONS.DIALOG_SUCCEED_LOADED: {
        return {
            ...state,
            registeredDialogs: state.registeredDialogs.map(d => {
                return d.name === action.name ? {
                    ...d,
                    isLoaded: true,
                } : d
            }) 
                
        }
    }
    default:
        return state
    }
}

export default dialogsReducer