import { WizardLayout } from 'application/components/pages/_layout'
import { ROUTES } from 'application/constants'
import { accessTokenUnregistered } from 'application/storage'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { AkteMetaDataLoader } from '../../_common'
import { getWizardComponent } from './mappers'
import WizardRegistrationDataLoader from './wizard_reg_data_loader'
import WizardRegistrationRedirect from './wizard_reg_redirect'
import WebHeaderLogoEffect from 'application/common/web_header_logo'

const WizardRegistrationComponent = (props) => {
	const { currentStep, akteMeta } = props
	let params = useParams()
	let {wizardPage} = params
    const WizardComponent = getWizardComponent(currentStep)
    if (accessTokenUnregistered.get() === '') {
        return <Navigate to={ROUTES.BASE} />
    }
	return (
        <WebHeaderLogoEffect accountPublicInfo={akteMeta}>
            <AkteMetaDataLoader {...props}>
                <WizardRegistrationDataLoader {...props}>
                    <WizardRegistrationRedirect {...props}>
                        <WizardLayout step={wizardPage} accountId={akteMeta.accountId}>
                            <WizardComponent {...props} />
                        </WizardLayout>
                    </WizardRegistrationRedirect>
                </WizardRegistrationDataLoader>
            </AkteMetaDataLoader>
        </WebHeaderLogoEffect>
    )
}

export default WizardRegistrationComponent
