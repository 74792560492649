import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export const getRanges = () => {
    const now = new Date()
    const aDayAgo = now.getTime() - (12 * 60 * 60 * 1000)
    const yesterday = now.getTime() - (12 * 60 * 60 * 1000) * 2
    const older = now.getTime() - (24 * 60 * 60 * 1000) * 7300 //(last 20 years should be enough, right?)
    const lastWeek = moment().day(-7)
    const thisWeek = moment().day(0)

    const startOfMonth = moment().clone().startOf('month')
    const startLastMonth = startOfMonth - (24 * 60 * 60 * 1000) * 31

    return [
        {
            headerKey: 'Today',
            range: moment.range(aDayAgo, now)
        },
        {
            headerKey: 'Yesterday',
            range: moment.range(yesterday, aDayAgo)
        },
        {
            headerKey: 'Earlier this week',
            range: moment.range(thisWeek, yesterday)
        },
        {
            headerKey: 'Last week',
            range: moment.range(lastWeek, thisWeek)
        },
        {
            headerKey: 'Earlier this month',
            range: moment.range(startOfMonth, lastWeek)
        },
        {
            headerKey: 'Last month',
            range: moment.range(startLastMonth, startOfMonth)
        },
        {
            headerKey: 'A long time ago',
            range: moment.range(older, startLastMonth)
        }
    ]
}