export const DASHBOARD = {
    LINK: {
        SETTINGS: {
            ACCOUNT: 'account',
            GENERAL: 'general',
            ABOUT: 'about',
        },
        AKTEN: {
            AKTEID: 'akteIdHash',
            ACCOUNTID: 'accountId',
        },
    }
}