import React from 'react'
import { FlexGrid, PushBottom30 } from 'application/components/fragments/grid'
import { StyledCompanyLogo } from '../fragments'

// const StyledCompanyName = styled.p`
//   font-size: ${props => props.theme.fontSize.headline3};
//   font-weight: ${props => props.theme.fontWeight.bold};
//   text-align: center;
//   color: ${props => props.theme.color.primary};
// `

const DefaultCompanyLogoComponent = ({ accountName, activated = true }) => {
    return <FlexGrid directionColumn itemsCenter>
        {activated && <PushBottom30 />}
        <StyledCompanyLogo activated={true} default src={'/assets/images/default/default_placeholder_company.png'} alt={accountName} />
        {/* <StyledCompanyName>
            {accountName}
        </StyledCompanyName> */}
    </FlexGrid> 
}

export default DefaultCompanyLogoComponent