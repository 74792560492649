import { connect } from 'react-redux'

import StatementDialog from './component'

const mapStateToProps = (state, props) => {
    return {
        dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name),
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatementDialog)