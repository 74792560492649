export const en = {
    translation: {
        'Akten': 'Cases',
        'NoCasesFound': 'No case found',
        'NoMessageFound': 'No message found',
        'SelectOneCase': 'Please select one case',
        'Login': 'Login',
        'ReturnLoginPage': 'Return to login',
        'username': 'Username',
        'UsernamePlaceholderForgot': 'Username',
        'password': 'Password',
        'legal notice' : 'Legal Notice',
        'privacy' : 'Privacy Policy',
        'conditions': 'Term & Conditions',
        'TimeHourSuffix': '',
        'There Is No Task': 'There Is No Task',
        'All': 'All',
        'Open': 'Open',
        'Done': 'Done',
        'Expired': 'Expired',
        // welcome page translate
        'task': 'Task',
        'You Have Successfully Submitted Your Answer': 'You Have Successfully Submitted Your Answer',
        'email': 'E-Mail',
        'Communication with the lawyer': 'Communication with the lawyer',
        'WelcomeTextTopWithHooray': 'Hooray - you have found the right lawyer! Long procedures and email battles are a thing of the past: With the app, you communicate e.sy via chat. You decide when and where to write your messages. Possible in no time with the e.syOne. Contacting a lawyer has never been easier!',
        'Four steps to get started with our free app': 'Four steps to get started with our free app',
        'Download the app': 'Download the app',
        'Smartlink received from lawyer': 'Smartlink received from lawyer',
        'Register': 'Register',
        'Lets go': 'Let\'s go',
        'e.syOne - your paperless exchange for legal stuff': 'e.syOne - your paperless exchange for legal stuff',
        'Simple - communication via chat': 'Simple - communication via chat',
        'Safe - thanks to WebAkte': 'Safe - thanks to WebAkte',
        'Fast - all information at a glance': 'Fast - all information at a glance',
        'Efficient - time and location-independent exchange': 'Efficient - time and location-independent exchange',
        'Simply clarify legal issues': 'Simply clarify legal issues',
        'InfoWithBildOne': 'Lean back and clarify your legal questions from the comfort of the sofa. Log in with your fingerprint or Face- ID and clarify your legal matters in peace. Don\'t worry - all of your data is safe.',
        'Your file is always with you': 'Your file is always with you',
        'Herzlichen Glückwunsch!': 'Congratulations!',
        'zum Dashboard': 'to Dashboard',
        'InfoWithBildTwo': 'Maximum self-determination - you always have your files with you on your smartphone. You will respond to your attorney\'s questions when it suits you.',
        'Dont worry - all of your data is safe.': 'Don\'t worry - all of your data is safe.',
        'InfoWithBildThree': 'You can rely on it, more than 670, 000 clients trust our software solutions and services. We have been firmly anchored in the legal industry for 20 years and over 18, 000 law firms use our simple and secure solutions every day.',
        'InfoWithBildThreeOne': 'Our diverse team consists of experts from various disciplines. A dedicated team from ten cultures put their heads together with the aim of enabling people like you to clarify legal matters quickly and easily.',
        'InfoWithBildFour': 'What are you waiting for? Take the first step and solve your legal stuff easier than ever.You can find the e.syOne for free.',
        'PasswordResetLinkError': '🔥 Password reset link cannot be sent',
        'PasswordResetLinkSuccess': '📧 Password reset link has been sent to your email',
        'PasswordForgotSubHeadline': 'Please enter your username. You will receive an email with a link to edit your password.',
        'ForgotPassword': 'Forgot Password',
        'ForgotUsername': 'Forgot Username',
        'ForgotUsernameSubHeadline': 'Please enter your e-mail address. We will send you an email with your username.',
        'UsernameResetLinkError': '💥 Username cannot be sent',
        'UsernameResetLinkSuccess': '📣 Username has been sent to your email',
        'RequiredFieldValidation': 'Please fill the required field.',
        'EmailValidation': 'Please enter a valid email adress.',
        'CheckReceivedEmail': 'Please check your e-mail.',
        'SubmitButtonText': 'Submit',
        'GainAccessTitle': 'Gain access',
        'GainAccessSubTitle': 'For security reasons and for clear identification, please verify the process by requesting a PIN.',
        'RequestPIN': 'Request PIN',
        'RequestPINError': 'Pin cannot be sent',
        'RequestPINSuccess': 'Pin has been sent',
        'RequestPINSuccessEmail': 'Pin has been sent to your email',
        'RequestPINSubTitle': 'Please enter the 6-digit PIN received by email or SMS.',
        'RequestPINQuestion': 'Have not received PIN yet?',
        'RequestPINAnother' : 'request PIN again',
    'EsyMandantSalutation': 'Welcome to e.syOne',
        'EsyMandantSalutationText': 'Follow your communication with your legal advisor anytime, anywhere.',
        'FirstStep': 'Get started', 
        'EsyMandantRegistration': 'Sign up now!',
        'EsyMandantRegistrationSubTitle': 'and gain direct access to your online Akte.',
        'EsyMandantPasswordConfirmation': 'Just one more step - Confirm your password',
        'BackButtonText':'Back',
        'NextButtonText': 'Next',
        'PasswordInputMatchingError': 'Password doesnt match!',
        'TextOnDownload': 'You look like a person with high mobility, downloading this application will make it your life easier!',
        'AddDocuments': 'Add document',
        'Favorites': 'Favorites',
        'PrivacyExplanationText': 'By clicking on "Login", I accept the e.syOne <1>terms of service</1> and confirm that I have read the e.syOne <3>privacy policy</3> and <5>terms and conditions</5>.',
        'PrivacyExplanationFees': 'Fees may apply for message and data retrieval. By clicking on "Login", I accept the e.syOne <1>terms of service</1> and confirm that I have read the e.syOne <3>privacy policy</3> and <5>terms and conditions</5>.',
        'PrivacyExplanationPIN': 'By clicking on "Request PIN", I accept the e.syOne <1>terms of service</1> and confirm that I have read the e.syOne  <3>privacy policy</3> and <4> terms and conditions.</4> terms and conditions.',
        'ReadMessage': 'Read message',
        'messages': 'Messages',
        'EmptySharedMessageContent': 'No messages available.',
        'document': 'Document',
        'photo': 'foto',
        'LoadMoreService': 'Load more services',
        'EnlargeImage': 'Enlarge',
        'RotateImage': 'Rotate',
        'ChangeProfilePicture': 'Change profile picture',
        'ResetPassword': 'Reset Password',
        'PasswordResetSuccess': 'The password has been changed successfully.',
        'ForBusiness': 'For Business',
        'Partners': 'Partners',
        'CongratulationText1':'Enjoy easy and secure communication with <1>{{accountName}}<1/>',
        'CongratulationText1Kommunizieren': ' ',
        'PasswordDontMatch': 'Passwords do not match',
        'PrivacyExplanationTextCaptcha': 'This page is protected by CAPTCHA. By continuing, I confirm that I have read the Google Privacy Policy and that I accept the Google Terms of Use.',
        'UnreadMessage':'Unread messages',
        'Today':'today',
        'CreationDateText':'Created at',
        'CreationDateTextToday':'Created',
        'UsernameNotEmpty': 'Username is required.',
        'PasswordSecurityCriteria': 'Password is not secure. Please follow our password security criteria.',
        'PasswordNotEmpty': 'Password is required',
        'PasswordNewNotEmpty': 'New password is required',
        'passwordCriteriaHeading': 'Your password must meet 3 of these 4 criteria:',
        'passwordLengthCriteriaHeading': 'In addition for password length:',
        'passwordSpecialCriteriaHeading': 'Your password may also:',
        'includesLowerCaseCharacter': 'At least one lowercase letter must be included.',
        'includesUpperCaseCharacter': 'At least one capital letter must be included.',
        'includesSpecialCharacter': 'At least one special character must be included.',
        'includesNumericCharacter': 'At least one number must be included.',
        'passwordNotBlacklisted': 'Password must not be trivial (e.g. “12345678“).',
        'passwordMinLength': 'Password must be at least 8 characters long.',
        'usernameNotIncluded': 'Password cannot contain the username you have chosen.',
        'DescriptionNotEmpty': 'Please describe your question',
        'ExistingPassword': 'Existing password',
        'ExistingPasswordNotEmpty': 'Please type your existing password',
        'EmailNotEmpty': 'Please type your registered E-Mail',
        'HideFolder': 'hide folder',
        'NewMessageInFolders': 'new message in folder',
        'ExploreFolders': 'explore folder',
        'PasswordUpdated': 'Password successfully changed',
		'PasswordUpdatedFailed': 'Passwort cannot be changed',
		'Password hint': 'Password hint',
		'Or log in app.': 'Or log in app.',
        'PasswordUpdatedFailed2': 'When you try your best and dont succeed, try again later',
        'RelatedSearch': 'Related search:',
        'FalsePin': 'Wrong PIN',
        'ForbiddenAccess': 'Access denied',
        'UploadLimit': 'You cannot upload files at the moment. Please contact your lawyer.',
        'Forbidden access to requested resource': 'Forbidden access to requested resource',
        'LinkInvalid': 'The link is invalid.',
        'Locked': 'Locked',
        'Not Acceptable': 'Not Acceptable',
        'Please choose a stronger Password!': 'Please choose a stronger Password!',
        'Username is already exist': 'Username is already exist',
        'Form cannot be sent': 'Form cannot be sent',
        'Form has been sent': 'Form has been sent',
        'Files with not allowed extensions has been removed': 'Files with not allowed extensions has been removed',
        'Username / Password is incorrect': 'Username / Password is incorrect',
        'January': 'January',
        'February': 'February',
        'March': 'March',
        'April': 'April',
        'May': 'May',
        'June': 'June',
        'July': 'July',
        'August': 'August',
        'September': 'September',
        'October': 'October',
        'November': 'November',
        'December': 'December',
        'Unauthorized': 'Unauthorized',
        'Good morning.': 'Good morning.',
        'Good afternoon.': 'Good afternoon.',
        'Good evening.': 'Good evening.',
        'Request': 'Request',
        'Go eat lunch.': 'Go eat lunch.',
        'Welcome': 'Welcome',
        'Registration': 'Registration',
        'Confirmation': 'Confirmation',
        'Files': 'Files',
        'Search files': 'Search files',
        'External Request': 'External Request',
        'New Password': 'New Password',
        'Password confirmation': 'Password confirmation',
        'Save password': 'Save password',
        'Please check your e-mail': 'Please check your e-mail',
        'Please enter a valid email adress.': 'Please enter a valid email adress.',
        'Description': 'Description',
        'Question': 'Question',
        'Set profile picture': 'Set profile picture',
        'Profile Setting': 'Profile Setting',
        'Profile picture, Password': 'Profile picture, Password',
        'General': 'General',
        'Language': 'Language',
        'About': 'About',
        'AGB, Contact Us': 'AGB, Contact Us',
        'About Settings': 'About Settings',
        'Contact us': 'Contact us',
        'Send': 'Send',
        'Email': 'Email',
        'Languages': 'Languages',
        'Forgot Username': 'Forgot Username',
        'Forgot Password': 'Forgot Password',
        'This field is required.': 'This field is required.',
        'Search Akten': 'Search Cases',
        'Messages': 'Messages',
        'Folders': 'Folders',
        'MessagesNavigationBar': 'Messages',
        'Service Provider': 'Service Provider',
        'Unread Messages': 'Unread Messages',
        'Settings': 'Settings',
        'General Settings': 'General Settings',
        'Account Settings': 'Account Settings',
        'Profile Picture': 'Profile Picture',
        'Write your message here': 'Write your message here',
        'your contact': 'Your contact',
        'LastWeek': 'Last week',
        'LastMonth': 'Last month',
        'Older': 'Older',
        'Communicating with lawyers has never been easier!': 'Communicating with lawyers has never been easier!',
        'login': 'Login',
        'Enter PIN': 'Enter PIN',
        'We are happy for your feedback!': 'We are happy for your feedback!',
        'About e.syOne': 'About e.syOne',
        'Set your profile picture': 'Set your profile picture',
        'Image has been uploaded': 'Image has been uploaded',
        'Image cannot be uploaded': 'Image cannot be uploaded',
        'Unexpected error': 'Unexpected error',
        'Bad Request': 'Bad Request',
        'Uploading files': 'Uploading files',
        'Show all files': 'Show all files',
        'LawBannerHasAGB': 'I have read <1>{{companyName}}</1> <3>Terms and Conditions</3> and I agree to them! ',
        'LawBannerHasDatenschutz': 'Find out more under the law firm\'s <1>data protection notice</1>! ',
        'Activate': 'Activate',
        'activateAccountText': 'To activate your profile in the account of the <1>{{companyName}}</1>, please click "Activate"!',
        'Write your message to ': 'Write your message to ',
        'NotesPassword': 'Notice: The password must not be a trivial password. This includes passwords such as <1>"12345678"</1>, but also passwords that contain your <3>user name</3> or your <5>e- mail address</5>. Repetitions of characters<7>(e.g. "aaa" or "555")</7> must also be avoided',
        'Virus detected, but we are vaccinated 🦠': 'Virus erkannt, aber wir sind geimpft 🦠',
        'You do not have access to e.syOne. Please contact your lawyer.': 'You do not have access to e.syOne. Please contact your lawyer.',
        'PasswordResetSuccessIsRenderFromWebviewMobile': 'The password has been changed successfully. Please try to log in to the app again.',
        'Learn':'Learn More',
        'ValidUntil' : 'Valid until',
        'ExpiredOn' : 'Expired on',
        'AnsweredOn': 'Answered on',
        'Download case': 'Download case',
        'LoginImageRightPath': 'assets/images/image_login.jpg',
        'yes': 'YES',
        'no': 'NO',
        'ConfirmTextExternalLinkModalStart': 'You will be redirected to the website ',
        'ConfirmTextExternalLinkModalEnd': '. Please keep in mind that this website might activate cookies and privacy-related features. Do you wish to continue?',
        'DashboardMobileNavigationBar': 'Dashboard',
        'ErrorRedirectButtonText': 'Back to Homepage',
        'step': 'Step',
        'overview' : 'Overview',
        'Your assistance is needed': 'Your help is needed',
        'In order to follow up your case': 'In order to able to follow up your case, we need further information from you',
        'To your tasks': 'To your task', 
        'Case status': 'Status',
        'in folders' : 'In sub folder',
        'in this akte' : 'In this file',
        'Fall Gemeldet': 'Case reported',
        'Fall Wird Geprüft': 'Case under review',
        'Fall In Bearbeitung': 'Case in progress',
        'Fall Abgeschlossen': 'Case closed',
        'Ihr Fall wurde erfolgreich versendet.': 'Your case was successfully sent.',
        'Mandatsannahme steht aus.': 'Mandate acceptance is in process.',
        'Ihr Fall wurde angenommen.': 'Your case has been accepted.',
        'YourCaseWillbe': 'Your case will be processed by us.',
        'NewsOverview' : 'Unread message',
        'New Message' : 'New Message',
        'Add subject' : 'Add subject',
        'Enter your message' : 'Type your message...',
        'Enter a subject' : 'Add subject',
        'MessageLabel': 'Message',
        'NewPasswordPlaceholder': 'Password',    
        'NewPasswordConfirmationPlaceholder' : 'Password confirmation',
        'assignePerson': 'Collaborator',
        'Dieser Fall wurde bereits beendet.': 'This case has already been closed.',
        'Created on': 'Created on',
        'Add Attachment': 'Add attachment',
        'Upload document': 'Upload document',
    }
}