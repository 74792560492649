import { fetchHandler, HTTP_METHOD, limiter, API_VERSION_ESYMANDANT } from 'application/api/request_builders'
import { fetchRequestBuilder } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const putPasswordOnDashboard = (userId, payload) => {

    const request = fetchRequestBuilder(`${API_VERSION_ESYMANDANT}/user/${userId}/changepassword`, HTTP_METHOD.PUT, payload)

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}