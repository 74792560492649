import FontIcon from 'application/components/controls/font_icon'
import React from 'react'
import styled from 'styled-components'

const Gravatar = styled.div`
    flex: 0 0 ${props => props.large}px;
    display: flex;
    justify-content: center;
`

const GravatarCircle = styled.div`
    background-color: ${props => props.backgroundcolor === '' ? props.theme.color.primary : props.backgroundcolor};
    border-radius: ${p => p.borderRadius};
    border: ${p => p.borderWidth} solid #fff;
    height: ${props => props.large}px;
    text-align: center;
    width: ${props => props.large}px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:  calc(${props => props.large}px / 3);
    line-height: 1;
    color: ${props => props.fontColor ? props.fontColor : props.theme.color.color0};
`


const GravatarWithInitial = ({
    initial = '',
    backgroundcolor = '',
    large = '108',
    icon = '',
    borderRadius = '50%',
    borderWidth = '0px',
    fontColor
}) => {
    const iconSize = (large/2).toString()
    return <Gravatar large={large}>
        <GravatarCircle fontColor={fontColor} large={large} borderWidth={borderWidth} borderRadius={borderRadius} backgroundcolor={backgroundcolor}>
                {
                    icon !== '' ? <FontIcon 
                        icon={icon}
                        color='#fff'
                        fontSize={iconSize}
                    /> : <span>
                        {initial}
                    </span> 
                } 
        </GravatarCircle>
    </Gravatar>
}

export default GravatarWithInitial