import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import { Headline1 } from 'application/components/fragments/typography'
import { ROUTES } from 'application/constants'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { LayoutBase } from '../../_layout'
import { useTranslation } from 'react-i18next'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { isMobile } from 'react-device-detect'

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`
const SomethingWentWrong = () => {
    const { getAsset, activePlugin } = usePluginsContext()
    const theme = useTheme()

    const {t} = useTranslation()
    return <LayoutBase>
        <FlexGrid directionColumn itemsCenter justifyCenter={activePlugin ? true : false} style={{ 
            minHeight:'100vh',
            gap: '20px'
         }}>
            {!activePlugin &&
                <FlexGridItem grow={0} justifyCenter>
                    <StyledNavLink to={ROUTES.BASE}>
                        <ImagePlaceHolder large='50px' withBorder={false} source={theme.companyIcon} />
                    </StyledNavLink>
                </FlexGridItem>
            }
            <FlexGridItem grow={0} justifyCenter>
                <ImagePlaceHolder
                    withBorder={false}
                    large={isMobile ? '80vw' : '25vw'}
                    source={getAsset('somethingwentwrong.png')}
                />
            </FlexGridItem>
            <Headline1>
                {t('ForbiddenAccess')}
            </Headline1>
        </FlexGrid>
    </LayoutBase> 
}

export { SomethingWentWrong }
export default SomethingWentWrong