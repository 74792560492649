import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import IconLabel from 'application/components/controls/icon/icon_label'

const ContactDetailServiceProvider = ({selectedAccount}) => {
    return <FlexGrid directionColumn justifyCenter>
        {
            selectedAccount.address !== '' && <IconLabel icon='location_on' label={selectedAccount.address} />
        }
        {
            selectedAccount.phone !== null && <IconLabel icon='phone' label={selectedAccount.phone} />
        }
        {
            selectedAccount.fax !== null && <IconLabel icon='print' label={selectedAccount.fax} />
        }
        {
            selectedAccount.website !== null && <IconLabel linkHref={selectedAccount.website} icon='launch' label={selectedAccount.website} />
        }
    </FlexGrid>
}

export default ContactDetailServiceProvider
