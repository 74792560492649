import ImagePlaceHolder from 'application/components/controls/image_placeholder'
import { FlexGridItem } from 'application/components/fragments/grid'
import { PluginAlternateView } from 'plugins'
import React from 'react'

const LogoTopBarSection = (props) => {
    return <FlexGridItem itemsCenter grow={0.5}>
        <PluginAlternateView id="topbar.logo">
            <ImagePlaceHolder
                withBorder={false}
                large='48px'
                source={'/assets/images/icon_esy_one.png'}
                altText='logo e.sy One'
            />
        </PluginAlternateView>
    </FlexGridItem>
}

export default LogoTopBarSection
