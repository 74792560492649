import { connect } from 'react-redux'
import TopBarEffects from './effects'
import { getProfilePicture } from 'application/redux/actions/auth'
import { openNavigationLinkMobilePopupSidebar } from 'application/redux/actions/sidebars'

const mapStateToProps = state => ({
    auth: state.auth,
    profilePicture: state.auth.common.user.profilePicture,
    allAktenLoaded: state.common.allAkten.isLoaded,
    allAccountsLoaded: state.common.allAccounts.isLoaded,
    selectedAccount: state.common.accountDetails.selectedAccount,
    profilePictureLoaded: state.auth.common.user.profilePicture.isLoaded,
    profilePictureId: state.auth.common.user.profilePicture.id,
    userId: state.auth.common.user.userId,
    accountId: state.auth.common.user.accountIds[0],
})

const mapDispatchToProps = dispatch => ({
    getProfilePicture: (accountId, userId) => {
        dispatch(getProfilePicture(accountId, userId))
    },
    openNavigationLinkSidebar: () => dispatch(openNavigationLinkMobilePopupSidebar()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopBarEffects)