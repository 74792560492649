import { logoutRequest } from 'application/redux/actions/auth'
import { connect } from 'react-redux'
import Layout from './base'

const mapDispatchToProps = dispatch => {
    return {
        // onGetUserData: () => dispatch(getUserData())
        logoutRequest: () => dispatch(logoutRequest())
    }
}

export default connect(
    state => ({
        auth: state.auth,
        registeredLoggedIn: state.auth.registered.loggedIn,
        unregisteredLoggedIn: state.auth.unregistered.loggedIn,
    }),
    mapDispatchToProps
)(Layout)