import { objectHelpers } from 'application/common'

export const updateDataUnreadMessageInFolder = (state, nodes, folderId) => {
    for (var i = 0; i < nodes.length; i++) {
        if (nodes[i].id === folderId) {
            const updated = objectHelpers.mergeDeep(state, {
                ...nodes[i],
                totalUnreadMessage: parseInt(nodes[i].totalUnreadMessage) !== 0 ? (parseInt(nodes[i].totalUnreadMessage) - 1) : 0
            })
            return updated
        }
        let newState = objectHelpers.mergeDeep(state, nodes[i])
        var a = updateDataUnreadMessageInFolder(newState, nodes[i].descendants, folderId)
        if (a != null) {
            return a
        }
    }
}

export const updateDataUnreadMessageInFolderMap = (folders, folderId) => {
    return folders.map(item => ({
        ...item,
        totalUnreadMessage: item.id === folderId ? parseInt(item.totalUnreadMessage) !== 0 ? (parseInt(item.totalUnreadMessage) - 1) : 0 : item.totalUnreadMessage,
        descendants: updateDataUnreadMessageInFolderMap(item.descendants, folderId)
    }))
}

export const updateDataInFolderMap = (folders, folderId, payload) => {
    return folders.map(item => item.id === folderId ? {
        ...payload
    } : {
        ...item,
        descendants: updateDataInFolderMap(item.descendants, folderId, payload)
    })
}

export const filterNewMessagesAndFiles = (oldRegisteredList, newRegisteredMetadata) => {
    const newMessages = [...oldRegisteredList.filter(r => r.key == newRegisteredMetadata.rootInfo.idHash)[0].messages, ...newRegisteredMetadata.messages]
    const newFiles = [...oldRegisteredList.filter(r => r.key == newRegisteredMetadata.rootInfo.idHash)[0].files, ...newRegisteredMetadata.files]

    const uniqueMessages = new Set()
    const uniqueFiles = new Set()

    let filteredMessages = newMessages.filter(m => {
        const isDuplicate = uniqueMessages.has(m.id)
        uniqueMessages.add(m.id)
        return !isDuplicate
    })

    let filteredFiles = newFiles.filter(f => {
        const isDuplicate = uniqueFiles.has(f.id)
        uniqueFiles.add(f.id)
        return !isDuplicate
    })

    filteredMessages = filteredMessages.sort((a,b) => Date.parse(a.creationDate) - Date.parse(b.creationDate))
    filteredFiles = filteredFiles.sort((a,b) => Date.parse(a.creationDate) - Date.parse(b.creationDate))
    const messageLength = filteredMessages.length
    
    return {filteredMessages , filteredFiles, messageLength}
}