import { ROUTES } from 'application/constants'
import AktenSidebar from '../../internal/akten/sidebar'
import DashboardSidebar from '../../internal/dashboard/sidebar'
import ServiceProviderSidebar from '../../internal/service_provider/sidebar'
import SettingsSidebar from '../../internal/settings/sidebar'

export const getSidebarComponent = (location) => {
    switch (location) {
    case ROUTES.AKTEN:
    case ROUTES.AKTEN_PRESELECTED:
        return AktenSidebar
    case ROUTES.DASHBOARD:
        return DashboardSidebar
    case ROUTES.SERVICE_PROVIDER:
        return ServiceProviderSidebar
    case ROUTES.SETTINGS:
    case ROUTES.SETTINGS_PRESELECTED:
        return SettingsSidebar
    default:
        return DashboardSidebar
    }
}