import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragment from '../fragments'

const HeadlineSection = ({ headline, date }) => {
    const {t} = useTranslation()
    return <FlexGridItem grow={0}>
        <FlexGrid width='100%' itemsCenter justifySpaceBetween>
            <Fragment.DashboardHeadline>
                {headline}
            </Fragment.DashboardHeadline>
            <Fragment.DashboardDate>
                {t(date.month)} {date.date}, {date.year}
            </Fragment.DashboardDate>
        </FlexGrid>
    </FlexGridItem>
}

export default HeadlineSection