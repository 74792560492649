import ActionButton from 'application/components/controls/button/action_button/component'
import PreloaderSpin from 'application/components/controls/preloader/spin'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { Spacer } from 'application/components/pages/internal/dashboard/fragments'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormPasswordRegistration from '../../wizard/registration/password/sections/form_password_registration'
import TitleWizard from '../../wizard/registration/_common/title_wizard'
import TopContentWizardReg from '../../wizard/registration/_common/top_content'
import * as WizardMobileStyled from './styles'

import { useTheme } from 'styled-components'
import PasswordStrengthCheckerAnimation from '../../wizard/registration/password/sections/password_strength_checker_animation'

const PasswordWizardMobile = props => {
    const { wizardData, handleBlurPass, handleChangeNewPass, onOpenPasswordCriteriaOpenDialog, handleFocusPass, onSubmitSuccess, register, registeredFailed, registeredRequest, validation, errors, newPass, username, password, errorMessage, handleSubmit, akteMeta } = props
    const {t} = useTranslation()
    const theme = useTheme()


    return <WizardMobileStyled.Layout>
        <WizardMobileStyled.Container>
            <TopContentWizardReg useMobile wizardData={wizardData} />
            <WizardMobileStyled.Container>
                <TitleWizard step={3} headline={t('EsyMandantPasswordConfirmation')} desc='' spacerHeadlineAndStep='30px' />
                <FlexDividerHorizontal />
                <Spacer distance='16px' />
                <FormPasswordRegistration
                    useMobile
                    handleBlurPass={handleBlurPass}
                    handleChangeNewPass={handleChangeNewPass}
                    handleFocusPass={handleFocusPass}
                    handleSubmit={handleSubmit}
                    onSubmitSuccess={onSubmitSuccess}
                    register={register}
                    registeredFailed={registeredFailed}
                    registeredRequest={registeredRequest}
                    validation={validation}
                    errors={errors}
                    newPass={newPass}
                    password={password}
                    errorMessage={errorMessage}
                />
            </WizardMobileStyled.Container>
        </WizardMobileStyled.Container>
        <WizardMobileStyled.Container>
            <PasswordStrengthCheckerAnimation accountId={akteMeta.accountId} username={username} newPass={password.current}/>
            <FlexDividerHorizontal />
            <Spacer distance='20px' />
            {
                !registeredRequest ?
                    <ActionButton
                        backgroundColor={theme.color.primary}
                        buttonText={t('SubmitButtonText')}
                        onButtonClick={handleSubmit(onSubmitSuccess)}
                        strechted
                        borderradius='10px'
                    /> : <PreloaderSpin
                        primaryColor={theme.color.primary}
                    />
            }
        </WizardMobileStyled.Container>
    </WizardMobileStyled.Layout>
}

export default PasswordWizardMobile
