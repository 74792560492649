import React, { useEffect } from 'react'

const CreatorProfilePictureRegister = (props) => {
    const {
        children,
        auth,
        accountId,
        creatorIdHash,
        allAktenLoaded,
        files,
        akteIdHash,
        registerCreatorProfilePicture
    } = props
    const registered = files.profilePictureCreator.filter(e => e.creatorIdHash === creatorIdHash && e.akteIdHash === akteIdHash).length > 0
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    useEffect(() => {
        allAktenLoaded
            && !registered
            && loggedIn
            && registerCreatorProfilePicture(accountId, creatorIdHash, akteIdHash)
    }, [accountId, akteIdHash, allAktenLoaded, creatorIdHash, loggedIn, registerCreatorProfilePicture, registered])

    return <div>
        {children}
    </div>
}

export default CreatorProfilePictureRegister