import ACTIONS from 'application/constants'

export const getFormElementsRequest = (key, pin = '', loginAttempt, metaData = null) => ({
    type: ACTIONS.FORM_GET_FORM_ELEMENTS_REQUEST,
    key,
    pin,
    loginAttempt,
    metaData
})

export const refreshGetFormElements = () => ({
    type: ACTIONS.FORM_GET_FORM_ELEMENTS_REFRESH,
})

export const populateFormMetaData = (payload) => ({
    type: ACTIONS.FORM_POPULATE_FORM_METADATA,
    payload,
})

export const refreshFormElements = () => ({
    type: ACTIONS.FORM_REFRESH_FORM_ELEMENTS,
})

export const getFormElementsSucceed = () => ({
    type: ACTIONS.FORM_GET_FORM_ELEMENTS_SUCCEED,
})

export const getFormElementsFailed = () => ({
    type: ACTIONS.FORM_GET_FORM_ELEMENTS_FAILED,
})

export const getFormElementsRedirected = () => ({
    type: ACTIONS.FORM_GET_FORM_ELEMENTS_REDIRECTED,
})

export const formWithPin = (condition) => ({
    type: ACTIONS.FORM_WITH_PIN,
    condition
})

export const formAuthenticated = () => ({
    type: ACTIONS.FORM_AUTHENTICATED,
})

export const formLoginAttempt = (attempt) => ({
    type: ACTIONS.FORM_LOGIN_ATTEMPT,
    attempt
})

export const getFormElementsSucceedWithAuth = () => ({
    type: ACTIONS.FORM_GET_FORM_ELEMENTS_SUCCEED_WITH_AUTH,
})

export const getFormElementsRequestWithAuth = (workflowKey, pin) => ({
    type: ACTIONS.FORM_GET_FORM_ELEMENTS_REQUEST_WITH_AUTH,
    workflowKey,
    pin
})

export const getFormElementsFailedWithAuth = () => ({
    type: ACTIONS.FORM_GET_FORM_ELEMENTS_FAILED_WITH_AUTH,
})

export const populateFormElements = (payload) => ({
    type: ACTIONS.FORM_POPULATE_FORM_ELEMENTS,
    payload
})

export const setThemeOverride = (themeOverride) => ({
    type: ACTIONS.FORM_SET_THEME,
    themeOverride
})

export const sendFormRequest = (workflowKey, formData, isAufgabe = false) => ({
    type: ACTIONS.FORM_SEND_REQUEST,
    workflowKey,
    formData,
    isAufgabe
})

export const sendFormSucceed = () => ({
    type: ACTIONS.FORM_SEND_SUCCEED,
})

export const sendFormFailed = () => ({
    type: ACTIONS.FORM_SEND_FAILED
})

export const refreshSendForm = () => ({
    type: ACTIONS.FORM_SEND_REFRESH
})

export const updateUploadIds = (fileId, uploadId) => ({
    type: ACTIONS.FORM_UPDATE_UPLOAD_IDS,
    fileId,
    uploadId
})

export const updateUploadIdsInputForm = (fileId, uploadId, keyValue) => ({
    type: ACTIONS.FORM_UPDATE_UPLOAD_IDS_INPUT_FORM,
    fileId,
    uploadId,
    keyValue
})

export const updateResultTextForm = (resultText) => ({
    type: ACTIONS.FORM_UPDATE_RESULT_TEXT,
    resultText
})

export const setReturnUrlForm = (url) => ({
    type: ACTIONS.FORM_SET_RETURN_URL,
    url
})

export const refreshReturnUrlForm = () => ({
    type: ACTIONS.FORM_REFRESH_RETURN_URL,
    
})


export const HandlePush = (url, status) => ({
    type: ACTIONS.AUTH_HANDLE_PUSH,
    url,
    status
})