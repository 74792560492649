import { getSelectedFolder } from 'application/components/building_blocks/tree/helpers'
import { getFoldersInRegisteredMessage } from 'application/redux/selectors'
const { setSelectedFolder } = require('application/redux/actions/pages/akten')
const { put, select } = require('redux-saga/effects')

export function* setSelectedFolderSaga(action) {
    const folders = yield select(getFoldersInRegisteredMessage, action.messageKey)
    const foundedFolder = getSelectedFolder(folders, action.folderId)
    const isCurFolderExist = foundedFolder !== null
    if (isCurFolderExist) {
        yield put(setSelectedFolder(foundedFolder))
    }
}
