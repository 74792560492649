var aesjs = require('aes-js')
export const truncateEndText = (text, maxCount) => {
    text = text.length > maxCount ? text.substring(0, maxCount) : text
    text = text.length === maxCount ? text.substring(0, Math.min(text.length, text.lastIndexOf(' '))) + '...' : text

    return text
}

export const getInitials = (firstName, lastName) => {
    return firstName !== ''
        && firstName !== undefined
        && firstName !== null
        ? `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`
        : lastName.charAt(0).toUpperCase()
}

export const truncateMiddleText = (fullStr, strLen, separator) => {
    if (fullStr.length <= strLen) return fullStr

    separator = separator || '...'

    var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars)
}

// 128-bit key (16 bytes * 8 bits/byte = 128 bits)

export const _enText = (text) => {
    var key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
    var textBytes = aesjs.utils.utf8.toBytes(text)
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))
    var encryptedBytes = aesCtr.encrypt(textBytes)
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)
    return encryptedHex
}

export const _deText = (encryptedHex) => {
    var key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
    var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex)
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))
    var decryptedBytes = aesCtr.decrypt(encryptedBytes)
    // Convert our bytes back into text
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes)
    return decryptedText
}

const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight

export const resizeText = ({ element, elements, minSize = 10, maxSize = 100, step = 1, unit = 'px' }) => {
    (elements || [element]).forEach(el => {
        let i = minSize
        let overflow = false
        const parent = el.parentNode

        while (!overflow && i < maxSize) {
            el.style.fontSize = `${i}${unit}`
            overflow = isOverflown(parent)

            if (!overflow) i += step
        }

        // revert to last state where no overflow happened
        el.style.fontSize = `${i - step}${unit}`
    })
}