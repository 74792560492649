import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ImageViewer from './viewers_by_type/image_viewer'
import PdfViewer from './viewers_by_type/pdf_viewer'
import FallbackViewer from './viewers_by_type/fallback_viewer'
import { FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import PreloaderSpin from 'application/components/controls/preloader/spin'

const StyledFileViewer = styled.div`
    cursor: ${props => props.isFullClick ? 'pointer' : 'default'};
    overflow: ${props => props.isSizeFixed ? 'hidden' : 'auto'};
    height: ${props => props.isSizeFixed ? props.heightFixed : '100%'};
    width: ${props => props.isSizeFixed ? props.widthFixed : '100%'};
    background: ${props => props.isSizeFixed ? `url(${props.imageSrc})` : 'unset'};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: ${props => props.isSizeFixed ? '0px 8px 8px 0px' : 'unset'};
    ${props =>
        props.isPdf
            ? `
            display: flex;
            flex-direction: column;
            ` : `
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
    & .pg-viewer-wrapper{
        overflow-y: auto;
    }
    & .pdf-canvas canvas{
        border: 1px solid #aaa;        
        margin-bottom: 20px;
        max-width: ${props => props.isSizeFixed ? '200px' : '100%'};
    }
    & .pdf-viewer{
        text-align: center;
        max-height: ${props => props.isSizeFixed ? '200px' : 'calc(100% - 150px)'} ;
    }
`

const getAttachmentViewer = extension => {
    switch (extension?.toLowerCase()) {     
    case 'jpg': case 'jpeg': case 'jpe': case 'png': case 'gif':
        if(true)
        return ImageViewer
    case 'pdf':
        return PdfViewer            
    default:
        return FallbackViewer
    }
}

const AttachmentViewerComponent = ({ 
    fileId,
    fileName,
    attachment,
    extension,
    isLoading = true,
    accountId,
    isSizeFixed,
    widthFixed = '200px',
    heightFixed = '200px',
    onFullFileClick,
    withHeadline,
    withDownload,
    onAttachmentDownload,
    fontSizeHeadline,
    attachmentFailed,
    withCaption = false
}) => {
    // const { t } = useTranslation()
    const AttachmentViewer = getAttachmentViewer(extension)
    if (isLoading) {
        // return <StyledFileViewer widthFixed={widthFixed} heightFixed={heightFixed} isSizeFixed={isSizeFixed}>
        //     <FlexGrid>
        //         <FlexGridItem justifyCenter itemsCenter width='widthFixed'>
        //             <PreloaderSpin />
        //         </FlexGridItem>
        //     </FlexGrid>
        // </StyledFileViewer>
        return <StyledFileViewer widthFixed={widthFixed} heightFixed={heightFixed} isFullClick={onFullFileClick !== undefined} onClick={onFullFileClick} isSizeFixed={isSizeFixed} imageSrc={attachment.data}>
            <FallbackViewer
                fileId={fileId} fileName={fileName}
                onAttachmentDownload={onAttachmentDownload}
                withDownload={false}
                withHeadline={withHeadline}
                fontSizeHeadline={fontSizeHeadline} />
        </StyledFileViewer>
    }
    const isPdf = extension === 'pdf'
    return <StyledFileViewer isPdf={isPdf} widthFixed={widthFixed} heightFixed={heightFixed} isFullClick={onFullFileClick !== undefined} onClick={onFullFileClick} isSizeFixed={isSizeFixed} imageSrc={attachment.data}>
        {
            attachmentFailed ? <FallbackViewer
                fileId={fileId} fileName={fileName}
                onAttachmentDownload={onAttachmentDownload}
                withDownload={false}
                withHeadline={withHeadline}
                fontSizeHeadline={fontSizeHeadline} /> :
                <AttachmentViewer
                    fileType={extension}
                    filePath={attachment.data}
                    fileName={fileName}
                    fileId={fileId}
                    accountId={accountId}
                    isSizeFixed={isSizeFixed}
                    onAttachmentDownload={onAttachmentDownload}
                    withDownload={withDownload}
                    withHeadline={withHeadline}
                    fontSizeHeadline={fontSizeHeadline}
                    errorComponent={<p>error!</p>} />
        }

    </StyledFileViewer>
}

AttachmentViewerComponent.propTypes = {
    attachmentId: PropTypes.number,
    attachmentName: PropTypes.string,
    onAttachmentDownload: PropTypes.func,
    extension: PropTypes.string
}

export default AttachmentViewerComponent