import { connect } from 'react-redux'
import Routes from './component'
import {setReturnUrl} from 'application/redux/actions/auth'

const mapDispatchToProps = dispatch => {
    return {
        onRedirectToLogin: (returnUrl) => {
            dispatch(setReturnUrl(returnUrl))
        }
    }
}


export default connect(
    state => ({
        auth: state.auth,
        dialogs: state.dialogs,
    }),
    mapDispatchToProps
)(Routes)