import { call, put, takeEvery } from 'redux-saga/effects'

import { ACTIONS, NOTIFICATION } from 'application/constants'
import { api } from 'application/api'
import { 
    loginRequestFailed,
    loginRequestSuccess,
    populateUserData,
    setPushToDashboard,
    showLoginError,
    writeToken,
} from 'application/redux/actions/auth'
import { accessTokenRegistered } from 'application/storage'
import { handleError } from '../../errors'
import { userMapperImport } from './mappers'
import { refreshAllAktenDAta } from 'application/redux/actions/common/all_akten'
import { refreshAllAccountsDAta } from 'application/redux/actions/common/all_accounts'
import { ERROR_TYPE_CODE } from 'application/constants/errors'
import { showToast } from 'application/redux/actions/notifications'
import { accessTokenForm } from 'application/storage/access_token'

function* loginSaga(action) {
    try {
        const loginResponse = yield call(api.gateway.auth.postLogin, action.username, action.password)

        const userPayload = userMapperImport(loginResponse)

        yield accessTokenRegistered.store(loginResponse.accessToken)
        yield accessTokenForm.store(loginResponse.accessToken)
        yield put(writeToken(loginResponse.accessToken))
        yield put(populateUserData(userPayload))
        yield put(refreshAllAccountsDAta())
        yield put(refreshAllAktenDAta())
        yield put(loginRequestSuccess())
        yield put(setPushToDashboard(true))
    } catch (e) {
		yield put(loginRequestFailed(e.data.payload))
		if (e.response !== undefined && e.response !== null && e.response.status === ERROR_TYPE_CODE.NOT_ACCEPTABLE) {
            yield put(showToast('You do not have access to e.sy One. Please contact your lawyer.', NOTIFICATION.LEVEL.ERROR))
       		yield put(showLoginError('You do not have access to e.sy One. Please contact your lawyer.'))
		} else {
			yield put(showLoginError(e.data.reasonMessage))
			yield handleError(e, action)
		}
    }
}

export function* loginWatcher() {
    yield takeEvery(ACTIONS.AUTH_LOGIN_REQUEST, loginSaga)
}