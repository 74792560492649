import { isTablet } from 'react-device-detect'

export const getGreetings = () => {
    let d = new Date()
    let time = parseInt(d.getHours())
    if (time < 12) {
        return isTablet ? '☀️' : 'Good morning.'
    }
    if (time >= 12 && time < 18) {
        return isTablet ? '🌇' : 'Good afternoon.'
    }
    if (time >= 18) {
        return isTablet ? '🌑' : 'Good evening.'
    }
}