import React from 'react'
import styled from 'styled-components'
import ActionButtonNavlink from './navlink_button'
import ActionButtonHref from './href_button'
import { StyledButtonWrapper } from './fragments'

const StyledButton = styled.button`
    padding: 0px;
    margin: 0px;
    border: none;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: ${props => props.theme.color.color0};
    width:${props => props.strechted ? '100%' : 'auto'};
`

const ActionButton = ({
    buttonText,
    className,
    onButtonClick,
    strechted = false,
    disabled = false,
    secondary = false,
    noBackground = false,
    noPadding = false,
    linkTo = '',
    hrefTo = '',
    color = '#fff',
    fontSize = '18px',
    height,
    borderradius,
    backgroundColor,
    outline = false,
    borderColor = ''
}) => {
    return linkTo !== '' ? <ActionButtonNavlink
        backgroundColor={backgroundColor}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
        strechted={strechted}
        disabled={disabled}
        secondary={secondary}
        noBackground={noBackground}
        linkTo={linkTo}
        color={color}
        fontSize={fontSize}
        height={height}
        borderradius={borderradius}
        outline={outline}
        borderColor={borderColor}
        noPadding={noPadding}
    /> : hrefTo !== '' ? <ActionButtonHref
        backgroundColor={backgroundColor}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
        strechted={strechted}
        disabled={disabled}
        secondary={secondary}
        noBackground={noBackground}
        hrefTo={hrefTo}
        color={color}
        fontSize={fontSize}
        height={height}
        borderradius={borderradius}
        outline={outline}
        borderColor={borderColor}
        noPadding={noPadding}
    /> : <StyledButton disabled={disabled} onClick={onButtonClick} strechted={strechted}>
        <StyledButtonWrapper
            backgroundColor={backgroundColor}
            borderradius={borderradius}
            outline={outline}
            height={height}
            color={color}
            fontSize={fontSize}
            noBackground={noBackground}
            secondary={secondary}
            className={className}
            borderColor={borderColor}
            noPadding={noPadding}
            disabled={disabled}
        >
            {buttonText}
        </StyledButtonWrapper>
    </StyledButton>
}


export default ActionButton