import { sendMessageAkteSucceed } from 'application/redux/actions/pages/akten'
import { call, put } from 'redux-saga/effects'
import { sendSingleMessageAkteSaga } from './send_single_message_akte_saga'
import { sendSingleMessageFolderSaga } from './send_single_message_folder_saga'

export function* sendMessageAkteSaga(action) {
    const inFolder = action.metaData && action.metaData.folderId !== ''
    if (inFolder) {
        yield call(sendSingleMessageFolderSaga, action)
    } else {
        yield call(sendSingleMessageAkteSaga, action)
    }
    
    yield put(sendMessageAkteSucceed())
}