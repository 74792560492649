import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'


const LinkFooter = props => {
    const { openStatement, accountPublicInfo, useMobile = false, textColor } = props
    const { esyThingAgb, esyThingDatenschutz, accountName } = accountPublicInfo
    const hasAgb = esyThingAgb !== undefined && esyThingAgb !== null
    const hasDatenschutz = esyThingDatenschutz !== undefined && esyThingDatenschutz !== null
    const { t } = useTranslation()
    const privacy = t('privacy')
    const conditions = t('conditions')

    return <FlexGrid directionColumn>
                {
                    hasDatenschutz &&
                    <Fragments.StyledLink onClick={() => openStatement(esyThingDatenschutz, privacy + ' - ' + accountName)}>
                         <Fragments.AsclarText 
                                color={textColor} 
                                fontSize={useMobile ? '11.7px' : '17.5px'} 
                                margin={'5px 0'}
                                textDecoration={'underline'}
                            >{privacy}</Fragments.AsclarText>
                    </Fragments.StyledLink>
                }
                {
                    hasAgb &&
                    <Fragments.StyledLink onClick={() => openStatement(esyThingAgb, conditions + ' - ' + accountName)}>
                        <Fragments.AsclarText 
                            color={textColor} 
                            fontSize={useMobile ? '11.7px' : '17.5px'} 
                            margin={'5px 0'}
                            textDecoration={'underline'}
                        >{conditions}</Fragments.AsclarText>
                    </Fragments.StyledLink>
                }
        </FlexGrid>
}

export default LinkFooter
