import Preloader3D from 'application/components/controls/preloader/3d'
import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SomethingWentWrong from './something_went_wrong'



const AkteMetaDataLoader = (props) => {
    const { akteMetaLoaded, akteMetaFailed, getAkteMetaRequest, auth } = props

    let { hashUserKey } = useParams()

    useEffect(() => {
        !akteMetaLoaded && hashUserKey !== undefined && getAkteMetaRequest(hashUserKey)
    }, [akteMetaLoaded, getAkteMetaRequest, hashUserKey])

    if (akteMetaLoaded && !akteMetaFailed) {
        return <React.Fragment>{props.children}</React.Fragment>
    }

    if (akteMetaFailed || hashUserKey === undefined) {
        return <SomethingWentWrong />
    }
    const accountId = auth.common.user.accountId

    return (
        <FlexGrid height="100vh" justifyCenter itemsCenter>
            <Preloader3D/>
        </FlexGrid>
    )
}

export default AkteMetaDataLoader
