import { ACTIONS } from 'application/constants'
import defaultStore from '../../default_store'

const pinReducer = (state = defaultStore.auth.pin, action) => {
    switch (action.type) {
    case ACTIONS.AUTH_SEND_PIN_REQUEST: {
        return {
            ...state,
            ...state.pin,
            request: true,
        }
    }
    case ACTIONS.AUTH_SEND_PIN_SUCCEED: {
        return {
            ...state,
            request: false,
            sent: true
        }
    }
    case ACTIONS.AUTH_SEND_PIN_FAILED: {
        return {
            ...state,
            request: false,
            sent: false,
            failed: true,
        }
    }
    default:
        return state
    }
}

export default pinReducer