import { API_VERSION_ESYMANDANT, fetchHandler, HTTP_METHOD, limiter } from 'application/api/request_builders'
import { fetchRequestBuilder } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const getPin = (hashAuthKey, methode) => {

    const request = fetchRequestBuilder(API_VERSION_ESYMANDANT + '/authenticate/adhoc/pin/' + hashAuthKey + '?preferredChannel=' + methode, HTTP_METHOD.GET)

    
    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}