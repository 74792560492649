import ACTIONS from 'application/constants'
import defaultStore from '../default_store'

const navigationLinkMobileSidebarReducer = (state = defaultStore.sidebars.navigationLinkMobile, action) => {
    switch (action.type) {
    case ACTIONS.SIDEBAR_OPEN_NAVIGATION_LINK_MOBILE:
        return {
            ...state,
            visible: true,
        }
    case ACTIONS.SIDEBAR_CLOSE_NAVIGATION_LINK_MOBILE:
        return {
            ...state,
            visible: false
        }
    case ACTIONS.SIDEBAR_SET_DIALOG_NAVIGATION_LINK_MOBILE:
        return {
            ...state,
            dialogVisible: action.condition
        }
    default:
        return state
    }
}

export default navigationLinkMobileSidebarReducer