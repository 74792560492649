export const WIZARD_STEP = {
    REGISTRATION: {
        WELCOME: 'welcome',
        USERNAME: 'username',
        PASSWORD: 'password',
        DONE: 'done',
    }
}

export const WIZARD_TYPE = {
    REGISTRATION: 'REGISTRATION',
}