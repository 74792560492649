import {Filler, FlexGrid, FlexGridItem } from 'application/components/fragments/grid'
import React, { useContext } from 'react'
import { ImageWizard } from '../../fragments'
import { StartRedirect } from '../../../_common'
import { useMediaQuery } from 'react-responsive'
import TitleWizard from '../_common/title_wizard'
import BannerStatementAccount from './sections/banner_statement_account'
import ButtonUpdateWizard from './sections/button_update_wizard'
import RedirectMobile from 'application/common/redirect_mobile'
import { WizardMobileVersion } from '../../../mobile'
import TopContentWizardReg from '../_common/top_content'
import { usePluginsContext } from 'application/components/hooks/use_plugins_context'

const WizardRegistrationWelcomeComponent = (props) => {
    const { wizardData, onUpdateWizardNext, akteMeta, agbsAndDatenschutzAccepted } = props
    const { accountId, accountName, esyThingAgb, esyThingDatenschutz } = akteMeta
    const accountHasLaw = esyThingAgb !== null || esyThingDatenschutz !== null
    const isNotebook = useMediaQuery({ minWidth: 1224, maxWidth: 1440 })
    const mobileProps = {
        ...props,
        accountId,
        accountName,
        esyThingAgb,
        esyThingDatenschutz,
        accountHasLaw
    }
    const {getAsset, activePlugin} = usePluginsContext()

	return <StartRedirect {...props}>
	<RedirectMobile
                MobileComponent={WizardMobileVersion.WelcomeWizardMobile}
                {...mobileProps}
            >
                <TopContentWizardReg accountId={accountId} noPaddingRightOnContent={true} wizardData={wizardData}/>
                <FlexGridItem>
                    <FlexGrid>
                        <FlexGridItem basis='45%'>
                            <FlexGrid directionColumn>
                                <TitleWizard akteMeta={akteMeta} />
                                <BannerStatementAccount esyThingAgb={esyThingAgb} esyThingDatenschutz={esyThingDatenschutz} accountHasLaw={accountHasLaw} accountId={accountId} accountName={accountName} />
                            </FlexGrid>
                        </FlexGridItem>
                        <FlexGridItem basis='45%' justifyFlexEnd>
                            {!activePlugin &&
                                <ImageWizard
                                src={getAsset('welcome_mandant_compressed/fill_the_form.png')}
                                imgWidth={isNotebook ? '65%' : '75%'}
                                alt='Welcome to e.sy One'
                            />
                            }

                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <Filler />
                <ButtonUpdateWizard accountId={accountId} onUpdateWizardNext={onUpdateWizardNext} agbsAndDatenschutzAccepted={agbsAndDatenschutzAccepted} accountHasLaw={accountHasLaw} />
            </RedirectMobile>
	</StartRedirect>
}

export default WizardRegistrationWelcomeComponent