import { all } from 'redux-saga/effects'

import { regenerateWatcher } from './sagas/auth/regenerate'
import { showNotificationWatcher } from './sagas/notifications'
import { sendPayloadWatcher, fetchDataDialogWatcher } from './sagas/dialogs'
import { debounceWatcher } from './sagas/debounce'
import { wizardWatcher } from './sagas/wizard'
import { akteMetaWatcher } from './sagas/akte_meta'
import * as common from './sagas/common'
import * as auth from './sagas/auth'
import * as pages from './sagas/pages'
import * as files from './sagas/files'
import * as form from './sagas/form'
import { messagesWatcher } from './sagas/messages'

export default function* rootSaga() {
  yield all([
    debounceWatcher(),
    regenerateWatcher(),
    auth.forgotCredentialsWatcher(),
    auth.loginPinWatcher(),
    auth.loginAdHocWatcher(),
    auth.loginWatcher(),
    auth.reqPinWatcher(),
    auth.userDataWatcher(),
    showNotificationWatcher(),
    sendPayloadWatcher(),
    fetchDataDialogWatcher(),
    wizardWatcher(),
    akteMetaWatcher(),
    common.allAktenWatcher(),
    common.allAccountsWatcher(),
    common.agbDatenschutzAccountWatcher(),
    common.accountDetailsWatcher(),
    common.accountPublicInfoInternalAssetsWatcher(),
    pages.settingsWatcher(),
    pages.aktenWatcher(),
    files.fetchFullFileByIdWatcher(),
    files.fetchThumbnailByIdWatcher(),
    files.downloadFileWatcher(),
    files.downloadFileAdHocSagaWatcher(),
    files.postFilesWatcher(),
    files.editPictureWatcher(),
    form.formAssistantWatcher(),
    messagesWatcher(),
  ])
}
