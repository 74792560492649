import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import { isTablet } from 'react-device-detect'
import { NavLink } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import IconLink from '../../icon/icon_link'

const Wrapper = styled(NavLink)`
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: ${p => p.width};
	${props => props.withBorder ? `border-bottom: 1px solid ${props.theme.color.color15}` : 'border-bottom: unset;' }
`

const Title = styled.span`
    color: ${props => props.defaultColor ? props.theme.color.color90 : props.isselected ? props.theme.color.color90 : props.theme.color.color30};
    font-size: ${props => isTablet ? props.theme.fontSize.standard : props.theme.fontSize.medium};
    font-family: ${props => props.theme.fontFamily.secondary};
    font-weight: ${props => props.isselected ? props.theme.fontWeight.semiBold : props.theme.color.regular};
    margin-bottom: 8px;
`

const Description = styled.span`
    color: ${props => props.defaultColor ? props.theme.color.color90 : props.isselected ? props.theme.color.color90 : props.theme.color.color30};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.isselected ? props.theme.fontWeight.semiBold : props.theme.color.regular};
`

const SettingPreviewComponent = ({
    isselected = false,
    titleMenu,
    descMenu,
    to,
    theme,
    withBorder = false,
    defaultColor = false,
    width = '100%',
    onClick = () => {}
}) => {
    return <Wrapper onClick={onClick} width={width} withBorder={withBorder} to={to}>
        <FlexGrid itemsFlexStart justifySpaceBetween>
            <Title defaultColor={defaultColor} isselected={isselected}>
                {titleMenu}
            </Title>
            <IconLink
                iconKey='chevron_right'
                fontSize={theme.fontSize.medium}
                color={defaultColor ? theme.color.color90 : isselected ? theme.color.color90 : theme.color.color30}
                width='auto'
            />
        </FlexGrid>
        <Description defaultColor={defaultColor} isselected={isselected}>
            {descMenu}
        </Description>
    </Wrapper>
}

export default withTheme(SettingPreviewComponent)