import Preloader3D from 'application/components/controls/preloader/3d'
import { FlexGrid } from 'application/components/fragments/grid'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const LoginLoadEffect = (props) => {
    const { getAccountPublicInfo, accountPublicInfo, publicInfoFailed, children } = props
    const { hashUserKey } = useParams()
    useEffect(() => {
        if (hashUserKey) getAccountPublicInfo(hashUserKey)
    }, [hashUserKey, getAccountPublicInfo])

    return accountPublicInfo || !hashUserKey || publicInfoFailed ? <React.Fragment>
        {children}
    </React.Fragment> : <FlexGrid justifyCenter>
        <Preloader3D/>
    </FlexGrid>
}

export default LoginLoadEffect
