import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { accessTokenAdhoc} from 'application/storage/access_token'
import { call, takeEvery } from 'redux-saga/effects'

function* loginAdHocSaga(action) {
  try {
    const loginResponse = yield call(
      api.gateway.auth.postloginAdHoc,
      action.key
    )
    yield accessTokenAdhoc.store(loginResponse.accessToken)
  } catch (e) {}
}

export function* loginAdHocWatcher() {
  yield takeEvery(ACTIONS.AUTH_ADHOC_ACCESS, loginAdHocSaga)
}
