import { STORAGE } from 'application/constants'

export const storageUserData = {
    store: (data) => {
        localStorage.removeItem(STORAGE.USER_DATA)
        localStorage.setItem(STORAGE.USER_DATA, JSON.stringify(data))
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.USER_DATA)
        return storageEntry === null ? [] : (JSON.parse(storageEntry))
    },
    delete: () => {
        localStorage.removeItem(STORAGE.USER_DATA)
    }
}