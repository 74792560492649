import { getSecondaryLogo } from 'application/redux/actions/common/all_accounts'
import { connect } from 'react-redux'
import SecondaryLogoCompanyComponent from './component'

const mapStateToProps = (state, props) => {
    const secondaryLogoRegistered = state.files.secondaryLogo.filter(f => f.accountId === props.accountId && f.isLoaded).length > 0
    return {
        allAccountsLoaded: state.common.allAccounts.isLoaded,
        secondaryLogoLoaded: secondaryLogoRegistered,
        hasSecondaryLogo: secondaryLogoRegistered && state.files.secondaryLogo.find(f => f.accountId === props.accountId).has,
        secondaryLogo: secondaryLogoRegistered && state.files.secondaryLogo.find(f => f.accountId === props.accountId).data,
        secondaryColor: props.accountId && state.common.allAccounts.payload.find(f => f.id === props.accountId).secondaryColor,
        auth: state.auth,
    }
}

const mapDispatchToProps = dispatch => ({
    getSecondaryLogo: (accountId) => dispatch(getSecondaryLogo(accountId))
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryLogoCompanyComponent)