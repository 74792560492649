import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    flex-flow: row nowrap;
    padding: 10px;
`

const StyledText = styled.p`
    margin: 0px;
    text-transform: uppercase;
    font-family: ${p => p.theme.fontFamily.secondary};
    font-size: ${p => p.theme.fontSize.standard};
    font-weight: ${p => p.theme.fontWeight.bold};
`

const DivLine = styled.div`
    border-bottom: 1px solid ${p => p.theme.color.color20};
    flex: 2 0;
`

const DividerTextLine = ({ text }) => {
    return <Wrapper>
        <StyledText>
            {text}
        </StyledText>
        <DivLine />
    </Wrapper>
}

DividerTextLine.propTypes = {
    text: PropTypes.string.isRequired
}

export default DividerTextLine
