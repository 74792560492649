export const createMessageMapper = (payload, files, userFullName) => {
    const fileIds = getIdFromFiles(files)
    return {
        Header: payload.subject,
        TextContent: payload.message,
        UploadIds: fileIds
    }
}

const getIdFromFiles = (files) => {
    let result = files.map(f => f.fileuploadId)
    return result
}

export const createMessageOnqueueMapper = (id, message, creatorIdHash, creatorName, attachments) => {
    return {
        id,
        message,
        creatorIdHash,
        creatorName,
        creationDate: new Date(),
        attachments,
        isFavorite: false,
        isOwnMessage: true,
        delivered: false,
        read: true,
    }
}