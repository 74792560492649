export const STORAGE = {
    ACCESS_TOKEN_REGISTERED: 'storage_access_token_registered',
    ACCESS_TOKEN_UNREGISTERED: 'storage_access_token_unregistered',
    ACCESS_TOKEN_FORM: 'storage_access_token_form',
    ACCESS_TOKEN_ADHOC: 'storage_access_token_adhoc',
    PIN_TOKEN_FORM: 'pin_token_form',
    ACCESS_TOKEN: 'storage_access_token',
    TOKEN_DATA: 'storage_token_data',
    USER_DATA: 'storage_ud',
    WIZARD_DATA: 'storage_wizard_data',
    HASH_USER_KEY: 'storage_hash_user_key',
    USERNAME_SET: 'storage_us',
    PASSWORD_SET: 'storage_ps',
    REGISTRATION_COMPLETED: 'rc',
    CONSENT_USE_APP: 'cua',
    LOCKED_LOGIN_ATTEMPT_TIME: 'llat',
}