export const en = {
  translation: {
    'Mandate Conditions': 'Mandate terms',
    'LawBannerText1' : 'I agree with the',
    'LawBannerText2' : 'privacy policy',
    'LawBannerText3' : 'agreement.',
    'privacy' : 'Privacy policy',
    'legal notice' : 'Legal notice',
    'agbTextFormula': 'AGB',
    'agbText': 'Terms & conditions',
    'PasswordForgotSubHeadline': 'Please enter your username. You will receive an e-mail with a link to edit your password.',
    'ForgotUsernameSubHeadline': 'Please enter your e-mail address. We will send you an e-mail with your username.',
    'ForgotUsername': 'Forgot your username?',
    'ForgotPassword': 'Forgot your password?',
    // 'UsernamePlaceholderForgot': 'username',
    // 'email': 'e-mail address',
    // 'Profile picture, Password': 'Profile picture, password',
    // 'New Password': 'New password',
    'Good Morning!': 'Good morning.',
    'Good Afternoon!': 'Good afternoon.',
    'Good Evening!': 'Good evening.',
    'Account Settings': 'Account settings',
    'Search Akten': 'Search cases',
    'MessagesNavigationBar': 'Cases',
    'DashboardMobileNavigationBar': 'Messages',
    'Settings' : 'Settings',
    'PasswordResetLinkSuccess': 'Password reset link has been sent to your e-mail.',
    'UsernameResetLinkSuccess': 'Username has been sent to your e-mail.',
    'LoginImageRightPath': '/assets/images/wourage/image_login.png',
    'There Is No Task': 'There is no task.',
    'ValidUntil' : 'valid until',
    'ExpiredOn' : 'expired on',
    'AnsweredOn': 'answered on',
    'Akten': 'Files',


    'EmptySharedMessageContent': 'Empty message',
    'No folders have been created yet.': 'Empty folders',
    'No favorites have been saved yet.': 'Empty favorites',
    'No files': 'Empty files',
    'ErrorRedirectButtonText': 'Back to loginpage',


    'EsyMandantRegistration': 'Please create a username.',
    'EsyMandantRegistrationSubTitle':'',
    'EsyMandantPasswordConfirmation': 'Please create a secure password.',
    'EsyMandantSalutation': 'Welcome.',
    'EsyMandantSalutationText': '',
    'Herzlichen Glückwunsch!': 'Your registration was successful.',
    'CongratulationText1':'',
    'FirstStep': 'Next', 

    'Forgot Username': 'Forgot username',
    'Forgot Password': 'Forgot password',

    'ResetPassword': 'Reset password',
    'NewPasswordPlaceholder' : 'New password',
    'NewPasswordConfirmationPlaceholder' : 'New password confirmation',

    'zum Dashboard': 'Go to the online portal',

    
    'Enter a subject' : 'Please enter your subject.',
    'Add subject' : 'Subject',
    'Enter your message' : 'Please enter your message.',
    
    'CheckReceivedEmail': 'You received an e-mail from us.',
    
    'RequestPINAnother' : 'Request PIN again.',
  }
}