import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import { FlexGrid } from 'application/components/fragments/grid'
// import { textHelpers } from 'application/common'

const StyledImage = styled.img`
    width: auto;
    max-width: 80%;
`
// const StyledCaption = styled.div`
//         font-size: ${props => props.theme.fontSize.medium};
//         font-weight: ${props => props.theme.fontWeight.bold};
//         margin: 0;
//         max-width: 100%;
//         text-align: center;
//         `

const ImageViewerComponent = ({ isSizeFixed, fileType, filePath, errorComponent = null, withCaption = false, fileName }) => {
    // const truncateFileName = textHelpers.truncateMiddleText(fileName, 20)
    return !isSizeFixed &&
        <StyledImage src={filePath} className='jestImageViewer' />
}

ImageViewerComponent.propTypes = {
    fileType: PropTypes.string.isRequired,
    filePath: PropTypes.string.isRequired,
    errorComponent: PropTypes.element,
}

export default ImageViewerComponent