import { connect } from 'react-redux'
import SettingListComponent from './component'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingListComponent)