import { takeEvery } from 'redux-saga/effects'
import i18next from 'i18next'
import { toast } from 'react-toastify'
import { NOTIFICATION, ACTIONS } from 'application/constants'

function showNotificationSaga(payload) {
    switch (payload.level) {     
    case NOTIFICATION.LEVEL.ERROR:
        toast.error(i18next.t(payload.message))
        break
    case NOTIFICATION.LEVEL.WARNING:
        toast.warn(i18next.t(payload.message))
        break
    default: //NOTIFICATION.LEVEL.SUCCESS
        toast.success(i18next.t(payload.message))
    }
}

export function* showNotificationWatcher() {
    yield takeEvery(ACTIONS.NOTIFICATIONS_SHOW_TOAST, showNotificationSaga)
}