import React, { Fragment, useEffect } from 'react'

const AllAktenLoader = (props) => {
    const { allAccountsLoaded, getAllAkten, children, auth } = props
    const loggedIn = auth.registered.loggedIn || auth.unregistered.loggedIn
    useEffect(() => {
        loggedIn && allAccountsLoaded && getAllAkten()
    }, [allAccountsLoaded, getAllAkten, loggedIn])

    return <Fragment>
        {children}
    </Fragment>
}

export default AllAktenLoader