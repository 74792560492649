import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from 'application/constants'
import * as Pages from 'application/components/pages'
import { AuthProvider } from 'application/components/context/auth/auth_context'
import { RequireAuth } from 'application/components/context/auth/require_auth'

const AppRoutes = ({ auth, dialogs, onRedirectToLogin }) => {
    const logoutClicked = auth.common.logoutClicked
    return (
        <AuthProvider auth={auth} onRedirectToLogin={onRedirectToLogin} logoutClicked={logoutClicked}>
            <Routes>
                {/* EXTERNAL */}
                <Route exact path={ROUTES.LOGIN} element={<Pages.External.Auth.Login />}>
                    <Route exact path={ROUTES.HASHUSERKEY} element={<Pages.External.Auth.Login />} />
                </Route>
                <Route exact path={ROUTES.ENTER_PIN} element={<Pages.External.Auth.EnterPin />}>
                    <Route exact path={ROUTES.HASHUSERKEY} element={<Pages.External.Auth.EnterPin />} />
                </Route>
                <Route exact path={ROUTES.REQUEST_PIN} element={<Pages.External.Auth.RequestPin />}>
                    <Route exact path={ROUTES.HASHUSERKEY} element={<Pages.External.Auth.RequestPin />} />
                </Route>
                <Route exact path={ROUTES.BASE} element={<Pages.External.Auth.Login />} />
                <Route
                    exact
                    path={ROUTES.BASE_BUSINESS}
                    element={<Pages.External.WelcomePage.BusinessWelcomePage />}
                />
                <Route
                    exact
                    path={ROUTES.WIZARD_REG}
                    element={<Pages.External.Wizard.WizardRegistration />}
                />
                <Route
                    exact
                    path={ROUTES.FORGET_PASSWORD}
                    element={<Pages.External.Auth.ForgetCredentials.RequestPassword />}
                />
                <Route
                    exact
                    path={ROUTES.FORGET_USERNAME}
                    element={<Pages.External.Auth.ForgetCredentials.RequestUsername />}
                />
                <Route
                    exact
                    path={ROUTES.FORGET_PASSWORD_HASH}
                    element={<Pages.External.Auth.ForgetCredentials.RequestPassword />}
                />
                <Route
                    exact
                    path={ROUTES.FORGET_USERNAME_HASH}
                    element={<Pages.External.Auth.ForgetCredentials.RequestUsername />}
                />
                <Route
                    exact
                    path={ROUTES.RESET_PASSWORD_HASH}
                    element={<Pages.External.Auth.ForgetCredentials.ResetPassword />}
                />
                <Route
                    exact
                    path={ROUTES.RESET_PASSWORD_DONE}
                    element={<Pages.External.Auth.ForgetCredentials.ResetPasswordDone />}
                />
                <Route
                    exact
                    path={ROUTES.FILL_THE_FORM}
                    element={<Pages.External.MobileAssistant.FormMobileAssistant />}
                />
                <Route
                    exact
                    path={ROUTES.FILL_THE_FORM_SUCCEED}
                    element={<Pages.External.MobileAssistant.FormSucceed />}
                />
                <Route
                    exact
                    path={ROUTES.FILL_THE_FORM_EXTERNAL_REQUEST}
                    element={<Pages.External.MobileAssistant.FormMobileAssistant />}
                />
                <Route
                    exact
                    path={ROUTES.PAGE_FILL_THE_FORM_EXTERNAL_REQUEST}
                    element={<Pages.External.MobileAssistant.FormMobileAssistant />}
                />
                <Route
                    exact
                    path={ROUTES.FILL_THE_FORM_EXTERNAL_REQUEST_SUCCEED}
                    element={<Pages.External.MobileAssistant.FormSucceed />}
                />
                <Route
                    exact
                    path={ROUTES.PAGE_FILL_THE_FORM_EXTERNAL_REQUEST_SUCCEED}
                    element={<Pages.External.MobileAssistant.FormSucceed />}
                />
                <Route
                    exact
                    path={ROUTES.LOGIN_SPECIFIC_ACCOUNT}
                    element={<Pages.External.Auth.LoginAccount />}
                />
                <Route
                    exact
                    path={ROUTES.PAGE_LOGIN_SPECIFIC_ACCOUNT}
                    element={<Pages.External.Auth.LoginAccount />}
                />
               
                {/* INTERNAL */}
                <Route
                    exact
                    path={ROUTES.DASHBOARD}
                    element={
                        <RequireAuth>
                            <Pages.Internal.Dashboard />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path={ROUTES.AKTEN}
                    element={
                        <RequireAuth>
                            <Pages.Internal.Akten />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path={ROUTES.AKTEN_PRESELECTED}
                    element={
                        <RequireAuth>
                            <Pages.Internal.Akten />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path={ROUTES.SERVICE_PROVIDER}
                    element={
                        <RequireAuth>
                            <Pages.Internal.ServiceProvider />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path={ROUTES.SETTINGS}
                    element={
                        <RequireAuth>
                            <Pages.Internal.Settings />
                        </RequireAuth>
                    }
                >
                    <Route
                        exact
                        path={ROUTES.SELECTED_SETTING}
                        element={
                            <RequireAuth>
                                <Pages.Internal.Settings />
                            </RequireAuth>
                        }
                    />
                </Route>
                {/* MOBILE */}
                <Route
                    exact
                    path={ROUTES.YOU_IN_MOBILE}
                    element={<Pages.External.MobilePage.MobileDownloadApp />}
                />
                <Route
                    exact
                    path={ROUTES.ACTIVATE_MOBILE_NO_APP}
                    element={<Pages.External.MobilePage.ActivateMode />}
                />
                <Route path='*' element={<Pages.External.NotFound />} />
            </Routes>
        </AuthProvider>
    )
}

export default AppRoutes
