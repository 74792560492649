import { api } from 'application/api'
import { sendPinSucceed } from 'application/redux/actions/auth'
import { closeAllDialogs } from 'application/redux/actions/dialogs'
import { showToast } from 'application/redux/actions/notifications'
import { hideWaitingScreen, showWaitingScreen } from 'application/redux/actions/waiting_screen'
import { call, put } from 'redux-saga/effects'

export function* getPinSaga(action) {
    yield put(showWaitingScreen('Sending pin'))
    yield call(api.gateway.auth.getPin, action.payload.hashAuthKey, action.payload.methode)
    yield put(hideWaitingScreen())
    yield put(sendPinSucceed())
    yield put(closeAllDialogs())
    yield put(showToast(action.metaData.successMessage))
}