import PreloaderSpin from 'application/components/controls/preloader/spin'
import { FlexGrid } from 'application/components/fragments/grid'
import React from 'react'
import InternalLayout from '../../_layout'
import CountSection from '../_common/count_section'
import HeadlineSection from '../_common/headline_section'
import ProviderSection from '../_common/provider_section'
import { getCountIndicator } from '../_common/function'
import { PushBottom24 } from '../dashboard/fragments'

const ServiceProviderComponent = ({
    allAccountsLoaded,
    allAccounts,
    setSelectedAccountDetails,
    selectedAccount,
    allAkten
}) => {
    return <InternalLayout>
        {
            allAccountsLoaded ? <FlexGrid directionColumn>
                <HeadlineSection
                    headline={'Service Provider'}
                />
                <CountSection
                    countIndicator={getCountIndicator(allAkten, allAccounts)}
                    withIcon={false}
                />
                <PushBottom24 />
                <ProviderSection
                    allAkten={allAkten}
                    allAccounts={allAccounts}
                    setSelectedAccountDetails={setSelectedAccountDetails}
                    selectedAccount={selectedAccount}
                />
            </FlexGrid> : <PreloaderSpin />
        }
    </InternalLayout>
}

export default ServiceProviderComponent