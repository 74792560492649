import { usePluginsContext } from 'application/components/hooks/use_plugins_context'
import { useTranslation } from 'react-i18next'

export function MobileAssistantFooterPolicy(props){
    const { textColor } = props
    const { config } = usePluginsContext()
    const {t} = useTranslation()
    return <>
        <a href={config.datenschutzLink} target='_blank' rel='noopener noreferrer'
            style={{
                color: textColor,
            }}
        >
            {t('privacy')}
        </a>
        <a href={config.agbLink} target='_blank' rel='noopener noreferrer'
            style={{
                color: textColor,
            }}
        >
            {t('agbText')}
        </a>
    </>
}