import React, { useCallback, useState } from 'react'
import { FlexGrid, FlexGridItem, PushBottom20, PushBottom30 } from 'application/components/fragments/grid'
import { useTranslation } from 'react-i18next'
import { DialogFooterRight, DialogMainContent } from 'application/components/fragments/dialog'
import ActionButton from 'application/components/controls/button/action_button/component'
import { SimpleUploader } from 'application/components/controls/form_elements'
import Cropper from 'react-easy-crop'
import Slider from '@mui/material/Slider'
import { CropperWrapper, SliderWrapper } from './fragments'
import { FlexDividerHorizontal } from 'application/components/fragments/flex_divider'
import { isMobileOnly } from 'react-device-detect'

const UploadProfilePictureDialog = ({ abort, uploadProfilePicture, putProfilePicture, dialog, onFileChange }) => {
    const { t } = useTranslation()

    const payload = dialog.payload

    const imageSrc = payload.file.attachment.thumbnail
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const errorMessage = '🎃 ' + t('Image cannot be uploaded')
    const successMessage = '👀 ' + t('Image has been uploaded')

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const handleNewProfilePicture = () => {
        uploadProfilePicture(imageSrc, croppedAreaPixels, rotation, dialog.name, payload.userId, payload.accountId, payload.file, errorMessage, successMessage)
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexGrid directionColumn>
                <FlexGridItem>
                    <CropperWrapper>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={1}
                            cropShape="round"
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        /> 
                    </CropperWrapper>
                </FlexGridItem>
                <PushBottom30 />
                <FlexGridItem justifyCenter itemsCenter>
                    <FlexGrid justifyCenter>
                        <FlexGridItem justifyFlexEnd={!isMobileOnly} itemsCenter widthMobile='100%'>
                            <span>
                                {t('EnlargeImage')}
                            </span>
                            <SliderWrapper>
                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                            </SliderWrapper>
                        </FlexGridItem>
                        <FlexGridItem itemsCenter widthMobile='100%'>
                            <span>
                                {t('RotateImage')}
                            </span>
                            <SliderWrapper>
                                <Slider
                                    value={rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    aria-labelledby="Rotation"
                                    onChange={(e, rotation) => setRotation(rotation)}
                                />
                            </SliderWrapper>
                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <PushBottom20 />
                <FlexDividerHorizontal />
                <PushBottom20 />
            </FlexGrid>
            <DialogFooterRight>
                <FlexGridItem itemsCenter justifyFlexEnd>
                    <SimpleUploader
                        label={t('ChangeProfilePicture')}
                        onFileAdded={(files) => putProfilePicture(files)}
                        pp={true}
                    />
                    <ActionButton
                        fontSize={'14px'}
                        secondary={true}
                        buttonText={t('choose')}
                        height='35px'
                        onButtonClick={handleNewProfilePicture}
                    // strechted={true}
                    />
                </FlexGridItem>
            </DialogFooterRight>
        </DialogMainContent>
    </React.Fragment>

}

export default UploadProfilePictureDialog