import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'


export const AufgabeNoData = (props) => {
    const { t } = useTranslation()
    const theme = useTheme()
    
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        fontSize: '20px',
        fontFamily: theme.fontFamily.secondary,
        fontWeight: 'bold',
        marginTop:'100px',
    }}>
        <span>{t('There Is No Task')}</span>
    </div>
}

