import { DIALOG } from 'application/constants'
import { setPicture } from 'application/redux/actions/files'
import { setNewPasswordRequest } from 'application/redux/actions/pages/settings'
import { connect } from 'react-redux'
import AccountSettingComponent from './component'

const mapStateToProps = state => ({
    profilePicture: state.auth.common.user.profilePicture,
    user: state.auth.common.user,
    userId: state.auth.common.user.userId,
    accountId: state.auth.common.user.accountIds[0],
    sendNewPasswordRequest: state.pages.settings.account.newPasswordRequest,
    allAccounts: state.common.allAccounts.payload,
})

const mapDispatchToProps = dispatch => ({
    setProfilePicture: (files, userId, accountId) => dispatch(setPicture(files, {dialogName: DIALOG.NAME.UPLOAD_PROFILE_PICTURE, accountId, userId})),
    sendNewPassword: (userId, payload, handleResponseMessage) => { dispatch(setNewPasswordRequest(userId, payload, handleResponseMessage)) },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountSettingComponent)