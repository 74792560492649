import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { AKTEN } from 'application/constants'
import { useQuery } from 'application/components/hooks'
import { useParams } from 'react-router-dom'
import { ROUTE_PARAMETERS } from 'application/constants'
import { useTranslation } from 'react-i18next'

const CategorizedAktenContent = ({
    displayContentMobile,
    setDisplayContentMobile,
    refreshMessageFavorite,
    selectedAkte,
    selectedFolder,
    akteIdHash,
    accountId,
    updateDataSelectedMessageFolder,
	updateDataSelectedMessageRoot,
	// totalUnreadMessageInFolder
}) => {
    let query = useQuery()
    let folderId = query.getStringParam(ROUTE_PARAMETERS.AKTEN.FOLDER_ID)
    let inFolder = folderId !== ''
    let params = useParams()
    const handlingUpdateMessages = () => {
        if (inFolder) {
            updateDataSelectedMessageFolder(params, selectedFolder.id, akteIdHash, accountId, selectedFolder)
        } else {
            updateDataSelectedMessageRoot(params, akteIdHash, accountId, selectedAkte)
        }
	}
	const { t } = useTranslation()
	const folderChipLabel = t(AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FOLDERS)
    return (
        <Stack direction={'row'} spacing={1} justifyContent={'center'}>
            <Chip
                label={t(AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.MESSAGES)}
                variant={displayContentMobile.view === AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.MESSAGES ? 'filled' : 'outlined'}
                onClick={() => {
                    setDisplayContentMobile({ view: AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.MESSAGES, payload: {} })
                    handlingUpdateMessages()
                }}
            />
            <Chip
                label={t(AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FILES)}
                variant={displayContentMobile.view === AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FILES ? 'filled' : 'outlined'}
                onClick={() => setDisplayContentMobile({ view: AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FILES, payload: {} })}
            />
            <Chip
                label={folderChipLabel}
                variant={displayContentMobile.view === AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FOLDERS ? 'filled' : 'outlined'}
                onClick={() => setDisplayContentMobile({ view: AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FOLDERS, payload: {} })}
            />
            <Chip
                label={t(AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FAVORITES)}
                variant={displayContentMobile.view === AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FAVORITES ? 'filled' : 'outlined'}
                onClick={() => {
                    setDisplayContentMobile({ view: AKTEN.DISPLAY_CONTENT_VIEW_MOBILE.FAVORITES, payload: {} })
                    refreshMessageFavorite()
                }}
            />
        </Stack>
    )
}

CategorizedAktenContent.propTypes = {
    isSelected: PropTypes.string,
    setDisplayContentMobile: PropTypes.func,
    displayContentMobile: PropTypes.object,
    refreshMessageFavorite: PropTypes.func,
    selectedAkte: PropTypes.object,
    selectedFolder: PropTypes.object,
    akteIdHash: PropTypes.string,
    accountId: PropTypes.string,
    updateDataSelectedMessageFolder: PropTypes.func,
    updateDataSelectedMessageRoot: PropTypes.func,
}

export default CategorizedAktenContent
