import { DIALOG } from 'application/constants'
import { getAccountPublicInfo } from 'application/redux/actions/common/account_details'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { refreshGetFormElements, refreshReturnUrlForm, refreshSendForm, setReturnUrlForm } from 'application/redux/actions/form'
import { connect } from 'react-redux'
import LoginAccountComponent from './component'

const mapStateToProps = (state) => ({
    accountPublicInfo: state.common.accountDetails.publicInfo,
    publicInfoLoading: state.common.accountDetails.publicInfoLoading,
    publicInfoLoaded: state.common.accountDetails.publicInfoLoaded,
    publicInfoFailed: state.common.accountDetails.publicInfoFailed,
    auth: state.auth,
    sendFormSucceed: state.form.send.succeed,
    formReturnUrl: state.form.returnUrl,
    formLoaded: state.form.succeed,
})

const mapDispatchToProps = dispatch => ({
    getAccountPublicInfo: (accountIdentify) => dispatch(getAccountPublicInfo(accountIdentify)),
    openLoginDialog: (title) => { dispatch(openDialogByName(DIALOG.NAME.LOGIN_FORM, true, {}, title)) },
    handleExternalRequestClick: (url) => dispatch(setReturnUrlForm(url)),
    refreshForm: () => {
        dispatch(refreshReturnUrlForm())
        dispatch(refreshSendForm())
        dispatch(refreshGetFormElements())
    },
    openStatement: (statement, title) => dispatch(openDialogByName(DIALOG.NAME.DISPLAY_ACCOUNT_STATEMENT, true, { statement }, title)),
})

export default connect(
    mapStateToProps, mapDispatchToProps
)(LoginAccountComponent)
